import React from "react";
import { AuthenticationContext } from "../../AuthenticationProvider";
import { Route, RouteProps } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

interface IPrivateRouteProps extends RouteProps {}

export const PrivateRoute = (props: IPrivateRouteProps) => {
  const { isAuthenticated, signin } = React.useContext(AuthenticationContext);
  const [authenticated, setAuthentication] = React.useState(isAuthenticated());

  React.useEffect(() => {
    setAuthentication(isAuthenticated());
  }, [setAuthentication, isAuthenticated]);

  React.useEffect(() => {
    if (!authenticated) {
      signin();
    }
  }, [authenticated, signin]);

  if (authenticated) {
    return <Route {...props} />;
  } else {
    return <CircularProgress />;
  }
};
