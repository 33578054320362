import React, { useEffect } from "react";
import { Tabs, Tab, Badge } from "@material-ui/core";
import "./FilingHomeTab.scss";
import { Filing } from "../../../../utils/model";
import { RouteComponentProps, withRouter } from "react-router";
import { FilingView } from "../../FilingHome";
import { FilingTypes, StatusTypes } from "../../../../utils/enums";


interface IAgencyAdminProps extends RouteComponentProps {
  filing?: Filing;
  tab: FilingView;
  setTab: (tab: FilingView) => void;
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const FilingHomeTabWithRouter = (props: IAgencyAdminProps) => {
  const { tab, setTab, filing } = props;

  // console.log("Filing Home Tab: Props Display", props)
  useEffect(() => { }, [props.location.search]);
  useEffect(() => { }, [props.filing]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    tabSelected: FilingView
  ) => {
    // console.log("FilingHomeTab", tabSelected);
    setTab(tabSelected);
  };

  return (
    <div className="filing-home-tab">
      <div className="tab-container">
        <div className="tabs">
          <Tabs
            value={tab}
            indicatorColor="primary"
            aria-label="simple"
            onChange={handleChange}
          >
            <Tab label="Actions" value="Actions" {...a11yProps("Actions")} />
            <Tab label="Filing" value="Filing" {...a11yProps("Filing")} />
            <Tab label="Comments" value="Comments" {...a11yProps("Comments")} />
            {filing?.filingType?.id != FilingTypes.ExecutiveOrder &&
             filing?.filingType?.id != FilingTypes.GovernorDeclaration &&
             filing?.filingType?.id != FilingTypes.Notice_LD &&
             filing?.filingType?.id != FilingTypes.Notice_WR &&
             filing?.filingType?.id != FilingTypes.Notice_ERR &&
             filing?.filingType?.id != FilingTypes.EditorsNotice &&
             filing?.filingType?.id != FilingTypes.FeeIncrease
             ? <Tab label="View Draft" value="Draft" {...a11yProps("Draft")}/> : null}
            <Tab label="History" value="History" {...a11yProps("History")} />
            {/* {(tab === "Manage") ? <Tab label="Review Segments" value="Review" {...a11yProps("Review")} /> : <div></div>} */}

            {/* <Tab
              label="Notice Filing"
              value="Notice Filing"
              {...a11yProps("Notice Filing")}
            /> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export const FilingHomeTab = withRouter(FilingHomeTabWithRouter);
