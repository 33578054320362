export enum FilingTypes {
    Adopted = 9,
    Adopted_PRE = 12,
    Adopted_EME = 11,
    Adopted_PERM = 10,
    EditorsNotice = 19,
    Emergency = 14,
    ExecutiveOrder = 17,
    FeeIncrease = 13,
    GovernorDeclaration = 21,
    Misc = 18,
    Notice = 1,
    Notice_CAN = 4,
    Notice_CON = 3,
    Notice_ERR = 8,
    Notice_LD = 6,
    Notice_NRI = 2,
    Notice_SR = 5,
    Notice_WR = 7,
    Permanent = 15,
    Preemptive = 16,
    RulemakingRecord = 20
}