import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { EntityType } from "../model";

class EntityTypeAPI extends BaseAPI<EntityType> {
  constructor() {
    super("entityType", APISettings.adminRulesApiUrl);
  }

  public getAllEntityTypes: () => Promise<EntityType[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllEntityTypes`)).data;
  };

  public getEntityTypesById: (id: number) => Promise<EntityType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetEntityTypesById/${id}`))
      .data;
  };

  public createEntityTypes: (entityType: EntityType) => Promise<any> = async (
    entityType: EntityType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateEntityTypes`,
      {
        entityType,
      }
    );
    return response.data;
  };

  public removeEntityTypes: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveEntityTypes/${id}`
    );
    return response.data;
  };

  public updateEntityTypes: (
    id: number,
    entityType: EntityType
  ) => Promise<EntityType> = async (id: number, entityType: EntityType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateEntityTypes/${id}`,
      { entityType }
    );
    return response.data;
  };
}

export const entityTypeAPI = new EntityTypeAPI();
