import * as React from "react";
// import { Column } from "material-table";
import { Filing, FilingType } from "../../../../utils/model";
import { UrlParser } from "../../../../utils";
import { FilingTypes } from "../../../../utils/enums";

// #######################################################
// Set Initial columns for dynamic column generation
// #######################################################
let columns: any = [];

// #######################################################
// Set Contact Types and create columns
// #######################################################
export const setFilingTypeRows = (
  rows: any,
  filingType?: FilingType,
  updateRows?: () => void
) => {

  if (rows[0] !== undefined && rows) {
    const filing: Filing = new Filing(
      rows[0].name,
      rows[0].visibility,
      rows[0].additionalInformation,
      rows[0].filingType,
      rows[0].entityType,
      rows[0].entityId,
      rows[0].entity,
      rows[0].status,
      rows[0].published,
      rows[0].amendments,
      rows[0].todoLists,
      rows[0].templateValues,
      rows[0].commentThread,
      rows[0].created,
      rows[0].submissionDate,
      rows[0].acceptanceDate,
      rows[0].publicationDate
    );

    columns = [];
    const keys = Object.keys(filing);
    keys.forEach((key, index) => {
      // Regular expression takes table name and converts formatting
      var text = key.replace(/([A-Z])/g, " $1");
      var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
      const column: any = {};
      column.title = capitalizeText;
      let lookup: Map<string, string> = new Map<string, string>();

      switch (column.title) {
        case "Published":
          column.title = "Publication Status";
          break;
        case "Status":
          break;
        // case "Filing Type":

        //   break;
        case "Created":
          column.title = "Date Created";
          break;
        case "Title Number":
          column.title = "Title";
          break;
        case "Chapter Number":
          column.title = "Chapter";
          break;
        // Filter which columns should be visible from original object
        case "Status Id":
        case "Filing Type Id":
        case "Filing Id":
        case "Root Id":
        case "Locked":
        case "Todo Lists":
        case "Additional Information":
        case "Visibility":
        case "Notes":
        case "Entity Type":
        case "Entity Id":
        case "Entity":
        case "Amendments":
        case "Template Values":
        case "Comment Thread":
        case "Entity Type Id":
        case "Filing History":
        case "Publication Status":
        case "PublicationStatus":
        case "Root":
        case "Description":
        case "Available Actions":
        case "Name":
        case "Tasks":
        case "Include In Register":
          column.hidden = true;
          break;

        default:
          break;
      }

      column.field = key;
      if (!column.hidden) {
        columns.push(column);
      }
    });
  }

  rows.forEach(async (element: any, i: number) => {
    let url = UrlParser.Filing.parseFiling(FilingTypes[element.filingTypeId], rows[i].id);
    rows[i].url = url;
    //rows[i].filingType = element.filingType;
  })

  return { rows: rows, columns: columns };
};
