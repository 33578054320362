import { StatusRecordStatus } from "../enums";
import { StatusType } from "../enums/StatusType";
// import { ContactType } from "./ContactType";
import { Address } from "./Address"
import { Identifiable } from ".";
import { ContactType } from "./ContactType";

export class Contact extends Identifiable {
    constructor(firstName: string, lastName: string, title: string, email: string, website: string, address: Address, phone: string, status: StatusType, contactType: ContactType, contactTypeId: number, recordStatus: StatusRecordStatus) {
        super(null);
        this.firstName = firstName;
        this.lastName = lastName;
        this.title = title;
        this.email = email;
        this.website = website;
        this.address = address;
        this.phone = phone;
        this.status = status;
        this.contactType = contactType;
        this.contactTypeId = contactTypeId;
        this.recordStatus = recordStatus;
    }

    public firstName: string;
    public lastName: string;
    public title: string;
    public email: string;
    public website: string;
    public address?: Address;
    public phone: string;
    public status: StatusType;
    public contactType?: ContactType;
    public contactTypeId: number
    public recordStatus: StatusRecordStatus;
}