import {
  Avatar,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import DeleteForever from "@material-ui/icons/DeleteForever";
import React, { useState } from "react";
import { DialogTitleWithExit } from "../../../../../../../../../../../components/DialogTitleWithExit";
import { Action } from "../../../../../../../../../../../utils/model";
import { Notification } from "../../../../../../../../../../../utils/model/subscription/Notification";
import { NotificationForm } from "../../../NotificationForm";
import "./NotificationItem.scss";
import { notificationAPI } from "../../../../../../../../../../../utils/api";

interface INotificationItemProps {
  notification: Notification;
  action: Action;
  onChange: () => void;
}

export const NotificationItem = (props: INotificationItemProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const onClose = () => {
    setOpen(false);
  };
  const onDelete = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setDeleting(true);
    if (props.notification.id != null) {
      await notificationAPI.delete(props.notification.id);
      props.onChange();
      onClose();
    }
  };
  const openModal = () => {
    if (!deleting) {
      setOpen(true);
    } else {
      setDeleting(false);
    }
  };
  const onSubmit = () => {
    props.onChange();
    onClose();
  };
  return (
    <div className="notification-item">
      <div style={{ display: "flex" }}>
        <ListItem onClick={openModal} button>
          <ListItemAvatar>
            <Avatar>
              <EmailOutlined />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={props.notification.name} />
        </ListItem>
        <IconButton onClick={onDelete}>
          <DeleteForever />
        </IconButton>
      </div>
      <Divider />
      <Dialog
        fullWidth
        // disableBackdropClick
        onClose={onClose}
        aria-labelledby={"Add Notification"}
        open={open}
        {...props}
      >
        <DialogTitleWithExit onClose={onClose}>
          Edit Notification
        </DialogTitleWithExit>
        <DialogContent>
          <NotificationForm
            onSubmit={onSubmit}
            action={props.action}
            notification={props.notification}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
