import React from "react";
import { AuthenticationContext } from "../../AuthenticationProvider";
import { CircularProgress } from "@material-ui/core";

interface ISignInRedirectCallbackProps {}

export const SilentRenew = (props: ISignInRedirectCallbackProps) => {
  const { signinSilentCallback } = React.useContext(AuthenticationContext);
  
  React.useEffect(() => {
    signinSilentCallback()
  }, [signinSilentCallback])

  return <CircularProgress />;
};
