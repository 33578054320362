import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { CodeModal } from "..";
import "./CodeResult.scss";
import { HistoryModal } from "./History/HistoryModal";
import { TitleHistoryModal } from "./TitleHistory/TitleHistoryModal";

interface ICodeModalButtonProps {
  title?: string;
  type?: string;
  segment?: any;
}

export const CodeModalButton = (props: ICodeModalButtonProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState<boolean>(false);
  const [isTitleHistoryOpen, setIsTitleHistoryOpen] = useState<boolean>(false);

  const openCodeModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  }

  const toggleSegmentHistory = () => {
    isHistoryOpen ? setIsHistoryOpen(false) : setIsHistoryOpen(true);
  }

  const toggleTitleHistory = () => {
    isTitleHistoryOpen ? setIsTitleHistoryOpen(false) : setIsTitleHistoryOpen(true);
  }

  return (
    <div>
      <HistoryModal open={isHistoryOpen} segment={props.segment ?? null} onClose={toggleSegmentHistory} />
      <TitleHistoryModal open={isTitleHistoryOpen} referenceCode={props.title ?? null} onClose={toggleTitleHistory} />
      <CodeModal open={modalOpen} segment={props.segment} title={props.title} onClose={openCodeModal} />
      <Button
        look="flat"
        icon="file"
        style={{ color: "blue" }}
        onClick={openCodeModal}
      >
        View {props.type ? props.type : "Title"}
      </Button>
      {props.segment ?
        (
          <Button
            look="flat"
            icon="book"
            // style={{ color: "green" }}
            disabled={false}
            value={props.segment?.id ?? -1}
            onClick={toggleSegmentHistory}
          >
            View History
          </Button>
        )
        : (
          <Button
            look="flat"
            icon="calendar"
            style={{ color: "orange" }}
            onClick={toggleTitleHistory}
          >
            View Title History
          </Button>
        )}

      {/* TODO: show button to subscribe on chapters only */}
      {/* {props.segment.name == "Chapter" || props.segment.name == "Title" ? (
        <div>Subscribe</div>
      ) : null} */}
    </div>
  );
};


