import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { RulesAffectedIndexItem } from "../model/RulesAffectedIndexItem";

class RulesAffectedIndexApi extends BaseAPI<RulesAffectedIndexItem> {
    constructor() {
      super("RulesAffectedIndex", APISettings.adminRulesApiUrl);
    }

    public getRaiForCurrentRegister: (registerId: number) => Promise<RulesAffectedIndexItem[]> = async (registerId: number) => {
        var result = (await BaseAPI.axios.get(`${this.apiUrl}/GetForCurrentRegister/${registerId}`));
        return result.data;
    }
}

export const rulesAffectedIndexAPI = new RulesAffectedIndexApi();