import React, { useState, useEffect, Fragment } from "react";
import { FileUploadDialog } from "../FileUploadDialog";
import "./ImageWrapper.scss";
import { CmsItem } from "../../utils/model/CmsItem";
import { cmsItemAPI } from "../../utils/api";
import { StorageContainer } from "../../utils/enums";

import { Image } from "./../Image";
import { CmsType } from "../../utils/enums";

interface IImageWrapperProps {
  className: string;
  name: string;
  alt: string;
  storageContainer: StorageContainer;
}

export const ImageWrapper = (props: IImageWrapperProps) => {
  const initialCmsItem = new CmsItem(props.name, "placeholder", CmsType.Image);

  const [cmsItem, setCmsItem] = useState(initialCmsItem);
  const [refreshImage, setRefreshImage] = useState(false);

  const getCmsImage = React.useCallback(async () => {
    let cmsImage = await cmsItemAPI.getCmsItemsByName(props.name);
    if (cmsImage == null) {
      cmsImage = new CmsItem(props.name, "", CmsType.Image);
    }
    setCmsItem(cmsImage);
  }, [props.name]);

  useEffect(() => {
    getCmsImage();
  }, [getCmsImage]);

  const onImageChange = (name: string) => {
    const reloadItem = cmsItem;
    reloadItem.name = name;
    setCmsItem(reloadItem);
    setRefreshImage(true);
    setTimeout(() => {
      setRefreshImage(false);
    }, 200);
  };

  return (
    <Fragment>
      <div>
        {cmsItem.id ? cmsItem.name === 'logo' ? (
          <a href={`${window.location.origin}/home`}>
            <Image
              className={props.className}
              source={cmsItem.value}
              label={props.name}
              refresh={refreshImage}
              alt={props.alt}
              storageContainer={props.storageContainer}
            />
          </a>
        ) : (
          <Image
            className={props.className}
            source={cmsItem.value}
            label={props.name}
            refresh={refreshImage}
            alt={props.alt}
            storageContainer={props.storageContainer}
          />
        ) : null}
        <FileUploadDialog cmsItem={cmsItem} onChange={onImageChange} />
      </div>
    </Fragment>
  );
};
