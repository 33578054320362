import {
  Chip,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Tooltip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import "./ChipStringListInput.scss";

interface IChipStringListInputProps {
  commaDelimitatedString: string;
  onChange: (event: string) => void;
  label: string;
}

const HOTKEYS: string[] = ["Enter", ";"];

export const ChipStringListInput = (props: IChipStringListInputProps) => {
  const {  commaDelimitatedString } = props;
  useEffect(() => {
    if (commaDelimitatedString != "") {
      setList(commaDelimitatedString.split(","));
    }
  }, [commaDelimitatedString]);

  const [inputString, setInputString] = useState<string>("");

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (HOTKEYS.indexOf(key) != -1) {
      event.preventDefault();
      handleAdd();
    }
  };
  const onInputStringChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    for (let index = 0; index < HOTKEYS.length; index++) {
      const item = HOTKEYS[index];
      if (value.includes(item)) {
        return;
      }
    }
    setInputString(value);
  };

  const [list, setList] = useState<string[]>([]);
  const handleDelete = (item: string) => {
    const localList = [...list];
    const index = localList.indexOf(item);

    if (index != -1) {
      localList.splice(index, 1);

      onChange(localList);
    }
  };
  const handleAdd = () => {
    const index = list.indexOf(inputString);
    if (index === -1) {
      const localList = [...list];
      localList.push(inputString);
      onChange(localList);
      setInputString("");
    }
  };
  const onChange = (localList: string[]) => {
    setList(localList);
    props.onChange(localList.join(","));
  };

  const onClick = () => {
    handleAdd();
  };
  return (
    <div className="chip-string-list-input">
      <div className="chip-list-container">
        {list.map((item, index) => (
          <div className="string-chip" key={index+item}>
            <Chip
              label={item}
              icon={<EmailIcon />}
              onDelete={() => {
                handleDelete(item);
              }}
              color="primary"
            />
          </div>
        ))}
      </div>
      <FormControl>
        <InputLabel id={`name-${props.label.toLowerCase()}`}>
          {props.label}
        </InputLabel>
        <Input
          onChange={onInputStringChange}
          onKeyUpCapture={onKeyUp}
          value={inputString}
        ></Input>
      </FormControl>
      <IconButton
        disableTouchRipple={true}
        className={`add-email`}
        onClick={onClick}
        color="primary"
      >
        <Tooltip title={`add ${props.label}`} placement="bottom">
          <AddIcon />
        </Tooltip>
      </IconButton>
    </div>
  );
};
