import React, { useEffect } from "react";
import "./DeleteRowModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { Divider } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  ruleAPI,
  owningAPI,
  contactTypeAPI,
  addressAPI,
  contactAPI,
} from "../../../../../../../utils/api";
import { Contact } from "../../../../../../../utils/model";

interface IContactTypeModalProps {
  open: boolean;
  deleteRow?: any;
  type: string;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}

export const DeleteRowModal = (props: IContactTypeModalProps) => {
  const { open, handleClickOpen, handleClose } = props;
  const [deleteId, setDeleteId] = React.useState(-1);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackSuccessOpen, setSnackSuccessOpen] = React.useState(false);
  const [linked, setLinked] = React.useState("");

  // ###############################################
  // Set the row data if edit button clicked
  // ###############################################
  useEffect(() => {
    if (props.open === false) {
      setDeleteId(-1);
    } else {
      if (props.deleteRow.id !== undefined) {
        setDeleteId(props.deleteRow.id);
      }
    }
  }, [props.deleteRow, props.handleClickOpen, props.open]);

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const saveForm = async (event: any) => {
    if (deleteId !== -1) {
      if (props.type === "contact") {
        try {
          let owning = await owningAPI.getAll();
          owning.forEach((element) => {
            if (element.contact.id === props.deleteRow.id) {
              setLinked("owning entry " + element.name + " ");
              setSnackOpen(true);
            }
            return;
          });

          // delete contact
          await contactAPI.delete(props.deleteRow.id);
          await addressAPI.delete(props.deleteRow.address.id);
          setSnackSuccessOpen(true);
        } catch (err) {
          console.error(err);
        }
      } else if (props.type === "Titles") {
        try {
          //delete rule
          await ruleAPI.delete(props.deleteRow.id);
          setSnackSuccessOpen(true);
        } catch (err) {
          console.error(err);
        }
      } else if (props.type === "owning") {
        try {
          //delete owning entry
          await owningAPI.delete(props.deleteRow.id);
          setSnackSuccessOpen(true);
        } catch (err) {
          console.error(err);
        }
      } else if (props.type === "contact type") {
        try {
          //delete contact type
          let contacts: any = await contactAPI.getAllContacts();
          let contactList: Contact[] = contacts.result;
          contactList.forEach((element) => {
            if (element.id === props.deleteRow.id) {
              setLinked(
                "contact " +
                  element.firstName.toString() +
                  " " +
                  element.lastName.toString()
              );
              setSnackOpen(true);
            }
          });
          await contactTypeAPI.delete(props.deleteRow.id);
          setSnackSuccessOpen(true);
        } catch (err) {
          console.error(err);
        }
      }
      handleClose(event);
    }
  };

  return (
    <div onClick={handleClickOpen}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">
          Unable to delete this record. please delete {linked} first.
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackSuccessOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Record Deleted Correctly</Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Delete
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div>Are you sure you want to delete this record?</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(event) => {
              saveForm(event);
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
