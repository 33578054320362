import React, { PropsWithChildren, useEffect } from 'react'
import ReactGa from 'react-ga4';
import { useLocation } from 'react-router';

interface IWrapperProps {
  initialized: boolean,
  children: PropsWithChildren<any>;
}

const Wrapper = (props: IWrapperProps) => {
  const { children, initialized } = props;
  const location = useLocation();

  useEffect(() => {
    if (props.initialized) {
      ReactGa.send({ hitType: "pageview", pagePath: location.pathname })
    }
  }, [initialized, location])

  return children;
}

export default Wrapper