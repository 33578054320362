
export enum DelayActions {
    AdminRulesSearch,
};

export abstract class DelayAction {
    private static delayPromises: {[key: string]: Promise<void>} = {};

    /**
     * Simple method that resolves a promise if another request is NOT made within
     *  the 'minimumDelayTime' with the same 'delayName'.
     * @param delayName Name of the action being performed
     * @param minimumDelayTime How many ms before attempting to resolve action
     */
    public static createDelay = (delayName: string | number, minimumDelayTime: number = 500): Promise<void> => {

        const tempPromise = new Promise<void>((resolve) => {
            setTimeout(() => {
                if (tempPromise === DelayAction.delayPromises[delayName]) {
                    resolve();
                    delete DelayAction.delayPromises[delayName];
                }
            }, minimumDelayTime);
        });
        DelayAction.delayPromises[delayName] = tempPromise;
        return tempPromise;
    };
}
