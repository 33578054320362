export enum ActionTypes {
    Accept = 1,
    Add = 2,
    Approve = 4,
    Cancel = 16,
    Codify = 5,
    Complete = 6,
    MarkForPublication = 7,
    Publish = 9,
    Reject = 10,
    Review = 11,
    Rework = 12,
    SetEffectiveDate = 13,
    Submit = 14,
    SubmitForAgencyReview = 3,
    SubmitForOarReview = 8
}