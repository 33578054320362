import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

interface IResultsAppBar {
  resultCount: number;
}

export const ResultsAppBar = (props: IResultsAppBar) => {
  const { resultCount } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        style={{
          backgroundColor: "#dbdbdb",
          color: "black",
          marginTop: "1em",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
        position="static"
      >
        <Toolbar>
          <h2 className="shrunk-h2">Results({resultCount})</h2>
        </Toolbar>
      </AppBar>
    </div>
  );
};
