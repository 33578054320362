import { Button } from '@material-ui/core'
import { Card, CardBody, CardTitle, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout'
import UnsubscribeIcon from "@material-ui/icons/Unsubscribe";
import React from 'react'
import { useSubscriptionDetailController } from './SubscriptionDetailController';

interface ISubscriptionDetailsProps {
  userInfo: any;
}

const SubscriptionDetails = (props: ISubscriptionDetailsProps) => {
  const { userInfo } = props;

  const {
    // functions
    unsubscribeAllItems,
    unsubscribeItem,
    // state
    userTitles,
    userChapters,
  } = useSubscriptionDetailController(userInfo);

  return (
    <Card className="card-container">
      <CardBody>
        <CardTitle
          style={{
            textAlign: "center",
            color: "cornflowerblue",
            marginBottom: "1em",
          }}
        >
          Manage Your Subscriptions
        </CardTitle>
        <GridLayout
          rows={[{ height: "auto" }, { height: "100%" }]}
          cols={[
            { width: "auto" },
            { width: "auto" },
            { width: "auto" },
            { width: "auto" },
            // {width: "10%"}
          ]}
        //   gap={{ rows: 5, cols: 10 }}
        >
          <GridLayoutItem row={1} col={1} colSpan={2}>
            <h3>Subscribed Titles</h3>

            <hr />
            <Button
              startIcon={<UnsubscribeIcon />}
              color="primary"
              onClick={() => unsubscribeAllItems("Title")}
            >
              unsubscribe all titles
            </Button>
            {userTitles ? (
              <ul>
                {userTitles.map((title: any, index: number) => (
                  <div>
                    <li key={index}>
                      <span>
                        <Button
                          color="secondary"
                          onClick={() =>
                            unsubscribeItem(
                              title.subscription
                            )
                          }
                        >
                          unsubscribe
                        </Button>
                      </span>
                      {"Title " +
                        title.rule.referenceCode +
                        ". " +
                        title.rule.title}
                    </li>
                  </div>
                ))}
              </ul>
            ) : (
              <div></div>
            )}
          </GridLayoutItem>

          <GridLayoutItem row={1} col={3} colSpan={2}>
            <h3>Subscribed Chapters</h3>
            <hr />
            <Button
              startIcon={<UnsubscribeIcon />}
              color="primary"
              onClick={() => unsubscribeAllItems("Chapter")}
            >
              unsubscribe all chapters
            </Button>
            {userChapters ? (
              <ul>
                {userChapters.map((chapter: any, index: number) => (
                  <div>
                    <li key={index}>
                      <span>
                        <Button
                          color="secondary"
                          onClick={() =>
                            unsubscribeItem(
                              chapter.subscription
                            )
                          }
                        >
                          unsubscribe
                        </Button>
                      </span>
                      {"Title " + chapter.chapter.titleNum + ". " + " Chapter " +
                        chapter.chapter.chapterNum +
                        ". " +
                        chapter.chapter.description}
                    </li>
                  </div>
                ))}
              </ul>
            ) : (
              <div></div>
            )}
          </GridLayoutItem>
        </GridLayout>
      </CardBody>
    </Card>
  )
}

export default SubscriptionDetails