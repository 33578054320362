import './NotFound';

interface INotFoundProps {

}

export const NotFound = (props: INotFoundProps) => {

    return (
        <div className="not-found">
            404 page not found
        </div>
    );
}



