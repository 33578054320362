import { Step, StepLabel, Stepper, Typography, FormLabel, ButtonGroup } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect } from "react";
import { actionAPI, filingTypeAPI, statusAPI, filingAPI } from "../../../../utils/api";
import { Filing, Action, Status } from "../../../../utils/model";
import { ActionTypes, StatusTypes } from "../../../../utils/enums";
import "./ActionTab.scss";
import { ActionButton } from ".";
import { RejectReason } from ".";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from '@material-ui/core/Button';

interface IMainTabProps {
  filing: Filing;
  saveForm: (value: any, type: string) => void;
  triggerReload: () => void;
}

export interface FormImperativeHandles {
  getValues(): any;
  getType(): string;
}

export let ActionTab = React.forwardRef((props: IMainTabProps, ref) => {
  const [filingType, setFilingType] = React.useState<any | null>(null);
  const [actions, setActions] = React.useState<any | null>(null);
  const [targetStatusId, setTargetStatusId] = React.useState<number>();
  const [steps, setSteps] = React.useState(['Step 1', 'Step 2', 'Step 3']);
  const [activeStep, setActiveStep] = React.useState(0);
  const [actionTypeId, setActionTypeId] = React.useState<number | null>();
  const [statusTypeId, setStatusTypeId] = React.useState<number | null>();
  const [rejectReason, setRejectReason] = React.useState<string | undefined>(props.filing.rejectReason);
  const [cancelFilingSnackIsVisible, setCancelFilingSnackIsVisible] = React.useState(false);
  const [workflowActionResultMessage, setWorkflowActionResultMessage] = React.useState<string | undefined>();
  const [workflowActionResultSnackIsVisible, setWorkflowActionResultSnackIsVisible] = React.useState(false);

  const setupFilingType = React.useCallback(async () => {
    try {
      // We need to be careful with this to actually select the liaison officers
      let value:any = await filingTypeAPI.getFilingTypeById(props.filing.filingTypeId);
      setFilingType(value);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setupActions = React.useCallback(async () => {
     try {
      setActionTypeId(null);

      let _filing = await filingAPI.getFilingsById(props.filing.id!);
      let statusId: number = _filing.statusId;
      let actionList: any = await actionAPI.getActionsByStatusId(statusId, props.filing.entity!.rule.referenceCode.toString(), props.filing.id!);

      setStatusTypeId(_filing.status?.statusTypeId);
      setActions(actionList);
     } catch (err) {
    }
   }, [targetStatusId]);

  const setTargetStatus = async (_targetStatusId: number) => {
    setTargetStatusId(_targetStatusId);
  };

  const _setActionTypeId = (_actionTypeId: number) => {
    setActionTypeId(_actionTypeId);
  }

  const setupStatus = React.useCallback(async () => {
    try {
      let _filing = await filingAPI.getFilingsById(props.filing.id!);
      let value: any = await filingTypeAPI.getFilingTypeById(_filing.filingTypeId);
      let statusList: any = await statusAPI.getStatusesByWorkflowId(value.workflowId);
      let stepList: any = [];
      let index = statusList.result.findIndex((x:any) => x.id === _filing.statusId);

      statusList.result.forEach((value:any, index:number) => {
        stepList.push({name: value.name, description: value.description});
      })

      setStatusTypeId(_filing.status?.statusTypeId);
      setFilingType(value);
      setActiveStep(index);
      setSteps(stepList);
    } catch (err) {
      console.error(err);
    }
  },[props.filing]);

  const _rerenderActions = (targetStatusTypeId?: number | null) => {
    let actionTypeId: any; // = targetStatusTypeId == StatusTypes.Reworked ? ActionTypes.Rework : null;
    switch (targetStatusTypeId) {
      case StatusTypes.Reworked:
          actionTypeId = ActionTypes.Rework;
        break;
      case StatusTypes.OarReview:
          actionTypeId = ActionTypes.SubmitForAgencyReview;
      default:
         actionTypeId = null;
        break;
    }

    setActionTypeId(actionTypeId);
    setupActions();
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    setCancelFilingSnackIsVisible(false);
  };

  const _renderActions = () => {
    if(filingType != null) {
      if(actions != null) {
        if (statusTypeId != StatusTypes.Reworked && actionTypeId != ActionTypes.Review && ((actionTypeId == ActionTypes.Rework && statusTypeId !=  StatusTypes.AgencyReview)|| actionTypeId == ActionTypes.Reject || actionTypeId === ActionTypes.SubmitForAgencyReview)) {
          return (
            <RejectReason
              filing={props.filing}
              actions={actions}
              actionTypeId={actionTypeId}
              rerenderActions={_rerenderActions}
              rerenderSteps={setupStatus}
              setStatusType={setStatusTypeId}
              setRejectReason={setRejectReason} />
          );
        }

        return (
          <div className="action-items">
              {actions.map((item: Action, key: number) => {
                return (
                  <ActionButton
                    key={key}
                    action={item}
                    filing={props.filing}
                    triggerReload={props.triggerReload}
                    setTargetStatus={setTargetStatus}
                    setActionTypeId={_setActionTypeId}
                    rerenderActions={setupActions}
                    setCancelFilingSnackVisibility={setCancelFilingSnackIsVisible}
                    setWorkflowActionResultSnackVisibility={setWorkflowActionResultSnackIsVisible}
                    setWorkflowActionResultMessage={setWorkflowActionResultMessage} />
                )
              })}
          </div>
              );
      } else {
        return (<div className="action-items">No Actions Available.</div>)
      }
    }
  };

  const cancelFiling = async () => {
    let value: any = await filingAPI.cancelFiling(props.filing.id ?? 0);

    setupStatus();
    setupActions();
    setCancelFilingSnackIsVisible(false);
  };

  const _renderStepper = () => {
    if(filingType != null) {
      return (
        <Stepper activeStep={activeStep} alternativeLabel={true} nonLinear={true}>
          {steps.map((item:any, index) => {
          const stepProps = {};
          return (
            <Tooltip key={item.name} title={item.description ? item.description : ""} aria-label="add" placement="top">
            <Step {...stepProps}>
              <StepLabel>{item.name}</StepLabel>
            </Step>
            </Tooltip>
          );
        })}
        </Stepper>
      );
    }
  };

  useEffect(() => {
    setupFilingType();
    setupStatus();
    setupActions();
  }, [props.filing]);

  return (
    <div className="action-tab">
      <div>
        <Typography>Current Status</Typography>
      </div>
      { _renderStepper()}
      <div>
        { <Typography>Available Actions</Typography> }
      </div>
      <div>
        { _renderActions() }
      </div>
      <div>
      {
          statusTypeId == StatusTypes.Rejected
          || statusTypeId == StatusTypes.Reworked
          || (statusTypeId === StatusTypes.OarReview  && actionTypeId === ActionTypes.SubmitForAgencyReview)
          ?
          (
            <div className="reject-reason-wrapper">
              <div className="reject-reason-title">
                <Typography>Reject Reason</Typography>
              </div>
              <div className="reject-reason-reason">
                {rejectReason}
              </div>
            </div>
          ) : null
        }
      </div>
      <Snackbar
        open={cancelFilingSnackIsVisible}
        onClose={() => { setCancelFilingSnackIsVisible(false); }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <div>
              <Button
                color="inherit" size="small"
                onClick={cancelFiling}>
                  YES
              </Button>
              <Button
                color="inherit"
                size="small"
                onClick={() => { setCancelFilingSnackIsVisible(false); }}>
                  NO
              </Button>
            </div>
          }>
          Are you sure you want to cancel this filing?
        </Alert>
      </Snackbar>
      <Snackbar
      open={workflowActionResultSnackIsVisible}
      onClose={() => { setWorkflowActionResultSnackIsVisible(false); }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }} >
        <Alert severity="warning">{ workflowActionResultMessage }</Alert>
      </Snackbar>
    </div>
  );
});
