import { CircularProgress } from '@material-ui/core';
import { Dialog } from '../../../../../../components/Dialog'
import { Paragraph } from '../../../../../../components/Paragraph';
import { useHistoryController } from './HistoryController'
import "./HistoryModal.scss";
import { Segment } from '../../../../../../utils/model';
import { Button } from '@progress/kendo-react-buttons';
import { savePDF } from "@progress/kendo-react-pdf";
import { useRef } from 'react';
import { NoteType } from '../../../../../../utils/enums';

interface IHistoryProps {
  open: boolean;
  segment: any | null;
  onClose: () => void;
}

/**
 *
 * @param open boolean - determins if dialog is visible
 * @param segment Segment - used to fetch all segment history
 * @param onClose ()=>void - function to toggle open true/false state for closing the dialog
 * @returns
 */
export const HistoryModal = (props: IHistoryProps) => {
  const { open, segment, onClose } = props;
  const {
    // functions
    getSegmentTypeNumber,
    getSegmentStatus,
    // variables
    historyItems,
    loading,
  } = useHistoryController(segment, open);


  function printDiv() {
        var divContents = document.getElementById("print-container")!.innerHTML;
        var printWindow: any = window.open("", "", "");
        printWindow.document.write(`<html><head><style type="text/css">
        @media print{

            .hide {
              visibility: hidden;
              margin-top: -50px;
            }
            .code-label {
                margin-top: 20px;
                margin-bottom: 20px;
                display:flex;
                font-size:12pt;
                justify-content: center;
                font-family:"Times New Roman";
            }
            .code-content {
                margin-top:20px;
                margin-bottom:20px;
                font-size:12pt;
                font-family:"Times New Roman";
            }
        }
        .code-label {
            display:flex;
            font-size:12pt;
            justify-content: center;
            font-family:"Times New Roman";
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .code-content {
            margin-top:20px;
            margin-bottom:20px;
            font-size:12pt;
            font-family:"Times New Roman";
        }
        .segment-note {
            margin-left: 1rem;
        }

        .inline-note * {
            font-size: 9pt !important;
            font-family: "Times New Roman", Times, serif !important;
            display: inline-block;
        }

        .inline-note {
            margin-top: 0 !important;
            font-size: 9pt !important;
        }

        .inline-note-text {
            margin-top: 20px;
            margin-bottom: 0px;
            font-size: 9pt !important;
        }

        .inline-note-text > * {
            display: inline;
            font-size: 9pt !important;
        }

        .inline-note-text > * > * {
            display: inline;
            font-size: 9pt !important;
        }

        .segment-note * {
            font-size: 9pt !important;
            font-family: "Times New Roman", Times, serif !important;
            display: inline-block;
        }
        .Default {
            margin: 0;
        }
        img {
            width: 100% !important;
        }
        </style><title>${props.segment ? props.segment.sectionNum : "PDF"}</title>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }


  let container: any = useRef<HTMLDivElement>(null);
  const downloadHistory = async (event: any) => {
    let element = container.current;
    savePDF(
      element,
      {
        paperSize: "a4",
        margin: 40,
        fileName: `${event.target.value + ".pdf"}`,
      },
    );
  };

  /**
   *
   * @param segment accepts in a segment to get all segment notes
   * @returns React.tsx to render
   */
  const renderSegmentNotes = (segment: any) => {
    if (!segment.segmentNotes && segment.segmentNotes.length > 0) {
      return '';
    }


    let notes = segment.segmentNotes;
    let textBody: any;
    let editorsNotes: any = [];
    let sourceNotes: any = [];
    let authorityNotes: any = [];
    let footNotes: any = [];
    let agencyNotes: any = [];

    // // Order in which note types should appear
    // //  1. Footnote
    // //  2. Source
    // //  3. Editors
    // //  4. Agency
    // //  5. Authority

    notes.forEach((note: any, index: number) => {
      //   var found = null;
      //   found = noteType?.find((a:any) => a == note.noteTypeId);
      //   if(found) {
      if (note.noteTypeId == NoteType.Source) {
        sourceNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Editors) {
        editorsNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Authority) {
        authorityNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        )
      } else if (note.noteTypeId == NoteType.Agency) {
        agencyNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      }
      //   }
    })

    textBody = (
      <div>
        {footNotes.length > 0 ? (
          <div className="note">
            {footNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {sourceNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Source: </b>{sourceNotes.map((item: any, index: number) => { if (item != "") return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}]</span>
          </div>
        ) : null}
        {editorsNotes.length > 0 ? (
          <div className="note">
            {editorsNotes.map((item: any, index: number) => { if (item != "") return (<div className="inline-note-text"><i><strong>EDITOR'S NOTE: </strong>{item}</i>{index + 1 < editorsNotes.length ? (<span>,&nbsp;</span>) : null}</div>) })}
          </div>
        ) : null}
        {agencyNotes.length > 0 ? (
          <div className="note">
            {agencyNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < agencyNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {authorityNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]</span>
          </div>
        ) : null}
      </div>
    )

    return textBody;
  };

  return (
    <Dialog title="History" open={open} onClose={onClose} className="history-modal">
      <>
        {loading ? (<div className="history-loading"><CircularProgress /> Loading</div>) : null}
        {historyItems ? (
          <div className="history-dialog-content">
            {historyItems.map((history: any, index: number) => {
              return (
                <div id="print-container">
                  <div className="banner">
                    <div className='hide'>
                      {history.originalText ? (
                        <Button
                          key={history.originalDescription + index}
                          icon="pdf"
                          style={{ color: "#034d9a" }}
                          disabled={false}
                          value={history.originalDescription + "-" + new Date(history.codifiedOn).toLocaleDateString()}
                          onClick={printDiv}
                        >
                          Download PDF
                        </Button>
                      ) : null}
                      <div><b>Description:</b> {history.originalDescription}</div>
                      <div>
                        <b>{segment ? segment?.name! : "No type found"}.</b> {getSegmentTypeNumber(history, false)}
                      </div>
                      <div>
                        <b>Date:</b> {new Date(history.codifiedOn).toLocaleDateString()}
                      </div>
                      <div>
                        <b>Status:</b> {getSegmentStatus(history)}
                      </div>
                    </div>
                  </div>
                  <div  ref={container}>
                    <div className='hide'><b>
                      {getSegmentTypeNumber(history, true)}. {history.originalDescription}{(history: any) => getSegmentStatus(history)}
                    </b></div>
                    <Paragraph value={history.originalText ?? ''} className="history-text" />
                    {renderSegmentNotes(segment ? segment : null)}
                  </div>
                </div>)
            })}
          </div>
        ) : (
          <div>No historical records found</div>
        )}
      </>
    </Dialog >
  )
}
