import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import { Contact, Docket, Filing, FilingType, Rule } from "../../../../../../utils/model";
import { Row, Select, TextField } from "./";
import "./../FilingAddForm.scss";
import { EditorType } from "../../../../../../utils/enums";
import "./../FilingAddForm.scss";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";

interface IGovDeclarationsFormProps {
  attestationList: Contact[];
  docketList: Docket[];
  filing?: Filing;
  filingType: FilingType;
  // liaisonList: Contact[];
  ruleList: Rule[];
  formatDate: (date: any) => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderRuleDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  saveForm: (item: any, type: string) => void;
  returnContactListAsync: (ruleId:number,isAttestation:boolean) => any;
}

const getInitialState = (props: IGovDeclarationsFormProps) => ({
  chapter: props.ruleList[0],
  chapterId: -1,
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  draftId: 0,
  effectiveDate: new Date(),
  filingName: "",
  formName: "GD",
  filingType: "Governor Declarations",
  governorDeclarationsDocument: [{ guid: "", filename: "" }],
  liaison: Contact,
  liaisonId: 0,
  rule: props.ruleList[0],
  ruleId: -1,
  synopsis: "",
  text: ""
});

export let GovernorDeclarationsForm = React.forwardRef((props: IGovDeclarationsFormProps, ref) => {
  const [formValues, handleChange] = useFormState({ ...getInitialState(props), ...props.filing?.entity }, ref);

  const [ruleContactList, setRuleContactList]: any = React.useState([]);
  const [attestationContactList, setAttestationContactList]: any = React.useState([]);
  const [text, setText] = React.useState<string>(formValues.text);
  const [chapterList, setChapterList]: any = React.useState([]);
  const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
  const [helperText, setHelperText] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>(false);
  const nameErrorMessage: string = "Filing Name Required";

  const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value as string;
    let isError: boolean = value.trim().length == 0;
    let errorMessage: string = isError ? nameErrorMessage : '';

    setIsError(isError);
    setHelperText(errorMessage);
    handleChange(e, "filingName", value);
  };

  const currId = uuid();
  const setChapterListFromRuleId = React.useCallback(async (ruleId: number) => {
    const chapters = await props.returnChapterListAsync(ruleId);
    setChapterList(chapters);
  },
    [props]
  );

  const setLiaisonListFromRuleId = React.useCallback(async(ruleId:number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    },[props]
  );

  const setAttestationListFromRuleId = React.useCallback(async(ruleId:number) => {
      const attestation = await props.returnContactListAsync(ruleId, true);
      setAttestationContactList(attestation);
    },[props]
  );

  useEffect(() => {
    if (!formValues.rule && !props.filing) {
      formValues.rule = props.ruleList[0];
      formValues.ruleId = props.ruleList[0]?.id;
      setLiaisonListFromRuleId(props.ruleList[0]?.id!);
      setAttestationListFromRuleId(props.ruleList[0]?.id!);
    } else if (props.filing) {
      if (props.filing?.isReadOnly) {
        setReadOnly(props.filing.isReadOnly);
      }
      let entity = Object.create(props.filing!.entity!);
      setChapterListFromRuleId(entity.rule.segmentId);
      setLiaisonListFromRuleId(entity.rule.id);
      setAttestationListFromRuleId(entity.rule.id);
    }
  }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

  const handleRuleSelectChange = async (event: React.ChangeEvent<HTMLInputElement>, name: string, rawValue: string | null) => {
    const value = rawValue as keyof typeof Rule;
    if (value != null) {
      let rule = props.ruleList.find((i: any) => i.id === rawValue);
      if (rule != null) {
        handleChange(event, "rule", rule);
        handleChange(event, "ruleId", rule.id);
        setChapterListFromRuleId(rule.segmentId!);
        setLiaisonListFromRuleId(rule.id!);
        setAttestationListFromRuleId(rule.id!);
      }
    }
  };

  const textChange = (value: string) => {
    formValues.text = value;
  };

  return (
    <FormProvider values={formValues} onChange={handleChange}>
      <div className="flex-column governor-declaration-form">
        <Typography variant="h6">Governor's Declaration</Typography>
        <TextField
          id={`name-text-field${currId}`}
          label="FILING NAME"
          name="filingName"
          placeholder="Filing Name (REQUIRED)"
          disabled={readOnly}
          onChange={handleFilingNameChange}
          error={isError}
          helperText={helperText}
          variant="outlined"
          aria-label="filing name"
          autoFocus
        />
        <Row className="rule-title-row">
          <Select
            className="drop-down"
            id={`name-text-field${currId}`}
            label="TITLE"
            name="ruleId"
            value={formValues.ruleId}
            onChange={handleRuleSelectChange}
            disabled={true}
          >
            {props.renderRuleDropdown}
          </Select>
        </Row>
        <TextField
          name="synopsis"
          label="SYNOPSIS"
          multiline
          rows="5"
          fullWidth
          disabled={readOnly}
        />
        <RichTextEditor
          text={text}
          editorType={EditorType.segment}
          onTextChange={textChange}
          height={"50vh"}
          width={"auto"}
          disable={readOnly}
        />
      </div>
    </FormProvider>
  );
}
);
