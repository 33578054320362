import React from "react";
import "./WorkQueueLinks.scss";
import { Card, Grow, Paper, Typography } from "@material-ui/core";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { TextWrapper } from "../../../../../components/TextWrapper";
import { EditorType } from "../../../../../utils/enums";

interface IWorkQueueLinksProps {}

export const WorkQueueLinks = (props: IWorkQueueLinksProps) => {
  return (
    <Grow in={true}>
      <Paper id="links" className="display-pane-right">
        <Typography>Links</Typography>
        <Card className="my-work-info">
          <TextWrapper
            editorType={EditorType.paragraph}
            name="myWorkLinks"
            className="mywork-links"
          />
        </Card>
      </Paper>
    </Grow>
  );
};
