import React, { useEffect } from "react";
import { AlertDialog } from "../../../../../../../components";
import { Table } from "../../../../../../../components/Table";
import {
  contactAPI,
  contactTypeAPI,
  owningAPI,
  ruleAPI,
} from "../../../../../../../utils/api";
import { Contact, ContactType } from "../../../../../../../utils/model";
import { StateOfficeNavigationSettings } from "../../../../../../../utils/settings";
import { AttestationModal } from "../AttestationModal";
import { ContactModal } from "../ContactModal";
import { ContactTypeModal } from "../ContactTypeModal";
import { DeleteRowModal } from "../DeleteRowModal";
import { OwningModal } from "../OwningModal";
import { RuleModal } from "../RuleModal";
import "./GenericTable.scss";
import {
  setContactRows,
  setContactTypeRows,
  setOwningRows,
  setRuleRows,
} from "./TableSetup";

interface IGenericTableProps {
  modalType: "owning" | "contact" | string | "contact type" | "attestation";
  contactType?: ContactType;
}

// #######################################################
// Define Generic table
// #######################################################
export const GenericTable = (props: IGenericTableProps) => {
  const { OwningTitlePlural, RuleTitlePlural } = StateOfficeNavigationSettings;
  const [filtering, setFiltering] = React.useState(false);
  const [rows, setRowData]: any = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tableMessage, setTableMessage] = React.useState("Loading...");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [columns, setColumnsSource]: any = React.useState([]);
  const [numberOfRecords, setNumberOfRecords]:any = React.useState(20);

  // Set this up as an interceptor for our loading logic
  const setColumns = (columns: []) => {
    setColumnsSource(columns);
    setIsLoading(false);
    setTableMessage("No data to display");
  };

  // #######################################################
  // Toggle filter options on table
  // #######################################################
  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setFiltering(true);
    }
  };

  const getNextPage = async(pageData:any) => {
    let page = ((pageData.skip + pageData.take) / pageData.take);
    let pageSize = Math.abs(pageData.take);
    let pageValues:any;
    let currentData:any = [];

    switch (props.modalType) {
        case "RuleTitlePlural":
            pageValues = await ruleAPI.getAllRules({pageNumber: page, pageSize: pageSize},false);
            currentData.length = numberOfRecords;
            currentData.fill({},0,numberOfRecords);
            rows.forEach((element:any, i:number) => {
              currentData[i] = element;
            })
            pageValues.result.forEach((element:any, i:number) => {
                currentData[i+pageData.skip] = element;
            });
            setRowData(currentData);
            return(currentData);
        case "contact":
            if(props.contactType!.id) {
              pageValues = await contactAPI.getAllContactsByType(props.contactType!.id, {pageNumber: page, pageSize: pageSize || 20});
              currentData.length = numberOfRecords;
              currentData.fill({},0,numberOfRecords);
              rows.forEach((element:any, i:number) => {
                currentData[i] = element;
              })
              pageValues.result.forEach((element:any, i:number) => {
                  currentData[i+pageData.skip] = element;
              });
              setRowData(currentData);
            }
            return(currentData);
    }
    return rows;
  }

  const onMount = React.useCallback(async () => {
    try {
      if (props.modalType === "contact") {
        if (props.contactType!.id) {
          let contactCount = await contactAPI.getContactCountByType(props.contactType!.id);
          let contacts: any = await contactAPI.getAllContactsByType(
            props.contactType!.id,
            {pageNumber: 1, pageSize: 20}
          );
          let contactList: Contact[] = contacts.result;
          contactList.forEach((element) => {
            if (props.contactType) {
              element.contactType = props.contactType;
            }
          });
          setRowData(
            setContactRows(contactList).arr,
            setNumberOfRecords(contactCount)
          );
          setColumns(setContactRows(contactList).columns);
        }
      } else if (props.modalType === "owning") {
        await owningAPI.getAll().then((response: any) => {
          setRowData(setOwningRows(response).arr, numberOfRecords(response.length));
          setColumns(setOwningRows(response).columns);
        });
      } else if (props.modalType === "contact type") {
        await contactTypeAPI.getAllContactTypes().then((response: any) => {
          setRowData(
            setContactTypeRows(response.result).arr,
            setNumberOfRecords(response.result.length)
          );
          setColumns(setContactTypeRows(response).columns);
        });
      } else if (props.modalType === RuleTitlePlural) {
        let count = await ruleAPI.getRuleCount();
        await ruleAPI.getAllRules({pageNumber: pageNumber, pageSize: 20},false).then((response: any) => {
          setRowData(
            setRuleRows(response).arr,
            setNumberOfRecords(count)
          );
          setColumns(setRuleRows(response).columns);
        });
      } else {
        console.error("You've been directed to an incorrect page");
      }
      toggleFiltering();
    } catch (err) {
      console.error("Failed to get column information: ", err);
    }
    setPageNumber(1);
  }, [RuleTitlePlural, props.contactType, props.modalType]);

  // #######################################################
  // On inital load setup columns and data
  // Also watches the Contact type Prop for changes
  // #######################################################
  useEffect(() => {
    onMount();
  }, [props.contactType, onMount]);

  // #######################################################
  // Modal Controls
  // #######################################################
  const initalOpen: boolean = false;
  const [open, setOpen] = React.useState<boolean>(initalOpen);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [editRow, setEditRow] = React.useState<any>({});
  const [deleteRow, setDeleteRow] = React.useState({});

  const handleClickOpen = (rowData?: any) => {
    if(rowData) {
      setEditRow(rowData);
    }
    setOpen(true);
  };

  const handleClose = async (event: any, rowData?: any) => {
    if(rowData) {
      setEditRow(rowData);
    }

    event.stopPropagation();
    setOpen(false);
    setOpenDelete(false);
    await onMount();
  };

  // #######################################################
  // Default tab to Rules and allow for tab switching
  // #######################################################
  // let modal = {val: <RuleModal key="temp" open={open} editRow={editRow} handleClickOpen={handleClickOpen} handleClose={handleClose}/>}
  let modal = { val: <div></div> };

  if (props.modalType === "contact") {
    modal = {
      val: (
        <ContactModal
          key="contact"
          open={open}
          editRow={editRow}
          contactType={props.contactType!}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ),
    };
  } else if (props.modalType === RuleTitlePlural) {
    modal = {
      val: (
        <RuleModal
          key={RuleTitlePlural}
          open={open}
          editRow={editRow}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ),
    };
  } else if (props.modalType === "owning") {
    modal = {
      val: (
        <OwningModal
          key="owning"
          open={open}
          editRow={editRow}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ),
    };
  } else if (props.modalType === "attestation") {
    modal = {
      val: (
        <AttestationModal
          key="attestation"
          open={open}
          editRow={editRow}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ),
    };
  } else if (props.modalType === "contact type") {
    modal = {
      val: (
        <ContactTypeModal
          key="contactType"
          open={open}
          editRow={editRow}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      ),
    };
  }

  if (openDelete === true) {
    modal = {
      val: (
        <DeleteRowModal
          type={props.modalType}
          open={open}
          deleteRow={deleteRow}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        ></DeleteRowModal>
      ),
    };
  }

  const getTitle = (): string => {
    switch (props.modalType) {
      case "owning":
        return OwningTitlePlural;
      case "contact":
        return props.modalType.toUpperCase();
      case RuleTitlePlural:
        return RuleTitlePlural;
      case "contact type":
        return props.modalType.toUpperCase();
      case "attestation":
        return props.modalType.toUpperCase();
      default:
        return "Unknown";
    }
  };

    /*******************************************
   * Alert Dialog On Delete
   *******************************************/
    const [openWarn, setOpenWarn] = React.useState(false);
    const [alertText, setAlertText] = React.useState("");
    const [recordToRemove, setRecordToRemove] = React.useState<any>(null);

    const deleteRecord = (rowData:any) => {
      setRecordToRemove(rowData);
      setAlertText(`You are about to delete the contact "${rowData.firstName ?? "unknown"} ${rowData.lastName ?? "unknown"}."Click yes to delete the contact.`);
      toggleAlert();
    }

    const alertResponse = async (response: boolean) => {
      if(response) {
        let response = await contactAPI.deleteContact(recordToRemove.id);
        onMount();
      }
      // clear out the record to delete and close the alert modal
      setRecordToRemove(null);
      toggleAlert();
    }

    const toggleAlert = () => {
      openWarn ? setOpenWarn(false) : setOpenWarn(true);
    }

    let alert = { val: <div></div> };

    if(openWarn) {
      alert = {
        val: (
          <AlertDialog
            open={openWarn}
            close={openWarn}
            textContent={alertText}
            alertResponse={alertResponse}
            yesNo
          />
        )
      }
    }


  return (
    <div>
      {alert.val}
      {modal.val}
      <Table
        rows={rows}
        columns={columns}
        numberOfRecords={numberOfRecords}
        paging={getNextPage}
        add={handleClickOpen}
        edit={handleClickOpen}
        deleteable={props.modalType == "contact" ? true : false}
        delete={deleteRecord}
        // sortable
        // filterable
      />
    </div>
  );
};
