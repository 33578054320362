import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";
import { addressAPI, contactAPI } from "../../.../../../../../../../utils/api";
import { Authorized } from "../../../../../../../components/Authorized";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import {
  StatusRecordStatus,
  StatusType,
} from "../../../../../../../utils/enums";
import { Address } from "../../../../../../../utils/model/Address";
import { Contact } from "../../../../../../../utils/model/Contact";
import { ContactType } from "../../../../../../../utils/model/ContactType";
import "./AttestationModal.scss";

interface IAttestationModalProps {
  open: boolean;
  editRow?: any;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}

export const AttestationModal = (props: IAttestationModalProps) => {
  const { open, handleClickOpen, handleClose } = props;
  const [editId, setEditId] = React.useState(-1);
  const [editAddrId, setEditAddrId] = React.useState(0);
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    website: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
  });

  const saveForm = async () => {
    // API Call to create Attestation
    // Attestation types should maybe be static. Such as Default or Contact Officer type
    const contactType = new ContactType("", "Attestation Officer");
    const address = new Address(
      values.address,
      "",
      "",
      values.city,
      values.state,
      values.country,
      parseInt(values.postalCode)
    );
    let contact = new Contact(
      values.firstName,
      values.lastName,
      values.title,
      values.email,
      values.website,
      address,
      values.phone,
      StatusType.Approval,
      contactType,
      contactType.id ?? -1,
      StatusRecordStatus.Active
    );

    if (editId) {
      contact.id = editId;
      address.id = editAddrId;
      try {
        await addressAPI.update(editAddrId, address);
        delete contact.address;
        delete contact.contactType;
        await contactAPI.update(editId, contact);
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        delete contact.id;
        delete contact.address?.id;
        delete contact.contactType?.id;
        await contactAPI.create(contact);
        window.location.reload();
      } catch (err) {
        console.error(err);
      }
    }
  };

  // set form values
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  // ###############################################
  // Set the row data if edit button clicked
  // ###############################################
  useEffect(() => {
    if (props.open === false) {
      setValues({
        firstName: "",
        lastName: "",
        title: "",
        email: "",
        website: "",
        phone: "",
        address: "",
        city: "",
        country: "",
        state: "",
        postalCode: "",
      });
      setEditAddrId(0);
      setEditId(0);
    } else {
      if (props.editRow.id !== undefined) {
        setValues({
          firstName: props.editRow.firstName,
          lastName: props.editRow.lastName,
          title: props.editRow.title,
          email: props.editRow.email,
          website: props.editRow.website,
          phone: props.editRow.phone,
          address: props.editRow.address.address1,
          city: props.editRow.address.city,
          country: props.editRow.address.country,
          state: props.editRow.address.state,
          postalCode: props.editRow.address.postalCode,
        });
        setEditAddrId(props.editRow.address.id);
        setEditId(props.editRow.id);
      }
    }
  }, [props.editRow, props.handleClickOpen, props.open]);

  return (
    <div onClick={handleClickOpen}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Add Attestation Officer
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText>Attesation</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label="First Name"
            onChange={handleChange}
            value={values.firstName}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="lastName"
            label="Last Name"
            onChange={handleChange}
            value={values.lastName}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            onChange={handleChange}
            value={values.title}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            type="email"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            name="website"
            label="Website"
            onChange={handleChange}
            value={values.website}
            type="string"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            name="phone"
            label="Phone"
            onChange={handleChange}
            value={values.phone}
            type="tel"
            fullWidth
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Address</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="address"
            label="Street"
            onChange={handleChange}
            value={values.address}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="city"
            label="City"
            onChange={handleChange}
            value={values.city}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="state"
            label="State"
            onChange={handleChange}
            value={values.state}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="country"
            label="Country"
            onChange={handleChange}
            value={values.country}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="postalCode"
            label="Postal Code"
            onChange={handleChange}
            value={values.postalCode}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Authorized>
            <Button
              onClick={(event) => {
                saveForm();
                handleClose(event);
              }}
              color="primary"
            >
              Save
            </Button>
          </Authorized>
        </DialogActions>
      </Dialog>
    </div>
  );
};
