import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Authorized } from "../../../../components";
import { DialogTitleWithExit } from "../../../../components/DialogTitleWithExit";
import { RichTextEditor } from "../../../../components/RichTextEditor";
import { PostsAPI } from "../../../../utils/api/PostsApi";
import { EditorType } from "../../../../utils/enums/EditorType";
import { PostStatus } from "../../../../utils/enums/PostStatus";
import { Post } from "../../../../utils/model/Post";
import "./CreatePost.scss";

interface ICreatePostProps {
  onSave: () => void;
}

export const CreatePost = (props: ICreatePostProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const closeAction = () => {
    setShow(false);
  };
  const openAction = () => {
    setShow(true);
  };

  const savePost = async () => {
    closeAction();
    const post = new Post(text, title, PostStatus.Active);
    await PostsAPI.createPost(post);
    props.onSave();
  };

  const textChange = (text: string) => {
    setText(text);
  };
  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
  };

  return (
    <div>
      <Dialog className="dialog" open={show} onClose={closeAction}>
        <DialogTitleWithExit
          className="dialog-title"
          title="Add New Post"
          onClose={closeAction}
        >
          Add New Post
        </DialogTitleWithExit>
        <DialogContent className="content" dividers>
          <div className="post-title">
            <TextField
              fullWidth
              name="PostTitle"
              variant="outlined"
              type="string"
              label="Enter Post Title"
              defaultValue={title}
              onChange={titleChange}
            />
          </div>
          <RichTextEditor
            text={""}
            editorType={EditorType.paragraph}
            onTextChange={textChange}
            width={"auto"}
            height={"90%"}
            // saveText={savePost}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAction} color="primary">
            Cancel
          </Button>
          <Button onClick={savePost} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Authorized>
        <Button className="create-button" onClick={openAction}>
          Create News Post
        </Button>
      </Authorized>
    </div>
  );
};
