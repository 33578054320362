import { useState } from "react";
const { SearchClient, AzureKeyCredential } = require("@azure/search-documents");
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import { ButtonAppBar } from "../ButtonAppBar";
import Suggestions from "./Suggestions/Suggestions";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./SearchWrapper.scss";
import { useEffect } from "react";

import React from "react";
import { searchFuncAPI } from "../../../../../utils/api/SearchFuncApi";
import { Facets } from "..";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

///////////////////////////////////////////////////////////////////////////////////////////////////
// Custom styles
///////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
      paddingTop: "1rem",
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: "bold"
      // fontWeight: theme.typography.fontWeightBold,
    },
    caption: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: "bold",
      // fontWeight: theme.typography.fontWeightBold,
      marginBottom: "1rem",
    },
    advancedSearch: {
      backgroundColor: "#1CA6DF",
      color: "white",
    },
    searchFocused: {
      '& > div[tabindex="0"]:focus': {
        backgroundColor: "#004e9a",
        boxShadow: "0 0 1pt 0.8pt blue",
      },
    },
    inputLabel: {
      position: "absolute",
      left: "24px",
      background: "white",
      padding: "0 6px",
      zIndex: 1,
      fontWeight: "normal",
      fontSize: "1.5em",
    },
    relative: {
      position: "relative",
    },
  })
);

export const SearchWrapper = (props: any) => {
  let [q, setQ] = useState(props.q ?? "");
  let [suggestions, setSuggestions] = useState([]);
  let [showSuggestions, setShowSuggestions] = useState(false);
  const [rowHeight, setRowHeight] = React.useState("100%");
  const [colWidth, setColWidth] = React.useState("100%");
  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(false);

  const handleEmergencyChecked = () => {
    setQ("");
    setShowSuggestions(false);
    setShowFilter(false);
    props.postSearchHandler("");
    props.handleEmergencyChecked();
  };

  const onSearchHandler = () => {
    // console.log("onSearchHandler", q);
    props.postSearchHandler(q);
    setShowSuggestions(false);
  };

  const suggestionClickHandler = (s: string) => {
    (document.getElementById("search-box") as HTMLTextAreaElement).value = s;
    setShowSuggestions(false);
    props.postSearchHandler(s);
  };

  const onEnterButton = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  };

  const onChangeHandler = () => {
    if (props.emergencyChecked) {
      props.handleEmergencyChecked(false);
    }
    var searchTerm = (
      document.getElementById("search-box") as HTMLTextAreaElement
    ).value;
    setShowSuggestions(true);
    setQ(searchTerm);

    // use this prop if you want to make the search more reactive
    if (props.searchChangeHandler) {
      props.searchChangeHandler(searchTerm);
    }
  };

  useEffect(() => {
    // console.log("Search Wrapper useEffect", props.q);
    (props.q !== "") ? setShowFilter(true) : setShowFilter(false);

    const timer = setTimeout(() => {

      const body = {
        q: q,
        top: 5,
        suggester: "Id",
      };

      if (q === "") {
        setSuggestions([]);
      } else {

        searchFuncAPI
          .httpSearchFunction("Suggest", body)
          .then((response: any) => {
            setSuggestions(response.data.suggestions);
          })
          .catch((error) => {
            console.error(error);
            setSuggestions([]);
          });
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [q]);

  var suggestionDiv;
  if (showSuggestions) {
    suggestionDiv = (
      <Suggestions
        suggestions={suggestions}
        suggestionHandler={(s: string) => suggestionClickHandler(s)}
      ></Suggestions>
    );
  } else {
    suggestionDiv = <div></div>;
  }

  const clearForm = React.useCallback(() => {
    setQ("");
    setShowSuggestions(false);
    setShowFilter(false);
    props.postSearchHandler("");
    props.handleEmergencyChecked(false);
  }, [q]);


  return (

    <Container>
      <ButtonAppBar clearForm={clearForm} />
      <div className="search-wrapper" onKeyDown={(e) => onEnterButton(e)}>
        <div className="suggestions">
          {!props.emergencyChecked ? (
            <TextField
              id="search-box"
              label="Search Code:"
              style={{ marginTop: 10 }}
              placeholder="Enter a WORD or PHRASE to search"
              fullWidth
              className="search-box"
              value={q}
              autoComplete="off"
              onChange={onChangeHandler}
              defaultValue={props.q}
              onBlur={() => setShowSuggestions(false)}
              onClick={() => setShowSuggestions(true)}
              InputLabelProps={{
                shrink: true,
              }}
              aria-label="search rule form"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    color="primary"
                    aria-label="execute search"
                    onClick={onSearchHandler}
                    type="submit"
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          ) : null}

          <div className="emergency-check">
            <FormControlLabel
              control={
                <Checkbox
                  className="emergency-check"
                  onChange={handleEmergencyChecked}
                  checked={props.emergencyChecked}
                />
              }
              label="Emergency Rules"
            />

            {suggestionDiv}
          </div>

          {props.showFilter == true ? (
            <div className={classes.root}>
              <Accordion classes={{ root: classes.searchFocused }}>
                <AccordionSummary
                  classes={{ root: classes.advancedSearch }}
                  style={{
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Advanced Search   -   Use the filters below to narrow your search results.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "white" }}>


                  <div className="grid-layout-container">
                    <GridLayout
                      align={{ horizontal: "center" }} rows={[{ height: rowHeight }]}
                      cols={[{ width: colWidth }]}
                    >
                      <GridLayoutItem
                        row={1}
                        col={1}
                        className="checkbox-display"
                      >
                        <Facets
                          facets={props.facets}
                          filters={props.filters}
                          setFilters={props.setFilters}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : (
            <div className="code-search-title"></div>
          )}
        </div>
      </div>
    </Container>
  );
};
