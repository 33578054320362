import * as React from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./RegisterContentTable.scss";
import { CircularProgress, Tooltip } from "@material-ui/core";
import {
  ActionsLayout,
  Dialog,
  DialogActionsBar,
} from "@progress/kendo-react-dialogs";
import { draftAPI, filingTypeAPI, segmentHistoryAPI } from "../../../../../utils/api";
import { AlertDialog } from "../../../../../components";
import { RegisterEditLockButton } from "../RegisterEditLockButton";
import { UrlParser } from "../../../../../utils";
import { RouteComponentProps, withRouter } from "react-router";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { RuleDrafting } from "../../../../../utils/classes";

interface IRegisterContentTableProps extends RouteComponentProps {
  rows: any;
  columns: any;
  numberOfRecords: number;
  // add?: () => void;
  edit: (data: any) => void;
  height?: string;
  tableName?: string;
  regApprovedChecked?: any;
  filingsApproved: boolean;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

/**
 *
 * @param {Object[]} rows - Array of row data objects
 * @param {Object[{title:string,field:string}]} columns - array of column name objects {title:string,field:string}
 * @param {number} numberOfRecords - specify total number of records in the table
 * @param {() => void} [add] - function to open dialog for adding new table data
 * @param {string} [height] - string to override the table height
 * @param {string} [tableName] - adds a lable to the table if desireds
 * @returns kendo table component for draft records
 */
export const RegisterContentTableWithRouter = (
  props: IRegisterContentTableProps
) => {

  // alert user that record is being edited.
  const [alertUser, setAlertUser] = React.useState<boolean>(true);
  // state set for edit button status.
  const [isEditable, setIsEditable] = React.useState<boolean>(true);
  const [height, setHeight] = React.useState<any>("75vh");
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }],
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );
  const [visible, setVisible] = React.useState(false);
  const [draftToDelete, setDraftToDelete] = React.useState<any>();
  const [sectionNumber, setSectionNumber] = React.useState<any>();
  const [filingsApproved, setFilingsApproved] = React.useState<boolean>(false);

  /* ON MOUNT */
  const onMount = React.useCallback(async () => {
    if (props.height) {
      setHeight(props.height);
    }

    // if register approved checked, set isEditable to false.;

    setDataResult(process(props.rows, dataState));
  }, [props.rows, props.columns]);

  React.useEffect(() => {
    onMount();
  }, [props]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  let _pdfExport: GridPDFExport | null;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const deleteDraft = async (dataItem: any) => {
    let deleteResponse = await draftAPI.deleteDraft(dataItem.id);
    alert(`${draftToDelete.draftName} Successfully Deleted.`);
    setVisible(!visible);
  };

  const toggleDialog = (dataItem: any) => {
    setDraftToDelete(dataItem);
    setVisible(!visible);
  };

  const alertResponse = (response: boolean) => {
    visible ? setVisible(false) : setVisible(true);
    if (response) {
      deleteDraft(draftToDelete);
    }
  };

  // handle edit lock action
  const editLockAction = (dataItem: any) => {
    setSectionNumber(dataItem.dataItem.sectionNum);

    props.edit(dataItem);
  };

  //Navigation link to filing
  const navigateToPage = async (filing: any) => {
    let filingType = await filingTypeAPI.getFilingTypeById(filing.filingTypeId);
    props.history.push(
      UrlParser.Filing.parseFiling(filingType.name, filing.filingId)
    );
    let url = UrlParser.Filing.parseFiling(
      filing.filingTypeName,
      filing.filingId
    );
    UrlParser.Filing.parseFiling(filing.filingTypeName, filing.filingId);
    return url;
  };

  const updateLockAction = (data: any) => {};

  // Lock edit button to "read only" and change button to a lock icon when
  const editButtonColumn = (data: GridCellProps) => {
    let filingTypeName = data.dataItem.filingTypeName;
    let published = props.regApprovedChecked;
    let filingCheck =
      filingTypeName == "Permanent" ||
      filingTypeName == "Emergency" ||
      filingTypeName == "Preemptive";

    return (
      <td className="newIcon" role="gridcell">
        {published != true ? (
          filingCheck ? (
            data.dataItem.lockedByUserId != null ? (
              <Button
                icon="lock"
                className="k-button k-danger edit-in-progress-button"
                onClick={() => editLockAction(data)}
              >
                Edit in progress
              </Button>
            ) : (
              <Tooltip title="Edit Draft" aria-label="add" placement="top">
                <Button
                  className="k-button k-primary edit-button"
                  onClick={() => editLockAction(data)}
                  disabled={filingsApproved}
                >
                  Edit
                </Button>
              </Tooltip>
            )
          ) : (
            <div>
              <Tooltip
                title="See Draft Editor under My Work"
                placement="top-start"
              >
                <div>Not Available For Edit</div>
              </Tooltip>
            </div>
          )
        ) : (
          <div>
            <Tooltip
              title="See Draft Editor under My Work"
              placement="top-start"
            >
              <div>Not Available For Edit</div>
            </Tooltip>
          </div>
        )}
      </td>
    );
  };

  const lastModifiedColumn = (data: GridCellProps) => {
    let newDate = new Date(data.dataItem.lastModified).toLocaleDateString();
    return (
      <td className="lastModified" role="gridcell">
        { newDate }
      </td>
    );
  };

  const editedSegmentColumn = (data: GridCellProps) => {
    return (
      <td className="description" role="gridcell">
        {data.dataItem.segmentData ? data.dataItem.segmentData.description : ""}
      </td>
    );
  };

  const filingColumn = (data: GridCellProps) => {
    return (
      <td className="link" role="gridcell">
        <a href="#"  onClick={() => navigateToPage(data.dataItem)}>
          {data.dataItem.name}
        </a>
      </td>
    );
  };

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element: any, i: number) => {
      if (element.hidden != true && element.field != "id") {
        return (
          <GridColumn field={element.field} key={i} title={element.title} />
        );
      }
    });

    columnsToShow.splice(3, 0,
      <GridColumn
        key="lastModified"
        field={"lastModified"}
        title="Last Modified"
        cell={lastModifiedColumn}
      />
    );

    columnsToShow.unshift(
      <GridColumn
        key="description"
        field={"description"}
        title="Description"
        cell={editedSegmentColumn}
      />
    );


    columnsToShow.unshift(
      <GridColumn
        key="filing"
        field={"filingName"}
        title="Filing Name"
        cell={filingColumn}
      />
    );

    columnsToShow.unshift(
      <GridColumn
        key="Edit"
        field={"Edit"}
        title="Edit Draft"
        cell={editButtonColumn}
      />
    );

    if (columnsToShow.length > 0 && props.rows.length > 0) {
      return (
        <Grid
          className="draft"
          key={"RegisterIssue"}
          style={{
            height: height,
            minWidth: "100%",
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          // groupable={true}
          // group={[{field: "filingTypeName"}]}
          reorderable={true}
          pageable={{
            buttonCount: 4,
            pageSizes: true,
          }}
          data={dataResult}
          expandField="expanded"
          {...dataState}
          onDataStateChange={dataStateChange}
          total = {dataResult.total ? dataResult.total : 0}
        >
          <GridToolbar>
            <ButtonGroup className="button-group">
              <Button
                icon="pdf"
                title="Export to PDF"
                className="k-button k-secondary button"
                onClick={exportPDF}
              />

            </ButtonGroup>
            {props.tableName ? <h3>{props.tableName}</h3> : null}
          </GridToolbar>
          <GridNoRecords>
            <CircularProgress />
          </GridNoRecords>
          {columnsToShow}
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="work-queue">
      <AlertDialog
        textContent={
          "This will delete the draft " +
          (draftToDelete ? draftToDelete.draftName : "")
        }
        close={!visible}
        open={visible}
        alertResponse={alertResponse}
      />
      {_renderTable()}
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
};

export const RegisterContentTable = withRouter(RegisterContentTableWithRouter);
