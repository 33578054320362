import {
  AdoptedPermanentForm,
  AdoptedPreemptiveForm,
  AdoptionOfEmergencyForm,
  EmergencyForm,
  FeeIncrease,
  NoticeOfPublicCommentCancellation,
  PermanentForm,
  ExecutiveOrderForm,
  Misc,
  NoticeCANForm,
  NoticeCONForm,
  NoticeEditorsForm,
  NoticeLegDisForm,
  NoticeNRIForm,
  NoticeOfErrorForm,
  NoticeSRForm,
  NoticeWithdrawalForm,
  PreemptiveForm,
  RulemakingRecordForm,
  GovernorDeclarationsForm
} from "./components/FilingAddForm/components";
import { NoticeOfPublicCommentContinuation } from "./components/FilingAddForm/components/NoticeOfPublicCommentContinuation";
import { NoticeOfPublicHearingContinuation } from "./components/FilingAddForm/components/NoticeOfPublicHearingContinuation";
// import { NoticeSR } from "./components/FilingAddForm/components/NoticeSRForm";
// import { NoticeWithdrawal } from "./components/FilingAddForm/components/NoticeWithdrawalForm";
// import {
  // AdoptedPreemptiveForm,
// } from "./components/FilingAddForm/forms";

export const FormMap: Record<
  string,
  React.ForwardRefExoticComponent<any & React.RefAttributes<unknown>>
> = {
  AdoptedPreemptive: AdoptedPreemptiveForm,
  "Adoption-PERM": AdoptedPermanentForm,
  "AdoptionOfEmergency": AdoptionOfEmergencyForm,
  Emergency: EmergencyForm,
  "Editor'sNotice": NoticeEditorsForm,
  Permanent: PermanentForm,
  Preemptive: PreemptiveForm,
  ExecutiveOrders: ExecutiveOrderForm,
  GovernorDeclaration: GovernorDeclarationsForm,
  Misc: Misc,
  "Notice-LD": NoticeLegDisForm,
  "Notice-WR": NoticeWithdrawalForm,
  "Notice-Err": NoticeOfErrorForm,
  "Notice-NRI": NoticeNRIForm,
  "Notice-CON": NoticeCONForm,
  "Notice-CAN": NoticeCANForm,
  "Notice-SR": NoticeSRForm,
  // NoticeSR: NoticeSR,
  // NoticeWithdrawal: NoticeWithdrawal,
  NoticeOfPublicCommentCancellation: NoticeOfPublicCommentCancellation,
  NoticeOfPublicCommentContinuation: NoticeOfPublicCommentContinuation,
  NoticeOfPublicHearingCancellation: NoticeOfPublicCommentCancellation,
  NoticeOfPublicHearingContinuation: NoticeOfPublicHearingContinuation,
  FeeIncrease: FeeIncrease,
  "Fee Increase": FeeIncrease,
  "RulemakingRecord": RulemakingRecordForm
};
