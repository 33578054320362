import { VisibilityLevel, ThreadType } from "../enums";
import { Identifiable } from "./Identifiable";
import {Comment} from './Comment';

export class CommentThread extends Identifiable {
  constructor(
    parentId: number,
    threadType: ThreadType,
    referenceId: number,
    visibility: VisibilityLevel,
    comments?: Comment[] | null
  ) {
    super(null);
    this.parentId = parentId;
    this.threadType = threadType;
    this.referenceId = referenceId;
    this.visibility = visibility;
    if(comments) {
      this.comments = comments;
    } else {
      this.comments = [];
    }
  }

  public parentId: number;
  public threadType: ThreadType;
  public referenceId: number;
  public visibility: VisibilityLevel;
  public comments?: Comment[];
}
