import { useEffect, useState } from "react";
import { draftAPI, filingAPI } from "../../../utils/api";
import { set } from "lodash";

export const useProposedRulesController = () => {
  const [propsedRules, setProposedRules] = useState<any[]>([]);
  const [proposedRuleFilings, setProposedRuleFilings] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [proposedRuleData, setProposedRuleData] = useState<any>(undefined);
  const [alertMessage, setAlertMessage] = useState<string | null | undefined>(
    null
  );
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  useEffect(() => {
    async function getProposedRules() {
      // console.log("getting proposed rules");
      let filings = await filingAPI.getPublicProposedRuleFilings();
      // console.log(filings);
      setProposedRuleFilings(filings);
    }

    getProposedRules();
  }, []);

  const toggleAlertDialog = () => {
    alertOpen ? setAlertOpen(false) : setAlertOpen(true);
  };

  const toggleDialog = async (id?: number) => {
    dialogOpen ? setDialogOpen(false) : setDialogOpen(true);
    if (id) {
      setProposedRuleData(undefined);
      let draftData = await draftAPI.getDraft(id);
      setProposedRuleData(draftData);
    }
  };

  const emailLiaison = (filing: any) => {
    if (filing.entity.liaison.email || filing.entity.liaison.email !== "") {
      window.open("mailto:" + filing.entity.liaison.email);
    } else {
      setAlertMessage(
        "No contact information found. Please find the contact information on the quicklinks page."
      );
      toggleAlertDialog();
    }
  };

  return {
    //variables
    proposedRuleFilings,
    dialogOpen,
    proposedRuleData,
    alertMessage,
    alertOpen,
    // functions
    toggleDialog,
    toggleAlertDialog,
    emailLiaison,
  };
};
