// import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import "./CodeResult.scss";
import {
  AddAlert,
  CheckCircleOutline,
  // Code as CodeIcon,
  ExpandMore as ExpandMoreIcon,
  // History as HistoryIcon,
  // PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  Button,
  Container,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Rule } from "../../../../../utils/model/Rule";
import { NestedExpansions } from ".";
import {
  contactAPI,
  ruleAPI,
  subscriptionLoginAPI,
  userSubscriptionAPI,
} from "../../../../../utils/api";
import Alert from "@material-ui/lab/Alert";
import { UserSubscription } from "../../../../../utils/model/subscription/UserSubscription";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: "bold",
      // fontWeight: theme.typography.fontWeightBold,
    },
    advancedSearch: {
      backgroundColor: "#0066A6",
      color: "white",
    },
    searchFocused: {
      '& > div[tabindex="0"]:focus': {
        backgroundColor: "#004e9a",
        boxShadow: "0 0 1pt 0.8pt blue",
      },
    },
    inputLabel: {
      position: "absolute",
      left: "24px",
      background: "white",
      padding: "0 6px",
      zIndex: 1,
      fontWeight: "normal",
      fontSize: "1.5em",
    },
    relative: {
      position: "relative",
    },
  })
);
interface IAgencyDisplayProps {
  agencyList: [];
  noteTypes: any;
}

type Color = "success" | "info" | "warning" | "error";

export const CodeResult = (props: IAgencyDisplayProps) => {
  const [expanded, setExpanded] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackClose, setSnacClose] = useState(false);

  const [segment, setSegment]: any = useState();
  const [email, setEmail] = useState<any>(null);
  const [rule, setRule] = useState<any>(null);
  const [isCurrentUserLoggedIn, setIsCurrentUserLoggedIn] = useState(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<Color>();
  const [userSubscriptions, setUserSubscriptions] = useState<UserSubscription[]>([]);

  const classes = useStyles();

  const handleSubscribeSubmit = async (e: any, item: any, source?: string) => {

    var tempUser = sessionStorage.getItem("subscriptionUser");
    var userObj = JSON.parse(tempUser!);
    var uerEmail = userObj.user.email;
    e.stopPropagation();


    const userId = await subscriptionLoginAPI.getUserId(uerEmail);

    let userSubscription: UserSubscription = {
      id: 0,
      userId: userId
    }

    let alertText = "";

    if (source === "chapter") {
      userSubscription.chapterSegmentId = item.id;
      alertText = "You have subscribed to Chapter " + item.chapterNum + ". " + item.description;

    } else {
      userSubscription.ruleId = item.id;
      alertText = "You have subscribed to Title " + item.referenceCode + ". " + item.title;
    }

    try {
      const result = await userSubscriptionAPI.addUserSubscription(userSubscription);
      // console.log('code result result - ', result)
      if (result === 'error') {
        setAlertSeverity('error')
        setAlertMessage("You have already subscribed to this item or the Title. Unsubscribe through the subscriptions portal.");
      } else {
        setAlertSeverity('success');
        setAlertMessage(alertText);
        setUserSubscriptions([...userSubscriptions , userSubscription])
      }

    } catch (error) {
      setAlertSeverity('error');
      setAlertMessage("There was an error adding the subscription.");
    }

    setSnackOpen(true);
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleChange =
    (segmentId: any, title: any) => async (event: any, newExpanded: any) => {
      setExpanded(newExpanded ? segmentId : false);
      let element = document.getElementById(segmentId);
      if (element) {
        element.scrollIntoView();
      }

      let ruleResult = await ruleAPI.getRuleReferenceCode(title.referenceCode);
      setRule(ruleResult);

      // get the agency contact on expansion
      if (ruleResult.contactIds) {
        let contact: any;
        if (ruleResult.contactIds.liaison) {
          try {
            contact = await contactAPI.getContactById(
              ruleResult.contactIds.liaison
            );
            if (contact) {
              if (contact.email) {
                setEmail("mailto:" + contact.email);
              }
            }
          } catch (error) {
            throw error;
          }
        }
      }
    };

  const style = {
    // marginLeft: props.agencyList.values. * 3,
  };


  useEffect(() => {

    const getSessionStorage = sessionStorage.getItem("subscriptionUser");
    const userInfo = getSessionStorage ? JSON.parse(getSessionStorage) : null;

    if (userInfo !== null) {

      const getUserSubscriptions = async (user: any) => {

        let subs = await userSubscriptionAPI.getUserSubscriptions(user.user);
        if(subs === null) setUserSubscriptions([]);
        setUserSubscriptions(subs);

      };
      setIsCurrentUserLoggedIn(true);

      // get logged in user's subscriptions
      if (userSubscriptions.length === 0 ) {
        getUserSubscriptions(userInfo);
      }
    }
  }, [userSubscriptions]);

  const saveForm = (event: any) => { };

  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
      <Container>
        <div className="agency">
          {props.agencyList
            ? props.agencyList.map((item: Rule, key: number) => (
              <Accordion
                square
                key={key}
                expanded={expanded === `${item.segmentId}`}
                onChange={handleChange(`${item.segmentId}`, item)}
                TransitionProps={{
                  unmountOnExit: true,
                  timeout: 100,
                }}
                id={`${item.segmentId}`}
              >
                <AccordionSummary
                  classes={{ root: classes.advancedSearch }}
                  className="label"
                  aria-controls="panel2d-content"
                  aria-label="Agency Listing"
                  id="panel2d-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className="content-container">
                    <Typography className="title-name">
                      Title {item.referenceCode}. {item.title}
                    </Typography>
                    {(isCurrentUserLoggedIn)
                      ? (userSubscriptions.length === 0 || !userSubscriptions.find((x) => x.ruleId === item.id))
                      ? (<Tooltip title="Subscribe to this Title">
                          <IconButton
                            onClick={(e) => handleSubscribeSubmit(e, item)}
                            className="subscribe-button"
                            color="inherit"
                            size="small"
                          >
                            <AddAlert />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="You are subscribed to this Title">
                          <IconButton
                            onClick={(e) => handleSubscribeSubmit(e, item)}
                            className="subscribe-button"
                            color="inherit"
                            size="small"
                          >
                            <CheckCircleOutline />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                  </div>
                </AccordionSummary>

                <NestedExpansions
                  titleLevel={true}
                  className={"Title"}
                  open={expanded}
                  title={item}
                  segmentId={item.segmentId!}
                  noteTypes={props.noteTypes}
                  email={email}
                  isCurrentUserLoggedIn={isCurrentUserLoggedIn}
                  handleSubscribeSubmit={handleSubscribeSubmit}
                  userSubscriptions={userSubscriptions}
                  subscribedTitleSegmentId={userSubscriptions.length > 0 && userSubscriptions?.find((x) => x.ruleId == item.id)?.ruleId}
                />
              </Accordion>
            ))
            : null}
        </div >
      </Container >
    </>
  );
};
