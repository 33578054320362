import { Docket } from "../model";
import { BaseAPI } from "./internal";

class DocketAPI extends BaseAPI<Docket> {
  constructor() {
    super("docket");
  }

  public getAllDockets: () => Promise<Docket[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetDockets`)).data;
  };

  public getDocketsById: (id: number) => Promise<Docket> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetDocketsById/${id}`))
      .data;
  };

  public createDockets: (docket: Docket) => Promise<any> = async (
    docket: Docket
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateDockets`, {
      docket,
    });
    return response.data;
  };

  public removeDockets: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveDockets/${id}`
    );
    return response.data;
  };

  public updateDockets: (id: number, docket: Docket) => Promise<Docket> =
    async (id: number, docket: Docket) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/UpdateDockets/${id}`,
        docket
      );
      return response.data;
    };
}

export const docketAPI = new DocketAPI();
