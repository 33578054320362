import { CircularProgress } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
// import Highlighter from "react-highlighter";
import { CustomizedDialogs } from "../../components/CustomizedDialogs";
import { cmsItemAPI } from "../../utils/api";
import { CmsType, EditorType } from "../../utils/enums";
import { CmsItem } from "../../utils/model";
import { Paragraph } from "../Paragraph";
// import { serialize } from "../TextEditors";
import "./TextWrapper.scss";

interface ITextWrapperProps {
  className: string;
  editorType: EditorType;
  name: string;
}

export const TextWrapper = (props: ITextWrapperProps) => {
  // var Highlighter = require("react-highlighter");
  const initialCmsItem = new CmsItem(props.name, "", CmsType.HTML);
  initialCmsItem.id = 0;
  const [cmsItem, setCmsItem] = useState(initialCmsItem);

  const setDialogText = React.useCallback(async () => {
    let cmsItem = await cmsItemAPI.getCmsItemsByName(props.name);
    setCmsItem(cmsItem);
  }, [props.name]);

  useEffect(() => {
    setDialogText();
  }, [setDialogText]);

  // on click in modal triggers refresh of data displayed on page
  const onSaveClick = () => {
    setDialogText();
  };

  const onTextChange = () => { };

  return (
    <Fragment>
      {
        // TODO Fix For Items
        cmsItem.id !== 0 ? (
          <CustomizedDialogs
            id={cmsItem.id!}
            editorType={props.editorType}
            name={props.name}
            dialogText={cmsItem.value}
            onClick={onSaveClick}
            onTextChange={onTextChange}
          />
        ) : (
          <CircularProgress />
        )
      }
      <div className={props.className}>
        {/* {Array.isArray(cmsItem?.value)
          ? cmsItem.value.map((node, index) => {
              if (props.editorType === EditorType.paragraph) {
                node.type = "home-paragraph";
              } else if (props.editorType === EditorType.title) {
                node.type = "home-title";
              } else if (props.editorType === EditorType.subTitle) {
                node.type = "home-sub-title";
              }
              return serialize(node, index);
            })
          : null} */}

        <Paragraph value={cmsItem.value} className={props.className} />
      </div>
    </Fragment>
  );
};
