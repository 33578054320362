import { Registries } from "../model/Registries";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { AggregatedRegister } from "../model/AggregatedRegister";
import { FinalizedRegister } from "../model/FinalizedRegister";

class RegistriesAPI extends BaseAPI<CustomElementRegistry> {
  constructor() {
    super("Registries", APISettings.adminRulesApiUrl);
  }

  public canPreview: (registerId: number) => Promise<Boolean> = async (
    registerId: number
  ) => {
    var response = await BaseAPI.axios.get(
      `${this.apiUrl}/CanPreview/${registerId}`
    );
    return response.data;
  };

  public getRegistriesById: (id: number) => Promise<Registries> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRegistryById/${id}`))
      .data;
  };

  public getAllRegistries: (paging?: any) => Promise<Registries[]> = async (
    paging: any
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllRegistries?pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}`
      )
    ).data;
  };

  public getAllPublishedRegistries: (paging?: any) => Promise<Registries[]> =
    async (paging: any) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetAllPublishedRegistries?pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}`
        )
      ).data;
    };

  public getAggregatedRegistry: (id: any) => Promise<AggregatedRegister[]> =
    async (id: any) => {
      try {
        return (
          await BaseAPI.axios.get(`${this.apiUrl}/GetAggregatedRegister/${id}`)
        ).data;
      } catch (error) {
        return null;
      }
    };

  public createRegistries: (registries: Registries) => Promise<any> = async (
    registries: Registries
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateRegistries`,
      { registries }
    );
    return response.data;
  };

  public removeRegistries: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveRegistries/${id}`
    );
    return response.data;
  };

  public updateRegistries: (
    id: number,
    registries: Registries
  ) => Promise<Registries> = async (id: number, registries: Registries) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateRegistry/${id}`,
      registries
    );
    return response.data;
  };

  public getLastPublishedRegister: () => Promise<Registries> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetLastPublishedRegister/`))
      .data;
  };

  public updateRegisterAfterGenerationAsync: (
    id: number,
    htmlFileName: string
  ) => Promise<any> = async (id: number, htmlFileName: string) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateRegisterAfterGenerationAsync/${id}/${htmlFileName}`
    );
    return response.data;
  };

  public updateRegisterStatus: (
    registerId: number,
    statusId: number
  ) => Promise<any> = async (registerId: number, statusId: number) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateRegisterStatus/${registerId}/${statusId}`
    );
    return response.data;
  };

  public publishRegister: (registerId: number) => Promise<any> = async (
    registerId: number
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/PublishRegister/${registerId}`
    );
    return response.data;
  };

  public finalizeRegisterGeneration: (data: FinalizedRegister) => Promise<any> =
    async (data: FinalizedRegister) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/FinalizeRegisterGeneration`,
        data
      );
      return response.data;
    };
}

export const registriesAPI = new RegistriesAPI();
