import { useState } from "react";
// ui
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Divider } from "@material-ui/core";
// components
import AgencyLiaisonEmail from "./Forms/AgencyLiaisonEmail";
import ActiveAgencyUser from "./Forms/ActiveAgencyUser";
import RulesRecord from "./Forms/RulesRecord";
import RulesLiaison from "./Forms/RulesLiaison";
import CodeReport from "./Forms/CodeReport";

import "./ReportsPage.scss";


interface IReportsTableProps extends IStateTabComponentProps { }

/**
 * @description handles switching between different form components for running reports
 * @returns React.Component
 */
export const ReportsPage = (props: IReportsTableProps) => {
  const reports = ["Agency Liaison Email", "Active Agency Users", "Rule Record", "Rules Liaison", "Code Statistics"]
  const [report, setReport] = useState<string>('Agency Liaison Email');

  const handleDropDownSelect = (event: DropDownListChangeEvent) => {
    setReport(event.target.value);
  };

  return (
    <div className="reports-page">
      <div>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          <div>Select a Report</div>
          <DropDownList
            style={{
              width: "300px",
            }}
            data={reports}
            defaultValue="Agency Liaison Report"
            onChange={handleDropDownSelect}
          />
        </form>
      </div>
      <Divider />
      <div>
        <form onSubmit={(e) => { e.preventDefault(); }}>
          {report === "Agency Liaison Email" ? (
            <AgencyLiaisonEmail selectedReport={report} />
          ) : null}
          {report === "Active Agency Users" ? (
            <ActiveAgencyUser selectedReport={report} />
          ) : null}
          {report === "Rule Record" ? (
            <RulesRecord selectedReport={report} />
          ) : null}
          {report === "Rules Liaison" ? (
            <RulesLiaison selectedReport={report} />
          ) : null}
          {report === "Code Statistics" ? (
            <CodeReport selectedReport={report} />
          ) : null}
        </form>
      </div>
    </div>
  );
};
