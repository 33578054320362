import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import { RichTextEditorTest } from "./components/TextEditors";
import { Login, Subscription } from "./pages";
import { Blog } from "./pages/Blog";
import { FeeIncrease } from "./pages/FeeIncrease";
import { Registers } from "./pages/Registers";
import { AdoptedRules, CodeSearch, Home, QuickLinks } from "./publicFacing/pages";
import { State } from "./stateOffice/pages";
import { PrivateRoute } from "./utils/authentication";
import { AuthenticationProvider } from "./utils/authentication/AuthenticationProvider";
import { AuthenticationRoutes } from "./utils/authentication/AuthenticationRoutes";
import ProposedRules from "./publicFacing/pages/ProposedRules/ProposedRules";
import { SubscriptionProfile } from "./pages/Subscription/SubscriptionProfile";
import { SubscriptionManagement } from "./pages/Subscription/SubscriptionManagement";
import { RegisterComplete } from "./pages/Subscription/components/SubscriptionRegisterForms/RegisterComplete";
import { RecoverPasswordForm } from "./pages/Subscription/components/SubscriptionRecoveryForms/RecoverPasswordForm";
import { useAnalytics } from "./utils/analytics/useAnalytics";
import Wrapper from "./utils/analytics/Wrapper";

export const Routes = () => {

  const { initialized } = useAnalytics();

  return (
    <AuthenticationProvider>
      <BrowserRouter>
        <Wrapper initialized={initialized}>
          <Switch>
            {[...AuthenticationRoutes]}
            <Route exact path="/code" component={CodeSearch} />
            <Route exact path="/quicklinks" component={QuickLinks} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/proposedrules" component={ProposedRules} />
            {/* <Route exact path="/adoptedrules" component={AdoptedRules} /> */}
            <Route exact path="/subscription" component={Subscription} />
            <Route exact path="/news" component={Blog} />
            <Route exact path="/registers" component={Registers} />
            <Route exact path="/feeincrease" component={FeeIncrease} />
            <Route exact path="/activate-account/:activationKey" component={RegisterComplete} />
            <Route exact path="/ResetSubscriptionUserPassword/:activationKey" component={RecoverPasswordForm} />
            {
              sessionStorage.getItem("subscriptionUser")
                ? <Route exact path="/subscriptionProfile" component={SubscriptionProfile} />
                : <Route exact path="/subscriptionLogin" component={Subscription} />
            }
            {/* <Route exact path="/subscriptionLogin" component={Subscription} /> */}
            <Route exact path="/subscriptionManagement" component={SubscriptionManagement} />

            <PrivateRoute path="/state/:tabName" component={State} />
            {/* <Route exact path="/test" component={RichTextEditorTest} />, */}
            <Route component={() => <Redirect to="/home" />} />
          </Switch>
        </Wrapper>
      </BrowserRouter>
    </AuthenticationProvider>
  );
};
