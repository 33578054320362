import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { contactTypeAPI } from "../../../../../../../utils/api";
import { ContactType } from "../../../../../../../utils/model/ContactType";
import "./ContactTypeModal.scss";

interface IContactTypeModalProps {
  open: boolean;
  editRow?: any;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}

export const ContactTypeModal = (props: IContactTypeModalProps) => {
  const { open, handleClickOpen, handleClose } = props;
  // TODO: Investigate if the below two lines are necessary.
  // const initialContactType = "";
  // const [contactType, setContactTypes]  = React.useState(initialContactType);
  const [editId, setEditId] = React.useState(-1);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    name: "",
    type: "",
  });

  // ###############################################
  // Set the row data if edit button clicked
  // ###############################################
  useEffect(() => {
    if (props.open === false) {
      setValues({
        name: "",
        type: "",
      });
      setEditId(-1);
    } else if (props.editRow) {
      if (props.editRow.id) {
        setValues({
          name: props.editRow.name,
          type: props.editRow.type,
        });
        setEditId(props.editRow.id);
      }
    }
  }, [props.editRow, props.handleClickOpen, props.open]);

  const saveForm = async (event: any) => {
    if (editId !== -1) {
      const contactType: ContactType = new ContactType(
        values.name,
        values.type
      );
      contactType.id = editId;
      try {
        //update existing item
        await contactTypeAPI.updateContactType(editId, contactType);
        setSnackOpen(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      if (editId === -1 && values.name !== "") {
        // create new item
        const contactType: ContactType = new ContactType(
          values.name,
          values.type
        );
        delete contactType.id;
        try {
          await contactTypeAPI.createContactType(contactType);
        } catch (err) {
          console.error(err);
        }
      }
    }
    handleClose(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <div onClick={handleClickOpen}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Save Successful</Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Contact Type
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            value={values.name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            name="type"
            label="Type"
            type="text"
            value={values.type}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(event) => {
              saveForm(event);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
