// import React from "react";
// import { Filing } from "../../../../utils/model";
import { FilingStatusHistory } from "../../../FilingStatusHistory/FilingStatusHistory";
import "./HistoryTab.scss";

interface IHistoryTabProps {
  filingId: number;
}

export const HistoryTab = (props: IHistoryTabProps) => {
  return (
    <div className="history-tab">
      <FilingStatusHistory id={props.filingId} />
    </div>
  );
};
