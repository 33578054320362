import {
  FormGroup,
  FormLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FileUpload } from "../../../../../../components/FileUpload";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Draft,
  Filing,
  FilingType,
  Rule,
} from "../../../../../../utils/model";
import { Row, Select, TextField } from "./";
import { RULE_PLURAL_NAME } from "./../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";
import { StorageContainer, DraftStatuses, RulemakingActions } from "../../../../../../utils/enums";
import { FilingLink } from "../../FilingLink";
import { PermanentFinalRulemakingAction } from "../../../../../../utils/model/Filing";
import { fileAPI } from "../../../../../../utils/api";

interface IAdoptedPermanentFormProps {
  filingType: FilingType;
  filing?: Filing;
  renderRuleDropdown: () => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  // attestationList: Contact[];
  // liaisonList: Contact[];
  ruleList: Rule[];
  draftList: Draft[];
  saveForm: (item: any, type: string) => void;
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
  returnContactListAsync: (ruleId: number, isAttestation: boolean) => any;

}

const getInitialState = (props: IAdoptedPermanentFormProps) => ({
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  rule: props.ruleList[0],
  chapter: props.ruleList[0],
  ruleId: 1,
  chapterId: -1,
  reasonForRule: "",
  adoptionDate: new Date(),
  ruleImpactReport: [{ guid: "", filename: "" }],
  agencyRuleReport: [{ guid: "", filename: "" }],
  feeIncrease: "N/A",
  liaison: Contact,
  liaisonId: 0,
  segments: [],
  transmittalSheet: [{ guid: "", filename: "" }],
  draft: null,
  draftId: null,
  rulemakingAction: RulemakingActions.Permanent
});

export let AdoptedPermanentForm = React.forwardRef(
  (props: IAdoptedPermanentFormProps, ref) => {
    const [formValues, handleChange] = useFormState(
      { ...getInitialState(props), ...props.filing?.entity },
      ref
    );

    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    const setLiaisonListFromRuleId = React.useCallback(async (ruleId: number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    }, [props]
    );

    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }
      } else if (props.filing) {
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);

    const handleDateChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      date: Date | null
    ) => {
      handleChange(event, name, date);
      if (name === "effectiveDate") {
        handleChange(event, "enactingClause", "EFFECTIVE DATE " + date);
      }
    };

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const handleDraftSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);
        }
        handleChange(event, "draftName", value);
      }
    };

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id! === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        if (name === "liaisonId") {
          contact = ruleContactList.find((i: any) => i.id === rawValue);
        } else {
          contact = attestationContactList.find((i: any) => i.id === rawValue);
        }
        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
            handleChange(event, "liaisonId", contact);
          } else {
            handleChange(event, "attestation", contact);
            handleChange(event, "attestationId", contact);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleFeeIncreaseChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string
    ) => {
      value === "Yes"
        ? handleChange(event, "feeIncrease", "Yes")
        : handleChange(event, "feeIncrease", "N/A");
    };

    const onUploadRuleImpact = (guid: string, filename: string) => {
      let index = formValues.ruleImpactReport.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.ruleImpactReport.push(uploadObject);
      } else {
        formValues.ruleImpactReport[index] = uploadObject;
      }
    };

    const onUploadAgencyRule = (guid: string, filename: string) => {
      let index = formValues.agencyRuleReport.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.agencyRuleReport.push(uploadObject);
      } else {
        formValues.agencyRuleReport[index] = uploadObject;
      }
    };

    const currId = uuid();

    const onTransmittalSheetUpload = (guid: string, filename: string) => {
      let index = formValues.transmittalSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.transmittalSheet.push(uploadObject);
      } else {
        formValues.transmittalSheet[index] = uploadObject;
      }
    };

    const onRuleImpactDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var ruleImpactData = formValues.ruleImpactReport.filter((obj: any) => { return obj.guid !== guid; });
          formValues.ruleImpactReport = ruleImpactData;
          handleChange(null, 'ruleImpactReport', ruleImpactData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    const onAgencyRuleReportDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var agencyRuleReportData = formValues.agencyRuleReport.filter((obj: any) => { return obj.guid !== guid; });
          formValues.agencyRuleReport = agencyRuleReportData;
          handleChange(null, 'agencyRuleReport', agencyRuleReportData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    const onTransmittalSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var transmittalData = formValues.transmittalSheet.filter((obj: any) => { return obj.guid !== guid; });
          formValues.transmittalSheet = transmittalData;
          handleChange(null, 'transmittalSheet', transmittalData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    const handleRulemakingSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, name: string, value: string) => {
      handleChange(event, name, value);
    };

    return (
      <FormProvider
        aria-label="Create Filing"
        values={formValues}
        onChange={handleChange}
      >
        <div className="flex-column">
          <Typography variant="h6">ADOPTED PERMANENT RULES</Typography>
          <TextField
            id={`name-text-field${currId}`}
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
          />
          <Row className="rule-title-row">
            <Select
              className="drop-down"
              id={`name-text-field${currId}`}
              label="TITLE"
              name="ruleId"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                className="drop-down"
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem className="drop-down-item" key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>
          <Row>
            <Select
              label="DRAFTS"
              name="draft"
              value={formValues.draftId ?? 0}
              onChange={handleDraftSelectChange}
              disabled={readOnly}
            >
              {props.renderDraftDropdown}
            </Select>
          </Row>

          <Select
            className="drop-down"
            id={`name-text-field${currId}`}
            name="rulemakingAction"
            label="RULEMAKING ACTION"
            defaultValue={RulemakingActions.Permanent}
            onChange={handleRulemakingSelectChange}
            disabled={readOnly}
          >
            <MenuItem value={RulemakingActions.Permanent}>{RulemakingActions.Permanent}</MenuItem>
          </Select>

          <div className="date-picker-wrapper">
            <FormLabel className="label-date">ADOPTION DATE</FormLabel>
            <div>Permanent rules can only be submitted within 10 days of adoption.</div>
            <DatePicker
              // id="adoption-date-picker"
              name="adoptionDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.adoptionDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "adoptionDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <TextField
            id="reason-for-rule"
            name="reasonForRule"
            label="REASON FOR RULE (If resubmitting, please also include reason for resubmission)"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <Row>
            <Select
              id="fee-increase"
              name="feeIncrease"
              label="FEE INCREASE"
              defaultValue={"N/A"}
              onChange={handleFeeIncreaseChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            <h5>
              Reminder: Agency Must File a Notification of Fee Increase or New
              Fees
            </h5>
          </Row>
          <div>
            <p></p>
          </div>
          <FormLabel>RULE IMPACT STATEMENT</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.ruleImpactReport.map((element: any, key: number) => {
              if (element.guid) {
                return (
                  <FilingLink guid={element.guid} fileName={element.filename} onDelete={onRuleImpactDelete} />
                );
              }
            })}
            <FileUpload
              onUploaded={onUploadRuleImpact}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
          <div>
            <p></p>
          </div>
          <FormLabel>AGENCY RULE REPORT</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.agencyRuleReport.map((element: any, key: number) => {
              if (element.guid) {
                return (
                  <FilingLink guid={element.guid} fileName={element.filename} onDelete={onAgencyRuleReportDelete} />
                );
              }
            })}
            <FileUpload
              onUploaded={onUploadAgencyRule}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
          <div>
            <p></p>
          </div>
          <Select
            id="liaison-id"
            name="liaisonId"
            label="AGENCY LIAISON"
            onChange={handleContactSelectChange}
            disabled={readOnly}
          >
            {ruleContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderLiaisonDropdown} */}
          </Select>
          <FormLabel>TRANSMITTAL SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.transmittalSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onTransmittalSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onTransmittalSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
          {props.filing?.adoptedRuleDocumentPdf ? (
            <Fragment>
              <FormLabel>ADOPTED RULE DOCUMENT</FormLabel>
              <FormGroup className="form-group-checkbox">
                <FilingLink guid={props.filing!.adoptedRuleDocumentPdf.substr(0, props.filing!.adoptedRuleDocumentPdf.lastIndexOf('.')) + '.pdf'} fileName={`Adoption${props.filing!.name}.pdf`} container={"adopted-rule-documents"} />
              </FormGroup>
            </Fragment>
          ) : null}
        </div>
      </FormProvider>
    );
  }
);
