import { Identifiable } from ".";
import { StatusRecordStatus } from "../enums";
import { RuleStatus } from "../enums/RuleStatus";

export class Rule extends Identifiable {
  constructor(
    title: string,
    referenceCode: string,
    segmentId: number,
    contactIds: any,
    ruleTypeId: number,
    notes: string,
    ruleStatus: RuleStatus,
    recordStatus: StatusRecordStatus,
    additionalInformation?: string | undefined
  ) {
    super(null);
    this.title = title;
    this.referenceCode = referenceCode;
    this.segmentId = segmentId;
    this.contactIds = contactIds;
    this.ruleTypeId = ruleTypeId;
    this.notes = notes;
    this.additionalInformation = additionalInformation;
    this.ruleStatus = ruleStatus;
    this.recordStatus = recordStatus;
    if(additionalInformation)
      this.additionalInformation = additionalInformation;
  }

  public title: string;
  public referenceCode: string;
  public segmentId: number;
  public contactIds: any;
  public ruleTypeId: number;
  public notes: string;
  public ruleStatus: RuleStatus;
  public recordStatus: StatusRecordStatus;
  public additionalInformation?: string | undefined;
}
