// Material UI
import { createTheme, ThemeProvider } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { Footer } from "../../../components/Footer";
import { ImageWrapper } from "../../../components/ImageWrapper";
import { Menu } from "../../../components/Menu";
import { Search } from "../../../components/Search";
import { TextWrapper } from "../../../components/TextWrapper";
import { EditorType, StorageContainer } from "../../../utils/enums";
import { getOS } from "../../../utils/UserOS";
import "./Home.scss";

interface IHomeProps { }

const textTheme = createTheme({
  typography: {
    h1: {
      fontSize: "5.75vw",
      color: "#ffff",
      fontFamily:
        "Cambria, 'Hoefler Text', 'Liberation Serif', Times, 'Times New Roman', serif;",
    },
    h3: {
      fontSize: "22pt",
      fontWeight: 300,
    },
    body1: {
      lineHeight: "22px",
      fontSize: "12pt",
      fontWeight: 300,
    },
    body2: {
      lineHeight: "22px",
      fontSize: "12pt",
      fontWeight: 300,
    },
  },
});

export const Home = (props: IHomeProps) => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  return (
    <div className="home" id="Home">
      <a id="skip-nav" className="skiplink-text" href="#main-content">
        Skip to Main Content
      </a>
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      <main id="main-content">
        <Fade in={true} timeout={1000}>
          <div className="image-wrapper">
            <ImageWrapper
              name="image1"
              className="image1"
              alt=""
              storageContainer={StorageContainer.PublicCms}
            />
            <h1 className="visually-hidden">Oklahoma Administrative Rules</h1>
            <div className="title-center-search">
              <Search id="home" />
            </div>
          </div>
        </Fade>
        <div id="About">
          <TextWrapper
            editorType={EditorType.title}
            name="title"
            className="title"
          />
          <hr className="underline" />
          <ThemeProvider theme={textTheme}>
            <div className="body-wrapper body-pane">
              <TextWrapper
                editorType={EditorType.paragraph}
                name="body1"
                className="body1"
              />
            </div>
          </ThemeProvider>
        </div>
        <div className="body-wrapper body-split-pane">
          <div className="body-wrapper">
            <ImageWrapper
              name="image2"
              className="image-right edit"
              alt=""
              storageContainer={StorageContainer.PublicCms}
            />
          </div>
          <div className="body-wrapper right">
            <ThemeProvider theme={textTheme}>
              <TextWrapper
                editorType={EditorType.subTitle}
                name="title2"
                className="title-body"
              />
              <div className="body2">
                <TextWrapper
                  editorType={EditorType.paragraph}
                  name="body2"
                  className="body2"
                />
              </div>
            </ThemeProvider>
          </div>
        </div>
        <div className="body-wrapper body-split-pane">
          <div className="body-wrapper right">
            <ThemeProvider theme={textTheme}>
              <TextWrapper
                editorType={EditorType.subTitle}
                name="title3"
                className="title-body"
              />
              <div className="body2">
                <TextWrapper
                  editorType={EditorType.paragraph}
                  name="body3"
                  className="body2"
                />
              </div>
            </ThemeProvider>
          </div>
          <div className="body-wrapper">
            <ImageWrapper
              name="image3"
              className="image-right edit"
              alt=""
              storageContainer={StorageContainer.PublicCms}
            />
          </div>
        </div>
        <div className="image-wrapper">
          <ImageWrapper
            name="image4"
            className="image-footer edit"
            alt=""
            storageContainer={StorageContainer.PublicCms}
          />
        </div>
        <div id="Contact">
          <TextWrapper
            editorType={EditorType.subTitle}
            name="title4"
            className="title"
          />
          <hr className="underline" />
        </div>
        <ThemeProvider theme={textTheme}>
          <div className="body-wrapper body-pane center-text">
            <TextWrapper
              editorType={EditorType.paragraph}
              name="body4"
              className="body1"
            />
          </div>
          <div className="body-wrapper body-pane center-text">
            <TextWrapper
              editorType={EditorType.paragraph}
              name="body5"
              className="body1"
            />
          </div>
        </ThemeProvider>
      </main>
      <footer>
        <Footer
          stateName={"Oklahoma"}
          copyrightYear={2020}
          footerMessage={`"For questions regarding the content or application of a specific rule, please contact the agency responsible for the rule. A directory of agencies is also available online. For questions about the rulemaking process, please send your questions and comments to Rules@ok.gov."`}
        />
      </footer>
    </div>
  );
};