import {Field,} from "@progress/kendo-react-form";

import "./LoginForms.scss";
import {FormInput} from "../../Helpers/form-components";
import {passwordValidator} from "../../Helpers/Validators";


export const PasswordForm = (
    <div style={{marginTop: "5em", color: "cornflowerblue"}}>
        <Field
            type={"password"}
            key={"password"}
            id={"password"}
            name={"password"}
            label={<strong>Enter your Password:</strong>}
            component={FormInput}
            validator={passwordValidator}
        />
    </div>
);
  