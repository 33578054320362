import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class CodeActionAPI extends BaseAPI<string> {
  constructor() {
    super("codeActions", APISettings.adminRulesApiUrl);
  }

  public executeAction: (
    actionId: number,
    filingId: number,
    isTask: boolean
  ) => Promise<any> = async (
    actionId: number,
    filingId: number,
    isTask: boolean
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}?actionId=${actionId}&filingId=${filingId}&isTask=${isTask}`
    );
    return response.data;
  };
}

export const codeActionAPI = new CodeActionAPI();
