// import React, { useState } from "react";
import "./Paragraph.scss";
import Parser from "html-react-parser";

interface IParagraphProps {
  className: string;
  value: string;
}

export const Paragraph = (props: IParagraphProps) => {
  // console.log(props.className);
  // This can't use Typography from Material because that causes nested <p> tags when paired with tinymce
  return (<div className={props.className}>{Parser(props.value)}</div>);
};
