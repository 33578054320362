import { FormLabel, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import { Contact, Docket, Filing, FilingType, Rule } from "../../../../../../utils/model";
import { Row, Select, TextField } from "./";
import "./../FilingAddForm.scss";
import { EditorType } from "../../../../../../utils/enums";
import "./../FilingAddForm.scss";
import DatePicker from "react-datepicker";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";
import { height } from "@material-ui/system";

interface IExecutiveOrderFormProps {
  attestationList: Contact[];
  docketList: Docket[];
  filing?: Filing;
  filingType: FilingType;
  liaisonList: Contact[];
  ruleList: Rule[];
  formatDate: (date: any) => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  renderContactDropdown: () => any;
  renderLiaisonDropdown: () => any;
  renderRuleDropdown: () => any;
  saveForm: (item: any, type: string) => void;
}

const getInitialState = (props: IExecutiveOrderFormProps) => ({
  chapter: props.ruleList[0],
  chapterId: -1,
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  draftId: 0,
  effectiveDate: new Date(),
  executiveOrderDocument: [{ guid: "", filename: "" }],
  filingName: "",
  formName: "EO",
  filingType: "Executive Order",
  liaison: Contact,
  liaisonId: 0,
  rule: props.ruleList[0],
  ruleId: -1,
  synopsis: "",
  text: "",
  eoNumberYear: "",
  eoNumberNumber: ""
});

export let ExecutiveOrderForm = React.forwardRef((props: IExecutiveOrderFormProps, ref) => {
  const [formValues, handleChange] = useFormState({ ...getInitialState(props), ...props.filing?.entity }, ref);
  const [chapterList, setChapterList]: any = React.useState([]);
  const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
  const setChapterListFromRuleId = React.useCallback(async (ruleId: number) => {
    const chapters = await props.returnChapterListAsync(ruleId);
    setChapterList(chapters);
  }, [props]
  );
  const currId = uuid();
  const [text, setText] = React.useState<string>(formValues.text);
  const [helperText, setHelperText] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>(false);
  const nameErrorMessage: string = "Filing Name Required";

  const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value as string;
    let isError: boolean = value.trim().length == 0;
    let errorMessage: string = isError ? nameErrorMessage : '';

    setIsError(isError);
    setHelperText(errorMessage);
    handleChange(e, "filingName", value);
  };

  useEffect(() => {
    if (!formValues.rule && !props.filing) {
      formValues.rule = props.ruleList[0];
      formValues.ruleId = props.ruleList[0]?.id;
    } else if (props.filing) {
      let entity = Object.create(props.filing!.entity!);
      setChapterListFromRuleId(entity.rule.segmentId);
      if (props.filing?.isReadOnly) {
        setReadOnly(props.filing.isReadOnly);
      }
    }
  }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

  const handleRuleSelectChange = async (event: React.ChangeEvent<HTMLInputElement>, name: string, rawValue: string | null) => {
    const value = rawValue as keyof typeof Rule;
    if (value != null) {
      let rule = props.ruleList.find((i: any) => i.id === rawValue);
      if (rule != null) {
        handleChange(event, "rule", rule);
        handleChange(event, "ruleId", rule.id);
        setChapterListFromRuleId(rule.segmentId!);
      }
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, date: Date | null) => {
    handleChange(event, name, date);
    if (name === "effectiveDate") {
      handleChange(event, "enactingClause", `EFFECTIVE DATE ${props.formatDate(date)}`);
    }
  };

  const textChange = (value: string) => {
    formValues.text = value;
  };

  return (
    <FormProvider values={formValues} onChange={handleChange}>
      <div className="flex-column executive-order-form">
        <Typography variant="h6">EXECUTIVE ORDER</Typography>
        <TextField
          label="FILING NAME"
          name="filingName"
          placeholder="Filing Name (REQUIRED)"
          disabled={readOnly}
          onChange={handleFilingNameChange}
          error={isError}
          helperText={helperText}
          variant="outlined"
          aria-label="filing name"
          autoFocus
        />
        <Row className="rule-title-row">
          <Select
            className="drop-down"
            id={`name-text-field${currId}`}
            label="TITLE"
            name="ruleId"
            value={formValues.ruleId}
            onChange={handleRuleSelectChange}
            disabled={true}
          >
            {props.renderRuleDropdown}
          </Select>
        </Row>
        <div className="date-picker-wrapper">
          <FormLabel className="label-date">EFFECTIVE DATE</FormLabel>
          <DatePicker
            name="effectiveDate"
            className="date-picker"
            showPopperArrow={false}
            selected={new Date(formValues.effectiveDate)}
            onChange={(date: Date, event: React.ChangeEvent<any>) => handleDateChange(event, "effectiveDate", date)}
            dateFormat="MMMM d, yyyy"
            disabled={readOnly}
          />
        </div>
        <div className="eo-number-wrapper">
          <FormLabel className="label-date">EXECUTIVE ORDER NUMBER</FormLabel>
          <div className="eo-number-container">
            <div className="eo-number-start">1:</div>
            <div className="eo-number-input">
              <TextField name="eoNumberYear" disabled={readOnly} />
            </div>
            <div>-</div>
            <div className="eo-number-input">
              <TextField name="eoNumberNumber" disabled={readOnly} />
            </div>
          </div>
        </div>
        <TextField
          name="synopsis"
          label="SYNOPSIS"
          multiline
          rows="5"
          fullWidth
          disabled={readOnly}
        />
        <RichTextEditor
          text={text}
          editorType={EditorType.segment}
          onTextChange={textChange}
          height={"50vh"}
          width={"auto"}
          disable={readOnly}
        />
      </div>
    </FormProvider>
  );
}
);
