import { Button } from "@progress/kendo-react-buttons";
import React, { useState, useEffect } from "react";
import { contactAPI } from "../../../../../utils/api";
import "./CodeResult.scss";

interface IContactHyperLinkProps {
  email: string;
}

export const ContactHyperLink = (props: IContactHyperLinkProps) => {
  const { email } = props;

  return (email
    ? (<a className="contact" href={email}><Button look="flat" icon="user" style={{ color: "orange" }}>Contact Agency</Button></a>)
    : (<Button className="contact" look="flat" icon="user" style={{ color: "orange" }} disabled={true}>No Contact Found</Button>));

};

