import React, { useEffect } from "react";

import { AppBar, Tabs, Tab } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { useQueryFilingType } from "../../../../../utils/hooks";
import { FilingType } from "../../../../../utils/model";
import { UrlParser } from "../../../../../utils";
import "./FilingSubTab.scss";

// TODO ROB refactor this component to use the url in place of state.

interface IAgencyAdminProps extends RouteComponentProps {
  filingTypes: FilingType[];
  filingType: FilingType | undefined;
}

const FilingSubTabWithRouter = (props: IAgencyAdminProps) => {
  let tab = useQueryFilingType(props.location.search, props.filingTypes);

  if (tab === "Notice") {
    tab = "Notice-NRI";
  }

  if (tab === "Adopted") {
    tab = "Adoption-PERM";
  }

  const [filingType, setFilingType] = React.useState("Notice-NRI");
  const [filingTypeList, setFilingTypeList] = React.useState<FilingType[]>([]);

  useEffect(() => {
    let filingTypes: FilingType[] = [];
    if (props.filingType) {
      if (props.filingType.name.substring(0, 6) == "Notice") {
        props.filingTypes.forEach((item: FilingType) => {
          if (item.name.substring(0, 6) === "Notice") {
            filingTypes.push(item);
          }
        })

        setFilingTypeList(filingTypes);
        setFilingType("Notice-NRI");
        // these were commented out because the use effect trigger was breaking routing
        // props.history.push(UrlParser.Filing.parseFilingType(props.filingType.name));

      } else if (props.filingType.name.substring(0, 5) == "Adopt") {
        props.filingTypes.forEach((item: FilingType) => {
          if (item.name.substring(0, 5) === "Adopt") {
            filingTypes.push(item);
          }
        })

        setFilingTypeList(filingTypes);
        setFilingType("Adoption-PERM");
        // these were commented out because the use effect trigger was breaking routing
        // props.history.push(UrlParser.Filing.parseFilingType(props.filingType.name));

      } else {
        setFilingType("");
      }
    }
  }, [props.filingType])

  const handleChange = (event: React.ChangeEvent<{}>, item: string) => {
    props.history.push(UrlParser.Filing.parseFilingType(item));
  };

  return (
    filingType != "" && filingTypeList ? (
      <div className="filing-sub-tab">
        <AppBar position="static" color="default">
          <Tabs
            value={tab}
            indicatorColor="primary"
            aria-label="Type of Filing"
            onChange={handleChange}
            scrollButtons="auto"
            variant="fullWidth"
          >
            {
              filingTypeList ? filingTypeList.map((value: FilingType) => {
                if (value.subFiling) {
                  return (<Tab key={value.id} label={value.alternativeName} value={value.name} />);
                }
              }) : null
            }
          </Tabs>
        </AppBar>
      </div>
    ) : null
  );
};

export const FilingSubTab = withRouter(FilingSubTabWithRouter);
