import React, { useEffect } from "react";
import { StorageContainer } from "../../../../utils/enums/";
import { saveAs } from "file-saver";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

/**
 * @description Component for individual link line items
 *
 * @component Link
 * @param {string}  guid Name of the guid in Azure Blob storage
 * @param {string}  fileName Name of the file for downloading
 * @example
 * return (
 *    <Link
 *      label="Sample"
 *      textName="ItemLabel"
 *      fileName="cmsItem"
 *      readOnly={false}
 *      //hyperlinkOverride="https://google.com"
 *    />
 * )
 */

interface IFilingLinkProps {
  fileName: string;
  guid: string;
  container?: string;
  onDelete?: (guid: string) => void;
}

export const FilingLink = (props: IFilingLinkProps) => {
  const [label, setLabel] = React.useState(props.fileName);

  let guid: string;

  const getGuid = React.useCallback(async () => {
    if (props.guid) {
      let item = props.guid as string;
      guid = item;
    }
    return guid;
  }, []);

  useEffect(() => {
    getGuid();
  }, [getGuid]);

  const onClick = async () => {
    let item = await getGuid();

    let url = `https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=${props.container ? props.container : StorageContainer[StorageContainer.FilingUploads]}&name=${item}`;

    let blob = await fetch(url).then((r) => r.blob());

    saveAs(blob, label);
  };

  const _render = () => {
    return (
      <div>
        <a href="#" onClick={onClick}>
          {label}
        </a>
        {props.onDelete != null && props.onDelete != undefined ? (
          <IconButton onClick={() => props.onDelete!(props.guid)} size="small" style={{ "marginLeft": "0.5rem", "color": "#f44336" }}><Delete /></IconButton>
        ) : null}
      </div>
    );
  };

  return <div>{_render()}</div>;
};
