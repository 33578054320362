import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./FeeIncreaseTable.scss"
import { CircularProgress } from '@material-ui/core';
import { AuthenticationContext } from '../../../utils/authentication/AuthenticationProvider';
import { ApplicationRoles } from '../../../utils/model/ApplicationUser';
import { Fragment } from 'react';
import { FilingLink } from '../../../stateOffice/pages/FilingBuilder/components';


interface IFeeIncreaseTableProps {
  rows: any;
  columns: any;
  height?: string;
  tableName?: string;
  paging?: (page: any) => any;
  numberOfRecords?: number;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

export const FeeIncreaseTable = (props: IFeeIncreaseTableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [numberOfRecords, setNumberOfRecords] = React.useState<number>(0);
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );
  const [auth, setAuth] = React.useState<any>(null);


  const { isAuthenticated, getUser } = React.useContext(
    AuthenticationContext
  );

  const onMount = React.useCallback(async () => {
    if (props.height) {
      setHeight(props.height);
    }
    setDataResult(process(props.rows, props.paging ?? dataState));
  }, [props.paging, props.columns])


  React.useEffect(() => {
    onMount();
  }, [props.rows, props.height, props.columns])

  React.useMemo(() => {
    setDataState({
      skip: 0,
      take: 20,
      sort: [{ field: "id", dir: "desc" }]
    });
  }, [props.numberOfRecords])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };


  const Download = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        {
          data.dataItem.feeIncreaseLink ? (
            <div className="download-links">
              {
                data.dataItem.feeIncreaseLink.map((item: any, index: number) => {
                  if (item.guid != "") {
                    return (<FilingLink key={data.dataItem.name + index} guid={item.guid} fileName={item.filename} />)
                  }
                })
              }
            </div>
          ) : null
        }
      </td>
    )
  }

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element: any, i: number) => {
      if (element.field != "id") {
        return (
          <GridColumn field={element.field} key={element.title + i} title={element.title} />
        );
      }
    });

    columnsToShow.push(<GridColumn field={"Link"} title="Fee Increase Letter" cell={Download} />);

    if (columnsToShow.length > 0 && props.rows.length > 0) {
      return (
        <Grid
          style={{
            height: height,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          reorderable={true}
          pageable={{
            buttonCount: 4,
            pageSizes: true,
          }}
          data={dataResult}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={props.numberOfRecords ? props.numberOfRecords : 0}
        >
          <GridToolbar>
            <ButtonGroup className="button-group">
              <Button
                icon="xls"
                title="Export to Excel"
                className="k-button k-secondary button"
                onClick={exportExcel}
              />
              <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
              <div className="filing-label">
                {props.tableName ? (
                  <h3>{props.tableName}</h3>
                ) : null}
              </div>
            </ButtonGroup>
          </GridToolbar>
          <GridNoRecords>
            <CircularProgress />
          </GridNoRecords>
          {columnsToShow}
        </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="table">
      <ExcelExport
        data={props.rows}
        ref={(exporter: any) => {
          _export = exporter;
        }}
      >
        {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}


