export class ActionType {
    constructor(id: number, actionName: string, icon: string) {
        this.id = id;
        this.actionName = actionName;
        this.icon = icon;
    };

    public id: number;
    public actionName: string;
    public icon: string;
}