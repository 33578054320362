import { Address } from "../model/Address";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class AddressAPI extends BaseAPI<Address> {
  constructor() {
    super("addresses", APISettings.adminRulesApiUrl);
  }

  public getAddresses: () => Promise<Address> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAddresses`)).data;
  };

  public getAddressById: (id: number) => Promise<Address> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAddressById/${id}`))
      .data;
  };

  public createAddress: (address: Address) => Promise<any> = async (
    address: Address
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateAddress`, {
      address,
    });
    return response.data;
  };

  public removeAddress: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveAddress/${id}`
    );
    return response.data;
  };

  public updateAddress: (id: number, address: Address) => Promise<Address> =
    async (id: number, address: Address) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/UpdateAddress/${id}`,
        { address }
      );
      return response.data;
    };
}

export const addressAPI = new AddressAPI();
