import React from "react";
import "./LogoutCallBack";
import { AuthenticationContext } from "../../AuthenticationProvider";
import { CircularProgress } from "@material-ui/core";

interface ISignInRedirectCallbackProps {}

export const LogoutCallBack = (props: ISignInRedirectCallbackProps) => {
  const { signoutRedirectCallback } = React.useContext(AuthenticationContext);
  
  React.useEffect(() => {
    signoutRedirectCallback()
  }, [signoutRedirectCallback])
  
  return <CircularProgress />;
};
