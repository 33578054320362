import { Log, User, UserManager } from "oidc-client";
import { useLocation } from "react-router-dom";
import { ApplicationUser } from "../model/ApplicationUser";
import { AuthenticationSettings } from "../settings";

export class AuthenticationService {
  public userManager: UserManager;
  public authenticationSettings: AuthenticationSettings;

  constructor() {
    this.authenticationSettings = new AuthenticationSettings();
    // setup authentication settings
    this.userManager = new UserManager(this.authenticationSettings);

    // add logging for authentication
    Log.logger = console;
    Log.level = Log.INFO;

    /***** ADD EVENT LISTENERS ******/

    // addUserLoaded
    this.userManager.events.addUserLoaded((event) => {
      // If after detecting a user we are still on the signIn screen redirect to the signed in home.
      if (window.location.href.indexOf("signin-callback") !== -1) {
        this.navigateToScreen();
      }
    });

    // addSilentRenewError
    this.userManager.events.addSilentRenewError((error) => {
      // If the silent renewal process is not working let the user know.
      console.error("silent renew error", error.message);
    });

    // addAccessTokenExpired
    this.userManager.events.addAccessTokenExpired((event) => {
      // If the token expires then attempt to signIn again.
      console.error("token expired");
      // Clear out any stale state
      this.userManager.clearStaleState();
      // clear out all our local storage variables
      if (window.location.hash) {
        this.userManager.signinRedirectCallback();
      } else {
        localStorage.setItem(
          "redirectUri",
          window.location.pathname + window.location.search
        );
        this.userManager.signinRedirect();
      }
    });

    //
  }

  /***** CALLBACKS ******/
  public signinRedirectCallback = async () => {
    await setTimeout(() => null, 3000);
    this.userManager.signinRedirectCallback();
    // Add code to be run after the callback to be run after we get the access token/user back.
  };

  public signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
    // Add code to be run after the callback to be run after we get the access token/user back.
  };

  public signoutRedirectCallback = async () => {
    await this.userManager.signoutRedirectCallback();
    // Clear out any stale state
    this.userManager.clearStaleState();
    // remove user from local storage to force future sigin
    this.userManager.removeUser();
    // clear out all our local storage variables
    localStorage.clear();
    // redirect the browser to the home page
    window.location.replace(this.authenticationSettings.clientRoot);
  };

  /***** COMMANDS ******/
  public signin = () => {
    localStorage.setItem(
      "redirectUri",
      window.location.pathname + window.location.search
    );
    this.userManager.signinRedirect();
  };

  public signout = () => {
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token"),
    });
    this.userManager.clearStaleState();
    // remove user from local storage
    this.userManager.removeUser();
    // clear out all our local storage variables
    localStorage.clear();
  };

  public renewToken = (): Promise<User> => {
    return this.userManager.signinSilent();
  };

  public createSigninRequest = () => {
    localStorage.setItem(
      "redirectUri",
      window.location.pathname + window.location.search
    );
    return this.userManager.createSigninRequest();
  };

  public signinSilent = () => {
    this.userManager
      .signinSilent()
      .then((user) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  /***** QUERIES ******/
  public getUser = async (): Promise<ApplicationUser | null> => {
    // Get the user, if it is not null return it.
    let user = await this.userManager.getUser();
    let applicationUser = user as ApplicationUser;
    if (applicationUser) {
      return applicationUser;
    } else {
      return null;
    }
    return null;
  };

  public getUserId = async (): Promise<string | undefined> => {
    let user = await this.userManager.getUser();
    return user?.profile?.sub;
  };

  public isAuthenticated = (): boolean => {
    let oidcStorage: any;
    // grab the user info out of sessionStorage
    const storageObject = localStorage.getItem(
      `oidc.user:${this.authenticationSettings.authority}:${this.authenticationSettings.client_id}`
    );
    if (storageObject != null) {
      oidcStorage = JSON.parse(storageObject);
    }
    // if the session storage contains something, and it also has an access token send back true
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  /***** MISC ******/
  public parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  public navigateToScreen = () => {
    // window.location.replace("/state/home");
    let URI = localStorage.getItem("redirectUri");
    // window.location.replace(location.pathname);
    if (URI?.includes("/state")) {
      window.location.replace(URI);
    } else {
      window.location.replace("/home");
    }
  };
}
