import { FilingType } from "../model/FilingType";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class FilingTypeAPI extends BaseAPI<FilingType> {
  constructor() {
    super("filingType", APISettings.adminRulesApiUrl);
  }

  public getFilingTypeById: (id: number) => Promise<FilingType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetFilingTypeById/${id}`)).data;
  };

  public getAllFilingTypesNonPaging: () => Promise<FilingType[]> = async () => {
    var response = await BaseAPI.axios.get(`${this.apiUrl}/GetAllFilingTypesNonPaging`);
    return response.data;
  };

  public getAllFilingTypes: (pagingParameters?: any) => Promise<FilingType[]> =
    async (pagingParameters?: any) => {
      if (pagingParameters) {
        return (
          await BaseAPI.axios.get(`${this.apiUrl}/GetAllFilingTypes?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}`)).data;
      } else {
        return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllFilingTypes`)).data;
      }
    };

  public getAllPublicFilingTypes: (pagingParameters?:any) => Promise<FilingType[]> = async(pagingParameters?:any) => {
    if(pagingParameters) {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetAllPublicFilingTypes?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}`)
      ).data;
    } else {
      return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllPublicFilingTypes`)).data;
    }
  };

  public createFilingTypes: (filingType: FilingType) => Promise<any> = async (
    filingType: FilingType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateFilingTypes`,
      {
        filingType,
      }
    );
    return response.data;
  };

  public removeFilingType: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveFilingType/${id}`
    );
    return response.data;
  };

  public updateFilingType: (
    id: number,
    filingType: FilingType
  ) => Promise<FilingType> = async (id: number, filingType: FilingType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateFilingType/${id}`,
      { filingType }
    );
    return response.data;
  };
}

export const filingTypeAPI = new FilingTypeAPI();
