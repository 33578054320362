import { Icon, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { UrlParser } from "../../../../utils";
import { filingAPI } from "../../../../utils/api";
import { Filing } from "../../../../utils/model";
import { FilingView } from "../../FilingHome";
import { FilingHomeTab } from "../FilingHomeTab";
import "./FilingHeader.scss";

interface IFilingHeaderProps extends RouteComponentProps {
  filing: Filing | null;
  selectedView: FilingView;
  updateView: (filingView: FilingView) => void;
}

const FilingHeaderPreRouter = (props: IFilingHeaderProps) => {
  const { filing, selectedView, updateView } = props;

  const [parentFiling, setParentFiling] = useState<Filing | null>(null);

  const redirectToFiling = () => {
    if (parentFiling?.id != null) {
      const url = UrlParser.Filing.parseFiling(
        parentFiling.name,
        parentFiling.id
      );
      props.history.push(url);
    }
  };
  if (filing == null) {
    return null;
  }
  return (
    <div className="filing-header">
      <span>{filing?.name}</span>
      {filing.isReadOnly ? (
        <Tooltip title={(<div>Unable to be edited for one of the following reasons<li>User Permissions</li><li>Filing already Completed/Approved/Rejected</li><li>Filing included in register that's already been approved</li></div>)} placement="right">
          <Icon color="error">info</Icon>
        </Tooltip>
      ) : null}
      <FilingHomeTab filing={filing} tab={selectedView} setTab={updateView} />
    </div>
  );
};

export const FilingHeader = withRouter(FilingHeaderPreRouter);
