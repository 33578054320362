import React from 'react';
import { Home } from '../model'
// import { FilingType, VisibilityLevel } from '../enums'
import { SetStateAction, Dispatch } from 'react';
type HomeDispatch = (state: Home) => void;
type HomeProviderProps = { children: React.ReactNode }
const defaultHome = new Home("Home", "", "");
const HomeStateContext = React.createContext<Home>(defaultHome);
const HomeDispatchContext = React.createContext<Dispatch<SetStateAction<Home>>>(()=>{});

function HomeProvider({ children }: HomeProviderProps) {
  const [state, dispatch] = React.useState<Home>(defaultHome);
  return (
    <HomeStateContext.Provider value={state}>
      <HomeDispatchContext.Provider value={dispatch}>
        {children}
      </HomeDispatchContext.Provider>
    </HomeStateContext.Provider>
  )
}
function useHomeState() {
  const context = React.useContext(HomeStateContext)
  if (context === undefined) {
    throw new Error('useHomeState must be used within a HomeProvider');
  }
  return context
}
function useHomeDispatch() {
  const context = React.useContext(HomeDispatchContext)
  if (context === undefined) {
    throw new Error('useHomeDispatch must be used within a HomeProvider');
  }
  return context
}

function useHome(): ([Home, HomeDispatch]) {
  return [useHomeState(), useHomeDispatch()];
}
export { HomeProvider, useHome };