import { Button, Dialog, DialogActions, DialogContent, Divider, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { Authorized, EnumSelectInput, IconSelector } from "../../../../../../../components";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { statusAPI } from "../../../../../../../utils/api";
import { StatusRecordStatus, StatusType as StatusTypes, VisibilityLevel } from "../../../../../../../utils/enums";
import { Status, StatusType } from "../../../../../../../utils/model";
import { Workflow } from "./../../../../../../../utils/model/Workflow";
import "./StatusModal.scss";

interface IStatusModalProps {
    open:boolean;
    workflow:Workflow;
    handleClickOpen: () => void;
    handleClose: (event:any) => void;
    triggerReload: () => void;
    selectedStatus?:any;
}

export const StatusModal = (props: IStatusModalProps) => {
    const { open, handleClickOpen, handleClose } = props;
    const [values, setValues] = React.useState({
        id: -1,
        name: "",
        icon: "",
        description: "",
        status: 0,
        order: 0,
        visibility: 0,
        statusType: new StatusType(1, "Accepted", "check_circle_outline")
    });

    const saveForm = async() => {
        // Creates a new status item
        if(values.id === -1) {
          try {
              let status = new Status(values.name, values.description, values.status, values.order, props.workflow, values.visibility, StatusRecordStatus.Active, values.statusType);
              delete(status.id);
              await statusAPI.create(status);
              props.triggerReload();
          } catch (error) {
              console.error('Status Create Error Encountered: ', error);
          }
        } else if(values.id !== -1 && values.id) {
          try {
              let status = new Status(values.name, values.description, values.status, values.order, props.workflow, values.visibility, StatusRecordStatus.Active, values.statusType);
              status.id = props.selectedStatus.id;
              await statusAPI.update(props.selectedStatus.id, status);
              props.triggerReload();
          } catch (error) {
              console.error('Status Create Error Encountered: ', error);
          }
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setValues({...values, [name]: value});
    };

    useEffect(() => {
      if(props.selectedStatus) {
        setValues(values => ({...values,
          id: props.selectedStatus.id,
          name: props.selectedStatus.name,
          icon: props.selectedStatus.icon,
          description: props.selectedStatus.description,
          order: props.selectedStatus.sortOrder,
          visibility: props.selectedStatus.visibility,
        }));
      } else {
        setValues(values => ({...values,
          id: -1,
          name: "",
          icon: "",
          description: "",
          status: 0,
          order: 0,
          visibility: 0,
        }))
      }
    }, [props.open, props.selectedStatus])

    const iconSelect = (newIcon:string) => {
        setValues({...values, icon: newIcon});
    }


    const handleStatusChange = (val: number) => {
        setValues({...values, status: val});
    }

    const handleVisibilityChange = (val: number) => {
        setValues({...values, visibility: val});
    }

    return (
      <div onClick={handleClickOpen}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
            Add Status
          </DialogTitleWithExit>
          <Divider />
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              margin="dense"
              name="name"
              label="Name"
              onChange={handleChange}
              value={values.name}
              type="text"
            />
            <TextField
              fullWidth
              margin="dense"
              name="description"
              label="Description"
              onChange={handleChange}
              value={values.description}
              type="text"
            />
            <EnumSelectInput enumerable={StatusTypes} value={values.status} title="Status" onChange={handleStatusChange} className="status-select"/>
            <EnumSelectInput enumerable={VisibilityLevel} value={values.visibility} title="Visibility" onChange={handleVisibilityChange} className="status-select"/>
            <TextField
              label="Sort Order"
              margin="dense"
              type="number"
              name="order"
              onChange={handleChange}
              value={values.order}
              fullWidth
            />

            <div className="form-icon-select">
                <div className="form-icon-text"> Select Icon  </div>
                <IconSelector onSelect={iconSelect} icon={values.icon || ""} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Authorized>
              <Button onClick={(event) => { saveForm(); handleClose(event);}} color="primary">
                Save
              </Button>
            </Authorized>
          </DialogActions>
        </Dialog>
      </div>
    );
};