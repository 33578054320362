import { RuleType } from "../model/RuleType";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class RuleTypeAPI extends BaseAPI<RuleType> {
  constructor() {
    super("ruleTypes", APISettings.adminRulesApiUrl);
  }

  public getAllRuleTypes: () => Promise<RuleType[]> = async () => {
    const url = `${this.apiUrl}/GetAllRuleTypes`;
    const response = await BaseAPI.axios.get(url);
    return response.data;
  };

  public getRuleTypeById: (id: number) => Promise<RuleType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleTypeById/${id}`))
      .data;
  };

  public createRuleTypes: (ruleType: RuleType) => Promise<any> = async (
    ruleType: RuleType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateRuleTypes`,
      {
        ruleType,
      }
    );
    return response.data;
  };

  public removeRuleTypes: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveRuleTypes/${id}`
    );
    return response.data;
  };

  public updateRuleTypes: (
    id: number,
    ruleType: RuleType
  ) => Promise<RuleType> = async (id: number, ruleType: RuleType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateRuleTypes/${id}`,
      { ruleType }
    );
    return response.data;
  };
}

export const ruleTypeAPI = new RuleTypeAPI();
