import "./ActionIconWrapper.scss";
import { Action, Filing } from "../../../../../utils/model";
import { ActionIcon } from "../../../../../components";
import { codeActionAPI } from "../../../../../utils/api";

interface IActionIconWrapperProps {
  action: Action;
  filing: Filing;
  setLoading: (loading: boolean) => void;
  updateRows: () => void;
}

export const ActionIconWrapper = (props: IActionIconWrapperProps) => {
  let { filing, action, updateRows, setLoading } = props;
  const onClick = async (e: MouseEvent) => {
    e.stopPropagation();
    try {
      setLoading(true);
      await codeActionAPI.executeAction(
        props.action.id!,
        props.filing.id!,
        props.action.type == "Task"
      );
      setLoading(false);
      updateRows();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <ActionIcon
      className="table-action"
      onClick={onClick}
      color={"primary"}
      label={props.action.name}
      icon={props.action.actionType ? props.action.actionType.icon : "build"}
    />
  );
};
