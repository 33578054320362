import React, {Fragment, useEffect} from "react";
import "./AttachmentsTab.scss";
import {AttachmentLink} from "./AttachmentLink";
import { Typography } from "@material-ui/core";
import { cmsItemAPI } from "../../../../../../../utils/api";
import { Attachment, AttachmentTwoTone } from "@material-ui/icons";



interface IAttachmentsTab {
    attachments:any;
}

export const AttachmentsTab = (props: IAttachmentsTab) => {
    const [agencyRule,setAgencyRules] = React.useState<any>();
    const [ruleImpact,setRuleImpact] = React.useState<any>();
    const [adoptedRules, setAdoptedRules] = React.useState<any>();

    useEffect(() => {
        let agencyFiles:any[] = [];
        let ruleFiles:any[] = [];
        let adoptedFiles:any[]= [];
        props.attachments.forEach((item:any) => {
            if(item.agencyRules) {
                item.agencyRules.forEach((agencyRule:any) => {
                    agencyFiles.push(agencyRule);
                })
            }
            if(item.ruleImpact) {
                item.ruleImpact.forEach((ruleImpact:any) => {
                    ruleFiles.push(ruleImpact);
                })
            }
            if(item.adoptedRules) {
                item.adoptedRules.forEach((adopted:any) => {
                    adoptedFiles.push(adopted);
                })
            }
        })
        setAgencyRules(agencyFiles);
        setRuleImpact(ruleFiles);
        setAdoptedRules(adoptedFiles);
    }, [props.attachments])


    return (
        <div className="attachments-container">
            <Typography variant="h6" component="h6" className="label">Agency Rule Report</Typography>
            <ul>
                {agencyRule ?
                    agencyRule.map((attachment:any,index:number) => {
                        return <li><AttachmentLink guid={attachment.guid} fileName={attachment.filename}/></li>
                    }) : null
                }
            </ul>
            <Typography variant="h6" component="h6" className="label">Rule Impact Report</Typography>
            <ul>
                {ruleImpact ?
                        ruleImpact.map((attachment:any,index:number) => {
                        return <li><AttachmentLink guid={attachment.guid} fileName={attachment.filename}/></li>
                    }) : null
                }
            </ul>
            {adoptedRules ? adoptedRules.length > 0 ? (<Typography variant="h6" component="h6" className="label">Adopted Rule Document</Typography>) : null : null}
            <ul>
                {adoptedRules ?
                    adoptedRules.map((attachment:any,index:number) => {
                        return <li><AttachmentLink container={"adopted-rule-documents"} guid={attachment.guid} fileName={attachment.filename}/></li>
                    }) : null
                }
            </ul>
        </div>
    )
}
