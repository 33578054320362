import React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./table.scss"
import { CircularProgress } from '@material-ui/core';
import { Authorized } from '../Authorized';
import { AuthenticationContext } from '../../utils/authentication/AuthenticationProvider';
import { ApplicationRoles } from '../../utils/model/ApplicationUser';
import { Fragment } from 'react';


interface ITableProps {
  rows: any;
  columns: any;
  add?: () => void;
  edit?: (rowData: any) => any;
  height?: string;
  tableName?: string;
  paging?: (page: any) => any;
  numberOfRecords?: number;
  filterable?: boolean;
  sortable?: boolean;
  deleteable?: boolean;
  delete?: (rowData: any) => void;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

export const Table = (props: ITableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [numberOfRecords, setNumberOfRecords] = React.useState<number>(0);
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );
  const [auth, setAuth] = React.useState<any>(null);


  const { isAuthenticated, getUser } = React.useContext(
    AuthenticationContext
  );

  const hasRole = async (roles: ApplicationRoles[]) => {
    let user = await getUser();
    if (user && roles != null) {
      let found = false;
      if (typeof user.profile?.role === "string") {
        const role = user.profile?.role as unknown;
        found = roles.some((r) => role === r);
      } else {
        found = user.profile?.role?.some((r) => roles?.includes(r));
      }
      return found;
    }
    return true;
  }

  const onMount = React.useCallback(async () => {
    if (props.height) {
      setHeight(props.height);
    }
    let authorized = await hasRole(["Office of Administrative Rules", "Global User Administrator"]);
    setAuth(authorized);
    setDataResult(process(props.rows, props.paging ?? dataState));
  }, [props.paging, props.columns])


  React.useEffect(() => {
    onMount();
  }, [props.rows, props.height, props.columns])

  React.useMemo(() => {
    setDataState({
      skip: 0,
      take: 20,
      sort: [{ field: "id", dir: "desc" }]
    });
  }, [props.numberOfRecords])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  let _pdfExport: GridPDFExport | null;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const pageChange = async (event: GridPageChangeEvent) => {
    if (props.paging) {
      let newRecords = await props.paging(event.page);
      setDataState(event.page);
      setDataResult(process(props.rows, event.page));
    }
  }

  const EditColumn = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        {
          props.edit ?
            <Authorized
              showComponent={false}
              redirectToLogin={false}
              roles={["Office of Administrative Rules", "Global User Administrator"]}
            >
              <span>
                <Button primary={true} onClick={() => props.edit!(data.dataItem)}>Edit</Button>
                {props.deleteable ? (
                  <Button primary={false} onClick={() => props.delete!(data.dataItem)} className="warn-button">Delete</Button>
                ) : null}
              </span>
            </Authorized> :
            null
        }
      </td>
    )
  }

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element: any, i: number) => {
      if (element.field != "contactType" && element.field != "recordStatus" && element.field != "status" && element.field != "id") {
        return (<GridColumn field={element.field} key={i} title={element.title} />);
      }
    });

    if (auth) {
      columnsToShow.unshift(<GridColumn field={"Link"} title="Edit" cell={EditColumn} />);
    }

    if (columnsToShow.length > 0 && props.rows.length > 0) {
      return (
        <Grid
          style={{
            height: height,
          }}
          sortable={props.sortable ?? false}
          filterable={props.filterable ?? false}
          resizable={true}
          // groupable={true}
          reorderable={true}
          pageable={{
            buttonCount: 3,
            pageSizes: [5, 10, 20, props.numberOfRecords ?? props.rows.length],
          }}
          data={props.rows.slice(dataState.skip, dataState.take + dataState.skip)}
          skip={dataState.skip}
          take={dataState.take}
          onPageChange={pageChange}
          {...dataState}
          onDataStateChange={dataStateChange}
          total={props.numberOfRecords ? props.numberOfRecords : 0}
        >
          <GridToolbar>
            <ButtonGroup className="button-group">
              {/* <Button
                icon="xls"
                title="Export to Excel"
                className="k-button k-secondary button"
                onClick={exportExcel}
              /> */}
              <Button
                icon="pdf"
                title="Export to PDF"
                className="k-button k-secondary button"
                onClick={exportPDF}
              />
              <Authorized
                showComponent={false}
                redirectToLogin={false}
                roles={["Office of Administrative Rules", "Global User Administrator"]}
              >
                <Button
                  icon="add"
                  className="k-button k-secondary button"
                  title="Add Record"
                  onClick={props.add}
                />
              </Authorized>
            </ButtonGroup>
            {props.tableName ? (
              <h3>{props.tableName}</h3>
            ) : null}
          </GridToolbar>
          <GridNoRecords>
            No Records Found
          </GridNoRecords>
          {columnsToShow}
        </Grid>
      );
    } else {
      return (
        <Fragment>
          <div className="empty-table-container">
            <Authorized
              showComponent={false}
              redirectToLogin={false}
              roles={["Office of Administrative Rules", "Global User Administrator"]}
            >
              <div>
                <ButtonGroup className="add-button-group">
                  <Button
                    icon="add"
                    className="k-button k-secondary add-button"
                    title="Add Record"
                    onClick={props.add}
                  >
                    Add Record
                  </Button>
                </ButtonGroup>
              </div>
            </Authorized>
            <div className="center-text-heading">No Records Found</div>
          </div>
        </Fragment>
      );
    }
  }

  return (
    <div className="table">
      <ExcelExport
        data={props.rows}
        ref={(exporter: any) => {
          _export = exporter;
        }}
      >
        {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}


