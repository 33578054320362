import * as React from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridExpandChangeEvent,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./DraftTable.scss";
import { CircularProgress, Snackbar, SnackbarContent, Tooltip } from "@material-ui/core";
import {
  ActionsLayout,
  Dialog,
  DialogActionsBar,
} from "@progress/kendo-react-dialogs";
import { draftAPI, filingAPI, segmentHistoryAPI } from "../../../../../utils/api";
import { AlertDialog } from "../../../../../components";
import { RuleDraftEditButton } from "./RuleDraftEditButton";
import { Fragment } from "react";
import { DraftStatuses } from "../../../../../utils/enums/DraftStatuses";
import { SnackbarAlert } from "../SnackbarAlert";
import { Paragraph } from "../../../../../components/Paragraph";
import { Alert } from "@material-ui/lab";

interface IDraftTableProps {
  rows: any;
  columns: any;
  numberOfRecords: number;
  add?: () => void;
  edit: (data: any) => void;
  reloadData: () => any;
  height?: string;
  tableName?: string;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

/**
 *
 * @author Brandon Larsen <brandon.larsen@tecuity.com>
 * @param {Object[]} rows - Array of row data objects
 * @param {Object[{title:string,field:string}]} columns - array of column name objects {title:string,field:string}
 * @param {number} numberOfRecords - specify total number of records in the table
 * @param {() => void} [add] - function to open dialog for adding new table data
 * @param {string} [height] - string to override the table height
 * @param {string} [tableName] - adds a lable to the table if desired
 * @returns kendo table component for draft records
 */
export const DraftTable = (props: IDraftTableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }],
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows ?? [], dataState)
  );
  const [visible, setVisible] = React.useState(false);
  const [draftToDelete, setDraftToDelete] = React.useState<any>();
  const [alertMessageOpen, setAlertMessageOpen] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<any>(<p></p>);
  const [alertMessageType, setAlertMessageType] = React.useState<string>("success");

  const onMount = React.useCallback(async () => {
    // console.log('Draft Table Mounted');
    if (props.height) {
      setHeight(props.height);
    }

    setDataResult(process(props.rows, dataState));
  }, [props.rows, props.columns]);

  React.useEffect(() => {
    onMount();
  }, [props]);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  let _pdfExport: GridPDFExport | null;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const deleteDraft = async (dataItem: any) => {
    // this needs to delete the segment history, draft, and and segments created
    let includedInFiling: any;
    try {
      includedInFiling = await filingAPI.getFilingsByDraftId(dataItem.id);
    } catch (error) {
      console.warn("Could not find any filing records", error);
    }

    if (!includedInFiling) {
      let deleteResponse = await draftAPI.deleteDraft(dataItem.id);
      setAlertMessage(deleteResponse);
      setAlertMessageType("success")
      toggleAlert();

      setVisible(!visible);
      props.reloadData();
    } else {
      let filingMessage: string = "";
      includedInFiling.forEach((x: any) => filingMessage += `<li>${x.entity.docketNumber} ${x.name}</li>`)
      // console.log(filingMessage);
      var message = <div><b><p>Cannot delete draft because it is being used by the following filings.</p><Paragraph value={filingMessage} className="" /></b></div>
      setAlertMessageType("warning");
      setAlertMessage(message);
      toggleAlert();
    }

    // console.log("deleteResponse: ", deleteResponse);
    // alert(`${draftToDelete.draftName} Successfully Deleted.`);
  };

  const toggleDialog = (dataItem: any) => {
    setDraftToDelete(dataItem);
    setVisible(!visible);
  };

  const toggleAlert = () => {
    alertMessageOpen ? setAlertMessageOpen(false) : setAlertMessageOpen(true);
  }

  const alertResponse = (response: boolean) => {
    // console.log(response);
    visible ? setVisible(false) : setVisible(true);
    if (response) {
      deleteDraft(draftToDelete);
    }
  };

  const editButtonColumn = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        {data.dataItem.draftStatusId == DraftStatuses.New ? (
          <Fragment>
            <Tooltip title="Edit Draft" aria-label="add">
              <Button
                className="k-button k-primary edit-button"
                onClick={() => props.edit(data.dataItem)}
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip title="Delete Draft" aria-label="add">
              <Button
                className="k-button delete-button"
                onClick={() => toggleDialog(data.dataItem)}
              >
                Delete
              </Button>
            </Tooltip>
          </Fragment>
        ) : (
          "OAR Accepted. Edits are locked."
        )}
      </td>
    );
  };

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element: any, i: number) => {
      if (
        element.field != "id" &&
        element.field != "createdBy" &&
        element.field != "isEditable"
      ) {
        return (
          <GridColumn field={element.field} key={i} title={element.title} />
        );
      }
    });

    columnsToShow.unshift(
      <GridColumn
        key="Edit"
        field={"Edit"}
        title="Edit Draft"
        cell={editButtonColumn}
      />
    );
    // columnsToShow.unshift(<GridColumn field={"dueDate"} title="Status" cell={dueDateColumn}/>);

    if (columnsToShow.length >= 0 && props.rows.length >= 0) {
      return (
        <Grid
          className="draft"
          key={"DraftTable"}
          style={{
            height: height,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          groupable={true}
          reorderable={true}
          pageable={{
            buttonCount: 4,
            pageSizes: true,
          }}
          data={dataResult}
          {...dataState}
          onDataStateChange={dataStateChange}
          // detail={DetailComponent}
          // expandField="expanded"
          // onExpandChange={expandChange}
          total={props.numberOfRecords ? props.numberOfRecords : 0}
        >
          <GridToolbar>
            <ButtonGroup className="button-group">
              <Button
                icon="pdf"
                title="Export to PDF"
                className="k-button k-secondary button"
                onClick={exportPDF}
              />
              <Button
                icon="add"
                className="k-button k-secondary button"
                title="Add Record"
                onClick={props.add}
              />
            </ButtonGroup>
            {props.tableName ? <h3>{props.tableName}</h3> : null}
          </GridToolbar>
          <GridNoRecords>
            <div>No Records Found</div>
          </GridNoRecords>
          {columnsToShow}
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="work-queue">
      <AlertDialog
        textContent={
          "This will delete the draft " +
          (draftToDelete ? draftToDelete.draftName : "")
        }
        open={visible}
        close={!visible}
        alertResponse={alertResponse}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={alertMessageOpen}
        autoHideDuration={6000}
        onClose={toggleAlert}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={alertMessageType === "success" ? "success" : "warning"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {_renderTable()}
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
};
