import { Identifiable } from ".";
import { VisibilityLevel } from "../enums";
import { Status } from "./Status";

export class Workflow extends Identifiable {
  constructor(name: string, visibility: VisibilityLevel) {
    super(null);
    this.name = name;
    this.visibility = visibility;
    this.statusCollection = [];
  }
  public name: string;
  public visibility: VisibilityLevel;
  public statusCollection: Status[];
  public addStatus = (status: Status) => {
    this.statusCollection.push(status);
  };
}
