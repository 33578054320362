import * as React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardTitle,
    GridLayout,
    GridLayoutItem,
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from "@progress/kendo-react-layout";
import "./Subscription.scss";
// import loginBackground from "../../../../../assets/ne-logo.png";
import {EmailForm} from "./components/SubscriptionLoginForms/EmailForm";
import {PasswordForm} from "./components/SubscriptionLoginForms/PasswordForm";
import {SubscriptionLoginSteps} from "./components/SubscriptionLoginForms/SubscriptionLoginSteps";
import {SubscriptionRecoverySteps} from "./components/SubscriptionRecoveryForms/SubscriptionRecoverySteps";
import {Menu} from "../../components/Menu";
import {getOS} from "../../utils/UserOS";
import {Container} from "@material-ui/core";
import { SubscriptionRegisterSteps } from "./components/SubscriptionRegisterForms/SubscriptionRegisterSteps";


interface IUserProps {
    tabSelect: any;
}

export const Subscription = (props: IUserProps) => {
    const os = getOS();
    const [selected, setSelected] = React.useState<number>(0);
    const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

    const stepPages = [EmailForm, PasswordForm];
    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    // useEffect(() => {
    //   setSelected(props.tabSelect);
    // }, [props.tabSelect]);


    return (
        <div className="subscriptions">
            <Container>
                <Menu title={"Home"} OS={platform}></Menu>
                <main id="main-content">
                    <div className="modal-content" style={{background: "lightsteelblue"}}>

                        <div className="step-wrapper" >

                            <div className="form-container">

                                <Card className="card-container">
                                    {/*<CardImage*/}
                                    {/*  src={loginBackground}*/}
                                    {/*  style={{ backgroundColor: "black", padding: 20 }}*/}
                                    {/*/>*/}
                                    <div>
                                        <CardHeader>
                                            <CardTitle style={{textAlign: "center"}}>
                                                <strong>Rules & Regulations Subscription Service</strong>
                                            </CardTitle>
                                            <CardSubtitle></CardSubtitle>
                                        </CardHeader>

                                        <CardBody>
                                            <div>
                                                <TabStrip selected={selected} onSelect={handleSelect}>
                                                    <TabStripTab title="Login">
                                                        <SubscriptionLoginSteps/>
                                                    </TabStripTab>

                                                    <TabStripTab title="Recover Account" disabled={false}>
                                                        <SubscriptionRecoverySteps/>
                                                    </TabStripTab>

                                                    <TabStripTab title="Register New User" disabled={false}>
                                                        <SubscriptionRegisterSteps tabSelect={selected}
                                                        />
                                                    </TabStripTab>
                                                </TabStrip>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                </main>
            </Container>
        </div>


    );
};

