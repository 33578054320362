import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { ExecutiveDeclaration, Filing } from "../model";

class ExecutiveDeclarationAPI extends BaseAPI<ExecutiveDeclaration> {
    constructor() {
      super("entityType", APISettings.adminRulesApiUrl);
    }

    //#region HttpGet
    public getAllByFilingId: (filingId: number) => Promise<ExecutiveDeclaration> = async (filingId: number) => {
        var response = await BaseAPI.axios.get(`${this.apiUrl}/GetExecutiveDeclarationsByFilingId/${filingId}`);
        return response.data;
    };

    public getById: (id: number) => Promise<ExecutiveDeclaration> = async (id: number) => {
        var response = await BaseAPI.axios.get(`${this.apiUrl}/GetExecutiveDeclarationById/${id}`);
        return response.data;
    };

    public getCurrentByFilingId: (filingId: number) => Promise<ExecutiveDeclaration> = async (filingId: number) => {
        var response = await BaseAPI.axios.get(`${this.apiUrl}/GetCurrentExecutiveDeclarationByFilingId/${filingId}`);
        return response.data;
    };
    //#endregion

    //#region HttpPost
    public create: (model: ExecutiveDeclaration) => Promise<ExecutiveDeclaration> = async (model: ExecutiveDeclaration) => {
        var response = await BaseAPI.axios.post(`${this.apiUrl}/CreateExecutiveDeclaration`, {model});
        return response.data;
    };
    //#endregion
}

export const executiveDeclarationAPI = new ExecutiveDeclarationAPI();