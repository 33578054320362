import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core/";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { UrlParser } from "../../../../../utils";
import { statusAPI, workflowAPI } from "../../../../../utils/api";
import {
  Status,
  Workflow,
  Workflow as WorkflowModel,
} from "./../../../../../utils/model";
import { StatusCard } from "./components/StatusCard/StatusCard";
import { WorkflowModal } from "./components/WorkflowModal/WorkflowModal";
import "./WorkflowHome.scss";

interface IWorkflowProps extends RouteComponentProps {
  workflows: WorkflowModel[];
  ClassName?: string;
  triggerReload: () => void;
}

const WorkflowHomeComponent = (props: IWorkflowProps) => {
  const { workflows } = props;
  const [statusCollection, setStatusCollection] = useState<Status[]>(
    workflows[0].statusCollection
  );
  const [selectedWorkflow, setSelectedWorkflow]: any = useState(workflows[0]);

  const setupWorkflow = React.useCallback(async () => {
    const queryInfo = UrlParser.Workflow.getParameters(props.location.search);
    if (queryInfo.workflowId != null) {
      const currentWorkflow = await workflowAPI.get(queryInfo.workflowId);
      setOpen(currentWorkflow.name);
      await setSelectedWorkflow(currentWorkflow);
      await setStatusCollection(currentWorkflow.statusCollection);
    } else {
      props.history.push(UrlParser.Workflow.parseWorkflow(workflows[0].id!));
    }
  }, [props.location.search]);

  const setupStatus = () => {
    const queryInfo = UrlParser.Workflow.getParameters(props.location.search);
    if (queryInfo.statusId != null) {
      const statusItem:
        | Status
        | undefined = selectedWorkflow.statusCollection.find(
        (statusCurrent: Status) => statusCurrent.id == queryInfo.statusId
      );
      if (statusItem != null) {
        scrollToStatus(statusItem);
      }
    }
  };

  useEffect(() => {
    setupWorkflow();
  }, [props.location.search]);
  useEffect(() => {
    setupStatus();
  }, [statusCollection]);

  const [open, setOpen]: any = useState();
  const [openModal, setOpenModal]: any = useState(false);
  const [openDelete, setOpenDelete]: any = useState(false);

  const handleClick = (workflow: Workflow) => {
    if (open !== workflow.name) {
      props.history.push(UrlParser.Workflow.parseWorkflow(workflow.id!));
    } else {
      setOpen(false);
    }
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpenModal(false);
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteClose = (event: any) => {
    event.stopPropagation();
    setOpenDelete(false);
  };

  const jumpToStatus = (status: Status) => {
    const querystring = UrlParser.Workflow.parseStatus(
      selectedWorkflow.id!,
      status.id!
    );
    props.history.push(querystring);
  };

  const scrollToStatus = (status: Status) => {
    const statusElement = document.getElementById(status.name);
    if (statusElement) {
      statusElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const updateWorkflow = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as keyof typeof String;
    // setWorkflowName(value);
    let workflow = selectedWorkflow;
    workflow.name = value;
    setSelectedWorkflow(workflow);
  };

  const saveWorkflow = async () => {
    try {
      let workflow = new WorkflowModel(
        selectedWorkflow.name,
        selectedWorkflow.visibility
      );
      workflow.id = selectedWorkflow.id;
      await workflowAPI.patch(selectedWorkflow.id, workflow.name);
      props.triggerReload();
    } catch (error) {
      console.error("Cannot save workflow: ", error);
    }
  };

  const deleteWorkflow = async () => {
    try {
      await workflowAPI.delete(selectedWorkflow.id);
      props.triggerReload();
    } catch (error) {
      console.error("Cannot Delete workflow: ", error);
    }
  };

  const reloadStatusCollection = async () => {
    const collection: Status[] = await statusAPI.getAll({
      workflowId: selectedWorkflow.Id,
    });
    setStatusCollection(collection);
  };

  return (
    <React.Fragment>
      <div className="root">
        <aside className="menu-list">
          {props.workflows.map((workflow, key) => (
            // <Collapse>
            <List key={key} component="div" disablePadding>
              <ListItem button onClick={() => handleClick(workflow)}>
                <ListItemText className="list-item" primary={workflow.name} />
                {open === workflow.name ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={open === workflow.name}
                timeout="auto"
                unmountOnExit
              >
                {statusCollection.map((status: any, index) => (
                  <ListItem
                    key={index}
                    button
                    dense
                    onClick={() => jumpToStatus(status)}
                  >
                    <ListItemText
                      className="sub-list-text"
                      primary={status.name}
                    />
                  </ListItem>
                ))}
              </Collapse>
              <Divider />
            </List>

            // </Collapse>
          ))}
          <div className="workflow-add">
            <IconButton
              size="small"
              aria-label="Add new action"
              className="add-new-icon"
              onClick={handleClickOpen}
            >
              <AddIcon />
            </IconButton>
            <WorkflowModal
              open={openModal}
              triggerReload={props.triggerReload}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
            <Dialog
              open={openDelete}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete Workflow "' +
                  (selectedWorkflow ? selectedWorkflow.name + '"' : "")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  WARNING: To delete a Workflow each status must first be
                  removed workflows are also tied to filings which must also be
                  removed in order to delete
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={(event) => {
                    deleteWorkflow();
                    handleDeleteClose(event);
                    props.triggerReload();
                  }}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </aside>
        <div className="content">
          <h1 className="visually-hidden">Workflow</h1>
          <h2 className="visually-hidden">
            {selectedWorkflow !== undefined
              ? selectedWorkflow.name
              : "Select a workflow"}
          </h2>
          <div className="name-field">
            <TextField
              id={
                selectedWorkflow !== undefined
                  ? selectedWorkflow.name
                  : "emptyName"
              }
              className="workflow-name"
              label="Workflow Name"
              variant="outlined"
              value={
                selectedWorkflow !== undefined ? selectedWorkflow.name : ""
              }
              onChange={updateWorkflow}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button size="small" color="primary" onClick={saveWorkflow}>
                      Update Name
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              size="medium"
              color="secondary"
              aria-label="Delete Workflow"
              className="delete-icon"
              onClick={handleDeleteOpen}
            >
              <DeleteIcon />
            </IconButton>
          </div>
          <div className="card-actions">
            <StatusCard
              triggerReload={reloadStatusCollection}
              statusList={statusCollection}
              workflow={selectedWorkflow}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const WorkflowHome = withRouter(WorkflowHomeComponent);
