// import React from "react";
// import { Filing } from "../../../../utils/model";
import { CommentThreadComp } from "../../../CommentThread";
import { FilingHistory } from "../../../FilingHistory/FilingHistory";
import "./CommentTab.scss";

interface ICommentTabProps {
  filingId: number;
}

export const CommentTab = (props: ICommentTabProps) => {
  return (
    <div className="comment-tab">
      <CommentThreadComp type="filing" id={props.filingId} />
    </div>
  );
};
