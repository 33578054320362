import { Skeleton } from "@material-ui/lab";
// import React from "react";
import { StorageContainer } from "../../utils/enums";
import "./Image.scss";

interface IImageProps {
  className: string;
  source: string;
  alt?: string | undefined;
  label: string;
  storageContainer: StorageContainer;
  refresh: boolean;
}

export const Image = (props: IImageProps) => {
  let src = `https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=${
    StorageContainer[props.storageContainer]
  }&name=${
    props.source
  }`;

  if (!props.refresh) {
    if (props.source != "") {
      return (
        <img
          className={props.className}
          src={src}
          alt={props.alt ?? undefined}
        />
      );
    } else {
      return (
        <Skeleton
          // animation="wave"
          variant="rect"
          height={150}
          className={props.className}
        />
      );
    }
  } else {
    return <Skeleton animation="wave" variant="rect" width={40} height={40} />;
  }
};
