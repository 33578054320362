import { useState } from "react";
// const { SearchClient, AzureKeyCredential } = require("@azure/search-documents");
import { AppBar, Box, Button, Container, IconButton, Toolbar } from "@material-ui/core";
// import {Search} from "./Search";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

import "./SearchWrapper.scss";

interface IRegisterSearchWrapperProps {}

export const RegisterSearchWrapper = (props: any) => {
  const [q, setQ] = useState("");
  const [showResults, setShowResults] = useState(false);

  // const { setSearchFilter, searchFilters, clearSearchFilters } = useSearch();

  const onSearchHandler = () => {
    props.postSearchHandler(q);
  };

  const suggestionClickHandler = (s: any) => {
    (document.getElementById("search-box") as HTMLTextAreaElement).value = s;
    props.postSearchHandler(s);
  };

  const onEnterButton = (event: { keyCode: number; }) => {
    if (event.keyCode === 13) {
      onSearchHandler();
    }
  };

  const onChangeHandler = () => {
    var searchTerm = (document.getElementById("search-box") as HTMLTextAreaElement).value;
    // setShowSuggestions(true);
    setQ(searchTerm);

    // use this prop if you want to make the search more reactive
    if (props.searchChangeHandler) {
      props.searchChangeHandler(searchTerm);
    }
  };

  const clearSearch = () => {
    setQ("");
    props.postSearchHandler("");
  }

  return (
    <Container >
      {/* <ButtonAppBar /> */}
      <div className="button-app-bar">
        <AppBar position="static" className="toolbar">
          <Toolbar>
            <h2 aria-level={1} className="shrunk-h2">
              Search Oklahoma Registers
            </h2>
            <Button variant="contained" onClick={clearSearch}>Clear Search</Button>
          </Toolbar>
        </AppBar>
      </div>
      <div className="register-wrapper" onKeyDown={e => onEnterButton(e)}>
          <div className="register-container" >
              <TextField
                id="search-box"
                label="Search Register"
                style={{ margin: 8 }}
                placeholder="Enter a WORD or PHRASE to search registers"
                fullWidth
                className="search-box"
                value={q}
                autoComplete="off"
                onChange={onChangeHandler}
                defaultValue={q}

                InputLabelProps={{
                  shrink: true,
                }}
                aria-label="search registers form"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="primary"
                      aria-label="execute search"
                      onClick={onSearchHandler}
                      type="submit"
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
          </div>
      </div>
    </Container>
  );
};
