import React, { useEffect } from "react";
import { CmsItem } from "../../../../utils/model/";
import { CmsType, StorageContainer } from "../../../../utils/enums/";
import { cmsItemAPI } from "../../../../utils/api";
import { EditQuickLinkDialog } from ".";

interface IEditQuickLinkProps {
  name: string;
  fileUpload: boolean;
  cmsLinkItem: CmsItem;
  cmsNameItem: CmsItem;
  onChange: (value: CmsItem) => void;
}

export const EditQuickLink = (props: IEditQuickLinkProps) => {
  const initialCmsItem = new CmsItem(props.name, "PlaceHolder", CmsType.Image);

  return (
    <div>
      <EditQuickLinkDialog
        cmsNameItem={props.cmsNameItem}
        cmsLinkItem={props.cmsLinkItem}
        readOnly={props.fileUpload}
        onChange={props.onChange}
      />
    </div>
  );
};
