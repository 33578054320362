import { IconButton } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import React from "react";
import "./EditButton.scss";

interface IEditButtonProps {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EditButton = (props: IEditButtonProps) => {
  return (
    <IconButton
      className={props.className}
      onClick={props.onClick}
      color="primary"
      aria-label="Edit"
      component="span"
    >
      <Edit />
    </IconButton>
  );
};
