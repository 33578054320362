import { Divider, ListItem } from "@material-ui/core";
import { useCallback } from "react";
// import React from "react";
// import { FilingHistory } from "../../../../utils/model/FilingHistory";
import { FilingStatusHistory } from "../../../../utils/model/FilingStatusHistory";
import { Paragraph } from "../../../Paragraph";
import { UserAvatar } from "../../../UserAvatar";
import "./History.scss";

interface IHistoryProps {
  key?: number;
  history: FilingStatusHistory;
}

export const History = (props: IHistoryProps) => {
  const { key, history } = props;

  const getDate = (date: Date) => {
    // + "Z" makes the date string get interpreted as UTC where we store UTC dates in the DB
    let newdate = new Date(date + "Z");
    // make sure the dates display in CST
    let intlDateObj = new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'medium',
      timeZone: "America/Chicago"
    })
    return intlDateObj.format(newdate).toString();
  }

  return (
    <div className="comment">
      <div key={key}>
        <ListItem alignItems="flex-start">
          <UserAvatar userId={history.createdBy} displayText="" />
          {/* <UserAvatar userId={history.createdBy}  /> */}

        </ListItem>
        {/* <ListItem> */}
        {/* <ListItemText primary="From" secondary="" /> */}
        {/* <ListItemText primary="To" secondary={history.newValue} /> */}
        {/* </ListItem> */}
        <div className="history-contents">
          <div>
            <h4>STATUS</h4>
            <Paragraph
              value={typeof history.statusDescription === "string" ? history.statusDescription : ""
              }
              className="original"
            />
          </div>
          <div>
            <h4>DATE</h4>
            <Paragraph
              value={getDate(history.createdOn)}

              //   typeof history.createdOn.toLocaleString() === "string" ? history.createdOn.toLocaleString() : ""
              // }
              className="new"
            />
          </div>
          {/* <div>
            <h4>ACTOR</h4>
            <Paragraph
              value={
                typeof history.createdBy === "string" ? history.createdBy : ""
              }
              className="new"
            />
          </div> */}
        </div>
        <Divider variant="inset" component="li" />
      </div>
    </div>
  );
};
