import { Identifiable } from "./Identifiable";

export class AuditableEntity extends Identifiable {
  constructor() {
      super(null);
  }
  public createdBy?: string;
  public created?: Date;
  public lastModifiedBy?: string;
  public lastModified?: Date;
}
