import { Container } from "@material-ui/core";
import { Navigation } from "./../../../components";
import "./State.scss";
import { MainComponent } from "../../../pages";
import { NavigationProvider } from "../../../utils/hooks";
import { DevelopmentToolbar } from "./components/DevelopmentToolbar";

interface IStateProps { }

export const State = (props: IStateProps) => {
  return (
    <Container className="state-container" maxWidth={false}>
        <NavigationProvider>
          <Navigation />
          <main id="main-content" className="state">
          <MainComponent />
          </main>
        </NavigationProvider>
        <DevelopmentToolbar/>
    </Container>
  );
};
