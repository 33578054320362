import { Fab, Step, Stepper } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import cloneDeep from "lodash/cloneDeep";
import { statusAPI, workflowAPI } from '../../../../../utils/api';
import { StatusRecordStatus, StatusType as StatusTypes, VisibilityLevel } from './../../../../../utils/enums';
import { useWorkflowDispatch, useWorkflowState } from './../../../../../utils/hooks';
import { Status, StatusType } from "./../../../../../utils/model";
import { StatusStep } from './components';
import './WorkflowPath';

interface IWorkflowPathProps {
    activeStep: number;
    setActiveStep: (activeStep: number) => void;
    statusList: Status[];
}

export const WorkflowPath = (props: IWorkflowPathProps) => {
    const { activeStep, setActiveStep } = props;
    const workflow = useWorkflowState();
    const setWorkflow = useWorkflowDispatch();

    const addNewStatus = async () => {
        const statusType = new StatusType(3, "Approved", "thumb_up_off_alt");
        const status = new Status("", "Wallpaper", StatusTypes.Approval, 1, workflow, VisibilityLevel.Title, StatusRecordStatus.Active, statusType);
        const newStatus = await statusAPI.create(status);
        if (workflow.id && newStatus.id) {
            workflowAPI.addStatus(workflow.id, newStatus.id);
        }
        const operatingWorkflow = cloneDeep(workflow);
        operatingWorkflow.statusCollection.push(status);
        await setWorkflow(operatingWorkflow);
    }

    return (
        <div>
            <Stepper activeStep={activeStep} >
                {workflow.statusCollection.map((status, key) => <StatusStep key={key} index={key} onClickStep={setActiveStep} status={status} />)}
                <div>
                    <Step>
                        <Fab onClick={addNewStatus} >
                            <Add />
                        </Fab>
                    </Step>
                </div>
            </Stepper>
        </div>
    );
}
