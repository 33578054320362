import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar, TextField, MenuItem, Divider, Select, Box, Chip, FormControl, Tooltip, FormControlLabel, Checkbox } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import ImageFilter9Plus from "material-ui/svg-icons/image/filter-9-plus";
import React, { useEffect, useMemo } from "react";

import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { contactAPI, contactTypeAPI, ruleAPI, ruleTypeAPI } from "../../../../../../../utils/api/";
import { StatusRecordStatus, StatusType } from "../../../../../../../utils/enums";
import { RuleStatus } from "../../../../../../../utils/enums/RuleStatus";
import { usePrevious } from "../../../../../../../utils/hooks";
import { Address, ContactType, Rule, Segment } from "../../../../../../../utils/model";
import { Contact } from "../../../../../../../utils/model/Contact";
import { RuleType } from "../../../../../../../utils/model/RuleType";
import { StateOfficeNavigationSettings } from "../../../../../../../utils/settings";
import "./RuleModal.scss";

interface IRuleModalProps {
  open: boolean;
  editRow?: any;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}



export const RuleModal = (props: IRuleModalProps) => {
  const { RuleTitle } = StateOfficeNavigationSettings;
  const { open, handleClickOpen, handleClose } = props;
  const prevOpen = usePrevious(open);
  const [values, setValues] = React.useState({
    id: 1,
    title: "",
    reference: "",
    segment: "",
    filings: "",
    notes: "",
  });

  const initialContacts: Contact[] = [];
  const [liaisonContacts, setLiaisonContacts] = React.useState(initialContacts);
  const [cabinetContacts, setCabinetContacts] = React.useState(initialContacts);
  const [alternateLiaisonContacts, setAlternateLiaisonContacts] = React.useState(initialContacts);
  const [attestationOfficerContacts, setAttestationOfficerContacts] = React.useState(initialContacts);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackErrorOpen, setSnackErrorOpen] = React.useState(false);
  const [attestationError, setAttestationError] = React.useState(true);
  const [liaisonError, setLiaisonError] = React.useState(true);
  const [cabinetError, setCabinetError] = React.useState(true);
  const [referenceCodeError, setReferenceCodeError] = React.useState(true);
  const [titleError, setTitleError] = React.useState(true);
  const [isChecked, setIsChecked] = React.useState<boolean>();
  const [ruleStatus, setRuleStatus] = React.useState<RuleStatus>();
  const contactType = useMemo(() => new ContactType("", "None"), []);
  const address = useMemo(() => new Address("", "", "", "", "", "", 0), []);
  const initialContact: Contact = useMemo(
    () =>
      new Contact(
        "None",
        "",
        "",
        "",
        "",
        address,
        "",
        StatusType.Approval,
        contactType,
        -1,
        StatusRecordStatus.Active
      ),
    [address, contactType]
  );
  const [contact, setContact] = React.useState({ liaison: null, altLiaisons: [], attOfficers: [], cabinetSecretary: null });
  const [altLiaisonContact, setAltLiaisonContact] = React.useState<any[]>([]);
  const [attOfficerContact, setAttOfficerContact] = React.useState<any[]>([]);
  // const [cabinetContact, setCabinetContact] = React.useState<any>("")
  const initialRuleTypes: RuleType[] = [];
  const [ruleTypes, setRuleTypes] = React.useState(initialRuleTypes);
  const initialRuleType: RuleType = useMemo(() => new RuleType("None", "", 0), []);
  const [ruleType, setRuleType] = React.useState(initialRuleType);
  const [ruleId, setRuleId] = React.useState(-1);

  useMemo(() => {
    if (contact) {
      contact.attOfficers.length >= 1 ? setAttestationError(false) : setAttestationError(true);
      contact.liaison ? setLiaisonError(false) : setLiaisonError(true);
      contact.cabinetSecretary ? setCabinetError(false) : setCabinetError(true);
    } else {
      setLiaisonError(true);
      setAttestationError(true);
      setCabinetError(true);
    }
    if (values) {
      values.title != "" && values.title ? setTitleError(false) : setTitleError(true);
      values.reference != "" && values.reference ? setReferenceCodeError(false) : setReferenceCodeError(true);
    } else {
      setReferenceCodeError(true);
      setTitleError(true);
    }
  }, [contact, values])


  function _renderLiaisonDropdown() {
    let menuItems: any = [];
    liaisonContacts.sort((a: any, b: any) => (a.lastName > b.lastName) ? 1 : -1);
    liaisonContacts.forEach((liaison) => {
      if (liaison.id != null) {
        menuItems.push(
          <MenuItem key={liaison.id} value={liaison.id}>
            <div><b>{liaison.firstName + " " + liaison.lastName}</b>{": " + liaison.email.toLowerCase()}</div>
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  function _renderCabinetDropdown() {
    let menuItems: any = [];
    cabinetContacts.sort((a: any, b: any) => (a.lastName > b.lastName) ? 1 : -1);
    cabinetContacts.forEach((cabinet) => {
      if (cabinet.id != null) {
        menuItems.push(
          <MenuItem key={cabinet.id} value={cabinet.id}>
            <div><b>{cabinet.firstName + " " + cabinet.lastName}</b>{": " + cabinet.email.toLowerCase()}</div>
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  function _renderAltLiaisonDropdown() {
    let menuItems: any = [];
    alternateLiaisonContacts.forEach((liaison) => {
      if (liaison.id != null) {
        menuItems.push(
          <MenuItem key={liaison.id} value={liaison.id}>
            <div className="contact-menu-item">
              <span>
                {liaison.firstName + " " + liaison.lastName}
              </span>
              {
                altLiaisonContact
                  ? altLiaisonContact.find(element => element == liaison.id)
                    ? <Tooltip title="click to remove"><Check className="select-menu-icon" /></Tooltip>
                    : null
                  : null
              }
            </div>
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  function _renderAttOfficerDropdown() {
    let menuItems: any = [];
    attestationOfficerContacts.forEach((attOfficer) => {
      if (attOfficer.id != null) {
        menuItems.push(
          <MenuItem
            key={attOfficer.id}
            value={attOfficer.id}
          >
            <div className="contact-menu-item">
              <span>
                {attOfficer.firstName + " " + attOfficer.lastName}
              </span>
              {
                attOfficerContact
                  ? attOfficerContact.find(element => element == attOfficer.id)
                    ? <Tooltip title="click to remove"><Check className="select-menu-icon" /></Tooltip>
                    : null
                  : null
              }
            </div>
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  function _renderRuleTypeDropdown() {
    let menuItems: any = [];
    ruleTypes.forEach((id) => {
      if (id.id != null) {
        menuItems.push(
          <MenuItem key={id.id} value={id.id}>
            {id.name}
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  function generateLiaisonDropdown(response: any) {
    if (response) {
      let val: any = [];
      response.forEach((values: any) => {
        val.push(values);
      });
      setLiaisonContacts(val);
    }
  }

  function generateAltLiaisonDropdown(response: any) {
    if (response) {
      let val: any = [];
      response.forEach((values: any) => {
        val.push(values);
      });
      setAlternateLiaisonContacts(val);
    }
  }

  function generateAttOfficerDropdown(response: any) {
    if (response) {
      let val: any = [];
      response.forEach((values: any) => {
        val.push(values);
      });
      setAttestationOfficerContacts(val);
    }
  }

  function generateCabinetDropdown(response: any) {
    if (response) {
      let val: any = [];
      response.forEach((values: any) => {
        val.push(values);
      });
      setCabinetContacts(val);
    }
  }

  // function generateRuleTypeDropdown(response: any) {
  //   let val: any = [];
  //   if (response) {
  //     response.forEach((values: any) => {
  //       val.push(values);
  //     });
  //   }
  //   setRuleTypes(val);
  // }

  const setEditValues = React.useCallback(async () => {
    if (props.open && !prevOpen) {
      if (props.editRow) {
        if (props.editRow.id) {
          setValues({
            id: props.editRow.id,
            title: props.editRow.title,
            reference: props.editRow.referenceCode,
            segment: props.editRow.segment,
            filings: props.editRow.pendingFilings,
            notes: props.editRow.notes,
          });
          setRuleId(props.editRow.id);
          //use contact array to find contact base on id;
          if (props.editRow.contactIds) {
            setContact(props.editRow.contactIds);
            setAltLiaisonContact(props.editRow.contactIds.altLiaisons ?? []);
            setAttOfficerContact(props.editRow.contactIds.attOfficers ?? []);
          }
          let ruleType: any = await ruleTypeAPI.getRuleTypeById(props.editRow.ruleTypeId);
          setRuleType(ruleType);
        }
      }
    }
  }, [props.editRow])

  useEffect(() => {
    // Clear data before setting edit values
    setValues({
      id: 1,
      title: "",
      reference: "",
      segment: "",
      filings: "",
      notes: "",
    });
    setRuleType(initialRuleType);
    setContact({ liaison: null, altLiaisons: [], attOfficers: [], cabinetSecretary: null });
    setRuleId(-1);

    // Modal was opened
    if (props.open) {
      setEditValues();
    }

  }, [
    initialContact,
    initialRuleType,
    props.editRow,
    props.handleClickOpen,
    props.open,
    prevOpen,
  ]);


  const setupContacts = async () => {
    let contactTypes: any = await contactTypeAPI.getAllContactTypes();
    contactTypes.result.forEach(async (item: any, index: number) => {
      if (item.name == "Agency Liaison") {
        await contactAPI.getAllContactsByType(contactTypes.result[index].id, { pageNumber: 1, pageSize: 2000 }).then((response: any) => generateLiaisonDropdown(response.result));
      }
      if (item.name == "Alternate Liaison") {
        await contactAPI.getAllContactsByType(contactTypes.result[index].id, { pageNumber: 1, pageSize: 2000 }).then((response: any) => generateAltLiaisonDropdown(response.result));
      }
      if (item.name == "Attestation Officer") {
        await contactAPI.getAllContactsByType(contactTypes.result[index].id, { pageNumber: 1, pageSize: 2000 }).then((response: any) => generateAttOfficerDropdown(response.result));
      }
      if (item.name == "Cabinet Secretary") {
        await contactAPI.getAllContactsByType(contactTypes.result[index].id, { pageNumber: 1, pageSize: 2000 }).then((response: any) => generateCabinetDropdown(response.result));
      }
    })
    // generateDropdown(contacts);
  }

  useEffect(() => {
    let status: RuleStatus = props.editRow.ruleStatus;

    switch (status) {
      case RuleStatus.Active:
        setIsChecked(true);
        break;
      case RuleStatus.Inactive:
        setIsChecked(false);
    }

    if (props.open) {
      try {
        setupContacts();
      } catch (err) {
        console.error(err)
      }
      // try {
      //   ruleTypeAPI
      //     .getAllRuleTypes()
      //     .then((response) => generateRuleTypeDropdown(response));
      // } catch (err) {}
    }
  }, [props.open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelectLiaisonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (liaisonContacts != null) {
      let val = liaisonContacts.find((i) => i.id === event.target.value);
      if (val) {
        let contactIds: any = contact;
        contactIds.liaison = val.id;
        setContact(contactIds);
      }
    }
    setValues({ ...values });
  };

  const handleSelectCabinetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (cabinetContacts != null) {
      let val = cabinetContacts.find((i) => i.id === event.target.value);
      if (val) {
        let contactIds: any = contact;
        contactIds.cabinetSecretary = val.id;
        setContact(contactIds);
      }
    }
    setValues({ ...values });
  };

  const handleSelectAltLiaisonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (alternateLiaisonContacts != null) {
      let val = alternateLiaisonContacts.find((i) => i.id === event.target.value);
      if (val) {
        let contactIds: any = contact;
        if (val.id) {
          let index: any = contact.altLiaisons.findIndex((i: any) => i === val!.id);
          if (index != -1) {
            contactIds.altLiaisons.splice(index, 1);
          } else {
            contactIds.altLiaisons.push(val.id);
          }
          setContact(contactIds)
          setAltLiaisonContact(contactIds.altLiaisons ?? []);
        }
      }
    }
    setValues({ ...values });
  };

  const handleSelectAttestationOfficerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (attestationOfficerContacts != null) {
      let val = attestationOfficerContacts.find((i) => i.id === event.target.value);
      if (val) {
        let contactIds: any = contact;
        if (val.id) {
          let index: any = contact.attOfficers.findIndex((i: any) => i === val!.id);
          if (index != -1) {
            contactIds.attOfficers.splice(index, 1);
          } else {
            contactIds.attOfficers.push(val.id);
          }
          setContact(contactIds)
          setAttOfficerContact(contactIds.attOfficers ?? []);
        }
      }
    }
    setValues({ ...values });
  };

  const handleDeleteContact = (event: React.ChangeEvent<{ value: unknown }>) => {
    // console.log("Clicked on", event.target.value);
  }

  const handleSelectRuleTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (ruleTypes !== null) {
      let val = ruleTypes.find((i) => i.id === event.target.value);
      if (val) {
        setRuleType(val);
      }
    }
  };

  const handleIsActiveCheck = () => {
    switch (isChecked) {
      case true:
        setIsChecked(false);
        setRuleStatus(RuleStatus.Inactive)
        break;
      case false:
        setIsChecked(true);
        setRuleStatus(RuleStatus.Active);
        break;
    }
    setValues({ ...values });
  }

  const saveForm = async (event: any) => {
    //rule type id 3 is standard rule type
    let newRuleId = -1;
    let rule = new Rule(
      values.title,
      values.reference,
      (ruleId === newRuleId ? newRuleId : props.editRow.segmentId),
      contact,
      ruleType.id ?? 3,
      values.notes,
      ruleStatus!,
      StatusRecordStatus.Active
    );

    if (attestationError || liaisonError || referenceCodeError || titleError || cabinetError) {
      setSnackErrorOpen(true);
    } else {
      if (ruleId === newRuleId) {
        try {
          delete rule.id;
          await ruleAPI.createRule(rule);
        } catch (err) {
          console.error(err);
        }
      } else {
        try {
          rule.id = ruleId;
          await ruleAPI.updateRule(ruleId, rule);
          setSnackOpen(true);
        } catch (err) {
          console.error(err);
        }
      }
      handleClose(event);
    }

  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
    setSnackErrorOpen(false);
  };

  return (
    <div className="rule-modal">
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Save Successful</Alert>
      </Snackbar>
      <Snackbar
        open={snackErrorOpen}
        autoHideDuration={7000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Please fill out all required fields</Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="rule-dialog"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">{'Add/Update Title'}</DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText></DialogContentText>
          <FormControl fullWidth error={titleError}>
            <TextField
              required
              autoFocus
              margin="dense"
              name="title"
              label="Name"
              type="text"
              onChange={handleChange}
              value={values.title}
              fullWidth
              error={titleError}
            />
          </FormControl>
          {/* ************* KNOWN BUG *******************
          * You can currently enter in whatever reference code you would like, these should be forced to be unique.
          * TODO
          * Talk to Rob before you fix this, we may not be doing it this way in the end.
        / * ************* KNOWN BUG *******************/}
          <FormControl fullWidth error={referenceCodeError}>
            <TextField
              required
              margin="dense"
              name="reference"
              label="Reference Code"
              type="text"
              onChange={handleChange}
              value={values.reference}
              fullWidth
              error={referenceCodeError}
            />
          </FormControl>
          {/* <TextField
            margin="dense"
            name="notes"
            label="Notes"
            type="text"
            multiline
            maxRows={4}
            onChange={handleChange}
            value={values.notes}
            fullWidth
          /> */}
        </DialogContent>
        {/* ************* KNOWN BUG *******************
          * There are issues with Contacts currently
          * due to those issues we will add them in when they are working
          * TODO
          *  Fix contact creation and display and make it available here.
        / * ************* KNOWN BUG *******************/}
        <DialogContent className="contact-selection">
          <div>
            <FormControl error={liaisonError}>
              <DialogContentText className={liaisonError ? "error-text" : ""}>{liaisonError ? "Please Select a Liaison*" : "Agency Liaison"}</DialogContentText>
              <Select
                margin="dense"
                labelId="label"
                name="contactSelect"
                value={contact?.liaison}
                onChange={handleSelectLiaisonChange}
                fullWidth
              >
                {_renderLiaisonDropdown()}
              </Select>
            </FormControl>
            {/* Form controls go here if cabinet is required */}
          </div>
          <FormControl error={cabinetError}>
            <DialogContentText className={cabinetError ? "error-text" : ""}>{cabinetError ? "Please Select a Cabinet Secretary" : "Cabinet Secretary"}</DialogContentText>
            <Select
              margin="dense"
              labelId="label"
              name="contactSelect"
              value={contact?.cabinetSecretary}
              onChange={handleSelectCabinetChange}
              fullWidth
            >
              {_renderCabinetDropdown()}
            </Select>
          </FormControl>
        </DialogContent>
        {/* MultiSelect for alt liaison and attestation officers */}
        <DialogContent className="contact-selection">
          <div>
            <DialogContentText>Alternate Liaisons</DialogContentText>
            <Select
              margin="dense"
              labelId="label"
              name="contactSelect"
              value={contact?.altLiaisons}
              onChange={handleSelectAltLiaisonChange}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {selected.map((value: any) => {
                    let contact = alternateLiaisonContacts.find((i) => i.id === value);
                    if (contact) {
                      return (
                        <Chip className="contact-chip" key={contact.id} label={contact.firstName + " " + contact.lastName} />
                      )
                    }
                  })}
                </Box>
              )}
            >
              {_renderAltLiaisonDropdown()}
            </Select>
          </div>
          <div>
            <FormControl error={attestationError}>
              <DialogContentText className={attestationError ? "error-text" : ""}>{attestationError ? "Select Attestation Officers*" : "Attestation Officers"}</DialogContentText>
              <Select
                margin="dense"
                labelId="label"
                name="contactSelect"
                value={contact?.attOfficers}
                onChange={handleSelectAttestationOfficerChange}
                renderValue={(selected: any) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value: any) => {
                      let contact = attestationOfficerContacts.find((i) => i.id === value)
                      if (contact) {
                        return (
                          <Chip className="contact-chip" key={contact.id} label={contact.firstName + " " + contact.lastName} />
                        )
                      }
                    })}
                  </Box>
                )}
              >
                {_renderAttOfficerDropdown()}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogContent>
          <div className="is-active-check">
            <FormControlLabel
              control={
                <Checkbox onChange={handleIsActiveCheck} checked={isChecked} />
              }
              label="Rule is Active"
            />
          </div>
        </DialogContent>

        {/* <DialogContent>
          <DialogContentText>Rule Type</DialogContentText>
          <Select
            margin="dense"
            labelId="label"
            name="ruleSelect"
            value={ruleType.id}
            onChange={handleSelectRuleTypeChange}
            fullWidth
          >
            {_renderRuleTypeDropdown()}
          </Select>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(event) => { saveForm(event); }} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
