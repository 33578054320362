import * as React from "react";
import { Form, FormElement, FormSubmitClickEvent, } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { subscriptionLoginAPI } from "../../../../utils/api/subscription/SubscriptionLoginApi";
import { EmailForm } from "./EmailForm";
import { PasswordForm } from "./PasswordForm";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";


interface stepsInterface {
    isValid: boolean | undefined;
    label: string;
}


export const SubscriptionLoginSteps = () => {
    const navigate = useHistory();
    const stepPages = [EmailForm, PasswordForm];
    const [visible, setVisible] = React.useState(false);
    const [step, setStep] = React.useState<number>(0);
    const [formState, setFormState] = React.useState<Object>({});
    const [steps, setSteps] = React.useState<Array<stepsInterface>>([
        { label: "Email", isValid: undefined },
        { label: "Password", isValid: undefined },
    ]);
    const [validEmail, setValidEmail] = React.useState<boolean>(false);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;
    const [userEmail, setUserEmail] = React.useState<string>("");

    const [emailMessage, setEmailMessage] = React.useState<string>("");
    const [emailAlreadyExists, setEmailAlreadyExists] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);

    const alertResponse = (response: boolean) => {
        visible ? setVisible(false) : setVisible(true);
    };
    const isValidEmail = React.useRef(false);
    const [success, setSuccess] = React.useState<boolean>(false);


    React.useEffect(() => {
        isValidEmail.current = validEmail;
    }, [validEmail]);


    const onStepSubmit = React.useCallback(
        async (event: FormSubmitClickEvent) => {
            const { isValid, values } = event;
            // Validate Email, set state and return if not valid
            if (step === 0) {
                if (!event.values.email) {
                    return;
                } else {
                    // INFO: commented out the email vailidation part where that can happen all at once on signin attempt
                    // verify email is in DB
                    // const emailResponse: any =
                    //     await subscriptionLoginAPI.verifySubscriptionUserEmail(
                    //         event.values.email
                    //     );

                    // // set state for email validation
                    // if (emailResponse === true) {
                    setValidEmail(() => true);
                    setUserEmail(values.email);
                    setEmailMessage("");
                    // } else {
                    //     setStep(0);
                    //     setEmailMessage("This email does not exist in our system.");
                    //     return;
                    // }
                }
            }

            // Validate Email, set state and return if not valid
            if (isLastStep) {
                if (!event.values.password) {
                    return;
                } else {
                    if (isValidEmail.current) {
                        // Submit email and password

                        const emailAddress = event.values.email;
                        const userPassword = event.values.password;
                        setLoading(true);

                        var loginResponse: any;
                        try {
                            loginResponse =
                              await subscriptionLoginAPI.loginSubscriptionUser({
                                userEmail: emailAddress,
                                password: userPassword,
                                newPassword: userPassword,
                              });

                        if (loginResponse?.isSuccess != true) {
                            setLoading(false);
                            Swal.fire(
                                "Failed",
                                "The email or password entered is not correct",
                                "error"
                              );
                              setSuccess(false);
                              setStep(0);
                              return;
                            } else {
                              setLoading(false);
                              Swal.fire({
                                title: "Login Successful",
                                icon: "success",
                                showClass: {
                                  popup:
                                    "animate__animated animate__fadeInDown",
                                },
                                hideClass: {
                                  popup: "animate__animated animate__fadeOutUp",
                                },
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate.push("/subscriptionProfile");
                                  window.location.reload();
                                  setSuccess(true);
                                }
                              });
                            }
                        } catch (error) {
                            Swal.fire(
                              "Failed",
                              "The email or password entered is not correct",
                              "error"
                            );
                            setSuccess(false);
                        }

                    }
                }
            }

            const currentSteps = steps.map(
                (currentStep: stepsInterface, index: number) => ({
                    ...currentStep,
                    isValid: index === step ? isValid : currentStep.isValid,
                })
            );

            setSteps(currentSteps);

            if (!isValid) {
                return;
            }

            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            // if (isLastStep) {
            //     alert(JSON.stringify(values));
            // }
        },
        [steps, isLastStep, step, lastStepIndex]
    );

    const onPrevClick = React.useCallback(
        (event: any) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));
        },
        [step, setStep]
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", }}>
            <Stepper value={step} items={steps} className="subscription-login-stepper"/>
            <Form initialValues={formState} onSubmitClick={onStepSubmit} render={(formRenderProps) => (
                <div style={{ alignSelf: "center" }}>
                    <FormElement  >
                            {/* style={{ width: "30vw" }} */}
                        {stepPages[step]}
                        <span style={{ marginTop: "40px" }} />
                        <h5 style={{ backgroundColor: "white", color: "red" }}>
                            {emailMessage}
                        </h5>
                        <div style={{ justifyContent: "space-between", alignContent: "center", }}
                            className={"k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"}
                        >
                            <span style={{ alignSelf: "center" }}>
                                Step {step + 1} of 2
                            </span>
                            <div>
                                {step !== 0 ? (
                                    <Button
                                        style={{ marginRight: "16px" }}
                                        onClick={onPrevClick}
                                        type="button"
                                    >
                                        Previous
                                    </Button>
                                ) : undefined}
                                <Button
                                    disabled={!formRenderProps.allowSubmit}
                                    onClick={formRenderProps.onSubmit}
                                    type="submit"
                                >
                                    {isLastStep ? "Submit" : "Next"}
                                </Button>
                            </div>
                        </div>
                    </FormElement>
                </div>
            )}
            />
        </div>
    );
};
