import { Button, Dialog, DialogActions, DialogContent, Divider, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { Authorized, EnumSelectInput } from "../../../../../../../components";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { workflowAPI } from "../../../../../../../utils/api";
import { VisibilityLevel } from "../../../../../../../utils/enums";
import { Workflow } from "./../../../../../../../utils/model/Workflow";
import "./WorkflowModal.scss";
import {v4 as uuid} from "uuid";


interface IWorkflowModalProps {
    open:boolean;
    triggerReload: () => void;
    handleClickOpen: () => void;
    handleClose: (event:any) => void;
}

export const WorkflowModal = (props: IWorkflowModalProps) => {
    const { open, handleClickOpen, handleClose } = props;
    const [values, setValues] = React.useState({
        name: "",
        icon: "",
        description: "",
        order: 0,
        visibility: 0,
    });

    const saveForm = async() => {
        // Creates a new status item
        try {
            let workflow = new Workflow(values.name, values.visibility);
            delete(workflow.id);
            await workflowAPI.create(workflow);
            props.triggerReload()
        } catch (error) {

        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setValues({...values, [name]: value});
    };

    useEffect(() => {

    }, [props.open])

    // TODO: Implement or delete this function
    // const iconSelect = (newIcon:string) => {
    //     setValues({...values, icon: newIcon});
    // }

    const handleVisibilityChange = (val: number) => {
        setValues({...values, visibility: val});
    }

    const currId = uuid() 

    return (
      <div onClick={handleClickOpen}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
            Add Workflow
          </DialogTitleWithExit>
          <Divider />
          <DialogContent className="dialog-title">
            <TextField
              id = {`name-text-field${currId}`}
              autoFocus
              fullWidth
              margin="dense"
              name="name"
              label="Name"
              onChange={handleChange}
              value={values.name}
              type="text"
            />
            <EnumSelectInput enumerable={VisibilityLevel} 
            value={values.visibility} title="" 
            onChange={handleVisibilityChange}
            className="status-select"/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Authorized>
              <Button onClick={(event) => { saveForm(); handleClose(event);}} color="primary">
                Save
              </Button>
            </Authorized>
          </DialogActions>
        </Dialog>
      </div>
    );
};