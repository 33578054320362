import { FormGroup, FormLabel, IconButton, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuid } from "uuid";
import { Row, Select, TextField } from ".";
// import { StateOfficeNavigationSettings } from "./../../../../../../utils/settings/StateOfficeNavigationSettings";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Filing,
  FilingType,
  Rule,
  Segment,
} from "../../../../../../utils/model";
import { PermanentFinalRulemakingAction } from "../../../../../../utils/model/Filing";
import { RULE_PLURAL_NAME } from "../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";

interface IDefaultFormProps {
  filingType: FilingType;
  filing?: Filing;
  formatDate: (date: any) => any;
  renderRuleDropdown: () => any;
  renderContactDropdown: () => any;
  renderLiaisonDropdown: () => any;
  renderDocketDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  attestationList: Contact[];
  liaisonList: Contact[];
  docketList: Docket[];
  ruleList: Rule[];
  saveForm: (item: any, type: string) => void;
}

const getInitialState = (props: IDefaultFormProps) => ({
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  chapter: props.ruleList[0],
  rule: props.ruleList[0],
  chapterId: -1,
  ruleId: -1,
  rulemakingAction: PermanentFinalRulemakingAction.PermanentFinal,
  authority: "",
  rules: "",
  submitGovCabDate: new Date(),
  commentBeginDate: new Date(),
  commentEndDate: new Date(),
  publicHearing: "",
  publicHearingDate: new Date(),
  adoptionDate: new Date(),
  adoptionGovDate: new Date(),
  legislativeApproval: "",
  legislativeDisapproval: "",
  govApproval: "",
  finalAdoptionDate: new Date(),
  effectiveDate: new Date(),
  supersededRules: "N/A",
  supersededEmergencyActions: "N/A",
  gubernatorialApproval: "N/A",
  registerPublication: "N/A",
  docketSelect: Docket,
  docketSelectId: -1,
  docketSelectNumber: "N/A",
  incorporatedRef: "N/A",
  incorporatedStandards: "N/A",
  incorporatedRules: "N/A",
  availability: "N/A",
  gistAnalysis: "",
  contactPerson: "",
  enactingClause:
    "PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING RULES ARE CONSIDERED FINALLY ADOPTED AS SET FORTH IN 75 O.S., SECTIONS 250.3(5) AND 308(E), WITH AN EFFECTIVE DATE OF " +
    props.formatDate(new Date()),
  liaison: Contact,
  liaisonId: 0,
  attestation: Contact,
  attestationId: 0,
  segments: [],
});

export let DefaultForm = React.forwardRef((props: IDefaultFormProps, ref) => {
  const { filing, ruleList, returnChapterListAsync } = props;
  const [formValues, handleChange] = useFormState(
    { ...getInitialState(props), ...props.filing?.entity },
    ref
  );

  const [chapterList, setChapterList] = React.useState<Segment[]>([]);
  const [helperText, setHelperText] = React.useState<string>('');
  const [isError, setIsError] = React.useState<boolean>(false);
  const nameErrorMessage: string = "Filing Name Required";

  const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value as string;
    let isError: boolean = value.trim().length == 0;
    let errorMessage: string = isError ? nameErrorMessage : '';

    setIsError(isError);
    setHelperText(errorMessage);
    handleChange(e, "filingName", value);
  };

  const setChapterListFromRuleId = React.useCallback(
    async (ruleId: number) => {
      const chapters = await returnChapterListAsync(ruleId);

      setChapterList(chapters);
    },
    [setChapterList, returnChapterListAsync]
  );

  useEffect(() => {
    if (!formValues.rule && !filing) {
      formValues.rule = ruleList[0];
      formValues.ruleId = ruleList[0]?.id;
    } else if (filing) {
      let entity = Object.create(filing!.entity!);
      setChapterListFromRuleId(entity.rule.segmentId);
    }
  }, [formValues.rule, filing, ruleList, setChapterListFromRuleId]);

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    date: Date | null
  ) => {
    handleChange(event, name, date);
    if (name === "effectiveDate") {
      handleChange(
        event,
        "enactingClause",
        "PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING RULES ARE CONSIDERED FINALLY ADOPTED AS SET FORTH IN 75 O.S., SECTIONS 250.3(5) AND 308(E), WITH AN EFFECTIVE DATE OF " +
        props.formatDate(date)
      );
    }
  };

  const handleChapterSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    rawValue: string | null
  ) => {
    const value = rawValue as keyof typeof Rule;
    if (value != null) {
      let chapter = chapterList.find((i) => i.id === rawValue);
      if (chapter != null) {
        handleChange(event, "chapter", chapter);
        handleChange(event, "chapterId", chapter.id);
      }
    }
  };

  const handleRuleSelectChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    rawValue: string | null
  ) => {
    const value = rawValue as keyof typeof Rule;
    if (value != null) {
      let rule = props.ruleList.find((i) => i.id === rawValue);
      if (rule != null) {
        handleChange(event, "rule", rule);
        handleChange(event, "ruleId", rule.id);
        setChapterListFromRuleId(rule.segmentId!);
      }
    }
  };


  const handleContactSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    rawValue: string | null
  ) => {
    const value = rawValue as keyof typeof Contact;
    let contact: Contact | undefined | null;
    if (value != null) {
      if (name === "liaisonId") {
        contact = props.liaisonList.find((i) => i.id === rawValue);
      } else {
        contact = props.attestationList.find((i) => i.id === rawValue);
      }
      if (contact != null) {
        if (name === "liaisonId") {
          handleChange(event, "liaison", contact);
        } else {
          handleChange(event, "attestation", contact);
        }
        handleChange(event, name, contact.id);
      }
    }
  };

  const handleIncorporationSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    value: string
  ) => {
    value === "Yes"
      ? handleChange(event, "incorporatedRef", "Yes")
      : handleChange(event, "incorporatedRef", "N/A");
  };

  const currId = uuid();

  return (
    <FormProvider values={formValues} onChange={handleChange}>
      <div className="flex-column">
        <TextField
          label="FILING NAME"
          name="filingName"
          placeholder="Filing Name (REQUIRED)"
          onChange={handleFilingNameChange}
          error={isError}
          helperText={helperText}
          variant="outlined"
          aria-label="filing name"
          autoFocus
        />
        <Row>
          <Select
            label={RULE_PLURAL_NAME}
            name="rule"
            value={formValues.ruleId}
            onChange={handleRuleSelectChange}
          >
            {props.renderRuleDropdown}
          </Select>
          {formValues.ruleId !== -1 ? (
            <Select
              id={`name-text-field${currId}`}
              label="CHAPTER"
              name="chapterId"
              value={formValues.chapterId}
              onChange={handleChapterSelectChange}
            >
              {chapterList.map((chapterSegment) => {
                return (
                  <MenuItem key={chapterSegment.id} value={chapterSegment.id!}>
                    {chapterSegment.titleNum + " " + chapterSegment.description}
                  </MenuItem>
                );
              })}
              <MenuItem key={-1} value={-1}>
                <IconButton className="add-new-chapter">
                  Add New
                  <AddIcon style={{ fontSize: "1.3em" }} />
                </IconButton>
              </MenuItem>
            </Select>
          ) : null}
        </Row>
        <Row>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">
              COMMENT PERIOD: START DATE
            </FormLabel>
            <DatePicker
              name="commentBeginDate"
              showPopperArrow={false}
              className="date-picker"
              selected={new Date(formValues.commentBeginDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "commentBeginDate", date)
              }
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">
              COMMENT PERIOD: END DATE
            </FormLabel>
            <DatePicker
              name="commentEndDate"
              showPopperArrow={false}
              className="date-picker"
              selected={new Date(formValues.commentEndDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "commentEndDate", date)
              }
              dateFormat="MMMM d, yyyy"
            />
          </div>
        </Row>
        <FormGroup className="form-group-checkbox">
          <Select
            name="incorporatedRef"
            label="INCORPORATIONS BY REFERENCE"
            defaultValue={"N/A"}
            onChange={handleIncorporationSelectChange}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="N/A">No</MenuItem>
          </Select>
          {formValues.incorporatedRef === "Yes" ? (
            <React.Fragment>
              <TextField
                variant="outlined"
                name="incorporatedStandards"
                label="INCORPORATED STANDARDS"
                placeholder="Enter Text..."
              />
              <TextField
                variant="outlined"
                name="incorporatedRules"
                label="INCORPORATING RULES"
                placeholder="Enter Text..."
              />
              <TextField
                variant="outlined"
                name="availability"
                label="AVAILABLITY"
                placeholder="Enter Text..."
              />
            </React.Fragment>
          ) : null}
        </FormGroup>
        <Select
          name="liaisonId"
          label="PLEASE SELECT AGENCY LIAISON TO GENERATE TRANSMITTAL SHEET"
          onChange={handleContactSelectChange}
        >
          {props.renderLiaisonDropdown}
        </Select>
      </div>
    </FormProvider>
  );
});
