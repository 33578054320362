import { Registries } from "../model/Registries";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { AggregatedRegister } from "../model/AggregatedRegister";
import { FinalizedRegister } from "../model/FinalizedRegister"

class RegistryFilingAPI extends BaseAPI<CustomElementRegistry> {
  constructor() {
    super("RegistryFiling", APISettings.adminRulesApiUrl);
  }


  public publishRegisterFilings: (id: number) => Promise<Registries> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/PublishRegisterFilings/${id}`))
      .data;
  };

}

export const registryFilingAPI = new RegistryFilingAPI();
