import {
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useEmergencyCodeController } from "./EmergencyCodeConroller";
import DescriptionIcon from "@material-ui/icons/Description";
import { Fragment } from "react";
import "./EmergencyRules.scss";
import { Loader } from "@progress/kendo-react-indicators";

interface IEmergencyCodeProps {
  toggleDraftModal: (id?: number) => void;
  setDraftData: any;
  draftData: any;
}

export const EmergencyCodeResult = ({
  toggleDraftModal,
  setDraftData,
  draftData,
}: IEmergencyCodeProps) => {
  const {
    loading,
    loadingChapter,
    emergencyTitles,
    emergencyChapters,
    titleExpanded,
    chapterExpanded,
    effectiveDate,
    expirationDate,
    filing,
    handleTitleChange,
    handleChapterChange,
  } = useEmergencyCodeController(setDraftData);

  return (
    <Fragment>
      {loading ? (
        <div className="history-loading">
          <CircularProgress /> Loading
        </div>
      ) : null}
      <Container>
        <div className="agency">
          {emergencyTitles
            ? emergencyTitles.map((item: any, key: number) => (
                <Accordion
                  square
                  className="titleName"
                  key={key}
                  expanded={titleExpanded === `${item.segmentId}`}
                  onChange={handleTitleChange(`${item.segmentId}`)}
                  TransitionProps={{
                    timeout: 0,
                  }}
                  id={`${item.segmentId}`}
                >
                  <AccordionSummary
                    // classes={{ root: classes.advancedSearch }}
                    className="titleLabel"
                    aria-controls="panel2d-content"
                    aria-label="Title Listing"
                    id="panel2d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography>
                      Title {item.referenceCode}. {item.title}
                    </Typography>
                  </AccordionSummary>

                  {emergencyChapters
                    ? emergencyChapters.map((chapter: any, key: number) => (
                        <Accordion
                          square
                          className="chapterName"
                          key={key}
                          expanded={chapterExpanded === `${chapter.id}`}
                          onChange={handleChapterChange(
                            `${chapter.id}`,
                            `${chapter.filingId}`
                          )}
                          TransitionProps={{
                            timeout: 0,
                          }}
                          id={`${chapter.id}`}
                        >
                          <AccordionSummary
                            className="chapterLabel"
                            aria-controls="panel2d-content"
                            aria-label="Chapter Listing"
                            id="panel2d-header"
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography>
                              Chapter {chapter.chapterNum}.{" "}{chapter.description}
                              {chapter.segmentTypeId === 4 && " - Subchapter " + chapter.subChapterNum + '. ' + chapter.subChapterName }    {/* subchapter */}
                              {chapter.segmentTypeId === 6 && " - Subchapter " + chapter.subChapterNum + ". " + chapter.subChapterName + ' - '  + chapter.sectionNum + '. ' + chapter.sectionName}    {/* subchapter section */}
                              {chapter.segmentTypeId === 9 && ' - ' + chapter.sectionNum + '. ' + chapter.sectionName}    {/* chapter section */}

                              {chapter.segmentTypeId === 13 && " - Subchapter " + chapter.subChapterNum + ". " + chapter.subChapterName + ' - Part ' + chapter.partNum + '. ' + chapter.partName}    {/* part */}
                              {chapter.segmentTypeId === 14 && ' - Subchapter ' + chapter.subChapterNum + ". " + chapter.subChapterName + ' - Part ' + chapter.partNum + '. ' + chapter.partName + ' - ' + chapter.sectionNum + '. ' + chapter.sectionName}    {/* part section */}
                              {chapter.segmentTypeId === 11 && " - Appendix " + chapter.appendixNum + '. ' + chapter.appendixName}    {/* appendix */}
                            </Typography>
                          </AccordionSummary>

                          <div className="draft-content">
                            {loadingChapter ? (
                              <Loader />
                            ) : (
                              <>
                                <div>
                                  <b>Effective Date:</b>{" "}
                                  {effectiveDate
                                    ? effectiveDate?.toLocaleDateString()
                                    : "N/A"}
                                </div>
                                <div>
                                  <b>Expiration Date:</b>{" "}
                                  {expirationDate
                                    ? expirationDate?.toLocaleDateString()
                                    : "N/A"}
                                </div>
                                {draftData ? (
                                  <Button
                                    variant="text"
                                    color="primary"
                                    startIcon={<DescriptionIcon />}
                                    onClick={() =>
                                      toggleDraftModal(chapter.draftId)
                                    }
                                  >
                                    View Emergency Rule
                                  </Button>
                                ) : null}
                              </>
                            )}
                          </div>
                        </Accordion>
                      ))
                    : null}
                </Accordion>
              ))
            : null}
        </div>
      </Container>
    </Fragment>
  );
};
