import { Appendix } from "../model/Appendix";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class AppendixAPI extends BaseAPI<Appendix> {
  constructor() {
    super("appendix", APISettings.adminRulesApiUrl);
  }

  public getAppendixByName: (name: string) => Promise<Appendix> = async (
    name: string
  ) => {
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/GetAppendixByName?name=${name}`)
    ).data;
  };

  public getAppendixById: (id: number) => Promise<Appendix> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAppendixsById/${id}`))
      .data;
  };

  public createAppendix: (appendix: Appendix) => Promise<any> = async (
    appendix: Appendix
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateAppendix`, {
      appendix,
    });
    return response.data;
  };

  public removeAppendix: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveAppendix/${id}`
    );
    return response.data;
  };

  public updateAppendixs: (
    id: number,
    appendix: Appendix
  ) => Promise<Appendix> = async (id: number, appendix: Appendix) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateAppendix/${id}`,
      { appendix }
    );
    return response.data;
  };
}

export const appendixAPI = new AppendixAPI();
