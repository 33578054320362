import { Button, Dialog, CircularProgress } from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { segmentAPI } from '../../../utils/api';
import { NoteType } from '../../../utils/enums';
import { Paragraph } from '../../Paragraph';
import "../AdminEditor.scss";
import { SegmentEditForm, NoteForm } from '../Components';

interface IAdminEditor {
    note?: any;
    sectionNum?: any;
    editData?: any;
    editText?: any;
    type: "EditSegment" | "EditNote" | "CreateNote";
}


export const CurrentRuleEditPreview = (props: IAdminEditor) => {
    const { sectionNum, editData, type, note } = props;
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [children, setChildren] = React.useState<any>(<div> Some text to preview</div>)
    const [previewData, setPreviewData] = React.useState<any>([]);

    const onMount = async () => {
        setChildren(<div className="loading"><div>LOADING DATA: Depending on chapter size this may take a moment.</div><CircularProgress /></div>)
        if (editData || editData.titleNum != "1") {
            let allData: any = await segmentAPI.getSegmentsByChapterNum(editData.titleNum, editData.chapterNum);
            setPreviewData(allData);
        } else {
            setPreviewData([editData]);
        }
    }

    useEffect(() => {
        onMount();
    }, [editData, note])

    const _renderSegmentNotes = () => {
        let notesToRender = <div>Notes</div>;

        return notesToRender
    }

    const _renderDescription = (segment: any) => {
        let description;

        switch (segment.name) {
            case "Chapter":
                description = <span className="center bold chapter-font">{`${segment.name.toUpperCase()} ${segment.chapterNum}. ${segment.description.toUpperCase()} ${segment.statusName != "Undefined" ? "[" + segment.statusName.toUpperCase() + "]" : ""}`}</span>;
                break;
            case "Subchapter":
                description = <span className="bold center">{`${segment.name.toUpperCase()} ${segment.subChapterNum}. ${segment.description.toUpperCase()} ${segment.statusName != "Undefined" ? "[" + segment.statusName.toUpperCase() + "]" : ""}`}</span>;
                break;
            case "Section":
                description = <span className="bold">{`${segment.sectionNum}. ${segment.description} ${segment.statusName != "Undefined" ? "[" + segment.statusName.toUpperCase() + "]" : ""}`}</span>;
                break;
            case "Appendix":
                description = <span className="bold center">{`${segment.name} ${segment.appendixNum}. ${segment.description} ${segment.statusName != "Undefined" ? "[" + segment.statusName.toUpperCase() + "]" : ""}`}</span>;
                break;
            case "Part":
                description = <span className="bold center">{`${segment.name} ${segment.partNum}. ${segment.description} ${segment.statusName != "Undefined" ? "[" + segment.statusName.toUpperCase() + "]" : ""}`}</span>;
                break;
            default:
                break;
        }

        return description;
    }

    /**
     * Note order
     *  1. Footnote
     *  2. Source
     *  3. Editors
     *  4. Agency
     *  5. Authority
     */
    const _renderNotes = (segment: any) => {
        let notes = segment.segmentNotes;
        let text: any;
        let editorsNotes: any = [];
        let sourceNotes: any = [];
        let authorityNotes: any = [];
        let footNotes: any = [];
        let agencyNotes: any = [];

        notes.forEach((note: any, index: number) => {
            //   var found = null;
            //   found = noteType?.find((a:any) => a == note.noteTypeId);
            //   if(found) {
            if (note.noteTypeId == NoteType.Footnote) {
                footNotes.push(
                    <div className="preview-note-text">
                        <Paragraph value={note.note} className="preview-note-text" />
                    </div>
                );
            } else if (note.noteTypeId == NoteType.Source) {
                sourceNotes.push(
                    <div className="preview-note-text">
                        <Paragraph value={note.note} className="preview-note-text" />
                    </div>
                );
            } else if (note.noteTypeId == NoteType.Editors) {
                editorsNotes.push(
                    <div className="preview-note-text">
                        <Paragraph value={note.note} className="preview-note-text" />
                    </div>
                );
            } else if (note.noteTypeId == NoteType.Agency) {
                agencyNotes.push(
                    <div className="preview-note-text">
                        <Paragraph value={note.note} className="preview-note-text" />
                    </div>
                );
            } else if (note.noteTypeId == NoteType.Authority) {
                authorityNotes.push(
                    <div className="preview-note-text">
                        <Paragraph value={note.note} className="preview-note-text" />
                    </div>
                );
            }
            //   }
        })

        text = (
            <div>
                {footNotes.length > 0 ? (
                    <div className="edit-preview-note">
                        {footNotes.map((item: any, index: number) => { return (<span className="preview-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
                    </div>
                ) : null}
                {sourceNotes.length > 0 ? (
                    <div className="edit-preview-note">
                        [<b>Source: </b>{sourceNotes.map((item: any, index: number) => { return (<span className="preview-note-text">{item}{index + 1 < sourceNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}]
                    </div>
                ) : null}
                {editorsNotes.length > 0 ? (
                    <div className={`edit-preview-note`}><i><b>EDITOR'S NOTE: </b>{editorsNotes}</i></div>
                ) : null}
                {agencyNotes.length > 0 ? (
                    <div className="edit-preview-note">
                        {agencyNotes.map((item: any, index: number) => { return (<span className="preview-note-text">{item}{index + 1 < agencyNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}
                    </div>
                ) : null}
                {authorityNotes.length > 0 ? (
                    <div className="edit-preview-note">
                        [<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="preview-note-text">{item}{index + 1 < sourceNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]
                    </div>
                ) : null}
            </div>
        )

        return text;
    }

    return (
        <div>
            {previewData ? previewData.map((item: any, index: number) => {
                return (
                    <div>
                        {item.id == editData.id ? (
                            <Fragment>
                                {_renderDescription(item)}
                                {item.text != null && item.text != "" ? (
                                    <Paragraph key={index + item.description} value={editData.text} className="" />
                                ) : null}
                                {_renderNotes(item)}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {_renderDescription(item)}
                                {item.text != null && item.text != "" ? (
                                    <Paragraph key={index + item.description} value={item.text} className="" />
                                ) : null}
                                {_renderNotes(item)}
                            </Fragment>
                        )}
                        <br />
                    </div>
                );
            }) : null}
            {previewData.length < 1 ? (<div className="center bold">No Edited Data Found</div>) : null}
        </div>
    );
};
