import {Topic} from './../../model';
import {BaseAPI} from './../internal';
import {APISettings} from './../../settings/APISettings';


class TopicAPI extends BaseAPI<Topic> {
    constructor() {
        super("topic", APISettings.subscriptionsUrl);
    }
}

export const topicAPI = new TopicAPI();
