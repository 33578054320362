import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridNoRecords, GridToolbar, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { DataResult, State } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./DashboardTable.scss"
import { CircularProgress, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { UrlParser, PageLoader } from '../../../../../utils';
import { filingAPI } from "../../../../../utils/api";
import { Filing, ListPayload } from '../../../../../utils/model';
import { DueDates } from '../../../../../utils/model';
import { getEntityData, isFalsy } from "../../../../../utils/utility_functions";
import { setFilingTypeRows as dashboardTableSetup } from "../DashboardTableSetup";


interface IDashboardTableProps {
  //rows:any;
  //columns:any;
  add?: () => void;
  height?: string;
  tableName?: string;
  filingTypeIds: number[];
  id: string;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

interface TempColumn {
  visible: boolean;
  field: string;
  title: string;
  width: string;
}


export const DashboardPagedTable = (props: IDashboardTableProps) => {
  const gridRef = React.useRef<Grid>(null);
  const [height, setHeight] = React.useState<any>("75vh");
  const [filings, setFilings] = React.useState<DataResult>({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 10,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id, ...rest } = props;

  // const onMount = React.useCallback(async() => {
  //   console.log('Dashboard Table Mounted');
  //   if (props.height) {
  //     setHeight(props.height);
  //   }

  //   // await props.rows.forEach(async (element:any,i:number) => {
  //   //   let filingType:FilingType = await filingTypeAPI.getFilingTypeById(element.filingTypeId);
  //   //   props.rows[i].filingType = filingType;

  //   //   let url = UrlParser.Filing.parseFiling(props.rows[i].filingType.name, props.rows[i].id);
  //   //   props.rows[i].url= url;

  //   // })
  //   //I commented out this
  //   // props.rows.forEach((e: any, i: number) => {
  //   //   e.created = new Date(e.created).toLocaleDateString();
  //   //   e.adoptionDate = new Date(e.adoptionDate).toLocaleDateString();
  //   // });

  //   //setDataResult(process(props.rows, dataState));

  // },[props.rows])

  // React.useEffect(() => {
  //   onMount();
  // }, [props])

  React.useEffect(() => {
    //  console.log(`DashboardPagedTable useEffect dataState Changed`);
    if (props.filingTypeIds.length > 0) {
      (async () => {
        try {
          setLoading(true);
          resetScrollToTop();
          const skip = dataState.skip ?? 0;
          const take = dataState.take ?? 10;
          const page = ((skip + take) / take);
          //const filingsPayLoad = await filingAPI.getFilingsByTypeAsync(props.filingTypeId,{pageNumber: page, pageSize: take});
          const filingsPayLoad = await filingAPI.GetFilingsByTypesAsync(props.filingTypeIds, { pageNumber: page, pageSize: take }, false);
          const filingsPayloadInfo = setFilingInfo(filingsPayLoad)
          setFilings({ data: filingsPayloadInfo.items, total: filingsPayloadInfo.totalCount });
        } catch (ex: any) {
          console.error(`Error in DashboardPagedTable: ${ex.message}`);
        } finally {
          setLoading(false);
        }
      })();
    }
    return () => {
      // this now gets called when the component unmounts
    };
  }, [dataState]);

  const resetScrollToTop = () => {
    try {
      let gridCointainer = document ? document.getElementById(`grid-${id}`) : null;
      if (gridCointainer) {
        let gridContent = gridCointainer.querySelector(".k-grid-content");
        if (gridContent) {
          gridContent.scrollTop = 0;
        }
      }
    } catch (ex: any) {
      console.error(`Errror in resetScrollToTop${ex.message}`);
    }
  };
  const setFilingInfo = (payload: ListPayload<Filing>) => {
    for (let index = 0; index < payload.items.length; index++) {

      payload.items[index].docketNumber = getEntityData(payload.items[index].entity, "docketNumber")
      payload.items[index].titleNumber = getEntityData(payload.items[index].entity, "rule", "referenceCode");
      payload.items[index].chapterNumber = getEntityData(payload.items[index].entity, "chapter", "chapterNum");
      if (payload.items[index].created) {
        payload.items[index].created = new Date(payload.items[index].created).toLocaleDateString();
      }
      if (!isFalsy(payload.items[index].adoptionDate)) {
        payload.items[index].adoptionDate = new Date(payload.items[index].adoptionDate as unknown as string).toLocaleDateString();
      }
      if (!isFalsy(payload.items[index].submissionDate)) {
        payload.items[index].submissionDate = new Date(payload.items[index].submissionDate as unknown as string).toLocaleDateString();
      }
      if (!isFalsy(payload.items[index].acceptedDate)) {
        payload.items[index].acceptedDate = new Date(payload.items[index].acceptedDate as unknown as string).toLocaleDateString();
      }
      if (!isFalsy(payload.items[index].publicationDate)) {
        payload.items[index].publicationDate = new Date(payload.items[index].publicationDate as unknown as string).toLocaleDateString();
      }

    }
    return payload;
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    // setDataResult(process(props.rows, event.dataState));
    // setDataState(event.dataState);
  };
  const pageChange = (event: GridPageChangeEvent) => {
    setDataState({ ...event.page });
  };
  // const expandChange = (event: GridExpandChangeEvent) => {
  //   const isExpanded =
  //     event.dataItem.expanded === undefined
  //       ? event.dataItem.aggregates
  //       : event.dataItem.expanded;
  //   event.dataItem.expanded = !isExpanded;

  //   setDataResult({ ...dataResult });
  // };

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const dueDateColumn = (data: GridCellProps) => {
    let dueDates: any = new DueDates();
    var currentDate = new Date();
    var elementDate = new Date(data.dataItem.created);
    var returnValue = "inactive";
    for (var i: number = 0; i < dueDates.DueDates.length; i++) {
      var submissionDate = new Date(dueDates.DueDates[i].submissionDate);
      var acceptDate = new Date(dueDates.DueDates[i].acceptanceDate);
      if (elementDate >= submissionDate && elementDate >= acceptDate) {
        returnValue = "active";
        break;
      }
    }

    // if not found in due date array return error icon
    if (returnValue == "active") {
      return (
        <td className="newIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Active" aria-label="Active">
            <AddCircleIcon />
          </Tooltip>
        </td>
      )
    } else {
      return (
        <td className="oldIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Past Due" aria-label="PastDue">
            <ErrorIcon />
          </Tooltip>
        </td>
      )
    }
  }

  const hyperLinkColumn = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        <Tooltip title="Filing" aria-label="add">
          <a href={UrlParser.Filing.parseFiling(data.dataItem.filingType.name, data.dataItem.id) ? UrlParser.Filing.parseFiling(data.dataItem.filingType.name, data.dataItem.id).toString() : ""}>{data.dataItem.name}</a>
        </Tooltip>
      </td>
    )
  }

  const _renderTable = () => {
    if (filings.data.length > 0) {
      let data = dashboardTableSetup(filings.data);
      const allColumns = filings.data.length > 0 ? data.columns : [];
      //var allColumns = props.rows.length > 0 ? props.columns : [];

      const setColumns: TempColumn[] = [];

      allColumns.map((element: any) => {

        let column = {} as TempColumn
        column.field = element.field;
        column.title = element.title;
        column.visible = false;

        switch (element.field) {
          case "id":
            break;
          case "docketNumber":
            column.visible = true;
            break;
          case "submissionDate":
            column.visible = true;
            break;
          case "acceptedDate":
            column.visible = true;
            break;
          case "publicationDate":
            column.visible = true;
            break;
          case "titleNumber":
            column.visible = true;
            break;
          case "chapterNumber":
            column.visible = true;
            break;
          case "published":
            break;
          case "filingType":
            break;
          case "status":
            break;
          case "created":
            break;
          case "ruleId":
            break;
          case "chapterId":
            break;
          case "liaisonId":
            break;
          case "docketId":
            break;
          case "adoptionDate":
            break;
          case "titleNumber":
            break;
          case "draftId":
            break;
          case "chapterNumber":
            break;
          case "showInReviewModal":
            break;
          case "isReadOnly":
            break;
          case "isInDraftState":
            break;
          case "isInDraftState":
            break;
          case "registerId":
            break;
          default:
            break;
        }
        setColumns.push(column);
      });

      var columnsToShow = [];
      columnsToShow = setColumns.map((element: TempColumn, i: number) => {
        if (element.visible) {
          return (<GridColumn field={element.field} key={i + element.title} title={element.title} width={element.width} />);
        }
      });

      columnsToShow.unshift(<GridColumn key={"link"} field={"Link"} title="Name" cell={hyperLinkColumn} />);
      // columnsToShow.unshift(<GridColumn key={"dueDate"} field={"dueDate"} title="Status" cell={dueDateColumn} />);

      if (columnsToShow.length > 0) {
        return (
          <Grid
            style={{
              height: height,
            }}
            sortable={false}
            filterable={false}
            resizable={true}
            // groupable={true}
            reorderable={true}
            pageable={{
              buttonCount: 4,
              pageSizes: true,
            }}
            data={filings}
            {...dataState}
            onDataStateChange={dataStateChange}
            onPageChange={pageChange}
          // detail={DetailComponent}
          // expandField="expanded"
          // onExpandChange={expandChange}
          >
            <GridToolbar>
              <ButtonGroup className="button-group">
                {/* <Button
                icon="xls"
                title="Export to Excel"
                className="k-button k-secondary button"
                onClick={exportExcel}
              /> */}
                <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
                {/* <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={props.add}/> */}
              </ButtonGroup>
              {props.tableName ? (
                <h3>{props.tableName}</h3>
              ) : null}
            </GridToolbar>
            <GridNoRecords>
              <CircularProgress />
            </GridNoRecords>
            {columnsToShow}
          </Grid>
        )
      } else {
        return null;
      }
    }
  }

  return (
    <div className="work-queue">
      {/* <ExcelExport
        data={filings.data}
        ref={(exporter:any) => {
          _export = exporter;
        }}
      >*/}
      {
        <div id={`grid-${id}`}>
          {_renderTable()}
          {loading ? <PageLoader idValue={`grid-${id}`} /> : <></>}

        </div>}
      {/*</ExcelExport> */}
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}


