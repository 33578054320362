import { AggregatedRegister } from "../../../../utils/model";

interface Column {
  field: string; // create a column bound to the "name" field
  title: string; // set its title to "Name
  hidden: boolean; // used to define if a column should be rendered or not
}

export const setRegisterContentData = (rows: any) => {
  // console.log("helper function hit")
  if (rows.length <= 0) {
    return;
  }

  const aggregatedRegister: AggregatedRegister = new AggregatedRegister(
    rows[0].segmentId,
    rows[0].draftId,
    rows[0].segmentData,
    rows[0].startRow,
    rows[0].endRow,
    rows[0].includeInDraft,
    rows[0].isNewSegment,
    rows[0].name,
    rows[0].includeInRegister,
    rows[0].statusId,
    rows[0].published,
    rows[0].visibility,
    rows[0].filingTypeId,
    rows[0].ruleId,
    rows[0].chapterId,
    rows[0].titleNumber,
    rows[0].chapterNumber,
    rows[0].draftStatus,
    rows[0].isEditable,
    rows[0].draftName,
    rows[0].alternativeName,
    rows[0].description,
    rows[0].subFiling,
    rows[0].status,
    rows[0].workflowId,
    rows[0].notes,
    rows[0].additionalInformation,
    rows[0].docketYear,
    rows[0].liaisonId,
    rows[0].docketId,
    rows[0].adoptionDate,
    rows[0].registerId,
    rows[0].entity,
    rows[0].segmentStatusId,
    rows[0].lockedByUserId,
    rows[0].sectionNumber,
    rows[0].appendixNumber,
    rows[0].docketNumber
  );

  // console.log(aggregatedRegister);

  let columns: any = [];
  const keys = Object.keys(aggregatedRegister);

  keys.forEach((key, index) => {
    // Regular expression takes table name and converts formatting
    var text = key.replace(/([A-Z])/g, " $1");
    var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
    let column: Column = { field: key, title: capitalizeText, hidden: false };
    // we'll hide everything but filing name (the filing name is handled in the table component), docket, chapter, title, draft name
    switch (column.field) {
      case "name":
      case "segmentId":
      case "draftId":
      case "segmentData":
      case "startRow":
      case "endRow":
      case "includeInDraft":
      case "isNewSegment":
      case "includeInRegister":
      case "statusId":
      case "published":
      case "visibility":
      case "filingTypeId":
      case "ruleId":
      case "chapterId":
      case "draftStatus":
      case "isEditable":
      case "alternativeName":
      case "description":
      case "subFiling":
      case "status":
      case "workflowId":
      case "notes":
      case "additionalInformation":
      case "docketYear":
      case "liaisonId":
      case "docketId":
      case "adoptionDate":
      case "registerId":
      case "entity":
      case "segmentStatusId":
      case "lockedByUserId":
      case "lastModified":
        column.hidden = true;
        break;
    }
    columns.push(column);
  });

  columns.reverse();
  columns.sort((x: any, y: any) => {
    return x.field === "title" ? -1 : y.field === "title" ? 1 : 0;
  });

  // rows.forEach((row:any) => {
  //   row.adoptionDate = new Date(row.adoptionDate).toLocaleString();
  //   row.lastModified = new Date(row.lastModified).toLocaleString();
  // });
  // console.log("data in helper: ", rows, columns)
  return { rows: rows, columns: columns };
};
