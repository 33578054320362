import * as React from "react";
import {
  Form,
  FormElement,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { RegisterPasswordForm } from "../SubscriptionRegisterForms/RegisterPasswordForm";
import { RegisterEmailForm } from "../SubscriptionRegisterForms/RegisterEmailForm";
import { subscriptionLoginAPI } from "../../../../utils/api/subscription/SubscriptionLoginApi";
import bcrypt from "bcryptjs";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { WaitingForValidationForm } from "../SubscriptionRegisterForms/WaitingForValidationForm";
import { SubscriptionUserLogin } from "../../../../utils/model/subscription/SubscriptionUserLogin";
import { Container } from "@material-ui/core";
import { maxWidth } from "@material-ui/system";

interface stepsInterface {
  isValid: boolean | undefined;
  label: string;
}

interface registerInterface {
  tabSelect: any;
}

const stepPages = [RegisterPasswordForm];

export const SubscriptionRecoverSteps = () => {
  let navigate = useHistory();
  const [step, setStep] = React.useState<number>(0);
  const [formState, setFormState] = React.useState<Object>({});
  const [steps, setSteps] = React.useState<Array<stepsInterface>>([
    { label: "New Password", isValid: undefined },
    { label: "Updated", isValid: undefined },
  ]);
  const lastStepIndex = steps.length - 1;

  const [usersEmail, setUsersEmail] = React.useState<string | null>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [validEmail, setValidEmail] = React.useState<any>();
  const [emailAlreadyExists, setEmailAlreadyExists] = React.useState<any>();
  const [success, setSuccess] = React.useState<boolean>(false);

  const salt = bcrypt.genSaltSync(10);
  const stepPages = [
    RegisterPasswordForm,
  ];



  const handleSubmit = React.useCallback(
    async (event: FormSubmitClickEvent) => {
      const { isValid, values } = event;

      //   console.log('values - ', values)
      // console.log("email - ", usersEmail);



      if (isValid) {


        var fullUrl = window.location.href;
        var fullUrlArray = fullUrl.split("/");
        var activationKey = fullUrlArray[4];

        const params = new URLSearchParams(window.location.search);
        // console.log("params - ", params);
        let email = params.get("emailAddress");

        let user: SubscriptionUserLogin = {
          userEmail: email!,
          password: values.password,
          newPassword: values.password,
        };

        // console.log('user - ', user)
        let result;
        try {
          result = await subscriptionLoginAPI.resetSubscriberUserPassword(
            activationKey,
            user
          );

        } catch (error) {
          console.error(error)
        }
        // console.log("result - ", result);
        try {
          if (result.isSuccess === false) {
            Swal.fire("Failed", "There was an error", "error");
            setSuccess(false);
            return;
          } else {
            Swal.fire({
              title: "Password Successfully Changed! Please try logging in.",
              icon: "success",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                setSuccess(true);
                navigate.push("/subscriptionLogin");
              }
            });
          }
        } catch (error) {
          Swal.fire("Failed", "There was an error", "error");
          setSuccess(false);
          return;
        }

      }
    },
    []
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", }} >

      <Form initialValues={formState} onSubmitClick={handleSubmit} render={(formRenderProps) => (
        <div style={{ alignSelf: "center" }}>
          <FormElement>
            {stepPages[step]}
            <h5 style={{ justifyContent: "space-between", backgroundColor: "white", color: "red" }}>
              {emailMessage}
            </h5>
            <div style={{ justifyContent: "space-between", alignContent: "center", }}
              className={"k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"}
            >
              <Button onClick={formRenderProps.onSubmit}>Submit</Button>
            </div>
          </FormElement>
        </div>
      )}
      />
    </div>

  );
};
