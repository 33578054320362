import React from "react";
import "./../fonts/MaterialIcons/MaterialIcons-Regular.ttf";
import "./../fonts/MaterialIcons/MaterialIcons-Regular.woff2";
import "./Icon.scss";
import "./../fonts.css";

interface IIconProps {
  className?: string;
  icon: string;
  style?: React.CSSProperties;
}

export const Icon = React.forwardRef<HTMLElement, IIconProps>((props, ref) => {
  const icon = props.icon ?? "question_answer";
  return (
    <i style={props.style} className="material-icons" ref={ref}>
      {icon}
    </i>
  );
});
