import { CmsItem } from "../model";
// import { APISettings } from "../settings";
// import { StaticAuthenticationSettings } from "../settings/AuthenticationSettings";
import { BaseAPI } from "./internal";

class CmsItemAPI extends BaseAPI<CmsItem> {
  constructor() {
    super("");
  }
  public getCmsItemsByName: (name: string) => Promise<CmsItem> = async (
    name: string
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCmsItemsByName/${name}`)).data;
  };

  public getCmsItemsById: (id: number) => Promise<CmsItem> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCmsItemsById/${id}`))
      .data;
  };

  public createCmsItems: (cmsItem: CmsItem) => Promise<any> = async (
    cmsItem: CmsItem
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateCmsItems`, {
      cmsItem,
    });
    return response.data;
  };

  public getCmsItems: () => Promise<CmsItem> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCmsItems`)).data;
  };

  public removeCmsItems: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveCmsItem/${id}`
    );
    return response.data;
  };

  public updateCmsItems: (cmsItem: CmsItem) => Promise<CmsItem> = async (
    cmsItem: CmsItem
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateCmsItem`,
      cmsItem
    );
    return response.data;
  };
}

export const cmsItemAPI = new CmsItemAPI();
