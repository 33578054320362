import {Field} from "@progress/kendo-react-form";

import "./LoginForms.scss";

import {FormInput} from "../../Helpers/form-components";
import {emailValidator} from "../../Helpers/Validators";

export const EmailForm = (
    <div style={{marginTop: "5em", color: "cornflowerblue"}}>
        <Field
            key={"email"}
            id={"email"}
            name={"email"}
            label={"Please Enter your Email Address to Continue"}
            hint={"Hint: Enter your personal email address."}
            type={"email"}
            component={FormInput}
            validator={emailValidator}
        />
    </div>
);
