import { getOS } from "../../utils/UserOS";
import { useCallback, useEffect, useState } from "react";
import { Menu } from "../../components/Menu";
import { FeeIncrease as FeeIncreaseModel } from "../../utils/model/FeeIncrease";
import "./FeeIncrease.scss";
import { filingAPI, filingTypeAPI } from "../../utils/api";
import { setFeeIncreaseData } from "./Components/FeeIncreaseTableHelper";
import { FeeIncreaseTable } from "./Components";
import { LinearProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface IFeeIncreaseProps {
  feeIncrease: FeeIncreaseModel;
}

export const FeeIncrease = (props: IFeeIncreaseProps) => {
  const [feeIncreases, setFeeIncreases] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>();
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  const loadData = useCallback(async () => {
    let filingTypes = await filingTypeAPI.getAllPublicFilingTypes();
    const found = filingTypes.find(element => element.name == "Fee Increase");
    if (found) {
      let pageSizeForAllFeeIncreases = 500000000;
      let feeIncreaseFilings = await filingAPI.getPublishedFilingsByType(found.id!, { pageNumber: 1, pageSize: pageSizeForAllFeeIncreases });
      setFeeIncreases(feeIncreaseFilings);
      let data: any = await setFeeIncreaseData(feeIncreaseFilings);
      setTableData(data);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="fee-increase" id="Home">
      <a id="skip-nav" className="skiplink-text" href="#main-content">
        Skip to Main Content
      </a>
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      <main className="fee-increase-blog">
        {tableData ? (
          <FeeIncreaseTable
            rows={tableData.rows}
            columns={tableData.columns}
            tableName="Notices of Fee Increases"
          />
        ) : (
          <div className="placeholder-container">
            <Skeleton className="skeleton-placeholder" animation="wave" width="60%" />
            <Skeleton className="skeleton-placeholder" animation="wave" variant="rect" width="100%" height={800} />
          </div>
        )}
      </main>
    </div>
  );
};
