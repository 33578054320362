import { NoteType } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class NoteTypesAPI extends BaseAPI<NoteType> {
  constructor() {
    super("noteTypes", APISettings.adminRulesApiUrl);
  }

  public getNoteTypesById: (id: number) => Promise<NoteType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetNoteTypesById/${id}`))
      .data;
  };

  public getAllNoteTypes: () => Promise<NoteType[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllNoteTypes`)).data;
  };

  public createNoteTypes: (noteTypes: NoteType) => Promise<any> =
    async (noteTypes: NoteType) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/CreateNoteTypes`,
        {
          noteTypes,
        }
      );
      return response.data;
    };

  public removeNoteTypes: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveNoteTypes/${id}`
    );
    return response.data;
  };

  public updateNoteTypes: (
    id: number,
    noteTypes: NoteType
  ) => Promise<NoteType> = async (id: number, noteTypes: NoteType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateNoteTypes/${id}`,
      { noteTypes }
    );
    return response.data;
  };
}

export const noteTypesAPI = new NoteTypesAPI();
