import { Typography, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
// import { UrlParser } from "../../../utils";
// import { workflowAPI } from "../../../utils/api";
// import { Workflow } from "../../../utils/model";
import { useWorkflowDispatch, useWorkflowState } from "./../../../utils/hooks";
import { WorkflowPath } from "./components";
import { StatusEditWrapper } from "./components/Status";
import "./WorkflowBuilder";

interface IWorkflowBuilderProps {
}

const WorkflowBuilderWithRouter = (
  props: RouteComponentProps<IWorkflowBuilderProps>
) => {
  const setWorkflow = useWorkflowDispatch();
  const workflow = useWorkflowState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setActiveStep(0);
  }, [loading]);

  useEffect(() => {
    if (workflow.statusCollection.length === activeStep) {
      setActiveStep(workflow.statusCollection.length - 1);
    }
  }, [workflow.statusCollection.length, activeStep]);

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <div className="workflow-builder">
        <Typography>{workflow.name}</Typography>
        <WorkflowPath
          statusList={workflow.statusCollection}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
        <StatusEditWrapper
          status={workflow.statusCollection[activeStep]}
          index={activeStep}
        />
      </div>
    );
  }
};

export const WorkflowBuilder = withRouter(WorkflowBuilderWithRouter);
