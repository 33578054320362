import { CodeFullEdition } from "../model";
// import { APISettings } from "../settings";
// import { StaticAuthenticationSettings } from "../settings/AuthenticationSettings";
import { BaseAPI } from "./internal";

class CodificationHistoryAPI extends BaseAPI<CodeFullEdition> {
  constructor() {
    super("");
  }

  public getVolumeList: () => Promise<CodeFullEdition[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetVolumeList`)).data;
  };

  public getCodificationHistoryBySegmentId: (
    id: number
  ) => Promise<CodeFullEdition[]> = async (id: number) => {
    try {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetCodificationHistoryBySegmentIdAsync/${id}`
        )
      ).data;
    } catch (error) {
      return null;
    }
  };
}
export const codificationHistoryAPI = new CodificationHistoryAPI();
