import React, { Fragment } from "react";
import axios from "axios";
import "./WorkQueueWrapper.scss";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  DashboardTable,
  dashboardTableSetup,
  DraftModal,
  DraftTable,
  WorkQueueLinks,
  WorkQueueNews,
  WorkQueueTable,
  DashboardPagedTable
} from "..";

import { setDraftTypeRows as draftTableSetup } from "../DraftTableSetup";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Typography } from "@material-ui/core";
import { draftAPI, filingAPI, filingTypeAPI } from "../../../../../utils/api";
import { StatusRecordStatus, FilingTypes } from "../../../../../utils/enums";
import { SplitScreen } from "../SplitScreen";
import { Authorized } from "../../../../../components";
import { ExpandMore } from "@material-ui/icons";
import { getEntityData } from "../../../../../utils/utility_functions";
import { ListPayload } from "../../../../../utils/model/ListPayload";
import { Draft, Filing, FilingType } from "../../../../../utils/model";
import { SortDescriptor, DataResult } from "@progress/kendo-data-query";
import { GridPageChangeEvent } from '@progress/kendo-react-grid';

interface IWorkQueueWrapperProps extends IStateTabComponentProps { }

export const WorkQueueWrapper = (props: IWorkQueueWrapperProps) => {
  const [selectedTab, setSelectedTab] = React.useState("workQueue");
  const [expanded, setExpanded] = React.useState("table");
  const [draftRows, setDraftRows] = React.useState<any>([]);
  // const [draftRowCount, setDraftRowCount] = React.useState<number>(0);
  const [draftColumns, setDraftColumns] = React.useState<any>([]);
  // const [workRows, setWorkRows] = React.useState<DataResult>({
  //   data: [],
  //   total: 0,
  // });
  // const [workRowCount, setWorkRowCount] = React.useState<number>(0);
  const [openDraft, setOpenDraft] = React.useState<boolean>(false);
  const [draftData, setDraftData] = React.useState<any>(null);
  // const [noticeRows, setNoticeRows] = React.useState<any>([]);
  // const [adoptedRows, setAdoptedRows] = React.useState<any>([]);
  // const [feeIncreasesRows, setFeeIncreasesRows] = React.useState<any>([]);
  // const [emergencyRows, setEmergencyRows] = React.useState<any>([]);
  // const [permanentRows, setPermanentRows] = React.useState<any>([]);
  // const [preemptiveRows, setPreemptiveRows] = React.useState<any>([]);
  // const [executiveOrdersRows, setExecutiveOrdersRows] = React.useState<any>([]);
  // const [miscRows, setMiscRows] = React.useState<any>([]);
  // const [rulemakingRecordRows, setRulemakingRecordRows] = React.useState<any>([]);

  // const [columns, setColumns] = React.useState<any>([]);
  // const [numberOfRecords, setNumberOfRecords]:any = React.useState(20);
  const [expandedAccordionContentPanel, setExpandedAccordionContentPanel] = React.useState<string | false>('panel1');

  /**
   * This function returns an object with two keys: id and aria-controls.
   * The value of the id key is a string that is the same as the index argument.
   * The value of the aria-controls key is a string that is the same as the index argument.
   * @param {any} index - The index of the tab.
   * @returns The a11yProps function is returning an object with the id and aria-controls
  properties.
   */
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  /**
   * It takes an event and a new value, and sets the selected tab to the new value.
   * @param {React.ChangeEvent} event - React.ChangeEvent<{}>
   * @param {string} newValue - string
   * @returns None
   */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
    if (newValue == "Draft") {
      getDraftDetails();
    }
  };


  // const setWorkFilings = async (filingTypes:any) => {
  //   let filingsPayLoad:ListPayload<Filing>;
  //   if(filingTypes && columns.length < 1 && workRows.data.length < 1) {
  //     let rowCount:number = 0;
  //     //let count = await filingAPI.getFilingCount();
  //     filingsPayLoad = await filingAPI.getAllFilingsAsync({pageNumber: 1, pageSize: 10});
  //     setWorkRowCount(filingsPayLoad.totalCount);
  //     if(filingsPayLoad && filingsPayLoad.items.length > 0) {
  //       for(let i = 0; i < filingsPayLoad.items.length;i++){
  //         for(const typeItem of filingTypes){
  //           if(filingsPayLoad.items[i].filingTypeId == typeItem.id) {
  //             filingsPayLoad.items[i].filingType = typeItem.name;
  //           }
  //             //element.published = StatusRecordStatus[element.published];
  //         //filingsPayLoad.items[i].published = StatusRecordStatus[filingsPayLoad.items[i].published];

  //         filingsPayLoad.items[i].docketNumber = getEntityData(filingsPayLoad.items[i].entity,"docketNumber")

  //         filingsPayLoad.items[i].titleNumber = getEntityData(filingsPayLoad.items[i].entity, "rule", "referenceCode");

  //         filingsPayLoad.items[i].chapterNumber = getEntityData(filingsPayLoad.items[i].entity, "chapter", "chapterNum");

  //         }


  //       };
  //       let data = workQueueTableSetup(filingsPayLoad.items);
  //       setColumns(data.columns);
  //       setWorkRows({data:filingsPayLoad.items, total:filingsPayLoad.totalCount});
  //     }
  //   }
  // }
  //   const getAllFilings = async () => {
  //     let pagingParameters = { pageNumber: 1, pageSize: 50 };
  //     let filingTypes: FilingType[] = await filingTypeAPI.getAllFilingTypes(pagingParameters);
  //     let filings:Filing[] = [];
  //     let axiosArray:Promise<Filing[]>[] = [];

  //     for (let x = 0; x < filingTypes.length; x++) {
  //       let filingTypeId:number = filingTypes[x].id || 0;
  //       let promise = filingAPI.getFilingsByType(filingTypeId);
  //       axiosArray.push(promise);
  //     }
  //       await axios.all([...axiosArray]).then(
  //       axios.spread((...allResponses) => {
  //         for(let i = 0; i < allResponses.length; i++){
  //           for(const filing of allResponses[i]){
  //             filings.push(filing);
  //           }
  //         }
  //    })).catch(error => {
  //     console.error(error.message);
  //    });
  //    return filings;
  // };
  // I don't like this on mount function, we should refactor the api to get filings by these groupings.
  // Right now this will not allow server side paging where filing types are being combined.
  // const getFilingDetails = async() => {
  //   let pagingParameters = { pageNumber: 1, pageSize: 50 };
  //   let filingTypes:FilingType[] = await filingTypeAPI.getAllFilingTypes(pagingParameters);
  //   // TODO: THIS FILING LIST GETS ALL NOTICE ALSO DOESN'T HAVE PAGING YET.
  //   //let filingsList:any = await filingAPI.getAllFilingsLikeTypeName("Notice-");
  //   const notices:Filing[] = [];
  //   const adopted:Filing[] = []
  //   const fees:Filing[] = [];
  //   const emergency:Filing[] = [];
  //   const editorNotice:Filing[] = [];
  //   const misc:Filing[] = [];
  //   const permanent:Filing[] = [];
  //   const preemptive:Filing[] = [];
  //   const executiveOrders:Filing[] = [];

  //   const tempFilings = await getAllFilings();

  //   if(tempFilings.length > 0) {
  //     for(let element of tempFilings){
  //       try{
  //         //const tempFilings:any = await filingAPI.getFilingsByType(element.id);
  //         const filingTypeName  = element?.filingType?.name ?? "";
  //         //noticeItem.published = StatusRecordStatus[noticeItem.published];
  //         //noticeItem.filingType = noticeItem.name;

  //         element.docketNumber = getEntityData(element.entity, "docketNumber");

  //         element.titleNumber = getEntityData(element.entity, "rule", "referenceCode");

  //         element.chapterNumber = getEntityData(element.entity, "chapter", "chapterNum");

  //         element.submissionDate = (element.submissionDate != null) ? new Date(element.submissionDate).toLocaleDateString() : null;
  //         element.acceptedDate = (element.acceptedDate != null) ? new Date(element.acceptedDate).toLocaleDateString() : null;
  //         element.publicationDate = (element.publicationDate != null) ? new Date(element.publicationDate).toLocaleDateString() : null;

  //       switch (filingTypeName) {
  //         case "Notice-SR":
  //         case "Notice-NRI":
  //         case "Notice-LD":
  //         case "Notice-WR":
  //         case "Notice-CON":
  //         case "Notice-CAN":
  //               notices.push(element);
  //           break;
  //         case "Adoption-PERM":
  //         case "AdoptedPreemptive":
  //         case "AdoptionOfEmergency":

  //               adopted.push(element);
  //           break;
  //         case "Fee Increase" || "FeeIncrease":

  //           fees.push(element);
  //           break;
  //         case "Emergency":

  //            emergency.push(element);
  //           break;
  //         case "Permanent":

  //             permanent.push(element);
  //           break;
  //         case "Preemptive":

  //            preemptive.push(element);
  //           break;
  //         case "ExecutiveOrders":

  //            executiveOrders.push(element);
  //           break;
  //         case "Misc":

  //            misc.push(element);
  //           break;
  //         case "Editor'sNotice":
  //           console.log(`value:${element?.filingType?.id}`);
  //            editorNotice.push(element);
  //           break;
  //       }
  //     }catch(ex){}

  //     }
  //   }
  //   setRulemakingRecordRows(editorNotice);
  //   setMiscRows(misc);
  //   setExecutiveOrdersRows(executiveOrders);
  //   setPreemptiveRows(preemptive);
  //   setPermanentRows(permanent);
  //   setEmergencyRows(emergency);
  //   setFeeIncreasesRows(fees);
  //   setAdoptedRows(adopted);
  //   setNoticeRows(notices);
  // //       setColumns(data.columns);
  //   //await setWorkFilings(filingTypes);
  // }

  /**
   * @description - Takes in a date and returns just the date corrected for timezone
   * @param date - date timestamp
   * @returns - Local date corrected for timezone to fix off by one "day" when dealing with GMT dates
   */
  const getDate = (date: string) => {
    let newdate = new Date(date);
    const day = newdate.getUTCDate() - 1;
    const month = newdate.getUTCMonth() + 1; // Return Value is 0 indexed
    const year = newdate.getUTCFullYear();
    const localString = new Date(month + "-" + day + "-" + year).toLocaleDateString();
    return localString;
  }

  /**
   * @description gets users drafts on draft tab click
   * @todo get draft from api and configure rows and columns with draftTableSetup
   */
  const getDraftDetails = async (draft?: Draft) => {

    let drafts: any = await draftAPI.getAllDrafts();
    // let draft = new Draft('Test Draft','150','10',123,new Date(),'N',true);
    let data = draftTableSetup([drafts[0]]);

    drafts.map((draft: any, index: number) => {
      draft.createdOn = new Date(draft.createdOn).toLocaleDateString();
    });
    // console.log("Columns: ", data.rows)
    setDraftRows(drafts);
    setDraftColumns(data.columns);
    if(draft){
      setDraftData(draft);
    }
  }

  /**
   * @description - onMount kicks off the api calls to populate table data
   */
  const onMount = async () => {
    if (selectedTab !== "Draft") {
      //await getFilingDetails();
    } else {
      await getDraftDetails();
    }
  }

  /**
   *
   * @param {Object} pageData - page take and skip values for serverside paging
   * @returns work queue row data
   */
  // const getNextWorkPage = async(event:GridPageChangeEvent, sort:SortDescriptor[]) => {
  //   let page = ((event.page.skip + event.page.take) / event.page.take);
  //   let pageSize = Math.abs(event.page.take);
  //   let pageValues:any;
  //   let currentData:any = [];
  //   let pagingParameters = { pageNumber: 1, pageSize: 50 };
  //   let filingTypes:any = await filingTypeAPI.getAllFilingTypes(pagingParameters);
  //   pageValues = await filingAPI.getAllFilingsAsync({pageNumber: page, pageSize: pageSize});
  //   setWorkRowCount(pageValues.totalCount);
  //   if(filingTypes && pageValues && pageValues.items.length > 0){
  //     const items = pageValues.items;
  //     for(let index = 0; index < items.length;index++){
  //       for(const typeItem of filingTypes){
  //         if(items[index].filingTypeId == typeItem.id) {
  //           items[index].filingType = typeItem.name;
  //         }
  //       }
  //       //items[index].published = StatusRecordStatus[items[index].published];
  //       items[index].docketNumber = getEntityData(items[index].entity,"docketNumber")

  //       items[index].titleNumber = getEntityData(items[index].entity, "rule", "referenceCode");

  //       items[index].chapterNumber = getEntityData(items[index].entity, "chapter", "chapterNum");
  //     }
  //     const totalRecords = pageValues.totalCount;
  //     const numberOfRecords = pageValues.items.length;//5

  //     // currentData.length = totalRecords;//300
  //     // currentData.fill({id:0},0,numberOfRecords);

  //     // if(sort[0].field == "id" && sort[0].dir == "asc"){
  //     //   for(let i = 0; i < numberOfRecords; i++){
  //     //     currentData[i] = items[i];
  //     //   }
  //     // }else{
  //     //   for(let i = 0; i < numberOfRecords; i++){
  //     //     currentData[i] = items[i];
  //     //   }
  //     // }


  //     // workRows.forEach((element:any, i:number) => {
  //     //   currentData[i] = element;
  //     // })

  //     // items.forEach((element:any, i:number) => {
  //     //   currentData[i+pageData.skip] = element;
  //     // });
  //     setWorkRows({data:items, total:totalRecords});
  //     return items;
  //   }

  //   return workRows;
  // }

  /**
   * @description toggles the draft modal window. This also clears state draft data for making sure we don't pass data into the create form
   * @param {Objec} [rowData] - rowData object passed on click of row item to be passed into draft modal window
   */
  const toggleDraftModal = () => {
    setDraftData(null);
    openDraft ? setOpenDraft(false) : setOpenDraft(true);
  }

  /**
   * @description toggles the draft modal window for edit and sets the selected row data
   * @param {Object} [rowData] - rowData object passed on click of row item to be passed into draft modal window
   */
  const openEdit = (rowData?: any) => {
    if (rowData) {
      setDraftData(rowData);
    }
    // openDraft ? setOpenDraft(false) : setOpenDraft(true);
    setExpanded("edit");
  }

  const setExpandedPanel = () => {
    expanded == "table" ? setExpanded("edit") : setExpanded("table");
  }

  const handleAccordionContentChange = (panel: any) => (event: any, isExpanded: boolean) => {
    // console.log('Accordion Expanded: ', { panel, isExpanded });
    setExpandedAccordionContentPanel(isExpanded ? panel : false)
  }

  const updateDraft = (draft:Draft) => {
    setDraftData(draft);
  }

  React.useMemo(() => {
    onMount();
  }, [])

  return (
    <div className="work-queue-wrapper">
      <Tabs value={selectedTab} onChange={handleChange} indicatorColor="primary" className="tabs">
        <Tab
          value={"workQueue"}
          className="tab-menu"
          label={`Work Queue`}
          {...a11yProps(0)}
        />
        <Tab
          value={"Dashboard"}
          className="tab-menu"
          label={"Dashboard"}
          {...a11yProps(1)}
        />
        <Tab
          value={"Draft"}
          className="tab-menu"
          label={"Draft"}
          {...a11yProps(2)}
        />
      </Tabs>
      {selectedTab === "workQueue" ? (
        <div>
          {/* <WorkQueueTable {...props} title="My Work" /> */}

          <WorkQueueTable />

          <div className="split-display">
            <WorkQueueNews />
            <WorkQueueLinks />
          </div>
        </div>
      ) : null}
      {selectedTab === "Dashboard" ? (
        <Authorized
          showComponent={false}
          redirectToLogin={false}
          roles={["Office of Administrative Rules", "Global User Administrator"]}
          returnError={true}
        >
          <Fragment>

            <Accordion expanded={expandedAccordionContentPanel === 'panel1'}
              onChange={handleAccordionContentChange('panel1')}>
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography>Notice Rules | Adopted Rules</Typography>
                  {expandedAccordionContentPanel === "panel1" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <div className="dashboard">
                <div>
                  <DashboardPagedTable id="1" filingTypeIds={[2, 3, 4, 5, 6, 7, 8]} height={"75vh"} tableName="Notice Rules" />
                </div>
                <div>
                  <DashboardPagedTable id="2" filingTypeIds={[10, 11, 12]} height={"75vh"} tableName="Adopted Rules" />
                  {/*<DashboardTable rows={adoptedRows} height={"65vh"} tableName="Adopted Rules"/>*/}
                </div>
              </div>
            </Accordion>
            <Accordion expanded={expandedAccordionContentPanel === 'panel2'}
              onChange={handleAccordionContentChange('panel2')}>
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography>Fee Increase Rules | Emergency</Typography>
                  {expandedAccordionContentPanel === "panel2" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <div className="dashboard">
                <div>
                  <DashboardPagedTable id="3" filingTypeIds={[13]} height={"75vh"} tableName="Fee Increase Rules" />
                </div>
                <div>
                  <DashboardPagedTable id="4" filingTypeIds={[14]} height={"75vh"} tableName="Emergency" />
                </div>
              </div>
            </Accordion>
            <Accordion expanded={expandedAccordionContentPanel === 'panel3'}
              onChange={handleAccordionContentChange('panel3')}>
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography>Permanent | Preemptive</Typography>
                  {expandedAccordionContentPanel === "panel3" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <div className="dashboard">
                <div>
                  <DashboardPagedTable id="5" filingTypeIds={[15]} height={"75vh"} tableName="Permanent" />
                </div>
                <div>
                  <DashboardPagedTable id="6" filingTypeIds={[16]} height={"75vh"} tableName="Preemptive" />
                </div>
              </div>
            </Accordion>
            <Accordion expanded={expandedAccordionContentPanel === 'panel4'}
              onChange={handleAccordionContentChange('panel4')}>
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography>Executive Orders | MISC</Typography>
                  {expandedAccordionContentPanel === "panel4" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}

                </div>
              </AccordionSummary>
              <div className="dashboard">
                <div>
                  <DashboardPagedTable id="7" filingTypeIds={[17]} height={"75vh"} tableName="Executive Orders" />
                </div>
                <div>
                  <DashboardPagedTable id="8" filingTypeIds={[18]} height={"75vh"} tableName="MISC" />
                </div>
              </div>
            </Accordion>
            <Accordion expanded={expandedAccordionContentPanel === 'panel5'}
              onChange={handleAccordionContentChange('panel5')}>

              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">

                  <Typography>Rulemaking Record</Typography>
                  {expandedAccordionContentPanel === "panel5" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <div className="dashboard">

                <DashboardPagedTable id="9" filingTypeIds={[19]} height={"75vh"} tableName="Rulemaking Record" />
              </div>
            </Accordion>
            <div className="split-display">
              <WorkQueueNews />
              <WorkQueueLinks />
            </div>
          </Fragment>
        </Authorized>
      ) : null}
      {selectedTab === "Draft" ? (
        <Authorized
          showComponent={false}
          redirectToLogin={false}
          roles={["Agency Liaison", "Attestation Officer", "Office of Administrative Rules", "Global User Administrator"]}
          returnError={true}
        >
          <div>
            <Accordion
              expanded={expanded === "table"}
              onChange={setExpandedPanel}
              TransitionProps={{
                timeout: 200
              }}
            >
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography >Rule Drafting Table</Typography>
                  {expanded === "table" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <DraftModal id="draftModal" draftData={draftData} open={openDraft} close={toggleDraftModal} reloadData={getDraftDetails} edit={false}/>
                {/* TODO: FIX THIS SO THE NUMBER OF RECORDS IS FETCHED CORRECTLY */}
                <DraftTable rows={draftRows} columns={draftColumns} numberOfRecords={draftRows.length} add={toggleDraftModal} edit={openEdit} reloadData={getDraftDetails}/>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "edit"}
              onChange={setExpandedPanel}
              TransitionProps={{
                timeout: 200
              }}
            >
              <AccordionSummary className="accordion-summary">
                <div className="accordion-label">
                  <Typography>Rule Drafting Editor</Typography>
                  {expanded === "edit" ? (<KeyboardArrowUpIcon fontSize="medium" />) : (<KeyboardArrowDownIcon fontSize="medium" />)}
                </div>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <SplitScreen draft={draftData} reloadData = {getDraftDetails} />
              </AccordionDetails>
            </Accordion>
          </div>
        </Authorized>
      ) : null
      }
    </div>
  );
};
