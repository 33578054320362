export const useFormControls = () => {
  const validateEmail: any = (email: any) => {
    var validated = false;

    if (email) {
      if (email)
        validated = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)
          ? true
          : false;
    }

    return validated;
  };

  const validateName: any = (name: any) => {
    var validated = false;
    if (name) {
      validated = name.length > 0
        ? true
        : false;
    }
    return validated;
  };

  const validatePhoneNumber: any = (phone: any) => {
    // console.log("hit the phone validation")
    var validated = false;
    if (phone) {
      validated = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[.-]\d{4}$/.test(phone)
        ? true
        : false;
    }
    return validated;
  };

  return {
    validateEmail,
    validateName,
    validatePhoneNumber
  };
}