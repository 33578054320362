import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./DashboardTable.scss"
import { CircularProgress, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { UrlParser } from '../../../../../utils';
import { filingTypeAPI } from '../../../../../utils/api';
import { APISettings } from '../../../../../utils/settings';
import { FilingType } from '../../../../../utils/model/FilingType';
import { DueDates } from '../../../../../utils/model';
// import {
//   setFilingTypeRows as workQueueTableSetup
// } from "../WorkQueueTableSetup";
import { setFilingTypeRows as dashboardTableSetup } from "../DashboardTableSetup";


interface IDashboardTableProps {
  rows: any;
  //columns:any;
  add?: () => void;
  height?: string;
  tableName?: string;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

interface TempColumn {
  visible: boolean;
  field: string;
  title: string;
  width: string;
}

export const DashboardTable = (props: IDashboardTableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(

    process(props.rows, dataState)
  );

  const onMount = React.useCallback(async () => {
    // console.log('Dashboard Table Mounted');
    if (props.height) {
      setHeight(props.height);
    }

    // await props.rows.forEach(async (element:any,i:number) => {
    //   let filingType:FilingType = await filingTypeAPI.getFilingTypeById(element.filingTypeId);
    //   props.rows[i].filingType = filingType;

    //   let url = UrlParser.Filing.parseFiling(props.rows[i].filingType.name, props.rows[i].id);
    //   props.rows[i].url= url;

    // })

    props.rows.forEach((e: any, i: number) => {
      e.created = new Date(e.created).toLocaleDateString();
      e.adoptionDate = new Date(e.adoptionDate).toLocaleDateString();
    });

    setDataResult(process(props.rows, dataState));

  }, [props.rows])

  React.useEffect(() => {
    onMount();
  }, [props])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const dueDateColumn = (data: GridCellProps) => {
    let dueDates: any = new DueDates();
    var currentDate = new Date();
    var elementDate = new Date(data.dataItem.created);
    var returnValue = "inactive";
    for (var i: number = 0; i < dueDates.DueDates.length; i++) {
      var submissionDate = new Date(dueDates.DueDates[i].submissionDate);
      var acceptDate = new Date(dueDates.DueDates[i].acceptanceDate);
      if (elementDate >= submissionDate && elementDate >= acceptDate) {
        returnValue = "active";
        break;
      }
    }

    // if not found in due date array return error icon
    if (returnValue == "active") {
      return (
        <td className="newIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Active" aria-label="Active">
            <AddCircleIcon />
          </Tooltip>
        </td>
      )
    } else {
      return (
        <td className="oldIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Past Due" aria-label="PastDue">
            <ErrorIcon />
          </Tooltip>
        </td>
      )
    }
  }

  const hyperLinkColumn = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        <Tooltip title="Filing" aria-label="filing">
          <a href={UrlParser.Filing.parseFiling(data.dataItem.filingType.name, data.dataItem.id) ? UrlParser.Filing.parseFiling(data.dataItem.filingType.name, data.dataItem.id).toString() : ""}>{data.dataItem.name}</a>
        </Tooltip>
      </td>
    )
  }

  const _renderTable = () => {
    let data = dashboardTableSetup(props.rows);
    var allColumns = props.rows.length > 0 ? data.columns : [];
    //var allColumns = props.rows.length > 0 ? props.columns : [];

    let setColumns: TempColumn[] = [];

    allColumns.map((element: any) => {

      let column = {} as TempColumn
      column.field = element.field;
      column.title = element.title;
      column.visible = false;

      switch (element.field) {
        case "id":
          break;
        case "docketNumber":
          column.visible = true;
          break;
        case "submissionDate":
          column.visible = true;
          break;
        case "acceptedDate":
          column.visible = true;
          break;
        case "publicationDate":
          column.visible = true;
          break;
        case "titleNumber":
          column.visible = true;
          break;
        case "chapterNumber":
          column.visible = true;
          break;
        case "published":
          break;
        case "filingType":
          break;
        case "status":
          break;
        case "created":
          break;
        case "ruleId":
          break;
        case "chapterId":
          break;
        case "liaisonId":
          break;
        case "docketId":
          break;
        case "adoptionDate":
          break;
        case "titleNumber":
          break;
        case "draftId":
          break;
        case "chapterNumber":
          break;
        case "showInReviewModal":
          break;
        case "isReadOnly":
          break;
        case "isInDraftState":
          break;
        case "isInDraftState":
          break;
        case "registerId":
          break;
        default:
          break;
      }
      setColumns.push(column);
    });

    var columnsToShow = [];
    columnsToShow = setColumns.map((element: TempColumn, i: number) => {
      if (element.visible) {
        return (<GridColumn field={element.field} key={i + element.title} title={element.title} width={element.width} />);
      }
    });

    columnsToShow.unshift(<GridColumn key={"link"} field={"Link"} title="Name" cell={hyperLinkColumn} />);
    // columnsToShow.unshift(<GridColumn key={"dueDate"} field={"dueDate"} title="Status" cell={dueDateColumn} />);

    if (columnsToShow.length > 0 && props.rows.length > 0) {
      return (
        <Grid
          key={"DashboardTable"}
          style={{
            height: height,
          }}
          // sortable={true}
          // filterable={true}
          resizable={true}
          // groupable={true}
          reorderable={true}
          pageable={{
            buttonCount: 4,
            pageSizes: true,
          }}
          data={dataResult}
          {...dataState}
          onDataStateChange={dataStateChange}
        // detail={DetailComponent}
        // expandField="expanded"
        // onExpandChange={expandChange}
        >
          <GridToolbar>
            <ButtonGroup className="button-group">
              {/* <Button
                icon="xls"
                title="Export to Excel"
                className="k-button k-secondary button"
                onClick={exportExcel}
              /> */}
              <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
              {/* <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={props.add}/> */}
            </ButtonGroup>
            {props.tableName ? (
              <h3>{props.tableName}</h3>
            ) : null}
          </GridToolbar>
          <GridNoRecords>
            <CircularProgress />
          </GridNoRecords>
          {columnsToShow}
        </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="work-queue">
      <ExcelExport
        data={props.rows}
        ref={(exporter: any) => {
          _export = exporter;
        }}
      >
        {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}


