import { useState, Fragment } from "react";
import {
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import {
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import "./CodeResult.scss";
import { ExpansionDetail } from ".";



interface IExecOrderExpansionProps {
  className?: string;
  childSegments?: any;
  noteTypes: any;
}

export const ExecOrderExpansion = (props: IExecOrderExpansionProps) => {
  const [expanded, setExpanded] = useState("");

  // expand accoridon
  const handleChange = (id: any, type?: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? id : false);
  };

  const getStatusTag = (item: any) => {
    if (item.statusName) {
      switch (item.statusName) {
        // case "New":
        //   return "[New]";
        case "Revoked":
          return "[Revoked]";
        case "Reserved":
          return "[Reserved]";
        case "Expired":
          return "[Expired]";
        case "AmmendedAndRenumbered":
          return "[Amended and Renumbered]";
        case "Renumbered":
          return "[Renumbered]";
        case "Superseded":
          return "[Superseded]";
        case "Terminated":
          return "[Terminated]";
        case "Transferred":
          return "[Transferred]";
        case "Other":
          return "[Other]";
        case "Published":
          return "[Published]";
        default:
          return "";
          break;
      }
    }
  }


  const _renderAccordion = (item: any) => {
    return (
      <Accordion
        className={props.className || 'test'}
        key={item.id}
        expanded={expanded === `${item.id}`}
        onChange={handleChange(`${item.id}`)}
        TransitionProps={{
          unmountOnExit: true,
          timeout: 100
        }}
      >
        <AccordionSummary
          className="label"
          aria-controls="panel2d-content"
          aria-label="Agency Listing"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >

          {item.name}. {item.execOrderNum} {getStatusTag(item)}

        </AccordionSummary>
        {/* if value details exist then render card */}
        {item.name == "Executive Order" ? (<ExpansionDetail segment={item} noteTypes={props.noteTypes} />) : null}
      </Accordion>
    )
  }

  // main return calls accoridon render
  return (
    <Fragment>
      {props.childSegments ?
        props.childSegments.map((item: any, index: number) => {
          if (item) {
            return (_renderAccordion(item));
          }
        })
        : null}
    </Fragment>
  );
};


