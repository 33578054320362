import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { ActionIconWrapper } from "..";
import { Filing } from "../../../../../utils/model";
import "./Action.scss";

interface IActionProps {
  row: Filing;
  updateRows: () => void;
}

export const Action = (props: IActionProps) => {
  let { row, updateRows } = props;
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return <CircularProgress />;
  }
  if (row != null && row.availableActions != null) {
    return (
      <div className="action-container">
        {row.availableActions?.map((action, actionIndex) => (
          <ActionIconWrapper
            setLoading={setLoading}
            action={action}
            filing={row}
            key={actionIndex}
            updateRows={updateRows}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
};
