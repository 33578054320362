import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class CodificationAPI extends BaseAPI<any> {
  constructor() {
    super("", APISettings.adminRulesApiUrl);
  }

  public codifyFiling = async (
    filingId: number,
    actionTypeId: number | null | undefined
  ): Promise<any> => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/CodifyFiling/${filingId}/${actionTypeId}`
    );
    return response.data;
  };

  public generateTitleDocument: (id: number) => Promise<any> = async (
    id: number
  ) => {
    return (
      await BaseAPI.axios.put(`${this.apiUrl}/GenerateTitleDocument/${id}`)
    ).data;
  };
}

export const codificationAPI = new CodificationAPI();
