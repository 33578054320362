import React from 'react';
import { Agency } from '../model/Agency';
import { FilingType, VisibilityLevel } from '../enums';
import { SetStateAction, Dispatch } from 'react';
type AgencyDispatch = (state: Agency) => void;
type AgencyProviderProps = { children: React.ReactNode }
const defaultAgency = new Agency("loading", FilingType.Standard_Rule, VisibilityLevel.GeneralPublic);
const AgencyStateContext = React.createContext<Agency>(defaultAgency);
const AgencyDispatchContext = React.createContext<Dispatch<SetStateAction<Agency>>>(()=>{});

function AgencyProvider({ children }: AgencyProviderProps) {
  const [state, dispatch] = React.useState<Agency>(defaultAgency);
  return (
    <AgencyStateContext.Provider value={state}>
      <AgencyDispatchContext.Provider value={dispatch}>
        {children}
      </AgencyDispatchContext.Provider>
    </AgencyStateContext.Provider>
  )
}
function useAgencyState() {
  const context = React.useContext(AgencyStateContext)
  if (context === undefined) {
    throw new Error('useAgencyState must be used within a AgencyProvider');
  }
  return context
}
function useAgencyDispatch() {
  const context = React.useContext(AgencyDispatchContext)
  if (context === undefined) {
    throw new Error('useAgencyDispatch must be used within a AgencyProvider');
  }
  return context
}

function useAgency(): ([Agency, AgencyDispatch]) {
  return [useAgencyState(), useAgencyDispatch()];
}
export { AgencyProvider, useAgency };