import React from "react";
import { AuthenticationContext } from "../../AuthenticationProvider";
import { CircularProgress } from "@material-ui/core";

interface ISignInRedirectCallbackProps {}

export const SignInRedirectCallback = (props: ISignInRedirectCallbackProps) => {
  const { signinRedirectCallback } = React.useContext(AuthenticationContext);

  React.useEffect(() => {
    signinRedirectCallback()
  }, [signinRedirectCallback])
  
  return <CircularProgress />;
};
