import { Box, withStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useEffect } from "react";
import { contactTypeAPI } from "../../../../../../../utils/api";
import { StateOfficeNavigationSettings } from "../../../../../../../utils/settings";
import { GenericTable } from "../GenericTable";
import "./TabMenu.scss";

interface ITabMenuProps {}

const CustomTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    padding: "1px",
    backgroundColor: "#004E9A",
  },
})(Tabs);

const SubMenuTabs = withStyles({
  root: {
    borderBottom: "1px solid #000",
  },
  indicator: {
    padding: "1px",
    backgroundColor: "#004E9A",
  }
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// props for main tabs
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// props for contact type sub menu
function a12yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div className="body">{value === index && <Box p={0}>{children}</Box>}</div>
  );
}

export const TabMenu = (props: ITabMenuProps) => {
  const { OwningUsed, OwningTitlePlural, RuleTitlePlural } =
    StateOfficeNavigationSettings;
  const [value, setValue] = React.useState(0);
  const [subValue, setSubValue] = React.useState(0);
  const [contactTypes, setContactTypes] = React.useState([]);
  const [tabs, setTabs] = React.useState(<Tab></Tab>);
  // const [contactTable, setContactTable] = React.useState({
  //   val: (
  //     <GenericTable
  //       modalType={"contact"}
  //       contactType={contactTypes[subValue]}
  //     />
  //   ),
  // });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleSubChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSubValue(newValue);
  };

  // ######################################################
  // generate contact type tabs
  // ######################################################
  const _createContactTypeTabs = React.useCallback(async function () {
    let values: any;
    try {
      values = await contactTypeAPI.getAllContactTypes();
      setContactTypes(values.result);
      createTabs(values.result);
    } catch (err) {}
  }, []);

  // ######################################################
  // generate contact type tabs
  // ######################################################
  const createTabs = function (tabs: any) {
    var tab: any = [];
    var added: any = [];
    for (var i = 0; i < tabs.length; i++) {
      if (added.indexOf(tabs[i].type) === -1) {
        added.push(tabs[i].type);
        tab.push(
          <Tab className="tab" label={tabs[i].type} {...a12yProps(i)} />
        );
      }
    }
    if (tab.length === 0) {
      tab.push(
        <Tab
          className="tab"
          label={"No contact types found"}
          {...a12yProps(0)}
        />
      );
      setTabs(tab);
    } else {
      setTabs(tab);
    }
  };

  // Triggers on load to generate tabs
  useEffect(() => {
    _createContactTypeTabs();
  }, [_createContactTypeTabs]);

  const renderTabs = (): JSX.Element => {
    if (OwningUsed) {
      return (
        <CustomTabs value={value} onChange={handleChange} className="tabs">
          <Tab
            className="tab"
            label={`${OwningTitlePlural}`}
            {...a11yProps(0)}
          />
          <Tab className="tab" label={`${RuleTitlePlural}`} {...a11yProps(1)} />
          {/* <Tab className="tab" label="contact type" {...a11yProps(2)} /> */}
          <Tab className="tab" label="Contacts" {...a11yProps(2)} />
        </CustomTabs>
      );
    }
    return (
      <CustomTabs value={value} onChange={handleChange} className="tabs" variant="scrollable">
        <Tab className="tab" label={`${RuleTitlePlural}`} {...a11yProps(0)} />
        {/**************KNOWN BUG**********************
         * On Selecting Contact the screen goes white
         * TODO: Diagnose Bug, and work on implementing
         * Dynamic contact tabs, on creating a new contact_type
         * UPDATE: Seems to be caused by the seeded data
         * seeded data doesn't have an Address
         ***************KNOWN BUG*********************/}
        {/* <Tab className="tab" label="contact type" {...a11yProps(1)} /> */}
        <Tab className="tab" label="Contacts" {...a11yProps(1)} />
      </CustomTabs>
    );
  };

  const renderPanels = (): JSX.Element => {
    if (OwningUsed) {
      return (
        <div>
          <TabPanel value={value} index={0}>
            <GenericTable modalType={"owning"} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GenericTable modalType={RuleTitlePlural} />
          </TabPanel>
          {/**************KNOWN BUG**********************
           * On Selecting Contact the screen goes white
           * TODO: Diagnose Bug, and work on implementing
           * Dynamic contact tabs, on creating a new contact_type
           * UPDATE: Seems to be caused by the seeded data
           * seeded data doesn't have an Address
           ***************KNOWN BUG*********************/}
          {/* <TabPanel value={value} index={2}>
            <GenericTable modalType={"contact type"} />
          </TabPanel> */}
          <TabPanel value={value} index={2}>
            <SubMenuTabs
              value={subValue}
              onChange={handleSubChange}
              className="tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs}
            </SubMenuTabs>
            {/* //takes in contact type for filtered table view */}
            <GenericTable
              modalType={"contact"}
              contactType={contactTypes[subValue]}
            />
          </TabPanel>
        </div>
      );
    } else {
      return (
        <div>
          <TabPanel value={value} index={0}>
            <GenericTable modalType={RuleTitlePlural} />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <GenericTable modalType={"contact type"} />
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <SubMenuTabs
              value={subValue}
              onChange={handleSubChange}
              className="tabs"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs}
            </SubMenuTabs>
            {/* //takes in contact type for filtered table view */}
            <GenericTable
              modalType={"contact"}
              contactType={contactTypes[subValue]}
            />
          </TabPanel>
        </div>
      );
    }
  };
  return (
    <div className="tab-menu">
      {renderTabs()}
      {renderPanels()}
    </div>
  );
};
