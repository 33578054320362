import { Button } from "@progress/kendo-react-buttons";
import React, { useState, useEffect } from "react";
import { contactAPI } from "../../../../../utils/api";
import "./CodeResult.scss";

interface IContactHyperLinkProps {
  contactIds: any;
  open?: boolean | undefined;
  close?: () => void;
  handleClick? : () => void;
}

export const CommentHyperLink = (props: IContactHyperLinkProps) => {
  // const [email, setEmail] = useState<any>();
  const [showModal, setShowModal] = useState <boolean>(false);

  // const onMount = React.useCallback(async () => {
  //     let contact:any;
  //     if(props.contactIds.liaison) {
  //           contact = await contactAPI.getContactById(props.contactIds.liaison);
  //           setEmail("mailto:" + contact.email);
  //     }
  // },[props.contactIds])


  // useEffect(() => {
  //   if(props.contactIds) {
  //     onMount();
  //   }
  // }, [props.contactIds])



  return (
    <Button  look="flat" icon="comment" onClick={props.handleClick} style={{ color:'red'}}>Provide Public Comment</Button>
  )

};

