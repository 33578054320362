import { Action, FilingActionRequest } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class ActionAPI extends BaseAPI<Action> {
  constructor() {
    super("actions", APISettings.adminRulesApiUrl);
  }

  public getActions: () => Promise<Action> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetActions`)).data;
  };

  public getActionsById: (id: number) => Promise<Action> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetActionsById/${id}`))
      .data;
  };

  public getActionsByStatusId: (id: number,titleNum: string, filingId:number) => Promise<Action[]> = async (
    id: number,
    titleNum: string,
    filingId: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetActionsByStatusId?id=${id}&title=${titleNum}&filingId=${filingId}`,)).data;
  }

  public createActions: (action: Action) => Promise<any> = async (
    action: Action
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateActions`, {
      action,
    });
    return response.data;
  };

  public removeActions: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveActions/${id}`
    );
    return response.data;
  };

  public updateActions: (id: number, Action: Action) => Promise<Action> =
    async (id: number, Action: Action) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/UpdateActions/${id}`,
        { Action }
      );
      return response.data;
    };

  public updateFilingWorkflowAction: (request: FilingActionRequest) => Promise<any> = async (request:FilingActionRequest) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/filing/workflow/action`, request);
    return response.data;
  }
}

export const actionAPI = new ActionAPI();
