import { Rule, Contact } from "../model/";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class RuleAPI extends BaseAPI<Rule> {
  constructor() {
    super("rule", APISettings.adminRulesApiUrl);
  }

  public getAllRules: (paging?: any, filter?:boolean) => Promise<Rule[]> = async (
    paging?: any,
    filter?:boolean
  ) => {
    let rules = (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllRules?pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}&filter=${filter}`
      )
    ).data;
    return rules;
  };

  public getAllRulesWithEmergency: (paging?: any, filter?:boolean) => Promise<Rule[]> = async (
    paging?: any,
    filter?:boolean
  ) => {
    let rules = (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllRulesWithEmergency?pageNumber=${paging.pageNumber}&pageSize=${paging.pageSize}&filter=${filter}`
      )
    ).data;
    return rules;
  };

  public getRulesList: () => Promise<Rule[]> = async () => {
    let rules = (await BaseAPI.axios.get(`${this.apiUrl}/GetRulesList`)).data;

    return rules;
  };

  public getRuleCount: () => Promise<Rule[]> = async () => {
    let rules = (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleCount`)).data;
    return rules;
  };

  public getRuleById: (id: number) => Promise<Rule> = async (id: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleById/${id}`)).data;
  };


  public getRuleReferenceCode: (referenceCode: string) => Promise<Rule> = async (referenceCode: string) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleReferenceCode/${referenceCode}`)).data;
  };

  public getRuleSegmentId: (segmentId: number) => Promise<Rule> = async (segmentId: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleSegmentId/${segmentId}`)).data;
  };

  public getRuleContactsAsync: (ruleId: number) => Promise<Contact> = async (ruleId: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetRuleContactsAsync/${ruleId}`)).data;
  };

  public createRule: (rule: Rule) => Promise<any> = async (rule: Rule) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateRules`, rule);
    return response.data;
  };

  public removeRule: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.put(`${this.apiUrl}/RemoveRules/${id}`);
    return response.data;
  };

  public updateRule: (ruleId: number, rule: Rule) => Promise<any> = async (ruleId: number, rule: Rule) => {
    let response = await BaseAPI.axios.put(`${this.apiUrl}/UpdateRule`, rule);
    return response.data;
  };

  public createAdoptedRuleDocument: (filingId: number) => Promise<any> = async (filingId: number) => {
    let response = await BaseAPI.axios.put(`${this.apiUrl}/CreateAdoptedRuleDocument/${filingId}`);
    return response.data;
  };
}

export const ruleAPI = new RuleAPI();
