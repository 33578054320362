/**
 * This is a React component that is used to display the work queue.
 */
import "./WorkQueue.scss";
import { IStateTabComponentProps } from "../IStateTabComponentProps";
import { WorkQueueWrapper } from "./components";

interface IWorkQueueProps extends IStateTabComponentProps {}

export const WorkQueue = (props: IWorkQueueProps) => {
  return (
      <div className="work-queue">
        <WorkQueueWrapper {...props} />
      </div>
  );
};
