import {NotificationEvent} from ".";
import {Identifiable} from "..";
import {NotificationType} from "../../enums/NotificationType";

export class Notification extends Identifiable {
    public notificationEvent: NotificationEvent;
    public notificationType: NotificationType;
    public sendToSubscribers: boolean;
    public roles: string[];
    public emails: string[];
    public subject: string;
    public body: string;
    public text: string;
    public userIds: string[] = [];
    public replaceables: string[];
    public name: string;

    constructor(
        name: string,
        notificationEvent: NotificationEvent,
        notificationType: NotificationType,
        sendToSubscribers: boolean,
        roles: string[],
        emails: string[],
        subject: string,
        body: string,
        text: string,
        replaceables: string[]
    ) {
        super(null);
        this.name = name;
        this.notificationEvent = notificationEvent;
        this.notificationType = notificationType;
        this.roles = roles;
        this.sendToSubscribers = sendToSubscribers;
        this.emails = emails;
        this.subject = subject;
        this.body = body;
        this.text = text;
        this.replaceables = replaceables;
        this.userIds = [];
    }
}
