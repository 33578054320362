import { Step, StepButton, StepProps } from "@material-ui/core";
import { Status as StatusModel } from "./../../../../../../../utils/model";
import './StatusStep';

interface IStatusStepProps extends StepProps {
    status: StatusModel;
    onClickStep: (index: number) => void;
    index: number;
}

export const StatusStep = (props: IStatusStepProps) => {
    const { status, onClickStep, index } = props;
    return (
        <div onClick={() => onClickStep(index)}>
            <Step {...props} >
                <StepButton>
                    {/* <Status status={status}/> */}
                    {status.name}
                </StepButton>
            </Step>
        </div>
    );
};



