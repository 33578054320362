import { AppBar, Button, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import {
  AccountBox,
  AccountCircle,
  AllInbox,
  Announcement,
  Business,
  ExitToApp,
  Home,
  InsertComment,
  MenuBook,
  Lock,
  Security,
} from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import { StorageContainer } from "../../utils/enums";
import { ImageWrapper } from "../ImageWrapper";
import { MenuDrawer } from "../MenuDrawer";
import { UserIndicator } from "../Navigation/components";
import "./Menu.scss";
import { NavLink } from "./NavLink";
import {
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import SubscriptionLoginButton from "../../pages/Subscription/components/SubscriptionLoginButton/SubscriptionLoginButton";
import SubscriptionLogoutButton from "../../pages/Subscription/components/SubscriptionLogoutButton/SubscriptionLogoutButton";
import { useHistory } from "react-router";
import Email from "@material-ui/icons/Email";

interface IMenuProps {
  title: string;
  OS: string;
}

export const useMenuStyles = makeStyles((theme) => ({
  // appBarSpacer: theme.mixins.toolbar,
  root: {
    width: "auto",
    flexGrow: 1,
  },
  toolbar: {},
  menu: {
    position: "fixed",
    backgroundColor: "rgba(255,255,255,0.9)",
    // maxHeight: "162px",
    maxHeight: "7rem",
  },
  menuItems: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    // maxHeight: "162px",
    maxHeight: "7rem",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileMenuItems: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  menuRight: {},
  menuLeft: {
    flex: 10,
  },
  logo: {
    alignItems: "center",
    height: "7rem",
    maxHeight: "7rem",
    marginTop: "1em",
    // height: "162px",
    // maxHeight: "162px",
    // marginTop: "10px",
    alt: "Oklahoma Administrative rules logo",
  },
  linkIcon: {
    maxWidth: 17,
    marginRight: 3,
  },
}));

const url = `${window.location.origin}/code`;

export const Menu = (props: IMenuProps) => {
  let navigate = useHistory();
  const [open, setOpen] = React.useState(false);

  const [menuItem, setMenuItem] = React.useState("Login");

  const classes = useMenuStyles();
  const { signout, signin, isAuthenticated } = useContext(AuthenticationContext);
  const initialAuthenticated = isAuthenticated();
  useEffect(() => {
    setAuthentication(isAuthenticated());
  }, [isAuthenticated]);
  const [authenticated, setAuthentication] = useState(initialAuthenticated);

  const checkPage = () => {
    let windowCheck = window.location.pathname;

    if (windowCheck == "/code") {
      window.location.reload();
      window.location.href = "/code";
    } else {
      window.location.href = "/code";
    }
  };



  const handleLogout = () => {
    sessionStorage.removeItem("subscriptionUser");

    navigate.push("/home");
    // window.location.reload();
  };


  // Dropdown user options button
  const items = [
    sessionStorage.getItem("subscriptionUser") ? (
      <Button
        onClick={() => handleLogout()}
        size="medium"
        startIcon={<ExitToApp />}
      >
        Subscription Logout
      </Button>
    ) : (
      <Button
        onClick={() => (window.location.href = "/subscriptionLogin")}
        size="medium"
        startIcon={<Lock />}
      >
        Subscription Login
      </Button>
    ),

    authenticated ? (
      <Button aria-label="logout" className="logout" startIcon={<ExitToApp />} onClick={signout}>
        State Logout
      </Button>
    ) : (
      <Button onClick={signin} size="medium" startIcon={<Security />}>
        State Login
      </Button>
    ),
  ];

  const filingItems = [
    (<NavLink to="/proposedrules" icon={MenuBook}>
      Proposed Rules
    </NavLink>),
    // (<NavLink to="/adoptedrules" icon={AllInbox}>
    //   Adopted Rules
    // </NavLink>),
    (<NavLink to="/feeincrease" icon={Announcement}>
      Fee Increases
    </NavLink>)
  ]

  return (
    <div className={classes.root}>
      <AppBar className={classes.menu}>
        <nav>
          <Toolbar className={classes.toolbar}>
            {props.OS === "mobile" ? (
              <div className="mobile-menu">
                {/* <a href={`${window.location.origin}/home`}> */}
                <ImageWrapper
                  name="logo"
                  className="mobile-logo"
                  alt="Oklahoma Secretary of State Administrative rules"
                  storageContainer={StorageContainer.PublicCms}
                />
                {/* </a> */}
                <div>
                  <MenuDrawer />
                </div>
              </div>
            ) : (
              <div className={classes.menuItems}>
                <div className={classes.menuLeft}>
                  {/* <a href={`${window.location.origin}/home`}> */}
                  <div className="logo-wrapper">
                    <ImageWrapper
                      name="logo"
                      className={classes.logo}
                      alt="Oklahoma Secretary of State Administrative rules"
                      storageContainer={StorageContainer.PublicCms}
                    />
                  </div>
                  {/* </a> */}
                </div>
                <div className="menu">
                  <NavLink to="/home" icon={Home}>
                    {props.title}
                  </NavLink>
                  <NavLink to="/code" icon={MenuBook}>
                    Code
                  </NavLink>
                  {/* <NavLink to="/proposedrules" icon={MenuBook}>
                    Proposed Rules
                  </NavLink> */}
                  {/* <NavLink to="/adoptedrules" icon={AllInbox}>
                    Adopted Rules
                  </NavLink> */}
                  <NavLink to="/registers" icon={MenuBook}>
                    Register
                  </NavLink>
                  <NavLink to="/news" icon={InsertComment}>
                    News
                  </NavLink>
                  {/* <NavLink to="/feeincrease" icon={Announcement}>
                    Fee Increases
                  </NavLink> */}
                  <NavLink icon={AllInbox} to="/quicklinks">
                    QuickLinks
                  </NavLink>
                  <DropDownButton
                    icon="book"
                    className="menu-dropdown"
                    text="Proposed Rules"
                    items={filingItems}
                    look="flat"
                  />
                  {sessionStorage.getItem("subscriptionUser") ? (
                    <NavLink to="/subscriptionProfile" icon={Email}>
                      Subscriptions
                    </NavLink>
                  ) : null}

                  {authenticated ? (
                    <NavLink to="/state/my work" icon={Business}>
                      State Portal
                    </NavLink>
                  ) : (
                    <></>
                  )}
                  <>
                    <DropDownButton
                      icon="cog"
                      className="menu-dropdown"
                      text="User Options"
                      items={items}
                      look="flat"
                    />
                  </>
                </div>
                <div>
                  <UserIndicator />
                </div>
              </div>
            )}
          </Toolbar>
        </nav>
      </AppBar>
    </div>
  );
};
