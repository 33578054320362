import { SubscriptionUser } from "../../model/SubscriptionUser";
import { APISettings } from "../../settings/APISettings";
import { ListPayload } from "../../model";
import { UserSubscription } from "../../model/subscription/UserSubscription";
import { SubscriptionBaseAPI } from "../SubscriptionBaseApi";

class UserSubscriptionApi extends SubscriptionBaseAPI<SubscriptionUser> {
  constructor() {
    super("userSubscription", APISettings.adminRulesApiUrl);
  }

  // get all subscription users from API
  public getAllSubscriptionUsers: () => Promise<any> = async () => {
    var response = (
      await SubscriptionBaseAPI.axios.get(
        `${this.apiUrl}/api/SubscriptionUser/GetAllSubscriptionUsers`
      )
    ).data;
    return response.data;
  };

  // get all subscription users from API paged
  public getAllSubscriptionUsersAsync: (
    pagingParameters: any
  ) => Promise<ListPayload<SubscriptionUser>> = async (
    pagingParameters: any
  ) => {
    let response = await SubscriptionBaseAPI.axios.get(
      `${this.apiUrl}/api/GetAllSubscriptionUsersAsync?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}`
    );
    return response.data;
  };

  // get user subscriptions from API
  public getUserSubscriptions: (user: any) => Promise<UserSubscription[]> = async (
    user: any
  ) => {
    try {
      return (
        await SubscriptionBaseAPI.axios.get<any[]>(
          `${this.apiUrl}/UserSubscriptions?emailAddress=${user.email}`
        )
      ).data;
    } catch (error) {
      return [];
    }
  };

  // Add chapter subscription
  public addChapterSubscription: (
    itemId: any,
    userId: any,
    type: string
  ) => Promise<object> = async (itemId: any, userId: any, type: string) => {
    var titleId = 0;
    var chapterId = 0;

    if (type === "Title") {
      titleId = itemId;
    } else {
      chapterId = itemId;
    }

    var response = (
      await SubscriptionBaseAPI.axios.post<any[]>(
        `${this.apiUrl}/AddChapterSubscription`,
        {
          UserId: userId,
          TitleId: titleId,
          ChapterId: chapterId,
        }
      )
    ).data;

    return response;
  };

  // Add title subscription
  // When a title has been subscribed to, each chapter will be saved as a subscription
  public addTitleSubscription: (
    itemId: any,
    userId: any,
    type: string
  ) => Promise<object> = async (itemId: any, userId: any, type: string) => {
    var titleId = 0;
    var chapterId = 0;

    if (type === "Title") {
      titleId = itemId;
    } else {
      chapterId = itemId;
    }

    var response = await SubscriptionBaseAPI.axios.post<any[]>(
      `${this.apiUrl}/AddTitleSubscription`,
      {
        UserId: userId,
        TitleId: titleId,
        ChapterId: chapterId,
      }
    );

    return response;
  };

  public addUserSubscription: (
    userSubscription: UserSubscription
  ) => Promise<any> = async (userSubscription: UserSubscription) => {
    try {
      var response = await SubscriptionBaseAPI.axios
        .post<UserSubscription>(
          `${this.apiUrl}/AddUserSubscription`,
          userSubscription
        )
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return "error";
        });
      return response;
    } catch (error) {
      return "error";
    }
  };

  // unsubscribe user from subscription
  public unsubscribeItem: (
    id: number,
    userId: any,
    ruleId?: number,
    chapterSegmentId?: number
  ) => Promise<any> = async (
    id: number,
    userId: any,
    ruleId?: number,
    chapterSegmentId?: number
  ) => {
    try {
      var response = (
        await SubscriptionBaseAPI.axios.post<any[]>(
          `${this.apiUrl}/RemoveUserSubscription/`,
          {
            id: id,
            UserId: userId,
            ruleId: ruleId,
            ChapterSegmentId: chapterSegmentId,
          }
        )
      ).data;
      return response;
    } catch (error) {
      return false;
    }
  };

  // remove list of subscriptions
  public unsubscribeItems: (subscriptions: any[]) => Promise<object> = async (
    subscriptions: any[]
  ) => {
    var response = (
      await SubscriptionBaseAPI.axios.post<any[]>(
        `${this.apiUrl}/RemoveUserSubscriptions/`,
        subscriptions
      )
    ).data;

    return response;
  };

  public updateSubscriberEmail: (emailUpdate: any) => Promise<object> = async (
    emailUpdate: any
  ) => {
    try {
      var response = (
        await SubscriptionBaseAPI.axios.post<any>(
          `${this.apiUrl}/api/SubscriptionUser/ChangeSubscriberUserEmailAddress`,
          emailUpdate
        )
      ).data;
      return response;
    } catch (error) {
      return null;
    }
  };

  public changeSubscriberUserPassword: (userInfo: any) => Promise<object> =
    async (userInfo: any) => {
      try {
        var response = (
          await SubscriptionBaseAPI.axios.post<any>(
            `${this.apiUrl}/api/SubscriptionUser/ChangeSubscriberUserPassword`,
            userInfo
          )
        ).data;
        return response;
      } catch (error) {
        return null;
      }
    };
}

export const userSubscriptionAPI = new UserSubscriptionApi();
