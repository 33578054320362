import { ContactType } from "../model/ContactType";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class ContactTypeAPI extends BaseAPI<ContactType> {
  constructor() {
    super("contactTypes", APISettings.adminRulesApiUrl);
  }

  public getAllContactTypes: () => Promise<any> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllContactTypes`)).data;
  };

  public getContactTypeById: (id: number) => Promise<ContactType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetContactTypesById/${id}`))
      .data;
  };

  public createContactType: (contactType: ContactType) => Promise<any> = async (
    contactType: ContactType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateContactType`,
      contactType,
    );
    return response.data;
  };

  public removeContactType: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/RemoveContactType/${id}`
    );
    return response.data;
  };

  public updateContactType: (
    id: number,
    contactType: ContactType
  ) => Promise<ContactType> = async (id: number, contactType: ContactType) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateContactType`,
      contactType
    );
    return response.data;
  };
}

export const contactTypeAPI = new ContactTypeAPI();
