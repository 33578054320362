import React, { SetStateAction, Dispatch } from 'react';
import { Tab } from '../settings/StateOfficeNavigationSettings';

// SetUp Types 
type TabProviderProps = { children: React.ReactNode }

// Setup Contexts for setting(dispatch) and getting(State) our current tab
const TabStateContext = React.createContext<Tab | null>(null);
const TabDispatchContext = React.createContext<Dispatch<SetStateAction<Tab | null>>>(() => { });

const NavigationProvider = ({ children }: TabProviderProps) => {
    // Create state to be used in our context
    // Create a set state method to be used in Dispatch context
    const [state, setState] = React.useState<Tab | null>(null);
    return (
            <TabStateContext.Provider value={state}>
                <TabDispatchContext.Provider value={setState}>
                    {children}
                </TabDispatchContext.Provider>
            </TabStateContext.Provider>
    )
}

const useNavigationState = () => {
    const context = React.useContext(TabStateContext)
    if (context === undefined) {
        throw new Error('useNavigationState must be used within a NavigationProvider');
    }
    return context
}

const useNavigationDispatch = () => {
    const context = React.useContext(TabDispatchContext)
    if (context === undefined) {
        throw new Error('useWorkflowDispatch must be used within a WorkflowProvider');
    }
    return context
}

export { NavigationProvider, useNavigationState, useNavigationDispatch };