import React, { Fragment, useEffect } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI, registriesAPI, segmentHistoryAPI } from "../../../../../utils/api";
import "./RegisterPreviewModal.scss";
import { GridPDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Box, Card, Tab, Tabs } from "@material-ui/core";
import { jsPDF } from "jspdf";
import { FileUpload } from "../../../../../components/FileUpload";
import { RegisterStatuses, StorageContainer } from "../../../../../utils/enums";
import { Info } from "@material-ui/icons";
import { register } from "../../../../../serviceWorker";
import { Registries } from "../../../../../utils/model";

interface IRegisterPreviewModal {
  open: boolean;
  close: () => void;
  volumeNum: number;
  issueNum: number;
  register: Registries;
}

export const RegisterPreviewModal = (props: IRegisterPreviewModal) => {
  const [registerContent, setRegisterContent]:any = React.useState<any>("Loading");

  const onMount = async () => {
    // console.log("on mount hit");
  };

  useEffect(() => {
    if (props.open) {
      onMount();
    }
    return () => {};
  }, [props.open]);

  /**
   * @description - PDF download function
   */
  let container:any = React.useRef<HTMLIFrameElement>();
  const pdfDownload = () => {
    var printWindow:any = window.open(`https://oklahomarules.blob.core.windows.net/temppublicregister/Volume-${props.volumeNum}_Issue-${props.issueNum}.html`);

    // If we need to open the preview endpoint file instead this will be the url.
    // var printWindow:any = window.open("http://oklahoma-tec-registercompile-dev.azurewebsites.net/api/register/preview/491");
  };

  // TODO: This will likely need to be updated to allow for saving of the filename rather than the guid
  /**
   *
   * @description Called after register pdf uploaded into blob storage. This is triggered by the onUpload callback from the file upload component
   * @param guid
   * @param fileName
   */
  const processUploadedDoc = (guid: string, fileName: string) => {
    let updatedRegister = props.register;
    updatedRegister.pdfFileName = fileName;
    updatedRegister.registerStatusId = RegisterStatuses.GenerationComplete;
    registriesAPI.updateRegistries(updatedRegister.id!, updatedRegister);
  }

  // This was an initial attempt to get the .html file to use Kendo savePDF component which ended up not working
  // let data:string;
  // fetch('https://oklahomarules.blob.core.windows.net/temppublicregister/Volume-39_Issue-23.html')
  // .then(res => res.blob())
  // .then(blob => {
  //   blob.text().then(result => setRegisterContent(result));
  //   // blob.text().then(result => (document.getElementById("content")!.innerHTML = result));
  //   // document.getElementById("content")!.innerHTML = res
  //   // setRegisterContent(blob);
  // })


  const modalDetails = () => {
    return (
    <Fragment>
      <div className="register-preview-actions">
        <div className="register-info-text">
          <Info/>After clicking "CREATE PDF", use "ctrl+p" to save as PDF using Google Chrome then upload the document here to store PDF in the system.
        </div>
        <div className="file-upload-box">
          <FileUpload
            showPreview={false}
            allowedFileTypes={["pdf"]}
            fileName={`Volume-${props.volumeNum}_Issue-${props.issueNum}.pdf`}
            storageContainer={StorageContainer.TempPublicRegister}
            onUploaded={processUploadedDoc}
            useName="true"
          />
        </div>
      </div>
      <iframe
        id="registerContent"
        name="registerContent"
        ref={container}
        className="preview-frame"
        src={`https://oklahomarules.blob.core.windows.net/temppublicregister/Volume-${props.volumeNum}_Issue-${props.issueNum}.html`}
      />
    </Fragment>
    );
  };

  const modalActions = () => {
    let value;
    value = <Button onClick={pdfDownload}>Create PDF</Button>;

    return value;
  };

  return (
    <Dialog
      className="add-register-item-modal"
      open={props.open}
      onClose={props.close}
      title={`Preview Register Volume ${props.volumeNum} Issue ${props.issueNum}`}
      children={modalDetails()}
      actions={modalActions()}
    />
  );
};
