import "./MainComponent.scss";
import { useNavigationState } from "../../utils/hooks";
import { NotFound } from "../NotFound";

interface IMainComponentProps {}

export const MainComponent = (props: IMainComponentProps) => {
  const currentTab = useNavigationState();

  if (currentTab) {
    return (
      <div className="main-component">
        <currentTab.component {...currentTab.componentProps} />
      </div>
    );
  } else {
    return <NotFound />;
  }
};
