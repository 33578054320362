import { CommentThread } from ".";
import { VisibilityLevel, CommentStatus } from "../enums";
import { Identifiable } from "./Identifiable";

export class Comment extends Identifiable {
  constructor(
    text: string,
    visibility: VisibilityLevel,
    status: CommentStatus,
    commentThreadId: number,
  ) {
    super(null);
    this.text = text;
    this.visibility = visibility;
    this.status = status;
    this.commentThreadId = commentThreadId;
  }
  public text: string;
  public visibility: VisibilityLevel;
  public status: CommentStatus;
  public commentThreadId: number;
  public commentThread?: CommentThread;
  public created?:Date;
  public createdBy?:string;
  public lastModifiedBy?:string;
  public lastModified?:Date;
}
