import { Contact, ContactType, Rule } from "../../../../../../../utils/model";
import { Owning } from "../../../../../../../utils/model/Owning";
import { GridColumn } from "@progress/kendo-react-grid";


// #######################################################
// Set Initial columns for dynamic column generation
// #######################################################
let columns: any = [];

// #######################################################
// Set Contacts and create columns
// #######################################################
export const setContactRows = (rows: any) => {
  let arr: Contact[] = [];
  for (var i = 0; i < rows.length; i++) {
    var temp;
    temp = rows[i] as Contact;
    temp.id = rows[i].id;
    arr.push(temp);
  }

  if (arr[0] !== undefined) {
    const contact: Contact = new Contact(
      rows[0].firstName,
      rows[0].lastName,
      rows[0].title,
      rows[0].email,
      rows[0].website,
      rows[0].address,
      rows[0].phone,
      rows[0].status,
      rows[0].contactType,
      rows[0].contactType.id,
      rows[0].recordStatus
    );

    columns = [];
    const keys = Object.keys(contact);
    keys.forEach((key) => {
      // Regular expression takes table name and converts formatting
      var result = key.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      const column: any = {};
      column.title = finalResult;
      column.field = key;

      // Dynamically create map of column data
      let lookup: Map<string, string> = new Map<string, string>();
      if (column && contact) {
        if (column.title === "Address") {
          column.render = (contact: any) => contact?.address?.address1;
          // columns.push(column);
        } else if (column.title === "Contact Type") {
          if (contact.contactType) {
            column.render = (contact: any) => contact.contactType?.type;
            // columns.push(column);
          }
        } else if (column.title === "Name") {
          arr.forEach((contact) => {
            lookup.set(contact.firstName, contact.lastName);
          });

          // convert Map to object
          let obj = Array.from(lookup).reduce(
            (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
            {}
          );

          if (key === "name") {
            column.lookup = obj;
          }
        }

        // this hides the title and website columns in the table where they are not needing to be displayed
        if (column.title != "Website" && column.title != "Title") {
          columns.push(column);
        }
      }
    });
  }

  return { arr: arr, columns: columns };
};

// #######################################################
// Set Contact Types and create columns
// #######################################################
export const setContactTypeRows = (rows: any) => {
  let arr: ContactType[] = [];
  for (var i = 0; i < rows.length; i++) {
    var temp;
    temp = rows[i] as ContactType;
    temp.id = rows[i].id;
    arr.push(temp);
  }

  if (arr[0] !== undefined) {
    const contactType: ContactType = new ContactType(
      rows[0].name,
      rows[0].type
    );

    columns = [];
    const keys = Object.keys(contactType);
    keys.forEach((key) => {
      // Regular expression takes table name and converts formatting
      var result = key.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      const column: any = {};
      column.title = finalResult;
      column.field = key;

      // Dynamically create map of column data
      let lookup: Map<string, string> = new Map<string, string>();
      if (column && contactType) {
        if (column.title === "Name") {
          arr.forEach((contactType) => {
            lookup.set(contactType.name, contactType.name);
          });

          // convert Map to object
          let obj = Array.from(lookup).reduce(
            (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
            {}
          );

          if (key === "name") {
            column.lookup = obj;
          }
        }

        if (column.title === "Contact") {
          arr.forEach((contactType) => {
            lookup.set(contactType.name, contactType.type);
          });
        }

        // convert Map to object
        let obj = Array.from(lookup).reduce(
          (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
          {}
        );

        if (key === "contact") {
          column.lookup = obj;
        }
      }

      columns.push(column);
    });
  }

  return { arr: arr, columns: columns };
};

// #######################################################
// Set Owning and create columns
// #######################################################
export const setOwningRows = (rows: any) => {
  let arr: Owning[] = [];
  for (var i = 0; i < rows.length; i++) {
    var temp;
    temp = rows[i] as Owning;
    temp.id = rows[i].id;
    arr.push(temp);
  }

  if (arr[0] !== undefined) {
    const owning: Owning = new Owning(
      rows[0].name,
      rows[0].contact,
      rows[0].status,
      rows[0].recordStatus
    );

    columns = [];
    const keys = Object.keys(owning);
    keys.forEach((key) => {
      // Regular expression takes table name and converts formatting
      var result = key.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      const column: any = {};
      column.title = finalResult;
      column.field = key;

      // Dynamically create map of column data
      let lookup: Map<string, string> = new Map<string, string>();
      if (column && owning) {
        if (column.title === "Contact") {
          column.render = (owning: any) =>
            owning.contact.firstName + " " + owning.contact.lastName;
        }
        if (column.title === "Name") {
          arr.forEach((owning) => {
            lookup.set(owning.name, owning.name);
          });

          // convert Map to object
          let obj = Array.from(lookup).reduce(
            (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
            {}
          );

          if (key === "name") {
            column.lookup = obj;
          }
        }

        if (column.title === "Contact") {
          arr.forEach((owning) => {
            lookup.set(
              owning.contact.firstName,
              owning.contact.firstName.toString()
            );
          });
        }

        // convert Map to object
        let obj = Array.from(lookup).reduce(
          (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
          {}
        );

        if (key === "contact") {
          column.lookup = obj;
        }
      }

      columns.push(column);
    });
  }

  return { arr: arr, columns: columns };
};

// #######################################################
// Set Rules and create columns
// #######################################################
export const setRuleRows = (rows: any) => {
  let arr: Rule[] = [];
  for (var i = 0; i < rows.length; i++) {
    var temp;
    temp = rows[i] as Rule;
    temp.id = rows[i].id;
    arr.push(temp);
  }

  if (arr[0] !== undefined) {
    const rule = {
      id: rows[0].id,
      title: rows[0].title,
      referenceCode: rows[0].titleNum,
      contact: rows[0].contactIds.liaison,
      ruleType: rows[0].ruleType,
    };
    // We shouldn't be showing all values from DB only whats needed
    // const rule: Rule = new Rule(
    //   rows[0].title,
    //   rows[0].titleNum,
    //   rows[0].segment,
    //   rows[0].documents,
    //   rows[0].contact,
    //   rows[0].ruleType,
    //   rows[0].pendingFilings,
    //   rows[0].notes,
    //   rows[0].additionalInformation,
    //   rows[0].ruleStatus,
    //   rows[0].recordStatus
    // );

    columns = [];
    const keys = Object.keys(rule);
    keys.forEach((key) => {
      // Regular expression takes table name and converts formatting
      var result = key.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      const column: any = {};
      column.title = finalResult;
      column.field = key;
      key += rule.id; // used to ensure that even if there are duplicate names that we have a unique key

      // Dynamically create map of column data
      let lookup: Map<string, string> = new Map<string, string>();
      if (column && rule) {
        if (column.title === "Contact") {
          column.render = (rule: any) =>
            rule.contactIds.liaison;
        }
        // if (column.title === "Segment") {
        //   if (rule.ruleType) {
        //     try {
        //       column.render = (rule) => {
        //         if (rule.segment.title !== null) {
        //           rule.segment.title;
        //         } else {
        //           ("");
        //         }
        //       };
        //     } catch (e) {
        //       alert(e);
        //     }
        //   }
        // }
        if (column.title === "title") {
          arr.forEach((rule) => {
            lookup.set(rule.title, rule.title);
          });
        }

        // convert Map to object
        let obj = Array.from(lookup).reduce(
          (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
          {}
        );

        if (key === "title") {
          column.lookup = obj;
        }
      }

      columns.push(column);
    });
  }
  return { arr: arr, columns: columns };
};
