import React, { Fragment, useEffect, useState } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI, fileAPI, registriesAPI, segmentHistoryAPI } from "../../../../../utils/api";
import "./PublicRegisterPreviewModal.scss";
import { GridPDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Box, Card, Tab, Tabs } from "@material-ui/core";
import { jsPDF } from "jspdf";
import { FileUpload } from "../../../../../components/FileUpload";
import { RegisterStatuses, StorageContainer } from "../../../../../utils/enums";
import { Info } from "@material-ui/icons";
import { register } from "../../../../../serviceWorker";
import { Registries } from "../../../../../utils/model";

interface IPublicRegisterPreviewModal {
  open: boolean;
  close: () => void;
  registerName: string;
  htmlFilename: string;
}

export const PublicRegisterPreviewModal = (props: IPublicRegisterPreviewModal) => {
  const [registerContent, setRegisterContent]:any = React.useState<any>("Loading");

  const onMount = async () => {
  };

  useEffect(() => {
    if(props.htmlFilename && props.htmlFilename !== ""){
      var printWindow:any = window.open(`https://oklahomarules.blob.core.windows.net/publicregister/${props.htmlFilename}`);
    }

    if (props.open) {
      onMount();
    }
    return () => {};
  }, [props.open]);

  /**
   * @description - PDF download function
   */
  let container:any = React.useRef<HTMLIFrameElement>();
  const pdfDownload = () => {
    var printWindow:any = window.open(`https://oklahomarules.blob.core.windows.net/publicregister/${props.htmlFilename}`);

    // If we need to open the preview endpoint file instead this will be the url.
    // var printWindow:any = window.open("http://oklahoma-tec-registercompile-dev.azurewebsites.net/api/register/preview/491");
  };

  // TODO: This will likely need to be updated to allow for saving of the filename rather than the guid
  /**
   *
   * @description Called after register pdf uploaded into blob storage. This is triggered by the onUpload callback from the file upload component
   * @param guid
   * @param fileName
   */

  const modalDetails = () => {
    return (
    <Fragment>
      <iframe
        id="registerContent"
        name="registerContent"
        ref={container}
        className="preview-frame"
        src={`https://oklahomarules.blob.core.windows.net/publicregister/${props.htmlFilename}`}
      />
    </Fragment>
    );
  };

  const modalActions = () => {
    let value;
    value = <Button onClick={pdfDownload}>View HTML</Button>;

    return value;
  };

  return (
    null
    // <Dialog
    //   className="add-register-item-modal"
    //   open={props.open}
    //   onClose={props.close}
    //   title={`Preview Register ${props.registerName}`}
    //   children={modalDetails()}
    //   actions={modalActions()}
    // />
  );
};
