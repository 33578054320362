import { AuditableEntity } from "./AuditableEntity";

export class Registries extends AuditableEntity {
  constructor(
    title: string,
    downloadURL: string,
    htmlFileName: string,
    pdfFileName: string,
    publishedOn?: Date,
    published?: boolean,
    approvedOn?: Date | null,
    submissionDeadline?: Date,
    acceptanceDeadline?: Date,
    anticipatedPublishingDate?: Date,
    actualPublishingDate?: Date,
    generatedTempRegisterDate?: Date,
    endingRegisterPageNumber?: number,
    issueNumber?: number,
    volumeNumber?: number,
    registerStatusId?: number,
    sectionNumber?: number,
    appendixNumber?: number,
  ) {
    super();
    this.title = title;
    this.downloadURL = downloadURL;
    this.htmlFileName = htmlFileName;
    this.pdfFileName = pdfFileName;
    if(publishedOn) {
      this.publishedOn = publishedOn;
    } else {
      this.publishedOn = new Date(0);
    }
    if(approvedOn){
      this.approvedOn = approvedOn;
    }
    if(submissionDeadline){
      this.submissionDeadline = submissionDeadline;
    }
    if(acceptanceDeadline){
      this.acceptanceDeadline = acceptanceDeadline;
    }
    if(anticipatedPublishingDate){
      this.anticipatedPublishingDate = anticipatedPublishingDate;
    }
    if(actualPublishingDate){
      this.actualPublishingDate = actualPublishingDate;
    }
    if(generatedTempRegisterDate) {
      this.generatedTempRegisterDate = generatedTempRegisterDate;
    }
    if(endingRegisterPageNumber){
      this.endingRegisterPageNumber = endingRegisterPageNumber;
    }
    if(issueNumber){
      this.issueNumber = issueNumber;
    }
    if(volumeNumber){
      this.volumeNumber = volumeNumber;
    }
    if(registerStatusId){
      
      this.registerStatusId = registerStatusId;
    }
    this.published = published;
    this.appendixNumber = appendixNumber;
    this.sectionNumber = sectionNumber;
  }

  public title: string;
  public downloadURL: string;
  public publishedOn?: Date;
  public published?: boolean;
  public approvedOn?: Date | null;
  public submissionDeadline?: Date;
  public acceptanceDeadline?: Date;
  public anticipatedPublishingDate?: Date;
  public generatedTempRegisterDate?: Date;
  public actualPublishingDate?: Date;
  public endingRegisterPageNumber?: number;
  public issueNumber?: number;
  public volumeNumber?: number;
  public registerStatusId?: number;
  public htmlFileName: string;
  public pdfFileName: string;
  public appendixNumber?: number;
  public sectionNumber?: number;

}