import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { RichTextEditor } from "../../RichTextEditor";
import {
  EditorType,
  StatusRecordStatus,
  StorageContainer,
  SegmentType
} from "../../../utils/enums";
import { RuleDrafting } from "../../../utils/classes/RuleDrafting";
// import "./DraftEditForm.scss";
import { FileUpload } from "../../FileUpload";
import { APISettings } from "../../../utils/settings";
import { DiffCompare } from "../../DiffCompare";
import { Button } from "@material-ui/core";
import { AuthenticationContext } from "../../../utils/authentication/AuthenticationProvider";
import { segmentAPI, SegmentCodificationHistoryAPI as codificationHistoryAPI } from "../../../utils/api";


interface ISegmentEditProps {
  sectionNum: any;
  editSegmentData: any;
  openAlert: (alertType: boolean, response: string) => void;
  reload?: () => void;
  isReadOnly?: boolean;
  type: "EditSegment" | "EditNote" | "CreateNote";
  updateEditedText: (text: any) => void;
}

export const SegmentEditForm = (props: ISegmentEditProps) => {
  const { sectionNum, editSegmentData, reload, openAlert, isReadOnly, type } = props;
  let ruleDrafting = new RuleDrafting();
  const [editFormData, setEditFormData] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [files, setFiles] = useState<any>(props.editSegmentData.images ?? []);
  const [initialText, setInitialText] = useState<string>("");
  const [modifiedText, setModifiedText] = useState<string>("");
  const [statusSelected, setStatusSelected] = React.useState({ value: { text: "[UNDEFINED]", id: 12 }, });
  const [diffView, setDiffView] = React.useState<boolean>(false);
  const [renumberedToValue, setRenumberedToValue] = React.useState<string>(props.editSegmentData.renumberedToSegmentNumber ?? "");
  const [statusList, setStatusList] = React.useState<any[]>(ruleDrafting.agencyStatuses);
  const [segmentType, setSegmentType] = React.useState<string>('');
  const [segmentNumber, setSegmentNumber] = React.useState<string>('');
  const [editSegmentTitle, setEditSegmentTitle] = React.useState<string>('Edit Segment');

  /**
   * @description - sets the RuleDrafting status definition instead of having it defined inline so it can also be accessed outside of this component
   */
  // filter status by user role
  // const status = ruleDrafting.oarStatuses;
  const { getUser } = useContext(AuthenticationContext);

  // get user data from server and validate roles to filter front end items;
  const filterRoles = async () => {
    let statusData = ruleDrafting.agencyStatuses;
    let user = await getUser();

    if (user) {
      user.profile.role.forEach((element: string) => {
        if (
          element == "Office Of Administrative Rules" ||
          element == "Global User Administrator"
        ) {
          statusData = ruleDrafting.oarStatuses;
        }
      });
    }
    setStatusList(statusData);
  };

  /* This is a React Hook that is used to set the state of the EditForm component. */
  useEffect(() => {
    if (editSegmentData !== undefined) {
      filterRoles();
      setEditFormData(editSegmentData);
      setDescription(editSegmentData.description);
      setInitialText(editSegmentData.text);
      setFiles(editSegmentData.images ?? []);
      setRenumberedToValue(editSegmentData.renumberedToSegmentNumber);
      setEditSegmentDetails(editSegmentData);

      // Grab status id and text for drop down list
      if (editSegmentData && editSegmentData.statusName !== null && statusList) {
        setStatusValue(editSegmentData.statusName);
      } else {
        setStatusValue({
          value: { text: statusList[0].text, id: statusList[0].id },
        });
      }
      props.updateEditedText(editFormData);
    } else {
      setEditFormData(0);
    }

    return () => {
      setEditFormData(0);
      setDescription("");
      setInitialText("");
    };
  }, [editSegmentData]);

  /**
   * It sets the status value to the value of the edit prop if it exists, otherwise it sets it to the
   * value of the status prop.
   * @param {any} setValue - The value that will be set to the state.
   */
  const setStatusValue = (setValue: any) => {
    if (editSegmentData) {
      // console.log("editSegmentData", editSegmentData);
      setStatusSelected({
        value: {
          text: editSegmentData.statusName.toUpperCase(),
          id: editSegmentData.segmentStatusId,
        },
      });
      if (editSegmentData.renumberedToSegmentNumber) {
        setRenumberedToValue(editSegmentData.renumberedToSegmentNumber);
      }
    } else {
      setStatusSelected(setValue);
    }
    props.updateEditedText(editFormData);
  };

  /**
   * It updates the draft data with the new values.
   */
  const updateSegment = async () => {
    try {
      if (segmentNumber.trim().length == 0) {
        openAlert(false, `${segmentType} Number is Required`);
        return;
      }
      else if (props.editSegmentData.name !== 'Executive Order' && description.trim().length == 0) {
        openAlert(false, 'Description is Required');
        return;
      }

      switch (editFormData.segmentTypeId) {
        case SegmentType.Title:
          editFormData.titleNum = segmentNumber;
          break;
        case SegmentType.Chapter:
          editFormData.chapterNum = segmentNumber;
          break;
        case SegmentType.ExecutiveOrder:
          // editFormData.execOrderNum = segmentNumber;
          break;
        case SegmentType.Subchapter:
          editFormData.subChapterNum = segmentNumber;
          break;
        case SegmentType.Appendix:
          editFormData.appendixNum = segmentNumber;
          break;
        case SegmentType.Part:
          editFormData.partNum = segmentNumber;
          break;
        case SegmentType.Section_Chapter:
        case SegmentType.Section_Part:
        case SegmentType.Section_Subchapter:
          editFormData.sectionNum = segmentNumber;
          break;
        default:
          break;
      };

      editFormData.description = description;

      if (editFormData.segmentStatusId != statusSelected.value.id) {
        editFormData.segmentStatusId = statusSelected.value.id;
        editFormData.statusName = statusSelected.value.text;
      }

      // TODO: this is only partially fixing the error something else is going on when saving.
      if (editFormData.hasEmergency === null) {
        editFormData.hasEmergency = false;
      }

      var statusText = statusSelected.value.text.toUpperCase();
      if (renumberedToValue != "" && statusText == "RENUMBERED" || statusText == "AMMENDED AND RENUMBERED") {
        editFormData.renumberedToSegmentNumber = renumberedToValue;
      }


      await codificationHistoryAPI.addAsync(editFormData.id);
      await segmentAPI.updateSegment(editFormData.id, editFormData);

      if (openAlert) {
        openAlert(true, "Changes saved successfully");
      }
    } catch (err) {
      if (openAlert) {
        openAlert(false, "Failed to Save the changes");
      }
      console.error(err);
    }
    if (reload) {
      reload();
    }
  };

  /**
   * It takes an event as an argument, and then sets the status property to the value of the
   * event.target.value property
   * @param {DropDownListChangeEvent} event - DropDownListChangeEvent
   */
  const handleChange = (event: DropDownListChangeEvent) => {
    setStatusSelected({ value: event.target.value });
    props.updateEditedText(editFormData);
  };

  /**
   *
   * @description - stores text editor contents in variable
   * @param data - string data from text editor
   */
  const textChange = (data: any) => {
    let formData: any = {};
    // Object assign used because javascript assigns by object reference
    Object.assign(formData, editFormData);
    formData.text = data;
    setEditFormData(formData);
    setModifiedText(formData.text);
    props.updateEditedText(formData);
  };

  /**
   * It takes in a guid and filename and then creates a url to the blob storage.
   * @param {string} guid - The GUID of the file that was uploaded.
   * @param {string} filename - The name of the file that was uploaded.
   */
  const fileUploaded = (guid: string, filename: string) => {
    //https://oklahomarules.blob.core.windows.net/temp/0a228c08-9abd-4b66-90d8-251a2b011bdc.png
    let url = `${APISettings.blobStorageUrl}/temp/${guid}`;
    let fileList = files;
    fileList.push({ guid: guid, filename: filename, url: url });

    // Clear files to reset state so it propgates data to front end on fileList.map(()=>{})
    setFiles([]);
    setFiles(fileList);
    props.updateEditedText(editFormData);
  };

  /**
   * It copies the image url to the clipboard.
   * @param {any} image - The image object that was clicked.
   */
  const copyText = (image: any) => {
    navigator.clipboard.writeText(image.url);
    alert("copied\r" + `${image.url!} \r${image.filename!}`);
  };

  const diffViewToggle = () => {
    diffView ? setDiffView(false) : setDiffView(true);
  };

  const setEditSegmentDetails = (data: any) => {
    let segmentType: string = '';
    let segmentNumber: string = '';

    switch (data.segmentTypeId) {
      case SegmentType.Title:
        segmentType = 'Title';
        segmentNumber = data.titleNum
        break;
      case SegmentType.Chapter:
        segmentType = 'Chapter';
        segmentNumber = data.chapterNum;
        break;
      case SegmentType.ExecutiveOrder:
        segmentType = 'Executive Order';
        segmentNumber = data.execOrderNum;
        break;
      case SegmentType.Subchapter:
        segmentType = 'Subchapter';
        segmentNumber = data.subChapterNum;
        break;
      case SegmentType.Appendix:
        segmentType = 'Appendix';
        segmentNumber = data.appendixNum;
        break;
      case SegmentType.Part:
        segmentType = 'Part';
        segmentNumber = data.partNum;
        break;
      case SegmentType.Section_Chapter:
      case SegmentType.Section_Part:
      case SegmentType.Section_Subchapter:
        segmentType = 'Section';
        segmentNumber = data.sectionNum;
        break;
      default:
        break;
    };

    setSegmentType(segmentType);
    setSegmentNumber(segmentNumber);
    setEditSegmentTitle(`Edit ${segmentType} ${segmentNumber}${data.description === null ? '' : '. '}${data.description !== null ? data.description : ''}`);
  };

  /* Used to render the edit form.
   */
  const _renderEdit = () => {
    return (
      <Card className="edit-card">
        <CardBody className="card-body">
          <CardHeader>
            <h2>{editSegmentTitle}</h2>
          </CardHeader>
          <Input
            name="segmentNumber"
            style={{ width: "100%" }}
            label={`${segmentType} Number`}
            value={segmentNumber}
            onChange={(e: any) => setSegmentNumber(e.value)}
            disabled={props.isReadOnly}
            required={true}
          />
          <Input
            name="description"
            style={{ width: "100%" }}
            label="Description"
            value={description}
            onChange={(e: any) => setDescription(e.value)}
            disabled={props.isReadOnly}
            required={ props.editSegmentData.name !== 'Executive Order'}
          />
          {statusList.length > 1 ? (
            <DropDownList
              style={{ width: "100%" }}
              data={statusList.sort((a, b) => a.text.localeCompare(b.text))}
              textField="text"
              dataItemKey="id"
              value={statusSelected.value}
              onChange={handleChange}
              label="Status"
              className="status-list"
              disabled={props.isReadOnly}
            />
          ) : null}
          {/*
             Note for the magic numbers below
             status id 5 == amended and renumbered
             status id 6 = renumbered
           */}
          {statusSelected.value.id == 5 || statusSelected.value.id == 6 ? (
            <Input
              name="Renumbered To"
              style={{ width: "100%" }}
              label="Renumbered To"
              value={renumberedToValue}
              onChange={(e: any) => setRenumberedToValue(e.value)}
            />
          ) : null}

          {editSegmentData.name == "Appendix" ? (
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!props.isReadOnly ? (
                <FileUpload
                  allowedFileTypes={["image", "pdf"]}
                  storageContainer={StorageContainer.Temp}
                  onUploaded={fileUploaded}
                />
              ) : null}
              <span style={{ flex: 2, marginLeft: "1rem" }}>
                {files.length > 0
                  ? files.map((element: any, key: number) => {
                    return (
                      <div key={key + element.filename}>
                        Image: {element.filename}{" "}
                        <a href="#" onClick={() => copyText(element)}>
                          Copy URL
                        </a>
                      </div>
                    );
                  })
                  : null}
              </span>
            </div>
          ) : null}

          <span style={{ display: "flex", height: "90%" }}>
            <div
              className="draft-editor-texteditor"
              style={{
                height: "100% !important",
                marginTop: ".5rem",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <RichTextEditor
                text={initialText ?? ""}
                editorType={EditorType.segment}
                diffView={diffViewToggle}
                disable={diffView || isReadOnly}
                onTextChange={textChange}
                height={"70vh"}
                width={"100%"}
              />
            </div>
            {diffView && editSegmentData ? (
              <div
                className={`${diffView ? "diff-visible" : "diff-hide"
                  } editor-compare`}
              >
                <div>
                  <Button
                    className="diff-close"
                    variant="contained"
                    onClick={() => setDiffView(false)}
                  >
                    Close
                  </Button>
                </div>
                <div>
                  <DiffCompare
                    text={editSegmentData.text}
                    textCompare={modifiedText ? modifiedText : initialText}
                    editorType={EditorType.segment}
                  />
                </div>
              </div>
            ) : null}
          </span>
        </CardBody>
        <CardActions>
          <span
            className="k-button k-flat k-primary"
            onClick={updateSegment}>
            {`Update ${segmentType}`}
          </span>
        </CardActions>
      </Card>
    );
  };
  /* This is a ternary operator that returns the _renderEdit() function if editFormData is not null,
   otherwise it returns null. */
  return <div>{editFormData ? _renderEdit() : null}</div>;
}