import {
  Chip,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import { CheckBoxFacet } from "./CheckBoxFacet";
import styled from "styled-components";
import "./Facets.scss";

export const Facets = (props: any) => {

  const FacetList = styled(List)({
    // marginTop: '10px !important',
    display: "inline-flex !important",
    gap: "10px"
    // marginLeft: "20%",
    // marginRight: "20%",
  });

  const mapFacetName = (facetName: any) => {
    const capitalizeFirstLetter = (string: any) =>
      string[0] ? `${string[0].toUpperCase()}${string.substring(1)}` : "";
    facetName = facetName.trim();
    facetName = capitalizeFirstLetter(facetName);

    facetName = facetName.replace("_", " ");
    return facetName;
  };

  const addFilter = (name: any, value: any) => {
    // console.log("Filter added: ", name);
    if (name == "Title Number") {
      const newFilters = props.filters.concat({ field: "DisplayName", value: value });
      props.setFilters(newFilters);

    } else {
      const newFilters = props.filters.concat({ field: name, value: value });
      // console.log("New Filters: ", newFilters);
      props.setFilters(newFilters);
    }

  };

  const removeFilter = (filter: any) => {
    const newFilters = props.filters.filter(
      (item: any) => item.value !== filter.value
    );
    props.setFilters(newFilters);
  };

  var facets;
  try {
    let facetList = ["DisplayName", "Chapter_Number", "Appendix_Number"];
    facets = facetList.map((key) => {



      return (
        <CheckBoxFacet
          className="display"
          key={key}
          name={key === "DisplayName" ? "Title Number" : key}
          values={key === "Title Number" ? props.facets["DisplayName"] : props.facets[key]}
          addFilter={addFilter}
          removeFilter={removeFilter}
          mapFacetName={mapFacetName}
          selectedFacets={props.filters.filter(
            (f: { field: string }) => f.field === (key === "Title Number" ? "DisplayName" : key)
          )}
        />
      );
    });
  } catch (error) {
    console.error(error);
  }

  const filters = props.filters.map((filter: any, index: any) => {

    return (
      <li key={index}>
        <Chip
          label={`${mapFacetName(filter.field == "DisplayName" ? "Title Number" : filter.field)}: ${filter.value}`}
          onDelete={() => removeFilter(filter)}
          className="chip"
        />
      </li>
    );
  });

  return (
    <div id="facetPanel" className="box">
      <div className="facetbox">
        {/* <div></div> */}
        <div id="clearFilters">

        </div>
        <ul className="filter-label">{filters}</ul>
        <FacetList className="listitem">{facets}</FacetList>

      </div>
    </div>
  );
};
