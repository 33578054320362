import { useEffect, useState } from "react";
import { subscriptionLoginAPI } from "../../../../utils/api";
import { getOS } from "../../../../utils/UserOS";
import { Menu } from "../../../../components/Menu/Menu";
import { APISettings } from "../../../../../src/utils/settings";
import { NavLink } from "../../../../components/Menu/NavLink";
import Done from "@material-ui/icons/Done";


export const RegisterComplete = () => {
  const [message, setMessage] = useState("Loading");
  const [success, setSuccess] = useState(false);
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  useEffect(() => {
    var fullUrl = window.location.href;
    var fullUrlArray = fullUrl.split("/");
    var activationKey = fullUrlArray[4];

    const getResult = async () => {
      try {
        let res = await subscriptionLoginAPI.activateSubscriptionUser(
          activationKey
        );
        if (res.isSuccess === true){
          setMessage("Thank you for creating a subscription account!");
          setSuccess(true);
        }

      } catch (error: any) {
        setMessage("Your account has already been activated.");
      }
    };
    getResult();
  }, []);

  return (
    <>
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      <div style={{margin: "170px 0em 0em 0em", textAlign: "center"}}>

        <h1>{message}</h1>;
        {success ?
        <div>
          <NavLink to="/subscriptionLogin" icon={Done}>
            Click Here to log in
          </NavLink>
        </div>
        : null}
      </div>
  </>
  )

};
