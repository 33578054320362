export class RegisterBanner {
    constructor() {}

    /**
     * @description Checks if banner should be displayed or not
     * @param selectedIssue
     * @param registerContents
     * @param selectedView
     * @returns
     */
    public checkForBannerMessage = async (selectedIssue: any, registerContents:any, selectedView:string) => {
        let foundUpdates = false;
        if(selectedIssue && selectedView != "issues") {
            let registerIssueDate = selectedIssue.lastModified;
            for(let i = 0; i < registerContents.length; i++) {
              if (registerContents[i].lastModified && registerIssueDate) {
                if(registerContents[i].lastModified > registerIssueDate) {
                  foundUpdates = true;
                }
              }
            }
        }
        return foundUpdates;
    }
}