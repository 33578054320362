import {
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Filing,
  FilingType,
  Rule,
  Draft,
} from "../../../../../../utils/model";
import {
  ContinueRulemakingAction,
} from "../../../../../../utils/model/Filing";
import { Row, Select, TextField } from ".";
import { RULE_PLURAL_NAME } from "../../../../../../utils/settings/RuleTitlePluralType";
import { DraftStatuses } from "../../../../../../utils/enums";
import "./../FilingAddForm.scss";

interface INoticeOfPublicCommentCancellationProps {
  filingType: FilingType;
  filing?: Filing;
  renderRuleDropdown: () => any;
  renderContactDropdown: () => any;
  renderLiaisonDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  attestationList: Contact[];
  liaisonList: Contact[];
  ruleList: Rule[];
  draftList: Draft[];
  saveForm: (item: any, type: string) => void;
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
}

const getInitialState = (props: INoticeOfPublicCommentCancellationProps) => ({
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  rule: props.ruleList[0],
  chapter: props.ruleList[0],
  ruleId: -1,
  chapterId: -1,
  registerPublication: "",
  rulemakingAction: ContinueRulemakingAction.HearingPermanent,
  commentPeriod: "N/A",
  commentText: "",
  commentBeginDate:
    props.filing?.entity?.commentBeginDate != null
      ? new Date(props.filing.entity.commentBeginDate)
      : new Date(),
  commentEndDate:
    props.filing?.entity?.commentEndDate != null
      ? new Date(props.filing.entity.commentEndDate)
      : new Date(),
  publicHearing: "N/A",
  publicHearingDate: "",
  additionalInfo: "",
  liaison: Contact,
  liaisonId: 0,
  segments: [],
  notes: "Notice of Continuation of Public Hearing",
  rootId: props.filing?.id ?? "",
  draftId: null,
  draft: null,
});

export let NoticeOfPublicHearingContinuation = React.forwardRef(
  (props: INoticeOfPublicCommentCancellationProps, ref) => {
    const { filing } = props;
    let data: any = { ...getInitialState(props) };
    if (filing != null) {
      data.ruleId = filing.entity!.ruleId!;
      data.rule = filing.entity!.rule!;
      data.chapter = filing.entity!.chapter!;
      data.chapterId = filing.entity!.chapterId!;
    }

    const [formValues, handleChange] = useFormState({ ...data }, ref);

    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        if (
          !Object.values(ContinueRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            ContinueRulemakingAction.HearingPermanent;
        }
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
      } else if (props.filing) {
        if (
          !Object.values(ContinueRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            ContinueRulemakingAction.HearingPermanent;
        }
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);
    
    const handleDateChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      date: Date | null
    ) => {
      handleChange(event, name, date);
      if (name === "effectiveDate") {
        handleChange(event, "enactingClause", "EFFECTIVE DATE " + date);
      }
    };

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
      }
    };

    const handleDraftSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);
        }
      }
    };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        contact = props.liaisonList.find((i) => i.id === rawValue);

        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
          } else {
            handleChange(event, "attestation", contact);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleCancelSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string
    ) => {
      value === "Yes"
        ? handleChange(event, name, "Yes")
        : handleChange(event, name, "N/A");
    };

    const currId = uuid();

    return (
      <FormProvider
        values={{
          ...formValues,
        }}
        onChange={handleChange}
      >
        <div className="flex-column">
          <Row>
            <Typography variant="h6" className="form-title">
              NOTICE OF PUBLIC HEARING CONTINUATION
            </Typography>
          </Row>
          <TextField
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row>
            <Select
              label={RULE_PLURAL_NAME}
              name="rule"
              value={formValues.ruleId}
              disabled={true}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                disabled={true}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem
                      key={chapterSegment.id!}
                      value={chapterSegment.id!}
                    >
                      {chapterSegment.description!}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>
          <Row>
            <Select
              label="DRAFTS"
              name="draft"
              value={formValues.draftId ?? 0}
              onChange={handleDraftSelectChange}
              disabled={readOnly}
            >
              {props.renderDraftDropdown}
            </Select>
          </Row>
          <Select
            name="rulemakingAction"
            label="RULEMAKING ACTION"
            value={ContinueRulemakingAction.HearingPermanent}
            disabled={true}
          >
            <MenuItem value={ContinueRulemakingAction.HearingPermanent}>
              {ContinueRulemakingAction.HearingPermanent}
            </MenuItem>
          </Select>
          <FormLabel className="effect-checkbox">COMMENT PERIOD</FormLabel>
          <FormGroup className="form-group-checkbox">
            <Select
              name="cancelCommentPeriod"
              label="ARE YOU CANCELLING THE COMMENT PERIOD?"
              value={"Yes"}
              disabled={true}
              onChange={handleCancelSelectChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            <Row>
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">
                  COMMENT PERIOD: START DATE
                </FormLabel>
                <DatePicker
                  name="commentBeginDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.commentBeginDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) => { }}
                  readOnly={true}
                  disabled={true}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">
                  COMMENT PERIOD: END DATE
                </FormLabel>
                <DatePicker
                  name="commentEndDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.commentEndDate)}
                  readOnly={true}
                  disabled={true}
                  onChange={(date: Date, event: React.ChangeEvent<any>) => { }}
                  dateFormat="MMMM d, yyyy"
                />
              </div>
            </Row>
          </FormGroup>
          <TextField
            name="registerPublication"
            label="REGISTER PUBLICATION OF NOTICE"
            placeholder=""
            multiline
            fullWidth
            rows="3"
            disabled={readOnly}
          />
          <TextField
            name="additionalInfo"
            label="ADDITIONAL INFORMATION"
            width="medium"
            disabled={readOnly}
          />
          <Select
            name="liaisonId"
            label="PLEASE SELECT AGENCY LIAISON TO GENERATE TRANSMITTAL SHEET"
            onChange={handleContactSelectChange}
            disabled={readOnly}
          >
            {props.renderLiaisonDropdown}
          </Select>
        </div>
      </FormProvider>
    );
  }
);
