import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import cloneDeep from "lodash/cloneDeep";
import React, { ChangeEvent, useEffect } from "react";
import { workflowAPI } from "../../../../../../../../../utils/api";
import {
  ActionIcon,
  ConfirmDialog,
  EnumSelectInput,
  IconSelector,
} from "./../../../../../../../../../components";
import {
  StatusType,
  VisibilityLevel,
} from "./../../../../../../../../../utils/enums";
import {
  useWorkflowDispatch,
  useWorkflowState,
} from "./../../../../../../../../../utils/hooks";
import { Status } from "./../../../../../../../../../utils/model";
import "./StatusEditDialog.scss";

interface IStatusEditModalProps {
  status: Status;
  index: number;
}


export const StatusEditDialog = (props: IStatusEditModalProps) => {
  const [open, setOpen] = React.useState(false);
  let initalStatus = cloneDeep(props.status);
  const [status, setStatus] = React.useState(initalStatus);
  const workflow = useWorkflowState();
  const setWorkflow = useWorkflowDispatch();
  const { index } = props;

  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  const removeStatus = async () => {
    handleClose();
    await setTimeout(() => {}, 300);
    const operatingWorkflow = cloneDeep(workflow);
    operatingWorkflow.statusCollection.splice(index, 1);
    if (workflow.id && status.id) {
      workflowAPI.removeStatus(workflow.id, status.id);
    }
    setWorkflow(operatingWorkflow);
  };

  const nameUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const operatingStatus = cloneDeep(status);
    operatingStatus.name = value;
    setStatus(operatingStatus);
  };
  const iconUpdate = (icon: string) => {
    const operatingStatus = cloneDeep(status);
    operatingStatus.statusType.iconName = icon;
    setStatus(operatingStatus);
  };
  const descriptionUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const operatingStatus = cloneDeep(status);
    operatingStatus.description = value;
    setStatus(operatingStatus);
  };
  const typeUpdate = (value: number) => {
    const operatingStatus = cloneDeep(status);
    operatingStatus.type = value;
    setStatus(operatingStatus);
  };
  const sortOrderUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const operatingStatus = cloneDeep(status);
    const newSortOrder = Number.parseInt(value);
    operatingStatus.sortOrder = newSortOrder;
    setStatus(operatingStatus);
  };
  const visibilityUpdate = (value: number) => {
    const operatingStatus = cloneDeep(status);
    operatingStatus.visibility = value;
    setStatus(operatingStatus);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // TODO: Implement or delete this function
  // const saveHandler = () => {
  //     // Send off api update
  //     // TODO
  //     // Pass changes up a level *status is the state variable that we are updating as we go.
  //     const operatingWorkflow = cloneDeep(workflow);
  //     operatingWorkflow.statusCollection[index] = status;
  //     setWorkflow(operatingWorkflow);
  //     handleClose();
  // }
  return (
    <div className="status-settings-container">
      <Settings onClick={handleClickOpen} className="status-settings" />
      <Dialog
        fullWidth={true}
        className="status-input-modal"
        open={open}
        scroll="body"
        onClose={handleClose}
      >
        <DialogTitle id="customized-dialog-title">{status.name}</DialogTitle>
        <DialogContent dividers>
          <TextField
            className="input-field"
            size="medium"
            label="name"
            value={status.name}
            onChange={nameUpdate}
          />
          <IconSelector
            className="input-field"
            icon={status.statusType.iconName}
            onSelect={iconUpdate}
          />
          <TextField
            className="input-field"
            multiline
            rows="4"
            label="description"
            value={status.description}
            onChange={descriptionUpdate}
            variant="outlined"
          />
          <EnumSelectInput
            className="input-field"
            title="Status Type"
            enumerable={StatusType}
            value={status.type}
            onChange={typeUpdate}
          />
          <TextField
            className="input-field"
            type="number"
            label="sortOrder"
            value={status.sortOrder}
            onChange={sortOrderUpdate}
          />
          <EnumSelectInput
            className="input-field"
            title="Visibility Level"
            enumerable={VisibilityLevel}
            value={status.visibility}
            onChange={visibilityUpdate}
          />
        </DialogContent>
        <DialogActions>
          <ActionIcon icon="Save" label="Save" color="primary" />
          <ConfirmDialog
            message="Are you sure you want to delete this status?"
            callback={removeStatus}
          >
            <ActionIcon icon="Delete" label="Delete" color="danger" />
          </ConfirmDialog>
        </DialogActions>
      </Dialog>
    </div>
  );
};
