import { SegmentHistoryData } from "../model/SegmentHistoryData";
import { SegmentHistory } from "../model/SegmentHistory";
import { segmentHistoryAPI } from "../api/SegmentHistoryApi";
import { draftAPI } from "../api/DraftApi";
import { Draft, Segment } from "../model";
import { StatusRecordStatus, StorageContainer } from "../enums";
import { fileAPI, segmentAPI } from "../api";
import { useContext } from "react";
import { AuthenticationContext } from "../authentication/AuthenticationProvider";

export class RuleDrafting {
    constructor() {

    }

    public oarStatuses = [
        // {
        //   id: 0,
        //   text: "No Status",
        // },
        {
          id: 1,
          text: "NEW",
        },
        {
          id: 2,
          text: "REVOKED",
        },
        {
          id: 3,
          text: "RESERVED",
        },
        {
          id: 4,
          text: "EXPIRED",
        },
        {
          id: 5,
          text: "AMENDED AND RENUMBERED",
        },
        {
          id: 6,
          text: "RENUMBERED",
        },
        {
          id: 7,
          text: "SUPERSEDED",
        },
        {
          id: 8,
          text: "TERMINATED",
        },
        {
          id: 9,
          text: "TRANSFERRED",
        },
        // {
        //   id: 10,
        //   text: "OTHER",
        // },
        {
          id: 11,
          text: "PUBLISHED",
        },
        {
          id: 12,
          text: "UNDEFINED",
        },
        {
          id: 13,
          text: "AMENDED",
        }
      ];

      public agencyStatuses =  [
        {
          id: 1,
          text: "NEW",
        },
        {
          id: 2,
          text: "REVOKED",
        },
        {
          id: 3,
          text: "RESERVED",
        },
        {
          id: 5,
          text: "AMENDED AND RENUMBERED",
        },
        {
          id: 6,
          text: "RENUMBERED",
        },
        {
          id: 12,
          text: "UNDEFINED",
        },
        {
          id: 13,
          text: "AMENDED",
        }
      ]


    /**
     *
     * @description - Creates new segment history record based off user input from edit form in the drafting tab under my work
     * @param status - status object containing text changes statusId
     * @param segmentData - segment data from segments table
     * @param description - description/name of segment from edit form
     * @param draft - draft object containing text contents from editor
     * @param images - image array of objects
     */
    public CreateSegmentHistory = async (status:any,segmentData:any,description:string,draft:any,images:any,renumberedToSegmentNumber:string) => {
        let data:SegmentHistoryData = new SegmentHistoryData(description,status.value.id,status.value.text,segmentData.text,[],images, renumberedToSegmentNumber);
        let dateValue:Date = new Date();

        let segmentHistoryItem = new SegmentHistory(null, draft.id, data, dateValue, dateValue, true);
        /**
         * Not sure if this is needed or if we just keep adding history items
         * @todo figure out if this how we are going to handle this
         */
        // let existingSegmentHistory = await segmentHistoryAPI.getSegmentHistoryById(segmentData.id);
        delete segmentHistoryItem.id;
        let response = await segmentHistoryAPI.createSegmentHistory(segmentHistoryItem, true);
        // console.log(response);
    }

     /**
     *
     * @description - Creates new segment history record based off user input from edit form in the drafting tab under my work
     * @param status - status object containing text changes statusId
     * @param segmentData - segment data from segments table
     * @param description - description/name of segment from edit form
     * @param draft - draft object containing text contents from editor
     * @param images - image array of objects
     * @param isNewSegment - specify if the new segment history entry is for a newly added segment
     */
    public UpdateSegmentHistory = async (status:any,segmentData:any,description:string,draft:any,images:any,renumberedToSegmentNumber:string) => {
        // console.log("EDIT DATA PASSED IN: ", segmentData, status);
        // console.log("Draft data PASSED IN: ", draft);


        let data:SegmentHistoryData = new SegmentHistoryData(description,status.value.id,status.value.text,segmentData.text,[],images,renumberedToSegmentNumber);
        let dateValue:Date = new Date();
        let id = segmentData.id ?? segmentData.segmentId;
        let segmentHistoryItem = new SegmentHistory(id,draft.id,data,dateValue,dateValue,true);

        // id needs to be deleted so the api doesn't try to do an identity insert
        delete segmentHistoryItem.id;

        try {
          let response = await segmentHistoryAPI.createSegmentHistory(segmentHistoryItem, true);
          return response;
        } catch (error:any) {
          return error.response.data;
        }
    }

    public UpdateEditLockByUserId = async(id: number) => {
      // console.log("User ID to update: ", id);
      let response = await segmentHistoryAPI.updateEditLockByUserId(id);
      // console.log("Update Edit Lock By User ID: ", response);
    }

     /**
     *
     * @description - Creates new segment history record based off user input from edit form in the drafting tab under my work
     * @param status - status object containing text changes statusId
     * @param segmentData - segment data from segments table
     * @param description - description/name of segment from edit form
     * @param draft - draft object containing text contents from editor
     * @param images - image array of objects
     * @param isNewSegment - specify if the new segment history entry is for a newly added segment
     */
          public UpdateSegmentHistoryInRegister = async (status:any,segmentData:any,description:string,draft:any,images:any,renumberedToSegmentNumber:string) => {

            let data:SegmentHistoryData = new SegmentHistoryData(description,status.value.id,status.value.text,segmentData.text,[],images,renumberedToSegmentNumber);

            let dateValue:Date = new Date();
            let id = segmentData.id ?? segmentData.segmentId;
            let segmentHistoryItem = new SegmentHistory(id,draft.id,data,dateValue,dateValue,true);

            // id needs to be deleted so the api doesn't try to do an identity insert
            delete segmentHistoryItem.id;

            try {
              let response = await segmentHistoryAPI.createSegmentHistoryInRegister(segmentHistoryItem);
              return response;
            } catch (error:any) {
              return error.response.data;
            }
        }

    public RevertSegmentHistory = async () => {

    }

    public CreateNewSegment = async () => {

    }

    /**
     * @description - Deletes single segment from draft history to restore back to original status
     * @param segment - segment object
     * @param draft - draft object
     * @param historyData - Array of history data is used to determine the images to be cleaned up
     */
    public DeleteSegmentFromDraft = async (segment:any,draft:any,historyData:any) => {
        // console.log("Deleting draft data: ",segment,draft,historyData);
        historyData.forEach((item:any,i:number)=> {
          // deleting any images for relevent segment
          if(item.segmentId == segment.id) {
            item.segmentData.images.forEach((image:any,j:number) => {
              // console.log("deleting images");
              fileAPI.deleteFile(image.guid,StorageContainer.Temp);
            })
          }
        });

        // deletes all histories for the draft and segment id
        try {

          await segmentHistoryAPI.DeleteSegmentHistories(segment.id, draft.id);
        } catch (error) {
          console.error("Couldnt delete the segment history",error);
        }

        // deletes segment if it was a new segment created for the draft will have a work in progress status
        if(segment.recordStatus == StatusRecordStatus.WIP) {
          try {
            await segmentAPI.deleteSegment(segment.id);
          } catch (error) {
            console.error("Error: Couldn't delete the work in progress segment", error);
          }
        }
    }

}