import {getter} from '@progress/kendo-react-common';


//Minimum ten characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const passwordRegex: RegExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/);
const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);


export const emailValidator = (value: string) => !value
    ? "Email field is required."
    : (emailRegex.test(value)
        ? ""
        : "Email is not in a valid format.");

export const passwordValidator = (value: string) => !value
    ? "Password is required"
    : (passwordRegex.test(value)
        ? ""
        : "Password is not in a valid format.");

export const confirmPasswordValidator = (value: string) => !value
    ? "Password is required"
    : (passwordRegex.test(value)
        ? ""
        : "Passwords do not match");

export const requiredValidator = (value: string) => value
    ? ""
    : "Error: This field is required.";


const emailGetter = getter('email');
const passwordGetter = getter('password');


export const formValidator = (values: any) => {
    const emailValue = emailGetter(values)
    const passwordValue = passwordGetter(values);

    if (
        passwordValue && passwordRegex.test(passwordValue) &&
        emailValue && emailRegex.test(emailValue)) {
        return {};
    }

    return {
        VALIDATION_SUMMARY: 'Please fill in the following fields.',
        'email': emailValue && emailRegex.test(emailValue) ? '' : 'Email is required and should be in a valid format.',
        'password': !passwordValue && passwordRegex.test(passwordValue) ? '' : 'User Name is required.',
    };
};