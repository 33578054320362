import { BaseAPI } from "./internal";
import { RuleHistory } from "../model/RuleHistory";
import { APISettings } from "../settings/APISettings";

class RuleHistoryAPI extends BaseAPI<RuleHistory> {
  constructor() {
    super("ruleHistory", APISettings.adminRulesApiUrl);
  }

  public getAllRuleHistories: () => Promise<RuleHistory[]> = async () => {
    const url = `${this.apiUrl}/GetAllRuleHistories`;
    const response = await BaseAPI.axios.get(url);
    return response.data;
  };

  public getRuleHistoriesByRuleId: (id: number) => Promise<RuleHistory[]> =
    async (id: number) => {
      try {
        return (
          await BaseAPI.axios.get(
            `${this.apiUrl}/GetRuleHistoriesByRuleId?ruleId=${id}`
          )
        ).data;
      } catch (error) {
        return null;
      }
    };
}

export const ruleHistoryAPI = new RuleHistoryAPI();
