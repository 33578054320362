import React, { useEffect, useMemo } from "react";
import "./OwningModal.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import { Owning } from "../../../../../../../utils/model/Owning";
import {
  StatusRecordStatus,
  StatusType,
} from "../../../../../../../utils/enums";
import { owningAPI } from "../../../../../../../utils/api";
import { Select, MenuItem, InputLabel, Divider } from "@material-ui/core";
import { contactAPI } from "../../../../../../../utils/api";
import { Contact } from "../../../../../../../utils/model/Contact";
import { ContactType, Address } from "../../../../../../../utils/model";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

interface IOwningModalProps {
  open: boolean;
  editRow?: any;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}

export const OwningModal = (props: IOwningModalProps) => {
  const { open, handleClickOpen, handleClose } = props;
  const initialContacts: Contact[] = [];
  const [contacts, setContacts] = React.useState(initialContacts);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const contactType = useMemo(() => new ContactType("", "None"), []);
  const address = useMemo(() => new Address("", "", "", "", "", "", 0), []);
  const initialContact: Contact = useMemo(
    () =>
      new Contact(
        "None",
        "",
        "",
        "",
        "",
        address,
        "",
        StatusType.Approval,
        contactType,
        -1,
        StatusRecordStatus.Active
      ),
    [address, contactType]
  );
  const [contact, setContact] = React.useState(initialContact);
  const [editId, setEditId] = React.useState(-1);

  // #######################################################
  // Generates Menu Items dynamically
  // #######################################################
  function _renderDropdown() {
    let menuItems: any = [];
    contacts.forEach((id) => {
      if (id.id != null) {
        menuItems.push(
          <MenuItem key={id.id} value={id.id}>
            {id.firstName + " " + id.lastName}
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  // #######################################################
  // Generate Items in Dropdown menu
  // #######################################################
  function generateDropdown(response: any) {
    let val: any = [];
    response.forEach((values: any) => {
      val.push(values);
    });
    setContacts(val);
  }

  // #######################################################
  // Runs once on page load to fetch contacts
  // #######################################################
  useEffect(() => {
    try {
      contactAPI
        .getAllContacts()
        .then((response) => generateDropdown(response));
    } catch (err) {
      console.error("FAILED TO GET CONTACTS", err);
    }
  }, []);

  useEffect(() => {
    if (props.open === false) {
      setName("");
      setContact(initialContact);
      setEditId(-1);
    } else if (props.editRow) {
      setName(props.editRow.name);
      if (props.editRow.id) {
        setContact(props.editRow.contact);
        setEditId(props.editRow.id);
      }
    }
  }, [initialContact, props.editRow, props.handleClickOpen, props.open]);

  const saveForm = async (event: any) => {
    if (editId !== -1) {
      const owning: Owning = new Owning(
        name,
        contact!,
        StatusType.Approval,
        StatusRecordStatus.Active
      );
      owning.id = editId;

      try {
        //update existing item
        await owningAPI.update(editId, owning);
        setSnackOpen(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      // create new item
      // TODO: Figure out why new Owning() here deletes the name but not in the edit row save
      let nameVal = name;
      const owning: Owning = new Owning(
        name,
        contact!,
        StatusType.Approval,
        StatusRecordStatus.Active
      );
      owning.name = nameVal;
      console.error(owning);
      delete owning.id;
      try {
        await owningAPI.create(owning);
      } catch (err) {
        console.error(err);
      }
    }
    handleClose(event);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    console.error(name);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // setContact(value);
    if (contacts != null) {
      let val = contacts.find((i) => i.id === event.target.value);
      console.error(val);
      if (val) {
        setContact(val);
      }
    }
    // }
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  return (
    <div onClick={handleClickOpen}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Save Successful</Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Owning
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            fullWidth
          />
          <InputLabel id="label" variant="standard">
            Contacts
          </InputLabel>
          <Select
            labelId="label"
            id="select"
            defaultValue="0"
            value={contact.id}
            onChange={handleSelectChange}
            fullWidth
          >
            {_renderDropdown()}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(event) => {
              saveForm(event);
              handleClose(event);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
