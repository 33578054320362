import React, { Fragment, useEffect } from "react";
import { segmentAPI, ruleAPI, segmentTypeAPI, codificationAPI } from "../../../../../utils/api";
import { AdminEditor, Authorized, DropDownSelect } from "../../../../../components";
import "./CurrentRulesWrapper.scss";
import { CodeHierarchy } from "../../../../../components/CodeHierarchy";
import { SegmentType } from "../../../../../utils/model";
import { CurrentRulesEditPage } from "../CurrentRulesEditPage";
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { CurrentRulesPreviewModal } from "../CurrentRulesPreview";
import { CurrentRulesModal } from "../CurrentRulesModal";
import { Alert } from "@material-ui/lab";
import { ExecOrderCodeHierarchy } from "../../../../../components/ExecOrderCodeHierarchy";

interface ICurrentRulesWrapperProps {

};

export const CurrentRulesWrapper = (props: ICurrentRulesWrapperProps) => {
  const [titleNames, setTitleNames]: any = React.useState([]);
  const [segment, setSegment]: any = React.useState();
  const [segmentTypes, setSegmentTypes] = React.useState<SegmentType[]>();
  const [itemToEdit, setItemToEdit] = React.useState<any>();
  const [preview, setPreview] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [editType, setEditType] = React.useState<"EditSegment" | "EditNote" | "CreateNote">("EditSegment");
  const [note, setNote] = React.useState<any>();
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertType, setAlertType] = React.useState<boolean>(false);
  const [state, setState] = React.useState({
    value: {
      text: "",
      id: 0,
    },
  });

  useEffect(() => {
    onMount();
  }, [props])

  // Sets the initial values on load
  const onMount = async () => {
    let titleNamesWithId = await ruleAPI.getAllRules({ pageNumber: 1, pageSize: 500 }, true);
    setTitleNames(await setListItems(titleNamesWithId));

    let ruleText = titleNamesWithId[0].referenceCode + '. ' + titleNamesWithId[0].title;
    let segmentId = titleNamesWithId[0].segmentId;
    let defaultSegment: any = await segmentAPI.getSegmentById(titleNamesWithId[0].segmentId!);
    let segmentTypes = await segmentTypeAPI.getAllSegmentTypes();

    setState({
      value: {
        text: ruleText,
        id: segmentId
      }
    });
    setSegment(defaultSegment[0]);
    setSegmentTypes(segmentTypes);
  }

  const setListItems = async (data: any) => {
    let listItems = [];
    for (let i = 0; i < data.length; i++) {
      listItems.push({ text: `${data[i].referenceCode}. ${data[i].title}`, id: data[i].segmentId });
    }
    return listItems;
  }

  // Sets selected segment id in state so it can be passed to the hierarchy triggering a refresh
  const updateHierarchy = async (id: number) => {
    let newSegment: any = await segmentAPI.getSegmentById(id);
    setSegment(newSegment[0]);
  }

  const reloadHierarchy = async () => {
    let newSegment: any = await segmentAPI.getSegmentById(segment.id);
    setSegment(newSegment[0]);
  }

  // Change even on dropdown title select
  const handleChange = (event: any) => {
    setState({
      value: event.target.value,
    });
    updateHierarchy(event.target.value.id);
  };

  // generate title documents
  const generateTitlePdf = async () => {
    let title: any = await ruleAPI.getRuleReferenceCode(segment.titleNum)
    if (title) {
      try {
        openAlert(true, "PDF Generation may take some time. Please remain on this page");
        await codificationAPI.generateTitleDocument(title.id);
        openAlert(true, "Generation successful");
      } catch (error: any) {
        openAlert(false, `PDF Generation Failed ${error.message}`);
      }
    } else {
      openAlert(false, `Could not find title for ${segment.titleNum}`);
    }
  }

  // function to launch editor
  const openEditor = (data: any, segmentData: any) => {
    // setIsEdit(true);
    setItemToEdit(data);
    setEditType("EditSegment")
    toggleModal();
  }

  const closeEditor = () => {
    toggleModal();
    setItemToEdit(null);
    updateHierarchy(state.value.id);
  }



  const togglePreview = () => {
    preview ? setPreview(false) : setPreview(true);
  }

  const openNoteEditor = (note: any, create: boolean) => {
    if (create) {
      setEditType("CreateNote")
      setNote(note);
    } else {
      setEditType("EditNote");
      setNote(note);
    }
    toggleModal();
  }

  const toggleModal = () => {
    isModalOpen ? setIsModalOpen(false) : setIsModalOpen(true);
  }

  const openAlert = (alertType: boolean, message: string) => {
    setAlertType(alertType)
    setAlertMessage(message);
    toggleAlert();
  }

  const toggleAlert = () => {
    alertOpen ? setAlertOpen(false) : setAlertOpen(true)
  }

  const _renderModal = () => {
    let JSXElement: JSX.Element;
    JSXElement = (
      <AdminEditor
        editData={itemToEdit ?? null}
        sectionNum={itemToEdit ? itemToEdit.sectionNum ? itemToEdit.sectionNum : null : null}
        note={note ?? null}
        reload={closeEditor}
        type={editType}
        openAlert={openAlert}
      />
    );
    return JSXElement;
  }

  return (
    <div className="current-rules-wrapper">
      {isModalOpen ? (
        <CurrentRulesModal
          note={note}
          type={editType}
          open={isModalOpen}
          close={toggleModal}
        >
          {_renderModal()}
        </CurrentRulesModal>
      ) : null
      }
      <Fragment>
        <Snackbar
          open={alertOpen}
          autoHideDuration={4000}
          onClose={toggleAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={alertType ? "success" : "error"}>{alertMessage}</Alert>
        </Snackbar>
        <CurrentRulesPreviewModal open={preview} close={togglePreview} titleData={segment} />
        <DropDownSelect
          label="Select Title:"
          data={titleNames}
          className={"title-list"}
          onChange={handleChange}
          value={state.value}
        />
        <div className="header-bar">
          <Button className="buttons-container-button" icon="pdf" onClick={togglePreview}>View Full Title</Button>
          <Authorized roles={["Office of Administrative Rules", "Global User Administrator"]}>
            <Button className="pdf-create-button" icon="add" onClick={generateTitlePdf}>Generate Public Title PDF</Button>
          </Authorized>
        </div>
        {segmentTypes && segment && segment.description != "Executive Orders" ? (
          <CodeHierarchy
            expandAll={false}
            segmentData={segment}
            segmentType={segmentTypes}
            editStatus={0}
            editSection={openEditor}
            addSegment={(type: string, event: any, segmentData: any) => { }}
            reloadHierarchy={reloadHierarchy}
            openNoteEditor={openNoteEditor}
            showWIP={false}
          />
        ) : segmentTypes && segment && segment.description == "Executive Orders" ? (
          <ExecOrderCodeHierarchy
            expandAll={false}
            segmentData={segment}
            segmentType={segmentTypes}
            editStatus={0}
            editSection={openEditor}
            addSegment={(type: string, event: any, segmentData: any) => { }}
            reloadHierarchy={reloadHierarchy}
            openNoteEditor={openNoteEditor}
          />
        ) : (
          <div><span>Loading</span><LinearProgress /></div>
        )}
      </Fragment>
    </div>
  );
};
