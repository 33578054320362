import { List } from "@material-ui/core";
import React, { useEffect } from "react";
import { filingStatusHistoryAPI } from "../../utils/api/FilingStatusHistoryApi";
import { FilingStatusHistory as FilingStatusHistoryType } from "../../utils/model/FilingStatusHistory";
import { History } from "./components";
import "./FilingStatusHistory.scss";

interface ICommentThreadProps {
  id: number;
}

export const FilingStatusHistory = (props: ICommentThreadProps) => {
  const [historyArray, setHistoryArray] = React.useState<FilingStatusHistoryType[]>([]);

  const onMount = React.useCallback(async () => {
    if (props.id) {
      let historyList: FilingStatusHistoryType[];
          historyList = await filingStatusHistoryAPI.GetFilingStatusHistoryByFilingId(props.id);
          setHistoryArray(historyList);
    }
  }, [props]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className="history-thread">
      <List className="history-list">
        {historyArray.map((history, key) => <History key={key} history={history}/>)}
      </List>
    </div>
  );
};
