import { Post } from "../model/Post";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
// import axios, { AxiosStatic } from "axios";
// import { AuthenticationSettings } from "../settings/AuthenticationSettings";

class PostAPI extends BaseAPI<Post> {
  constructor() {
    super("GetAllPosts", APISettings.adminRulesApiUrl);
  }

  public getPostById: (id: number) => Promise<Post> = async (id: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetPostById/${id}`)).data;
  };

  public getAllPosts: () => Promise<Post[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllPosts`)).data;
  };

  public createPost: (post: Post) => Promise<any> = async (post: Post) => {
    delete post.id;
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreatePost`,
      post
    );
    return response.data;
  };

  public deletePost: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeletePost/${id}`
    );
    return response.data;
  };

  public updatePost: (post: Post) => Promise<Post> = async (post: Post) => {
    post.lastModified = new Date();
    post.lastModifiedBy = "";
    const response = await BaseAPI.axios.put(`${this.apiUrl}/UpdatePost`, post);
    return response.data;
  };
}

export const PostsAPI = new PostAPI();
