import { useEffect, useState } from "react";
import { codeFullEditionAPI } from "../../../../../utils/api/CodeFullEditionApi";
import { CodeFullEdition } from "../../../../../utils/model";
import { fileAPI, ruleAPI } from "../../../../../utils/api";
import saveAs from "file-saver";
import { Color } from "@material-ui/lab/Alert";

export const useCodeFullEditionController = () => {
  const [codeFullEditionVolumes, setCodeFullEditionVolumes] = useState<any[]>(
    []
  );
  const [codeFullEditionIssues, setCodeFullEditionIssues] = useState<
    CodeFullEdition[] | null
  >(null);
  const [selectedVolume, setSelectedVolume] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [titles, setTitles] = useState<any[]>([]);
  const [snackMessage, setSnackMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<Color | undefined>(undefined);

  useEffect(() => {
    const getCodeFullEditionVolumes = async () => {
      let volumes = await codeFullEditionAPI.getVolumeList();
      if (volumes.length > 0) {
        setCodeFullEditionVolumes(volumes);
        setSelectedVolume(volumes[0].volumeNumber);
        getIssuesByVolumeNumber(volumes[0].volumeNumber ?? -1);
      } else {
        setLoading(false);
      }
    };

    const getTitleList = async () => {
      let titleList = await ruleAPI.getRulesList();
      setTitles(titleList);
    };

    getTitleList();
    getCodeFullEditionVolumes();
  }, []);

  function getFirstTwoDigitsOfYear() {
    let date = new Date();
    let year = date.getFullYear();
    let firstTwoDigits = year.toString().substring(0,2);
    return firstTwoDigits;
  }

  const selectVolume = (volume: any) => {
    setSelectedVolume(volume.target.value);
    getIssuesByVolumeNumber(volume.target.value);
  };

  const getIssuesByVolumeNumber = async (volume: number) => {
    let issues: CodeFullEdition[] | null = null;
    setLoading(true);
    issues = await codeFullEditionAPI.getCodeFullEditionByVolumeNumber(volume);
    setCodeFullEditionIssues(issues);
    setLoading(false);
  };

  const getTitleName = (referenceCode: string) => {
    let index = titles.findIndex((x) => x.referenceCode == referenceCode);
    if (index != -1) {
      return titles[index].title;
    } else {
      return "";
    }
  };

  const scheduleGeneration = async () => {
    try {
      let result = await codeFullEditionAPI.generateCodeFullEditionJob();
      // console.log(result);
      if (result === true) {
        setAlertType("success");
        setSnackMessage("Code Full Edition job scheduled successfully");
      } else {
        setAlertType("error");
        setSnackMessage(
          "Failed to schedule code full edition job, A scheduled job may already exist."
        );
      }
    } catch (error) {
      setAlertType("error");
      setSnackMessage(
        "Failed to schedule code full edition job, A scheduled job may already exist."
      );
    }
  };

  //  Download file(s)
  const downloadFile = async (volume: any) => {
    let url = `https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=full-code-reports&name=Volume-${volume.volumeNumber}_Issue-${volume.issueNumber}.pdf`;
    let blob = await fetch(url).then((r) => r.blob());
    saveAs(
      blob,
      `Volume-${volume.volumeNumber}_Issue-${volume.issueNumber}.pdf`
    );
  };

  const downloadZipDoc = async () => {
    if (selectedVolume) {
      // console.log("download selected file", selectedVolume);
    }
  };

  const handleSnackClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      // disable click away closures of the browser explorer page.
      return;
    }
    setSnackMessage(null);
    setAlertType(undefined);
  };

  return {
    // functions
    getFirstTwoDigitsOfYear,
    // getCodeFullEditionVolumes,
    getTitleName,
    scheduleGeneration,
    selectVolume,
    downloadFile,
    downloadZipDoc,
    snackMessage,
    alertType,
    // variable
    loading,
    selectedVolume,
    codeFullEditionIssues,
    codeFullEditionVolumes,
    handleSnackClose,
  };
};
