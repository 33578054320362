import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Col, Row } from "antd";

import "./Result.scss";
import Divider from "@material-ui/core/Divider/Divider";
import { Paragraph } from '../../../../../components/Paragraph';
import { useEffect, useState } from "react";
import { fileAPI } from "../../../../../utils/api";
import { StorageContainer } from "../../../../../utils/enums";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SelectField from "material-ui/SelectField";

export const Result = (props: any) => {
  const [blob, setBlob] = useState<any>();
  const [mimeType, setMimeType] = useState<string>();

  const setFile = async() => {
    // console.log(props.document.metadata_storage_name);
    let url: any = await fileAPI.getFileUrl(
      props.document.metadata_storage_name,
      StorageContainer.PublicRegister
    );


    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);

    if(blob.type == "application/pdf") {
      setMimeType("application/pdf");
      setBlob(fileURL);
    } else {
      setMimeType("text/html");
      setBlob(fileURL)
    }
  }

  useEffect(() => {
    setFile();
  }, [props])

  const onClick = async (value:string) => {
    // console.log(value);
    let url: any = await fileAPI.getFileUrl(
      value,
      StorageContainer.PublicRegister
    );


    let blob: Blob = await fetch(url).then((r) => r.blob());
    let fileURL = URL.createObjectURL(blob);

    // saveAs(blob, value);
    window.open(fileURL);
  };


  return (
    <Card className="result-register">
      <CardContent>
        <CardActions>
        <Col className="title-spacing">
          <Typography className="result-header-item">
            <strong>Register: </strong>
            <span className="result-header-value">
              <a href="#" onClick={() => onClick(props.document.metadata_storage_name)}>
                Download {props.document.metadata_storage_name}
              </a>
            <PictureAsPdfIcon/>
          </span>
          </Typography>
          </Col>

        </CardActions>
        <Divider />
        <div className="text-contents">
        {/* <Paragraph value={props.document.content} className="segment-text"/> */}
        <iframe src={blob} data-type={mimeType} className="text-contents"/>
        </div>
      </CardContent>
    </Card>
  );
};
