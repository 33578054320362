import { SubscriptionUser } from "../../model/SubscriptionUser";
import { APISettings } from "../../settings/APISettings";
import SubscriptionAuthHeader from "../../../pages/Subscription/Helpers/SubscriptionAuthHeader";
import { SubscriptionUserLogin } from "../../model/subscription/SubscriptionUserLogin";
import { SubscriptionBaseAPI } from "../SubscriptionBaseApi";

class SubscriptionLoginApi extends SubscriptionBaseAPI<SubscriptionUser> {
  constructor() {
    super("subscriptionLogin", APISettings.adminRulesApiUrl);
  }

  // Verify user email exists
  public verifySubscriptionUserEmail: (
    email: string
  ) => Promise<SubscriptionUser> = async (email: string) => {
    return (
      await SubscriptionBaseAPI.axios.get(
        `${this.apiUrl}/api/SubscriptionUser/GetUserCredentials/${email}`
      )
    ).data;
  };

  // Submit user registration to API
  public registerSubscriptionUser: (
    userEmail: any,
    userPassword: any
  ) => Promise<SubscriptionUser> = async (
    userEmail: any,
    userPassword: any
  ) => {
    const response = (
      await SubscriptionBaseAPI.axios.post(
        `${this.apiUrl}/api/SubscriptionUser/`,
        {
          userEmail: userEmail,
          password: userPassword,
          newPassword: userPassword,
        }
      )
    ).data;

    return response;
  };

  // initiate the password reset process
  public initiatePasswordReset: (email: string) => Promise<any> = async (
    email: string
  ) => {
    const response = await SubscriptionBaseAPI.axios.post<boolean>(
      `${this.apiUrl}/api/SubscriptionUser/InitiatePasswordReset?emailAddress=${email}`
    );
    return response.data;
  };

  // reset the user's password
  public resetSubscriberUserPassword: (
    token: string,
    user: SubscriptionUserLogin
  ) => Promise<any> = async (token: string, user: SubscriptionUserLogin) => {
    try {
      const response = await SubscriptionBaseAPI.axios.post<boolean>(
        `${this.apiUrl}/api/SubscriptionUser/ResetSubscriberUserPassword/${token}`,
        user
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // Log user in with submitted credentials
  public loginSubscriptionUser: (credentials: any) => Promise<boolean> = async (
    credentials: any
  ) => {
    // log user in
    try {
      const response = (
        await SubscriptionBaseAPI.axios.post(
          `${this.apiUrl}/api/SubscriptionUser/Login/`,
          credentials
        )
      ).data;
      // if token returned from api set user data in session storage and get userId by email
      if (response.message != null) {
        let userId = (
          await SubscriptionBaseAPI.axios.get(
            `${this.apiUrl}/api/SubscriptionUser/getUserIdByEmail/${credentials.userEmail}`
          )
        ).data;

        const userData = {
          user: {
            email: credentials.userEmail,
            userId: userId,
            token: response.message,
          },
        };

        sessionStorage.setItem("subscriptionUser", JSON.stringify(userData));
      }

      return response;
    } catch (error) {
      return null;
    }
  };

  public getUserId: (emailAddress: string) => Promise<number> = async (
    emailAddress: string
  ) => {
    const response = (
      await SubscriptionBaseAPI.axios.get(
        this.apiUrl + `/api/SubscriptionUser/GetUserIdByEmail/${emailAddress}`
      )
    ).data;
    return response;
  };

  // Activate subscription user
  public activateSubscriptionUser: (activationKey: string) => Promise<any> =
    async (activationKey: string) => {
      var response = await SubscriptionBaseAPI.axios.post<any>(
        this.apiUrl + `/api/SubscriptionUser/Activate/${activationKey}`
      );

      return response.data;
    };

  // Log user out
  public logout = () => {
    localStorage.removeItem("user");
  };

  // TODO: I think this can be removed
  // Get current user
  public getCurrentUser = () => {
    return SubscriptionBaseAPI.axios.get(this.apiUrl + `/getSubscriptionUser`, {
      headers: SubscriptionAuthHeader(),
    });
  };
}

export const subscriptionLoginAPI = new SubscriptionLoginApi();
