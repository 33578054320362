import React, { useEffect } from "react";
import { EditorType } from "../../utils/enums";
import parse from "html-react-parser";
import "./DiffCompare.scss";

interface IDiffCompareProps {
  text: string;
  textCompare: string;
  editorType: EditorType;
}

export const DiffCompare = (props: IDiffCompareProps) => {
  // set default font values based on EditorType
  const [html, setHTML]: any = React.useState(<h1>Loading</h1>);

  useEffect(() => {
    updateText();
  }, [props.textCompare]);

  // Rip and tear this stuff out of here Needs to be in a different view
  function updateText() {
    let diff = require("../../../node_modules/htmldiff/src/htmldiff");
    // convert props.text string value into HTML object
    var tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = props.text;

    var tmpCompare = document.implementation.createHTMLDocument();
    tmpCompare.body.innerHTML = props.textCompare;

    let output = diff(tmp.body.innerHTML, tmpCompare.body.innerHTML);

    // var parse = require("html-react-parser");
    output = parse(output);

    setHTML(output);
  }

  return <div id="diff-compare">{html}</div>;
};
