import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useReportFormController } from "./ReportFormController";
import { Button, CircularProgress } from "@material-ui/core";

interface IAgencyUserReport {
  selectedReport: string;
}

const ActiveAgencyUser = (props: IAgencyUserReport) => {
  const { selectedReport } = props;
  const {
    formValues,
    titles,
    chapters,
    ruleTypes,
    loading,
    // functions
    updateFormValues,
    runReport,
  } = useReportFormController();

  return (
    <div className="report-form">
      <div className="input-description">Select a Title</div>
      <DropDownList
        data={titles}
        value={formValues.rule}
        textField="title"
        dataItemKey="id"
        onChange={(event) =>
          updateFormValues({ ...formValues, rule: event.target.value })
        }
      // defaultItem={{ title: "Any", id: 0 }}
      />

      <div className="report-button-group">
        {loading
          ? (
            <div className='loading-report-text'>
              <span>
                Loading
              </span>
              <CircularProgress color="primary" size={20} />
            </div>
          )
          : (
            <>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "csv")}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "pdf")}>
                Download PDF
              </Button>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ActiveAgencyUser;
