import React from "react";
import './NoAction.scss';

interface INoActionProps {

}

export const NoAction = (props: INoActionProps) => {

    return (
        <div className="no-action">
            
        </div>
    );
}



