import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class RegisterGenerationAPI extends BaseAPI<any> {
  constructor() {
    super("any", APISettings.adminRulesApiUrl);
  }
 
  public finalize: (id: number, volumeNumber: number, issueNumber: number) => Promise<any> = async (
    id: number,
    volumeNumber: number,
    issueNumber: number) => {
        var response = await BaseAPI.axios.get(`${this.apiUrl}/FinalizeGenerate/${id}/${volumeNumber}/${issueNumber}`);
        return response.data;
  };

  public generate: (id: number, volumeNumber: number, issueNumber: number) => Promise<any> = async (
    id: number,
    volumeNumber: number,
    issueNumber: number) => {
      console.warn("Web-client generate", {id, volumeNumber, issueNumber});
        var response = await BaseAPI.axios.get(`${this.apiUrl}/Generate/${id}/${volumeNumber}/${issueNumber}`);
        return response.data;
  };

  public preview: (id: number) => Promise<any> = async (
    id: number) => {
        var response = await BaseAPI.axios.get(`${this.apiUrl}/api/register/preview/${id}`);
        return response.data;
  };
}

export const registerGenerationAPI = new RegisterGenerationAPI();