import { useEffect, useState } from "react";
import { Menu } from "../../../components/Menu";
import { getOS } from "../../../utils/UserOS";
import "./CodeSearch.scss";
import { SearchWrapper } from "./components";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation, useHistory } from "react-router-dom";

import Results from "../CodeSearch/components/Results/Results";
import { Pager } from "../CodeSearch/components/Pager/Pager";

import { searchFuncAPI, segmentAPI } from "../../../utils/api";


export const CodeSearch = () => {
    let location = useLocation();
    let history = useHistory();

    const os = getOS();
    const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

    const [results, setResults] = useState<any>([]);
    const [resultCount, setResultCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [q, setQ] = useState(
        new URLSearchParams(location.search).get("q") ?? ""
    );

    const [top] = useState(new URLSearchParams(location.search).get("top") ?? 8);
    const [skip, setSkip] = useState(
        new URLSearchParams(location.search).get("skip") ?? 20
    );
    const [filters, setFilters] = useState<any>(
        new URLSearchParams(location.search).get("filter") == "true"
            ? [{ field: "IncludeInDraft", value: true }]
            : []);
    const [facets, setFacets] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const [emergencyChecked, setEmergencyChecked] = useState(
        new URLSearchParams(location.search).get("filter") == "true" ? true : false);
    const [filterTerm, setFilterTerm] = useState("");
    const [queryBody, setQueryBody] = useState({
        q: q,
        top: top,
        skip: skip,
        filters: filters,
        highlight: [q]
    });

    // const getBoolValueForChecked = () => {
    //     let checkedState = new URLSearchParams(location.search).get("filter");
    //     if(checkedState == "true"){
    //
    //         setProposedChecked(true);
    //     } else {
    //         setProposedChecked(false);
    //     }
    // }
    // let dataResults = [];

    let resultsPerPage = top;
    const handleEmergencyChecked = (override?: boolean) => {
        // console.log('override - ', override );
        if (override === false) {
            setEmergencyChecked(override);
            return;
        }

        // console.log("Triggered propsed check handler");
        if (emergencyChecked === true) {
            setEmergencyChecked(false);
            setFilters([]);
            setQ('');
        } else {
            setEmergencyChecked(true);
            // setFilters([{ field: "IncludeInDraft", value: true }])
        }
    };


    useEffect(() => {
        setIsLoading(true);


        let body = {
            q: q,
            top: top,
            skip: skip,
            filters: filters,
            highlight: [q]
        };

        // temporary solution for the public comment email
        // segmentAPI.getProposedRules().then((response:any)=>{
        //     let proposedRules = response.results;
        //     let commentBeginDate;
        //     let commentEndDate;

        //     var tempProposedRules = [];

        //     for (let index = 0; index < proposedRules.length ; index++ ){

        //         let element = proposedRules[index];

        //         const obj = JSON.parse(element.document.Entity);

        //         let beginString = obj.commentBeginDate;
        //         let endString = obj.commentEndDate;
        //         commentBeginDate = Date.parse(beginString);
        //         commentEndDate = Date.parse(endString);

        //         var today = Date.now();

        //         if(!Number.isNaN(commentBeginDate)) {

        //             if(commentBeginDate < today && today < commentEndDate)  {
        //                 tempProposedRules.push(element);
        //             }
        //         }
        //     };
        //     console.log("tempProposedRules - ", tempProposedRules);
        //         setResultCount(tempProposedRules.length);
        //         setResults(tempProposedRules);

        // });

        // uncomment after the proposed rule testing is complete
        searchFuncAPI
            .httpSearchFunction("Search", body)
            .then((response: any) => {
                setResults(response.data.results);
                setFacets(response.data.facets);
                setResultCount(response.data.count);
                setShowFilter(q !== "" ? true : false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });



        setIsLoading(false);

    }, [q, top, skip, filters, emergencyChecked]);


    let postSearchHandler = (searchTerm: any) => {
        // pushing the new search term to history when q is updated
        // allows the back button to work as expected when coming back from the details page
        history.push("/code?q=");
        setCurrentPage(1);
        setSkip(0);
        setFilters([]);
        setQ(searchTerm);
    };

    let updatePagination = (newPageNumber: number) => {
        setCurrentPage(newPageNumber);
        setSkip((newPageNumber - 1) * +top);
    };

    var resultBody;
    if (isLoading) {
        resultBody = (
            <div className="col-md-9">
                <CircularProgress />
            </div>
        );
    } else {
        resultBody = (
            <div className="col-md-9">
                <Results
                    documents={results}
                    top={top}
                    skip={skip}
                    count={resultCount}
                    query={q}
                    emergencyChecked={emergencyChecked}
                />
                {q != "" ? (
                    <Pager
                        className="pager-style"
                        currentPage={currentPage}
                        resultCount={resultCount}
                        resultsPerPage={resultsPerPage}
                        setCurrentPage={updatePagination}
                    ></Pager>
                ) : null}
            </div>
        );
    }

    return (
        <div className="code-search">
            <a id="skip-nav" className="skiplink-text" href="#main-content">
                Skip to Main Content
            </a>
            <Menu title={"Home"} OS={platform}></Menu>

            <main id="main-content">
                <h1 className="code-search-title">OKLAHOMA ADMINISTRATIVE CODE</h1>
                <hr className="underline" />

                <SearchWrapper
                    emergencyChecked={emergencyChecked}
                    handleEmergencyChecked={handleEmergencyChecked}
                    postSearchHandler={postSearchHandler}
                    q={q}
                    showFilter={showFilter}
                    facets={facets}
                    filters={filters}
                    setFilters={setFilters}
                />

                <div className="results">{resultBody}</div>
            </main>
        </div>
    );
};
