import { Status } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class StatusAPI extends BaseAPI<Status> {
  constructor() {
    super("statuses", APISettings.adminRulesApiUrl);
  }

  public getStatusesById: (id: number) => Promise<Status> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetStatusesById/${id}`))
      .data;
  };


  public getAllStatuses: () => Promise<Status> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllStatuses`)).data;
  };

  public getStatusesByWorkflowId: (id: number) => Promise<Status> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetStatusesByWorkflowId?workflowId=${id}`)).data;
  }

  public createStatuses: (status: Status) => Promise<any> = async (
    status: Status
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateStatuses`, {
      status,
    });
    return response.data;
  };

  public removeStatuses: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveStatuses/${id}`
    );
    return response.data;
  };

  public updateStatuses: (id: number, status: Status) => Promise<Status> =
    async (id: number, status: Status) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/UpdateStatuses/${id}`,
        { status }
      );
      return response.data;
    };
}

export const statusAPI = new StatusAPI();
