import {
  ActionDisplayType,
  ExecutionPermission,
  VisibilityLevel,
} from "../enums";
import { Identifiable } from "./Identifiable";
import { ActionType, Status } from "../model";

export class Action extends Identifiable {
  constructor(
    name: string,
    description: string,
    codeAction: string,
    status: Status,
    targetStatus: Status,
    /*notification: Notification,prompt: any,*/ displayType: ActionDisplayType,
    visibility: VisibilityLevel,
    executionPermission: ExecutionPermission,
    sortOrder: number,
    additionalInformation: string,
    actionType: ActionType,
    targetStatusId?: number | null
  ) {
    super(null);
    this.name = name;
    this.description = description;
    this.codeAction = codeAction;
    this.status = status;
    this.targetStatus = targetStatus;
    this.type = "";
    // this.notification = null;
    // this.prompt = null;
    this.displayType = displayType;
    this.visibility = visibility;
    this.executionPermission = executionPermission;
    this.sortOrder = sortOrder;
    this.additionalInformation = additionalInformation;
    this.actionType = actionType;
    if (targetStatusId) {
      this.targetStatusId = targetStatusId;
    }
  }

  public name: string;
  public description: string;
  public codeAction: string;
  public status: Status;
  public targetStatus: Status;
  // public notification: Notification;
  public type: string;
  // public prompt: any;
  public displayType: ActionDisplayType;
  public visibility: VisibilityLevel;
  public executionPermission: ExecutionPermission;
  public sortOrder: number;
  public additionalInformation: string;
  public actionType: ActionType;
  public targetStatusId?: number | null;
  public actionTypeId?: number | null;
}
