import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  SplitButton,
} from "@progress/kendo-react-buttons";
import { CardHeader } from "@progress/kendo-react-layout";
import { Card } from "@progress/kendo-react-layout/dist/npm/card/Card";
import { CardActions } from "@progress/kendo-react-layout/dist/npm/card/CardActions";
import { CardBody } from "@progress/kendo-react-layout/dist/npm/card/CardBody";
import React, { Fragment, useContext, useEffect } from "react";
import { AddSegmentModal } from "../../stateOffice/pages/WorkQueue/components/AddSegmentModal";
import { segmentAPI } from "../../utils/api";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import { RuleDrafting } from "../../utils/classes";
import { EditorType, NoteType, SegmentType as SegmentTypes } from "../../utils/enums";
import { Draft, SegmentType } from "../../utils/model";
import { AlertDialog } from "../AlertDialog";
import { DiffCompare } from "../DiffCompare";
import { Paragraph } from "../Paragraph";

import "./ExecOrderCodeHierarchy.scss";
// import { RuleDrafting } from "../../../../../utils/classes";



interface IExecOrderCodeHierarchy {
  expandAll: boolean;
  segmentData: any;
  segmentType: SegmentType[];
  // splitValue?: [];
  editStatus: any;
  editSection: (data: any, segmentData: any) => void;
  addSegment: (type: string, event: any, segmentData: any) => void;
  reloadHierarchy: () => void;
  draft?: Draft;
  draftHistoryData?: any[];
  openNoteEditor?: (note: any, create: boolean) => void;
  execOrderYear?: any;
}

/**
 * @author Brandon Larsen <brandon.larsen@tecuity.com>
 * @description - Hierarchy component that accepts in hierarchy starting segment. This will create nested accordions with the parent/child relation of segments
 * @param expandAll - boolean
 * @param segmentData - initial segment object
 * @param draft - optional draft data where this is being used in the draft editor as well
 * @param segmentType - Array of SegmentTypes
 * @param editStatus - boolean if record has been edited
 * @param editSection - function to launch editor
 * @param addSegment - function to create new segments
 * @param draftHistoryData - array of draft history items
 * @param reloadHierarchy - reload function
 * @param openNoteEditor - function to trigger note editor in parent for admin users
 * @returns - Nested accordion react components
 */
export const ExecOrderCodeHierarchy = (props: IExecOrderCodeHierarchy) => {
  const [children, setChildren] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [typeName, setTypeName] = React.useState<string | null>(null);
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [toggleAddForm, setToggleAddForm] = React.useState<boolean>(false);
  const [addOptions, setAddOptions] = React.useState<any>([]);
  const [addSegmentData, setAddSegmentData] = React.useState<any>();
  const [diffDescription, setDiffDescription] = React.useState<any>(null);
  const [seeCancelButton, setSeeCancelButton] = React.useState<boolean>(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [editButtonValue, setEditButtonValue] = React.useState(props.editStatus);
  const [addParentData, setAddParentData] = React.useState<any>();
  const [textToRender, setTextToRender] = React.useState(props.segmentData.text ?? "");
  const [descriptionToRender, setDescriptionToRender] = React.useState(props.segmentData.description ?? "");
  const [editClass, setEditClass] = React.useState<string>("");
  const [typeNameNum, setTypeNameNum] = React.useState<any>({});
  const [addParentId, setAddParentId] = React.useState();
  const [execOrderYears, setExecOrderYears] = React.useState<any>([]);
  /**
   * Note Types used for the split add button in current rules page
   */
  const [addNoteOptions, setAddNoteOptions] = React.useState(
    [
      {
        "text": "Editors note",
        "icon": "plus-outline",
        "id": NoteType.Editors
      },
      {
        "text": "Source Note",
        "icon": "plus-outline",
        "id": NoteType.Source
      },
      {
        "text": "Authority Note",
        "icon": "plus-outline",
        "id": NoteType.Authority
      },
      {
        "text": "Footnote",
        "icon": "plus-outline",
        "id": NoteType.Footnote
      },
      {
        "text": "Agency Note",
        "icon": "plus-outline",
        "id": NoteType.Agency
      },
    ]
  );

  /**
   * @description - generates options for split button used to add new draft items to hierarchy
   * @requires props.segmentType - list of segment types passed to component through props
   * @requires props.segmentData - segment data required to determine the options available in split button
   */
  const setButtonOptions = React.useCallback(async () => {
    let addOptionsCurrent: any = [];
    let item = props.segmentType!.find(
      (item: SegmentType) => item.name == props.segmentData.name
    );

    /* Adding the options to the addOptions array. */
    props.segmentType?.forEach((childItem: any, j: number) => {
      if (childItem.parentId == item?.id) {
        addOptionsCurrent.push({
          text: childItem.name,
          icon: "plus-outline",
          id: childItem.id,
        });
      }
    });
    setAddOptions(addOptionsCurrent);
  }, [props.segmentType, props.segmentData]);

  /* Using the useCallback hook to create a function that will be called when the component is mounted. */
  const onMount = React.useCallback(async () => {
    // console.log(props.segmentData);
    if (
      props.segmentData.id &&
      props.segmentData.name !== "Section" &&
      props.segmentData.name !== "Appendix" &&
      props.segmentData.name !== "Executive Order"
    ) {
      // console.log(props.segmentData.name);
      setAddParentId(props.segmentData.id);
      // let childSegments =  await segmentAPI.getSegmentsByParentId(
      //   props.segmentData.id
      // );
      if (!props.execOrderYear && props.segmentData) {
        let execOrderYears = await segmentAPI.getSegmentsExecutiveOrderYears();
        execOrderYears.sort((a: any, b: any) => a.execOrderYear.localeCompare(b.text))
        setExecOrderYears(execOrderYears);
      }
    }
    if (props.execOrderYear && props.segmentData) {
      let childItems = await segmentAPI.getSegmentsByExecOrderYear(props.execOrderYear.execOrderYear);
      setChildren(childItems);
    }

    textToDisplay();
    filterRoles();
  }, [props.segmentData, props.draftHistoryData]);

  /* This is the code that is running when the component is mounted. */
  useEffect(() => {
    if (props.segmentType) {
      setButtonOptions();
    }
    if ((expanded || props.expandAll) && props.segmentData) {
      onMount();
      setTextToRender(props.segmentData.text ?? "");
      setDescriptionToRender(props.segmentData.description ?? "");
    }
    textToDisplay();

    if (props.addSegment !== null) {
      setAddSegmentData(props.segmentData);
    }
  }, [
    props.draft,
    props.segmentType,
    expanded,
    props.expandAll,
    props.draftHistoryData,
    props.segmentData
  ]);

  const { getUser } = useContext(AuthenticationContext);

  // get user data from server and validate roles to filter front end items;
  const filterRoles = async () => {
    let user = await getUser();
    if (user) {
      if ((typeof user.profile.role) == "string") {
        if (user.profile.role.toString() == "Office of Administrative Rules" || user.profile.role.toString() == "Global User Administrator") {
          setIsAdmin(true);
        }
      }
      user.profile.role.forEach((element: string) => {
        if (
          element == "Office of Administrative Rules" ||
          element == "Global User Administrator"
        ) {
          setIsAdmin(true)
        }
      });
    }
  };


  /**
   * @description - click event handler for accordion expansion
   */
  const handleExpansion = async () => {
    expanded ? setExpanded(false) : setExpanded(true);
  };

  /**
   * @description - Used to update the selected type to be added as segment history item
   * @param event - event handler used to extract the type being selected on Item click
   */
  const updateSelectedType = (event: any) => {
    // for type name and ID
    setTypeNameNum({ text: event.item.text ?? "", id: event.item.id });

    // For the type name
    setTypeName(event.item.text ?? "");
  };

  /**
   * @description used to add new segment history item
   */
  const addSegmentHistoryItem = (event?: any) => {
    if (typeName && props.draft) {
      toggleAddForm ? setToggleAddForm(false) : setToggleAddForm(true);
    }

    var parentIds = {
      Title: addSegmentData.titleNum,
      Chapter:
        addSegmentData.chapterNum !== null ? addSegmentData.chapterNum : null,
      SubChapter:
        addSegmentData.subChapterNum !== null
          ? addSegmentData.subChapterNum
          : null,
      Section:
        addSegmentData.subChapterNum !== null
          ? addSegmentData.subChapterNum
          : null,
      Part: addSegmentData.partNum !== null ? addSegmentData.partNum : null,
    };

    setAddParentData(parentIds);
  };

  /**
   * @description - Return the segment identifier for the hierarchy (ex. appendixNum, sectionNum)
   * @param segmentData - Segment data to determin which identifeir gets displayed
   * @returns string of segment idetifier
   */
  const getSegmentNumber = (segmentData: any) => {
    switch (segmentData.segmentTypeId) {
      case SegmentTypes.Title:
        return segmentData.titleNum;
      case SegmentTypes.Chapter:
        return segmentData.chapterNum;
      case SegmentTypes.Subchapter:
        return segmentData.subChapterNum;
      case SegmentTypes.Appendix:
        return segmentData.appendixNum;
      case SegmentTypes.Part:
        return segmentData.partNum;
      case SegmentTypes.Section_Chapter:
      case SegmentTypes.Section_Part:
      case SegmentTypes.Section_Subchapter:
        return segmentData.sectionNum;
      case SegmentTypes.ExecutiveOrder:
        return `${segmentData.execOrderNum}-${segmentData.execOrderYear}`;
      default:
        return '';
    }
  };

  /**
   * It renders the header of the segment card.
   * @returns A function that returns a function.
   */
  const _renderHeader = () => {
    return (
      <div>
        {props.draft ? (
          <CardHeader className="segment-card-header-hierarchy">
            <SplitButton
              text={typeName == null ? "SELECT TYPE TO ADD" : `ADD ${typeName.toUpperCase()}`}
              className="split-button"
              items={addOptions}
              onItemClick={updateSelectedType}
              onButtonClick={(event: any) => addSegmentHistoryItem(event)}
            />
          </CardHeader>
        ) : (
          <CardHeader className="segment-card-header-hierarchy">
            {isAdmin && !props.draft ? (
              <SplitButton
                text={typeName == null ? "SELECT NOTE TYPE TO ADD" : `ADD ${typeName.toUpperCase()}`}
                className="split-button"
                items={addNoteOptions}
                onItemClick={updateSelectedType}
                onButtonClick={(event: any) => openNoteEditor(event, typeNameNum, true)}
              />
            ) : null}
          </CardHeader>
        )}
      </div>
    );
  };


  /**
   * @description - This checks to see if pending data exists if it does then it renders the edited text instead of the permanent rule
   * @returns - segment text
   */
  const textToDisplay = () => {
    let text = props.segmentData.text ?? "";
    let description = props.segmentData.description;
    if (props.draftHistoryData && props.draftHistoryData.length > 0) {
      let found = props.draftHistoryData.find(
        (e: any) => e.segmentId === props.segmentData.id
      );
      if (found) {
        text = found.segmentData.text ?? "";
        description = found.segmentData.description;
        setTextToRender(text);
        let diff: any = <DiffCompare text={props.segmentData.description} textCompare={description} editorType={EditorType.segment} />;
        setDiffDescription(diff);
        setDescriptionToRender(description);
        // setExpanded(true);
        setEditClass("edited-item");
      }
    } else {
      setTextToRender(text);
      setDescriptionToRender(description);
      setDiffDescription(null);
      setEditClass("")
    }
  };

  /**
   * @description - When clicking the edit segment button this will either send the segment history data or segments table data
   */
  const setSegmentToEdit = () => {
    let found: any;
    if (props.draftHistoryData) {
      found = props.draftHistoryData.find(
        (e: any) => e.segmentId === props.segmentData.id
      );
    }

    if (found) {
      let data = found.segmentData;
      data.id = found.segmentId;
      props.editSection(found.segmentData, props.segmentData);
      // }
    } else {
      props.editSection(props.segmentData, props.segmentData);
    }
  };

  /**
   * @description - Delete segment history for single segment on draft
   */
  const deleteSegmentHistory = async () => {
    let ruleDrafting = new RuleDrafting();
    await ruleDrafting.DeleteSegmentFromDraft(props.segmentData, props.draft, props.draftHistoryData);
    props.reloadHierarchy();
  }

  /**
   * @description - toggles alert dialog modal that awaits a y/n response
   */
  const toggleAlertDialog = () => {
    alertOpen ? setAlertOpen(false) : setAlertOpen(true);
  }

  /**
   * @description - handle response from alert dialog
   * @param response - boolean response value selected in dialog
   */
  const alertDialogResponse = (response: boolean) => {
    if (response) {
      deleteSegmentHistory();
    }
    alertOpen ? setAlertOpen(false) : setAlertOpen(true);
  }

  /**
   * Function triggered by click even that takes in a note object to be set to note edit modal
   * @param event
   * @param note - note object
   */
  const openNoteEditor = (event: any, note?: any, create?: boolean) => {
    /*
      note.text used for creation and note.note for edit. This is to make sure if the user tries to create
      a note without selecting a note type the modal wont open.
    */
    if ((note.text || note.note) && isAdmin) {
      if (props.openNoteEditor && !create) {
        props.openNoteEditor(note, false);
      } else if (props.openNoteEditor && create) {
        typeNameNum.segmentId = props.segmentData.id;
        props.openNoteEditor(typeNameNum, create)
      }
    }
  }

  /**
   * @param note - note object {segmentId:number, noteTypeId:number, note:text}
   * @param index - index value where these item are being mapped over to be set as the key
   * @param noteType - note type array to limit what note types should be returned
   * @description - sets segment note data to include any additional stlying needed based on note type
   * @returns - JSX component
   * Note types
   * @info - 1: Editors
   * 2: Source
   * 3: Authority
   * 4: Footnote
   * 5: Agency
   */
  const _renderNotes = (notes: any, noteType?: number[]) => {
    let text: any;
    let editorsNotes: any = [];
    let sourceNotes: any = [];
    let authorityNotes: any = [];
    let footNotes: any = [];
    let agencyNotes: any = [];

    notes.forEach((note: any, index: number) => {
      var found = null;
      found = noteType?.find((a: any) => a == note.noteTypeId);
      if (found) {
        if (note.noteTypeId == NoteType.Source) {
          sourceNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          );
        } else if (note.noteTypeId == NoteType.Editors) {
          editorsNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          );
        } else if (note.noteTypeId == NoteType.Authority) {
          authorityNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          );
        } else if (note.noteTypeId == NoteType.Footnote) {
          footNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          )
        } else if (note.noteTypeId == NoteType.Agency) {
          agencyNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          );
        } else if (note.noteTypeId == NoteType.Footnote) {
          footNotes.push(
            <span className={isAdmin ? "note-zone inline-note-text" : "inline-note-text"} onClick={(event) => openNoteEditor(event, note)}>
              <Paragraph value={note.note} className="inline-note-text" />
            </span>
          );
        }
      }
    })

    text = (
      <div>
        {editorsNotes.length > 0 ? (
          <span className="note"><i><b>EDITOR'S NOTE: </b>{editorsNotes}</i></span>
        ) : null}
        {sourceNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Source: </b>{sourceNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}]</span>
          </div>
        ) : null}
        {authorityNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="inline-note-text">{item}{index + 1 < authorityNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]</span>
          </div>
        ) : null}
        {agencyNotes.length > 0 ? (
          <div className="note">
            {agencyNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < agencyNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {footNotes.length > 0 ? (
          <div className="note">
            {footNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
      </div>
    )

    return text;
  };

  /**
 * @description - renders descriptions for items from the segments table
 * @returns - JSX component
 */
  const _renderDescription = () => {
    let text = props.segmentData.name;
    let data = props.segmentData;

    if (!descriptionToRender) {
      // this first if condition is to handle the executive orders for the current rules page
      if (data.statusName != "Undefined" && data.statusName) {
        text = data.name + " " + getSegmentNumber(data) + " [" + data.statusName.toUpperCase() + "]"
      } else {
        text = data.name + " " + getSegmentNumber(data)
      }
    } else if (!diffDescription && data.statusName != "Undefined" && data.statusName) {
      text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender + " [" + data.statusName.toUpperCase() + "]"
    } else {
      text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender;
    }


    let jsxComp = (
      <Paragraph
        key={props.segmentData.id + ":" + props.segmentData.name}
        value={text}
        className=""
      />
    );

    return jsxComp;
  };

  /**
* @description - renders descriptions for edited items taking into account the amended/renumbered status tags
* @returns - JSX component
*/
  const _renderDiffDescription = () => {
    if (props.segmentData) {
      let preText = props.segmentData.name;
      let postText = "";
      let data = props.segmentData;

      if (diffDescription) {
        preText = props.segmentData.name + " " + getSegmentNumber(props.segmentData) + " - ";
      }

      if (props.draftHistoryData && props.draftHistoryData.length > 0 && data && props.segmentData) {
        let found = props.draftHistoryData.find((e: any) => e.segmentId === data.id);

        if (found) {
          if (found.segmentData && (found.segmentData.statusName.toUpperCase() == "AMENDED AND RENUMBERED" ||
            found.segmentData.statusName.toUpperCase() == "AMENDED" ||
            found.segmentData.statusName.toUpperCase() == "RENUMBERED")) {
            if (found.segmentData.renumberedToSegmentNumber) {
              postText += " [" + found.segmentData.statusName + " To " + found.segmentData.renumberedToSegmentNumber + "]"
            } else if (found.segmentData.statusName != "Undefined") {
              postText += " [" + found.segmentData.statusName + "] ";
            }
          }
        }
      }

      let jsxComp = (
        <span className="diff-description" style={{ display: "flex" }}>
          <Paragraph
            key={"prefix" + data.id + ":" + data.name}
            value={preText}
            className=""
          />
          {diffDescription}
          <Paragraph
            key={"postfix" + data.id + ":" + data.name}
            value={postText}
            className=""
          />
        </span>
      );

      return jsxComp;
    } else {
      return <div>Text segment loaded incorrectly</div>
    }
  };


  /**
   * This function renders the segment data
   * @returns The ExecOrderCodeHierarchy component is being returned.
   */
  const _renderSegments = () => {
    return (
      <div>
        <AlertDialog open={alertOpen} close={!alertOpen} textContent={"Are you sure you want to remove all drafted changes for " + props.segmentData.name + " " + getSegmentNumber(props.segmentData) + " - " + descriptionToRender} alertResponse={alertDialogResponse} />
        <Card className="segment-card">
          {(addOptions.length > 0 || addNoteOptions.length > 0) ? _renderHeader() : null}
          {props.segmentData ? (
            <CardBody className="segment-details">
              <div className="draft-info">
                {/* Editors Notes */}
                <div>
                  {props.segmentData.segmentNotes && (props.segmentData.segmentNotes.length > 0)
                    ?
                    _renderNotes(props.segmentData.segmentNotes, [1])
                    :
                    null
                  }
                </div>
                {/* Segment Text */}
                <div>
                  {textToRender ? (
                    <Paragraph value={textToRender} className="paragraph" />
                  ) : null}
                </div>
                {/* All Other Notes */}
                <div>
                  {props.segmentData.segmentNotes && (props.segmentData.segmentNotes.length > 0)
                    ?
                    _renderNotes(props.segmentData.segmentNotes, [2, 3, 4, 5])
                    : null}
                </div>
              </div>
            </CardBody>
          ) : null}
          <Fragment>
            {/* Hides card actions for agency users in current rules page */}
            {(props.draft || isAdmin) && props.segmentData.name != "Title" ? (
              <CardActions>
                <span
                  className="k-button k-flat k-primary"
                  onClick={() => setSegmentToEdit()}
                >
                  Edit {props.segmentData ? props.segmentData.name : "Segment"}
                </span>
                {props.editStatus !== 0 ? (
                  <span
                    className="k-button k-flat k-error-colored"
                    onClick={() => props.editSection(null, null)}
                  >
                    Cancel Update
                  </span>
                ) : (
                  <span></span>
                )}
                {editClass == "edited-item" ? (
                  <span className="k-button k-flat k-error-colored" onClick={() => toggleAlertDialog()}>
                    Remove Changes
                  </span>
                ) : null}
              </CardActions>
            ) : null}
          </Fragment>
        </Card>
        {execOrderYears && execOrderYears.length > 0 ? (
          execOrderYears.map((element: any, index: number) => {
            return (
              <ExecOrderCodeHierarchy
                editStatus={props.editStatus}
                key={element.id}
                segmentData={{}}
                segmentType={props.segmentType}
                expandAll={props.expandAll}
                editSection={props.editSection}
                // splitValue={props.splitValue ?? undefined}
                addSegment={props.addSegment}
                reloadHierarchy={props.reloadHierarchy}
                draft={props.draft}
                draftHistoryData={props.draftHistoryData}
                openNoteEditor={props.openNoteEditor}
                execOrderYear={element}
              />
            )
          })
        ) : null}
        {children && (children.length > 0)
          ? children.map((element: any, index: number) => {
            return (
              <ExecOrderCodeHierarchy
                editStatus={props.editStatus}
                key={element.id}
                segmentData={element}
                segmentType={props.segmentType}
                expandAll={props.expandAll}
                editSection={props.editSection}
                // splitValue={props.splitValue ?? undefined}
                addSegment={props.addSegment}
                reloadHierarchy={props.reloadHierarchy}
                draft={props.draft}
                draftHistoryData={props.draftHistoryData}
                openNoteEditor={props.openNoteEditor}
              />
            );
          })
          : null}
      </div>
    );
  };

  /* This is the code that renders the segments. */
  return (
    <Fragment>
      {props.draft ? (
        <AddSegmentModal
          open={toggleAddForm}
          closeForm={addSegmentHistoryItem}
          segmentType={typeNameNum}
          parentData={addParentData}
          draftHistoryData={props.segmentData}
          reloadHierarchy={props.reloadHierarchy}
          segmentParentId={addParentId}
          draft={props.draft!}
        />
      ) : null}
      {props.segmentData ? (
        <Accordion
          className="draft-editor"
          expanded={props.expandAll ? props.expandAll : expanded}
          TransitionProps={{
            timeout: 200,
          }}
        >
          <AccordionSummary
            className={
              props.segmentData.name
                ? expanded
                  ? props.segmentData.name + " " + "segment" + " " + editClass + "expanded"
                  : props.segmentData.name + " " + "segment" + " " + editClass
                : expanded
                  ? props.execOrderYear
                    ? "segment exec-order-year expanded"
                    : "segment expanded"
                  : props.execOrderYear
                    ? "segment exec-order-year"
                    : "segment"
            }
            onClick={handleExpansion}
          >
            {
              !diffDescription
                ? props.execOrderYear
                  ? props.execOrderYear.execOrderYear
                  : _renderDescription()
                : null
            }
            {diffDescription ? _renderDiffDescription() : null}
            <div>
              {expanded ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="Expanded code section"
                      color="primary"
                      disabled
                      checked={expanded}
                    />
                  }
                  label="Keep Open"
                />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="Expanded code section"
                      color="primary"
                      disabled
                      checked={expanded}
                    />
                  }
                  label="Keep Open"
                />
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails
            className={`draft-details ${props.segmentData.name}`}
          >
            {_renderSegments()}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Fragment>
  );
};
