import { Chip, InputLabel } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Group from "@material-ui/icons/Group";
// import React from "react";
import "./ChipSelectListInput.scss";

interface IChipSelectListInputProps {
  strings: string[];
  onChange: (added: boolean, selectedItem: string) => void;
  label: string;
  optionsList: string[];
}

export const ChipSelectListInput = (props: IChipSelectListInputProps) => {
  const handleDelete = (item: string) => {
    props.onChange(false, item);
  };

  const handleAdd = (item: string) => {
    props.onChange(true, item);
  };

  return (
    <div className="chip-list-input">
      <InputLabel>{props.label}</InputLabel>
      <div className="chip-list-container">
        {props.strings.map((item, index) => (
          <div className="string-chip" key={index+item}>
            <Chip
              label={item}
              icon={<Group />}
              onDelete={() => {
                handleDelete(item);
              }}
              color="primary"
            />
          </div>
        ))}
      </div>
      <div className="chip-list-container">
        {props.optionsList.map((item,index) => (
          <div className="string-chip" key={index+item}>
            <Chip
              label={item}
              icon={<Group />}
              onDelete={() => {
                handleAdd(item);
              }}
              deleteIcon={<AddIcon />}
              color="default"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
