import { Button } from "@material-ui/core";
import React from "react";
import { Dialog } from "../../../../../components/Dialog";
import { NoteType } from "../../../../../utils/enums";


interface ICurrentRulesModalProps {
    children: JSX.Element;
    note:any;
    type:"EditSegment" | "EditNote" | "CreateNote";
    open:boolean;
    close: () => void;
};

/**
 * @description Note editor modal for current rules page to create and edit
 * @param note note object either containing the note object on create or note object containing note text and NoteId
 * @param create flag to indicate weather note is being created or edited
 * @param open flag to toggle modal open or closed
 * @param close function calling back to parent to close the modal
 * @returns modal component
 */
export const CurrentRulesModal = (props: ICurrentRulesModalProps) => {
    const {
        note,
        type,
        open,
        close,
        children,
    } = props;

    let container:any = React.useRef<HTMLDivElement>(null);

    const saveData = () => {
        if(type == "EditSegment") {

        } else if (type == "CreateNote") {

        } else if (type == "EditNote") {

        }
    }

    const modalDetails = () => {
        return (
            <div ref={container}>
                Note Editor goes here
            </div>
        );
    }

    const modalActions = () => {
        return (
            // <Button onClick={saveData}>{type == "EditSegment" ? "Save Segment" : type == "CreateNote" ? "Create Note" : "Save Note"}</Button>
            <div></div>
        )
    }

    return (
        <Dialog
            className="current-rules-preview"
            open={open}
            onClose={close}
            title={type == "EditSegment" ? `Edit Segment` : type == "CreateNote" ? `Create ${note.text}` : `Edit ${NoteType[note.noteTypeId]} Note`}
            children={children} actions={modalActions()}
        />
    )
};