import { Filing } from "../../../../../utils/model";
// import { Column } from "material-table";
import { PublicationStatus } from "../../../../../utils/enums";
import { UrlParser } from "../../../../../utils/urlParser";
import { Link } from "react-router-dom";
import { StatusIcon } from "../StatusIcon";

// #######################################################
// Set Initial columns for dynamic column generation
// #######################################################
let columns: any = [];

// #######################################################
// Set Contact Types and create columns
// #######################################################
export const setFilingTypeRows = (
  rows: any,
  filingType: string,
  updateRows: () => void
) => {
  // let arr: Filing[] = [];
  // for (var i = 0; i < rows.length; i++) {
  //     var temp;
  //     temp = rows[i] as Filing;
  //     temp.id = rows[i].id;
  //     arr.push(temp);
  // }

  if (rows[0] !== undefined && rows) {
    const filing: Filing = new Filing(
      rows[0].name,
      rows[0].description,
      rows[0].visibility,
      rows[0].notes,
      rows[0].additionalInformation,
      rows[0].entityType,
      rows[0].entityId,
      rows[0].entity,
      rows[0].status,
      rows[0].published,
      rows[0].amendments,
      rows[0].todoLists,
      rows[0].templateValues,
      rows[0].commentThread
    );

    columns = [];
    const keys = Object.keys(filing);

    keys.forEach((key, index) => {
      // Regular expression takes table name and converts formatting
      var text = key.replace(/([A-Z])/g, " $1");
      var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
      const column: any = {};
      column.title = capitalizeText;
      let lookup: Map<string, string> = new Map<string, string>();

      switch (column.title) {
        case "Published":
          column.title = "Publication Status";
          column.render = (row: any, type: any) => {
            return PublicationStatus[row.published];
          };
          break;
        case "Status":
          column.render = (row: any, type: any) => {
            return row.status?.name ?? "blah";
          };
          break;
        case "Filing Type":
          column.render = (row: any, type: any) => {
            return row.filingTypeId;
          };
          break;
        case "Name":
          column.render = (row: any, type: any) => {
            // rows.forEach((filing: Filing) => {
            //   lookup.set(filing.name, filing.name);
            // });

            // // convert Map to object
            // let obj = Array.from(lookup).reduce(
            //   (obj, [key, value]) => Object.assign(obj, { [key]: value }), // Maps can have non-String keys which can break object literals
            //   {}
            // );

            // if (key === "name") {
            //   column.lookup = obj;
            // }
            return (
              <Link to={UrlParser.Filing.parseFiling(filingType, row.id!)} valueLink={row.name}>
                {row.name}
              </Link>
            );
          };
          break;
        // case "Available Actions": {
        //   column.title = "Actions";
        //   column.render = (row, type) => {
        //     return <Action row={row} updateRows={updateRows}/>
        //   };
        //   break;
        // }
        // Filter which columns should be visible from original object

        case "Status Id":
        case "Filing Type Id":
        case "Filing Id":
        case "Root Id":
        case "Locked":
        case "Todo Lists":
        case "Additional Information":
        case "Visibility":
        case "Notes":
        case "Entity Type":
        case "Entity Id":
        case "Entity":
        case "Amendments":
        case "Template Values":
        case "Comment Thread":
        case "Entity Type Id":
        case "Filing History":
        case "FilingType":
        case "Root":
        case "Description":
        case "Available Actions":
        case "Filing Type":
          column.hidden = true;
          break;

        default:
          break;
      }

      column.field = key;
      columns.push(column);
    });
  }

  /** These two columns below are used to define the custom columns now so that they can be filtered */
  let titleColumn: any = {}
  titleColumn.field = "Title";
  titleColumn.render = "Title"
  columns.push(titleColumn);

  let docketColumn: any = {}
  docketColumn.field = "Docket"
  docketColumn.render = "Docket Number"
  columns.push(docketColumn)

  let dateColumn: any = {}
  dateColumn.field = "dDateCreated"
  dateColumn.render = "dDateCreated"
  columns.push(dateColumn);

  let publicationColumn: any = {}
  publicationColumn.field = "publicationStatus"
  publicationColumn.render = "publicationStatus"
  columns.push(publicationColumn);

  rows.forEach((item: any, index: number) => {
    rows[index] = { ...item, "Title": +item.entity.rule.referenceCode, "Docket": item.entity.docketNumber, "publicationStatus": PublicationStatus[item.published] }
  })

  const StatusIconColumn: any = {
    title: "Status",
    // render: (row, type) => <StatusIcon status={rows.status} />,
    // render: (row, type) => <div></div>,
  };
  const ActionIconColumn: any = {};

  return { rows: rows, columns: columns };
};
