import * as React from "react";
import {
  Form,
  FormElement,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";

import { subscriptionLoginAPI } from "../../../../utils/api/subscription/SubscriptionLoginApi";
import bcrypt from "bcryptjs";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { RegisterEmailForm } from "./RegisterEmailForm";
import { RegisterPasswordForm } from "./RegisterPasswordForm";

interface stepsInterface {
  isValid: boolean | undefined;
  label: string;
}

interface registerInterface {
  tabSelect: any;
}

const stepPages = [RegisterEmailForm, RegisterPasswordForm];

export const SubscriptionRegisterSteps = (props: registerInterface) => {
  const [step, setStep] = React.useState<number>(0);
  const [formState, setFormState] = React.useState<Object>({});
  const [steps, setSteps] = React.useState<Array<stepsInterface>>([
    { label: "Email", isValid: undefined },
    { label: "Password", isValid: undefined },
  ]);
  const lastStepIndex = steps.length - 1;

  const [successful, setSuccessful] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [validEmail, setValidEmail] = React.useState<any>();
  const [emailAlreadyExists, setEmailAlreadyExists] = React.useState<any>();

  const salt = bcrypt.genSaltSync(10);
  const stepPages = [
    RegisterEmailForm,
    RegisterPasswordForm,
  ];

  const isValidEmail = React.useRef(false);
  // const doesEmailAlreadyExist = React.useRef(false);

  const [tabSelected, setTabSelected] = React.useState<number>(2);

  React.useEffect(() => {
    isValidEmail.current = validEmail;
    // doesEmailAlreadyExist.current = emailAlreadyExists;
    setTabSelected(props.tabSelect);
  }, [validEmail, emailAlreadyExists, props.tabSelect]);

  const onStepSubmit = React.useCallback(
    async (event: FormSubmitClickEvent) => {
      const { isValid, values } = event;

      // console.log('event - ', event)
      // console.log("step - ", step);


      if (step === 0) {
        // Verify email addresses match, set state and return if not valid
        // If email or confirmEmail is empty, return

        // Do emails match?
        if (event.values.email !== event.values.confirmEmail) {
          setEmailMessage("Emails do not match");
          return;
        } else {
          // if emails match, check db for existing email.
          try {
            const response: any =
              await subscriptionLoginAPI.verifySubscriptionUserEmail(
                values.email
              );
            if (response.isSuccess === true) {
              setEmailAlreadyExists(true);
              Swal.fire({
                title: "Uh Oh!",
                text: "There is already an account with that email, please check your email address and try again.",
                icon: "warning",
                confirmButtonText: "Ok",
                confirmButtonColor: "#3f51b5",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              });
              setEmailMessage("Email already exists");
              return;
            } else {
              setEmailAlreadyExists(false);
            }
          } catch (error) {
            setEmailAlreadyExists(false);
          }

          setValidEmail(true);
          setUserEmail(event.values.email);
          setEmailMessage("");
          // }
        }
        setUserEmail(event.values.email);
        setEmailMessage("");
      }

      // Verify passwords match, set state and return if not valid
      if (step === 1) {
        if (event.values.password !== event.values.confirmPassword) {
          setEmailMessage("Passwords do not match");
          return;
        }


        // setLoading(false);
        Swal.fire({
          title: `An email has been sent to ${values.email} Click the link to activate your account.`,

          icon: "success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate.push("/subscriptionProfile");
            // window.location.reload();
            // setSuccess(true);
          }
        });


        // create user
        const result = await subscriptionLoginAPI.registerSubscriptionUser(userEmail, event.values.password)
      }


      const currentSteps = steps.map(
        (currentStep: stepsInterface, index: number) => ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid,
        })
      );

      setSteps(currentSteps);

      if (!isValid) {
        return;
      }

      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);
    },
    [steps, step, lastStepIndex]
  );

  const onPrevClick = React.useCallback(
    (event: any) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stepper value={step} items={steps} />
      <Form
        initialValues={formState}

        onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (
          <div style={{ alignSelf: "center" }}>
            <FormElement >
              {stepPages[step]}
              <h5 style={{ backgroundColor: "white", color: "red" }}>
                {emailMessage}
              </h5>
              <span style={{ marginTop: "40px", marginBottom: "40px" }} />
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
                className={
                  "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                }
              >
                <span style={{ alignSelf: "center" }}>
                  Step {step + 1} of 2
                </span>
                <div>
                  {step !== 0 ? (
                    <Button
                      style={{ marginRight: "16px" }}
                      onClick={onPrevClick}
                    >
                      Previous
                    </Button>
                  ) : undefined}
                  {step !== 2 ? (
                    <Button
                      disabled={!formRenderProps.allowSubmit}
                      onClick={formRenderProps.onSubmit}
                    >
                      {step === 0 ? "Next" : "Submit"}
                    </Button>
                  ) : null}
                </div>
              </div>
            </FormElement>
          </div>
        )}
      />
    </div>
  );
};
