import React, { Fragment, useEffect } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI, segmentHistoryAPI } from "../../../../../utils/api";
import "./RegisterIssueModal.scss";
import { GridPDFExport, savePDF } from "@progress/kendo-react-pdf";
import { Box, Card, Tab, Tabs } from "@material-ui/core";

interface IRegisterIssueModal {
  open: boolean;
  close: () => void;
  // draft: any;
  // segmentHistoryData: any;
}

export const RegisterIssueModal = (props: IRegisterIssueModal) => {
  const onMount = async () => {
    // console.log("on mount hit");
  };

  useEffect(() => {
    if (props.open) {
      onMount();
    }
    return () => {};
  }, [props.open]);

  /**
   * @description - PDF download function
   */
  let container: any = React.useRef<HTMLDivElement>(null);
  const pdfDownload = () => {
    // console.log("pdf download triggered")
    let element = container.current;
    savePDF(element, {
      paperSize: "a4",
      margin: 40,
      fileName: "Register_Content",
    });
  };

  /**
   * @description saves current segment history edit changes
   * @returns null
   */
  const saveRegisterIssue = () => {
    // console.log("save clicked");
    pdfDownload();
  };

  const modalDetails = () => {
    return <Fragment></Fragment>;
  };

  const modalActions = () => {
    let value;
    value = <Button onClick={saveRegisterIssue}>Save</Button>;

    return value;
  };

  return (
    <Dialog
      className="add-register-item-modal"
      open={props.open}
      onClose={props.close}
      title={"Create New Register"}
      children={modalDetails()}
      actions={modalActions()}
    />
  );
};
