import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import "./Result.scss";
import { Button } from "@progress/kendo-react-buttons";
import {
  // Code as CodeIcon,
  ExpandMore as ExpandMoreIcon,
  // History as HistoryIcon,
  // PictureAsPdf as PictureAsPdfIcon,
  // OpenInBrowser
} from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";

import { StorageContainer } from "../../../../../../utils/enums";
import _ from "lodash";
import { Paragraph } from "../../../../../../components/Paragraph";
import { CodeModal } from "../..";
import { ContactHyperLink } from "../../CodeResult";
import { contactAPI, fileAPI, filingAPI, ruleAPI, segmentAPI } from "../../../../../../utils/api";
import saveAs from "file-saver";
import { PublicCommentModal } from "../../CodeResult/PublicCommentModal";
import { CommentHyperLink } from "../../CodeResult/CommentHyperLink";
import { format, parseISO } from "date-fns"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingBottom: "1rem",
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: "bold"
      // fontWeight: theme.typography.fontWeightBold,
    },
    breadCrumb: {
      align: "center !important",
    },
    advancedSearch: {
      backgroundColor: "#1CA6DF",
      color: "white",
    },
    searchFocused: {
      '& > div[tabindex="0"]:focus': {
        backgroundColor: "#004e9a",
        boxShadow: "0 0 1pt 0.8pt blue",
      },
    },
    inputLabel: {
      position: "absolute",
      left: "24px",
      background: "white",
      padding: "0 6px",
      zIndex: 1,
      fontWeight: "normal",
      fontSize: "1.5em",
    },
    relative: {
      position: "relative",
    },
  })
);



export default function Result(props: any) {
  const [copied, setCopied] = useState(false);

  const searchWord = props.query;
  const { segment } = props.document;
  const [expanded, setExpanded] = useState(false);
  const [htmlHighlight, setHtml] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [publicCommentModalOpen, setPublicCommentModalOpen] = useState<boolean>(false);
  const [contactIds, setContactIds] = useState<any>(null);
  const classes = useStyles();
  const url = window.location.href
  const [isCurrentPublicComment, setIsCurrentPublicComment] = useState<boolean>(false);
  const [email, setEmail] = useState<any>();
  const [commentText, setCommentText] = useState("");
  const [commentBeginDate, setCommentBeginDate] = useState("");
  const [commentEndDate, setCommentEndDate] = useState("");

  const copyTitleLocation = async (titleNum: any) => {
    const el = document.createElement("input");

    let url: any = await fileAPI.copyFileUrl(
      "Title_" + titleNum + ".pdf",
      StorageContainer.titlepdf
    );
    // console.log("Copy Title URL: ", url);

    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const onAccordionChange = async (params: any, expanded: boolean) => {
    if (expanded === true) {

      var entity = props.document.Entity;
      var jsonEntity;
      if (entity) {
        jsonEntity = JSON.parse(entity);

        var strCommentBeginDate = jsonEntity["commentBeginDate"];
        var strCommentEndDate = jsonEntity["commentEndDate"];

        var beginDate = getDate(strCommentBeginDate);
        var endDate = getDate(strCommentEndDate);

        setCommentBeginDate(beginDate);
        setCommentEndDate(endDate);

        var segData = JSON.parse(props.document.SegmentData);
        setCommentText(segData.text);
        setIsCurrentPublicComment(false);
      }

      try {
        // console.log(props.document)
        if (props.document.FilingId) {
          var result = await filingAPI.getPublicCommentById(
            props.document.ChapterId,
            props.document.FilingId
          );

          if (result.isCurrent === true) {
            setIsCurrentPublicComment(true);
            setEmail(result.email);
          }
        }
      } catch (error) {
        throw (error);
      }
    }
  };

  const openCodeModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  }

  const handlePublicCommentClick = () => {
    publicCommentModalOpen ? setPublicCommentModalOpen(false) : setPublicCommentModalOpen(true);

  }

  const openPublicCommentModal = () => {
    publicCommentModalOpen ? setPublicCommentModalOpen(false) : setPublicCommentModalOpen(true);
  }

  const pdfDownload = async () => {

    let url: any = await fileAPI.getFileUrl(
      "Title_" + props.document.Title_Number + ".pdf",
      StorageContainer.titlepdf
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    // console.log("Public", blob);
    const fileURL = url.createObjectURL(blob);
    // console.log("Result: ", blob);
    saveAs(blob, "Title_" + props.document.Title_Number + ".pdf");
  }

  const htmlDownload = async () => {

    let url: any = await fileAPI.getFileUrl(
      "Title_" + props.document.Title_Number + ".html",
      StorageContainer.TitleHtml
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);
    saveAs(blob, "Title_" + props.document.Title_Number + ".html");
  }

  // HIGHLIGHT SEARCHED WORD BEFORE DISPLAYING HTML IN THE PARAGRAPH
  // COMPONENT.
  const getHighlights = () => {
    let highLightResult = "";
    const replaceText = "No Text Found";

    let htmlResult: string;

    let escapedSearch = _.escapeRegExp(searchWord);



    if (escapedSearch === "") {
      if (props.document.Text) {
        htmlResult = props.document.Text;
      } else {
        htmlResult = "";
      }
    } else {
      highLightResult = `<span>${props.document.Text}</span>`;
      const regex = new RegExp(`(${escapedSearch})`, "gi");
      let parts =
        props.document.Text !== null
          ? props.document.Text.split(regex)
          : replaceText.split(regex);

      parts.forEach((part: any, index: number) => {
        parts[index] = regex.test(part)
          ? `<mark key=${index}>${part}</mark>`
          : `<span key=${index}>${part}</span>`;
      });

      htmlResult = parts.join("");
    }

    setHtml(htmlResult);
  };

  const onMount = useCallback(async () => {
    let rule: any;
    if (props.document.Title_Number && contactIds == null) {
      rule = await ruleAPI.getRuleReferenceCode(props.document.Title_Number);
      if (rule.contactIds) {
        setContactIds(rule.contactIds);
      }
    }
  }, [props.document.Title_Number, props.query])

  useEffect(() => {
    if (contactIds == null) {
      onMount();
    }
    getHighlights();
  }, [getHighlights, props.query]);

  if (props.document.Level == null) {
    // return <div>{props.document.SegmentTypeId}</div>;
  }
  const style = {
    marginLeft: props.document.Level * 3,
  };
  const stylePadding = {
    marginLeft: props.document.Level * 3 + 1,
  };

  // This was needed because the date is off by one where the data conversion tool uses UTC time so local date times end up off by 1 day
  // check the follow site for a detailed description of why this happens
  // https://dev.to/zachgoll/a-complete-guide-to-javascript-dates-and-why-your-date-is-off-by-1-day-fi1
  const getDate = (date: string) => {
    let newdate = new Date(date);
    const day = newdate.getUTCDate();
    const month = newdate.getUTCMonth() + 1; // Return Value is 0 indexed
    const year = newdate.getUTCFullYear();
    const localString = new Date(month + "-" + day + "-" + year).toLocaleDateString();
    return localString;
  }

  return (
    <Container>
      <CodeModal open={modalOpen} onClose={openCodeModal} title={props.document.Title_Number} />
      {contactIds ?
        <PublicCommentModal agencyEmail={email} open={publicCommentModalOpen} close={openPublicCommentModal} handlePublicCommentClick={handlePublicCommentClick} />
        : null}
      <div className={classes.root}>
        <Accordion
          classes={{ root: classes.searchFocused }}
          onChange={onAccordionChange}
        >
          <AccordionSummary
            classes={{ root: classes.advancedSearch }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>
              <Typography className={classes.heading}>
                {props.document.Description
                  ? props.document.Description
                  : "Executive Order"}
              </Typography>
              <Typography className={classes.breadCrumb}>
                Title {props.document.Title_Number}. {props.document.TitleName}
              </Typography>
              <Typography>
                {props.document.Chapter_Number
                  ? `Chapter ${props.document.Chapter_Number}. ${props.document.ChapterName ?? ""
                  }`
                  : null}
              </Typography>
              <Typography>
                {props.document.SubChapterNum
                  ? "Subchapter " +
                  `${props.document.SubChapterNum}. ${props.document.SubChapterName ?? ""
                  }`
                  : null}
              </Typography>
              <Typography>
                {props.document.PartNum
                  ? "Part " +
                  `${props.document.PartNum}. ${props.document.PartName ?? ""
                  }`
                  : null}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <Card
              orientation="horizontal"
              style={{ width: "100%", height: 500 }}
            >
              <h2 className="agency-display">{props.document.SegmentTypeName}</h2>

              <Paper style={style} className="owning-body">
                <Typography>
                  <CardHeader>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={9}>
                        <div>
                          {/* <p>
                            <b><strong style={{color: "#D68630"}}>Title Number:</strong></b> {props.document.OacNumber}
                          </p> */}
                          <div style={{ marginLeft: "-18px" }}>
                            {isCurrentPublicComment ? (
                              <CommentHyperLink
                                contactIds={contactIds}
                                open={publicCommentModalOpen}
                                close={openPublicCommentModal}
                                handleClick={handlePublicCommentClick}
                              />
                            ) : null}
                          </div>
                          {commentBeginDate ? (
                            <p>
                              <strong style={{ color: "#D68630" }}>
                                Public Comment Start:
                              </strong>{" "}
                              <span>
                                {commentBeginDate
                                  ? commentBeginDate
                                  : "No Begin Date Found"}
                              </span>
                              {/* {props.document.Created} */}
                            </p>
                          ) : null}
                          {commentEndDate ? (
                            <p>
                              <strong style={{ color: "#D68630" }}>
                                Public Comment End:
                              </strong>{" "}
                              <span>
                                {commentEndDate
                                  ? commentEndDate
                                  : "No End Date Found"}
                              </span>
                              {/* {props.document.Created} */}
                            </p>
                          ) : null}
                          <p>
                            <strong style={{ color: "#D68630" }}>
                              OAC Number:
                            </strong>{" "}
                            <span>
                              {props.document.Section_Number ??
                                props.document.ExecOrderNum ??
                                props.document.PartNum ??
                                "Appendix " + props.document.Appendix_Number ??
                                "No OAC Number Found"}
                            </span>
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <div>
                          <div>
                            <Button
                              look="flat"
                              icon="pdf"
                              style={{ color: "red" }}
                              value={props.document.Title_Number}
                              onClick={pdfDownload}
                            >
                              Download PDF
                            </Button>
                            <Button
                              look="flat"
                              icon="copy"
                              onClick={() =>
                                copyTitleLocation(props.document.Title_Number)
                              }
                              value={props.document.Title_Number}
                            >
                              {!copied ? "Copy Title Link" : "Copied!"}
                            </Button>
                            <Button
                              look="flat"
                              icon="code"
                              style={{ color: "blue" }}
                              value={props.document.Title_Number}
                              onClick={htmlDownload}
                            >
                              Download Html
                            </Button>
                            <Button
                              look="flat"
                              icon="file"
                              style={{ color: "blue" }}
                              onClick={openCodeModal}
                            >
                              View Title
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </CardHeader>
                  <CardBody>
                    <Paragraph
                      value={
                        isCurrentPublicComment ? commentText : htmlHighlight
                      }
                      className={"make-scrollable"}
                    />
                  </CardBody>
                </Typography>
              </Paper>
            </Card>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}
