import React, { useEffect } from "react";
import { Rule } from "../../../../utils/model/";
import { Divider, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactDisplay } from "./";

import "../QuickLinks.scss";

interface IAgencyDisplayProps {
  agencyList: [];
}

export const AgencyDisplay = (props: IAgencyDisplayProps) => {

  useEffect(() => {

  }, [])


  return (
    <div className="agency">
      {props.agencyList
        ? props.agencyList.map((item: Rule, key: number) => (
            <Paper className="agency-paper" key={key}>
              <div className="rule-wrapper">
                <div className="header">
                <Typography className="agency-label">Agency</Typography>
                </div>
                <Divider/>
                <div className="title">Title {item.referenceCode}</div>
                <div className="rule">{item.title ? item.title : ""}</div>
              </div>
              <div className="contact-wrapper">
                <div className="header">
                  <Typography className="agency-label">Liaison</Typography>
                </div>
                <Divider/>
                <ContactDisplay contactIds={item.contactIds}/>
              </div>
            </Paper>
          ))
        : null}
    </div>
  );
};
