import { Action, Workflow, StatusType } from "../model";
import { StatusRecordStatus, StatusType as StatusTypes, VisibilityLevel } from "../enums";
import { Identifiable } from "./Identifiable";

export class Status extends Identifiable {
    constructor(
        name: string,
        description: string,
        type: StatusTypes,
        sortOrder: number,
        workflow: Workflow,
        visibility: VisibilityLevel,
        recordStatus: StatusRecordStatus,
        statusType: StatusType,
        statusTypeId?: number
        ) {
            super(null);
            this.name = name;
            this.description = description;
            this.type = type;
            this.sortOrder = sortOrder;
            this.workflow = workflow;
            this.visibility = visibility;
            this.actions = [];
            this.recordStatus = recordStatus;
            this.statusType = statusType;

            if(statusTypeId) { this.statusTypeId = statusTypeId; }
    }

    public name: string;
    public description: string;
    public type: StatusTypes;
    public sortOrder: number;
    public formName?: string;
    public workflow: Workflow;
    public visibility: VisibilityLevel;
    public actions: Action[];
    public recordStatus: StatusRecordStatus;
    public statusType: StatusType;
    public statusTypeId?: number;
}