import * as React from 'react';
import { Dialog, Button, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import "./AlertDialog.scss";

interface IAlertDialog {
  open: boolean;
  close: boolean;
  textContent: string;
  alertResponse: (response: boolean) => void;
  yesNo?: boolean;
}

export const AlertDialog = (props: IAlertDialog) => {

  const handleClose = (response: boolean) => {
    props.alertResponse(response);
  };



  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="alert-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="message">
            <WarningIcon className="alert-icon" />
            <p>{"Are you Sure?"}</p>
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.textContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{props.yesNo ? "No" : "Disagree"}</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            {props.yesNo ? "Yes" : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}