import {NotificationEvent} from "../../model/subscription";
import {BaseAPI} from "../internal";
import {APISettings} from "../../settings/APISettings";

class NotificationEventAPI extends BaseAPI<NotificationEvent> {
    constructor() {
        super("notificationEvent", APISettings.subscriptionsUrl);
    }

    public getByName: (name: string) => Promise<NotificationEvent> = async (
        name: string
    ) => {
        const response = await BaseAPI.axios.get(
            `${this.apiUrl}/${this.type}/${name}`
        );
        if (response.status === 204) {
            return null;
        }
        return response.data;
    };
}

export const notificationEventAPI = new NotificationEventAPI();
