import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

export const useFormState = (initialState: object = {}, ref: any) => {
  const [formValues, setFormValues] = React.useState(initialState);

  useFormHandles(formValues, ref);

  const handleChange: any = (e: any, name: string = "", value: any) => {
    setFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  return [formValues, handleChange];
};

export const FormContext = React.createContext({});
export const FormOnChangeContext = React.createContext(
  (e: any, name: any, value: any) => {}
);

export const useFormValues = () => {
  const formValues = React.useContext(FormContext);
  return formValues;
};

export const useFormValue = (name: string = "", value?: any) => {
  const formValues: Record<string, string> = useFormValues();
  const formValue = value !== undefined ? value : formValues[name];
  return formValue;
};

export const useFormOnChange = () => {
  const onChange = React.useContext(FormOnChangeContext);
  return onChange;
};

interface IFormProvider {
  values: any;
  onChange: any;
  children: any;
}

export const FormProvider = ({ values, onChange, children }: IFormProvider) => {
  return (
    <FormContext.Provider value={values}>
      <FormOnChangeContext.Provider value={onChange}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {children}
        </MuiPickersUtilsProvider>
      </FormOnChangeContext.Provider>
    </FormContext.Provider>
  );
};

export const useFormHandles = (values: any, ref: any) => {
  React.useImperativeHandle(ref, () => ({
    getValues: () => values,
    getType: () => "Permanent",
  }));
};
