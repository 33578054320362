import React, { useEffect } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI } from "../../../../../utils/api";
import "./DraftPreviewModal.scss";
import { savePDF } from "@progress/kendo-react-pdf";
import { CircularProgress } from "@material-ui/core";

interface IDraftPreviewModal {
    open: boolean;
    close: () => void;
    draftData: any;
    isEmergency?: boolean;
    effectiveDate?: Date;
    expirationDate?: Date;
    // parentData:
}

export const DraftPreviewModal = (props: IDraftPreviewModal) => {
    const [html, setHtml] = React.useState<string>("");
    const onMount = async () => {
        let html = "";
        let draft: any = await draftAPI.compileDraft(props.draftData.id);

        if(draft.data){
             if (props.isEmergency) {
               html = `<div className="emergency-dates"><span className="emergency-effective-date"><b>Effective Date: </b></span>${props.effectiveDate?.toLocaleDateString()}<div><b>Expiration Date: </b>${props.expirationDate?.toLocaleDateString()}</div></div>`;
             }
            setHtml(html += draft.data!.result);
        } else {
            setHtml("No Text Found");
        }
    }

    useEffect(() => {
        if (props.open) {
            onMount();
        }
    }, [props.open])

    let container: any = React.useRef<HTMLDivElement>(null);

    const closeDialog = () => {
        setHtml("");
        props.close();
    }

    function printDiv() {
         let element: any = container.current;

        var printWindow: any = window.open("", "", "");
        printWindow.document.write(`<html><head><style type="text/css">
        @media print{
            .note {
                // display:flex;
                font-size: 11px !important;
                // margin-bottom: 12px;
                // margin-top: 12px;
            }
            .segment-title {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-section-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
            }
            .segment-item {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .preview-segment-note {
                > * {
                display: inline-block !important;
                }
                display: block !important;
                font-size: 11px !important;
                // float: left;
            }

            .center-note {
                display: flex;
                justify-content: center;
            }
            .inline-note-text {
                display: inline-block !important;
                font-size: 11pt !important;
                // float: left;
            }
                .inline-note-text > * {
                margin-bottom: 0px !important;
                margin-top: 0px !important;
            }
        }
        .note {
            // display:flex;
            font-size: 11px !important;
            // margin-bottom: 12px;
            // margin-top: 12px;
        }
        .segment-title {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-section-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
        }
        .segment-item {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .preview-segment-note {
            > * {
            display: inline-block !important;
            }
            display: block !important;
            font-size: 11px !important;
            // float: left;
        }

        .center-note {
            display: flex;
            justify-content: center;
        }
        .inline-note-text {
            display: inline-block !important;
            font-size: 11pt !important;
            // float: left;
        }
            .inline-note-text > * {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
        }
        </style><title>${props.draftData.draftName}</title>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(element.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print()
    }


    const pdfDownload = () => {
        let element = container.current;
        savePDF(element, {
            paperSize: "a4",
            margin: 40,
            fileName: `Chapter_${props.draftData.chapterNumber}_${new Date().toLocaleDateString()}`,
            scale: 0.65
        });
    }

    const modalDetails = () => {
        return (
            <div ref={container}>
                {html === "" ? (<div className="loading-spinner"><CircularProgress color="inherit" size={20} /><div>Loading</div></div>) : (<Paragraph className="" value={html} />)}
            </div>
        );
    }

    const modalActions = () => {
        return (
            <Button onClick={printDiv}>Download PDF</Button>
        )
    }

    return (
        <Dialog className="draft-preview" open={props.open} onClose={closeDialog} title={"Preview"} children={modalDetails()} actions={modalActions()} />
    )
}
