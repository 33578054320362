import React, { useState } from 'react'
import { Checkbox as Check, CheckboxChangeEvent } from '@progress/kendo-react-inputs'

interface ICheckboxProps {
    label: any;
    onClick: (value: boolean) => void;
    defaultValue?: boolean | null;
    readOnly?: boolean | null;
}

const Checkbox = (props: ICheckboxProps) => {
    const { label, onClick, defaultValue, readOnly } = props;
    const [value, setValue] = useState<boolean>(defaultValue ?? false)

    const handleChange = (event: CheckboxChangeEvent) => {
        setValue(event.value);
        onClick(event.value);
    };

    return (
        <>
            <Check
                value={value ?? false}
                label={label ?? ""}
                onChange={handleChange}
                defaultValue={defaultValue ?? false}
                readOnly={readOnly ?? false}
            />
        </>
    )
}

export default Checkbox