import { StatusRecordStatus } from "../enums";
import { Identifiable } from ".";

export class RuleType extends Identifiable {
    constructor(name: string, description: string, recordStatus: StatusRecordStatus) {
        super(null);
        this.name = name;
        this.description = description;
        this.recordStatus = recordStatus
    }

    public name: string;
    public description: string;
    public recordStatus: StatusRecordStatus;
}