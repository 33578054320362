import React, { Component } from "react";
import { AuthenticationService } from "./AuthenticationService";


const authService = new AuthenticationService();

// Create a new context (semi persistent storage)
export const AuthenticationContext = React.createContext<AuthenticationService>(
  authService
);

// Create a consumer (a way to access the context)
export const AuthenticationConsumer = AuthenticationContext.Consumer;

// Create a Provider component (a react component that will let us access the context and consume it)
export class AuthenticationProvider extends Component {
  render() {
    return (
      <AuthenticationContext.Provider value={authService}>
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}
