import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import React from "react";
import "./App.scss";
import "./fonts.css";
import "./fonts/MaterialIcons/MaterialIcons-Regular.ttf";
import "./fonts/MaterialIcons/MaterialIcons-Regular.woff2";
import "./fonts/Montserrat/Montserrat-Black.ttf";
import "./fonts/Montserrat/Montserrat-BlackItalic.ttf";
import "./fonts/Montserrat/Montserrat-Bold.ttf";
import "./fonts/Montserrat/Montserrat-BoldItalic.ttf";
import "./fonts/Montserrat/Montserrat-Italic.ttf";
import "./fonts/Montserrat/Montserrat-Light.ttf";
import "./fonts/Montserrat/Montserrat-LightItalic.ttf";
import "./fonts/Montserrat/Montserrat-Medium.ttf";
import "./fonts/Montserrat/Montserrat-MediumItalic.ttf";
import "./fonts/Montserrat/Montserrat-Regular.ttf";
import "./fonts/Montserrat/Montserrat-Thin.ttf";
import "./fonts/Montserrat/Montserrat-ThinItalic.ttf";

import { Routes } from "./Routes";

const App: React.FC = () => {
  const initalTheme = React.useMemo(
    () =>
      createTheme({
        overrides: {
          MuiContainer: {
            maxWidthXl: {},
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={initalTheme}>
      <CssBaseline />
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
