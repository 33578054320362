import {
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuid } from "uuid";
// import { StateOfficeNavigationSettings } from "./../../../../../../utils/settings/StateOfficeNavigationSettings";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Filing,
  FilingType,
  Rule,
  Draft,
} from "../../../../../../utils/model";
import { PermanentFinalRulemakingAction } from "../../../../../../utils/model/Filing";
import { Row, Select, TextField } from "./";
import { RULE_PLURAL_NAME } from "./../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";
import { FilingLink } from "../..";
import { FileUpload } from "../../../../../../components/FileUpload";
import { StorageContainer, DraftStatuses, RulemakingActions } from "../../../../../../utils/enums";
import { fileAPI } from "../../../../../../utils/api";

interface IPermanentFormProps {
  filingType: FilingType;
  filing?: Filing;
  formatDate: (date: any) => any;
  renderRuleDropdown: () => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderDocketDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  // attestationList: Contact[];
  // liaisonList: Contact[];
  docketList: Docket[];
  ruleList: Rule[];
  draftList: Draft[];
  saveForm: (item: any, type: string) => void;
  getProposedRules: (draftId: number | null | undefined) => string[];
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
  returnContactListAsync: (ruleId: number, isAttestation: boolean) => any;
}

const getInitialState = (props: IPermanentFormProps) => ({
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  chapter: props.ruleList[0],
  rule: props.ruleList[0],
  chapterId: -1,
  ruleId: -1,
  rulemakingAction: RulemakingActions.PermanentFinalAdoption,
  authority: "",
  rules: "",
  submitGovCabDate: new Date(),
  commentBeginDate: new Date(),
  commentEndDate: new Date(),
  publicHearing: "N/A",
  publicHearingDate: new Date(),
  adoptionDate: new Date(),
  adoptionGovDate: new Date(),
  legislativeApproval: "",
  legislativeDisapproval: "",
  govApproval: "",
  finalAdoptionDate: new Date(),
  effectiveDate: new Date(),
  supersededRules: "",
  supersededDocketNumber: "", 
  supersededEmergencyActions: "N/A",
  gubernatorialApproval: "",
  registerPublication: "",
  docketSelect: Docket,
  docketSelectId: -1,
  docketSelectNumber: "N/A",
  incorporatedRef: "N/A",
  incorporatedStandards: "",
  incorporatedRules: "",
  availability: "",
  gistAnalysis: "",
  contactPerson: "",
  enactingClause: `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING RULES ARE CONSIDERED FINALLY ADOPTED AS SET FORTH IN 75 O.S., SECTIONS 250.3(5) AND 308(E), WITH AN EFFECTIVE DATE OF ${props.formatDate(new Date())}:`,
  liaison: Contact,
  liaisonId: 0,
  attestation: Contact,
  attestationId: 0,
  transmittalSheet: [{ guid: "", filename: "" }],
  attestationSheet: [{ guid: "", filename: "" }],
  draftId: null,
  draft: null
});

export let PermanentForm = React.forwardRef(
  (props: IPermanentFormProps, ref) => {
    const [formValues, handleChange] = useFormState(
      { ...getInitialState(props), ...props.filing?.entity },
      ref
    );

    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const [proposedRulesText, setProposedRulesText] = React.useState<any>();

    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    const setLiaisonListFromRuleId = React.useCallback(async (ruleId: number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    }, [props]
    );

    const setAttestationListFromRuleId = React.useCallback(async (ruleId: number) => {
      const attestation = await props.returnContactListAsync(ruleId, true);
      setAttestationContactList(attestation);
    }, [props]
    );

    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        if (
          !Object.values(PermanentFinalRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            PermanentFinalRulemakingAction.PermanentFinal;
        }
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);
        setAttestationListFromRuleId(props.ruleList[0]?.id!);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }
      } else if (props.filing) {
        if (
          !Object.values(PermanentFinalRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            PermanentFinalRulemakingAction.PermanentFinal;
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
        setAttestationListFromRuleId(entity.rule.id);
      }
      if (props.filing?.isReadOnly) {
        setReadOnly(props.filing.isReadOnly);
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, date: Date | null) => {
      handleChange(event, name, date);
      if (name === "effectiveDate") {
        let enactingClause = `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING RULES ARE CONSIDERED FINALLY ADOPTED AS SET FORTH IN 75 O.S., SECTIONS 250.3(7) AND 308(E), WITH AN EFFECTIVE DATE OF ${props.formatDate(date)}:`;
        handleChange(event, "enactingClause", enactingClause);
      }
    };

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i: any) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
          setAttestationListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const handleDraftSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);

          let rulesText = await props.getProposedRules(draft.id);
          setProposedRulesText(rulesText);
          handleChange(event, "rules", rulesText);
        }
        handleChange(event, "draftName", value);
      }
    };

    const handleProposedRulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value as string;
      setProposedRulesText(value);
      handleChange(event, "rules", value);
    };

    const handleDocketSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.docketList.find((i) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "docket", rule);
          handleChange(event, "docketId", rule.id);
          handleChange(event, "docketNumber", `${rule.year}-${rule.documentNumber}`);
        }
      }
    };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        if (name === "liaisonId") {
          contact = ruleContactList.find((i: any) => i.id === rawValue);
        } else {
          contact = attestationContactList.find((i: any) => i.id === rawValue);
        }
        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
            handleChange(event, "liaisonId", contact.id);
          } else {
            handleChange(event, "attestation", contact);
            handleChange(event, "attestationId", contact.id);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleIncorpSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string
    ) => {
      value === "Yes"
        ? handleChange(event, "incorporatedRef", "Yes")
        : handleChange(event, "incorporatedRef", "N/A");
    };

    const handlePublicHearingSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string
    ) => {
      value === "Yes"
        ? handleChange(event, "publicHearing", "Yes")
        : handleChange(event, "publicHearing", "N/A");
    };

    const handleSupersededSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string | null
    ) => {
      value === "Yes"
        ? handleChange(event, "supersededEmergencyActions", "Yes")
        : handleChange(event, "supersededEmergencyActions", "N/A");
    };

    const handleRulemakingSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      value: string
    ) => {
      handleChange(event, name, value);
    };

    const currId = uuid();

    const onTransmittalSheetUpload = (guid: string, filename: string) => {
      let index = formValues.transmittalSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.transmittalSheet.push(uploadObject);
      } else {
        formValues.transmittalSheet[index] = uploadObject;
      }
    };

    const onAttestationSheetUpload = (guid: string, filename: string) => {
      let index = formValues.attestationSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.attestationSheet.push(uploadObject);
      } else {
        formValues.attestationSheet[index] = uploadObject;
      }
    };

    const onTransmittalSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var transmittalData = formValues.transmittalSheet.filter((obj: any) => { return obj.guid !== guid; });
          formValues.transmittalSheet = transmittalData;
          handleChange(null, 'transmittalSheet', transmittalData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    const onAttestationSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var attestationData = formValues.attestationSheet.filter((obj: any) => { return obj.guid !== guid; });
          formValues.attestationSheet = attestationData;
          handleChange(null, 'attestationSheet', attestationData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    return (
      <FormProvider values={formValues} onChange={handleChange}>
        <div className="flex-column">
          <Typography variant="h6">PERMANENT FILING</Typography>
          <TextField
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row>
            <Select
              label="TITLE"
              name="rule"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem className="drop-down-item" key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>
          <Row>
            <Select
              label="DRAFTS"
              name="draft"
              value={formValues.draftId ?? 0}
              onChange={handleDraftSelectChange}
              disabled={readOnly}
            >
              {props.renderDraftDropdown}
            </Select>
          </Row>
          <Select
            name="rulemakingAction"
            label="RULEMAKING ACTION"
            defaultValue={RulemakingActions.PermanentFinalAdoption}
            onChange={handleRulemakingSelectChange}
            disabled={readOnly}
          >
            <MenuItem value={RulemakingActions.PermanentFinalAdoption}>{RulemakingActions.PermanentFinalAdoption}</MenuItem>
          </Select>
          <TextField
            name="rules"
            label="RULES"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
            value={proposedRulesText}
            onChange={handleProposedRulesChange}
          />
          <TextField
            name="authority"
            label="AUTHORITY"
            multiline
            rows="3"
            fullWidth
            disabled={readOnly}
          />
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">
              SUBMISSION OF PROPOSED RULES TO GOVERNOR AND CABINET SECRETARY
            </FormLabel>

            <DatePicker
              name="submitGovCabDate"
              // disabled
              showPopperArrow={false}
              className="date-picker"
              selected={new Date(formValues.submitGovCabDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "submitGovCabDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <Row>
            <div className="date-picker-wrapper">
              <FormLabel className="label-date">
                COMMENT PERIOD: START DATE
              </FormLabel>
              <DatePicker
                name="commentBeginDate"
                showPopperArrow={false}
                className="date-picker"
                selected={new Date(formValues.commentBeginDate)}
                onChange={(date: Date, event: React.ChangeEvent<any>) =>
                  handleDateChange(event, "commentBeginDate", date)
                }
                dateFormat="MMMM d, yyyy"
                disabled={readOnly}
              />
            </div>
            <div className="date-picker-wrapper">
              <FormLabel className="label-date">
                COMMENT PERIOD: END DATE
              </FormLabel>
              <DatePicker
                name="commentEndDate"
                showPopperArrow={false}
                className="date-picker"
                selected={new Date(formValues.commentEndDate)}
                onChange={(date: Date, event: React.ChangeEvent<any>) =>
                  handleDateChange(event, "commentEndDate", date)
                }
                dateFormat="MMMM d, yyyy"
                disabled={readOnly}
              />
            </div>
          </Row>
          <FormGroup className="form-group-checkbox">
            <Select
              name="publicHearing"
              label="PUBLIC HEARING"
              defaultValue={"N/A"}
              onChange={handlePublicHearingSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </Select>
            {formValues.publicHearing === "Yes" ? (
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">PUBLIC HEARING</FormLabel>
                <DatePicker
                  name="publicHearingDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.publicHearingDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) =>
                    handleDateChange(event, "publicHearingDate", date)
                  }
                  dateFormat="MMMM d, yyyy"
                  disabled={readOnly}
                />
              </div>
            ) : null}
          </FormGroup>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">ADOPTION</FormLabel>
            <DatePicker
              name="adoptionDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.adoptionDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "adoptionDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">
              SUBMISSION OF ADOPTED RULES TO GOVERNOR AND LEGISLATURE
            </FormLabel>
            <DatePicker
              name="adoptionGovDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.adoptionGovDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "adoptionGovDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <TextField
            name="legislativeApproval"
            multiline
            rows="3"
            width="medium"
            label="LEGISLATIVE APPROVAL (if applicable)"
            disabled={readOnly}
          />
          <TextField
            name="legislativeDisapproval"
            multiline
            rows="3"
            width="medium"
            label="LEGISLATIVE DISAPPROVAL (if applicable)"
            disabled={readOnly}
          />
          <TextField
            name="govApproval"
            label="APPROVED BY GOVERNORS DECLARATION (if applicable)"
            multiline
            rows="3"
            width="medium"
            disabled={readOnly}
          />
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">FINAL ADOPTION</FormLabel>
            <DatePicker
              name="finalAdoptionDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.finalAdoptionDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "finalAdoptionDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">EFFECTIVE</FormLabel>
            <DatePicker
              name="effectiveDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.effectiveDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) =>
                handleDateChange(event, "effectiveDate", date)
              }
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <FormGroup className="form-group-checkbox">
            <Select
              name="supersededEmergencyActions"
              label="SUPERSEDED EMERGENCY ACTIONS"
              defaultValue={"N/A"}
              onChange={handleSupersededSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            {formValues.supersededEmergencyActions === "Yes" ? (
              <React.Fragment>
                <TextField
                  width="medium"
                  name="supersededRules"
                  label="SUPERSEDED RULES"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
                <TextField
                  variant="outlined"
                  name="gubernatorialApproval"
                  label="GUBERNATORIAL APPROVAL"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
                <TextField
                  variant="outlined"
                  name="registerPublication"
                  label="REGISTER PUBLICATION"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
                <TextField
                  variant="outlined"
                  name="supersededDocketNumber"
                  label="DOCKET NUMBER"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
              </React.Fragment>
            ) : null}
          </FormGroup>
          <FormGroup className="form-group-checkbox">
            <Select
              name="incorporatedRef"
              label="INCORPORATIONS BY REFERENCE"
              defaultValue={"N/A"}
              onChange={handleIncorpSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            {formValues.incorporatedRef === "Yes" ? (
              <React.Fragment>
                <TextField
                  variant="outlined"
                  name="incorporatedStandards"
                  label="INCORPORATED STANDARDS"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
                <TextField
                  variant="outlined"
                  name="incorporatedRules"
                  label="INCORPORATING RULES"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
                <TextField
                  variant="outlined"
                  name="availability"
                  label="AVAILABLITY"
                  fullWidth
                  multiline
                  rows="5"
                  disabled={readOnly}
                />
              </React.Fragment>
            ) : null}
          </FormGroup>
          <TextField
            name="gistAnalysis"
            label="GIST/ANALYSIS"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="contactPerson"
            label="CONTACT PERSON"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="enactingClause"
            label="ENACTING CLAUSE"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <Select
            name="liaisonId"
            label="AGENCY LIAISON"
            onChange={handleContactSelectChange}
          >
            {ruleContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderLiaisonDropdown} */}
          </Select>

          <FormLabel>TRANSMITTAL SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.transmittalSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onTransmittalSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onTransmittalSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>

          <Select
            name="attestationId"
            label="ATTESTATION OFFICER"
            onChange={handleContactSelectChange}
            disabled={readOnly}
          >
            {attestationContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderContactDropdown} */}
          </Select>
          <FormLabel>ATTESTATION SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.attestationSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onAttestationSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onAttestationSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
        </div>
      </FormProvider>
    );
  }
);
