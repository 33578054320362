import internal from "stream";
import { Identifiable } from "./Identifiable";

export class Draft extends Identifiable {
    constructor(
        draftName: string,
        titleNumber: string,
        chapterNumber: string,
        createdBy: number,
        createdOn: Date,
        isEditable: boolean,
        draftStatusId: number = 1
    ) {
        super(null);
        this.draftName = draftName;
        this.titleNumber = titleNumber;
        this.chapterNumber = chapterNumber;
        this.createdBy = createdBy;
        this.createdOn = createdOn;
        this.isEditable = isEditable;
        this.draftStatusId = draftStatusId;
    }

    public draftName: string;
    public titleNumber: string;
    public chapterNumber: string;
    public createdBy: number;
    public createdOn: Date;
    public isEditable: boolean;
    public draftStatusId: number;
}