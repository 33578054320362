import { useEffect, useState } from "react";
import { draftAPI, filingAPI, ruleAPI, segmentAPI } from "../../../utils/api";
import { Filing } from "../../../utils/model";

export const useEmergencyCodeController = (setDraftData: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChapter, setLoadingChapter] = useState<boolean>(false);
  const [emergencyTitles, setEmergencyTitles] = useState<any[]>([]);
  const [emergencyChapters, setEmergencyChapters] = useState<any[]>([]);
  const [titleExpanded, setTitleExpanded] = useState("");
  const [chapterExpanded, setChapterExpanded] = useState("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [effectiveDate, setEffectiveDate ] = useState<Date>();
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [filing, setFiling] = useState<Filing>();


  useEffect(() => {
    setLoading(true);
    async function getEmergencyRules() {
      let titles = await ruleAPI.getAllRulesWithEmergency(
        { pageNumber: 1, pageSize: 500 },
        false
      );
      setEmergencyTitles(titles);
    }
    getEmergencyRules();
    setLoading(false);
  }, []);

  const toggleDraftModal = async (id?: number) => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);

  };

  const getChaptersByTitle = async (id: number) => {
    let chapters = await segmentAPI.GetChapterSegmentsWithEmergencyByParentId(id);
    setEmergencyChapters(chapters);
  };

  const handleTitleChange = (segmentId: any) => (event: any, newExpanded: any) => {
      setEmergencyChapters([]);
      getChaptersByTitle(segmentId);
      setTitleExpanded(newExpanded ? segmentId : false);
    };

  const handleChapterChange = (segmentId: any, filingId: any) => async (event: any, newExpanded: any) => {
      setLoadingChapter(true);
      setEffectiveDate(undefined);
      setExpirationDate(undefined);

      setChapterExpanded(newExpanded ? segmentId : false);
      setDraftData(undefined);
      try {
        let filing = await filingAPI.getFilingsById(filingId);
        setFiling(filing);

        let effectiveDate:Date;
        let expirationDate: Date;

        if (filing.effectiveOn!== null) {
          effectiveDate = new Date(filing.effectiveOn!);
          expirationDate = new Date(filing.entity?.expirationDate);

          setEffectiveDate(effectiveDate);
          setExpirationDate(expirationDate);

        }
        let draftData = await draftAPI.getDraft(filing.draftId!);
        draftData.chapterNumber = filing.chapterNumber;
        setDraftData(draftData);

      } catch (error) {
        setDraftData({});
      } finally {
        setLoadingChapter(false);
      }

    };

  return {
    loading,
    loadingChapter,
    emergencyTitles,
    emergencyChapters,
    titleExpanded,
    chapterExpanded,
    effectiveDate,
    expirationDate,
    filing,
    handleTitleChange,
    handleChapterChange,
    toggleDraftModal,
  };
};
