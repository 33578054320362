import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { DialogTitleWithExit } from "../../../../components/DialogTitleWithExit";
import { RichTextEditor } from "../../../../components/RichTextEditor";
import { PostsAPI } from "../../../../utils/api/PostsApi";
import { EditorType } from "../../../../utils/enums/EditorType";
import { PostStatus } from "../../../../utils/enums/PostStatus";
import { Post } from "../../../../utils/model/Post";
import "./PostsEdit.scss";

interface ICreatePostProps {
  post: Post;
  onSave: () => void;
}

export const PostsEdit = (props: ICreatePostProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>(props.post.text ?? "");
  const [value, setValue] = useState<string>(props.post.text ?? "");
  const [title, setTitle] = useState<string>(props.post.title);

  useEffect(() => {
    setText(props.post.text);
    setTitle(props.post.title);
  }, [props.post]);

  const closeAction = () => {
    setShow(false);
  };
  const openAction = () => {
    setShow(true);
  };

  const savePost = async () => {
    closeAction();
    const post = new Post(value, title, PostStatus.Active);
    post.id = props.post.id;
    await PostsAPI.updatePost(post);
    props.onSave();
  };

  const textChange = (text: string) => {
    setValue(text);
  };
  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTitle(value);
  };

  return (
    <div>
      <Dialog
        className="dialog"
        open={show}
        fullWidth={true}
        onClose={closeAction}
      >
        <DialogTitleWithExit className="dialog-title" onClose={closeAction}>
          Edit Post
        </DialogTitleWithExit>
        <DialogContent className="content" dividers>
          <div className="post-title">
            <TextField
              fullWidth
              name="PostTitle"
              variant="outlined"
              type="string"
              label="Enter Post Title"
              defaultValue={title}
              onChange={titleChange}
            />
          </div>
          {/* <BlogEditor
            title="test"
            className="test"
            text={text}
            editorType={EditorType.paragraph}
            onTextChange={textChange}
            readOnly={false}
          /> */}
          <RichTextEditor
            text={text}
            editorType={EditorType.paragraph}
            onTextChange={textChange}
            width={"auto"}
            height={"90%"}
            // saveText={savePost}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAction} color="primary">
            Cancel
          </Button>
          <Button onClick={savePost} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton>
        <EditIcon onClick={openAction} />
      </IconButton>
    </div>
  );
};
