import { StatusRecordStatus } from "../enums";
import { StatusType } from "../enums/StatusType";
import { Identifiable } from ".";
import { Contact } from "./Contact";
import { Rule } from "./Rule";

export class Owning extends Identifiable {
    constructor(name: string, contact: Contact, status: StatusType, recordStatus: StatusRecordStatus) {
        super(null);
        this.name = name;
        this.contact = contact;
        this.status = status;
        this.recordStatus = recordStatus
    }

    public name: string;
    public contact: Contact;
    public status: StatusType;
    public recordStatus: StatusRecordStatus;
    public rules: Rule[] = [];
}