import List from "@material-ui/core/List";
import React, { useEffect, useState } from "react";
import { notificationAPI } from "../../../../../../../../../utils/api";
import { Action } from "../../../../../../../../../utils/model";
import {
  Notification,
  NotificationEvent as NotificationEventModel
} from "../../../../../../../../../utils/model/subscription";
import { NoNotificationEvent } from "../NoNotificationEvent";
import { NotificationItem } from "./components/NotificationItem";
import "./NotificationEvent.scss";

interface INotificationEventProps {
  notificationEvent: NotificationEventModel;
  action: Action;
  onChange: () => void;
}

export const NotificationEvent = (props: INotificationEventProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const getNotificationsByEvent = async () => {
    const notificationsIn = await notificationAPI.getAll({
      notificationEventId: props.notificationEvent.id,
    });
    setNotifications(notificationsIn);
  };

  useEffect(() => {
      getNotificationsByEvent();
  });

  const onChange = () => {
    getNotificationsByEvent();
  };

  return (
    <div className="notification-event">
      <h2>Notifications</h2>
      <List className={"notifications-list"} aria-label="notifications list">
        {notifications.map((notification, index) => (
          <NotificationItem
            action={props.action}
            onChange={onChange}
            key={index}
            notification={notification}
          />
        ))}
      </List>

      <NoNotificationEvent action={props.action} onChange={props.onChange} />
    </div>
  );
};
