import {Notification} from '../../model/subscription';
import {BaseAPI} from '../internal';
import {APISettings} from '../../settings/APISettings';


class NotificationAPI extends BaseAPI<Notification> {
    constructor() {
        super("notification", APISettings.subscriptionsUrl);
    }

    public patch: (
        id: number,
        model: Notification
    ) => Promise<Notification> = async (id: number, model: Notification) => {
        const response = await BaseAPI.axios.patch(
            `${this.apiUrl}/${this.type}/${id}`,
            model
        );
        return response.data;
    };
}

export const notificationAPI = new NotificationAPI();
