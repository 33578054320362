import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { EmailTemplate } from "../model";
import Base from "antd/lib/typography/Base";
import { FilingStatusHistory } from "../model/FilingStatusHistory";

class FilingStatusHistoryApi extends BaseAPI<any | FilingStatusHistory> {
  constructor() {
    super("filingStatusHistory", APISettings.adminRulesApiUrl);
  }

  public GetFilingStatusHistoryByFilingId: (id:number) => Promise<FilingStatusHistory[]> = async (id: number) =>{
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/GetFilingStatusHistoryByFilingId/${id}`)
    ).data;
  }

  get: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  create: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  update: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  delete: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
}

export const filingStatusHistoryAPI = new FilingStatusHistoryApi();
