// NOTE FOR THIS COMPONENT TO WORK WE NEED THE REGISTERS TO BE IN PAIRS OF HTML AND PDF DB ENTRIES AND FILES
// IN THE BLOB STORAGE REGISTER CONTAINER

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import React, { useEffect } from "react";
import saveAs from "file-saver";
import { fileAPI, registriesAPI } from "../../../../../utils/api";
import { StorageContainer } from "../../../../../utils/enums";
import "./AllRegisters.scss";

export const AllRegisters = (props: any) => {
  const [registers, setRegisters]:any = React.useState();
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [registerCount, setRegisterCount] = React.useState<number>(0);

  const onLoad = async () => {
    let regArr:any;
    setRegisters();
    if(!registers) {
      regArr = await registriesAPI.getAllPublishedRegistries({pageNumber: (props.skip / 5 + 1), pageSize: 10});
      setRegisters(regArr);
    }
  }

  useEffect(() => {
    onLoad();
  }, [props.query])

  const onClick = async (value:string) => {

    // console.log("Registers (Public) Hit", value);
    let url: any = await fileAPI.getFileUrl(
      value,
      StorageContainer.PublicRegister
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);
    saveAs(blob, "Register_" + value);
  };

  const onClickHtml= async (htmlFileName:string) => {
      let url: any = await fileAPI.getFileUrl(
      htmlFileName,
      StorageContainer.PublicRegister
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);
    saveAs(blob, "Register_" + htmlFileName);
  }

  const _renderRegister = (value: any, index:number) => {
    return (
      // <div>
      <Card className="reg-result" key={index}>
      <CardContent className="reg-contents">
        {/* <CardActions> */}
        <Typography className="result-header-item">
          <strong>
            {value.title}
          </strong>
        </Typography>
        {/* </CardActions> */}
        <Divider />
        <div className="text-contents">
        <Typography>
            <span>HTML: </span>
            <span className="result-header-value">
              <a href="#" onClick={() => onClickHtml(value.htmlFileName)}>
                View Register {value.title}
              </a>
            {/* <CodeIcon/> */}
          </span>
          </Typography>

          <Typography >
            <span>PDF: </span>
            <span className="result-header-value">
              <a href="#" onClick={() => onClick(value.pdfFileName)}>
                Download {value.title}
              </a>
            {/* <PictureAsPdfIcon/> */}
          </span>
          </Typography>
        </div>
      </CardContent>
    </Card>
    // </div>
    )
  }


  return (
    <div>
     {registers ? (registers.map((element:any,index:number) => _renderRegister(element, index))) : null}
    </div>
  );
};
