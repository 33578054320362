import { Button, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { actionAPI } from "../../../../../../../utils/api";
import { Action } from "../../../../../../../utils/model";
import { Status } from "../../../../../../../utils/model/Status";
import { ActionExpansion } from "../ActionExpansion/ActionExpansion";
// local imports
import "./ActionList.scss";

interface IActionListProps {
  // workflow:Workflow;
  status: Status;
  nextStatusList?: Status[];
}

export const ActionList = (props: IActionListProps) => {
  const [addAction, setAddAction] = useState(false);
  const [loadingActions, setLoadingActions] = useState(true);
  const initialAction: Action[] = [];
  const [actions, setActions] = useState(initialAction);
  const [nextStatus, setNextStatus] = useState(props.status);

  // TODO: Ensure that this use effect cannot result in an infinite loop
  const onMount = React.useCallback(async () => {
    if (props.nextStatusList && nextStatus) {
      for (var i = 0; i < props.nextStatusList?.length; i++) {
        if (props.nextStatusList[i].id === props.status.id) {
          setNextStatus(props.nextStatusList[i + 1]);
        }
      }
    }

    if (props.status) {
      const actionCollection = await actionAPI.getAll({
        "status.id": props.status!.id,
      });
      setActions(actionCollection);
      setLoadingActions(false);
    }
  }, [nextStatus, props.nextStatusList, props.status]);

  useEffect(() => {
    onMount();
  }, [props.nextStatusList,onMount]);

  const addNewAction = () => {
    addAction ? setAddAction(false) : setAddAction(true);
    setTimeout(function () {
      var toFocus = document.getElementById("panel1a-newAction");
      if (toFocus) {
        toFocus.focus();
      }
    }, 300);
  };

  const triggerReload = async () => {
    const actionCollection = await actionAPI.getAll({
      "status.id": props.status!.id,
    });
    setActions(actionCollection);
  };

  return (
    <div>
      {loadingActions ? <LinearProgress /> : null}
      {actions.map((action, key) => (
        <ActionExpansion
          key={key + action.name}
          action={action}
          statusList={props.nextStatusList}
          status={props.status}
          nextStatus={nextStatus}
          triggerReload={triggerReload}
        />
      ))}
      {addAction ? (
        <ActionExpansion
          status={props.status}
          statusList={props.nextStatusList}
          nextStatus={nextStatus}
          triggerReload={triggerReload}
        />
      ) : (
        <div />
      )}
      {addAction ? (
        <Button
          size="small"
          color="secondary"
          variant="contained"
          className="cancel-action-button"
          onClick={addNewAction}
        >
          Cancel Add
        </Button>
      ) : (
        <Button
          size="small"
          color="primary"
          variant="contained"
          className="add-action-button"
          onClick={addNewAction}
        >
          Add Action
        </Button>
      )}
    </div>
  );
};
