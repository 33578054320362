/**
 * It creates a modal that allows the user to create a new segment.
 * @param {AlertProps} props - IAddSegmentModal
 * @returns A div with a button inside of it.
 */
import { Input } from "@progress/kendo-react-inputs";
import { Icon, Snackbar, TextField, Tooltip } from "@material-ui/core";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import React, { Fragment, useEffect } from "react";
import { Dialog } from "../../../../../components/Dialog";
import "./AddSegmentModal.scss";
import { Info, SwapVerticalCircleSharp } from "@material-ui/icons";
import ImageFilter9 from "material-ui/svg-icons/image/filter-9";
import { SegmentType, StatusRecordStatus } from "../../../../../utils/enums";
import { segmentAPI, segmentHistoryAPI } from "../../../../../utils/api";
import { Draft, Segment, SegmentHistory, SegmentHistoryData } from "../../../../../utils/model";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { RuleDrafting } from "../../../../../utils/classes";

interface IAddSegmentModal {
  open: boolean;
  closeForm: () => void;
  segmentType?: any;
  parentData: any;
  draftHistoryData: any;
  draft: Draft;
  reloadHierarchy: () => void;
  segmentParentId: any;
  isCurrentRule?: boolean;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const AddSegmentModal = (props: IAddSegmentModal) => {
  const [parentIds, setParentIds] = React.useState("");
  const [newDescription, setDescription] = React.useState("");
  const [newId, setNewId] = React.useState("");
  const [typeCreated, setTypeCreated] = React.useState({});
  const [isValidNumber, setIsValidNumber] = React.useState<boolean>(true);

  const initalErrorMessage: string | null = null;
  const [snackErrorMessage, setErrorMessage] = React.useState<string | null>(
    initalErrorMessage
  );
  const initalSuccessMessage: string | null = null;
  const [snackSuccessMessage, setSnackSuccessMessage] = React.useState<
    string | null
  >(initalSuccessMessage);

  /**
   * @description - sets the RuleDrafting status definition instead of having it defined inline so it can also be accessed outside of this component
   */
  let ruleDrafting = new RuleDrafting();
  // filter statuses by role
  const status = ruleDrafting.oarStatuses;

  useEffect(() => {

    setTypeCreated(props.segmentType);
    let parentHierarchy = "";
    setDescription("");
    setNewId("");

    if (props.parentData) {
      let title = props.parentData.Title
        ? `Title: ${props.parentData.Title} `
        : "";
      let chapter = props.parentData.Chapter
        ? `> Chapter: ${props.parentData.Chapter} `
        : "";
      let subChapter = props.parentData.SubChapter
        ? `> SubChapter: ${props.parentData.SubChapter} `
        : "";

      parentHierarchy = title + chapter + subChapter;

      setParentIds(parentHierarchy);
    } else {
      setParentIds("No Ids found");
    }
  }, [props.parentData]);


  /**
   * This function is used to save a new segment to the database
   */
  const saveSegmentSeed = async () => {

    // validate
    let errorText: string = "";
    let hasErrors: boolean = false;

    if (newDescription === "" || newDescription === null) {
      errorText = props.segmentType.text + " Name Required";
      hasErrors = true;
    }

    if (newId === "" || newId === null) {
      errorText += hasErrors ? " | " + props.segmentType.text + " Id Required" : props.segmentType.text + " Id Required";
      hasErrors = true;
    }

    if (!isValidNumber) {
      errorText = `Please enter a valid ${props.segmentType.text} number. Refer to the ${props.segmentType.text} numbering guide found in the "info" text.`
      hasErrors = true;
    }


    if (props.segmentType.id === Number(SegmentType.Section_Subchapter) || props.segmentType.id === Number(SegmentType.Section_Chapter) || props.segmentType.id === Number(SegmentType.Section_Part)) {
      if (newId) {
        if (!newId.includes(":")) {
          // this is a section make sure it is in the proper format
          // must include at least a colon

          if (props.draft) {
            errorText += hasErrors ? " | " + `Please include the Title and Chapter/Subchapter with the Section Number in the following format:
              ${props.draft.titleNumber}:${props.draft.chapterNumber}${props.draftHistoryData.subChapterNum ? `-${props.draftHistoryData.subChapterNum}` : ''}-${newId}`
              : `Please include the Title and Chapter/Subchapter with the Section Number in the following format:
              ${props.draft.titleNumber}:${props.draft.chapterNumber}${props.draftHistoryData.subChapterNum ? `-${props.draftHistoryData.subChapterNum}` : ''}-${newId}`;
            hasErrors = true;
          } else {
            errorText += hasErrors ? " | " + `Please include the Title and Chapter/Subchapter with the Section Number in the following format:
            ${props.parentData.Title}:${props.parentData.Chapter}${props.parentData.SubChapter ? `-${props.parentData.SubChapter}` : ''}-${newId}`
              : `Please include the Title and Chapter/Subchapter with the Section Number in the following format:
            ${props.parentData.Title}:${props.parentData.Chapter}${props.parentData.SubChapter ? `-${props.parentData.SubChapter}` : ''}-${newId}`;
            hasErrors = true;
          }
        }
      }
    }


    if (hasErrors) {
      setErrorMessage(errorText);
      return;
    }


    var newSegment = new Segment(
      newDescription,
      props.segmentType.text === "Title" ? newId : props.parentData.Title,
      props.isCurrentRule ? StatusRecordStatus.Active : StatusRecordStatus.WIP,
      props.segmentType.id,
      props.segmentType.text === "Chapter" ? newId : props.parentData.Chapter,
      props.segmentType.text === "Subchapter" ? newId : props.parentData.SubChapter,
      props.segmentType.text === "Section" ? newId : null,
      props.segmentType.text === "Appendix" ? newId : null,
      props.segmentType.text === "Part" ? newId : null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      props.segmentParentId,
      null,
      12,
      null,
      null,
      new Date(),
      null,
      false
    );
    delete newSegment.id;

    var response = await segmentAPI.createSegments(props.isCurrentRule!, newSegment);
    response.statusCode === 200 ||
      response.statusCode === 201 ||
      response.statusCode === 202
      ? setSnackSuccessMessage(`${props.segmentType.text} Save Successful!`)
      : setErrorMessage(
        `${props.segmentType.text} was not saved due to error: ${response}.`
      );

    let segmentHistoryData: SegmentHistoryData = new SegmentHistoryData(
      response.value.description,
      response.value.segmentStatusId,
      "UNDEFINED",
      response.value.text,
      [],
      [],
      "");

    if (!props.isCurrentRule) {
      let dateValue: Date = new Date();
      let segmentHistory = new SegmentHistory(
        response.value.id,
        props.draft.id!,
        segmentHistoryData,
        dateValue,
        dateValue,
        true,
        true);
      delete segmentHistory.id;


      await segmentHistoryAPI.createSegmentHistory(segmentHistory, !props.isCurrentRule!);
    }

    props.closeForm();
    updateDraft();

  };

  /**
   * It updates the draft data in the database.
   */
  const updateDraft = async () => {
    // await ruleDrafting.UpdateSegmentHistory(statuses,editFormData,description, props.draftData, files);
    props.reloadHierarchy();
  };

  /**
   * It sets the snackSuccessMessage to null and the errorMessage to null.
   * @param {React.SyntheticEvent | React.MouseEvent} event - React.SyntheticEvent | React.MouseEvent
   * @param {string} [reason] - The reason why the snackbar is being closed.
   */
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      // disable click away closures of the browser explorer page.
      return;
    }
    setSnackSuccessMessage(null);
    setErrorMessage(null);

  };

  // used for validating user input on segement numbers to ensure
  const validateSection = (value: any) => {
    const sectionTest = RegExp(/^[0-9]{1,8}:+[0-9]{1,5}-+[0-9]{1,5}-+[0-9]{1,5}(?:.?[0-9])*$/);
    const chapterSubchapterTest = RegExp(/^[0-9]{1,8}$/);
    const partTest = RegExp(/^[0-9]{1,8}$/);
    const appendixTest = RegExp(/^[a-zA-Z]{1}(?:.?[0-9]){0,3}$/);

    switch (props.segmentType.text) {
      case "Section":
        let valid = sectionTest.test(value);
        setIsValidNumber(valid)
        break;
      case "Part":
        setIsValidNumber(partTest.test(value));
        break;
      case "Chapter":
      case "Subchapter":
        setIsValidNumber(chapterSubchapterTest.test(value));
        break;
      case "Appendix":
        setIsValidNumber(appendixTest.test(value));
        break;
      default:
        setIsValidNumber(false);
    }
  }

  const updateNewId = (value: any) => {
    setIsValidNumber(false);
    validateSection(value)
    setNewId(value);
  }

  /**
   * It creates a modal that allows the user to create a new segment.
   * @returns The modalDetails function returns a div with two input fields.
   */
  const modalDetails = () => {
    return (
      <div>
        <div style={{ "display": "flex", "alignItems": "center" }}>
          <Icon>
            <Tooltip title={(
              <div>
                <div><b>How to number</b></div>
                <div>There should be no words or spaces when entering the Chapter, Subchapter, Section, Part, or Appendix numbers. See below for additional details and examples.</div>
                <br />
                <div><b>Chapter/Subchapter:</b> Number only.
                  <br />&nbsp;&nbsp;Ex. <b>"16"</b>
                </div>
                <div>
                  <b>Section:</b>
                  <br />&nbsp;&nbsp;Ex. <b>"317:1-1-1", "317:1-1-1.2"</b>
                  <br />&nbsp;&nbsp;"TitleNumber:Chapter-Subchapter-Section"
                  <br />&nbsp;&nbsp;you can also add Subsection numbers at the end
                  <br />&nbsp;&nbsp;following a period.
                </div>
                <div><b>Part:</b> Number Only
                  <br />&nbsp;&nbsp;Ex. <b>"1", "2"</b>
                </div>
                <div><b>Appendix:</b> Capitalized Letter
                  <br />&nbsp;&nbsp;Ex. <b>"A", "B.1"</b>
                  <br />&nbsp;&nbsp;You can also have a Sub-appendix number following a period.
                </div>
              </div>
            )}>
              <Info />
            </Tooltip>
          </Icon>
          Info
        </div>
        <div className="text-field">
          <Input
            className="segment-name"
            label={props.segmentType.text + " Name"}
            style={{ width: "100%" }}
            onChange={(e: any) => setDescription(e.value)}
          />
        </div>
        <div className="text-field">
          <Input
            className="segment-number"
            label={`${parentIds} > New ${props.segmentType.text} Id`}
            style={{ width: "100%" }}
            onChange={(e: any) => updateNewId(e.value)}
            valid={isValidNumber}
          />
          {!isValidNumber ? (<div>Invalid {props.segmentType.text} number.</div>) : null}
        </div>
      </div>
    );
  };

  /**
   * It returns a div with a button inside of it.
   * @returns A div with a button inside of it.
   */
  const modalActions = () => {
    return (
      <div className="save-container">
        <Button className="cancel-button" onClick={props.closeForm}>
          Cancel
        </Button>
        <Button className="saved-button" onClick={saveSegmentSeed}>
          Save {props.segmentType.text}
        </Button>
      </div>
    );
  };

  /* It returns a div with a button inside of it.
  */
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackErrorMessage ? true : false}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert severity="error">{snackErrorMessage ?? ""}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackSuccessMessage ? true : false}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert severity="success">{snackSuccessMessage ?? ""}</Alert>
      </Snackbar>
      <Dialog
        className="add-segment-modal"
        open={props.open}
        onClose={props.closeForm}
        title={
          props.draftHistoryData ? `Add New ${props.segmentType.text}` : ""
        }
        children={modalDetails()}
        actions={modalActions()}
      />
    </Fragment>
  );
};
