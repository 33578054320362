import { PublicationRecord } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

interface ModelProperties {
  [key: string]: any;
}

interface PublicationRecordQuery extends PublicationRecord {
  IncludeAllChildren: boolean;
}

class PublicationRecordApi extends BaseAPI<
  PublicationRecord,
  PublicationRecordQuery
> {
  constructor() {
    super("PublicationRecordType", APISettings.adminRulesApiUrl);
  }

  // public getAllTypes: () => Promise<any> = async () => {
  //   const url = `${this.apiUrl}/${this.type}`;
  //   const response = await BaseAPI.axios.get(url);
  //   return response.data;
  // };

  public getPublicationRecordById: (id: number) => Promise<PublicationRecord> =
    async (id: number) => {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetPublicationRecordById/${id}`)
      ).data;
    };

  public getAllPublicationRecords: () => Promise<PublicationRecord[]> =
    async () => {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetAllPublicationRecords`)
      ).data;
    };

  public getAllPublicationRecordsByType: (
    typeId: number
  ) => Promise<PublicationRecord[]> = async (typeId: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllPublicationRecordsByType?typeId=${typeId}`
      )
    ).data;
  };

  public createPublicationRecord: (
    publicationRecord: PublicationRecord
  ) => Promise<any> = async (publicationRecord: PublicationRecord) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreatePublicationRecord`,
      {
        publicationRecord,
      }
    );
    return response.data;
  };

  public removePublicationRecord: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemovePublicationRecord/${id}`
    );
    return response.data;
  };

  public updatePublicationRecord: (
    id: number,
    publicationRecord: PublicationRecord
  ) => Promise<PublicationRecord> = async (
    id: number,
    publicationRecord: PublicationRecord
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdatePublicationRecord/${id}`,
      { publicationRecord }
    );
    return response.data;
  };
}

export const PublicationRecordAPI = new PublicationRecordApi();
