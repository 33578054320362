import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import React from 'react'
import { useReportFormController } from './ReportFormController'
import { Button, CircularProgress } from '@material-ui/core'

interface IRulesRecord {
  selectedReport: string,
}

const RulesRecord = (props: IRulesRecord) => {
  const { selectedReport } = props;
  const {
    formValues,
    titles,
    chapters,
    ruleTypes,
    loading,
    // functions
    updateFormValues,
    runReport,
  } = useReportFormController();

  return (
    <div className="report-form">
      <div className="input-description">Select a Title</div>
      <DropDownList
        data={titles}
        value={formValues.rule}
        textField="title"
        dataItemKey="id"
        onChange={(event) => updateFormValues({ ...formValues, rule: event.target.value })}
      // defaultItem={{ title: "Any", id: 0 }}
      />
      <div className="input-description">Select a Chapter</div>
      <DropDownList
        data={chapters}
        value={formValues.chapter ?? {description: "Any", id: 0}}
        textField="description"
        dataItemKey="id"
        onChange={(event) => updateFormValues({ ...formValues, chapter: event.target.value })}
      // defaultItem={{ description: "Any", id: 0 }}
      />
      <div className="input-description">Rule Type</div>
      <DropDownList
        data={ruleTypes}
        value={formValues.status ?? {description: "Any", id: 0}}
        onChange={(event) => updateFormValues({ ...formValues, status: event.target.value })}
      // defaultItem={"Any"}
      />
      <div className="input-description">Start Date</div>
      <DateTimePicker
        value={formValues.startDate}
        onChange={(event) => updateFormValues({ ...formValues, startDate: event.target.value })}
      />
      <div className="input-description">End Date</div>
      <DateTimePicker
        value={formValues.endDate}
        onChange={(event) => updateFormValues({ ...formValues, endDate: event.target.value })}
      />
      <div className="report-button-group">
        {loading
          ? (
            <div className='loading-report-text'>
              <span>
                Loading
              </span>
              <CircularProgress color="primary" size={20} />
            </div>
          )
          : (
            <>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "csv")}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "pdf")}>
                Download PDF
              </Button>
            </>
          )
        }
      </div>
    </div>
  )
}

export default RulesRecord