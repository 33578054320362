import { Divider, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { Email } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import {
  addressAPI,
  contactAPI,
  contactTypeAPI,
} from "../../.../../../../../../../utils/api";
import { Authorized } from "../../../../../../../components/Authorized";
import { DialogTitleWithExit } from "../../../../../../../components/DialogTitleWithExit";
import {
  StatusRecordStatus,
  StatusType,
} from "../../../../../../../utils/enums";
import { Address } from "../../../../../../../utils/model/Address";
import { Contact } from "../../../../../../../utils/model/Contact";
import { ContactType } from "../../../../../../../utils/model/ContactType";
import { useFormControls } from "./ContactFormControls";
import "./ContactModal.scss";

interface IContactModalProps {
  open: boolean;
  editRow?: any;
  contactType: ContactType;
  handleClose: (event: any) => void;
  handleClickOpen: () => void;
}

export const ContactModal = (props: IContactModalProps) => {
  const {
    validateEmail,
    validateName,
    validatePhoneNumber,
  } = useFormControls();

  const { open, handleClickOpen, handleClose } = props;
  const [editId, setEditId] = React.useState(-1);
  const [editAddrId, setEditAddrId] = React.useState(0);
  const initialContactTypes: ContactType[] = [];
  const [contactTypes, setContactTypes] = React.useState(initialContactTypes);
  const initialContactType: ContactType = new ContactType("", "");
  const [contactType, setContactType] = React.useState(
    props.editRow ? props.editRow.ContactType : props.contactType ? props.contactType : initialContactType
  );
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackFailedOpen, setSnackFailedOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [formValues, setFormValues] = React.useState({
    emailValid: true,
    emailMessage: "Email*",
    firstNameValid: true,
    firstNameMessage: "First Name*",
    lastNameValid: true,
    lastNameMessage: "Last Name*",
    phoneValid: true,
    phoneMessage: "Phone*"
  })
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    website: "",
    phone: "",
    address: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
  });

  useEffect(() => {
    try {
      contactTypeAPI
        .getAllContactTypes()
        .then((response: any) => generateDropdown(response.result));
    } catch (err) {
      console.error("FAILED TO GET CONTACTS", err);
    }
  }, []);

  function generateDropdown(response: any) {
    let val: any = [];
    response.forEach((values: any) => {
      val.push(values);
    });
    setContactTypes(val);
  }

  function _renderDropdown() {
    let menuItems: any = [];
    contactTypes.forEach((id) => {
      if (id.id != null) {
        menuItems.push(
          <MenuItem key={id.id} value={id.id}>
            {id.type}
          </MenuItem>
        );
      }
    });
    return menuItems;
  }

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (contactTypes != null) {
      let val = contactTypes.find((i) => i.id === event.target.value);
      if (val) {
        setContactType(val);
      }
    }
    // }
  };

  const checkFormIsValid = () => {
    let validated = false;
    if (
      (
        formValues.emailValid &&
        formValues.firstNameValid &&
        formValues.lastNameValid &&
        formValues.phoneValid
      ) && (
        values.email != "" &&
        values.firstName != "" &&
        values.lastName != "" &&
        values.phone != ""
      )
    ) {
      validated = true;
    }
    return validated;
  }

  const saveForm = async (event: any) => {
    // API Call to create Contact
    // Contact types should maybe be static. Such as Default or Contact Officer type
    let address = new Address(
      values.address,
      "",
      "",
      values.city,
      values.state,
      values.country,
      parseInt(values.postalCode)
    );

    if (props.contactType) {
      let contact = new Contact(
        values.firstName,
        values.lastName,
        values.title,
        values.email,
        values.website,
        address,
        values.phone,
        StatusType.Approval,
        props.contactType,
        props.contactType.id!,
        StatusRecordStatus.Active
      );

      if (editId) {
        contact.id = editId;
        address.id = editAddrId;
        try {
          // validateEditData();
          // This ugly else if does the following
          // If you have an address allow updating
          // else if you didn't have an address but have added one then create
          // otherwise ignore the address and just save the contact
          if (editAddrId) {
            await addressAPI.updateAddress(editAddrId, address);
            delete contact.address;
          } else if (address.address1) {
            try {
              address = await addressAPI.createAddress(address);
              contact.address = address;
            } catch (error) {
              setErrorMessage("Failed to Save Address: Make sure Address is filled out");
              setSnackFailedOpen(true);
              setSnackOpen(false);
            }
          } else {
            delete contact.address;
          }


          if (checkFormIsValid()) {
            await contactAPI.updateContact(contact);
            setSnackOpen(true);
          } else {
            setErrorMessage("Please Fill Out Requried Fields");
            setSnackFailedOpen(true);
            return;
          }
        } catch (err: any) {
          console.error(err);
          setErrorMessage(err.toString())
          setSnackFailedOpen(true);
          return;
        }
      } else {
        try {
          delete contact.id;
          delete contact.address?.id;
          delete contact.contactType;
          if (contact.address?.address1 === "") {
            delete contact.address;
          }
          if (checkFormIsValid()) {
            await contactAPI.createContact(contact);
          } else {
            setErrorMessage("Please Fill Out Requried Fields")
            validateAddData();
            setSnackFailedOpen(true);
            return;
          }
        } catch (err: any) {
          console.error(err);
          setErrorMessage(err.toString())
          setSnackFailedOpen(true);
          return;
        }
      }
    }
    handleClose(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name == "email") {
      let validated = validateEmail(value);
      // set email error message if email invalid
      validated ?
        setFormValues({ ...formValues, emailValid: true, emailMessage: "Email*" }) :
        setFormValues({ ...formValues, emailValid: false, emailMessage: "Please Enter a Valid Email" });
    }
    if (name == "firstName") {
      let validated = validateName(value);
      // set email error message if email invalid
      validated ?
        setFormValues({ ...formValues, firstNameValid: true, firstNameMessage: "First Name*" }) :
        setFormValues({ ...formValues, firstNameValid: false, firstNameMessage: "Please Enter First Name" });
    }
    if (name == "lastName") {
      let validated = validateName(value);
      // set email error message if email invalid
      validated ?
        setFormValues({ ...formValues, lastNameValid: true, lastNameMessage: "Last Name*" }) :
        setFormValues({ ...formValues, lastNameValid: false, lastNameMessage: "Please Enter Last Name" });
    }
    if (name == "phone") {
      // console.log("for some reason hitting this")
      let validated = validatePhoneNumber(value);
      // set email error message if email invalid
      validated ?
        setFormValues({ ...formValues, phoneValid: true, phoneMessage: "Phone*" }) :
        setFormValues({ ...formValues, phoneValid: false, phoneMessage: "Enter a valid phone number  (###) ###-#### or ###-###-####" });
    }
    setValues({ ...values, [name]: value });
  };

  // ###############################################
  // Set the row data if edit button clicked
  // ###############################################
  useEffect(() => {
    // clear values unless dialog is opened
    setValues({
      firstName: "",
      lastName: "",
      title: "",
      email: "",
      website: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
    });
    setEditAddrId(0);
    setEditId(0);

    if (props.editRow.id && props.open) {
      setValues({
        firstName: props.editRow.firstName,
        lastName: props.editRow.lastName,
        title: props.editRow.title,
        email: props.editRow.email,
        website: props.editRow.website,
        phone: props.editRow.phone,
        address: props.editRow?.address?.address1,
        city: props.editRow?.address?.city,
        country: props.editRow?.address?.country,
        state: props.editRow?.address?.state,
        postalCode: props.editRow?.address?.postalCode,
      });

      validateEditData();

      setContactType(props.editRow.contactType);
      setEditAddrId(props.editRow?.address?.id);
      setEditId(props.editRow.id);
    } else {
      setFormValues({
        emailValid: true,
        emailMessage: "Email*",
        firstNameValid: true,
        firstNameMessage: "First Name*",
        lastNameValid: true,
        lastNameMessage: "Last Name*",
        phoneValid: true,
        phoneMessage: "Phone*"
      })
    }
  }, [props.editRow, props.handleClickOpen, props.open]);

  const validateEditData = () => {
    let nameValidated = validateName(props.editRow.firstName);
    let lastNameValidated = validateName(props.editRow.lastName);
    let emailValidated = validateEmail(props.editRow.email);
    let phoneValidated = validatePhoneNumber(props.editRow.phone);

    setFormValues({
      ...formValues,
      emailValid: emailValidated,
      emailMessage: emailValidated ? "Email*" : "Please Enter a Valid Email",
      firstNameValid: nameValidated,
      firstNameMessage: nameValidated ? "First Name*" : "Please Enter First Name",
      lastNameValid: lastNameValidated,
      lastNameMessage: lastNameValidated ? "Last Name*" : "Please Enter Last Name",
      phoneValid: phoneValidated,
      phoneMessage: phoneValidated ? "Phone*" : "Enter a valid phone number  (###) ###-#### or ###-###-####"
    })
  }

  const validateAddData = () => {
    let nameValidated = validateName(values.firstName);
    let lastNameValidated = validateName(values.lastName);
    let emailValidated = validateEmail(values.email);
    let phoneValidated = validatePhoneNumber(values.phone);

    setFormValues({
      ...formValues,
      emailValid: emailValidated,
      emailMessage: emailValidated ? "Email*" : "Please Enter a Valid Email",
      firstNameValid: nameValidated,
      firstNameMessage: nameValidated ? "First Name*" : "Please Enter First Name",
      lastNameValid: lastNameValidated,
      lastNameMessage: lastNameValidated ? "Last Name*" : "Please Enter Last Name",
      phoneValid: phoneValidated,
      phoneMessage: phoneValidated ? "Phone*" : "Enter a valid phone number  (###) ###-#### or ###-###-####"
    })
  }

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleSnackFailedClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackFailedOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">Save Successful</Alert>
      </Snackbar>
      <Snackbar
        open={snackFailedOpen}
        autoHideDuration={2000}
        onClose={handleSnackFailedClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Add Contact
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <DialogContentText>Contact Info</DialogContentText>
          {/* <Select
            margin="dense"
            labelId="label"
            name="contactSelect"
            value={contactType.id != undefined ? contactType.id : 1}
            onChange={handleSelectChange}
            fullWidth
          >
            {_renderDropdown()}
          </Select> */}
          <TextField
            autoFocus
            margin="dense"
            name="firstName"
            label={formValues.firstNameMessage}
            onChange={handleChange}
            value={values.firstName}
            type="text"
            error={formValues.firstNameValid ? false : true}
          />
          <TextField
            autoFocus
            margin="dense"
            name="lastName"
            label={formValues.lastNameMessage}
            onChange={handleChange}
            value={values.lastName}
            type="text"
            error={formValues.lastNameValid ? false : true}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            onChange={handleChange}
            value={values.title}
            type="text"
          /> */}
          <TextField
            autoFocus
            margin="dense"
            name="email"
            label={formValues.emailMessage}
            onChange={handleChange}
            value={values.email}
            type="email"
            fullWidth
            error={formValues.emailValid ? false : true}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            name="website"
            label="Website"
            onChange={handleChange}
            value={values.website}
            type="string"
            fullWidth
          /> */}
          <TextField
            autoFocus
            margin="dense"
            name="phone"
            label={formValues.phoneMessage}
            onChange={handleChange}
            value={values.phone}
            type="tel"
            fullWidth
            error={formValues.phoneValid ? false : true}
          />
        </DialogContent>
        <DialogContent>
          <DialogContentText>Address</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="address"
            label="Street"
            onChange={handleChange}
            value={values.address}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="city"
            label="City"
            onChange={handleChange}
            value={values.city}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="state"
            label="State"
            onChange={handleChange}
            value={values.state}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="country"
            label="Country"
            onChange={handleChange}
            value={values.country}
            type="text"
          />
          <TextField
            autoFocus
            margin="dense"
            name="postalCode"
            label="Postal Code"
            onChange={handleChange}
            value={values.postalCode}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Authorized>
            <Button
              onClick={(event) => {
                saveForm(event);
              }}
              color="primary"
            >
              Save
            </Button>
          </Authorized>
        </DialogActions>
      </Dialog>
    </div>
  );
};
