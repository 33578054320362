import { useEffect, useRef, useState } from "react";
import {
  codificationHistoryAPI,
  segmentTypeAPI,
} from "../../../../../../utils/api";
import { savePDF } from "@progress/kendo-react-pdf";
import { SegmentStatus } from "../../../../../../utils/enums";

export const useHistoryController = (segment: any | null, open: boolean) => {
  const [historyItems, setHistoryItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getSegmentHistory = async () => {
      if (segment && open) {
        // get segment history
        try {
          setLoading(true);
          var history =
            await codificationHistoryAPI.getCodificationHistoryBySegmentId(
              segment?.id
            );
          setHistoryItems(history);
          var segmentStatuses = await setLoading(false);
        } catch (error) {
          throw error;
        }
      }
    };

    getSegmentHistory();
  }, [segment, open]);

  const getSegmentTypeNumber = (
    historicalRecord: any,
    includeName: boolean
  ) => {
    let response = "";
    switch (segment.name) {
      case "Section":
        if (includeName) {
          return `Section ${historicalRecord.originalSectionNumber}`;
        } else {
          return historicalRecord.originalSectionNumber;
        }
      case "Chapter":
        if (includeName) {
          return `Chapter ${historicalRecord.originalChapterNumber}`;
        } else {
          return historicalRecord.originalChapterNumber;
        }
      case "Part":
        if (includeName) {
          return `Part ${historicalRecord.originalPartNumber}`;
        } else {
          historicalRecord.originalPartNumber;
        }
      case "Subchapter":
        if (includeName) {
          return `Subchapter ${historicalRecord.originalSubchapterNumber}`;
        } else {
          return historicalRecord.originalSubchapterNumber;
        }
      case "Appendix":
        if (includeName) {
          return `Appendix ${historicalRecord.originalAppendixNumber}`;
        } else {
          return historicalRecord.originalAppendixNumber;
        }
      default:
        return "";

        return response;
    }
  };

  const getSegmentStatus = (history: any) => {
    switch (history.originalSegmentStatusId) {
      case SegmentStatus.New:
        return " [NEW]";
      case SegmentStatus.Revoked:
        return " [REVOKED]";
      case SegmentStatus.Reserved:
        return " [RESERVED]";
      case SegmentStatus.Expired:
        return " [EXPIRED]";
      case SegmentStatus.Amended:
        return " [AMENDED]";
      case SegmentStatus.AmendedRenumbered:
        return " [AMENDED AND RENUMBERED]";
      case SegmentStatus.Renumbered:
        return " [RENUMBERED]";
      case SegmentStatus.Superseded:
        return " [SUPERSEDED]";
      case SegmentStatus.Terminated:
        return " [TERMINATED]";
      case SegmentStatus.Transferred:
        return " [TRANSFERRED]";
      case SegmentStatus.Other:
        return " [OTHER]";
      case SegmentStatus.Published:
        return " [PUBLISHED]";
      default:
        return "";
        break;
    }
  };

  return {
    // functions
    getSegmentTypeNumber,
    getSegmentStatus,
    // variables
    historyItems,
    loading,
  };
};
