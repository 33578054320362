import { FilingHistory } from "../model/FilingHistory";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class FilingHistoryApi extends BaseAPI<any | FilingHistory> {
  constructor() {
    super("filingHistory", APISettings.adminRulesApiUrl);
  }
  get: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  create: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  update: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  delete: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
}

export const filingHistoryAPI = new FilingHistoryApi();
