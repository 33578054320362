import React, { Fragment, useEffect } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI, ruleAPI, segmentAPI, segmentHistoryAPI } from "../../../../../utils/api";
import "./CurrentRulesPreviewModal.scss";
import { GridPDFExport, savePDF } from "@progress/kendo-react-pdf";
import { NoteType, SegmentType as SegmentTypes } from "../../../../../utils/enums";
import { noteTypesAPI } from "../../../../../utils/api/NoteTypeApi";
import { LinearProgress } from "@material-ui/core";
import ImageFilter9Plus from "material-ui/svg-icons/image/filter-9-plus";
import { Alert } from "@material-ui/lab";

interface ICurrentRulesPreviewModal {
    open: boolean;
    close: () => void;
    titleData: any;
    // parentData:
}

export const CurrentRulesPreviewModal = (props: ICurrentRulesPreviewModal) => {
    const [html, setHtml] = React.useState<string>("");
    const [children, setChildren] = React.useState<any>();
    const [noteTypes, setNoteTypes] = React.useState<any>();
    const [titleSegments, setTitleSegments] = React.useState<any>([]);
    const [title, setTitle] = React.useState<any>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    const onMount = async () => {
        // console.log(props.titleData);
        let titleData = await ruleAPI.getRuleSegmentId(props.titleData.id);
        // console.log(titleData);
        setTitle(titleData);
        let segments: any = await segmentAPI.getSegmentsByTitleNum(titleData.referenceCode);
        setTitleSegments(segments);
        let noteTypeData = await noteTypesAPI.getAllNoteTypes();
        setNoteTypes(noteTypeData);
        setIsLoading(false);
        if (segments.length == 0) {
            setError(true);
        } else {
            setError(false);
        }
    }


    useEffect(() => {
        if (props.open) {
            onMount();
        }
        // clean up
        return () => {
            setTitleSegments([]);
            setTitle(null);
            setNoteTypes(null);
            setError(false);
            setIsLoading(true);
        }
    }, [props.open])


    const _renderPreview = (element: any) => {
        let JSXElement: JSX.Element = (<div></div>)
        // console.log("trying to preview", element);
        if (element.text) {
            JSXElement = <Paragraph value={element.text} className="segment-paragraph" />
        }
        return JSXElement;
    }
    const insertLabel = (note: string, insertText: string) => {
        if (note.indexOf('<p>') == 0) {
            return note.replace('<p>', `<p>${insertText}`);
        } else if (note.indexOf("<div>") == 0) {
            return note.replace("<div>", `<i>${insertText}`).replace('</div>', '</i>');
        }

        return `${"<span><strong>EDITOR'S NOTE: </strong></span>"}${note}`;

    };
    const _renderNotes = (segment: any, noteBeforeSegment: boolean) => {
        if (!segment.segmentNotes && segment.segmentNotes.length > 0) {
            return '';
        }

        let notes = segment.segmentNotes;
        let textBody: any;
        let editorsNotes: any = [];
        let sourceNotes: any = [];
        let authorityNotes: any = [];
        let footNotes: any = [];
        let agencyNotes: any = [];

        // console.log('notes - ', notes)

        // // Order in which note types should appear
        // //  1. Footnote
        // //  2. Source
        // //  3. Editors
        // //  4. Agency
        // //  5. Authority

        notes.forEach((note: any, index: number) => {
            //   var found = null;
            //   found = noteType?.find((a:any) => a == note.noteTypeId);
            //   if(found) {
            if (note.note != "") {
                if (note.noteTypeId == NoteType.Source && !noteBeforeSegment) {
                    sourceNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={note.note} className="inline-note-text" />
                        </div>
                    );
                } else if (note.noteTypeId == NoteType.Editors && !noteBeforeSegment) {
                    // TODO: seperate
                    editorsNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={insertLabel(note.note, "<span><strong>EDITOR'S NOTE: </strong></span>")} className="inline-note-text" />
                        </div>
                    );
                    // TODO: combined (After discussing with OK they want them seperate)
                    // editorsNotes.push(
                    //     <div className="inline-note-text">
                    //         <Paragraph value={note.note} className="inline-note-text" />
                    //     </div>
                    // );
                } else if (note.noteTypeId == NoteType.Authority && !noteBeforeSegment) {
                    authorityNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={note.note} className="inline-note-text" />
                        </div>
                    );
                } else if (note.noteTypeId == NoteType.Footnote && !noteBeforeSegment) {
                    footNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={note.note} className="inline-note-text" />
                        </div>
                    )
                } else if (note.noteTypeId == NoteType.Agency && !noteBeforeSegment) {
                    agencyNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={note.note} className="inline-note-text" />
                        </div>
                    );
                } else if (note.noteTypeId == NoteType.Footnote && !noteBeforeSegment) {
                    footNotes.push(
                        <div className="inline-note-text">
                            <Paragraph value={note.note} className="inline-note-text" />
                        </div>
                    );
                }
            }
        })

        textBody = (
            <div>
                {footNotes.length > 0 ? (
                    <div className="note">
                        {footNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
                    </div>
                ) : null}
                {authorityNotes.length > 0 ? (
                    <div className="note">
                        <span className="inline-note-text">[<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]</span>
                    </div>
                ) : null}
                {sourceNotes.length > 0 ? (
                    <div className="note">
                        <span className="inline-note-text">[<b>Source: </b>{sourceNotes.map((item: any, index: number) => { if (item != "") return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}]</span>
                    </div>
                ) : null}
                {editorsNotes.length > 0 ? (
                    <div className="note">
                        {/*TODO: Display inline */}
                        <i><span><strong>EDITOR'S NOTE: </strong></span></i>
                        {editorsNotes.map((item: any, index: number) => {
                            if (item != "") {
                                return (
                                    // TODO:
                                    // <div className={`note-text`}>
                                    <i>
                                        {item}
                                    </i>
                                    // TODO:
                                    // </div>
                                );
                            }
                        })}
                    </div>
                ) : null}
                {agencyNotes.length > 0 ? (
                    <div className="note">
                        {agencyNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < agencyNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
                    </div>
                ) : null}

            </div>
        )

        return textBody;
    }

    const getSegmentNumber = (segmentData: any) => {
        switch (segmentData.segmentTypeId) {
            case SegmentTypes.Title:
                return segmentData.titleNum;
            case SegmentTypes.Chapter:
                return segmentData.chapterNum;
            case SegmentTypes.Subchapter:
                return segmentData.subChapterNum;
            case SegmentTypes.Appendix:
                return segmentData.appendixNum;
            case SegmentTypes.Part:
                return segmentData.partNum;
            case SegmentTypes.Section_Chapter:
            case SegmentTypes.Section_Part:
            case SegmentTypes.Section_Subchapter:
                return segmentData.sectionNum;
            case SegmentTypes.ExecutiveOrder:
                return `${segmentData.execOrderNum}-${segmentData.execOrderYear}`;
            default:
                return '';
        }
    };

    const _renderStatusName = (segment: any) => {
        let descriptionToRender = segment.description ?? "";
        let text = segment.name;
        let data = segment;
        if (!descriptionToRender) {
            // this first if condition is to handle the executive orders for the current rules page
            if (data.statusName != "Undefined" && data.statusName) {
                text = data.name + " " + getSegmentNumber(data) + " [" + data.statusName + "]"
            } else {
                text = data.name + " " + getSegmentNumber(data)
            }
        } else if (data.statusName != "Undefined" && data.statusName) {
            if (
                data.statusName.toUpperCase() == "AMENDEDANDRENUMBERED" ||
                data.statusName.toUpperCase() == "AMENDED" ||
                data.statusName.toUpperCase() == "RENUMBERED"
            ) {
                if (data.statusName.toUpperCase() == "AMENDEDANDRENUMBERED") {
                    text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender + " [" + "AMENDED AND RENUMBERED To " + data.renumberedToSegmentNumber + "]"
                } else {
                    text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender + " [" + data.statusName.toUpperCase() + " To " + data.renumberedToSegmentNumber + "]"
                }
            } else {
                text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender + " [" + data.statusName.toUpperCase() + "]"
            }
        } else {
            text = data.name + " " + getSegmentNumber(data) + ". " + descriptionToRender;

        }

        return text;
    }

    const _renderHeader = (segment: any) => {
        let JSXElement: JSX.Element = <div></div>;
        if (segment.name == "Chapter") {
            JSXElement = (<Paragraph value={`${segment.name.toUpperCase()} ${segment.chapterNum}. ${segment.description.toUpperCase()} `} className="segment-header" />)
        }
        if (segment.name == "Subchapter") {
            JSXElement = (<Paragraph value={`${segment.name.toUpperCase()} ${segment.subChapterNum}.  ${segment.description.toUpperCase()} ${segment.statusName != "Undefined" ? _renderStatusName(segment).toUpperCase() : ""}`} className="segment-header" />)
        }
        if (segment.name == "Part") {
            JSXElement = (<Paragraph value={`${segment.name.toUpperCase()} ${segment.partNum}.  ${segment.description.toUpperCase()} ${segment.statusName != "Undefined" ? _renderStatusName(segment).toUpperCase() : ""}`} className="segment-header" />)
        }
        if (segment.name == "Section") {
            JSXElement = (<Paragraph value={`${segment.sectionNum}. ${segment.description} ${segment.statusName != "Undefined" ? _renderStatusName(segment) : ""}`} className="segment-section-header" />)
        }
        if (segment.name == "Executive Order") {
            JSXElement = (<Paragraph value={`${segment.execOrderNum}. ${segment.statusName != "Undefined" ? _renderStatusName(segment) : ""}`} className="segment-section-header" />)
        }
        return JSXElement;
    }

    let container: any = React.useRef<HTMLDivElement>(null);

    const pdfDownload = () => {
        let element = container.current;

        savePDF(element, {
            paperSize: "a4",
            margin: 40,
            fileName: `Title-${title.referenceCode}_${title.title}`,
            scale: 0.65
        });
    }

    function printDiv() {
        var divContents = document.getElementById("print-container")!.innerHTML;
        var printWindow: any = window.open("", "", "");
        printWindow.document.write(`<html><head><style type="text/css">
        @media print{
            .note {
                // display:flex;
                font-size: 11px !important;
                // margin-bottom: 12px;
                // margin-top: 12px;
            }
            .segment-title {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-section-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
            }
            .segment-item {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .preview-segment-note {
                > * {
                display: inline-block !important;
                }
                display: block !important;
                font-size: 11px !important;
                // float: left;
            }

            .center-note {
                display: flex;
                justify-content: center;
            }
            .inline-note-text {
                display: inline-block !important;
                font-size: 11pt !important;
                // float: left;
            }
            .inline-note-text > * {
                margin-bottom: 0px !important;
                margin-top: 0px !important;
                display:inline;
            }
        }
        .note {
            // display:flex;
            font-size: 11px !important;
            // margin-bottom: 12px;
            // margin-top: 12px;
        }
        .segment-title {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-section-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
        }
        .segment-item {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .preview-segment-note {
            > * {
            display: inline-block !important;
            }
            display: block !important;
            font-size: 11px !important;
            // float: left;
        }

        .center-note {
            display: flex;
            justify-content: center;
        }
        .inline-note-text {
            display: inline-block !important;
            font-size: 11pt !important;
            // float: left;
        }
        .inline-note-text > * {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            display:inline;
        }
        </style>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    const modalDetails = () => {
        return (
            <div ref={container} id="print-container">
                {title ? (
                    <Paragraph value={"TITLE " + title.referenceCode + ". " + title.title.toUpperCase()} className="segment-title" />
                ) : null}
                {
                    titleSegments.map((element: any, index: number) => {
                        return (
                            <>
                                <div key={index} className="segment-item">
                                    {_renderHeader(element)}
                                    {_renderPreview(element)}
                                    {_renderNotes(element, true)}
                                </div>
                                <div>
                                    {_renderNotes(element, false)}
                                </div>
                            </>

                        )
                    })
                }
                {isLoading ? (<LinearProgress />) : null}
                {error ? (<Alert severity="error">Error: Failed to load title data</Alert>) : null}
            </div>
        );
    }

    const modalActions = () => {
        return (
            <Button onClick={printDiv}>Print PDF</Button>
        )
    }

    return (
        <Dialog className="current-rules-preview" open={props.open} onClose={props.close} title={"Preview"} children={modalDetails()} actions={modalActions()} />
    )
}
