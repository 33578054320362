import React from "react";

import { AppBar, Tabs, Tab } from "@material-ui/core";
import "./FilingTab.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { useQueryFilingType } from "../../../../../utils/hooks";
import { FilingType } from "../../../../../utils/model";
import { UrlParser } from "./../../../../../utils/";

// TODO ROB refactor this component to use the url in place of state.

interface IAgencyAdminProps extends RouteComponentProps {
  filingTypes: FilingType[];
}

const FilingTabWithRouter = (props: IAgencyAdminProps) => {
  let tab = useQueryFilingType(props.location.search, props.filingTypes);

  // If the url is not "Notice" but one of the sub filings set the selected tab to just be Notice
  if (tab.substring(0, 6) == "Notice") {
    tab = "Notice";
  }

  if (tab.substring(0, 5) == "Adopt") {
    tab = "Adopted";
  }

  const handleChange = (event: React.ChangeEvent<{}>, item: string) => {
    props.history.push(UrlParser.Filing.parseFilingType(item));
  };

  return (
    <div className="filing-tab">
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          indicatorColor="primary"
          aria-label="Type of Filing"
          onChange={handleChange}
          scrollButtons="auto"
          variant="fullWidth"
        >
          {props.filingTypes ? props.filingTypes.map((value: FilingType) => {
            if (value.subFiling === false) {
              return (<Tab key={value.id ?? 1} label={value.alternativeName} value={value.name} />)
            }
          }
          )
            : null}
        </Tabs>
      </AppBar>
    </div>
  );
};

export const FilingTab = withRouter(FilingTabWithRouter);
