import { useEffect, useRef, useState } from "react";
import {
  codificationHistoryAPI,
  ruleAPI,
  ruleHistoryAPI,
} from "../../../../../../utils/api";

export const useTitleHistoryController = (
  referenceCode: any | null,
  open: boolean
) => {
  const [historyItems, setHistoryItems] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [title, setTitle] = useState<any>({});

  useEffect(() => {
    const getTitleHistory = async () => {
      if (referenceCode && open) {
        // get title history
        try {
          setLoading(true);
          let titleData = await ruleAPI.getRuleReferenceCode(referenceCode);
          setTitle(titleData);
          if (titleData && titleData.id) {
            let ruleHistory = await ruleHistoryAPI.getRuleHistoriesByRuleId(
              titleData.id
            );
            setHistoryItems(ruleHistory);
          }
          setLoading(false);
        } catch (error) {
          throw error;
        }
      }
    };

    getTitleHistory();
  }, [referenceCode, open]);

  return {
    // variables
    historyItems,
    loading,
    title,
  };
};
