import { Registries } from "../../../../utils/model";

interface Column {
  field: string; // create a column bound to the "name" field
  title: string; // set its title to "Name
  hidden: boolean; // used to define if a column should be rendered or not
}

export const setRegisterIssueData = (rows: any) => {
  const registries: Registries = new Registries(
    rows[0].volumeNumber,
    rows[0].issueNumber,
    rows[0].generatedTempRegisterDate,
    rows[0].anticipatedPublishingDate,
    rows[0].submissionDeadline,
    rows[0].appendixNumber,
    rows[0].publishedOn,
    rows[0].title,
    rows[0].downloadURL,
    rows[0].htmlFileName,
    rows[0].pdfFileName,
    rows[0].approvedOn,
    rows[0].acceptanceDeadline,
    rows[0].actualPublishingDate,
    rows[0].endingRegisterPageNumber,
    rows[0].registerStatusId,
  );

  let columns: any = [];
  const keys = Object.keys(registries);

  keys.forEach((key, index) => {
    // Regular expression takes table name and converts formatting
    var text = key.replace(/([A-Z])/g, " $1");
    var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
    let column: Column = { field: key, title: capitalizeText, hidden: false };

    switch (column.field) {
      case "htmlFileName":
      case "pdfFileName":
      case "created":
      case "id":
      case "createdBy":
      case "downloadURL":
      case "endingRegisterPageNumber":
      case "lastModifiedBy":
      case "lastModified":
      case "approvedOn":
      case "acceptanceDeadline":
      case "actualPublishingDate":
      case "registerStatusId":
      case "appendixNumber":
      case "sectionNumber":
      case "published":
      case "submissionDeadline":
      case "anticipatedPublishingDate":
        column.hidden = true;
        break;
    }
    columns.push(column);
  });

  columns.reverse();
  columns.sort((x: any, y: any) => {
    return x.field === "title" ? -1 : y.field === "title" ? 1 : 0;
  });

  return { rows: rows, columns: columns };
};
