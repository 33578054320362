import React from "react";
import { EditorType } from "../../utils/enums";
import { TextWrapper } from "../TextWrapper";
import "./Footer.scss";

interface IFooterProps {
  stateName: string;
  copyrightYear: number;
  footerMessage: string;
}

export const Footer = (props: IFooterProps) => {
  return (
    <div className="footer-main">
      <div className="footer-left">
        {/* <p> {props.stateName} Secretary of State</p>
        <p> {props.copyrightYear} © All Rights Reserved.</p> */}
        <TextWrapper
          editorType={EditorType.paragraph}
          name="footerCopyright"
          className="footerText"
        />
      </div>
      <div className="footer-right">
        {/* <p> {props.stateName} Secretary of State</p>
        <p> {props.copyrightYear} © All Rights Reserved.</p> */}
        <TextWrapper
          editorType={EditorType.paragraph}
          name="footerMessage"
          className="footerText"
        />
      </div>
    </div>
  );
};
