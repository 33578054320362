import React from "react";
import {
  TextField as MTextField,
  Select as MSelect,
  InputLabel,
  FormControlLabel,
  Checkbox as MCheckbox,
} from "@material-ui/core";
import { KeyboardDatePicker as MKeyboardDatePicker } from "@material-ui/pickers";
import { useId } from "@reach/auto-id";
import { useFormValue, useFormOnChange } from "../../../../../../utils/hooks";

export const FieldWrapper = ({ children, className = "", ...props }: any) => (
  <div className={`field-wrapper ${className}`} {...props}>
    {children}
  </div>
);

export const TextField = ({
  label,
  className,
  width,
  name,
  value,
  disabled,
  onChange,
  ...props
}: any) => {
  const id = useId();
  const formValue = useFormValue(name, value);
  const onValueChange = useFormOnChange();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, name, e.target.value);
    } else {
      onValueChange(e, name, e.target.value);
    }
  };

  return (
    <FieldWrapper>
      <InputLabel className="date-picker-label" shrink id={id}>
        {label}
      </InputLabel>
      <MTextField
        className={`text-input ${className}`}
        data-width={width}
        variant={disabled ? "filled" : "outlined"}
        margin="dense"
        name={name}
        value={formValue}
        onChange={handleChange}
        disabled={disabled}
        {...props}
        aria-labelledby={id?.toString()}
      />
    </FieldWrapper>
  );
};

export const Select = ({
  label,
  className = "",
  name,
  value,
  onChange,
  ...props
}: any) => {
  const id = useId();
  const formValue = useFormValue(name, value);
  const onValueChange = useFormOnChange();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, name, e.target.value);
    } else {
      onValueChange(e, name, e.target.value);
    }
  };

  return (
    <FieldWrapper>
      <InputLabel shrink id={id}>
        {label}
      </InputLabel>
      <MSelect
        className={`custom-select ${className}`}
        variant="outlined"
        margin="dense"
        name={name}
        value={formValue}
        onChange={handleChange}
        {...props}
        labelId={id}
      />
    </FieldWrapper>
  );
};

export const KeyboardDatePicker = ({
  label,
  name,
  value,
  onChange,
  ...props
}: any) => {
  const id = useId();
  const formValue = useFormValue(name, value);
  const onValueChange = useFormOnChange();

  const handleChange = (date: Date) => {
    if (onChange) {
      onChange({}, name, date);
    } else {
      onValueChange({}, name, date);
    }
  };

  return (
    <FieldWrapper className="date-picker-wrapper">
      <InputLabel className="date-picker-label" shrink id={id}>
        {label}
      </InputLabel>
      <MKeyboardDatePicker
        className="date-picker"
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        inputVariant="outlined"
        name={name}
        value={formValue}
        onChange={handleChange}
        {...props}
        id={id}
        TextFieldComponent={(textProps) => (
          <MTextField
            {...textProps}
            className={`${textProps.className} date-picker-input`}
            margin="dense"
            aria-labelledby={id}
          />
        )}
        KeyboardButtonProps={{
          "aria-label": "Select a Date",
        }}
      />
    </FieldWrapper>
  );
};
interface ICheckBoxProps {
  label: string;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value?: boolean;
  name?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    name?: string,
    value?: boolean | null
  ) => void;
  rootProps?: any;
}

export const Checkbox = ({
  label = "",
  onClick,
  checked = false,
  value = false,
  name = "",
  onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name?: string,
    value?: boolean | null
  ) => { },
  rootProps = {},
  ...props
}: ICheckBoxProps) => {
  const id = useId();
  const formValue = useFormValue(name, value);
  const onValueChange = useFormOnChange();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onClick) {
      if (onChange) {
        onChange(e, name, e.target.checked);
      } else {
        onValueChange(e, name, e.target.checked);
      }
    }
  };

  return (
    <FieldWrapper>
      <FormControlLabel
        onClick={onClick}
        control={
          <MCheckbox
            name={name}
            checked={checked}
            value={formValue}
            inputProps={{ "aria-labelledby": label }}
            onChange={onClick ? undefined : handleChange}
            {...props}
          />
        }
        label={
          <InputLabel shrink id={id} variant="standard">
            {label}
          </InputLabel>
        }
        labelPlacement="end"
        {...rootProps}
      />
    </FieldWrapper>
  );
};
