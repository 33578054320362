import { createContext, ReactNode, useEffect, useState } from "react";
import { DelayAction, DelayActions } from "../../../../../utils";
import { SearchQueryFields } from "../../../../../utils/enums/SearchQueryFields";
import { ISearchFilter } from "../../../../../utils/interfaces/ISearchFilter";
import {
  // PublicationRecordAPI,
  PublicationRecordTypeAPI,
  ruleTypeAPI,
  segmentAPI,
} from "../../../../../utils/api";
import { capitalizeText } from "../../../../../utils/utility_functions";
import { pick } from "lodash";

// Define the search context
const searchContext = createContext<{
  // context type definition
  searchQuery: ISearchFilter;
  searchQueryDefaults: ISearchFilter;
  searchResults: {}[];
  setSearchQuery: (newVal: {}) => void;
  setSearchResults: (newVal: {}[]) => void;
}>({
  // initial context values (some of which will be replaced in the provider)
  searchQuery: {},
  searchQueryDefaults: {},
  searchResults: [],
  setSearchQuery: () => {},
  setSearchResults: () => {},
});
const { Provider } = searchContext;

// Internal query search string to compare against state
let internalSearchText: string | undefined = "";

/**
 * A provider to be used in conjunction with the 'useSearch' custom hook.
 * @param props { children : ReactNode[] }
 */
const SearchProvider = ({ children }: { children: ReactNode }) => {
  // Define defaults for the search filters
  const searchQueryDefaults: ISearchFilter = {
    [SearchQueryFields.QueryString]: "",
    [SearchQueryFields.DateRangeStart]: null,
    [SearchQueryFields.DateRangeEnd]: null,

    // Advanced search Rule Making Actions
    [SearchQueryFields.RuleCurrentPermanent]: false,
    [SearchQueryFields.RuleProposedRules]: false,
    [SearchQueryFields.RuleEmergencyRules]: false,
    [SearchQueryFields.RuleExpiredEmergencyRules]: false,
    [SearchQueryFields.RuleSupercededRules]: false,
    [SearchQueryFields.RuleRevokedRules]: false,
    [SearchQueryFields.Title]: "",
    [SearchQueryFields.Agency]: "",
    [SearchQueryFields.Chapter]: "",
    [SearchQueryFields.Section]: "",
    [SearchQueryFields.Appendix]: "",

    // Advanced search Segment Types
    [SearchQueryFields.SegmentTypeId]: 0,
    [SearchQueryFields.ParentSegmentId]: 0,
  };

  const [searchQuery, setSearchQuery] =
    useState<ISearchFilter>(searchQueryDefaults);
  const [searchResults, setSearchResults] = useState<{}[]>([]);
  const [ruleActionData, setRuleActionData] = useState([]);
  const [ruleTypeData, setRuleTypeData] = useState([]);

  const modifiedData = (data: any, prefix: string) => {
    const modifiedData = data
      .map((dataType: any) => ({
        ...dataType,
        name: capitalizeText(prefix + dataType.name)
          .split(" ")
          .join(""),
      }))
      .map((dataType: any) => pick(dataType, ["id", "name"]));
    return modifiedData;
  };

  useEffect(() => {
    PublicationRecordTypeAPI.getAllPublicationRecordTypes()
      .then((data) => {
        setRuleActionData(modifiedData(data, "Rule Action"));
      })
      .catch((err) =>
        console.error(
          "%c FETCHING ALL RULE ACTION TYPES ",
          "background: red; color: black; font-size: 11px",
          err
        )
      );

    ruleTypeAPI
      .getAllRuleTypes()
      .then((data) => {
        setRuleTypeData(modifiedData(data, ""));
      })
      .catch((err) => {
        console.error(
          "%c FETCHING ALL RULE TYPES ",
          "background: red; color: black; font-size: 11px",
          err
        );
      });
  }, []);

  useEffect(() => {
    /**
     * If the search string has changes, create a delay to capture other
     *  string inputs before sending the request.
     */
    let delayTime = 0;
    if (searchQuery[SearchQueryFields.QueryString] !== internalSearchText) {
      delayTime = 500;
      internalSearchText = searchQuery[SearchQueryFields.QueryString];
    }
    DelayAction.createDelay(DelayActions.AdminRulesSearch, delayTime).then(
      async () => {
        const localSearchQuery = Object.assign({}, searchQuery);
        ruleActionData.forEach(
          (ruleAction: {
            id: number;
            name: keyof typeof SearchQueryFields;
          }) => {
            if (localSearchQuery[ruleAction.name]) {
              // @ts-ignore
              localSearchQuery[ruleAction.name] = ruleAction.id || "0";
            }
          }
        );

        ruleTypeData.forEach(
          (ruleType: { id: number; name: keyof typeof SearchQueryFields }) => {
            if (localSearchQuery[ruleType.name]) {
              // @ts-ignore
              localSearchQuery[ruleType.name] = ruleType.id || "0";
            }
          }
        );
        const results = await segmentAPI.getAll(localSearchQuery);

        // TODO remove default TEMP results
        // console.warn(
        //   "%c results ->",
        //   "background: silver; color: blue; font-size: 11px",
        //   results ? results : TEMPSearchResults
        // );
        setSearchResults(results);
      }
    );
  }, [searchQuery]);

  return (
    <Provider
      value={{
        setSearchQuery,
        searchQuery,
        searchQueryDefaults,
        searchResults,
        setSearchResults,
      }}
    >
      {children}
    </Provider>
  );
};

export { SearchProvider, searchContext };
