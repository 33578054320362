import { Button, CircularProgress } from '@material-ui/core';
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { useReportFormController } from './ReportFormController';

interface ICodeStatistics {
  selectedReport: string,
}

const CodeReport = (props: ICodeStatistics) => {
  const { selectedReport } = props;
  const { formValues, loading, updateFormValues, runReport } = useReportFormController();

  return (
    <div className="report-form">
      <div className="input-description"></div>
      <DateTimePicker
        value={formValues.startDate}
        onChange={(event) => updateFormValues({ ...formValues, startDate: event.target.value })}
      />
      <div className="input-description"></div>
      <DateTimePicker
        value={formValues.endDate}
        onChange={(event) => updateFormValues({ ...formValues, endDate: event.target.value })}
      />
      <div className="report-button-group">
        {loading
          ? (
            <div className='loading-report-text'>
              <span>
                Loading
              </span>
              <CircularProgress color="primary" size={20} />
            </div>
          )
          : (
            <>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "csv")}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "pdf")}>
                Download PDF
              </Button>
            </>
          )
        }
      </div>
    </div>
  )
}

export default CodeReport