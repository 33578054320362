import {
  Segment,
  // SegmentResult
} from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { formatDate, isFalsy } from "../../utils/utility_functions";
import { keys, startsWith } from "lodash";
import axios from "axios";

interface ModelProperties {
  [key: string]: any;
}

interface SegmentQuery extends Segment {
  IncludeAllChildren: boolean;
}

class SegmentAPI extends BaseAPI<Segment, SegmentQuery> {
  constructor() {
    super("segment", APISettings.adminRulesApiUrl);
  }

  public patch: (id: number, segment: Segment) => Promise<any> = async (
    id: number,
    segment: Segment
  ) => {
    const response = await BaseAPI.axios.patch(
      `${this.apiUrl}/${this.type}/${id}`,
      {
        id: segment.id,
        description: segment.description,
        titleNum: segment.titleNum,
        chapterNum: segment.chapterNum,
        subChapterNum: segment.subChapterNum,
        sectionNum: segment.sectionNum,
        appendixNum: segment.appendixNum,
        partNum: segment.partNum,
        execOrderNum: segment.execOrderNum,
        execOrderYear: segment.execOrderYear,
        authority: segment.authority,
        segmentTypeId: segment.segmentTypeId,
        text: segment.text,
        notes: segment.notes,
        additionalInformation: segment.additionalInformation,
        recordStatus: segment.recordStatus,
        ruleId: segment.ruleId,
        parentId: segment.parentId,
        contactId: segment.contactId,
        segmentStatusId: segment.segmentStatusId,
        filingId: segment.filingId,
        inProcessSegmentId: segment.inProcessSegmentId,
        effectiveDate: segment.effectiveDate,
      }
    );
    return response.data;
  };

  public getSegmentChildren: (parentId: number) => Promise<any> = async (
    parentId
  ) => {
    const response = await BaseAPI.axios.get(
      `${this.apiUrl}/${this.type}?ParentId=${parentId}&IncludeAllChildren=true`
    );
    return response.data;
  };

  public getAllList: (
    model?: Partial<Segment> & ModelProperties
  ) => Promise<Segment[]> = async (
    model?: Partial<Segment> & ModelProperties
  ) => {
    if (model) {
      const url = `${this.apiUrl}/${this.type}/list`;
      let queryString = "";
      let count = 0;
      for (const property in model) {
        if (count === 0) {
          queryString = `?${property}=${model[property]}`;
        } else {
          queryString += `&${property}=${model[property]}`;
        }
        count++;
      }
      const response = await BaseAPI.axios.get(`${url}${queryString}`);
      let list: Segment[] = [];
      if (Array.isArray(response.data)) {
        list = response.data;
      }
      return list;
    } else {
      const response = await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`);
      return response.data;
    }
  };

  public getAll: (
    model?: Partial<Segment> & ModelProperties
  ) => Promise<Segment[]> = async (
    model?: Partial<Segment> & ModelProperties
  ) => {
    const url = `${this.apiUrl}/${this.type}`;
    try {
      if (model) {
        const localModel = Object.assign({}, model);
        /**
         * Build query string from all model params
         */
        let RuleTypeIds = "";
        let PublicationRecordTypeIds = "";
        keys(localModel).forEach((param) => {
          if (startsWith(param, "RuleAction") && localModel[param]) {
            PublicationRecordTypeIds +=
              PublicationRecordTypeIds.length > 0
                ? `&PublicationRecordTypeIds=${localModel[param]}`
                : `${localModel[param]}`;
            delete localModel[param];
          }
          if (startsWith(param, "RuleType") && localModel[param]) {
            RuleTypeIds +=
              RuleTypeIds.length > 0
                ? `&RuleTypeIds=${localModel[param]}`
                : `${localModel[param]}`;
            delete localModel[param];
          }
          if (startsWith(param, "DateRange")) {
            localModel[param] = formatDate(localModel[param]);
          }
        });
        localModel.PublicationRecordTypeIds = PublicationRecordTypeIds;
        localModel.RuleTypeIds = RuleTypeIds;
        let queryString = "";
        let count = 0;
        for (const property in localModel) {
          if (!isFalsy(localModel[property])) {
            if (count === 0) {
              queryString = `?${property}=${localModel[property]}`;
            } else {
              queryString += `&${property}=${localModel[property]}`;
            }
            count++;
          }
        }

        // NOTE: [WIP] visual verification of param string && query url
        // console.log(
        //   "%c queryString ->",
        //   "background: black; color: yellow; font-size: 11px",
        //   queryString,
        //   "URL: ",
        //   `${url}${queryString}`
        // );

        const response = await BaseAPI.axios.get(`${url}${queryString}`);
        let list: Segment[] = [];
        if (Array.isArray(response.data)) {
          list = response.data;
        }

        // NOTE: [WIP] verifying response object has data
        // console.log(
        //   "%c[response.data] list ->",
        //   "background: green; color: blue; font-size: 11px",
        //   list
        // );

        return list;
      } else {
        const response = await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`);
        return response.data;
      }
    } catch (err) {
      console.error(
        "%c SEARCH FAILED ",
        "background: red; color: black; font-size: 11px",
        err
      );
    }
  };

  // uses the SegmentResult Model to get data from Rules, RuleTypes, SegmentTypes and Segment tables.
  // public getRulesSegment: () => Promise<SegmentResult[]> = async () =>{
  //   return (await BaseAPI.axios.get(`${this.apiUrl}/GetRulesSegment`)).data;
  // }

  public getHierarchy: (id: any) => Promise<Record<number, Segment>[]> = async (
    id: any
  ) => {
    const response = await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`, id);
    return response.data;
  };

  public getProposedRules: () => Promise<Segment[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetProposedRules`)).data;
  };

  public getAllSegments: () => Promise<Segment[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllSegments`)).data;
  };

  public getSegmentList: () => Promise<Segment[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentList`)).data;
  };

  public getSegmentListById: (id: number) => Promise<Segment[]> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentListById/${id}`))
      .data;
  };

  public getSegmentById: (id: number) => Promise<Segment[]> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentById/${id}`))
      .data;
  };

  public getSegmentsByTitleNum: (id: string) => Promise<Segment> = async (
    id: string
  ) => {
    let segmentData = await BaseAPI.axios.get(
      `${this.apiUrl}/GetSegmentsByTitleNum?titleNum=${id}`
    );
    // console.log("api segment data " , segmentData);
    return segmentData.data;
  };

  // public getSegmentsByTitleNum = async (id: string) => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", `${this.apiUrl}/GetSegmentsByTitleNum?titleNum=${id}`, true);
  //   xhr.onload = function (e) {
  //     if (xhr.readyState === 4) {
  //       if (xhr.status === 200) {
  //         console.log(xhr.responseText);
  //         // return(xhr.responseText);
  //       } else {
  //         console.error(xhr.statusText);
  //       }
  //     }
  //   };
  //   xhr.onerror = function (e) {
  //     console.error(xhr.statusText);
  //   };
  //   xhr.send(null);
  //   // xhr.responseText
  // };

  public getChapterSegment: (
    titleNum: string,
    chapterNum: string
  ) => Promise<Segment> = async (titleNum: string, chapterNum: string) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetChapterSegment?titleNum=${titleNum}&chapterNum=${chapterNum}`
      )
    ).data;
  };

  public getSegmentsByChapterNum: (
    id: string,
    chapterNum: string
  ) => Promise<Segment> = async (id: string, chapterNum: string) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetSegmentsByChapterNum?titleNum=${id}&chapterNum=${chapterNum}`
      )
    ).data;
  };

  public getSegmentsByExecOrderNum: (execOrderNum: string) => Promise<Segment> =
    async (execOrderNum: string) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetSegmentsByExecOrderNum?execOrderNum=${execOrderNum}`
        )
      ).data;
    };

  public getSegmentsByPartNum: (
    id: string,
    chapterNum: string,
    subChapterNum: string,
    partNum: string
  ) => Promise<Segment> = async (
    id: string,
    chapterNum: string,
    subChapterNum: string,
    partNum: string
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetSegmentsByPartNum?titleNum=${id}&chapterNum=${chapterNum}&subChapterNum=${subChapterNum}&partNum=${partNum}`
      )
    ).data;
  };

  public getSegmentsBySubChapterNum: (
    id: string,
    chapterNum: string,
    subChapterNum: string
  ) => Promise<Segment> = async (
    id: string,
    chapterNum: string,
    subChapterNum: string
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetSegmentsBySubChapterNum?titleNum=${id}&chapterNum=${chapterNum}&subChapterNum=${subChapterNum}`
      )
    ).data;
  };

  public getSegmentsExecutiveOrderYears: () => Promise<Segment[]> =
    async () => {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentsExecutiveOrderYears`)
      ).data;
    };

  public getSegmentsByParentId: (
    id: number,
    includeWIP: boolean
  ) => Promise<Segment> = async (id: number, includeWIP: boolean) => {
    try {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetSegmentsByParentId?parentId=${id}&includeWIP=${includeWIP}`
        )
      ).data;
    } catch (error) {
      return null;
    }
  };

  public getSegmentsByExecOrderYear: (year: string) => Promise<Segment> =
    async (year: string) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetSegmentsByExecOrderYear?year=${year}`
        )
      ).data;
    };

  public getChapterSegmentsByParentId: (id: number) => Promise<Segment[]> =
    async (id: number) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetChapterSegmentsByParentId?parentId=${id}`
        )
      ).data;
    };

  public getChapterSegmentsByParentIdIncludeAll: (
    id: number
  ) => Promise<Segment[]> = async (id: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetChapterSegmentsByParentIdIncludeAll?parentId=${id}`
      )
    ).data;
  };
  public GetChapterSegmentsWithEmergencyByParentId: (
    id: number
  ) => Promise<Segment[]> = async (id: number) => {
    try {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetChapterSegmentsWithEmergencyByParentId?parentId=${id}`
        )
      ).data;
    } catch (error) {
      return null;
    }
  };

  public createSegments: (
    isCurrentRule: boolean,
    segment: Segment
  ) => Promise<any> = async (isCurrentRule: boolean, segment: Segment) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateSegment/${isCurrentRule}`,
      segment
    );
    return response.data;
  };

  public removeSegments: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveSegments/${id}`
    );
    return response.data;
  };

  public updateSegment: (id: number, segment: Segment) => Promise<Segment> =
    async (id: number, segment: Segment) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/UpdateSegment/${id}`,
        segment
      );
      return response.data;
    };

  public deleteSegment: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeleteSegment/${id}`
    );
    return response.data;
  };
}

export const segmentAPI = new SegmentAPI();
