import { WebStorageStateStore } from "oidc-client";
import { APISettings } from ".";

export const StaticAuthenticationSettings = {
  client_id: "ladin-biblaetron",
  scope: "openid profile photo",
  aud: ["utilities-api", "administrative-rules-api", "subscription-api"],
  response_type: "code",
};
export class AuthenticationSettings {
  public authority = APISettings.identityServerUrl;
  public client_id = StaticAuthenticationSettings.client_id;
  public clientRoot = APISettings.webClientUrl;

  public scope = StaticAuthenticationSettings.scope;
  public aud = StaticAuthenticationSettings.aud;
  public apiRoot = APISettings.subscriptionsUrl;
  public response_type = StaticAuthenticationSettings.response_type;
  public automaticSilentRenew = false;
  public loadUserInfo = false;

  constructor() {
    this.redirect_uri = `${this.clientRoot}/signin-callback`;
    this.silent_redirect_uri = `${this.clientRoot}/silent-renew`;
    this.post_logout_redirect_uri = `${this.clientRoot}`;
    this.userStore = new WebStorageStateStore({ store: window.localStorage });
  }
  public redirect_uri: string;
  public silent_redirect_uri: string;
  public post_logout_redirect_uri: string;
  public userStore: WebStorageStateStore;
}
