import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { DialogTitleWithExit } from "../../../components/DialogTitleWithExit";
import { workflowAPI } from "../../../utils/api";
import { VisibilityLevel } from "../../../utils/enums";
import { Workflow, Workflow as WorkflowModel } from "./../../../utils/model";
import {} from "./components";
import { WorkflowHome } from "./components/WorkflowHome";
import "./WorkflowSelector.scss";
interface IWorkflowSelectorProps {}

const WorkflowSelectorWithRouter = (
  props: RouteComponentProps<IWorkflowSelectorProps>
) => {
  const [workflows, setWorkflows] = useState<WorkflowModel[] | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const fetchAndSetWorkflows = React.useCallback(async () => {
    const workflowCollection = await workflowAPI.getAll();
    setWorkflows(workflowCollection);
  }, []);

  useEffect(() => {
    fetchAndSetWorkflows();
  }, [fetchAndSetWorkflows]);

  const triggerReload = () => {
    fetchAndSetWorkflows();
  };
  if (workflows == null) {
    return <LinearProgress />;
  }
  const AddNewWorkflow = async () => {
    const workflow: Workflow = new Workflow(value, VisibilityLevel.Title);
    await workflowAPI.create(workflow);
    setValue("");
    handleClose();
    triggerReload();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
  };
  return (
    <div className="workflow-selector">
      {workflows.length > 0 ? (
        <WorkflowHome triggerReload={triggerReload} workflows={workflows} />
      ) : (
        <>
          <Button onClick={handleOpen} variant="contained" color="primary">
            <AddIcon />
          </Button>
          <Dialog
            disableEnforceFocus
            onClose={handleClose}
            aria-labelledby="workflow-creation-dialog"
            open={open}
          >
            <DialogTitleWithExit
              id="workflow-dialog-title"
              onClose={handleClose}
            >
              Workflow
            </DialogTitleWithExit>
            <DialogContent dividers>
              <FormControl>
                <InputLabel id={`name-workflow-name`}>Workflow Name</InputLabel>
                <Input onChange={onChange} value={value}></Input>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => AddNewWorkflow()}
                color="primary"
              >
                Create Workflow
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export const WorkflowSelector = withRouter(WorkflowSelectorWithRouter);
