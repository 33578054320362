import axios, { AxiosStatic } from "axios";
// import { request } from "http";
import { APISettings } from "../settings/APISettings";

class AuthenticationSession {
  constructor(email: string, userId: string, token: string) {
    this.email = email;
    this.userId = userId;
    this.token = token;
  }
  email: string;
  userId: string;
  token: string;
}
const getAuthSession = (): AuthenticationSession | null => {
  const item = sessionStorage.getItem(`subscriptionUser`);
  if (item != null) {
    let parsed = JSON.parse(item);
    return parsed.user as AuthenticationSession;
  } else return null;
};

const getBearerToken = () => {
  const session = getAuthSession();
  if (session !== null) {
    return session.token;
  } else {
    return null;
  }
};

export class SubscriptionBaseAPI<Identifiable, IdentifiableQuery = any> {
  constructor(type: string, apiUrl?: string) {
    if (apiUrl) {
      this.apiUrl = apiUrl;
    } else {
      this.apiUrl = APISettings.adminRulesApiUrl;
    }
    this.type = type;
    SubscriptionBaseAPI.axios = this.configureAxiosInterceptors();
  }

  // Configure auto renewal of tokens for 401 errors or if a user is not found.
  private configureAxiosInterceptors = () => {
    axios.interceptors.request.use((request) => {
      const Bearer = getBearerToken();
      if (Bearer !== null) {
        request.headers["Authorization"] = `Bearer ${Bearer}`;
      }
      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        switch (error?.response?.status) {
          case 401:
            // this.authService.renewToken();
            // Create a toast message
            return Promise.reject(new Error("No user or user token found"));
          default:
            return Promise.reject(error);
        }
      }
    );
    return axios;
  };

  public static axios: AxiosStatic;
  public apiUrl: string;
  public type: string = "";
}
