import {
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ChipSelectListInput } from "../../../../../../../../../components/ChipSelectListInput";
import { ChipStringListInput } from "../../../../../../../../../components/ChipStringListInput";
import { RichTextEditor } from "../../../../../../../../../components/RichTextEditor";
import { notificationAPI, roleAPI } from "../../../../../../../../../utils/api";
import { EditorType } from "../../../../../../../../../utils/enums";
import { NotificationType } from "../../../../../../../../../utils/enums/NotificationType";
import { Action } from "../../../../../../../../../utils/model";
import { Notification } from "../../../../../../../../../utils/model/subscription";
import "./NotificationForm.scss";

interface INotificationFormProps {
  notification?: Notification;
  action: Action;
  onSubmit: () => void;
}

export const NotificationForm = (props: INotificationFormProps) => {
  const [subject, setSubject] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [textBody, setTextBody] = useState<string>("");
  const [diff, setDiff] = useState(false);

  const setupNotification = React.useCallback(() => {
    if (props.notification != null) {
      const { subject, name, emails, body } = props.notification;
      setSubject(subject);
      setName(name);
      setEmails(emails.join(","));
      setBody(body);
      setGroups(props.notification.roles);
    }
  }, [props.notification]);

  // Groups
  const [groups, setGroups] = useState<string[]>([]);
  const [groupOptions, setGroupOptions] = useState<string[]>([]);
  const getGroupOptions = useCallback(async () => {
    const roles = (await roleAPI.getAll()).filter(
      (currentRole) => !props.notification?.roles.includes(currentRole)
    );
    setGroupOptions(roles);
  }, [props.notification?.roles]);

  const onChangeGroups = (added: boolean, selectedItem: string) => {
    const localGroups = [...groups];
    const localGroupOptions = [...groupOptions];
    if (added) {
      const index = localGroupOptions.indexOf(selectedItem);
      localGroupOptions.splice(index, 1);
      localGroups.push(selectedItem);
    } else {
      const index = localGroups.indexOf(selectedItem);
      localGroups.splice(index, 1);
      localGroupOptions.push(selectedItem);
    }
    setGroups(localGroups);
    setGroupOptions(localGroupOptions);
  };

  const setupGroupsEffect = React.useCallback(() => {
    setupNotification();
    getGroupOptions();
  }, [setupNotification, getGroupOptions]);

  useEffect(setupGroupsEffect, [setupGroupsEffect]);

  const onChangeSubject = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSubject(value);
  };
  const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  const [emails, setEmails] = useState<string>("");
  const onChangeEmails = (value: string) => {
    setEmails(value);
  };

  // Titles
  // const [titles, setTitles] = useState<string[]>([]);

  const onPublicNotificationChange = () => {
    setPublicNotification(!publicNotification);
  };
  const [publicNotification, setPublicNotification] = useState<boolean>(false);

  const saveChanges = async () => {
    const notification = new Notification(
      name,
      {
        name: props.action.id!.toString(),
      },
      NotificationType.Email,
      publicNotification,
      groups,
      emails.split(","),
      subject,
      body,
      textBody,
      []
    );

    if (props.notification?.id != null) {
      notification.id = props.notification.id;
      await notificationAPI.patch(props.notification.id, notification);
    } else {
      await notificationAPI.create(notification);
    }

    props.onSubmit();
  };
  //   TODO INTEGRATE this into the text editor so that we can get data points that are available for integrations
  //   const [replaceables, setReplaceables] = useState<string>();
  //   const onChangeReplaceables = (event:ChangeEvent<HTMLInputElement>) => {
  //     const {value} = event.target;
  //     setReplaceables(value);
  //   };
  // TODO This will need to be implemented when we get to push notifications
  //   const [notificationType, setNotificationType] = useState<string>();
  //   const onChangeNotificationType = (event: ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target;
  //     setNotificationType(value);
  //   };

  //   TODO this will need to be implemented when the ID server is ready
  //   const [roles, setRoles] = useState<string>();
  //   const onChangeRoles = (event: ChangeEvent<HTMLInputElement>) => {
  //     const { value } = event.target;
  //     setRoles(value);
  //   };

  const onTextChange = (content: any) => {
    setTextBody(content);
    let val: any;
    let items: any = "";
    // content.forEach((element: any, index: number) => {
    //   val = serialize(content[index], index);
    //   if (val !== undefined) {
    //     items += ReactDOMServer.renderToStaticMarkup(val);
    //   }
    // });
    setBody(items);
  };

  const diffView = (diffVal?: boolean) => {
    setDiff(diff);
  };

  return (
    <div className="notification-form">
      <Typography component="div" style={{ border: "black" }}>
        <FormControl className="notification-name">
          <InputLabel id="name-label">Notification Name</InputLabel>
          <Input onChange={onChangeName} value={name}></Input>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checkedIcon={publicNotification}
              onChange={onPublicNotificationChange}
              color="primary"
              name="Notify Public Subscribers"
            />
          }
          label="Notify Public Subscribers"
        />
        <ChipStringListInput
          commaDelimitatedString={emails}
          onChange={onChangeEmails}
          label="Email"
        />
        <ChipSelectListInput
          strings={groups}
          optionsList={groupOptions}
          onChange={onChangeGroups}
          label="Groups"
        />
        <FormControl>
          <InputLabel id="subject-label">Subject</InputLabel>
          <Input onChange={onChangeSubject} value={subject}></Input>
        </FormControl>
        <FormControl className="notification-name">
          <InputLabel id="body-label">Body</InputLabel>
          <div className="editor">
            <RichTextEditor
              text={
                props.notification?.text ? props.notification.text : textBody
              }
              editorType={EditorType.paragraph}
              diffView={diffView}
              saveText={saveChanges}
              onTextChange={onTextChange}
              height={"100vh"}
              width={"auto"}
            />
            {/* <NotificationEditor
              text={props.notification?.text || textBody}
              editorType={EditorType.segment}
              onTextChange={onTextChange}
              className="notification-editor"
            /> */}
          </div>
          {/* <Input type="textarea" onChange={onChangeBody} value={body}></Input> */}
        </FormControl>
      </Typography>
      <IconButton onClick={saveChanges}>
        <Tooltip title="save notification" placement="bottom">
          <SaveIcon />
        </Tooltip>
      </IconButton>
    </div>
  );
};
