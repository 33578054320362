import { SubscriptionUser } from "../../model/SubscriptionUser";
import { BaseAPI } from "../BaseApi";
import { APISettings } from "../../settings/APISettings";
import { emailValidator } from "../../../pages/Subscription/Helpers/Validators";
import { EmailSystemAddress } from "../../model/EmailSystemAddress";

class UserSubscriptionManagementApi extends BaseAPI<SubscriptionUser> {
  constructor() {
    super("userSubscriptionManagement", APISettings.adminRulesApiUrl);
  }

  public getSubscriptionUser: (userId?: string) => Promise<SubscriptionUser> =
    async (userId?: string) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/SubscriptionUser/GetSubscriptionUser/${userId}`
        )
      ).data;
    };

  // get all subscription users from API
  public getAllSubscriptionUsers: () => Promise<any> = async () => {
    var response = (
      await BaseAPI.axios.get(
        `${this.apiUrl}/api/SubscriptionUser/GetAllSubscriptionUsers`
      )
    ).data;
    // console.log("Response", response);
    return response;
  };

  public enableSubscriptionUser: (emailAddress: string) => Promise<any> =
    async (emailAddress: string) => {
      var response = (
        await BaseAPI.axios.get<void>(
          `${this.apiUrl}/api/SubscriptionUser/EnableSubscriptionUser/${emailAddress}`
        )
      ).data;

      return response;
    };

  public disableSubscriptionUser: (emailAddress: string) => Promise<any> =
    async (emailAddress: string) => {
      var response = (
        await BaseAPI.axios.get<void>(
          `${this.apiUrl}/api/SubscriptionUser/DisableSubscriptionUser/${emailAddress}`
        )
      ).data;

      return response;
    };

  public getFromEmailAddress: (id: number) => Promise<any> = async (
    id: number
  ) => {
    var response = (
      await BaseAPI.axios.get(`${this.apiUrl}/GetEmailSystemAddress/${id}`)
    ).data;

    return response;
  };

  public UpdateEmailSystemAddress: (
    emailSystemAddress: EmailSystemAddress
  ) => Promise<any> = async (emailSystemAddress: EmailSystemAddress) => {
    // console.log("emailAddress -", emailSystemAddress);
    try {
      var response = await BaseAPI.axios.put<void>(
        `${this.apiUrl}/UpdateEmailSystemAddress`,
        emailSystemAddress
      );
      return response.status === 200 ? true : false;
      // console.log('axios response -', response)
    } catch (error) {}
  };
}

export const userSubscriptionManagementAPI =
  new UserSubscriptionManagementApi();
