import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditorType, NoteType } from "../../../utils/enums";
import { segmentNoteAPI, SegmentCodificationHistoryAPI as codificationHistoryAPI } from "../../../utils/api";
import { SegmentNote } from "../../../utils/model";
import { RichTextEditor } from "../../RichTextEditor";

interface INoteFormProps {
    note: any;
    type: "CreateNote" | "EditNote";
    segmentId?: number;
    reload: () => void;
}

/**
 * @author Brandon Larsen <brandon.larsen@tecuity.com>
 * @description Note editor component used in the current rule page for editing varying segment notes
 * @param {id:number, segmentId:number, noteTypeId:number, note:string} note Note object containing note,noteTypeId,Id,segmentId
 * @param {"CreateNote" | "EditNote"} type edit declaration if creating a note or editing an existing note
 * @param {number} segmentId segmentId used when creating a new note. Not needed for updating existing notes
 * @returns {JSX.Element} React form component
 */
export  const NoteForm = (props: INoteFormProps) => {
    const {note, type} = props;
    const [noteTypes, setNoteTypes] = useState<NoteType>(note.noteTypeId);
    const [text, setText] = useState<string>(note.note);

    useEffect(() => {
     setText(note.note);
    }, [props.note])

    /**
     * @description Updates text variable typed data in rich text editor
     * @param content string from the editor
     * @param editor editor object with properties from RichTextEditor
     */
    const updateText = (content:string,editor:any) => {
        setText(content);
    }

    const saveFormData = async () => {
        if(type == "CreateNote") {
            // TODO: bring in the segment id
            let newNote = new SegmentNote(note.segmentId,note.id,text,new Date())
            delete newNote.id;

            await segmentNoteAPI.createSegmentNote(newNote);
        } else {
            let newNote:SegmentNote = await segmentNoteAPI.getSegmentNoteById(note.id);
            let noteToUpdate:SegmentNote = new SegmentNote(newNote.segmentId, newNote.noteTypeId, text, new Date(newNote.addedOn!), note.id);

            await codificationHistoryAPI.addNoteCodificationRecord(note.id);
            await segmentNoteAPI.updateSegmentNote(note.id, noteToUpdate);
        }
        // Reloads the parent data to pick up note changes
        props.reload();
    }

    /**
     * @returns {JSX.Element} return edit form
     */
    const _renderFormType = () => {
        let JSXElement:JSX.Element = (<div>Error loading note form</div>);
        if(note.noteTypeId == NoteType.Editors) {
            JSXElement = (
                <div>
                    Editing the note
                    <RichTextEditor
                        text={note.note}
                        editorType={EditorType.default}
                        width={"100%"}
                        height={"70vw"}
                        onTextChange={updateText}
                    />
                </div>
            );
        } else {
            JSXElement = (
                <div>
                    <RichTextEditor
                        text={note.note}
                        editorType={EditorType.default}
                        width={"100%"}
                        height={"70vw"}
                        onTextChange={updateText}
                    />
                </div>
            );
        }
        return JSXElement;
    }

    return (
        <div>
            <div className="admin-edit-body">
                {_renderFormType()}
            </div>
            <div className="admin-edit-footer">
                <Button onClick={saveFormData}>Save Note</Button>
            </div>
        </div>
    );
}