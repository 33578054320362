import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Card, CardBody, CardSubtitle, CardTitle } from '@progress/kendo-react-layout'
import React from 'react'
import Checkbox from '../../../../../components/Checkbox/Checkbox'
import Button from '@material-ui/core/Button'
import { useUserAccountDetailsController } from './UserAccountDetailsController'


interface IUserAccountDetails {
  userInfo: any;
  toggleAlert: () => void;
  setAlertMessage: (message?: string | undefined, type?: "success" | "error" | null | undefined) => void;
}

const UserAccountDetails = (props: IUserAccountDetails) => {
  // props
  const { userInfo } = props;

  // controller
  const {
    FormInput,
    // functions
    handleSubmit,
    emailValidator,
    togglePassCheck,
    changePassCheck,
    passwordValidator,
    toggleEdit,
    // state
    editMode,
    emailConfirmed,
    formValues,
    formKey,
  } = useUserAccountDetailsController(props)

  return (
    <Card className="card-container">
      <CardBody>
        <CardTitle
          style={{ textAlign: "center", color: "cornflowerblue" }}
        >
          Edit User Profile
        </CardTitle>
        <h4 style={{ textAlign: "center", width: "100%" }}>Email: <span style={{ textAlign: "right" }}>{userInfo.user.email}</span></h4>
        <Form
          key={formKey}
          onSubmit={handleSubmit}
          initialValues={formValues}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement style={{ maxWidth: "100svw" }}>
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  {editMode ? (
                    <div style={{ marginTop: "0.5em" }}>
                      {!changePassCheck ? (<>
                        <Field
                          id={"newEmail"}
                          name={"newEmail"}
                          label={<div style={{ color: "cornflowerblue" }}>Enter new email address</div>}
                          type={"email"}
                          component={FormInput}
                          validator={emailValidator}
                        // onChange={onChange}
                        />
                        <Field
                          id={"confirmEmail"}
                          name={"confirmEmail"}
                          label={<div style={{ color: "cornflowerblue" }}>Confirm new email address</div>}
                          type={"email"}
                          component={FormInput}
                          validator={emailValidator}
                        // onChange={onChange}
                        />
                        {emailConfirmed ? (
                          <Field
                            key={"acctPassword"}
                            id={"acctPassword"}
                            name={"acctPassword"}
                            label={"Please Enter Password and Submit"}
                            type={"password"}
                            component={FormInput}
                          // onChange={onChange}
                          />
                        ) : null}
                      </>) : null}

                      <div style={{ marginTop: "0.5rem" }}>
                        <Checkbox
                          defaultValue={changePassCheck}
                          label={"Change Password?"}
                          onClick={togglePassCheck}
                        />
                      </div>

                      {editMode && changePassCheck ? (
                        <Card style={{ marginTop: "1em" }}>
                          {/*<CardHeader style={{background: "cornflowerblue", color: "white"}}>Change Password</CardHeader>*/}
                          <CardSubtitle
                            style={{
                              textAlign: "left",
                              border: "none",
                            }}
                          >
                            <p>
                              Enter a password to protect your
                              subscriber preferences using the following
                              rules.
                            </p>

                            <ul>
                              <li>
                                Must be at least 10 characters long
                              </li>
                              <li>
                                Must contain at least one uppercase
                                letter
                              </li>
                              <li>
                                Must contain at least one lowercase
                                letter
                              </li>
                              <li>Must contain at least one number</li>
                              <li>
                                Must contain at least one special
                                character
                              </li>
                            </ul>
                          </CardSubtitle>
                          <CardBody
                            style={{
                              background: "cornflowerblue",
                              color: "white",
                            }}
                          >
                            <Field
                              key={"newPassword"}
                              id={"newPassword"}
                              name={"newPassword"}
                              label={"New Password"}
                              type={"password"}
                              component={FormInput}
                              validator={passwordValidator}
                            // onChange={onChange}
                            />
                            <Field
                              key={"confirmPassword"}
                              id={"confirmPassword"}
                              name={"confirmPassword"}
                              label={"Confirm New Password"}
                              type={"password"}
                              component={FormInput}
                              validator={passwordValidator}
                            // onChange={onChange}
                            />
                            {changePassCheck ? (
                              <Field
                                key={"acctPassword"}
                                id={"acctPassword"}
                                name={"acctPassword"}
                                label={"Old Password"}
                                type={"password"}
                                component={FormInput}
                              // onChange={onChange}
                              />
                            ) : null}
                          </CardBody>
                        </Card>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </fieldset>

              {editMode ? (
                <div className="k-form-buttons ">
                  <Button
                    style={{ width: "50%", color: "green" }}
                    type={"submit"}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    disabled={!formRenderProps.allowSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ width: "50%", color: "red" }}
                    type={"reset"}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                null
              )}
            </FormElement>
          )}

        />
        {!editMode ? (
          <div className='edit-profile-button'>
            <Button
              type={"button"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleEdit}
              style={{
                width: "100%",
                backgroundColor: "lightyellow",
              }}
            >
              Edit Profile
            </Button>
          </div>
        ) : null}
      </CardBody>
    </Card>
  )
}

export default UserAccountDetails