import { Action } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class DevelopmentApi extends BaseAPI<Action> {
  constructor() {
    super("Development", APISettings.adminRulesApiUrl);
  }
  public isDevelopment: () => Promise<boolean> = async () => {
    const response = await BaseAPI.axios.get(
      `${this.apiUrl}/${this.type}/IsDevelopment`
    );
    return response.data;
  };
  public runNightlyJobs: () => Promise<boolean> = async () => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}/RunNightlyJobs`
    );
    return response.data;
  };
  public setDateOnFiling: (
    filingId: number,
    dateToSet: string,
    date: string
  ) => Promise<boolean> = async (
    filingId: number,
    dateToSet: string,
    YYYYMMDD: string
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}/setDateOnFiling`,
      { filingId, dateToSet, YYYYMMDD }
    );
    return response.data;
  };
}

export const developmentAPI = new DevelopmentApi();
