import { Dialog, IconButton } from "@material-ui/core";
import DialogTitle, { DialogTitleProps } from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import { Button } from "@progress/kendo-react-buttons";
import { savePDF } from "@progress/kendo-react-pdf";
import { saveAs } from "@progress/kendo-file-saver";
import React, { useState, useEffect, Fragment } from "react";
import { Paragraph } from "../../../../../../components/Paragraph";
import { ruleAPI, segmentAPI } from "../../../../../../utils/api";
import { SegmentType, NoteType } from "../../../../../../utils/enums";
import './CodeModal.scss';

interface ICodeModalProps extends DialogTitleProps {
    onClose: (event?: any) => void
    open: boolean;
    title?: any;
    segment?: any;
}

export const CodeModal = (props: ICodeModalProps) => {
    const [fullCode, setFullCode] = useState<any>();
    const [ruleName, setRuleName] = useState<string>("");

    const onMount = async () => {
        if (props.title) {
            let rule = await ruleAPI.getRuleReferenceCode(props.title);
            setRuleName(rule.title);
        }

        if (props.segment) {
            if (props.segment.name == "Chapter") {
                let segments = await segmentAPI.getSegmentsByChapterNum(props.title, props.segment.chapterNum);
                setFullCode(segments);
                return
            }
            if (props.segment.name == "Subchapter") {
                let segments = await segmentAPI.getSegmentsBySubChapterNum(props.title, props.segment.chapterNum, props.segment.subChapterNum);
                setFullCode(segments);
                return
            }
            if (props.segment.name == "Section" || props.segment.name == "Appendix") {
                setFullCode([props.segment]);
                return;
            } if (props.segment.name == "Part") {
                let segments = await segmentAPI.getSegmentsByPartNum(props.title, props.segment.chapterNum, props.segment.subChapterNum, props.segment.partNum);
                setFullCode(segments);
                return
            }
            if (props.segment.name == "Executive Order" && props.segment.execOrderNum != null) {
                let segments = await segmentAPI.getSegmentsByExecOrderNum(props.segment.execOrderNum);
                setFullCode(segments);
                return
            }
            if (props.segment.name == "Title") {
                let segments = await segmentAPI.getSegmentsByTitleNum(props.title);
                setFullCode(segments);
            }
        } else {
            let segments = await segmentAPI.getSegmentsByTitleNum(props.title);
            setFullCode(segments);
        }
        // console.log(fullCode);
    }

    useEffect(() => {
        if (props.open && props.title) {
            onMount();
        }
    }, [props.title, props.open])

    const getSegmentStatus = (item: any) => {
        if (item.statusName) {
            switch (item.statusName) {
                // case "New":
                //   return " [NEW]";
                case "Revoked":
                    return " [REVOKED]";
                case "Reserved":
                    return " [Reserved]";
                case "Expired":
                    return " [EXPIRED]";
                // case "Amended":
                //   return " [AMENDED]"
                case "AmendedAndRenumbered":
                    if (item.renumberedToSegmentNumber != null) {
                        return ` [AMENDED AND RENUMBERED TO ${item.renumberedToSegmentNumber}]`;
                    } else {
                        return " [AMENDED AND RENUMBERED]";
                    }
                case "Renumbered":
                    if (item.renumberedToSegmentNumber != null) {
                        return ` [RENUMBERED TO ${item.renumberedToSegmentNumber}]`;
                    } else {
                        return " [RENUMBERED]";
                    }
                case "Superseded":
                    return " [SUPERSEDED]";
                case "Terminated":
                    return " [TERMINATED]";
                case "Transferred":
                    return " [TRANSFERRED]";
                case "Other":
                    return " [OTHER]";
                case "Published":
                    return " [PUBLISHED]";
                default:
                    return "";
                    break;
            }
        }
    }

    // commenting this out because its causing unwanted gaps in segments
    // In the instance that you have a segment like '<div>test</div><div>second div</div>` you'll get a gap mid paragraph
    // const getModifiedSegmentText = (text: string) => {
    //     if (text) {
    //         if (text.indexOf('<div>') == 0) return text.replace('<div>', '<div style="margin: 0 0 20px 0;">');
    //     }
    //     return text;
    // }

    const getSegmentHeader = (segment: any) => {
        if (segment.segmentTypeId == SegmentType.Appendix) {
            return `<div style='text-align: center;'><b>APPENDIX ${segment.appendixNum}. ${segment.description}${getSegmentStatus(segment)}</b></div>`;
        }
        else if (segment.segmentTypeId == SegmentType.ExecutiveOrder) {
            return `<div><b>${segment.execOrderNum}.${getSegmentStatus(segment)}</b></div>`;
        }
        else {
            return `<div><b>${segment.sectionNum}. ${segment.description}${getSegmentStatus(segment)}</b></div>`;
        }
    };

    let container: any = React.useRef<HTMLDivElement>(null);

    const pdfDownload = () => {
        let element = container.current;
        savePDF(element, {
            paperSize: "a4",
            margin: 40,
            fileName: `${props.segment.description}`,
        });
    }


    const getSegmentDescription = () => {
        const execOrderTitleNum = '1';
        if (props.segment && !props.segment.description) {
            if (props.title === execOrderTitleNum) {
                return `${props.segment.execOrderNum}`
            } else {
                // if description doesn't exist print use other info for download name
                let description = ''
                if (props.segment.titleNum) {
                    description += `Title-${props.segment.titleNum}`;
                }
                if (props.segment.chapterNum) {
                    description += `Chapter-${props.segment.chapterNum}`;
                }
                if (props.segment.subChapterNum) {
                    description += `SubChapter-${props.segment.subChapterNum}`;
                }
                if (props.segment.sectionNum) {
                    description += `Section-${props.segment.sectionNum}`;
                }
                if (props.segment.partNum) {
                    description += `Part-${props.segment.partNum}`;
                }
                if (props.segment.appendixNum) {
                    description += `Appendix-${props.segment.appendixNum}`;
                }
                return description;
            }
        } else {
            return props.segment.description;
        }
    }

    const htmlDownload = () => {
        let element: any = container.current;
        let description: string = getSegmentDescription();

        let tempHtml = `<html><head><style type="text/css">
        @media print{
            .code-label {
                display:flex;
                font-size:12pt;
                justify-content: center;
                font-family:"Times New Roman";
            }
            .code-content {
                margin-top:20px;
                margin-bottom:20px;
                font-size:12pt;
                font-family:"Times New Roman";
            }
        }
        .code-label {
            display:flex;
            font-size:12pt;
            justify-content: center;
            font-family:"Times New Roman";
        }
        .code-content {
            margin-top:20px;
            margin-bottom:20px;
            font-size:12pt;
            font-family:"Times New Roman";
        }
        </style>
        </head><body>${element.innerHTML}
        </body></html>`;
        const dataURI = "data:text/html," + tempHtml;
        saveAs(
            dataURI,
            `${description}.html`
        );
    }
    const insertLabelInlineNote = (note: string, insertText: string) => {
        if (note.indexOf("<p>") == 0) {

            return note.replace("<p>", `<p>${insertText}`);
        } else if (note.indexOf("<i>") == 0) {
            return note.replace("<i>", `<i>${insertText}`);
        } else if (note.indexOf("<div>") == 0) {
            return note.replace("<div>", `<i>${insertText}`).replace('</div>', '</i>');
        }
        else if (note.includes("<span")) {
            return note.replace("<span", `<i>${insertText}`).replace('</span>', '</i>');
        }


        // return note.replace("<p>", `<p>${insertText}`);
        return `${insertText}${note}`;

    };
    const _renderNotes = (item: any, sectionType: string) => {
        switch (sectionType) {
            case "Chapter":
                return (<div className="code-content"><Paragraph value={"<div style=\"margin-left: 1rem; max-width: 95%; font-size:11pt;\">[<b>Authority: </b>" + item.notes + "<div>"} className="" /></div>);
            default:
                return null;
        }
    };

    const renderNotesForSegmentsWithText = (segment: any) => {
        if (!segment.segmentNotes && segment.segmentNotes.length > 0) {
            return '';
        }

        let sourceNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Source);
        let editorNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Editors);
        let authorityNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Authority);
        let footnotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Footnote);
        let agencyNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Agency);
        let sourceNotesText: string = sourceNotes && sourceNotes.length > 0
            ? sourceNotes.map((note: any) => {
                return (
                    note.hRef !== null ?
                        `<a href="https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=PublicRegister&name=${note.hRef}" className="note-zone">&nbsp;${note.note.trim()}</a>`
                        :
                        ` ${note.note.trim()}`)

            }
            ).join('; ')
            : '';

        // Order in which note types should appear
        //  1. Footnote
        //  2. Source
        //  3. Editors
        //  4. Agency
        //  5. Authority
        return <div style={{ fontSize: "9pt" }}>
            {/* May need to add this in front of each footnote (we'll have to see what Chris says): &emsp;&emsp;&emsp; */}
            {
                footnotes && footnotes.length > 0
                    ? footnotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'>${note.note}</div>`} className="" /> })
                    : null
            }
            {
                sourceNotesText !== ''
                    ? <Paragraph value={`<div className="segment-note">[<strong>Source: </strong>${sourceNotesText}]</div>`} className="" />
                    : null
            }
            {
                editorNotes && editorNotes.length > 0
                    ? editorNotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'><i>${insertLabelInlineNote(note.note, "<strong>EDITOR'S NOTE:</strong>&nbsp;")}</i></div>`} className="" /> })
                    : null
            }
            {
                agencyNotes && agencyNotes.length > 0
                    ? agencyNotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'><strong>AGENCY NOTE: </strong>${note.note}</div>`} className="" /> })
                    : null
            }
        </div>;
    };

    const _renderSegmentNotes = (item: any) => {
        let agencyNote: string = "";
        let authorityNote: string = "";
        let editorsNote: string = "";
        let sourceNote: string = "";
        let notes = item.notes ?? item.segmentNotes;
        // console.log(item);
        if (Array.isArray(notes)) {
            notes.map((note: any) => {
                switch (note.noteTypeId) {
                    case 1: // Editor's
                        // Individual notes
                        var prefix = item.segmentTypeId == 1 ? "Editor's Note" : "EDITOR’S NOTE";
                        editorsNote += `<div class="inline-note"><i class="inline-note-text">${insertLabelInlineNote(note.note.trim(), `<b>${prefix}:</b>&nbsp;`)}</i></div>`
                        // combined notes
                        // editorsNote += `<div class="inline-note"><i class="inline-note-text">${note.note}</i></div>&nbsp;`;
                        break;
                    case 2: // Source
                        sourceNote += `<div class="inline-note">[<b>Source: </b>${note.note.trim()}]</div>`;
                        break;
                    case 3: // Authority
                        authorityNote = `<div class="inline-note">[<b>Authority: </b>${note.note.trim()}]</div>`;
                        break;
                    case 5: // Agency
                        agencyNote = `<i class="inline-note"><b>AGENCY NOTE:</b> ${note.note.trim()}</i>`;
                        break;
                }
            })
        };

        var noteHtml = "<div style='font-family: Times New Roman;'>";
        noteHtml += "<div style='font-size: 9pt; margin-top: 9px;'>";

        // individual note
        noteHtml = editorsNote;
        //combined note
        // noteHtml = `<i class="inline-note inline-note-text"><b>Editor's Note:</b>&nbsp; ${editorsNote}</i>`;

        if ((typeof authorityNote === "string" && authorityNote.trim().length != 0) ||
            (typeof sourceNote === "string" && sourceNote.trim().length != 0) ||
            (typeof agencyNote === "string" && agencyNote.trim().length != 0)) {
            noteHtml += authorityNote;
            noteHtml += sourceNote;
            noteHtml += agencyNote;
            noteHtml += "</div>";
        }
        // noteHtml += '</div>';

        return (<Paragraph key={item.id + "_segmentNote"} value={noteHtml} className="" />);
    };

    function printDiv() {
        var divContents = document.getElementById("print-container")!.innerHTML;
        var printWindow: any = window.open("", "", "");
        printWindow.document.write(`<html><head><style type="text/css">
        @media print{
            .code-label {
                margin-top: 20px;
                margin-bottom: 20px;
                display:flex;
                font-size:12pt;
                justify-content: center;
                font-family:"Times New Roman";
            }
            .code-content {
                margin-top:20px;
                margin-bottom:20px;
                font-size:12pt;
                font-family:"Times New Roman";
            }
        }
        .code-label {
            display:flex;
            font-size:12pt;
            justify-content: center;
            font-family:"Times New Roman";
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .code-content {
            margin-top:20px;
            margin-bottom:20px;
            font-size:12pt;
            font-family:"Times New Roman";
        }
        .segment-note {
            margin-left: 1rem;
        }

        .inline-note * {
            font-size: 9pt !important;
            font-family: "Times New Roman", Times, serif !important;
            display: inline-block;
        }

        .inline-note {
            margin-top: 0 !important;
            font-size: 9pt !important;
        }

        .inline-note-text {
            margin-top: 20px;
            margin-bottom: 0px;
            font-size: 9pt !important;
        }

        .inline-note-text > * {
            display: inline;
            font-size: 9pt !important;
        }

        .inline-note-text > * > * {
            display: inline;
            font-size: 9pt !important;
        }

        .segment-note * {
            font-size: 9pt !important;
            font-family: "Times New Roman", Times, serif !important;
            display: inline-block;
        }
        .Default {
            margin: 0;
        }
        img {
            width: 100% !important;
        }
        </style><title>${props.segment ? props.segment.description : "TitleNumber" + props.title}</title>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(divContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    const { children, onClose } = props;
    return (
        <Dialog open={props.open} className="code-modal">
            <DialogTitle {...props} className="code-modal-title">
                <IconButton aria-label="close dialog" onClick={onClose} className="close-icon">
                    <CloseIcon />
                </IconButton>
                <div>
                    Title {props.title}. {ruleName}
                    <div className="print-button-group">
                        <Button
                            look="flat"
                            icon="print"
                            style={{ color: "blue" }}
                            disabled={false}
                            onClick={printDiv}
                        >
                            Print
                        </Button>
                    </div>
                    {props.segment ? (
                        <div className="button-group">
                            {/* <Button
                    look="flat"
                    icon="pdf"
                    style={{ color: "red" }}
                    disabled={false}
                    value={props.segment.titleNum}
                    onClick={pdfDownload}
                >
                    Download PDF
                </Button> */}
                            <Button
                                look="flat"
                                icon="code"
                                style={{ color: "blue" }}
                                disabled={false}
                                value={props.title}
                                onClick={htmlDownload}
                            >
                                Download Html
                            </Button>
                        </div>
                    ) : null}
                </div>
            </DialogTitle>
            <div id="print-container" className="modal-content">
                <div ref={container}>
                    {fullCode ? fullCode.map((item: any, index: number) => {
                        let segmentTypicallyHasText = item.segmentTypeId == SegmentType.Appendix
                            || item.segmentTypeId == SegmentType.ExecutiveOrder
                            || item.segmentTypeId == SegmentType.Section_Chapter
                            || item.segmentTypeId == SegmentType.Section_Part
                            || item.segmentTypeId == SegmentType.Section_Subchapter;

                        if (segmentTypicallyHasText && item.filingId == null) {
                            if (item.name == "Executive Order" && item.statusName != "Terminated") {
                                let description = item.description != null
                                    ? item.description.toUpperCase()
                                    : '';

                                let newHeader = `<b>${item.execOrderNum}.${getSegmentStatus(item)}</b><p style='text-align:center;'><b>${description}</b></p>`;

                                return (
                                    <>
                                        <Paragraph key={index} value={`<div style='margin-left: 1rem; max-width: 95%'>${newHeader}${item.text}<div>`} className="code-content" />
                                        {
                                            item.segmentNotes ? _renderSegmentNotes(item) : null
                                        }
                                    </>
                                );
                            }
                            else {
                                let segmentHeader = getSegmentHeader(item);

                                return (
                                    <Fragment>
                                        {/* Removed call to getModifiedSegmentText function because it causes unwanted gaps */}
                                        <Paragraph key={index} value={`<div style='margin-left: 1rem; max-width: 95%;'>${segmentHeader}${(item.text == null ? '' : item.text)}<div>`} className="code-content" />
                                        {renderNotesForSegmentsWithText(item)}
                                    </Fragment>
                                );
                            }
                        } else if(segmentTypicallyHasText && item.filingId !== null){
                            let segmentHeader = getSegmentHeader(item);

                                return (
                                    <Fragment>
                                        {/* Removed call to getModifiedSegmentText function because it causes unwanted gaps */}
                                        <Paragraph key={index} value={`<div style='margin-left: 1rem; max-width: 95%;'>${segmentHeader}${(item.text == null ? '' : item.text)}<div>`} className="code-content" />
                                        {renderNotesForSegmentsWithText(item)}
                                    </Fragment>
                                );
                        } else {
                            if (item.name == "Title") {
                                return (
                                    <Fragment>
                                        <Paragraph key={index + "_title_segment_note"} value={"<b>" + item.name.toUpperCase() + " " + item.titleNum + ". " + item.description.toUpperCase() + "</b>"} className="code-label" />
                                        {
                                            (item.segmentNotes && item.segmentNotes.length > 0) ? _renderSegmentNotes(item) : null
                                        }
                                    </Fragment>
                                );
                            } else if (item.name == "Subchapter") {
                                return (
                                    <Fragment>
                                        <Paragraph key={index + "_subchapter_segment_note"} value={"<b>SUBCHAPTER  " + item.subChapterNum + ". " + item.description.toUpperCase() + getSegmentStatus(item) + "</b>"} className="code-label" />
                                        {
                                            (item.segmentNotes && item.segmentNotes.length > 0) ? _renderSegmentNotes(item) : null
                                        }
                                        {
                                            item.notes ? _renderNotes(item, item.name) : null
                                        }
                                    </Fragment>
                                );
                            } else if (item.name == "Chapter") {
                                return (
                                    <Fragment>
                                        <Paragraph key={index + "_chapter_segment_note"} value={"<b>" + item.name.toUpperCase() + " " + item.chapterNum + ". " + item.description.toUpperCase() + getSegmentStatus(item) + "</b>"} className="code-label" />
                                        {
                                            (item.segmentNotes && item.segmentNotes.length > 0) ? _renderSegmentNotes(item) : null
                                        }
                                        {
                                            item.notes ? _renderNotes(item, item.name) : null
                                        }
                                    </Fragment>
                                );
                            } else if (item.name == "Part") {
                                return (
                                    <Fragment>
                                        <Paragraph key={index + "_part_segment_note"} value={"<b>" + item.name.toUpperCase() + " " + item.partNum + ". " + item.description.toUpperCase() + getSegmentStatus(item) + "</b>"} className="code-label" />
                                        {
                                            (item.segmentNotes && item.segmentNotes.length > 0) ? _renderSegmentNotes(item) : null
                                        }
                                    </Fragment>
                                );
                            }
                        }
                    }) : null}
                </div>
            </div>
        </Dialog>
    );
}