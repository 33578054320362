import React, { ChangeEvent, Fragment, useMemo, useEffect } from "react";
import "./DraftModal.scss";
import { Button, Card, Dialog, DialogActions, DialogContent, Divider, Grow, Paper, Typography, TextField, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from "@material-ui/core";
import { DialogTitleWithExit } from "../../../../../components/DialogTitleWithExit";
import { Authorized } from "../../../../../components/Authorized";
import { RichTextEditor } from "../../../../../components/RichTextEditor";
import { draftAPI, ruleAPI, segmentAPI, segmentHistoryAPI } from "../../../../../utils/api";
import { cleanup } from "@testing-library/react";
import { Draft, Segment, SegmentHistory, SegmentHistoryData } from "../../../../../utils/model";
import { StatusRecordStatus } from "../../../../../utils/enums";
import { DraftStatuses } from "../../../../../utils/enums/DraftStatuses";
// import Typography from "material-ui/styles/typography";

interface IDraftModalProps {
  id:string;
  draftData: any | null;
  open:boolean;
  close: () => void;
  reloadData: (draft?:Draft) => any;
  edit?: boolean ;
}

/**
 *
 * @author Brandon Larsen <brandon.larsen@tecuity.com>
 * @author
 * @param {Object} [draftData] - data object of edit values for use in modal fields
 * @param {boolean} [open] - boolean value used to toggle open and close of dialog window
 * @param {() => void}  [close] - function that handles changing the [open] parameter
 * @todo  Write save function
 * @returns Segment Draft Dialog/Modal
 */
export const DraftModal = (props: IDraftModalProps) => {
  const [titles, setTitles] = React.useState<any>([]);
  const [chapters, setChapters] = React.useState<any>([]);
  const [selectedTitle, setSelectedTitle] = React.useState<any>(1);
  const [selectedChapter, setSelectedChapter] = React.useState(1);
  const [draftName, setDraftName] = React.useState("");
  const [chapterName, setChapterName] = React.useState("");
  const [chapterNum, setChapterNum] = React.useState("");
  const [creatingChapter, setCreatingChapter] = React.useState(false);

  const getTitles = async () => {
    let rules = await ruleAPI.getRulesList();
    // console.log(rules);
    setTitles(rules);
  }

  const onMount = React.useCallback( async() => {
    // console.log(props.draftData);


    if(props.edit === true){
      setDraftName(props.draftData.draftName)
    }

    if(titles.length < 1) {
       getTitles();
    }

    if(props.draftData != null) {
      setSelectedTitle(1);

    } else {
      setSelectedTitle(1)
    }
  },[props.draftData])

 /**
  * When the modal opens, run the onMount function.
  *
  * When the modal closes, clean up the state.
  */
  useEffect(() => {
    if(props.open === true) {
      onMount();
    }

    // clean up on close
    return () => {
      setDraftName("");
      setChapters([]);
      setTitles([]);
      setSelectedChapter(1);
      setSelectedTitle(1);
    }
  }, [props.open])

  const handleClose = () => {
    props.close();
  }

/**
 * @description - saves draft object to database. If a new chapter is created a new segment with a WIP status is created and segment history record created.
 * @returns The draft object.
 */
  const saveForm = async () => {
    if(props.edit === true){
      var updatedDraft = await draftAPI.updateDraftName(props.draftData.id, draftName)
      props.reloadData(updatedDraft);
      // props.setNewDraft!(updatedDraft);
    } else if(creatingChapter) {
      var title = titles.find((obj:any) => {
        return obj.id === selectedTitle;
      })

      let rule:any = await ruleAPI.getRuleById(title.id);
      // console.log(rule);

      let segmentTypeId = 2; // 2 is a chapter in the database just didn't want another api call just to get the id
      let segmentStatusId = 12; // 12 is the status of "UNDEFINED" which is the default value for segments that don't have an OAR defined status on them;
      let segment = new Segment(chapterName,title.referenceCode,StatusRecordStatus.WIP,segmentTypeId,chapterNum,null,null,null,null,null,null,null,"",null,null,null,rule.segmentId,null,segmentStatusId,null,null,null,null,false);
      delete segment.id;

      let createdSegment:any = await segmentAPI.createSegments(true, segment);

      let date:Date = new Date;
      let draft = new Draft(draftName, title.referenceCode, chapterNum, 1, date, true, DraftStatuses.New);
      delete draft.id;

      let createdDraft:any = await draftAPI.createDraft(draft);

      // Create segment history item for new segment;
      let segmentHistoryData = new SegmentHistoryData(chapterName,12,"UNDEFINED","",[],[],"");
      let segmentHistory = new SegmentHistory(createdSegment.value.id,createdDraft.id,segmentHistoryData,date,date,true);
      delete segmentHistory.id;
      // console.log("DATA TO CREATE", segmentHistory,createdSegment,createdDraft);
      await segmentHistoryAPI.createSegmentHistory(segmentHistory, true);
    }  else {
      var title = titles.find((obj:any) => {
        return obj.id === selectedTitle;
      })

      var chapter = chapters.find((obj:any) => {
        return obj.id === selectedChapter;
      })

      let date:Date = new Date;

      let draft = new Draft(draftName, title.referenceCode, chapter.chapterNum, 1, date, true, DraftStatuses.New);
      delete draft.id;

      await draftAPI.createDraft(draft);
    }
    props.reloadData();
  }

  const handleTextChange = (content: any, editor: any) => {
    // console.log(content,editor);
  }

  /**
   * @description - gets chapters from api on title selection and set chapter list if the selected title is not an executive order
   * @param {any} event - any - The event that triggered the function.
   * @returns None
   */
  const handleTitleSelectChange = async (event:any) => {
    setSelectedTitle(event.target.value);
    var executiveOrder = 1;

    if(event.target.value != executiveOrder) {
      // console.log(event.target.value);
      let title = await ruleAPI.getRuleById(event.target.value);
      let chaptersList:any = await segmentAPI.getSegmentsByParentId(title.segmentId, false);
      setChapters(chaptersList);
      setSelectedChapter(chaptersList[0]!.id!);
    } else {
      // console.log("Creating Executive Order");
      setChapters([]);
      setSelectedChapter(1);
    }
  }

  /**
   * `handleChapterSelectChange` is a function that takes an event as an argument and sets the
  `selectedChapter` state variable to the value of the event.
  * @param {any} event - any - The event object that is passed to the function.
  * @returns None
  */
  const handleChapterSelectChange = async (event:any) => {
    setSelectedChapter(event.target.value);
  }

  /**
   * Event hanlder for draft name change
   * @param {ChangeEvent} event - ChangeEvent<HTMLInputElement>
   * @returns None
   */
  const onChangeDraftName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDraftName(value);
  }

    /**
   * Event hanlder for draft name change
   * @param {ChangeEvent} event - ChangeEvent<HTMLInputElement>
   * @returns None
   */
     const onChangeChapter = (event: ChangeEvent<HTMLInputElement>, type:string) => {
      const { value } = event.target;
      if(type =="name") {
        setChapterName(value);
      }
      if(type == "num") {
        setChapterNum(value)
      }
    }

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        id={props.id}
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Draft
        </DialogTitleWithExit>
        <Divider />
        <DialogContent className="dialog-content">
          <div className="text-fields">
            <TextField
              label="Draft Name"
              variant="outlined"
              value={draftName}
              onChange={onChangeDraftName}
            />
          </div>
          <div className="text-fields">
            {props.edit ? null :
            <FormControlLabel
            control={<Checkbox color="primary" checked={creatingChapter} onChange={() => creatingChapter ? setCreatingChapter(false) : setCreatingChapter(true)} />}
            label="Create New Chapter"
            />
          }
          </div>
          {props.edit ? null :
          <div className="select-boxes">
            {titles.length > 0 ? (
              <div>
                <FormControl>
                  <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                    Select Title
                  </InputLabel>
                  <Select
                    variant="outlined"
                    className="drop-down"
                    id={"test"}
                    label="Select Title"
                    name="titleId"
                    value={selectedTitle}
                    onChange={handleTitleSelectChange}
                    >
                  {titles.map((element:any,key:number)=>{
                      return(
                        <MenuItem key={element.id} value={element.id}>
                          {element.referenceCode + " - " + element.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : null}
            {chapters.length > 0 && creatingChapter == false ? (
              <div>
                <FormControl>
                  <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                      Select Chapter
                  </InputLabel>
                <Select
                    variant="outlined"
                  className="drop-down"
                  id={"test"}
                  label="Select Chapter"
                  name="chapterId"
                  value={selectedChapter}
                  onChange={handleChapterSelectChange}
                >
                {chapters.map((element:any,key:number)=>{
                    return(
                      <MenuItem key={element.id} value={element.id}>
                        {element.chapterNum + " - " + element.description}
                      </MenuItem>
                    );
                })}
                </Select>
                </FormControl>
              </div>
            ) : null}
            {creatingChapter ? (
              <div className="chapter-form">
                <Typography variant="body2">Enter New Chapter Details</Typography>
                <TextField
                  label="Chapter Name"
                  variant="outlined"
                  value={chapterName}
                  onChange={(event:any) => onChangeChapter(event,"name")}
                />
                <TextField
                  label="Chapter Number"
                  placeholder="20"
                  variant="outlined"
                  value={chapterNum}
                  onChange={(event:any)=>onChangeChapter(event,"num")}
                />
              </div>
            ) : null}
          </div>
        }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Authorized>
            <Button
              onClick={(event) => {
                saveForm();
                handleClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </Authorized>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

