export const capitalizeText = (text: string) => {
  if (!text) return text;
  const allWords = text.split(" ");
  const capitalizedWords = allWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(" ");
};

export const isFalsy = (value: any) => {
  if (typeof value === "boolean" && value === false) return true;
  if (typeof value === "number" && (value === 0 || isNaN(value))) return true;
  if (typeof value === "string" && value.trim() === "") return true;
  if (value === null || value === undefined) return true;
  if(Object.prototype.toString.call(value) === '[object Object]' &&
    JSON.stringify(value) === '{}') return true;
  return false;
};

export const formatDate = (dateObj: Date) => {
  if (!dateObj) return "";

  // NOTE: month is 0-indexed; should we be using that as well?
  const MM = dateObj.getMonth();
  const dd = dateObj.getDate();
  const yyyy = dateObj.getFullYear();

  // NOTE: check for valid month, day && year
  if (!MM || !dd || !yyyy) return "";

  return `${MM}/${dd}/${yyyy}`;
};

export const getEntityData = (entity:{ [key: string]: any }={}, parent:string, child:string = "") => {
            let value:string = "";

            if (!isFalsy(entity))
            {
                if (!isFalsy(parent)
                    && !isFalsy(child)
                    && !isFalsy(entity[parent])
                    && !isFalsy(entity[parent][child]))
                {
                    value = entity[parent][child].toString();
                }
                else if (!isFalsy(parent)
                    && isFalsy(child)
                    && !isFalsy(entity[parent])
                    )
                {
                    value = entity[parent].toString();
                }

            }
            return value;
};
