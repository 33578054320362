import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ChangeEvent, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { FilingDates } from "../../stateOffice/pages/State/components/DevelopmentToolbar/DevelopmentToolbar";
import { ActionDisplayType, ActionType, ExecutionPermission, FilingType, PublicationStatus, StatusRecordStatus, StatusType, UserActions, UserTypes, VisibilityLevel } from "../../utils/enums";
import './EnumSelectInput.scss';

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
}

interface IEnumSelectInputProps {
    enumerable: StandardEnum<UserTypes | UserActions | StatusType | VisibilityLevel | StatusRecordStatus | ActionType | ActionDisplayType | ExecutionPermission | FilingType | StatusType | PublicationStatus | FilingDates>
    title: string;
    value: number;
    onChange: (value: number) => void;
    className?: string;
}

const uniqId = uuid()

export const EnumSelectInput = (props: IEnumSelectInputProps) => {
    // Destructure props
    const { enumerable } = props;
    // Map out all enum keys
    const members: any[] = Object.keys(enumerable);
    // assign only the string values
    let strings: string[] = members.filter(member => !parseInt(member) && parseInt(member) !== 0);
    // assign only the number values
    let numbers: number[] = members.filter(member => parseInt(member) || parseInt(member) === 0);
    useEffect(() => {
        if (props.value) {
            const valueIsValid = numbers.filter(value => value === props.value);
            if (!valueIsValid) {
                props.onChange(numbers[0]);
            }
        }
    });
    // setup onChange event to set the current value
    const onChange = (event: ChangeEvent<any>) => {
        const { value } = event.target;
        props.onChange(parseInt(value));
    }
    return (
         <div className={`enum-select-input ${props.className}`}>
            <FormControl>
                <InputLabel id={`enum-select-label${uniqId}`}>{props.title}</InputLabel>
                <Select
                    labelId={`enum-select-label${uniqId}`}
                    className="enum-select"
                    value={props.value}
                    onChange={onChange}
                >
                    {strings.map((value, key) => <MenuItem key={key} value={numbers[key]}>{value}</MenuItem>)}
                </Select>
         </FormControl>
        </div>);
}
