import { useEffect, useRef, useState } from "react";
import {
  ruleAPI,
  segmentAPI,
  userSubscriptionAPI,
} from "../../../../../utils/api";

export const useSubscriptionDetailController = (userInfo: any) => {
  const [userTitles, setUserTitles] = useState<any>(null);
  const [userChapters, setUserChapters] = useState<any>(null);

  useEffect(() => {
    // Gets all user subscriptions.
    const getSubscriptions = async () => {
      let titles: any[] = [];
      let chapters: any[] = [];
      const subscriptions: any = await userSubscriptionAPI.getUserSubscriptions(
        userInfo.user
      );

      // TODO: build an endpoint to get all chapters by array of chapter id's and another for titles/rules
      // I don't like that this could potentially cause a lot of requests to the API.
      for (let i = 0; i < subscriptions.length; i++) {
        if (subscriptions[i].ruleId) {
          let rule = await ruleAPI.getRuleById(subscriptions[i].ruleId);
          titles.push({ rule: rule, subscription: subscriptions[i] });
        } else {
          let chapter: any[] = await segmentAPI.getSegmentById(
            subscriptions[i].chapterSegmentId
          );
          chapters.push({
            chapter: chapter[0],
            subscription: subscriptions[i],
          });
        }
      }
      setUserTitles(titles);
      setUserChapters(chapters);
    };

    getSubscriptions();
  }, []);

  // let titleList = useRef([]);
  // let chapterList = useRef([]);

  // useEffect(() => {
  //   titleList.current = userTitles;
  //   chapterList.current = userChapters;
  // }, [userTitles, userChapters]);

  /**
   * @description Updates the state of the page on removal of a single subscription
   * @param id Accepts in the id of the subscription being removed
   */
  const updateSubscription = (id: number) => {
    let titleList: any[] = userTitles;
    let titleIndex = titleList.findIndex(
      (sub: any) => sub.subscription.id == id
    );

    if (titleIndex != -1) {
      titleList.splice(titleIndex, 1);
      setUserTitles([...titleList]);
    }

    let chapterList: any[] = userChapters;
    let chapterIndex = chapterList.findIndex(
      (sub: any) => sub.subscription.id === id
    );
    if (chapterIndex != -1) {
      chapterList.splice(chapterIndex, 1);
      setUserChapters([...chapterList]);
    }
  };

  // unsubscribes from a single item (chapter)
  const unsubscribeItem = async (data: any) => {
    try {
      const unsubscribe: any = await userSubscriptionAPI.unsubscribeItem(
        data.id,
        data.userId,
        data.ruleId,
        data.chapterSegmentId
      );
      updateSubscription(data.id);
    } catch (error) {
      console.error("unable to remove the subscription", error);
    }
  };

  // unsubscribes from all items of type "Chapter", "Title"
  const unsubscribeAllItems = (type: "Chapter" | "Title") => {
    let subscriptions: any;

    if (type === "Chapter") {
      subscriptions = userChapters.map((item: any, index: number) => {
        return item.subscription;
      });
      // console.log(subscriptions);
    }

    if (type === "Title") {
      subscriptions = userTitles.map((item: any, index: number) => {
        return item.subscription;
      });
      // console.log(subscriptions);
    }

    const unsubscribeAll: any =
      userSubscriptionAPI.unsubscribeItems(subscriptions);

    if (unsubscribeAll) {
      if (type === "Chapter") {
        setUserChapters([]);
      } else if (type === "Title") {
        setUserTitles([]);
      }
    }
  };

  return {
    // functions
    unsubscribeAllItems,
    unsubscribeItem,
    // state
    userTitles,
    userChapters,
  };
};
