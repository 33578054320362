import { APISettings } from "../../../utils/settings";

export const EditCommandCell = (props: any) => {
  const { dataItem } = props;


  return  (

    <td className="k-command-cell">
      <button
        className={`k-button k-button-md k-rounded-md ${dataItem.acctDisabled ? " k-button-solid k-button-solid-primary" : "k-button-outline k-button-outline-secondary" } k-grid-edit-command`}
        onClick={() => props.handleStatusChange(dataItem)}
      >
       {dataItem.acctDisabled === true ? 'Enable' : 'Disable' }
      </button>

    </td>
  );
};
