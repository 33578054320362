import {Dialog} from "../../../../../components/Dialog";
import React from "react"
import { Paragraph } from "../../../../../components/Paragraph";
import { Button } from "@progress/kendo-react-buttons";

interface Props {
  agencyEmail: any;
  open: boolean;
  close: () => void;
  handlePublicCommentClick: () => void;
}


export const PublicCommentModal = ({ agencyEmail, open, close }: Props) => {
  let container: any = React.useRef<HTMLDivElement>(null);
  let disclaimer =
    "Your message will be subject to the Oklahoma Open Records Act. The recipient is interested in reading your comments about this rule, and we ask that you be courteous and professional with your comments.";

  const modalDetails = () => {
    return (
      <div ref={container}>
        <Paragraph className="" value={disclaimer} />
        <hr />
        <Paragraph className="" value={agencyEmail} />
      </div>
    );
  };

  const modalActions = () => {
    return (
      // <Button primary onClick={sendComment}>Send Comments</Button>);
      <a className="contact" href={ "mailto:" + agencyEmail} onClick={close}><Button  primary >Send Comments</Button></a>)
  };

  return (
    <Dialog
      style={{ width: 500 }}
      className="draft-preview"
      open={open}
      onClose={close}
      title={"Please note:"}
      children={modalDetails()}
      actions={modalActions()}
    />
  );
};