import { Paragraph } from "../../../../../../components/Paragraph";

import "./Suggestions.scss";

export default function Suggestions(props: any){

    const suggestionHandler = (e: any) => {
        props.suggestionHandler(e.currentTarget.id);
    }

    const borders = {
        border: "1px solid #eee",
        boxShadow: "0 2px 3px #ccc",
        boxSizing: "border-box"
    }

    const suggestions = props.suggestions.map((s: any, index: number) => {
        return (<div className="suggestion-item" key={index} id={s.text} onMouseDown={suggestionHandler}><Paragraph className="text" value={s.text}/></div>);
    });

    return (
        <div className="borders">
            {suggestions}
        </div>
    );
}