import React from "react";
// import { useAgency } from "./../../../../../utils/hooks/useAgency";
import "./AgencyPath";
import { TabMenu } from "./components/TabMenu";

interface IAgencyPathProps {}

export const AgencyPath = (props: IAgencyPathProps) => {
  // const [agency, setAgency] = useAgency();

  // TODO: Use or delete this function
  // const addNewStatus = () => {
  //     const operatingAgency = cloneDeep(agency);
  //     setAgency(operatingAgency);
  // }

  return (
    <div>
      <TabMenu />
    </div>
  );
};
