import React, { useState, useEffect, Fragment } from "react";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AddAlert,
  CheckCircleOutline,
  Code as CodeIcon,
  ExpandMore as ExpandMoreIcon,
  History as HistoryIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";
import { CodeModal } from "..";
import { contactAPI, segmentAPI } from "../../../../../utils/api";
import "./CodeResult.scss";
import {
  CodeModalButton,
  ContactHyperLink,
  ExecOrderExpansion,
  ExpansionDetail,
} from ".";
import { Button } from "@progress/kendo-react-buttons";
import { ChildCodeCard } from "./ChildCodeCard";
import { getClosestNavigatableElement } from "@progress/kendo-react-data-tools/dist/npm/navigation/utils";
import { EmergencyModalButton } from "./EmergencyModalButton";
import { DialogTitleWithExit } from "../../../../../components/DialogTitleWithExit";
import { UserSubscription } from "../../../../../utils/model/subscription/UserSubscription";

interface INestedExpansionsProps {
  open: string;
  title?: any;
  titleLevel?: boolean;
  segmentId?: number;
  className?: string;
  segments?: any;
  noteTypes: any;
  email?: string;
  isCurrentUserLoggedIn?: boolean;
  handleSubscribeSubmit?: (e: any, item: any, source: string) => void;
  userSubscriptions?: UserSubscription[];
  subscribedTitleSegmentId?: any | undefined;
}

export const NestedExpansions = (props: INestedExpansionsProps) => {
  const [expanded, setExpanded] = useState<any>("");
  const [segments, setSegments] = useState<any>(
    props.segments || null || undefined
  );
  const [execOrderYears, setExecOrderYears] = useState<any>(null);
  const [childSegments, setChildSegments] = useState<any>(null);
  const [componentsRendered, setComponentsRendered] = useState(false);

  // get all items by parent id
  const onMount = async (id?: number) => {
    if (props.open === "1" && props.title.referenceCode == "1") {
      let execYears = await segmentAPI.getSegmentsExecutiveOrderYears();
      setExecOrderYears(execYears);
    } else if (!props.segments) {
      if (props.title.segmentId == props.open || (id && props.open)) {
        let segmentData: any = [];
        if (props.segmentId) {
          segmentData = await segmentAPI.getSegmentsByParentId(
            props.segmentId,
            false
          );
        } else if (props.title.segmentId) {
          segmentData = await segmentAPI.getSegmentsByParentId(
            props.title.segmentId,
            true
          );
        }
        setSegments(segmentData);
      }
    } else {
      setSegments(props.segments);
    }
  };

  const getChildSegments = async (id: number) => {
    let segmentData: any = [];
    segmentData = await segmentAPI.getSegmentsByParentId(id, false);
    setChildSegments(segmentData);
  };

  const getExecSegments = async (id: string) => {
    let segmentData: any = [];
    segmentData = await segmentAPI.getSegmentsByExecOrderYear(id);
    setChildSegments(segmentData);
  };

  // expand accoridon
  const handleChange =
    (id: any, type?: any) => (event: any, newExpanded: any) => {
      // console.log("on change clicked to expand");
      setChildSegments(null);
      setExpanded(newExpanded ? id : false);
      if (type && newExpanded) {
        getExecSegments(type);
      } else if (newExpanded) {
        getChildSegments(id);
      }
    };

  // triggers on mount
  useEffect(() => {

    if (props.segmentId && props.open !== props.title.id) {
      onMount();
    }
  }, [props.open]);

  const getStatusTag = (item: any) => {
    if (item.statusName) {
      switch (item.statusName) {
        // case "New":
        //   return "[New]";
        case "Revoked":
          return "[Revoked]";
        case "Reserved":
          return "[Reserved]";
        case "Expired":
          return "[Expired]";
        // case "Amended":
        // return "[Amended]"
        case "AmendedAndRenumbered":
          // console.log(item);
          if (item.renumberedToSegmentNumber != null) {
            return `[Amended and Renumbered To ${item.renumberedToSegmentNumber}]`;
          } else {
            return "[Amended and Renumbered]";
          }
        case "Renumbered":
          if (item.renumberedToSegmentNumber != null) {
            return `[Renumbered To ${item.renumberedToSegmentNumber}]`;
          } else {
            return "[Renumbered]";
          }
        case "Superseded":
          return "[Superseded]";
        case "Terminated":
          return "[Terminated]";
        case "Transferred":
          return "[Transferred]";
        case "Other":
          return "[Other]";
        case "Published":
          return "[Published]";
        default:
          return "";
          break;
      }
    }
  };

  const style = {
    // marginLeft: props.agencyList.values. * 3,
  };

  const handleSubmit = (e: any, item: any) => {
    props.handleSubscribeSubmit!(e, item, "chapter");
  };

  const getSegmentNum = (segment: any) => {
    if (segment.name == "Chapter") {
      return segment.chapterNum;
    } else if (segment.name == "Subchapter") {
      return segment.subChapterNum;
    } else if (segment.name == "Section") {
      return segment.sectionNum;
    } else if (segment.name == "Appendix") {
      return segment.appendixNum;
    } else if (segment.name == "Part") {
      return segment.partNum;
    } else if (segment.name == "Title") {
      return segment.titleNum;
    }
    // item.chapterNum | item.subChapterNum | item.sectionNum | item.appendixNum
  };

  const _renderAccordion = (item: any) => {
    return (
      <Accordion
        className={props.className || "test"}
        key={item.id}
        expanded={expanded === `${item.id}`}
        onChange={handleChange(`${item.id}`)}
        onClick={() => {
          console.error("clicked");
        }}
        TransitionProps={{
          unmountOnExit: true,
          // timeout: 100
        }}
      >
        <AccordionSummary
          className="label"
          aria-controls="panel2d-content"
          aria-label="Agency Listing"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          {item.titleNum == "1" ? (
            item.name + " " + item.execOrderNum + " " + getStatusTag(item)
          ) : (
            <div className="content-container">
              <div style={{ display: "flex" }}>
                <Typography>
                  {item.name +
                    " " +
                    getSegmentNum(item) +
                    ". " +
                    item.description +
                    " " +
                    getStatusTag(item)}
                </Typography>
                {item.hasEmergency == true ? (
                  <EmergencyModalButton filingId={item.filingId} />
                ) : null}
              </div>
              {(props.isCurrentUserLoggedIn) ?
                props.subscribedTitleSegmentId !== undefined ? (
                  <Tooltip title="You are subscribed to all Chapters in this Title">
                    <IconButton
                      onClick={(e) => handleSubmit(e, item)}
                      className="subscribe-button"
                      color="primary"
                      size="small"
                    >
                      <CheckCircleOutline />
                    </IconButton>
                  </Tooltip>
                ) : props.userSubscriptions!.length === 0 || !props.userSubscriptions?.find((x) => x.chapterSegmentId == item.id)
                  ? (
                    <Tooltip title="Subscribe to this Chapter">
                      <IconButton
                        onClick={(e) => handleSubmit(e, item)}
                        className="subscribe-button"
                        color="primary"
                        size="small"
                      >
                        <AddAlert />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="You are subscribed to this Chapter">
                      <IconButton
                        onClick={(e) => handleSubmit(e, item)}
                        className="subscribe-button"
                        color="primary"
                        size="small"
                      >
                        <CheckCircleOutline />
                      </IconButton>
                    </Tooltip>
                  ) : null}
            </div>
          )}
        </AccordionSummary>
        {/* if value details exist then render card */}
        {item.name == "Chapter" ||
          item.name == "Subchapter" ||
          item.name == "Part" ||
          item.name == "Executive Order" ? (
          <ChildCodeCard segment={item} />
        ) : null}
        {item.name == "Section" ||
          item.name == "Appendix" ||
          item.name == "Executive Order" ? (
          <ExpansionDetail segment={item} noteTypes={props.noteTypes} />
        ) : (
          <NestedExpansions
            className={item.name}
            segments={childSegments}
            open={item.id}
            title={props.title}
            segmentId={item.id}
            noteTypes={props.noteTypes}
            handleSubscribeSubmit={props.handleSubscribeSubmit}
          />
        )}
      </Accordion >
    );
  };

  const _renderExecYears = (item: any) => {
    return (
      <Accordion
        className={props.className || ""}
        key={`${item.execOrderYear}-year`}
        expanded={expanded === `${item.execOrderYear}-year`}
        onChange={handleChange(
          `${item.execOrderYear}-year`,
          item.execOrderYear
        )}
        TransitionProps={{
          unmountOnExit: true,
          timeout: 100,
        }}
      >
        <AccordionSummary
          className="label"
          aria-controls="panel2d-content"
          aria-label="Agency Listing"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          {item.execOrderYear}
        </AccordionSummary>

        {childSegments ? (
          <ExecOrderExpansion
            className="Executive"
            childSegments={childSegments}
            noteTypes={props.noteTypes}
          />
        ) : null}
      </Accordion>
    );
  };

  // main return calls accoridon render
  return (
    <Fragment>
      {props.segments == null && props.title && props.titleLevel ? (
        <ChildCodeCard
          expanded={props.open}
          title={props.title.referenceCode}
          email={props.email}
        />
      ) : null}
      {segments
        ? segments.map((item: any, index: number) => {
          if (item) {
            return _renderAccordion(item);
          }
        })
        : null
      }
      {
        props.segments
          ? props.segments.map((item: any, index: number) => {
            if (item) {
              return _renderAccordion(item);
            }
          })
          : null
      }
      {
        execOrderYears && props.open
          ? execOrderYears.map((item: any) => {
            return _renderExecYears(item);
          })
          : null
      }
    </Fragment >
  );
};
