import React, { ChangeEvent } from "react";
import SearchIcon from "@material-ui/icons/Search";
import "./Search.scss";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";

interface ISearchProps {
  id: string;
}

export const Search = (props: ISearchProps) => {
  const [query, setQuery] = React.useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const queryChange = event.target.value;
    setQuery(queryChange);
  };

  // const queryInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
  //   if (e.key === "Enter") {
  //     const url = window.location.origin + "/code?q=" + query;
  //     window.location.href = url;
  //   }
  // };

  const queryInputButton = (e: any) => {
    const url = window.location.origin + "/code?q=" + query;
    window.location.href = url;
  };

  const handleKeyPress = (event: any) => {

    if (event.key === 'Enter') {
      const url = window.location.origin + "/code?q=" + query;
      window.location.href = url;

    }
  }

  const filterSearch = (value: string) => {
    const url = window.location.origin + "/code?q=" + query + "&filter=" + "true";
    window.location.href = url;
  };

  const navigateToPage = (value: string) => {
    const url = window.location.origin + "/" + value;
    window.location.href = url;
  }

  return (
    <div className="search">
      <div className="search-bar">
        <TextField
          id="search"
          className="search-input"
          variant="filled"
          label="Search"
          fullWidth
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          // onKeyDown={(e) => queryInput(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Execute search"
                  onClick={(query) => queryInputButton(query)}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="search-tools">
        <Button
          className="search-button"
          variant="contained"
          onClick={() => navigateToPage("proposedrules")}
        >
          Proposed Rules
        </Button>
        {/* <Button
          className="search-button"
          variant="outlined"
          onClick={() => filterSearch("current")}
        >
          Current Rules
        </Button> */}
        {/* <Button
          className="search-button"
          variant="outlined"
          onClick={() => filterSearch("proposed")}
        >
          Proposed Rules
        </Button> */}
        {/* <Button
          className="search-button"
          variant="outlined"
          onClick={() => filterSearch("emergency")}
        >
          Emergency Rules
        </Button>
      */}
        <Button
          className="search-button"
          variant="contained"
          onClick={() => navigateToPage("feeIncrease")}
        >
          Fee Increases
        </Button>
      </div>
    </div>
  );
};
