export class FilingActionRequest {
    constructor(actionType: string, filingId: number, actionId: number) {
        this.actionType = actionType;
        this.filingId = filingId;
        this.actionId = actionId;
    };

    public actionType: string;
    public filingId: number;
    public actionId: number;
}