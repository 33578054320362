import { Identifiable } from "./Identifiable";

export class Docket extends Identifiable {
    constructor(year: number, documentNumber: number) {
        super(null);
        this.year = year;
        this.documentNumber = documentNumber;
    }
    public year: number;
    public documentNumber: number;
}