/*
    This Component will need to implement notifications and notification types in the future
    We currently aren't worrying about that. Notifications are setup in the API and just need
    to be uncommented to be incorperated
 */
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  EnumSelectInput,
  Icon,
  IconSelector,
} from "../../../../../../../components";
import { actionAPI, codeActionAPI } from "../../../../../../../utils/api";
import {
  ActionType as ActionTypes,
  ExecutionPermission,
  VisibilityLevel,
} from "../../../../../../../utils/enums";
// import { ActionType } from "../../../../../../../utils/enums/ActionType";
import { ActionDisplayType } from "../../../../../../../utils/enums/ActionDisplayType";
import { Action, ActionType } from "../../../../../../../utils/model";
import { Status } from "../../../../../../../utils/model/Status";
import { ActionNotification } from "../ActionNotification";
// local imports
import "./ActionExpansion.scss";

interface IActionExpansionProps {
  action?: Action;
  status: Status;
  statusList?: Status[];
  nextStatus?: Status;
  triggerReload: () => void;
}

export const ActionExpansion = (props: IActionExpansionProps) => {
  const [edited, setEdited] = useState(false);
  const [actionTypes, setActionTypes] = useState<any[]>([]);
  const [statusAlert, setStatusAlert] = useState(false);
  const [actionValues, setActionValues] = React.useState({
    name: "-1",
    icon: "",
    description: "",
    codeAction: "",
    nextStatus: props.action?.targetStatus
      ? props.action?.targetStatus
      : props.status,
    displayType: ActionDisplayType.Approval_Confirm,
    sortOrder: 0,
    additionalInformation: "",
    visibility: VisibilityLevel.Admin,
    executePermissions: ExecutionPermission.Admin,
  });

  const getCodeActions = React.useCallback(async () => {
    let actions = await codeActionAPI.getAll();
    setActionTypes(actions);

    generateDropdown(actions);
  }, []);

  useEffect(() => {
    if (props.action) {
      setActionValues({
        ...actionValues,
        name: props.action.name,
        icon: props.action.actionType ? props.action.actionType.icon : "build",
        description: props.action.description,
        codeAction: props.action.codeAction,
        nextStatus: props.action?.targetStatus
          ? props.action.targetStatus
          : actionValues.nextStatus,
        displayType: props.action.displayType,
        sortOrder: props.action.sortOrder,
        additionalInformation: props.action.additionalInformation,
      });
    }
    getCodeActions();
  }, [props.action, getCodeActions]);

  function generateDropdown(response: any) {
    if (Array.isArray(response)) {
      let val: any = [];
      response.forEach((values: any) => {
        val.push(values);
      });
      setActionTypes(val);
    }
  }

  function _renderDropdown() {
    let menuItems: any = [];
    actionTypes.forEach((id) => {
      if (id != null) {
        menuItems.push(
          <MenuItem key={id} value={id}>
            {id}
          </MenuItem>
        );
      }
    });
    if (menuItems.length < 0) {
      menuItems = [""];
    }
    return menuItems;
  }

  function _renderTargetDropdown() {
    let targetMenuItems: any = [];
    if (props.statusList) {
      props.statusList.forEach((element, index) => {
        if (element != null) {
          targetMenuItems.push(
            <MenuItem key={index + element.name} value={element!.id!}>
              {element.name}
            </MenuItem>
          );
        }
      });
    }
    if (targetMenuItems.length < 0) {
      targetMenuItems = [""];
    }
    return targetMenuItems;
  }

  const saveForm = async () => {
    setStatusAlert(false);
    try {
      if (props.status.id != null) {
        let actionType = new ActionType(1, "Accept", "build");
        let action = new Action(
          actionValues.name,
          actionValues.description,
          actionValues.codeAction,
          props.status,
          actionValues.nextStatus,
          actionValues.displayType,
          actionValues.visibility,
          actionValues.executePermissions,
          actionValues.sortOrder,
          actionValues.additionalInformation,
          actionType
        );

        if (props.action) {
          // if its the default values then no changes were made
          if (edited) {
            action.id = props.action.id;
            await actionAPI.update(props.action!.id!, action);
            props.triggerReload();
          } else {
          }
        } else {
          delete action.id;
          await actionAPI.create(action);
          props.triggerReload();
        }
      }
    } catch (error) {
      console.error("Error on saving form: ", error);
    }
  };

  const deleteAction = async () => {
    try {
      if (props.action) {
        await actionAPI.delete(props.action!.id!);
        props.triggerReload();
      }
    } catch (error) {
      console.error("Error deleting action: ", error);
    }
  };

  const iconSelect = (newIcon: string) => {
    setActionValues({ ...actionValues, icon: newIcon });
    setEdited(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setActionValues({ ...actionValues, [name]: value });
    setEdited(true);
  };

  const handleDisplayTypeChange = (val: number) => {
    setActionValues({ ...actionValues, displayType: val });
  };

  const handleVisibilityChange = (val: number) => {
    setActionValues({ ...actionValues, visibility: val });
  };

  const handleExecutionPermissionChange = (val: number) => {
    setActionValues({ ...actionValues, executePermissions: val });
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    if (value === "Next Status") {
      setStatusAlert(true);
    } else {
      setStatusAlert(false);
    }
    if (actionTypes != null) {
      let val = actionTypes.find((i) => i === event.target.value);
      if (val) {
        setActionValues({ ...actionValues, codeAction: val });
      }
      setEdited(true);
    }
    // }
  };

  const handleTargetSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (actionTypes != null) {
      let val = props.statusList!.find((i) => i.id === event.target.value);
      if (val) {
        setActionValues({ ...actionValues, nextStatus: val });
      }
      setEdited(true);
    }
    // }
  };

  const currId = uuid();

  return (
    <div>
      {props.action ? (
        <Accordion className="action-expansion">
          <AccordionSummary
            className="panel-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel1a-content${props.action.id}`}
            id={`panel1a-header${props.action.id}`}
          >
            {props.action.name}

            {props.action?.actionType.icon !== undefined ? (
              <Icon icon={props.action?.actionType.icon} />
            ) : null}
          </AccordionSummary>
          <AccordionDetails>
            <div className="action-form">
              <TextField
                id={`name-text-field${currId}`}
                fullWidth
                variant="filled"
                className="text-field"
                label="Name"
                defaultValue={props.action.name}
                onChange={handleChange}
              />
              <TextField
                id={`description-text-field${currId}`}
                fullWidth
                variant="filled"
                className="text-field"
                label="Description"
                defaultValue={props.action.description}
                onChange={handleChange}
              />
              <InputLabel
                id={`action-select${currId}`}
                className="select-label"
              >
                Action Type
              </InputLabel>
              <Select
                labelId={`action-select${currId}`}
                variant="filled"
                className="dropdown"
                margin="dense"
                label="Action Type"
                name="contactSelect"
                value={actionValues.codeAction}
                onChange={handleSelectChange}
                fullWidth
              >
                {_renderDropdown()}
              </Select>
              <InputLabel
                id={`target-select${currId}`}
                className="select-label"
              >
                Target Status
              </InputLabel>
              <Select
                labelId={`target-select${currId}`}
                variant="filled"
                className="dropdown"
                margin="dense"
                label="Target Status"
                name="targetSelect"
                value={actionValues.nextStatus.id}
                onChange={handleTargetSelectChange}
                fullWidth
              >
                {_renderTargetDropdown()}
              </Select>
              <EnumSelectInput
                enumerable={ActionDisplayType}
                value={actionValues.displayType}
                title="Display Type"
                onChange={handleDisplayTypeChange}
                className="status-select"
              />

              <TextField
                id={`sort-text-field${currId}`}
                fullWidth
                variant="filled"
                name="sortOrder"
                className="text-field"
                type="number"
                label="Sort Order"
                defaultValue={props.action.sortOrder}
                onChange={handleChange}
              />
              <TextField
                id={`addtlInfo-text-field${currId}`}
                fullWidth
                variant="filled"
                name="additionalInformation"
                className="text-field"
                label="Additional Information"
                defaultValue={props.action.additionalInformation}
                onChange={handleChange}
              />
              <ActionNotification action={props.action} />
              <div className="form-icon-select">
                <Typography className="form-icon-text">
                  {" "}
                  Select Icon{" "}
                </Typography>
                <IconSelector
                  onSelect={iconSelect}
                  icon={actionValues.icon || props.action.actionType ? props.action.actionType.icon : "build"}
                />
              </div>
            </div>
          </AccordionDetails>
          <AccordionActions>
            <Button
              size="small"
              onClick={() => {
                deleteAction();
              }}
            >
              Delete
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                saveForm();
              }}
            >
              Update Action
            </Button>
          </AccordionActions>
        </Accordion>
      ) : (
        <Accordion className="action-expansion">
          <AccordionSummary
            className="new-panel-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-newAction"
          >
            Create a New Action
          </AccordionSummary>

          <AccordionDetails>
            <div className="action-form">
              <TextField
                id={`name-text-field2${currId}`}
                fullWidth
                variant="filled"
                name="name"
                className="text-field"
                label="Name"
                defaultValue=""
                onChange={handleChange}
              />
              <TextField
                id={`description-text-field2${currId}`}
                fullWidth
                variant="filled"
                name="description"
                className="text-field"
                label="Description"
                //
                defaultValue=""
                onChange={handleChange}
              />
              {statusAlert && props.nextStatus === undefined ? (
                <div className="status-alert">
                  Make the next status exists in this workflow before saving
                </div>
              ) : null}
              <InputLabel
                id={`action-select2${currId}`}
                className="select-label"
              >
                Action Type
              </InputLabel>
              <Select
                variant="filled"
                className="dropdown"
                margin="dense"
                labelId={`action-select2${currId}`}
                label="Action Type"
                name="contactSelect"
                value={actionValues.codeAction}
                onChange={handleSelectChange}
                fullWidth
              >
                {_renderDropdown()}
              </Select>
              <EnumSelectInput
                enumerable={VisibilityLevel}
                value={actionValues.visibility}
                title="Visibility Type"
                onChange={handleVisibilityChange}
                className="status-select"
              />
              <EnumSelectInput
                enumerable={ExecutionPermission}
                value={actionValues.executePermissions}
                title="Execution Permission"
                onChange={handleExecutionPermissionChange}
                className="status-select"
              />
              <InputLabel
                id={`target-select2${currId}`}
                className="select-label"
              >
                Target Status
              </InputLabel>
              <Select
                variant="filled"
                className="dropdown"
                margin="dense"
                labelId={`target-select2${currId}`}
                label="Target Status"
                name="targetSelect"
                value={actionValues.nextStatus.id}
                onChange={handleTargetSelectChange}
                fullWidth
              >
                {_renderTargetDropdown()}
              </Select>
              <EnumSelectInput
                enumerable={ActionDisplayType}
                value={actionValues.displayType}
                title="Display Type"
                onChange={handleDisplayTypeChange}
                className="status-select"
              />
              <TextField
                id={`sort-text-field2${currId}`}
                fullWidth
                variant="filled"
                name="sortOrder"
                type="number"
                className="text-field"
                label="Sort Order"
                defaultValue=""
                onChange={handleChange}
              />
              <TextField
                id={`addltInfo-text-field2${currId}`}
                fullWidth
                variant="filled"
                name="additionalInformation"
                className="text-field"
                label="Additional Information"
                defaultValue=""
                onChange={handleChange}
              />
              <div className="form-icon-select">
                <Typography className="form-icon-text">
                  {" "}
                  Select Icon{" "}
                </Typography>
                <IconSelector onSelect={iconSelect} icon={actionValues.icon} />
              </div>
            </div>
          </AccordionDetails>
          <AccordionActions>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                saveForm();
                props.triggerReload();
              }}
            >
              Create Action
            </Button>
          </AccordionActions>
        </Accordion>
      )}
    </div>
  );
};
