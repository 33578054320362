import React, { ReactFragment } from "react";
import "./ConfirmDialog.scss";
import {
  Dialog,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { ActionIcon } from "../ActionIcon";

interface IConfirmDialogProps {
  children: ReactFragment;
  message: string;
  callback: () => void;
}

export const ConfirmDialog = (props: IConfirmDialogProps) => {
  const { children, message, callback } = props;
  const [open, setOpen] = React.useState(false);
  const close = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };
  const affirmativeResponse = (event: any) => {
    callback();
    close(event);
  };
  const negativeResponse = (event: any) => {
    close(event);
  };
  return (
    <div onClick={() => setOpen(true)} className="confirm-dialog">
      {children}
      <Dialog open={open} onClose={close}>
        <Card>
          <CardContent>
            <Typography>{message}</Typography>
          </CardContent>
          <ActionIcon
            onClick={affirmativeResponse}
            icon="Check"
            label="Yes"
            color="success"
          />
          <ActionIcon
            onClick={negativeResponse}
            icon="Close"
            label="No"
            color="danger"
          />
        </Card>
      </Dialog>
    </div>
  );
};
