import { IconButton, MenuItem, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Filing,
  FilingType,
  Rule,
} from "../../../../../../utils/model";
import { Row, Select, TextField } from "./";
import { EditorType } from "./../../../../../../utils/enums";
import { RULE_PLURAL_NAME } from "./../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";

interface INoticeEditorsProps {
  filingType: FilingType;
  filing?: Filing;
  renderRuleDropdown: () => any;
  // renderContactDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  // attestationList: Contact[];
  ruleList: Rule[];
  saveForm: (item: any, type: string) => void;
  returnContactListAsync: (ruleId:number,isAttestation:boolean) => any;
}

const getInitialState = (props: INoticeEditorsProps) => ({
  filingName: "",
  formName: "NoDraft",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  chapter: props.ruleList[0],
  rule: props.ruleList[0],
  chapterId: -1,
  ruleId: -1,
  text: "",
  segments: [],
});

export let NoticeEditorsForm = React.forwardRef(
  (props: INoticeEditorsProps, ref) => {
    const [formValues, handleChange] = useFormState(
      { ...getInitialState(props), ...props.filing?.entity },
      ref
    );

    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const setChapterListFromRuleId = React.useCallback(async (ruleId: number) => {
      const chapters = await props.returnChapterListAsync(ruleId);
      setChapterList(chapters);
    }, [props]
    );
    const [text, setText] = React.useState<string>(formValues.text);
    const [diff, setDiff] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };


    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);
        setAttestationListFromRuleId(props.ruleList[0]?.id!);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }
      } else if (props.filing) {
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
        setAttestationListFromRuleId(entity.rule.id);
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const setLiaisonListFromRuleId = React.useCallback(async(ruleId:number) => {
        const liaisons = await props.returnContactListAsync(ruleId, false);
        setRuleContactList(liaisons);
      },[props]
    );

    const setAttestationListFromRuleId = React.useCallback(async(ruleId:number) => {
        const attestation = await props.returnContactListAsync(ruleId, true);
        setAttestationContactList(attestation);
      },[props]
    );

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i: any) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
          setAttestationListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const textChange = (value: string) => {
      formValues.text = value;
    };

    const currId = uuid();

    return (
      <FormProvider values={formValues} onChange={handleChange}>
        <div className="flex-column">
          <Typography variant="h6">EDITOR'S NOTICE</Typography>
          <TextField
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row>
            <Select
              label="TITLE"
              name="rule"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem className="drop-down-item" key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>
          <RichTextEditor
            text={text}
            editorType={EditorType.segment}
            onTextChange={textChange}
            height={"50vh"}
            width={"auto"}
            disable={readOnly}
          />
        </div>
      </FormProvider>
    );
  }
);
