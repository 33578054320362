import { Owning } from "../model/Owning";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class OwningAPI extends BaseAPI<Owning> {
  constructor() {
    super("owning", APISettings.adminRulesApiUrl);
  }
}

export const owningAPI = new OwningAPI();
