export enum StorageContainer {
  PublicSegments,
  PublicAdministrativeCode,
  PublicRegister,
  PublicCms,
  Temp,
  TempPublicRegister,
  Backups,
  FilingUploads,
  TitleHtml,
  titlepdf,
  FullCodeReports,
  HistoricTitlePdf,
}
