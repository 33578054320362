import React from 'react'
import { useCodeFullEditionController } from './CodeFullEditionController'
import { Button, CircularProgress, InputLabel, MenuItem, Select, Snackbar } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { CloudDownload } from '@material-ui/icons'
import "./CodeFullEdition.scss"
import Alert from '@material-ui/lab/Alert/Alert';

const CodeFullEdition = () => {
  const {
    // functions
    getFirstTwoDigitsOfYear,
    // getCodeFullEditionVolumes,
    scheduleGeneration,
    selectVolume,
    getTitleName,
    downloadFile,
    downloadZipDoc,
    handleSnackClose,
    alertType,
    // variables
    loading,
    selectedVolume,
    codeFullEditionIssues,
    codeFullEditionVolumes,
    snackMessage,

  } = useCodeFullEditionController();

  return (
    <div>
      {/* Alert message */}
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!snackMessage}
          autoHideDuration={4000}
          onClose={handleSnackClose}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={alertType}
          >
            {snackMessage ?? ""}
          </Alert>
        </Snackbar>
      </div>
      <div>
        <div className="float-right button-margin">
          {/* <Button variant="contained" color="primary" onClick={downloadZipDoc} startIcon={<CloudDownload />}>Download All</Button> */}
          <Button variant="contained" color="default" startIcon={<AccessTime />} onClick={scheduleGeneration}>Queue Generation of Full Code</Button>
        </div>
      </div>
      <div className="report body">
        <InputLabel>Select Edition</InputLabel>
        <Select
          label="Select Edition"
          value={selectedVolume ? selectedVolume : -1}
          style={{ "width": "50vw" }}
          onChange={selectVolume}
        >
          {!selectedVolume ? (<MenuItem value={-1}>No Volumes Found</MenuItem>) : null}
          {codeFullEditionVolumes ? codeFullEditionVolumes.map((volume: any, index: number) => {
            return (
              <MenuItem key={'Volume' + volume.volumeNumber + index} value={volume.volumeNumber}>{getFirstTwoDigitsOfYear()}{volume.volumeNumber} Edition</MenuItem>
            )
          }) : null}
        </Select>
        {loading ? (
          <div className="loading-spinner"><CircularProgress color="inherit" size={20} /><div>Loading</div></div>
        ) : (
          <>
            {/* Standard volume */}
            {codeFullEditionIssues?.map((edition: any, index: number) => {
              return (
                <div className="download-card">
                  <div className="card-body">
                    <Button variant="contained" color="primary" onClick={() => downloadFile(edition)} startIcon={<CloudDownload />}>Download</Button>
                    {edition.referenceCode ? (
                      <>
                        <div><b>Title Name.</b> {getTitleName(edition.referenceCode)}</div>
                        <div><b>Title Number.</b> {edition.referenceCode}</div>
                      </>
                    ) : (
                      <div><b>General Index</b></div>
                    )}
                    <div><b>Edition:</b> {getFirstTwoDigitsOfYear()}{edition.volumeNumber}</div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div >
  )
}

export default CodeFullEdition