import { VisibilityLevel, PublicationStatus } from "../enums";
import { Action, Identifiable } from ".";
import { Status } from "./Status";
import { Amendment } from "./Amendment";
import { TodoList } from "./TodoList";
import { TemplateValue } from "./TemplateValue";
import { CommentThread } from "./CommentThread";
import { FilingHistory } from "./FilingHistory";
import { Task } from "./Task";

type PermanentEmergencyRulemakingActions = "Permanent" | "Emergency";
export const PermanentEmergencyRulemakingAction: Record<
  PermanentEmergencyRulemakingActions,
  string
  > = {
    Permanent: "Legislative disapproval of PERMANENT rules",
    Emergency: "Legislative disapproval of EMERGENCY rules"
  };

type ContinueRulemakingActions =
  | "CommentEmergency"
  | "HearingEmergency"
  | "CommentPermanent"
  | "HearingPermanent"
export const ContinueRulemakingAction: Record<
  ContinueRulemakingActions,
  string
> = {
  CommentEmergency: "Continued comment period and/or public hearing relating to a proposed EMERGENCY rulemaking action",
  HearingEmergency: "Continued Public Hearing Relating to Emergency Rulemaking Action",
  CommentPermanent: "Continued comment period and/or public hearing relating to a proposed PERMANENT rulemaking action",
  HearingPermanent: "Continued Public Hearing Relating to Permanent Rulemaking Action",
};

type CancelRulemakingActions =
  | "CommentEmergency"
  | "HearingEmergency"
  | "CommentPermanent"
  | "HearingPermanent"
export const CancelRulemakingAction: Record<CancelRulemakingActions, string> = {
  CommentEmergency:
    "Cancelled comment period and/or public hearing relating to a proposed EMERGENCY rulemaking action",
  HearingEmergency:
    "Cancelled Public Hearing Relating to Emergency Rulemaking Action",
  CommentPermanent:
    "Cancelled comment period and/or public hearing relating to a proposed PERMANENT rulemaking action",
  HearingPermanent:
    "Cancelled Public Hearing Relating to Permanent Rulemaking Action",
};

type SubmissionRulemakingActions = "Submission";
export const SubmissionRulemakingAction: Record<
  SubmissionRulemakingActions,
  string
> = { Submission: "Submission to Governor and Legislature" };

type ErrorRulemakingActions = "Error";
export const ErrorRulemakingAction: Record<ErrorRulemakingActions, string> = {
  Error: "Notice of error in published document",
};

type WithdrawalRulemakingActions = "Permanent" | "Emergency";
export const WithdrawalRulemakingAction: Record<
  WithdrawalRulemakingActions,
  string
> = {
  Permanent: "Withdrawal of PERMANENT rulemaking",
  Emergency: "Withdrawal of EMERGENCY rulemaking",
};

type NoticeNRIRulemakingActions = "Permanent" | "Emergency";
export const NoticeNRIRulemakingAction: Record<
  NoticeNRIRulemakingActions,
  string
> = {
  Permanent: "Notice of proposed PERMANENT rulemaking",
  Emergency: "Notice of proposed EMERGENCY rulemaking",
};

type AdoptedEmergencyRulemakingActions = "AdoptedEmergency";
export const AdoptedEmergencyRulemakingAction: Record<
  AdoptedEmergencyRulemakingActions,
  string
> = {
  AdoptedEmergency: "Adopted Emergency Rules",
};

type PreemptiveRulemakingActions = "Preemptive";
export const PreemptiveRulemakingAction: Record<
  PreemptiveRulemakingActions,
  PreemptiveRulemakingActions
> = {
  Preemptive: "Preemptive",
};

type FeeIncreaseRulemakingActions = "FeeIncrease";
export const FeeIncreaseRulemakingAction: Record<
  FeeIncreaseRulemakingActions,
  string
> = {
  FeeIncrease: "Notification of Fee Increase",
};

type EmergencyRulemakingActions = "Emergency";
export const EmergencyRulemakingAction: Record<
  EmergencyRulemakingActions,
  string
> = {
  Emergency: "EMERGENCY adoption",
};

type PermanentFinalRulemakingActions = "PermanentFinal";
export const PermanentFinalRulemakingAction: Record<
  PermanentFinalRulemakingActions,
  string
> = {
  PermanentFinal: "PERMANENT final adoption",
};

type PreemptiveAdoptionRulemakingActions = "PreemptiveAdoption";
export const PreemptiveAdoptionRulemakingAction: Record<
  PreemptiveAdoptionRulemakingActions,
  string
> = {
  PreemptiveAdoption: "PREEMPTIVE adoption",
};

type ExecutiveOrderRulemakingActions = "Executive";
export const ExecutiveOrderRulemakingAction: Record<
  ExecutiveOrderRulemakingActions,
  string
> = {
  Executive: "Executive Order",
};

type GovernorDeclarationRulemakingActions = "Governor";
export const GovernorDeclarationRulemakingAction: Record<
GovernorDeclarationRulemakingActions,
  string
> = {
  Governor: "Governor Declaration",
};

// filing model definition
export class Filing extends Identifiable {
  constructor(
      name: string,
      statusId: number,
      published: PublicationStatus,
      visibility: VisibilityLevel,
      filingTypeId: number,
      includeInRegister: boolean,
      fileTypeCanBePublished: boolean,
      ruleId: number,
      chapterId: number,
      draftId?: number,
      registerId?: number | null,
      entity?: { [key: string]: any },
      filingHistory?: FilingHistory[],
      tasks?: Task[],
      amendments?: Amendment[],
      todoLists?: TodoList[],
      templateValues?: TemplateValue[],
      commentThread?: CommentThread,
      created?: any,
      liaisonId?: number,
      docketId?: number,
      docketNumber:string = '',
      adoptionDate?: Date | string,
      effectiveOn?: Date,
      submissionDate?: Date | string,
      acceptedDate?: Date | string,
      publicationDate? : Date | string,
      titleNumber: string = '',
      chapterNumber: string = '',
      showInReviewModal: boolean = true,
      isReadOnly: boolean = false,
      isInDraftState: boolean = false,
      rejectReason?: string,
      adoptedRuleDocumentPdf?:string

      ) {
        super(null);
        this.name = name;
        this.statusId = statusId;
        this.published = published;
        this.visibility = visibility;
        this.filingTypeId = filingTypeId;
        this.includeInRegister = includeInRegister;
        this.fileTypeCanBePublished = fileTypeCanBePublished;
        this.ruleId = ruleId;
        this.chapterId = chapterId;
        if (draftId) { this.draftId = draftId; }
        if(registerId) { this.registerId = registerId; }
        if (entity) { this.entity = entity; }
        this.ruleId = ruleId;
        this.chapterId = chapterId;
        if (draftId) { this.draftId = draftId; }
        if(registerId) { this.registerId = registerId; }
        if (entity) { this.entity = entity; }
        if (filingHistory) { this.filingHistory = filingHistory; }
        if (tasks) { this.tasks = tasks; }
        if (amendments) { this.amendments = amendments; }
        if (todoLists) { this.todoLists = todoLists; }
        if (templateValues) { this.templateValues = templateValues; }
        if (commentThread) { this.commentThread = commentThread; }
        if (created) { this.created = parseInt(created); }
        this.liaisonId = liaisonId;
        this.docketId = docketId;
        this.docketNumber = docketNumber;
        if(adoptionDate) {this.adoptionDate = adoptionDate;}
        if(effectiveOn) { this.effectiveOn = effectiveOn;}
        if(submissionDate) {this.submissionDate = submissionDate;}
        if(acceptedDate) {this.acceptedDate = acceptedDate;}
        if(publicationDate) {this.publicationDate = publicationDate;}
        this.titleNumber = titleNumber;
        this.chapterNumber = chapterNumber;
        this.showInReviewModal = showInReviewModal;
        this.isReadOnly = isReadOnly;
        this.isInDraftState = isInDraftState;
        if(rejectReason) { this.rejectReason = rejectReason; }
        if(adoptedRuleDocumentPdf) {this.adoptedRuleDocumentPdf = adoptedRuleDocumentPdf}
      };

  public name: string;
  public statusId: number;
  public docketNumber?: string;
  public titleNumber: string = '';
  public chapterNumber: string = '';
  public published: PublicationStatus;
  public visibility: VisibilityLevel;
  public filingTypeId: number;
  public includeInRegister: boolean;
  public fileTypeCanBePublished: boolean;
  // This attribute should not be constructed or modified as it will be populated when a filing is fetched
  // This attribute should not be constructed or modified as it will be populated when a filing is fetched
  public entity?: { [key: string]: any };
  public filingHistory?: FilingHistory[];
  public filingType?: Filing;
  public tasks?: Task[];
  public root?: Filing;
  public status?: Status;
  public amendments?: Amendment[];
  public todoLists?: TodoList[];
  public templateValues?: TemplateValue[];
  public commentThread?: CommentThread;
  public created?: any;
  public availableActions: Action[] = [];
  public ruleId: number;
  public chapterId: number;
  public draftId?: number;
  public liaisonId?: number;
  public docketId?: number;
  public adoptionDate?:  Date | string;
  public submissionDate?: Date | string;
  public acceptedDate?: Date | string;
  public publicationDate?: Date | string;
  public showInReviewModal: boolean = true;
  public isReadOnly: boolean = false;
  public isInDraftState: boolean = false;
  public registerId?: number;
  public rejectReason?: string;
  public effectiveOn?: Date;
  public adoptedRuleDocumentPdf?: string;
}
