import internal from "stream";
import { Identifiable } from "./Identifiable";
import { SegmentHistoryData } from "./SegmentHistoryData";

export class SegmentHistory extends Identifiable {
    getUTCHours(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUTCMinutes(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUTCSeconds(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUTCMonth(): number {
      throw new Error("Method not implemented.");
    }
    getUTCDate(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUtcHours(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUtcMinutes(): number | undefined {
      throw new Error("Method not implemented.");
    }
    getUtcSeconds(): number | undefined {
      throw new Error("Method not implemented.");
    }
    constructor(
        segmentId: number | null,
        draftId: number,
        segmentData: SegmentHistoryData,
        startRow: Date,
        endRow: Date,
        includeInDraft: boolean,
        isNewSegment: boolean = false,
        segmentStatusId?: number | null,
        lockedByUserId?: string | null,
        lastModified?: Date | string,

    ) {
        super(null);
        if(segmentId) {
            this.segmentId = segmentId;
        } else {
            this.segmentId = null;
        }
        this.draftId = draftId;
        this.segmentData = segmentData;
        this.startRow = startRow;
        this.endRow = endRow;
        this.includeInDraft = includeInDraft;
        this.isNewSegment = isNewSegment;
        if (segmentStatusId) { this.segmentStatusId = segmentStatusId };
        if (lockedByUserId) { this.lockedByUserId = lockedByUserId };
        if(lastModified) { this.lastModified = lastModified; };
    }

    public segmentId?: number | null;
    public draftId: number;
    public segmentData: SegmentHistoryData;
    public startRow: Date;
    public endRow: Date;
    public includeInDraft: boolean;
    public isNewSegment: boolean;
    public segmentStatusId?: number;
    public lockedByUserId?: string;
    public lastModified?: Date | string;
}