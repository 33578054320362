import { useState } from "react";

export const useSubscriptionProfileController = () => {
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<
    "error" | "success" | "warning" | null
  >(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const toggleAlert = () => {
    alertOpen ? setAlertOpen(false) : setAlertOpen(true);
  };

  const setMessage = (message?: string, type?: "error" | "success" | null) => {
    if (message && type) {
      setAlertMessage(message);
      setAlertType(type);
      alertOpen ? setAlertOpen(false) : setAlertOpen(true);
    }
  };

  return {
    // data
    alertMessage,
    alertType,
    alertOpen,
    // functions
    toggleAlert,
    setMessage,
  };
};
