import { Profile, User, UserSettings } from "oidc-client";

export type CommonClaims = "Create" | "View" | "Update" | "Delete";
export type ClaimTypes = "Filing" | "Organization" | "Registry" | "Reports";

export type ApplicationRoles =
"Agency Liaison"
| "Governor"
| "Global User Administrator"
| "Legislature"
| "Role Administrator"
| "Cabinet Secretary"
| "Attestation Officer"
| "Office of Administrative Rules";

interface IApplicationUserProfile extends Profile {
  Filing: CommonClaims[];
  Organization: CommonClaims[];
  Registration: CommonClaims[];
  Reports: CommonClaims[];
  Titles: string[];
  title: string;
  name: string;
  firstName: string;
  lastName: string;
  photo: string;
  officePhone: string;
  role: ApplicationRoles[];
}

interface IApplicationUserSettings extends UserSettings {
  profile: IApplicationUserProfile;
}

export class ApplicationUser extends User {
  constructor(userSettings: IApplicationUserSettings) {
    super(userSettings);
    this.profile = userSettings.profile;
  }
  profile: IApplicationUserProfile;
}

const systemUserSettings: IApplicationUserSettings = {
  id_token: "",
  session_state: "",
  access_token: "",
  refresh_token: "",
  token_type: "",
  scope: "",
  expires_at: 0,
  state: "",
  profile: {
    iss: "",
    sub: "",
    aud: "",
    exp: 0,
    iat: 0,
    Filing: [],
    Organization: [],
    Registration: [],
    Reports: [],
    Titles: [],
    title: "Administrative Rules System",
    name: "System",
    firstName: "System",
    lastName: "",
    photo: "System",
    officePhone: "",
    role: [],
  },
};

export const SystemUser = new ApplicationUser(systemUserSettings);
