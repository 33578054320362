import {Card, CardBody, CardSubtitle} from "@progress/kendo-react-layout";
import * as React from "react";
import {Field} from "@progress/kendo-react-form";
import {FormInput} from "../../Helpers/form-components";
import {passwordValidator} from "../../Helpers/Validators";


export const RegisterPasswordForm = (
    <div style={{marginTop: "2em"}}>
        <Card>
            <CardSubtitle style={{textAlign: "left", border: "none"}}>
                <p>Enter a password to protect your subscriber preferences using the following rules.</p>

                <ul>
                    <li>Must be at least 10 characters long</li>
                    <li>Must contain at least one uppercase letter</li>
                    <li>Must contain at least one lowercase letter</li>
                    <li>Must contain at least one number</li>
                    <li>Must contain at least one special character</li>
                </ul>
            </CardSubtitle>
            <CardBody>
                <Field
                    key={"password"}
                    id={"password"}
                    name={"password"}
                    label={"Password"}
                    type={"password"}
                    component={FormInput}
                    validator={passwordValidator}
                />
                <Field
                    key={"confirmPassword"}
                    id={"confirmPassword"}
                    name={"confirmPassword"}
                    label={"Confirm Password"}
                    type={"password"}
                    component={FormInput}
                    validator={passwordValidator}
                />
            </CardBody>
        </Card>
    </div>
)
