import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment, useState } from "react";
import { Authorized } from "..";
import { cmsItemAPI } from "../../utils/api";
import { StorageContainer } from "../../utils/enums";
import { CmsItem } from "../../utils/model";
import { Photo } from "@material-ui/icons";
import { FileUpload } from "../FileUpload";
import "./FileUploadDialog.scss";

interface IFileUploadDialogProps {
  cmsItem: CmsItem;
  onChange?: (name: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // margin: 0,
      // padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles({
  btn: {
    paddingTop: 10,
    paddingRight: 45,
  },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  // root: {
  //   padding: theme.spacing(2),
  // },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const FileUploadDialog = (props: IFileUploadDialogProps) => {
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUpload = (guid: string, fileName: string) => {
    setFileName(guid);
  };

  const onSave = async () => {
    if (!fileName || fileName === "" || !props.cmsItem.id) {
      handleClose();
      return;
    }

    const cmsItem = props.cmsItem;
    cmsItem.value = fileName;
    await cmsItemAPI.updateCmsItems(cmsItem);

    if (props.onChange) {
      props.onChange(cmsItem.name);
    }

    handleClose();
  };

  return (
    <Fragment>
      <div className="image-edit-wrapper">
        <Authorized roles={["Office of Administrative Rules", "Global User Administrator"]}>
          <IconButton className="image-edit-position" onClick={handleClickOpen}>
            <Photo />
          </IconButton>
        </Authorized>
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className={classes.btn}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {props.cmsItem.name}
          </DialogTitle>
        </div>
        <DialogContent dividers>
          <FileUpload
            onUploaded={onUpload}
            allowedFileTypes={["image"]}
            storageContainer={StorageContainer.PublicCms}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
