export enum StatusTypes {
    Accepted = 1,
    AgencyReview = 2,
    Approved = 3,
    Canceled = 4,
    Codified = 5,
    Complete = 6,
    Draft = 7,
    OarReview = 8,
    Pending = 9,
    Published = 10,
    Rejected = 11,
    Reworked = 12,
    Submitted = 13
}