import React, { Fragment, useEffect } from "react";
import Button from "@progress/kendo-react-buttons/dist/npm/Button";
import { Dialog } from "../../../../../components/Dialog";
import { Paragraph } from "../../../../../components/Paragraph";
import { draftAPI, segmentHistoryAPI } from "../../../../../utils/api";
import "./WorkQueuePreviewModal.scss";
import { Box, Card, Tab, Tabs } from "@material-ui/core";
import { AttachmentsTab } from "./Components";


interface IWorkQueuePreviewModal {
    open: boolean;
    close: () => void;
    filingData: any;
    // parentData:
}

export const WorkQueuePreviewModal = (props: IWorkQueuePreviewModal) => {
    const [html, setHtml] = React.useState<string>("");
    const [tabValue, setTabValue] = React.useState<string>("draft");
    const [attachments, setAttachments] = React.useState<any[]>([]);
    const [chapterData, setChapterData] = React.useState<string>("");
    const [titleData, setTitleData] = React.useState<string>("");

    const onMount = async () => {
        if (props.filingData.entity) {
            let entity = props.filingData.entity;
            let draftId = entity.draftId;
            if (draftId) {
                let draft: any = await draftAPI.compileDraft(draftId);
                // console.log(draft);
                setHtml(draft.data!.result!);
            } else {
                setHtml("<h4>No Draft Data Found</h4>")
            }
            if (entity.transmittalSheet) {
                // console.log(entity.transmittalSheet);
            }
            if (entity.rule) {
                setTitleData("<b>Title " + entity.rule.referenceCode + ".</b> " + entity.rule.title);
            }
            if (entity.chapter) {
                setChapterData("<b>Chapter " + entity.chapter.chapterNum + ".</b> " + entity.chapter.description);
            }
        }
        findAllAttachments();
    }

    /**
     * @description - finds all attachments for preview modal this consists of rule impact statements/agency rule reports on adopted filing forms
     */
    const findAllAttachments = () => {
        let entity = props.filingData.entity;
        let files: any[] = [];
        // console.log("ENTITY: ", entity);
        // get all rule impact files
        if (entity.ruleImpactReport) {
            let ruleImpactFiles: any = { "ruleImpact": [] }
            entity.ruleImpactReport.forEach((ruleImpact: any, index: number) => {
                if (ruleImpact.guid != '') {
                    ruleImpactFiles.ruleImpact.push(ruleImpact);
                }
            })
            files.push(ruleImpactFiles);
        }
        // get all agency rule reports
        let agencyRules: any = { "agencyRules": [] };
        if (entity.agencyRuleReport) {
            entity.agencyRuleReport.forEach((agencyRuleReport: any, index: number) => {
                if (agencyRuleReport.guid != '') {
                    agencyRules.agencyRules.push(agencyRuleReport);
                }
            })
            files.push(agencyRules)
        }
        let adoptedRules: any = { "adoptedRules": [] }
        if (props.filingData.adoptedRuleDocumentPdf) {
            adoptedRules.adoptedRules.push({ guid: props.filingData!.adoptedRuleDocumentPdf.substr(0, props.filingData!.adoptedRuleDocumentPdf.lastIndexOf('.')) + '.html', filename: `Adopted${props.filingData.name}.html` });
            files.push(adoptedRules);
        }
        // console.log(files);
        setAttachments(files);
    }

    useEffect(() => {
        if (props.open) {
            onMount();
        }
        return () => {
            setChapterData("");
            setTitleData("");
        }
    }, [props.open])

    /**
     * @description - sets a11yProps for tab control
     * @param index
     * @returns
     */
    function a11yProps(index: string) {
        return {
            id: `tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    /**
     * @description - Tab change handler
     * @param event
     * @param newValue
     */
    const handleTabChange = (newValue: string) => {
        // console.log(newValue);
        setTabValue(newValue);
    };

    /**
     * @description - PDF download function
     */
    let container: any = React.useRef<HTMLDivElement>(null);
    const printDiv = () => {

        let element: any = container.current;

        var printWindow: any = window.open("", "", "");
        printWindow.document.title = "test"
        printWindow.document.write(`<html><head><style type="text/css">
        @media print{
            .note {
                // display:flex;
                font-size: 11px !important;
                // margin-bottom: 12px;
                // margin-top: 12px;
            }
            .segment-title {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
                justify-content: center;
            }
            .segment-section-header {
                display: flex;
                font-family: "Times New Roman", Times, serif;
                font-size: 12pt;
                font-weight: bold;
            }
            .segment-item {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .preview-segment-note {
                > * {
                display: inline-block !important;
                }
                display: block !important;
                font-size: 11px !important;
                // float: left;
            }

            .center-note {
                display: flex;
                justify-content: center;
            }
            .inline-note-text {
                display: inline-block !important;
                font-size: 11pt !important;
                // float: left;
            }
            .inline-note-text > * {
                margin-bottom: 0px !important;
                margin-top: 0px !important;
                display:inline;
            }
        }
        .note {
            // display:flex;
            font-size: 11px !important;
            // margin-bottom: 12px;
            // margin-top: 12px;
        }
        .segment-title {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
            justify-content: center;
        }
        .segment-section-header {
            display: flex;
            font-family: "Times New Roman", Times, serif;
            font-size: 12pt;
            font-weight: bold;
        }
        .segment-item {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .preview-segment-note {
            > * {
            display: inline-block !important;
            }
            display: block !important;
            font-size: 11px !important;
            // float: left;
        }

        .center-note {
            display: flex;
            justify-content: center;
        }
        .inline-note-text {
            display: inline-block !important;
            font-size: 11pt !important;
            // float: left;
        }
        .inline-note-text > * {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            display:inline;
        }
        </style><title>${props.filingData.name}</title>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(element.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print()
    }

    const _renderSelectedView = () => {
        switch (tabValue) {
            case "draft":
                return <div role="tabpanel" className="container" ref={container}><Paragraph className="paragraph" value={html} /></div>
                break;
            case "files":
                return <AttachmentsTab attachments={attachments} />
            default:
                break;
        }
    }

    const modalDetails = () => {
        return (
            <Fragment>
                <Box>
                    <Tabs value={tabValue} onChange={(event, newValue) => handleTabChange(newValue)} aria-label="basic tabs example">
                        <Tab label="Draft" value="draft" {...a11yProps("draft")} />
                        <Tab label="Attachments" value="files" {...a11yProps("files")} />
                    </Tabs>
                </Box>
                <Card className="preview-details">
                    <Fragment>
                        <span><Paragraph value={titleData} className="title-label" /><Paragraph value={chapterData} className="chapter-label" /></span>
                    </Fragment>
                    {_renderSelectedView()}
                </Card>
            </Fragment>
        );
    }
    const modalActions = () => {

        let value;
        if (tabValue == "draft") {
            value = (
                <Button onClick={printDiv}>Print PDF</Button>
            )
        } else {
            return (<Fragment />)
        }
        return value
    }

    return (
        <Dialog className="filing-preview" open={props.open} onClose={props.close} title={"Preview: " + props.filingData?.name} children={modalDetails()} actions={modalActions()} />
    )
}
