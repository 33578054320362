import "./CurrentRules.scss";
import { IStateTabComponentProps } from "../IStateTabComponentProps";
import { CurrentRulesWrapper } from "./components";

interface ICurrentRulesProps extends IStateTabComponentProps {}

export const CurrentRules = (props: ICurrentRulesProps) => {
  return (
    <div className="work-queue">
      <CurrentRulesWrapper {...props} />
    </div>
  );
};
