const tab = "rule filings";
export class FilingQuery {
  filingtype?: string;
  filingid?: number;
  segmentid?: number;
  actionid?: number;
  workflowId?: number;
  newfiling?: boolean;
  baseFilingId?: number;
  form?: string;
}
export class WorkflowQuery {
  workflowId?: number;
  statusId?: number;
  actionId?: number;
}

export class UrlParser {
  static Filing = class {
    private static parseUrl = (queryString: string) => {
      const query: FilingQuery = {};
      const pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
      ).split("&");
      for (var i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1] || "");
        switch (key.toLowerCase()) {
          case "filingtype":
            query.filingtype = value;
            break;
          case "filingid":
            query.filingid = parseInt(value);
            break;
          case "segmentid":
            query.segmentid = parseInt(value);
            break;
          case "actionid":
            query.actionid = parseInt(value);
            break;
          case "newfiling":
            query.newfiling = value == "true";
            break;
          case "basefilingid":
            query.baseFilingId = parseInt(value);
            break;
          case "form":
            query.form = value;
            break;
          default:
            // console.error(`${key} ${value} is not setup`);
            break;
        }
      }
      return query;
    };
    public static getParameters(queryString: string) {
      return this.parseUrl(queryString);
    }
    public static parseFilingType: (filingType: string) => string = (
      filingType: string
    ) => {
      return `/state/${tab}/filing?filingtype=${filingType}`;
    };
    public static parseFilingTypeCreate: (
      filingType: string,
      newFiling: boolean,
      baseFilingId: number
    ) => string = (
      filingType: string,
      newFiling: boolean,
      baseFilingId: number
    ) => {
      return `/state/${tab}/filing?filingtype=${filingType}&newfiling=${newFiling}&baseFilingId=${baseFilingId}`;
    };
    public static parseFilingTypeCreateWithForm: (
      filingType: string,
      newFiling: boolean,
      baseFilingId: number,
      form: string
    ) => string = (
      filingType: string,
      newFiling: boolean,
      baseFilingId: number,
      form: string
    ) => {
      return `/state/${tab}/filing?filingtype=${filingType}&newfiling=${newFiling}&baseFilingId=${baseFilingId}&form=${form}`;
    };
    public static parseFiling: (
      filingType: string,
      filingId: number
    ) => string = (filingType: string, filingId: number) => {
      return `/state/${tab}/filing?filingtype=${filingType}&filingId=${filingId}`;
    };
    public static parseFilingPreview: (filingId: number) => string = (
      filingId: number
    ) => {
      return `/state/my work/filingpreview?filingId=${filingId}`;
    };
    public static parseFilingSegmentText: (
      filingType: string,
      filingId: number,
      segmentId: number
    ) => string = (filingType: string, filingId: number, segmentId: number) => {
      return `/state/${tab}/filing?filingtype=${filingType}&filingid=${filingId}&segmentId=${segmentId}`;
    };
    public static parseAction: (
      filingType: string,
      filingId: number,
      actionId: number
    ) => string = (filingType: string, filingId: number, actionId: number) => {
      return `/state/${tab}/filing?filingtype=${filingType}&filingid=${filingId}&actionid=${actionId}`;
    };
  };
  static Workflow = class {
    private static parseUrl = (queryString: string) => {
      const query: WorkflowQuery = {};
      const pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
      ).split("&");
      for (var i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1] || "");
        switch (key.toLowerCase()) {
          case "workflowid":
            query.workflowId = parseInt(value);
          case "statusid":
            query.statusId = parseInt(value);
          case "actionid":
            query.actionId = parseInt(value);
            break;
          default:
            break;
        }
      }
      return query;
    };
    public static getParameters(queryString: string) {
      return this.parseUrl(queryString);
    }
    public static parseWorkflow: (workflowId: number) => string = (
      workflowId: number
    ) => {
      return `/state/workflow?workflowid=${workflowId}`;
    };
    public static parseStatus: (
      workflowId: number,
      statusId: number
    ) => string = (workflowId: number, statusId: number) => {
      return `/state/workflow?workflowid=${workflowId}&statusid=${statusId}`;
    };
    public static parseAction: (
      workflowId: number,
      statusId: number,
      actionId: number
    ) => string = (workflowId: number, statusId: number, actionId: number) => {
      return `/state/workflow?workflowid=${workflowId}&statusid=${statusId}&actionid=${actionId}`;
    };
  };
}
