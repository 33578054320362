export enum SegmentType {
    Title = 1,
    Chapter = 2,
    Subchapter = 4,
    Section_Subchapter = 6,
    Section_Chapter = 9,
    Appendix = 11,
    ExecutiveOrder = 12,
    Part = 13,
    Section_Part = 14
}
