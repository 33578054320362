export enum PublicationStatus {
  Preparing,
  Published,
  Archived,
  Terminated,
  OpenForPublicComment,
  PendingEffectiveDate,
  PublicCommentError,
  MarkedForArchive,
}
