import { Contact } from "../model/Contact";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class ContactAPI extends BaseAPI<Contact> {
  constructor() {
    super("contacts", APISettings.adminRulesApiUrl);
  }

  public getAllContacts: () => Promise<any[]> = async () => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllContacts?pageNumber=1&pageSize=1500`
      )
    ).data;
  };

  public getAllContactsByType: (
    typeId: number,
    pagingParameters?: any
  ) => Promise<any> = async (typeId: number, pagingParameters?: any) => {
    if (pagingParameters) {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetAllContactsByType?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}&typeId=${typeId}`
        )
      ).data;
    } else {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetAllContactsByType?typeId=${typeId}`
        )
      ).data;
    }
  };

  // GetContactCountByType

  public getContactCountByType: (typeId: number) => Promise<any> = async (
    typeId: number
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetContactCountByType?id=${typeId}`
      )
    ).data;
  };

  public getContactById: (id: number) => Promise<Contact> = async (
    id: number
  ) => {
    try {
      return (await BaseAPI.axios.get(`${this.apiUrl}/GetContactById/${id}`))
        .data;
    } catch (error) {
      return null;
    }
  };

  public getLiaisonContactsByTitle: (titleId: number) => Promise<any> = async (
    titleId: number
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetLiaisonContactsByTitle?titleId=${titleId}`
      )
    ).data;
  };

  public getAttestationContactsByTitle: (titleId: number) => Promise<any> =
    async (titleId: number) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetAttestationContactsByTitle?titleId=${titleId}`
        )
      ).data;
    };

  public createContact: (contact: Contact) => Promise<any> = async (
    contact: Contact
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateContact`,
      contact
    );
    return response.data;
  };

  public removeContact: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveContacts/${id}`
    );
    return response.data;
  };

  public deleteContact: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeleteContact/${id}`
    );
    return response.data;
  };

  public updateContact: (contact: Contact) => Promise<Contact> = async (
    contact: Contact
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateContact`,
      contact
    );
    return response.data;
  };
}

export const contactAPI = new ContactAPI();
