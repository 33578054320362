import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { CorrectedDocumentType } from "../model/CorrectedDocumentType";

class CorrectedDocumentTypeAPI extends BaseAPI<any> {
    constructor() {
        super("CorrectedDocumentType", APISettings.adminRulesApiUrl);
    }

    public getAllAsync: () => Promise<CorrectedDocumentType[]> = async () => {
        return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllCorrectedDocumentTypesAsync`)).data;
    };

    public getByFilingTypeIdAsync: (filingTypeId: number) => Promise<CorrectedDocumentType[]> = async (filingTypeId: number) => {
        return (await BaseAPI.axios.get(`${this.apiUrl}/GetByFilingTypeIdAsync/${filingTypeId}`)).data;
    };
}

export const correctedDocumentTypeAPI = new CorrectedDocumentTypeAPI();