import { Dialog, DialogContent } from "@material-ui/core";
import React, { useState } from "react";
import { ActionIcon } from "../../../../../../../../../components";
import { DialogTitleWithExit } from "../../../../../../../../../components/DialogTitleWithExit";
import { Action } from "../../../../../../../../../utils/model";
import { NotificationForm } from "../NotificationForm";
import "./NoNotificationEvent.scss";

interface INoNotificationEventProps {
  action: Action;
  onChange: () => void;
}

export const NoNotificationEvent = (props: INoNotificationEventProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const onClose = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    props.onChange();
    onClose();
  };
  return (
    <div className="no-notification-event">
      <ActionIcon
        color="default"
        label="Add Notification"
        icon="email"
        onClick={openModal}
      />
      <Dialog
        fullWidth
        // disableBackdropClick
        onClose={onClose}
        aria-labelledby={"Add Notification"}
        open={open}
        {...props}
      >
        <DialogTitleWithExit onClose={onClose}>
          Add Notification
        </DialogTitleWithExit>
        <DialogContent>
          <NotificationForm onSubmit={onSubmit} action={props.action} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
