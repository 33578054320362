import { LinearProgress } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { red } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Authorized } from "../../../../components";
import { Paragraph } from "../../../../components/Paragraph";
import { PostsAPI } from "../../../../utils/api/PostsApi";
// import { EditorType } from "../../../../utils/enums";
import { JsonUtilities } from "../../../../utils/JsonUtilities";
import { Post } from "../../../../utils/model/Post";
import { PostsEdit } from "../PostsEdit";

import "./Posts.scss";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: "10px",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);

interface IPostsProps {
  className?: string;
  post: Post;
  id: string;
  onUpdate: () => void;
  readonly?: boolean;
}

export const Posts = (props: IPostsProps) => {
  const [post, setPost] = useState<Post | null>(null);

  // This code is in place to make sure that we do not break the Blog editor by having null items within this object
  useEffect(() => {
    // Just here temporarily. Needs to be removed to display text content once we save post correctly
    if (props.post.text === null) {
      props.post.text =
        "<h3>Sample text</h3> <p>If this is showing it means the new post did not save correctly</p>";
    }
    const newPost = JsonUtilities.removeEmpty<Post>(props.post);
    setPost(newPost);
  }, [props.post]);

  const classes = useStyles();
  let date: string = "";
  if (props.post.created) {
    date = new Date(props.post.created).toLocaleDateString();
  }

  const deletePost = async () => {
    if (post && post.id != null) {
      await PostsAPI.deletePost(post.id);
      props.onUpdate();
    }
  };

  if (post != null) {
    return (
      <Card id={props.id} className={`${classes.root} ${props.className}`}>
        <CardHeader title={props.post.title} subheader={date} />
        <CardContent>
          <Paragraph value={props.post.text} className="blog" />
        </CardContent>
        <CardActions disableSpacing>
          {props.readonly == true ? null : (
            <Authorized>
              <div className="actions">
                <PostsEdit onSave={props.onUpdate} post={props.post} />
                <div>
                  <IconButton onClick={deletePost}>
                    <Delete />
                  </IconButton>
                </div>
              </div>
            </Authorized>
          )}
        </CardActions>
      </Card>
    );
  } else {
    // TODO
    // Replace this item with a loader
    return <LinearProgress />;
  }
};
