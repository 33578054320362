import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // IconButton,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect } from "react";
// import { StateOfficeNavigationSettings } from "./../../../../../utils/settings/StateOfficeNavigationSettings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DialogTitleWithExit } from "../../../../../components/DialogTitleWithExit";
import { UrlParser } from "../../../../../utils";
import {
  contactAPI,
  contactTypeAPI,
  docketAPI,
  draftAPI,
  filingAPI,
  functionAPI,
  ruleAPI,
  segmentAPI,
  statusAPI,
  correctedDocumentTypeAPI
} from "../../../../../utils/api";
import {
  FilingTypes,
  PublicationStatus,
  SegmentType,
  VisibilityLevel,
  SegmentStatus
} from "../../../../../utils/enums";
import {
  Contact,
  Docket,
  Draft,
  EntityType,
  Filing,
  Rule,
  CorrectedDocumentType
} from "../../../../../utils/model";
import { FormMap } from "../../FormMap";
import { DefaultForm } from "./components/";
import "./FilingAddForm.scss";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { User } from "oidc-client";
import _remove from "lodash/remove";
import ImageFilter9Plus from "material-ui/svg-icons/image/filter-9-plus";
import { forEach } from "lodash";

interface IFilingAddProps extends RouteComponentProps {
  open: boolean;
  filingType: any;
  handleClose: () => void;
  form?: string;
  filing?: Filing;
  updateRows: () => void;
  userTitleAccess: any;
  userRoleAccess: any;
}

export interface FormImperativeHandles {
  getValues(): any;
}

export const FilingAddFormWithRouter = (props: IFilingAddProps) => {

  const [ruleList, setRuleList] = React.useState<Rule[]>([]);
  const [docketList, setDocketList] = React.useState<Docket[]>([]);
  const [contactList, setContactList] = React.useState<any>([]);
  const [liaisonList, setLiaisonList] = React.useState<any>([]);
  const [chapterList, setChapterList]: any = React.useState([]);
  const [draftList, setDraftList] = React.useState<Draft[]>([]);
  const [correctedDocumentTypeList, setCorrectedDocumentTypeList] = React.useState<CorrectedDocumentType[]>([]);
  const formRef = React.useRef<FormImperativeHandles>();
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackBarText, setSnackBarText] = React.useState<any>();
  const [values, setValues]: any = React.useState({
    visibility: VisibilityLevel.Title,
    filingType: props.filingType,
    filingTypeId: props.filingType.id,
    entityType: EntityType,
    entityTypeId: "",
    entityId: -1,
  });

  const setupValues = React.useCallback(async () => {
    setValues({
      published: PublicationStatus.Preparing,
      visibility: VisibilityLevel.Title,
      filingType: props.filingType,
      filingTypeId: props.filingType.id,
      entityType: EntityType,
      entityTypeId: "",
      entityId: -1,
    });
  }, [props.filingType]);

  // const formName = [
  //   1
  //   :
  //   "Notice"
  //   2
  //   :
  //   "Notice_NRI"
  //   3
  //   :
  //   "Notice_CON"
  //   4
  //   :
  //   "Notice_CAN"
  //   5
  //   :
  //   "Notice_SR"
  //   6
  //   :
  //   "Notice_LD"
  //   7
  //   :
  //   "Notice_WR"
  //   8
  //   :
  //   "Notice_ERR"
  //   9
  //   :
  //   "Adopted"
  //   10
  //   :
  //   "Adopted_PERM"
  //   11
  //   : "Adopted_EME"
  //   12: "Adopted_PRE"
  // ];

  const [formKey, setFormKey] = React.useState<string>("");

  const setupRules = React.useCallback(async () => {
    try {
      let ruleCollection: Rule[] = await ruleAPI.getAllRules({ pageNumber: 1, pageSize: 250 }, true);

      if (props.filingType.id == FilingTypes.Adopted_PRE) {
        ruleCollection = ruleCollection.filter(x => x.referenceCode === '340');
        let segments: Object = await segmentAPI.getChapterSegmentsByParentId(22992);
        setChapterList(segments)
      }

      // Remove executive orders from the list of title options for these filing types.
      if (
        props.filingType.id == FilingTypes.Notice || props.filingType.id == FilingTypes.Notice_NRI ||
        props.filingType.id == FilingTypes.Notice_CON || props.filingType.id == FilingTypes.Notice_CAN ||
        props.filingType.id == FilingTypes.Notice_SR || props.filingType.id == FilingTypes.Notice_LD ||
        props.filingType.id == FilingTypes.Notice_WR || props.filingType.id == FilingTypes.Notice_ERR ||
        props.filingType.id == FilingTypes.Adopted || props.filingType.id == FilingTypes.Adopted_PERM ||
        props.filingType.id == FilingTypes.Adopted_PRE) {
        ruleCollection = _remove(ruleCollection, (x: Rule) => x.referenceCode !== "1");
      }

      // filtering is done at the api level
      setRuleList(ruleCollection);

      // let newRulesList: any = [];

      // // Filter out rules that the user does not have access to but allow all titles if user is admin
      // if(props.userRoleAccess) {
      //   if(typeof props.userRoleAccess == "string") {
      //     if (props.userRoleAccess == "Global User Administrator" || props.userRoleAccess == "Office of Administrative Rules" && newRulesList.length !> 0) {
      //       newRulesList = ruleCollection;
      //     }
      //   } else {
      //     props.userRoleAccess.forEach((item:any, index:number) => {
      //       if (item == "Global User Administrator" || item == "Office of Administrative Rules" && newRulesList.length !> 0) {
      //         newRulesList = ruleCollection;
      //       }
      //     })
      //   }
      // } else {
      //   // Filter out rules that the user does not have access to
      //   for(let i = 0; i < ruleCollection.length; i++) {
      //     if(props.userTitleAccess){
      //   for(let j = 0; j < props.userTitleAccess.length; j++) {
      //       if(ruleCollection[i].referenceCode == props.userTitleAccess[j]) {
      //         newRulesList.push(ruleCollection[i]);
      //       }
      //     }
      //   }
      // }
      // }


      // setRuleList(newRulesList);


    } catch (err) {
      console.error(err);
    }
  }, [props.filingType]);

  const formatDate = (date: any) => {
    var month = date.toLocaleString("en-us", { month: "long" });
    var day = date.getDate();
    var year = date.getFullYear();
    return month.toUpperCase() + " " + day + ", " + year;
  };

  const setupAttestation = async (ruleId: number) => {
    try {
      // We need to be careful with this to actually select the attestation officers
      // Where its going off the contact.name this may have to be adjusted
      // const contactTypeList = await contactTypeAPI.getAllContactTypes();
      // var contactType = contactTypeList.result.find(
      //   (element:any) => element.name === "Attestation Officer"
      // );
      // if (contactType !== undefined) {
      const contactCollection: any = await contactAPI.getAttestationContactsByTitle(
        ruleId
      );
      setContactList(contactCollection.result ?? []);
      // }
    } catch (err) {
      console.error(err);
    }
  };

  const setupLiaison = async (ruleId: number) => {
    try {
      // We need to be careful with this to actually select the liaison officers
      // Where its going off the contact.name this may have to be adjusted
      // const contactTypeList = await contactTypeAPI.getAllContactTypes();
      // var contactType = contactTypeList.result.find(
      //   (element:any) =>
      //     element.name === "Agency Liaison" ||
      //     element.name === "Alternate Liaison"
      // );
      // if (contactType !== undefined) {
      const contactCollection: any = await contactAPI.getLiaisonContactsByTitle(ruleId);
      setLiaisonList(contactCollection.result ?? []);
      // }
    } catch (err) {
      console.error(err);
    }
  };

  const setupDocket = async () => {
    try {
      const docketList = await docketAPI.getAllDockets();
      setDocketList(docketList);
    } catch (err) {
      console.error(err);
    }
  };

  const setupDrafts = async (ruleId: number | null | undefined, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => {
    try {
      if (ruleId && ruleId > 0) {
        const drafts = await draftAPI.getFilteredDraftsAsync(ruleId, filterByUserTitleClaim, excludeDraftStatusIds);
        setDraftList(drafts);
      }
    }
    catch (ex: any) {
      console.error(`Error in getFilteredDraftsAsync ${ex.message}`);
    }
  };

  const setupCorrectedDocumentTypes = async () => {
    try {
      let correctedDocumentTypes = await correctedDocumentTypeAPI.getByFilingTypeIdAsync(props.filingType.id);
      setCorrectedDocumentTypeList(correctedDocumentTypes);
    } catch (err) {
      console.error(err);
    }
  }

  const setupFormKey = async () => {
    let formName = props.form ?? props.filingType.name.replace(" ", "");
    setFormKey(formName);
    // restrictTitleOne(formName);
  }

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    setupValues();
    setupRules();
    setupAttestation(1);
    setupDocket();
    setupLiaison(1);
    //setupDrafts();
    setupCorrectedDocumentTypes();
    setupFormKey();
  }, [props.open, setupRules, setupValues]);

  const saveForm = async (item: any) => {
    let hasErrors: Boolean = false;
    let errorText: string = "";
    let filingName = item.filingName;
    if (filingName.trim().length === 0) {
      errorText = "Filing Name Required";
      hasErrors = true;
    }

    if (!item.formName) {
      let draftId = item.draftId;
      if (draftId === null) {
        errorText += (hasErrors) ? " | Draft Required" : "Draft Required";
        hasErrors = true;
      }

      let chapterId = item.chapterId;
      if (chapterId === -1) {
        errorText += (hasErrors) ? " | Chapter Required" : "Chapter Required";
        hasErrors = true;
      }

    } else {
      if (item.formName === "NoDraft") {
        let chapterId = item.chapterId;
        if (chapterId === -1) {
          errorText += (hasErrors) ? " | Chapter Required" : "Chapter Required";
          hasErrors = true;
        }
      }
      if (item.formName === "NoChapter") {
        let draftId = item.draftId;
        if (draftId === null) {
          errorText += (hasErrors) ? " | Draft Required" : "Draft Required";
          hasErrors = true;
        }
      }
    }

    if (hasErrors) {
      setSnackBarText(errorText);
      setSnackOpen(true);
      return;
    }

    let status: any = await statusAPI.getStatusesByWorkflowId(props.filingType.workflowId!);
    let statusId = status.result[0].id;

    // Used for text editor when nested in form to take temp text and populated formValues when saving
    if (item.noticeEditors && item.text) {
      item.noticeEditors = item.text;
      item.text = "<p></p>";
    }
    // console.log("Testing condition", [10, 11, 12, 13, 20, 18].some((x) => x === props.filingType.id));

    let filing: Filing = new Filing(
      item.filingName,
      statusId,
      values.published,
      values.visibility,
      values.filingTypeId,
      ![10, 11, 12, 13, 20, 18].some((x) => x === props.filingType.id), // set IncludeInRegister to match value of fileTypeCanBePublished.
      ![10, 11, 12, 13, 20, 18].some((x) => x === props.filingType.id), // set fileTypeCanBePublished based on filingtype.
      item.ruleId,
      item.chapterId,
      item.draftId,
      null,
      item,
      [],
      [],
      values.amendment,
      values.todoLists,
      values.templateValue,
      values.commentThread,
      null,
      item.liaisonId ?? null,
      null!,
      null!,
      item.adoptionDate,
      item.effectiveDate ?? null
    )

    // console.log("Filing: ", filing)

    delete filing.id;

    filing.entity = item;

    try {
      // update existing item
      const newFiling = await filingAPI.createFilings(filing);
      if (newFiling === undefined) {
        setSnackBarText("User does not have claim to title.")
        setSnackOpen(true);
      }
      else if (newFiling?.value.id != null) {
        props.handleClose();
        props.updateRows();
        const url = UrlParser.Filing.parseFiling(
          props.filingType.name,
          newFiling.value.id!
        );
        props.history.push(url);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderRuleDropdown = () => {
    let menuItems: any = [];

    if (ruleList) {
      // Load menu items for title dropdown.
      ruleList.forEach((id: any) => {
        if (id.id != null) {
          menuItems.push(<MenuItem key={id.id} value={id.id}>Title {id.referenceCode}. {id.title}</MenuItem>);
        }
      });
    }
    return menuItems;
  };

  // Filing add form function
  const returnChapterListAsync = async (
    parentId: number | null | undefined
  ) => {
    if (parentId) {
      let segments: Object = await segmentAPI.getChapterSegmentsByParentIdIncludeAll(parentId);
      return segments;
    }
    return chapterList;
  };

  // Get liaisons by titleId
  const returnContactListAsync = async (titleId: number, isAttestation: boolean) => {
    if (titleId != null) {
      let contacts: any = [];
      try {
        if (isAttestation) {
          contacts = await contactAPI.getAttestationContactsByTitle(titleId);
        } else {
          contacts = await contactAPI.getLiaisonContactsByTitle(titleId);
        }
      } catch (error) {
        contacts = [];
      }
      // console.log(contacts);
      return contacts ?? [];
    } else {
      return [];
    }
  }

  const renderContactDropdown = () => {
    let menuItems: any = [];
    if (contactList) {
      contactList.forEach((element: Contact) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={element.id} value={element.id}>
              {element.firstName + " " + element.lastName}
            </MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderLiaisonDropdown = () => {
    let menuItems: any = [];
    if (liaisonList) {
      liaisonList.forEach((element: Contact) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={element.id} value={element.id}>
              {element.firstName + " " + element.lastName}
            </MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderDocketDropdown = () => {
    let menuItems: any = [];
    if (docketList) {
      docketList.forEach((element: Docket) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={element.id} value={element.id}>
              {element.year + "-" + element.documentNumber}
            </MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderDraftDropdown = () => {
    let menuItems: any = [];
    if (draftList) {
      draftList.forEach((element: any, index: number) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={index} value={element.id}>
              {"Title - " + element.titleNumber + " Chapter - " + element.chapterNumber + ": " + element.draftName}
            </MenuItem>
          )
        }
      })
    }
    return menuItems;
  }

  const renderCorrectedDocumentTypeDropdown = () => {
    if (correctedDocumentTypeList) {
      let menuItems: any = [];
      correctedDocumentTypeList.forEach((item: CorrectedDocumentType) => {
        if (item.id != null) {
          menuItems.push(<MenuItem key={item.id} value={item.id}>{item.documentName}</MenuItem>);
        }
      });
      return menuItems;
    }
  };

  const getSegmentStatus = (statusId: number) => {
    switch (statusId) {
      case SegmentStatus.New:
        return " [NEW]"
      case SegmentStatus.Revoked:
        return " [REVOKED]";
      case SegmentStatus.Reserved:
        return " [RESERVED]"
      case SegmentStatus.Expired:
        return " [EXPIRED]"
      case SegmentStatus.Amended:
        return " [AMENDED]"
      case SegmentStatus.AmendedRenumbered:
        return " [AMENDED AND RENUMBERED]"
      case SegmentStatus.Renumbered:
        return " [RENUMBERED]"
      case SegmentStatus.Superseded:
        return " [SUPERSEDED]"
      case SegmentStatus.Terminated:
        return " [TERMINATED]"
      case SegmentStatus.Transferred:
        return " [TRANSFERRED]"
      case SegmentStatus.Other:
        return " [OTHER]"
      case SegmentStatus.Published:
        return " [PUBLISHED]"
      default:
        return "";
        break;
    }
  };

  const getProposedRules = async (draftId: number | null | undefined) => {
    let proposedRules = await draftAPI.getProposedRulesByDraftId(draftId!, "FilingAddForm.tsx");
    let proposedRulesText = "";
    proposedRules && proposedRules.length > 0 ?
      proposedRules
        .filter((x: any) => x.segmentTypeId != SegmentType.Title && x.segmentTypeId != SegmentType.Chapter)
        .forEach(x => {
          if (x.segmentTypeId == SegmentType.Subchapter) {
            proposedRulesText += `Subchapter ${x.subchapterNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else if (x.segmentTypeId == SegmentType.Appendix) {
            proposedRulesText += `Appendix ${x.appendixNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else if (x.segmentTypeId == SegmentType.Part) {
            proposedRulesText += `Part ${x.partNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else {
            proposedRulesText += `${x.sectionNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          }
        })
      : '';

    return proposedRulesText;
  };

  const _render = () => {
    // const formKey = props.form ?? props.filingType.name.replace(" ", "");
    // console.log("formKey", formKey)
    const CurrentFormComponent =
      FormMap[
      Object.keys(FormMap).find(
        (key) => key.toLowerCase() === formKey.toLowerCase()
      )!
      ] || DefaultForm;
    return (
      <CurrentFormComponent
        filing={props.filing}
        ref={formRef}
        attestationList={contactList}
        liaisonList={liaisonList}
        docketList={docketList}
        ruleList={ruleList}
        correctedDocumentTypeList={correctedDocumentTypeList}
        draftList={draftList}
        saveForm={saveForm}
        filingType={props.filingType}
        formatDate={formatDate}
        renderContactDropdown={renderContactDropdown()}
        renderLiaisonDropdown={renderLiaisonDropdown()}
        renderRuleDropdown={renderRuleDropdown()}
        renderDocketDropdown={renderDocketDropdown()}
        renderDraftDropdown={renderDraftDropdown()}
        returnChapterListAsync={returnChapterListAsync}
        renderCorrectedDocumentTypeDropdown={renderCorrectedDocumentTypeDropdown()}
        getProposedRules={getProposedRules}
        setupDrafts={setupDrafts}
        setupLiaison={setupLiaison}
        setupAttestation={setupAttestation}
        returnContactListAsync={returnContactListAsync}
      />
    );
  };

  return (
    <Dialog
      id="filing-add"
      className="filing-home"
      disableEnforceFocus
      // disableBackdropClick
      open={props.open}
      onClose={props.handleClose}
      maxWidth="md"
    >
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{snackBarText}</Alert>
      </Snackbar>
      <DialogTitleWithExit onClose={props.handleClose}>
        {/* {props.form ? props.filingType.name.toUpperCase() : "NOTICE-NRI"} */}
      </DialogTitleWithExit>
      <Tabs value="1" indicatorColor="primary" aria-label="simple">
        <Tab label="Create Filing" value="1" />
      </Tabs>
      <DialogContent className="filing-add" dividers>
        {_render()}
      </DialogContent>
      <DialogActions>
        <Button
          className="save-button"
          variant="contained"
          onClick={() => {
            if (formRef.current) {
              saveForm(formRef.current.getValues());
            }
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FilingAddForm = withRouter(FilingAddFormWithRouter);


