import NotificationAirlineSeatFlat from "material-ui/svg-icons/notification/airline-seat-flat";
import { StatusRecordStatus } from "../enums";
import { Identifiable } from "./Identifiable";

export class Segment extends Identifiable {
  constructor(
    description: string,
    titleNum: string,
    recordStatus: StatusRecordStatus,
    segmentTypeId: number,
    chapterNum: string | null,
    subChapterNum: string | null,
    sectionNum: string | null,
    appendixNum: string | null,
    partNum: string | null,
    execOrderNum: string | null,
    execOrderYear: string | null,
    authority: string | null,
    text: string | null,
    notes: string | null,
    additionalInformation: string | null,
    ruleId: number | null,
    parentId: number | null,
    contactId: number | null | null,
    segmentStatusId: number | null,
    filingId: number | null,
    inProcessSegmentId: number | null,
    effectiveDate: Date | null,
    renumberedToSegmentNumber: string | null = null,
    hasEmergency: boolean
  ) {
    super(null);
    this.description = description;
    this.titleNum = titleNum;
    this.recordStatus = recordStatus;
    this.segmentTypeId = segmentTypeId;
    this.chapterNum = chapterNum;
    this.subChapterNum = subChapterNum;
    this.sectionNum = sectionNum;
    this.appendixNum = appendixNum;
    this.partNum = partNum;
    this.execOrderNum = execOrderNum;
    this.execOrderYear = execOrderYear;
    this.authority = authority;
    this.text = text;
    this.notes = notes;
    this.additionalInformation = additionalInformation;
    this.ruleId = ruleId;
    this.parentId = parentId;
    this.contactId = contactId;
    this.segmentStatusId = segmentStatusId;
    this.filingId = filingId;
    this.inProcessSegmentId = inProcessSegmentId;
    this.effectiveDate = effectiveDate;
    this.renumberedToSegmentNumber = renumberedToSegmentNumber;
    this.hasEmergency = hasEmergency;
  }

  public description: string;
  public titleNum: string;
  public chapterNum: string | null;
  public subChapterNum: string | null;
  public sectionNum: string | null;
  public appendixNum: string | null;
  public partNum: string | null;
  public execOrderNum: string | null;
  public execOrderYear: string | null;
  public authority: string | null;
  public segmentTypeId: number;
  public text: string | null;
  public notes: string | null;
  public additionalInformation: string | null;
  public recordStatus: StatusRecordStatus;
  public ruleId: number | null;
  public parentId: number | null;
  public contactId: number | null;
  public segmentStatusId: number | null;
  public filingId: number | null;
  public inProcessSegmentId: number | null;
  public effectiveDate: Date | null;
  public renumberedToSegmentNumber: string | null;
  public hasEmergency: boolean;
}