import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridPageChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, SortDescriptor, State } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DueDates } from "../../../../../utils/model/DueDates";
import "./WorkQueueTable.scss"
import { CircularProgress, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { UrlParser } from '../../../../../utils';
import { filingAPI, filingTypeAPI } from '../../../../../utils/api';
import { WorkQueuePreviewModal } from '../WorkQueuePreviewModal';
import { RouteComponentProps, withRouter } from "react-router";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Authorized } from '../../../../../components/Authorized/Authorized';
import { AuthenticationContext } from '../../../../../utils/authentication/AuthenticationProvider';
import { useContext } from 'react';
import { FilingTypes, PublicationStatus, StatusRecordStatus } from "../../../../../utils/enums";
import {
  setFilingTypeRows as workQueueTableSetup
} from "../WorkQueueTableSetup";
import { ListPayload, Filing } from '../../../../../utils/model';
import { getEntityData } from "../../../../../utils/utility_functions";
// import { APISettings } from '../../../../../utils/settings';
// import { FilingType } from '../../../../../utils/model/FilingType';
// import { ApplicationRoles } from '../../../../../utils/model/ApplicationUser';


interface IWorkQueueTableProps extends RouteComponentProps {
  // rows:DataResult;
  // columns:any;
  // add?: () => void;
  // height?: string;
  // //paging?: (page:any, sort: SortDescriptor[]) => any;
  // numberOfRecords?: number;
  filingId?: number | null | undefined;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

interface TempColumn {
  visible: boolean;
  field: string;
  title: string;
  width: string;
}

const WorkQueueTablePreRouter = (props: IWorkQueueTableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [openError, setOpenError] = React.useState<boolean>(false);
  const [showButton, setShowButton] = React.useState<boolean>(false);
  const [selectedFilingData, setSelectedFilingData] = React.useState<any>();
  const [sort, setSort] = React.useState<Array<SortDescriptor>>([
    { field: "id", dir: "desc" },
  ]);
  const [filings, setFilings] = React.useState<DataResult>({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 20,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    // console.log(`WorkQueueTablePreRouter useEffect dataState Changed`);
    (async () => {
      try {
        setLoading(true);
        const skip = dataState.skip ?? 0;
        const take = dataState.take ?? 10;
        const page = ((skip + take) / take);
        const filingsPayLoad = await filingAPI.getAllFilingsAsync({ pageNumber: page, pageSize: take }, false);
        const filingsPayloadInfo = setFilingInfo(filingsPayLoad)
        setFilings({ data: filingsPayloadInfo.items, total: filingsPayloadInfo.totalCount });

      }
      catch (ex: any) {
        console.error(`Error in WorkQueueTablePreRouter: ${ex.message}`);
      }
      finally {
        setLoading(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [dataState]);

  const isFalsy = (value: any) => {
    if (typeof value === "boolean" && value === false) return true;
    if (typeof value === "number" && (value === 0 || isNaN(value))) return true;
    if (typeof value === "string" && value.trim() === "") return true;
    if (value === null || value === undefined) return true;
    if (Object.prototype.toString.call(value) === '[object Object]' &&
      JSON.stringify(value) === '{}') return true;
    return false;
  };

  const setFilingInfo = (payload: ListPayload<Filing>) => {
    for (let index = 0; index < payload.items.length; index++) {
      payload.items[index].docketNumber = getEntityData(payload.items[index].entity, "docketNumber")

      payload.items[index].titleNumber = getEntityData(payload.items[index].entity, "rule", "referenceCode");

      payload.items[index].chapterNumber = getEntityData(payload.items[index].entity, "chapter", "chapterNum");

      if (!isFalsy(payload.items[index].created)) {
        payload.items[index].created = new Date(payload.items[index].created as unknown as string).toLocaleDateString();
      }

      if (!isFalsy(payload.items[index].filingTypeId)) {
        payload.items[index].filingTypeId = (payload.items[index].filingTypeId as unknown as number);
      }
      //Set url
    }
    return payload;
  };
  const getData = async () => {
    try {

      //setError(null);
    } catch (err) {
      //setError(err.message);
      //setData(null);
    } finally {
      setLoading(false);
    }
  };
  const redirectToPreview = (dataItem?: any) => {
    if (dataItem) {
      const url = UrlParser.Filing.parseFilingPreview(dataItem.id);
      // console.log(url);
      props.history.push(url);
    } else if (props.filingId != null) {
      const url = UrlParser.Filing.parseFilingPreview(props.filingId!);
      props.history.push(url);
    }
  };
  const openPreviewModal = (dataItem: any, queryInfo?: any) => {
    // set url data when opening new filing
    if (dataItem) {
      setSelectedFilingData(dataItem);
      redirectToPreview(dataItem);
    }

    // set selected filing only when query info is already set
    if (queryInfo) {
      setSelectedFilingData(dataItem);
    }

    setOpenPreview(true);
  }
  //const [dataResult, setDataResult] = React.useState<DataResult>(props.rows);


  const onMount = React.useCallback(async () => {
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    // getAuthentication();
    if (queryInfo.filingid) {
      let data;
      try {
        data = await filingAPI.getFilingsById(queryInfo.filingid);
        if (data) {
          openPreviewModal(data, queryInfo);
        }
      } catch (error) {
        console.error(error);
        setOpenError(true);
      }
    }

    // if(props.height) {
    //   setHeight(props.height);
    // }

    // setDataResult(props.rows);
  }, [props.location.search])


  // React.useEffect(() => {
  //   onMount();
  // }, [props.rows,props.height,props.columns])

  // React.useMemo(() => {
  //   setDataState({
  //     skip: 0,
  //     take: 10,
  //     sort: [{ field: "id", dir: "desc" }]
  //   });
  // }, [props.numberOfRecords])


  const { isAuthenticated, getUser, signin } = useContext(
    AuthenticationContext
  );

  // This is how we can check authentication on the fly if needed
  // const hasRole = async (roles: ApplicationRoles[]) => {
  //     let user = await getUser();

  //     if (user && roles != null) {
  //         let found = false;
  //         if (typeof user.profile?.role === "string") {
  //           const role = user.profile?.role as unknown;
  //           found = roles.some((r) => role === r);
  //         } else {
  //           found = user.profile?.role?.some((r) => roles?.includes(r));
  //         }
  //         return found;
  //       }
  //       return true;
  // }

  // const getAuthentication = async () => {
  //   let authorized = await hasRole(["Agency Liaison", "Attestation Officer"]);
  //   setShowButton(authorized)
  // }

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    // console.log(`dataState Change: ${event.dataState}`)
    //setDataResult(props.rows);
    //setDataState(event.dataState);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    setDataState({ ...event.page });
  };



  const closePreview = () => {
    setOpenPreview(false);
    props.history.push("/state/my work");
  }

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const dueDateColumn = (data: GridCellProps) => {
    let dueDates: any = new DueDates();
    var currentDate = new Date();
    var elementDate = new Date(data.dataItem.created);
    var returnValue = "inactive";
    for (var i: number = 0; i < dueDates.DueDates.length; i++) {
      var submissionDate = new Date(dueDates.DueDates[i].submissionDate);
      var acceptDate = new Date(dueDates.DueDates[i].acceptanceDate);
      if (elementDate >= submissionDate && elementDate >= acceptDate) {
        returnValue = "active";
        break;
      }
    }



    // if not found in due date array return error icon
    if (StatusRecordStatus[data.dataItem.published] == "Inactive") {
      return (
        <td className="inactiveIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Inactive" aria-label="Inactive">
            <CheckCircleIcon />
          </Tooltip>
        </td>
      );
    } else if (returnValue == "active") {
      return (
        <td className="newIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Active" aria-label="Active">
            <AddCircleIcon />
          </Tooltip>
        </td>
      )
    } else {
      return (
        <td className="oldIcon" role="gridcell" aria-selected="false" data-grid-col-index="1">
          <Tooltip title="Past Due" aria-label="PastDue">
            <ErrorIcon />
          </Tooltip>
        </td>
      )
    }

  }


  // const createdDateColumn = (data: GridCellProps) => {
  //   let date = new Date(data.dataItem.created);
  //   return(
  //     <td className="" role="gridcell">
  //       {date.toLocaleDateString()}
  //     </td>
  //   )
  // }


  const hyperLinkColumn = (data: GridCellProps) => {
    return (
      <td className="table-buttons" role="gridcell">
        <Authorized
          showComponent={false}
          redirectToLogin={false}
          roles={["Agency Liaison", "Attestation Officer", "Office of Administrative Rules", "Global User Administrator", "Governor", "Legislature"]}
        >
          <Button className="open-button" onClick={() => { window.location.href = (data.dataItem.url ? data.dataItem.url.toString() : "") }}>Open {data.dataItem.name}</Button>
        </Authorized>
        {data.dataItem.showInReviewModal ? (
          <Button className="preview-button" onClick={() => openPreviewModal(data.dataItem)}>Preview</Button>
        ) : null
        }
      </td>
    )
  }
  const publicationStatusColumn = (data: GridCellProps) => {
    return (
      <td className="" role="gridcell">
        {PublicationStatus[data.dataItem.published]}
      </td>
    );
  }

  const filingTypeColumn = (data: GridCellProps) => {
    return (
      <td className="" role="gridcell">
        {FilingTypes[data.dataItem.filingTypeId]}
      </td>
    );
  }

  // This sort change will only effect sorting on the custom name column
  // const sortChange = (event: GridSortChangeEvent) => {
  //   let state = dataState;
  //   state.sort = event.sort;
  //   setDataState(state);
  //   let sortedRows = getRows(event.sort);
  //   setDataResult(process(sortedRows, dataState));
  //   setSort(event.sort);
  // };

  // const getRows = (sort: SortDescriptor[]):any => {
  //   let rows = props.rows;
  //   if(sort[0]) {
  //     // if(sort[0].field == "link") {
  //       if(sort[0].dir == "asc") {
  //         let sorted = rows.slice(dataState.skip, (dataState.skip + dataState.take))
  //                           .sort((a:any,b:any) => a.name > b.name ? 1 : -1)
  //                           .concat(rows.slice((dataState.skip + dataState.take), rows.length))
  //         console.log(sorted);
  //         return sorted;
  //       } else {
  //         let sorted = rows.slice(dataState.skip, (dataState.skip + dataState.take))
  //                           .sort((a:any,b:any) => a.name < b.name ? 1 : -1)
  //                           .concat(rows.slice((dataState.skip + dataState.take), rows.length))
  //         console.log(sorted);
  //         return sorted;
  //       }
  //       // } if (sort[0].field == "dueDate") {
  //       // if(sort[0].dir == "asc") {
  //       //   let sorted = rows.slice(dataState.skip, (dataState.skip + dataState.take))
  //       //                     .sort((a:any,b:any) => a.created > b.created ? 1 : -1)
  //       //                     .concat(rows.slice((dataState.skip + dataState.take), rows.length))
  //       //   console.log(sorted);
  //       //   return sorted;
  //       // } else {
  //       //   let sorted = rows.slice(dataState.skip, (dataState.skip + dataState.take))
  //       //                     .sort((a:any,b:any) => a.created < b.created ? 1 : -1)
  //       //                     .concat(rows.slice((dataState.skip + dataState.take), rows.length))
  //       //   console.log(sorted);
  //       //   return sorted;
  //       // }
  //     // }
  //   } else {
  //     let sorted = rows.slice(dataState.skip, (dataState.skip + dataState.take))
  //                       .sort((a:any,b:any) => a.created < b.created ? 1 : -1)
  //                       .concat(rows.slice((dataState.skip + dataState.take), rows.length))
  //     console.log(sorted);
  //     return sorted;
  //   }
  // }



  const _renderTable = () => {
    if (filings.data.length > 0) {
      let data = workQueueTableSetup(filings.data);
      const allColumns = filings.data.length > 0 ? data.columns : [];
      const setColumns: TempColumn[] = [];

      allColumns.map((element: any) => {

        let column = {} as TempColumn
        column.field = element.field;
        column.title = element.title;
        column.visible = false;

        switch (element.field) {
          case "id":
            break;
          case "docketNumber":
            column.visible = true;
            break;
          case "submissionDate":
            column.visible = false;
            break;
          case "titleNumber":
            column.visible = true;
            break;
          case "published":
            column.visible = true;
            break;
          case "filingTypeId":
            break;
          case "filingTypeName":
            column.visible = true;
            break;
          case "status":
            break;
          case "created":
             column.visible = true;
            break;
          case "ruleId":
            break;
          case "chapterId":
            break;
          case "liaisonId":
            break;
          case "docketId":
            break;
          case "adoptionDate":
            // column.visible = true;
            break;
          case "draftId":
            break;
          case "chapterNumber":
            column.visible = true;
            break;
          case "showInReviewModal":
            break;
          case "isReadOnly":
            break;
          case "isInDraftState":
            break;
          case "registerId":
            break;
          default:
            break;
        }
        setColumns.push(column);
      });

      var columnsToShow = [];
      columnsToShow = setColumns.map((element: TempColumn, i: number) => {
        if (element.visible) {
          if (element.field == 'published') {
            return (<GridColumn field={element.field} key={i + element.title} title={element.title} cell={publicationStatusColumn} width={element.width} />);
          } else {
            return (<GridColumn field={element.field} key={i + element.title} title={element.title} width={element.width} />);
          }

        }
      });


      // var dataForGrid = () => {
      //   filings.data.forEach((e: any, i: number) => {
      //     e.created = new Date(e.created).toLocaleDateString();
      //     e.adoptionDate = new Date(e.adoptionDate).toLocaleDateString();
      //   });
      //   return props.rows;
      // }

      columnsToShow.unshift(<GridColumn key={"filingType"} field={"filingType"} title="Filing Type" cell={filingTypeColumn} />);
      columnsToShow.unshift(<GridColumn key={"link"} field={"link"} title="Actions" cell={hyperLinkColumn} width={"250px"} />);
      // columnsToShow.unshift(<GridColumn key={"dueDate"} field={"dueDate"} title="Status" cell={dueDateColumn} />);


      if (columnsToShow.length > 0 && filings.data.length > 0) {
        return (
          <Grid
            key={"WorkQueueTable"}
            style={{
              height: height,
            }}
            sortable={false}
            pageable={{
              buttonCount: 3,
              pageSizes: true,
            }}
            {...dataState}
            data={filings}
            onDataStateChange={dataStateChange}
            resizable={true}
            reorderable={true}
            onPageChange={pageChange}
            className="work-table"
          >
            <GridToolbar>
              <ButtonGroup className="button-group">
                {/* <Button
                icon="xls"
                title="Export to Excel"
                className="k-button k-secondary button"
                onClick={exportExcel}
              /> */}
                <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
                {/* <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={props.add}/> */}
              </ButtonGroup>
            </GridToolbar>
            <GridNoRecords>
              <CircularProgress />
            </GridNoRecords>
            {columnsToShow}
          </Grid>
        );
      } else {
        return null;
      }
    }
  }

  return (
    <div className="work-queue">
      <Snackbar open={openError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => setOpenError(false)}>
        <Alert variant="filled" severity="error">
          Error: The filing preview could not be opened
          <p>This filing has been withdrawn</p>
        </Alert>
      </Snackbar>
      <WorkQueuePreviewModal open={openPreview} filingData={selectedFilingData} close={closePreview} />
      <ExcelExport
        data={filings.data}
        ref={(exporter: any) => {
          _export = exporter;
        }}
      >
        {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}

export const WorkQueueTable = withRouter(WorkQueueTablePreRouter);