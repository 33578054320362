import { BaseAPI } from "./internal";
import { Filing, Segment } from "../model";
import { ListPayload } from "../model/ListPayload";
import { APISettings } from "../settings/APISettings";

class FilingAPI extends BaseAPI<Filing> {
  constructor() {
    super("filing", APISettings.adminRulesApiUrl);
  }

  public patch: (id: number, filing: Filing) => Promise<any | Filing> = async (
    id: number,
    filing: Filing
  ) => {
    let filingEntityData = {
      Entity: filing.entity,
      Name: filing.name,
      StatusId: filing.statusId,
      Published: filing.published,
      Visibility: filing.visibility,
      Amendments: filing.amendments,
      TodoLists: filing.todoLists,
      TemplateValues: filing.templateValues,
      CommentThread: filing.commentThread,
      FilingTypeId: filing.filingTypeId,
      DraftId: filing.draftId,
      RegisterId: filing.registerId,
    };
    let response = await BaseAPI.axios.patch(
      `${this.apiUrl}/${this.type}/${id}`,
      filingEntityData
    );
    return response.data;
  };

  public updateSegment = async (
    filingId: number,
    segment: Segment
  ): Promise<Filing> => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/${this.type}/${filingId}/segment`,
      { segment }
    );
    return response.data;
  };

  public getFilingsById: (id: number) => Promise<Filing> = async (
    id: number
  ) => {
    try {
      return (await BaseAPI.axios.get(`${this.apiUrl}/GetFilingsById/${id}`))
        .data;
    } catch (error) {
      return {};
    }
  };

  public getFilingsByDraftId: (id: number) => Promise<[Filing]> = async (
    id: number
  ) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilingsByDraftId?draftId=${id}`
      )
    ).data;
  };

  public getPublicCommentById: (
    segmentId: number,
    filingId: number
  ) => Promise<any> = async (segmentId: number, filingId: number) => {
    let result = await BaseAPI.axios.get(
      `${this.apiUrl}/GetPublicCommentById/${segmentId}/${filingId}`
    );
    return result.data;
  };

  public getAllFilings: (pagingParameters: any) => Promise<Filing> = async (
    pagingParameters: any
  ) => {
    let response = await BaseAPI.axios.get(
      `${this.apiUrl}/GetAllFilings?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}`
    );
    return response.data;
  };
  public getAllFilingsAsync: (
    pagingParameters: any,
    getArchived?: boolean
  ) => Promise<ListPayload<Filing>> = async (
    pagingParameters: any,
    getArchived?: boolean
  ) => {
    let response = await BaseAPI.axios.get(
      `${this.apiUrl}/getAllFilingsAsync?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}&getArchived=${getArchived}`
    );
    return response.data;
  };
  public getFilingsByType: (
    id: number,
    pagingParameters?: any,
    archived?: boolean
  ) => Promise<Filing[]> = async (
    id: number,
    pagingParameters?: any,
    archived?: boolean
  ) => {
    if (pagingParameters) {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilingsByType?pageNumber=${
          pagingParameters.pageNumber
        }&pageSize=${pagingParameters.pageSize}&typeId=${id}&getArchived=${
          archived ?? false
        }`
      );
      return response.data;
    } else {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilingsByType?typeId=${id}`
      );
      return response.data;
    }
  };
  public GetFilingsByTypesAsync: (
    typeIds: number[],
    pagingParameters?: any,
    getArchived?: boolean
  ) => Promise<ListPayload<Filing>> = async (
    typeIds: number[],
    pagingParameters: any,
    getArchived?: boolean
  ) => {
    if (getArchived != null) {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilingsByTypesAsync?pageNumber=${
          pagingParameters.pageNumber
        }&pageSize=${pagingParameters.pageSize}&typeIds=${typeIds.join(
          "&typeIds="
        )}&getArchived=${getArchived}`
      );
      return response.data;
    } else {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetFilingsByTypesAsync?pageNumber=${
          pagingParameters.pageNumber
        }&pageSize=${pagingParameters.pageSize}&typeIds=${typeIds.join(
          "&typeIds="
        )}`
      );
      return response.data;
    }
  };
  public getPublishedFilingsByType: (
    id: number,
    pagingParameters?: any
  ) => Promise<Filing[]> = async (id: number, pagingParameters?: any) => {
    if (pagingParameters) {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetPublishedFilingsByType?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}&typeId=${id}`
      );
      return response.data;
    } else {
      let response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetPublishedFilingsByType?typeId=${id}`
      );
      return response.data;
    }
  };

  public getFilingCountByType: (id: number) => Promise<number> = async (
    id: number
  ) => {
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/getFilingCountByType?id=${id}`)
    ).data;
  };

  public getAllFilingsLikeTypeName: (typeLike: string) => Promise<number> =
    async (typeLike: string) => {
      return (
        await BaseAPI.axios.get(
          `${this.apiUrl}/GetAllFilingsLikeTypeName/${typeLike}`
        )
      ).data;
    };

  public getFilingCount: () => Promise<number> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetFilingCount`)).data;
  };

  public getPublicProposedRuleFilings: () => Promise<Filing[]> = async () => {
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/GetPublicProposedRuleFilings`)
    ).data;
  };

  public createFilings: (filing: Filing) => Promise<any> = async (
    filing: Filing
  ) => {
    const response = await BaseAPI.axios
      .post(`${this.apiUrl}/CreateFilings`, filing)
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorMsg = error;
          return errorMsg;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        return undefined;
      });
    return response?.data;
  };

  public removeFilings: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveFilings/${id}`
    );
    return response.data;
  };

  public updateFilings: (id: number, filing: Filing) => Promise<Filing> =
    async (id: number, filing: Filing) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/UpdateFilings`,
        filing
      );
      return response.data;
    };

  public rejectFiling: (
    filingId: number,
    statusId: number,
    rejectReason: string
  ) => Promise<any> = async (
    filingId: number,
    statusId: number,
    rejectReason: string
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/RejectFiling/${filingId}/${statusId}/${rejectReason}`
    );
    return response.data;
  };

  public cancelFiling: (filingId: number) => Promise<any> = async (
    filingId: number
  ) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/CancelFiling/${filingId}`
    );
    return response.data;
  };
}

export const filingAPI = new FilingAPI();
