import * as React from "react";
import {
  Form,
  FormElement,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";

import { EmailForm } from "../SubscriptionLoginForms/EmailForm";
import { RegisterPasswordForm } from "../SubscriptionRegisterForms/RegisterPasswordForm";
import { subscriberAPI, subscriptionLoginAPI } from "../../../../utils/api";
import Swal from "sweetalert2";

interface stepsInterface {
  isValid: boolean | undefined;
  label: string;
}

const stepPages = [EmailForm];

export const SubscriptionRecoverySteps = () => {
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [step, setStep] = React.useState<number>(0);
  const [formState, setFormState] = React.useState<Object>({});
  const [steps, setSteps] = React.useState<Array<stepsInterface>>([
    { label: "Verify Email", isValid: undefined },
  ]);

  // const lastStepIndex = steps.length - 1;

  const onStepSubmit = React.useCallback(
    async (event: FormSubmitClickEvent) => {
      const { isValid, values } = event;

      var recoveryEmail;
      var isValidEmail;

      if (values.email !== undefined) {
        recoveryEmail = values.email;
        isValidEmail = await subscriptionLoginAPI.verifySubscriptionUserEmail(
          recoveryEmail
        );
        // console.log("isValidEmail - ", isValidEmail);
        // console.log("values.email - ", values.email);
      } else {
        return;
      }

      // Send email to user to verify account holder
      const result = await subscriptionLoginAPI.initiatePasswordReset(
        values.email
      );
      if (result.isSuccess != true) {
        // setLoading(false);
        Swal.fire(
          "Failed",
          "The email or password entered is not correct",
          "error"
        );
        // setSuccess(false);
        // setStep(0);
        return;
      } else {
        // setLoading(false);
        Swal.fire({
          title: `An email has been sent to ${values.email} Click the link to reset your password.`,

          icon: "success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate.push("/subscriptionProfile");
            // window.location.reload();
            // setSuccess(true);
          }
        });
      }

      if (!isValid) {
        return;
      }

      setFormState(values);
    },
    [steps, step]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Form
        initialValues={formState}
        onSubmitClick={onStepSubmit}
        render={(formRenderProps) => (
          <div style={{ alignSelf: "center" }}>
            <FormElement>
              {stepPages[step]}
              <span
                style={{ marginTop: "40px" }}
                className={"k-form-separator"}
              />
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
                className={
                  "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                }
              >
                <div>
                  <Button
                    disabled={!formRenderProps.allowSubmit}
                    onClick={formRenderProps.onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </FormElement>
          </div>
        )}
      />
    </div>
  );
};
