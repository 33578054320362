import React, { useEffect } from "react";
import { CmsItem } from "../../../../utils/model/";
import { CmsType, StorageContainer } from "../../../../utils/enums/";
import { cmsItemAPI, fileAPI } from "../../../../utils/api";
import { EditQuickLink } from "./EditQuickLink";
import { Button, DialogTitle, TextField, Typography } from "@material-ui/core";
import { saveAs } from "file-saver";
import { readFileSync } from "fs";
import { writeFile } from "fs";

/**
 * @description Component for individual link line items
 *
 * @component Link
 * @param {string}  label Text lable shown in the hyperlink
 * @param {string}  textName CMS name of the text shown in the label
 * @param {string}  fileName CMS name of the PDF linked
 * @param {boolean} readOnly Turns hyperlink into cms editable text paragraph
 * @param {string}  hyperlinkOverride  URL for overriding the what is linked for example an external URL should use this
 * @example
 * return (
 *    <Link
 *      label="Sample"
 *      textName="cmsItemLabel"
 *      fileName="cmsItem"
 *      readOnly={false}
 *      //hyperlinkOverride="https://google.com"
 *    />
 * )
 */

interface IEditQuickLinkProps {
  textName: string;
  fileName: string;
  readOnly: boolean;
  hyperlinkOverride?: string;
}

export const Link = (props: IEditQuickLinkProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const initialCmsItem = new CmsItem(
    props.textName,
    "PlaceHolder",
    CmsType.Image
  );
  const [cmsItem, setCmsItem] = React.useState(initialCmsItem);
  const [cmsNameItem, setCmsNameItem] = React.useState(initialCmsItem);
  const [label, setLabel] = React.useState(Object(initialCmsItem.value));

  const getCmsFile = React.useCallback(async () => {
    if (props.fileName != "") {
      let item = await cmsItemAPI.getCmsItemsByName(props.fileName);
      setCmsItem(item);
    }
  }, []);

  const getCmsName = React.useCallback(async () => {
    if (props.textName != "") {
      let item = await cmsItemAPI.getCmsItemsByName(props.textName);
      setCmsNameItem(item);
      setLabel(Object(item.value));
    }
  }, []);

  useEffect(() => {
    getCmsFile();
    getCmsName();
  }, [getCmsFile]);

  const toggleDialog = () => {
    openDialog ? setOpenDialog(false) : setOpenDialog(true);
  };

  const onClick = async () => {
    let url: any = await fileAPI.getFileUrl(
      cmsItem.value,
      StorageContainer.PublicCms
    );


    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);
    // saveAs(blob, label + ".pdf");
    window.open(fileURL);
  };

  const onChange = (value: CmsItem) => {
    setLabel(Object(value.value));
    // _render();
  };

  [{ fileName: "" }];

  const _render = () => {
    return props.readOnly ? (
      <div>
        <Typography>{label}</Typography>
        <EditQuickLink
          name={props.fileName}
          cmsLinkItem={cmsItem}
          cmsNameItem={cmsNameItem}
          fileUpload={props.readOnly}
          onChange={onChange}
        />
      </div>
    ) : (
      <div>
        {props.hyperlinkOverride ? (
          <a href={props.hyperlinkOverride}>{label}</a>
        ) : (
          <a href="#" onClick={onClick}>
            {label}
          </a>
        )}
        {props.hyperlinkOverride ? (
          <EditQuickLink
            name={props.fileName}
            cmsLinkItem={cmsItem}
            cmsNameItem={cmsNameItem}
            fileUpload={true}
            onChange={onChange}
          />
        ) : (
          <EditQuickLink
            name={props.fileName}
            cmsLinkItem={cmsItem}
            cmsNameItem={cmsNameItem}
            fileUpload={props.readOnly}
            onChange={onChange}
          />
        )}
      </div>
    );
  };

  return <div>{_render()}</div>;
};
