import {
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
// import React from "react";
import { Action } from "../../../../../../stateOffice/pages/FilingBuilder/components/Actions";
import { UrlParser } from "../../../../../../utils";
import { Filing } from "../../../../../../utils/model";
import "./SubFilingDisplay.scss";

interface ISubFilingDisplayProps {
  filing: any;
  onClick: (filing: Filing) => void;
  updateFilings: () => void;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
}

export const SubFilingDisplay = (props: ISubFilingDisplayProps) => {
  const { filing, onClick, color, updateFilings } = props;
  const filingUrl = UrlParser.Filing.parseFiling(
    filing.name,
    filing.id!
  );

  // Get All Notice Filings that reference this parent and display a link to them
  return (
    <div>
      <ListItem className="sub-filing-display" component="a">
        <ListItemText
          onClick={() => onClick(filing)}
          className="sub-filing-name"
          primary={filing.name}
        />
        <ListItemText
          className="sub-filing-status"
          primary={filing.status?.name}
        />
        {/* Replace with action Container */}
        <Action row={filing} updateRows={updateFilings} />
        {/* Replace with action Container */}
      </ListItem>
      <Divider />
    </div>
  );
  // return (
  //   <div className="sub-filing-wrapper">
  //     <a href="">{filing.name}</a>

  //     <IconButton
  //       size="small"
  //       disableTouchRipple={true}
  //       className={`action-icon`}
  //       color={color}
  //     >
  //       <Tooltip title={filing.status.description} placement="bottom">
  //         <Fab className={color}>{<Icon icon={filing.status.icon} />}</Fab>
  //       </Tooltip>
  //     </IconButton>
  //   </div>
  // );
};
