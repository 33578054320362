import {
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import { ArrowBackIosRounded, ArrowLeft, ArrowRight, ContactSupportOutlined } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { RegisterIssues } from "../../../utils/model/RegisterIssues";
import { RegisterContentTable } from "./components/RegisterContentTable/RegisterContentTable";
import { RegisterIssueTable } from "./components/RegisterIssueTable";
import {
  draftAPI,
  fileAPI,
  registriesAPI,
  segmentAPI,
  segmentHistoryAPI,
  registerGenerationAPI,
  rulesAffectedIndexAPI,
  registryFilingAPI,
  filingTypeAPI,
} from "../../../utils/api";
import { setRegisterIssueData } from "./Helpers/RegisterIssueTableHelper";
import { setRegisterContentData } from "./Helpers/RegisterContentTableHelper";
import { RegisterIssueModal } from "./components/RegisterIssueModal";
import { FilingType, FinalizedRegister, Registries, Segment, SegmentHistory } from "../../../utils/model";
import { AuthenticationContext } from "../../../utils/authentication/AuthenticationProvider";
import { DraftEditForm } from "../../../components";
import "./Registers.scss";
import { AlertDialogWithUserInfo } from "../../../components/AlertDialogWithUserInfo";
import { Alert } from "@material-ui/lab";
import { StorageContainer, RegisterStatuses } from "../../../utils/enums";
import saveAs from "file-saver";
import { RegisterPreviewModal } from "./components/RegisterPreviewModal";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Banner } from "../../../components/Banner";
import { RegisterBanner } from "./Helpers/Classes";

interface IRegisterProps { }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notice: {
      backgroundColor: theme.palette.warning.main,
      padding: theme.spacing(1),
      textAlign: "center",
      fontSize: "15pt"
    },
    root: {
      border: 2,
      // color: 'white',
      backgroundColor: "primary",
      marginRight: '1em',
      '& > *': {
        margin: theme.spacing(1),
      },

    },

  }),
);


export const Registers = (props: IRegisterProps) => {
  const classes = useStyles();
  const [registerIssues, setRegisterIssues] = useState<RegisterIssues[]>([]);
  const [selectedView, setSelectedView] = useState<string>("issues");
  const [selectedIssue, setSelectedIssue] = useState<any>(null);
  const [draftEditOpen, setDraftEditOpen] = useState<boolean>(false);
  const [registerIssueRows, setRegisterIssueRows] = useState<any>([{}]);
  const [registerIssueColumns, setRegisterIssueColumns] = useState<any>([{}]);
  const [registerIssueOpen, setRegisterIssueOpen] = useState<boolean>(false);
  const [registerContentRows, setRegisterContentRows] = useState<any>([{}]);
  const [registerContentColumns, setRegisterContentColumns] = useState<any>([{}]);
  const [segmentHistoryRecord, setSegmentHistoryRecord] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [draftData, setDraftData] = useState<any>({});
  const [segmentHistoryData, setSegmentHistoryData] = useState<any>({});
  const [openErrorSnack, setOpenErrorSnack] = useState<boolean>(false);
  const [regGenSnackMessage, setRegGenSnackMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openPublishSnack, setOpenPublishSnack] = useState<boolean>(false);
  const [publishMessage, setPublishMessage] = useState<string>("");
  const [lockedId, setLockedId] = useState<string>("");
  const [issueApproved, setIssueApproved] = useState<boolean>(false);
  const [checkboxLabel, setCheckboxLabel] = useState<string>("Approve Filing(s)");
  const [filingsApproved, setFilingsApproved] = useState<boolean>(false);
  const [dateModified, setDateModified] = useState<string>();
  const [nonEditableCount, setNonEditableCount] = useState<number>(0);
  const [sectionNumber, setSectionNumber] = useState<string>("");
  const [generateBtnIsHidden, setGenerateBtnIsHidden] = useState<boolean>(false);
  const [publishBtnIsHidden, setPublishBtnIsHidden] = useState<boolean>(false);
  const [previewBtnIsHidden, setPreviewBtnIsHidden] = useState<boolean>(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [showBannerMessage, setShowBannerMessage] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState<JSX.Element>(<div></div>)
  const [registerGenMessage, setRegisterGenMessage] = useState<string>("");
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  // const [updateUserData, setUpdateUserData] = useState<any>({});
  // const [segmentData, setSegmentData] = useState<any>({});
  // const [openRegGenSnack, setOpenRegGenSnack] = useState<boolean>(true);
  // const [registerContentId, setRegisterContentId] = useState<number | null | undefined>();
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // Instantiate class for alert banner
  const BannerService = new RegisterBanner();

  const onMount = async () => {

    let registriesList = await registriesAPI.getAllRegistries({
      pageNumber: 1,
      pageSize: 2000,
    });

    registriesList.forEach((registry: any) => {
      registry.published = (registry.registerStatusId === 1)
        .toString()
        .toUpperCase();

      if (registry.publishedOn != null) {

        let date = new Date(registry.publishedOn);
        registry.publishedOn = date.toLocaleDateString('en-US');
      } else {
        registry.publishedOn = "No publish date";
      }

    });

    let data: any = setRegisterIssueData(registriesList);
    setRegisterIssueColumns(data!.columns);
    setRegisterIssueRows(data!.rows);
  };

  useEffect(() => {
    onMount();
    return () => {
      // cleanup
    };
  }, []);

  /**
   * @description Gets register data from register id to populate the register content table
   * @param id register issue id to fetch contents
   */
  const getRegisterContent = async (data: any) => {
    let dataId = data.id;
    let nonEditableRecordCount = 0;

    let registerContent = await registriesAPI.getAggregatedRegistry(dataId);

    // get count of editable items.
    registerContent.forEach((registry: any) => {
      if (registry.lockedByUserId != null) {
        nonEditableRecordCount++;
      }
    });

    setNonEditableCount(nonEditableRecordCount);
    let tableData = setRegisterContentData(registerContent);
    if (tableData) {
      setRegisterContentRows(tableData.rows ?? []);
      setRegisterContentColumns(tableData.columns ?? []);
    }
    await registerGenStatus(data);
    checkForBannerMessages();
  };

  /**
   * @param data used to pass the register issue data into the register content table
   */
  const editRegisterIssue = async (data: any) => {
    setSelectedView("registerContents");
    setSelectedIssue(data);
    // setRegisterContentId(data.id);
    setDraftEditOpen(false);

    let register = await registriesAPI.getRegistriesById(data.id);

    // Set generation button visibility
    if (register.registerStatusId == RegisterStatuses.Published)
      setGenerateBtnIsHidden(true);
    else
      setGenerateBtnIsHidden(false);

    let filingCantBePublished = false;
    for (var i = 0; i <= registerContentRows.length - 1; i++) {
      if (registerContentRows[i].fileTypeCanBePublished == false && filingCantBePublished == false) {
        filingCantBePublished = true;
      }
    }

    // Set approve filings cbx
    if (register.registerStatusId == RegisterStatuses.New) {
      setIssueApproved(false);
      setFilingsApproved(false);
      setCheckboxLabel("Approve Filing(s)");
    } else if (register.registerStatusId == RegisterStatuses.Published) {
      setIssueApproved(true);
      setFilingsApproved(true);
      setCheckboxLabel("Register Published");
    } else if (
      filingCantBePublished ||
      data.submissionDeadline > registerContentRows[0].filingSubmittedDate ||
      data.approvedOn > data.acceptanceDeadline ||
      data.published == true) {
      setCheckboxLabel("This register cannot be published");
      setNonEditableCount(1);
    } else {
      setIssueApproved(true);
      setFilingsApproved(true);
      setCheckboxLabel("Filing(s) Approved");
    }

    // if(registerContentRows[0].fileTypeCanBePublished != true || data.submissionDeadline )

    // Set publish and preview button visibility
    if (register.approvedOn != null && register.registerStatusId == RegisterStatuses.GenerationComplete) {
      setPublishBtnIsHidden(false);
      setPreviewBtnIsHidden(false);
    } else {
      setPublishBtnIsHidden(true);
      setPreviewBtnIsHidden(true);
    }

    getRegisterContent(data);
  };

  const { getUser } = useContext(
    AuthenticationContext
  );

  // Get user data
  const getAuthentication = async () => {
    // console.log("hit authentication call");
    let authorized = await getUser();
    // updateEditStatus(authorized?.profile);
    return authorized?.profile.sub;

  };

  /**
   *
   * @param data
   */
  const editRegisterContent = async (data: any) => {
    // set sectionNum for selected record.
    setSectionNumber(data.dataItem.sectionNum);

    // get segment history data and set id to the segmentId for update in draft edit form
    let segment = await segmentAPI.getSegmentById(data.dataItem.segmentId);
    let segmentHistory = await segmentHistoryAPI.getSegmentHistoryById(data.dataItem.id);

    interface data {
      [key: string]: any;
    }

    let segmentHistoryData: data = segmentHistory.segmentData;

    segmentHistoryData.id = segmentHistory.segmentId;

    // setSegmentData(segment);
    setSegmentHistoryData(segmentHistory);
    let draft = await draftAPI.getDraft(data.dataItem.draftId);
    setDraftData(draft);
    setLockedId(data.dataItem.lockedByUserId);
    if (data.dataItem.lastModified) {
      let date = new Date(data.dataItem.lastModified).toLocaleString();
      setDateModified(`Started editing this record: ${date}`);
    }

    // update segment history with user id
    let userId = await getAuthentication();

    setSegmentHistoryRecord(segmentHistory.segmentData);

    // open alert or edit modal based on lock status.
    if (data.dataItem.lockedByUserId == null && segmentHistory.lockedByUserId == null) {
      segmentHistory.lockedByUserId = userId;

      if (segmentHistory.lastModified != undefined) {
        var utcDate = new Date().toISOString();
        segmentHistory.lastModified = utcDate;
      }

      await segmentHistoryAPI.updateSegmentHistory(segmentHistory);
      toggleDraftEditModal();
      // set banner after change saved
    } else {
      setVisible(true);
    }
  };

  /**
   * @description opens and closes the draft segment editor from the RegisterContentTable
   */
  const toggleDraftEditModal = () => {
    draftEditOpen ? setDraftEditOpen(false) : setDraftEditOpen(true);

    // reload table contents
    getRegisterContent(selectedIssue);
  };

  /**
   * @description opens and closes the register create modal
   */
  const toggleRegisterModal = () => {
    // console.log("toggling register issue", registerIssueOpen);
    registerIssueOpen
      ? setRegisterIssueOpen(false)
      : setRegisterIssueOpen(true);
  };

  /**
   * @description toggles the preview modal when the register is not in a published status
   */
  const togglePreviewModal = () => {
    openPreviewModal ? setOpenPreviewModal(false) : setOpenPreviewModal(true);
  }

  const navigateToIssues = () => {
    setSelectedView("issues");
    setIssueApproved(false);
    setFilingsApproved(false);
    setCheckboxLabel("Approve Filing(s)");
    // clean up register content data so it doesn't persist when navigating to a new issue
    setRegisterContentRows([{}]);
    setRegisterContentColumns([{}]);
    setSegmentHistoryRecord({});
    setDraftData({});
    closeBanner();
  };

  const alertResponse = async (response: boolean) => {
    if (response) {
      // Set UTC date to be converted in users local time.
      let date = new Date();
      segmentHistoryData.lastModified = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();

      let value = segmentHistoryData;

      value.lockedByUserId = await getAuthentication();

      await segmentHistoryAPI.updateSegmentHistory(value);
      toggleDraftEditModal();
    }
    visible ? setVisible(false) : setVisible(true);
  };

  // unlock record on back button click.
  const updateUserEditStatus = async () => {
    setSelectedView("registerContents");
    segmentHistoryData.lockedByUserId = null;
    await segmentHistoryAPI.updateSegmentHistory(segmentHistoryData);
    setDraftEditOpen(false);
    getRegisterContent(selectedIssue);
  };

  const openError = (errorMessage?: string) => {
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setOpenErrorSnack(true);
  };

  /*
  In order to help OAR understand the state of generation process, upon register details
  load display status messages below the Generate, Preview, and Publish buttons
  (upon view load, no client notification or any thing)
    1. - No files generated - "No preview generated" (urls exist on the register record)
    2. - Generation in process (start date set and end is null OR end < start) -
        "Generation in process. Started at [date time]”
    3. - Generated but not published - "Generated on [date time]"
    4. - Published - "Published on [date time]"
  */
  const registerGenStatus = async (data: any) => {
    let generatedDate = new Date(data.generatedTempRegisterDate);
    let newGeneratedDate = new Date(generatedDate.getTime() - generatedDate.getTimezoneOffset() * 60 * 1000).toLocaleString();
    if (data != null) {
      let registerStatusMessage = "";
      if (data.registerStatusId === RegisterStatuses.New) {
        registerStatusMessage = "No preview generated";
      } else if (registerContentRows[0].fileTypeCanBePublished == true) {
        registerStatusMessage = "This register cannot be published or generated";
      } else if (data.registerStatusId === RegisterStatuses.GenerationInProgress) {
        registerStatusMessage = "Generation in progress. Started at " + new Date().toLocaleString();
        setShowSpinner(true);
      } else if (data.registerStatusId === RegisterStatuses.GenerationComplete) {

        registerStatusMessage = "Generated on " + newGeneratedDate;
        setShowSpinner(false);
      } else if (data.registerStatusId === RegisterStatuses.Published) {
        let date = new Date(data.lastModified).toLocaleString();
        registerStatusMessage = "Published on " + newGeneratedDate;
      }
      setRegGenSnackMessage(registerStatusMessage);
    } else {
      setRegGenSnackMessage("");
    }
  };

  // closes error snack message after alloted time
  const handleSnackClose = () => {
    setOpenErrorSnack(false);
  };

  const openPublish = (publishMessage?: string) => {
    if (publishMessage) {
      setPublishMessage(publishMessage);
    }
    setOpenPublishSnack(true);
  };

  const handlePublishSnackClose = () => {
    setOpenPublishSnack(false);
  }

  const approveRegisterIssue = async () => {
    let register = await registriesAPI.getRegistriesById(selectedIssue.id);
    // console.log("Register: ", register);
    setPublishBtnIsHidden(true);
    setPreviewBtnIsHidden(true);

    if (nonEditableCount == 0) {
      let filingCantBePublished = false;
      let filingTypes = await filingTypeAPI.getAllFilingTypesNonPaging();
      let filingTypeCandidatesForRegisterPublication = filingTypes.filter(x => x.isCandidateForRegister).map(x => x.id);

      for (var i = 0; i <= registerContentRows.length - 1; i++) {
        let filing = registerContentRows[i];
        let filingHasValidFilingTypeForPublication = filingTypeCandidatesForRegisterPublication.includes(filing.filingTypeId);
        if (filingHasValidFilingTypeForPublication === false) {
          filingCantBePublished = true;
          break;
        }
      }

      if (register.registerStatusId != RegisterStatuses.Published && issueApproved || filingCantBePublished == true) {
        setIssueApproved(false);
        setFilingsApproved(false);
        setCheckboxLabel("Approve Filing(s)");
        register.registerStatusId = RegisterStatuses.New;
        register.approvedOn = null;
      } else if (register.registerStatusId != RegisterStatuses.Published && !issueApproved) {
        // Set checkbox to allow filings approval if conditions are met.
        setIssueApproved(true);
        setFilingsApproved(true);
        setCheckboxLabel("Filing(s) Approved");
        register.registerStatusId = RegisterStatuses.Pending;
        register.approvedOn = new Date();
      }
      registriesAPI.updateRegistries(register.id!, register);
      // TODO: get this playing nice with the banner
      getRegisterContent(selectedIssue);
    } else {
      setCheckboxLabel("Cannot approve filing(s) while items are being edited.");
      register.approvedOn = null;
    }
  };

  /**
   * @description Get PDF register from blob storage and display after download.
  */
  const getRegisterPreview = async (register: any) => {
    let container = register.registerStatusId == RegisterStatuses.Published ? StorageContainer.PublicRegister : StorageContainer.TempPublicRegister;
    let url: any = await fileAPI.getFileUrl(register.pdfFileName, container);
    let blob: Blob = await fetch(url).then((r) => r.blob());

    if (register.registerStatusId != RegisterStatuses.Published) {
      togglePreviewModal();
    } else {
      saveAs(blob, register.pdfFileName);
    }
  };

  const generateRegister = async (registerDetails: Registries) => {
    // console.log("Generate Register", registerDetails);
    // Verify filing type can be submitted
    // Verify filing was submitted before register submission date deadline
    //

    setShowSpinner(true);
    let startTime = new Date().toLocaleString();
    setRegGenSnackMessage(`Generation in progress. Started at ${startTime}`);
    await registriesAPI.updateRegisterStatus(registerDetails.id!, RegisterStatuses.GenerationInProgress);
    let generatedDocument = await registerGenerationAPI.generate(registerDetails.id!, registerDetails.volumeNumber!, registerDetails.issueNumber!);

    let endTime = new Date().toLocaleString();
    setRegGenSnackMessage(`Generated on ${endTime}`);

    if (generatedDocument.htmlName)
      registerDetails.htmlFileName = generatedDocument.htmlName;

    await registriesAPI.updateRegisterAfterGenerationAsync(registerDetails.id!, registerDetails.htmlFileName);

    // Get updated register details after generation
    let register = await registriesAPI.getRegistriesById(registerDetails.id!);
    setSelectedIssue(register);

    setPublishBtnIsHidden(false);
    setPreviewBtnIsHidden(false);
    onMount();
    registerGenStatus(registerDetails);
    setShowBannerMessage(false);
    setOpenPreviewModal(true);
    setShowSpinner(false);
  };

  const publishRegister = async (registerDetails: Registries) => {
    if (await validateUser() && (registerDetails.registerStatusId == RegisterStatuses.GenerationComplete)) {
      try {
        openPublish("Register Generation and Publication is processing. This may take some time to complete. Please check publication status at a later time.");
        let rai = await rulesAffectedIndexAPI.getRaiForCurrentRegister(registerDetails.id!);
        let finalizedRegister = new FinalizedRegister(registerDetails.id!, registerDetails.volumeNumber!, registerDetails.issueNumber!, rai);
        let finalize = await registriesAPI.finalizeRegisterGeneration(finalizedRegister);
        let publish = await registriesAPI.publishRegister(registerDetails.id!);

        // Updates all filings associated with register by triggering their "publish" action
        await registryFilingAPI.publishRegisterFilings(registerDetails.id!);

        let date = new Date().toLocaleString();
        setRegGenSnackMessage(`Published on ${date}`);
        setCheckboxLabel("Register Published");
        setNonEditableCount(1); // Disabling approve cbx
        setGenerateBtnIsHidden(true);
        setPublishBtnIsHidden(true);
        setPreviewBtnIsHidden(true);
      } catch (error: any) {
        setGenerateBtnIsHidden(false);
        setPublishBtnIsHidden(true);
        setPreviewBtnIsHidden(true);
        console.error("Error: Could not publish register", error)
        openError(error.message);
      }
    } else {
      if (await validateUser() == false)
        openError("Invalid user.");
      else if (registerDetails.registerStatusId != RegisterStatuses.GenerationComplete)
        openError("Register generation is still in progress.");
    }
  };

  const validateUser = async () => {
    let user = await getUser();
    let userIsOAR = user?.profile.role.includes('Global User Administrator');
    let userIsAdmin = user?.profile.role.includes('Office of Administrative Rules');
    return (userIsOAR || userIsAdmin);
  }

  const checkForBannerMessages = async () => {
    let foundUpdates = await BannerService.checkForBannerMessage(selectedIssue, registerContentRows, selectedView);
    if (foundUpdates == true) {
      setShowBannerMessage(true);
      setBannerMessage(
        <div className="warning-banner">
          Pending changes found. Please generate a new document to include them.
        </div>
      );
    } else {
      setShowBannerMessage(false);
    }
  }

  const closeBanner = () => {
    setShowBannerMessage(false);
  }

  return (
    <div>
      <AlertDialogWithUserInfo
        close={!visible}
        open={visible}
        textContent={"This is currently being edited by the user below. By clicking the 'OVERRIDE AND EDIT' button, any changes you make will overwrite changes to this draft."}
        alertResponse={alertResponse}
        user={lockedId}
        modifiedDetail={dateModified}
      />
      <Snackbar
        open={openErrorSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity="error">
          {errorMessage != ""
            ? `Error: ${errorMessage}`
            : "Error: Failed To Save"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPublishSnack}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={8000}
        onClose={handlePublishSnackClose}
      >
        <Alert onClose={handlePublishSnackClose}>
          {publishMessage != ""
            ? `Publish: ${publishMessage}`
            : "Publish: Failed To Save"}
        </Alert>
      </Snackbar>
      <RegisterIssueModal open={false} close={toggleRegisterModal} />
      <RegisterPreviewModal
        open={openPreviewModal}
        close={togglePreviewModal}
        volumeNum={selectedIssue ? selectedIssue.volumeNumber : 0}
        issueNum={selectedIssue ? selectedIssue.issueNumber : 0}
        register={selectedIssue ? selectedIssue : null}
      />
      <Banner style="warn" showBanner={showBannerMessage} onClose={closeBanner}>
        {bannerMessage}
      </Banner>
      <div className="state-register">
        {segmentHistoryRecord && draftEditOpen ? (
          <div className="edit-view">
            <div className="button-toolbar">
              <Button
                variant="outlined"
                size="small"
                onClick={updateUserEditStatus}
              >
                <ArrowBackIosRounded />
                BACK
              </Button>
            </div>
            <DraftEditForm
              sectionNum={sectionNumber}
              edit={segmentHistoryRecord}
              draftData={draftData}
              originalSegment={draftData}
              reload={toggleDraftEditModal}
              isRegister={true}
              openError={openError}
              segmentHistoryId={segmentHistoryData.id}
              isReadOnly={selectedIssue.registerStatusId == RegisterStatuses.Published || selectedIssue.registerStatusId == RegisterStatuses.Pending}
            />
          </div>
        ) : null}
        {selectedView == "issues" && !draftEditOpen ? (
          <RegisterIssueTable
            rows={registerIssueRows}
            columns={registerIssueColumns}
            numberOfRecords={registerIssueRows.length ?? 0}
            edit={editRegisterIssue}
            add={toggleRegisterModal}
            tableName="Registers"
          />
        ) : !draftEditOpen ? (
          <div className="register-content">
            <div className="button-toolbar">
              <Button
                className="back-button"
                variant="contained"
                color="primary"
                size="small"
                onClick={navigateToIssues}
              >
                <ArrowBackIosRounded />
                BACK
              </Button>

              <div className={classes.root}>
                <FormControlLabel
                  className="register-checkbox"
                  label={checkboxLabel}
                  control={
                    <Checkbox checked={issueApproved} onChange={approveRegisterIssue} disabled={nonEditableCount > 0 || selectedIssue.registerStatusId == RegisterStatuses.Published} />
                  }
                />
                {filingsApproved ? (
                  <ButtonGroup aria-label="contained primary button group" variant="contained" color="primary" className="button-group">
                    <Tooltip title="Generate Register" placement="top">
                      <Button hidden={generateBtnIsHidden} onClick={() => generateRegister(selectedIssue)}>
                        {showSpinner ? (<CircularProgress color="inherit" size={20} />) : "Generate"}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Preview Register" placement="top">
                      <Button hidden={(previewBtnIsHidden)} onClick={() => getRegisterPreview(selectedIssue)}>
                        Preview
                      </Button>
                    </Tooltip>
                    <Tooltip title="Publish Register" placement="top">
                      <Button hidden={publishBtnIsHidden} onClick={() => publishRegister(selectedIssue)}>
                        Publish
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                ) : null}
                {regGenSnackMessage != "" ? (
                  <div className={classes.notice}>{regGenSnackMessage}</div>
                ) : null}
              </div>
            </div>
            {selectedIssue != null ? (
              <RegisterContentTable
                rows={registerContentRows}
                columns={registerContentColumns}
                numberOfRecords={5}
                edit={editRegisterContent}
                regApprovedChecked={issueApproved}
                tableName={`Register/${selectedIssue.title}`}
                filingsApproved={filingsApproved}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};