import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process, SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./RegisterIssue.scss"
import { CircularProgress, Tooltip } from '@material-ui/core';
import {
  ActionsLayout,
  Dialog,
  DialogActionsBar,
} from "@progress/kendo-react-dialogs";
import { draftAPI } from '../../../../../utils/api';
import { AlertDialog } from '../../../../../components';


interface IRegisterIssueProps {
  rows:any;
  columns:any;
  numberOfRecords:number;
  add?: () => void;
  edit: (data:any) => void;
  height?: string;
  tableName?: string;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

/**
 *
 * @param {Object[]} rows - Array of row data objects
 * @param {Object[{title:string,field:string}]} columns - array of column name objects {title:string,field:string}
 * @param {number} numberOfRecords - specify total number of records in the table
 * @param {() => void} [add] - function to open dialog for adding new table data
 * @param {string} [height] - string to override the table height
 * @param {string} [tableName] - adds a lable to the table if desired
 * @returns kendo table component for draft records
 */
export const RegisterIssueTable = (props:IRegisterIssueProps) => {
  // const initialSort: Array<SortDescriptor> = [
  //   { field: "publishedOn", dir: "desc"},
  // ];
  const [height, setHeight] = React.useState<any>("75vh");
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 24,
    sort: [{ field: "publishedOn", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );
  const [visible, setVisible] = React.useState(false);
  const [draftToDelete, setDraftToDelete] = React.useState<any>();
  // const [sort, setSort] = React.useState(initialSort);



  const onMount = React.useCallback(async() => {
      if(props.height) {
        setHeight(props.height);
      }

      for(let i = 0; i < props.rows.length; i++) {

        if (props.rows[i].generatedTempRegisterDate !== null) {
          const date = new Date(props.rows[i].generatedTempRegisterDate);
          props.rows[i].generatedTempRegisterDate = new Date(date.getTime() - date.getTimezoneOffset() + 60 + 1000).toLocaleString();
        }
      }
      setDataResult(process(props.rows, dataState));

  },[props.rows, props.columns])

  React.useEffect(() => {
    onMount();
  }, [props])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  let _pdfExport: GridPDFExport | null;
  const exportPDF = () => {
    if(_pdfExport !== null) {
      _pdfExport.save();
    }
  };


  const deleteDraft = async (dataItem: any) => {
    let deleteResponse = await draftAPI.deleteDraft(dataItem.id);
    alert(`${draftToDelete.draftName} Successfully Deleted.`);
    setVisible(!visible);
  }

  const toggleDialog = (dataItem: any) => {
    setDraftToDelete(dataItem);
    setVisible(!visible);
  };

  const alertResponse = (response:boolean) => {
    visible ? setVisible(false) : setVisible(true);
    if(response) {
      deleteDraft(draftToDelete);
    }
  }

  const editButtonColumn = (data: GridCellProps) => {
    return (
      <td className="newIcon" role="gridcell">
        <Tooltip title="View Register" aria-label="add">
          <Button
            className="k-button k-primary edit-button"
            onClick={() => props.edit(data.dataItem)}
          >
            View
          </Button>
        </Tooltip>
      </td>
    );
  }

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element:any, i:number) => {

      if(element.hidden != true) {
        return (<GridColumn field={element.field} key={i} title={element.title}/>);
      }
    });

    columnsToShow.unshift(<GridColumn key="View" field={"View"} title="View Register" cell={editButtonColumn}/>);
    // columnsToShow.unshift(<GridColumn field={"dueDate"} title="Status" cell={dueDateColumn}/>);

    if(columnsToShow.length > 0 && props.rows.length > 0) {
      return (
          <Grid
              className="draft"
              key={"RegisterIssue"}
              style={{
                height: height,
              }}
              sortable={true}
              filterable={true}
              resizable={true}

              reorderable={true}
              pageable={{
                buttonCount: 4,
                pageSizes: [12, 24, 48, props.numberOfRecords]
              }}
              data={dataResult}
              {...dataState}
              onDataStateChange={dataStateChange}
              // onSortChange={(e: GridSortChangeEvent) => {
              //   setSort(e.sort);
              // }}
              total={props.numberOfRecords ? props.numberOfRecords : 0}
            >
              <GridToolbar>
                <ButtonGroup className="button-group">
                  <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
                  <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={props.add}/>
                </ButtonGroup>
                {props.tableName ? (
                  <h3>{props.tableName}</h3>
                ) : null}
              </GridToolbar>
              <GridNoRecords>
                <CircularProgress />
              </GridNoRecords>
              { columnsToShow }
            </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="work-queue">
      <AlertDialog
        textContent={"This will delete the draft " + (draftToDelete ? draftToDelete.draftName : "")}
        open={visible}
        close={!visible}
        alertResponse={alertResponse}
      />
      {_renderTable()}
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}