// UI imports
import "./SubscriptionManagement.scss";

import {
  Card,
  CardBody,
  CardTitle,
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {

  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Menu } from "../../../components/Menu";
import { getOS } from "../../../utils/UserOS";

import { useSubscriptionManagementController } from "./SubscriptionManagementController";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  Button as mButton,
  CircularProgress,
  Snackbar,
  Typography,
  makeStyles,
  Input,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ExcelExport } from "@progress/kendo-react-excel-export";

    const useStyles = makeStyles({
      inputRight : {
        textAlign:"right",
        fontSize:"1.25rem",
      }
    });

export const SubscriptionManagement = () => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";
  const getLocalStorage = localStorage.getItem("subscriptionUser");

  const {
    loading,
    users,
    emailFromAddress,
    selected,
    alertMessageType,
    alertMessageOpen,
    alertMessage,
    dataState,
    dataResult,
    dataStateChange,
    commandCell,
    customDateCell,
    handleFromEmailChange,
    handleUpdateFromEmailSubmit,
    handleSelect,
    toggleAlert,

  } = useSubscriptionManagementController();

  // Gets all subscription users.

  // Disable subscription account.

  // Get all subscription templates
  // unsubscribes from all items (chapters)

  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  const classes = useStyles();

  const _renderTable = () => {
    return (
      <Grid
        filterable={true}
        sortable={true}
        pageable={{
          buttonCount: 3,
          pageSizes: [5, 10, users.length],
        }}
        reorderable={false}
        data={dataResult}
        {...dataState}
        onDataStateChange={dataStateChange}
      >
        <Column field="emailAddress" title="Email" />
        <Column
          field="registeredDate"
          filterable={false}
          title="Date Registered"
          cell={customDateCell}
        />
        <Column
          field="acctDisabledDate"
          filterable={false}
          title="Date Disabled"
          cell={customDateCell}
        />

        <Column cell={commandCell} filterable={false} title="Actions" />
        <GridToolbar>
          <ButtonGroup className="button-group">
            <Button
              icon="xls"
              title="Export to Excel"
              className="k-button k-secondary button"
              onClick={exportExcel}
            />
          </ButtonGroup>
        </GridToolbar>
      </Grid>
    );
  }

  return (
    <div className="chapters-results">
      <header className="app-bar" style={{ marginBottom: "5em" }}>
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      <div className="body rule-management">
        {loading ? (
          <div className="history-loading">
            <CircularProgress /> Loading
          </div>
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={alertMessageOpen}
          autoHideDuration={6000}
          onClose={toggleAlert}
        >
          <Alert
            elevation={20}
            variant="filled"
            severity={alertMessageType === "success" ? "success" : "warning"}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <GridLayout
          rows={[{ height: "auto" }, { height: "100%" }]}
          cols={[
            { width: "20%" },
            { width: "80%" },
            // {width: "10%"}
          ]}
        >
          <GridLayoutItem
            row={1}
            col={1}
            colSpan={2}
            className={"subscriptions-heading"}
          >
            <div>
              <h2>Subscription Management</h2>
              <hr />
            </div>
          </GridLayoutItem>

          <GridLayoutItem row={2} col={1} colSpan={2}>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              tabPosition={"left"}
            >
              <TabStripTab title="Manage Subscription Users">
                <div className="subscription-users responsive-widget-wrapper">
                  <ExcelExport
                    data={users}
                    ref={(exporter: any) => {
                      _export = exporter;
                    }}
                  >
                    {_renderTable()}
                  </ExcelExport>
                </div>
              </TabStripTab>
              <TabStripTab title="Manage From: Email Address">
                <Card className="card-container">
                  <CardBody>
                    <CardTitle
                      style={{
                        textAlign: "center",
                        color: "cornflowerblue",
                        fontSize: "large",
                      }}
                    >
                      Update the from email address for the subscriptions
                    </CardTitle>
                      <div style={{display:'flex'}}>
                        <Input
                          className="input-box"
                          name="fromAddress"
                          value={emailFromAddress}
                          onChange={handleFromEmailChange}
                          classes= {{
                             input: classes.inputRight
                          }}
                          ></Input>
                        <Typography variant="h6" >
                          @sos.ok.gov
                        </Typography>
                        <Button
                          onClick={handleUpdateFromEmailSubmit}
                          className="button-update-email k-button k-button-sm k-rounded-sm k-button-outline k-button-outline-primary"
                          >
                          Update
                        </Button>
                      </div>

                  </CardBody>
                </Card>
              </TabStripTab>
            </TabStrip>
          </GridLayoutItem>
        </GridLayout>
      </div>
    </div>
  );
};
