export enum SegmentStatus {
  Added,
  Amended = 13,
  AmendedRenumbered = 5,
  Codified,
  Expired = 4,
  Misc,
  New = 1,
  NumberChange,
  Other = 10,
  Published = 11,
  Renumbered = 6,
  Reserved = 3,
  Revoked = 2,
  RevokedReenacted,
  Superseded = 7,
  Terminated = 8,
  Transferred = 9,
  Undefined = 12
}
