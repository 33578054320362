import { Identifiable } from ".";

export class Address extends Identifiable {
    constructor(address1: string, address2: string, address3: string, city: string, state: string, country: string, postalCode: number) {
        super(null);
        this.address1 = address1;
        this.address2 = address2;
        this.address3 = address3;
        this.city = city;
        this.state = state;
        this.country = country;
        this.postalCode = postalCode;
    }

    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public country: string;
    public postalCode: number;
}