import React, { useEffect, useState } from "react";
import {
  Collapse,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";

import "./CheckBoxFacet.scss";

export const CheckBoxFacet = (props: any) => {
  let [checkboxValues, setCheckboxValues] = React.useState<any>(
    props.values ?? null
  );

  const facetListItemLength = props.name === "Title Number" ? "207px" : "207px";
  const FacetListItem = styled(ListItem)({
    display: "flex",
    // paddingLeft: '36px !important',
    width: facetListItemLength,
    color: "#3f51b5",
    overflowY: "hidden",
  });

  const FacetValueListItem = styled(ListItem)({
    // marginLeft: '10px !important',
    width: facetListItemLength,
    overflowY: "hidden",
  });

  const FacetValuesList = styled(List)({
    maxHeight: "100%",
    overflowY: "auto",
    // marginRight: '18px'
  });

  useEffect(() => {
    if (props.values) {
      setCheckboxValues(props.values);
    }
  }, [props.values]);

  const [isExpanded, setIsExpanded] = useState(false);

  const getCheckboxes = () => {
    let checkboxes;
    if (checkboxValues) {
      checkboxes = checkboxValues.map(
        (facetValue: { value: string | undefined; count: string }) => {
          // console.log("CheckBoxValues", facetValue);
          let isSelected = props.selectedFacets.some(
            (facet: { value: string | undefined }) =>
              facet.value === facetValue.value
          );

          return (
            <FacetValueListItem
              dense
              button
              disableGutters
              id={facetValue.value}
            >
              <Checkbox
                edge="start"
                disableRipple
                checked={isSelected}
                onClick={
                  isSelected
                    ? () =>
                        props.removeFilter({
                          field: props.name,
                          value: facetValue.value,
                        })
                    : () => props.addFilter(props.name, facetValue.value)
                }
              />
              {props.name === "Title_Number" ? (
                <ListItemText
                  primary={
                    props.name +
                    " " +
                    facetValue.value +
                    " (" +
                    facetValue.count +
                    ")"
                  }
                />
              ) : (
                <ListItemText
                  primary={facetValue.value + " (" + facetValue.count + ")"}
                />
              )}
            </FacetValueListItem>
          );
        }
      );
    } else {
      checkboxes = <div>test</div>;
    }

    return checkboxes;
  };

  return (
    <div>
      <FacetListItem
        disableRipple={true}
        button
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ListItemText primary={props.mapFacetName(props.name)} />
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </FacetListItem>
      <Collapse in={isExpanded} component="div">
        <FacetValuesList className="list-display">
          {getCheckboxes()}
        </FacetValuesList>
      </Collapse>
    </div>
  );
};
