import { useContext } from "react";
import { searchContext } from "../../publicFacing/pages/CodeSearch/components/SearchProvider/SearchProvider";
import { ISearchFilter } from "../interfaces/ISearchFilter";

/**
 * Exposes the state, and SOME functionality from the SearchProvider.
 * NOTE: When used, must be under component tree where the SearchProvider is a parent.
 */
export const useSearch = () => {
  const {
    searchQuery,
    searchQueryDefaults,
    searchResults,
    setSearchQuery,
  } = useContext(searchContext);

  /**
   * Sets a single search filter
   * @param name name of the filter
   * @param value value of the filter
   */
  const setSearchFilter = <T extends keyof ISearchFilter>(
    name: T,
    value: ISearchFilter[T]
  ) => {
    setSearchQuery({
      ...searchQuery,
      [name]: value,
    });
  };

  /**
   * Clears search filters
   */
  const clearSearchFilters = () => {
    setSearchQuery(searchQueryDefaults);
  };

  return {
    setSearchFilter,
    searchFilters: searchQuery as ISearchFilter,
    searchResults: searchResults,
    clearSearchFilters,
  };
};
