import { IconButton } from "@material-ui/core";
import DialogTitle, { DialogTitleProps } from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
// import React from "react";
import './DialogTitleWithExit.scss';

interface IDialogTitleWithExitProps extends DialogTitleProps {
    onClose: (event?:any) => void
}

export const DialogTitleWithExit = (props: IDialogTitleWithExitProps) => {
    const { children, onClose } = props;
    return (
        <DialogTitle {...props} className="dialog-title-with-exit">
            <IconButton aria-label="close dialog" onClick={onClose} className="close-icon">
                <CloseIcon />
            </IconButton>
            {children}
        </DialogTitle>
    );
}



