import React, { Fragment, useEffect } from "react";
import { contactAPI } from "../../../../utils/api";


interface IContactDisplayProps {
  contactIds: any;
}

export const ContactDisplay = (props: IContactDisplayProps) => {
  const [contact, setContact] = React.useState<any>();

  const getContact = React.useCallback(async() =>  {
    if(props.contactIds.liaison !== null) {
        let value:any = await contactAPI.getContactById(props.contactIds.liaison);
        setContact(value);
    }
  },[props.contactIds])

  useEffect(() => {
      getContact();
  }, [props.contactIds])



  return (
    <Fragment>
        <div className="name">
            {contact
            ? contact.firstName && contact.lastName
                ? contact.firstName + " " + contact.lastName
                : ""
            : ""}
        </div>
        <div className="phone">
            {contact
            ? contact.phone
                ? contact.phone
                : ""
            : ""}
        </div>
        <div className="email">
            {contact
            ? contact.email
                ? <a href={"mailto:" + contact.email}>{contact.email}</a>
                : ""
            : ""}
        </div>
    </Fragment>
  );
};
