import React from "react";
import "./Reports.scss";
import { IStateTabComponentProps } from "../IStateTabComponentProps";
import { ReportsWrapper } from "./components";

interface IReportsProps extends IStateTabComponentProps {}

export const Reports = (props: IReportsProps) => {
  return (
    <div className="reports">
      <ReportsWrapper {...props} />
    </div>
  );
};
