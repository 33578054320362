import { CircularProgress } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AuthenticationContext } from "./../../utils/authentication/AuthenticationProvider";
import "./Login.scss";

interface ILoginProps extends RouteComponentProps {}

export const Login = withRouter((props: ILoginProps) => {
  const { isAuthenticated, navigateToScreen } = useContext(
    AuthenticationContext
  );
  const initialAuthenticated = isAuthenticated();
  useEffect(() => {
    setAuthentication(isAuthenticated());
  }, [isAuthenticated]);
  const [authenticated, setAuthentication] = useState(initialAuthenticated);
  if (authenticated) {
    navigateToScreen();
  }
  return <CircularProgress />;
});
