import { Divider, ListItem } from "@material-ui/core";
import { UserAvatar } from "../../../UserAvatar";
import { Comment as CommentType } from "./../../../../utils/model";
import "./Comment.scss";

interface ICommentProps {
  key?: number;
  comment: CommentType;
}

export const Comment = (props: ICommentProps) => {
  const { key, comment } = props;
  
  return (
    <div className="comment" key={key}>
      <ListItem alignItems="flex-start">
        <UserAvatar userId={comment.createdBy!} displayText={comment.text} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </div>
  );
};
