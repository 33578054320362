import { CircularProgress, LinearProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { notificationEventAPI } from "../../../../../../../utils/api";
import { Action } from "../../../../../../../utils/model";
import { NotificationEvent as NotificationEventModel } from "./../../../../../../../utils/model/subscription";
import "./ActionNotification.scss";
import { NoAction } from "./components/NoAction";
import { NoNotificationEvent } from "./components/NoNotificationEvent";
import { NotificationEvent } from "./components/NotificationEvent";

interface IActionNotificationProps {
  action?: Action;
}

export const ActionNotification = (props: IActionNotificationProps) => {
  enum RenderComponent {
    loading,
    noAction,
    noNotificationEvent,
    notificationEvent,
  }
  const [renderComponent, setRenderComponent] = useState<RenderComponent>(
    RenderComponent.noAction
  );
  const [
    notificationEvent,
    setNotificationEvent,
  ] = useState<NotificationEventModel | null>(null);
  
  const onLoad = useCallback(()=> async () => {
    //   If No Action set componentToRender
    if (props.action == null) {
      setRenderComponent(RenderComponent.noAction);
      return;
    } else if (props.action.id != null) {
      //   Check Notifications Api for event
      const notificationEventForAction = await notificationEventAPI.getByName(
        props.action.id.toString()
      );
      await notificationEventForAction;
      if (notificationEventForAction == null) {
        setRenderComponent(RenderComponent.noNotificationEvent);
      } else {
        setRenderComponent(RenderComponent.notificationEvent);
      }
      setNotificationEvent(notificationEventForAction);
    } else {
      console.error("action id was null when expected to be a number");
      setRenderComponent(RenderComponent.noAction);
    }
  },[RenderComponent,props.action]);

  useEffect(() => {
    onLoad();
  }, [props.action, renderComponent, onLoad]);

  

  const onChange = () => {
    onLoad();
  };
  //   RENDER AREA
  switch (renderComponent) {
    case RenderComponent.loading:
      return <CircularProgress />;
    case RenderComponent.noAction:
      return <NoAction />;
    case RenderComponent.noNotificationEvent:
      if (props.action != null) {
        return (
          <NoNotificationEvent onChange={onChange} action={props.action} />
        );
      }
      // falls through
    case RenderComponent.notificationEvent:
      if (notificationEvent?.id && props.action) {
        return (
          <NotificationEvent
            notificationEvent={notificationEvent}
            onChange={onChange}
            action={props.action}
          />
        );
      } else if (props.action) {
        return <LinearProgress />;
      }
      // falls through
    default:
      console.error(
        `Invalid renderComponent in ActionNotification.tsx ${renderComponent}`
      );
      return null;
  }
};
