import { Amendment } from "../model/Amendment";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class AmendmentAPI extends BaseAPI<Amendment> {
  constructor() {
    super("amendment", APISettings.adminRulesApiUrl);
  }
  public getAmendments: () => Promise<Amendment> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAmendments`)).data;
  };

  public getAmendmentsById: (id: number) => Promise<Amendment> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAmendmentsById/${id}`))
      .data;
  };

  public createAmendments: (Amendment: Amendment) => Promise<any> = async (
    amendment: Amendment
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateAmendments`,
      {
        amendment,
      }
    );
    return response.data;
  };

  public removeAmendments: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveAmendments/${id}`
    );
    return response.data;
  };

  public updateAmendments: (
    id: number,
    amendment: Amendment
  ) => Promise<Amendment> = async (id: number, amendment: Amendment) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateAmendment/${id}`,
      { amendment }
    );
    return response.data;
  };
}

export const amendmentAPI = new AmendmentAPI();
