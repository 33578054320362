import * as React from "react";
import {
  CompositeFilterDescriptor,
  DataResult,
  State,
  process,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";
import { EditCommandCell } from "./EditCommandCell";
import moment from "moment";
import { SubscriptionUser } from "../../../utils/model/SubscriptionUser";
import { userSubscriptionManagementAPI } from "../../../utils/api/subscription/UserSubscriptionManagementApi";
import { TabStripSelectEventArguments } from "@progress/kendo-react-layout";
import { EmailSystemAddress } from "../../../utils/model/EmailSystemAddress";

export const useSubscriptionManagementController = () => {
  const lastSuccess = React.useRef<string>("");
  const pending = React.useRef<boolean>(true);
  const [users, setUsers] = React.useState<any>([]);
  const [dataState, setDataState] = React.useState<State>({
    skip: 0,
    take: 10,
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(users, dataState)
  );
  const [loading, setLoading] = React.useState(false);
  const [isCurrentUser, setIsCurrentUser] = React.useState(true);
  const [emailFromAddress, setEmailFromAddress] = React.useState("");
  const [selected, setSelected] = React.useState<number>(0);
  const [alertMessageType, setAlertMessageType] = React.useState<string>("");
  const [alertMessageOpen, setAlertMessageOpen] =
    React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<any>(<p></p>);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>({
    logic: "and",
    filters: [],
  });

  React.useEffect(() => {
    async function getUsers() {
      setLoading(true);

      let users = await userSubscriptionManagementAPI.getAllSubscriptionUsers();
      setUsers(users);

      setDataResult(process(users, dataState))

      let publicEmailAddress = await userSubscriptionManagementAPI.getFromEmailAddress(1);
      setEmailFromAddress(publicEmailAddress.email);
      setLoading(false);
    }

    getUsers();
  }, []);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(users, event.dataState));
    setDataState(event.dataState);
  };

  const commandCell = (props: GridCellProps) => (
    <EditCommandCell
      {...props}
      isCurrentUser={isCurrentUser}
      handleStatusChange={handleStatusChange}
    />
  );

  const toggleAlert = () => {
    alertMessageOpen ? setAlertMessageOpen(false) : setAlertMessageOpen(true);
  };

  const customDateCell = (props: GridCellProps) => {
    return (
      <td>
        {props.dataItem[props.field!] === null
          ? ""
          : moment(props.dataItem[props.field!]).format("MM/DD/YYYY")}
      </td>
    );
  };

  const handleStatusChange = async (user: SubscriptionUser) => {

    switch (user.acctDisabled) {
      case true:
        try {
          await userSubscriptionManagementAPI.enableSubscriptionUser(user.emailAddress);
          user.acctDisabled = false;
          user.acctDisabledDate = null;
        } catch (error) {
          console.error(error)
        }
        break;
      case false:
        try {
          await userSubscriptionManagementAPI.disableSubscriptionUser(user.emailAddress)
          user.acctDisabled = true;
          user.acctDisabledDate = new Date();

        } catch (error) {
          console.error(error);

        }
        break;
    }

    let tempUsers = users;
    let tempUserIndex = tempUsers.findIndex((x: SubscriptionUser) => x.id == user.id);

    tempUsers[tempUserIndex] = user;

    setDataResult(process(tempUsers, dataState));

  };

  const handleFromEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    // update email from address
    setEmailFromAddress(value);
  };

  const handleUpdateFromEmailSubmit = async () => {
    if (emailFromAddress === "") {
      return;
    }

    const emailUserId = 1 // this is the userId for the system email table

    const emailSystemAddress: EmailSystemAddress = {
      id: emailUserId,
      email: emailFromAddress,
      emailSystemAddressTypeId: 1,
    };

    let success = await userSubscriptionManagementAPI.UpdateEmailSystemAddress(emailSystemAddress);
    if (success) {
      setAlertMessageType("success");
      setAlertMessage("Updated From: Email Address");
    } else {
      setAlertMessageType("warning");
      setAlertMessage("There was an error updating Email Address");
    }
    toggleAlert();

  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return {
    loading,
    users,
    isCurrentUser,
    emailFromAddress,
    selected,
    alertMessageType,
    alertMessageOpen,
    alertMessage,
    dataState,
    filter,
    dataResult,
    dataStateChange,
    commandCell,
    customDateCell,
    handleFromEmailChange,
    handleUpdateFromEmailSubmit,
    handleSelect,
    toggleAlert,
    setFilter,
  };
};
