import { useEffect, useState } from "react";
import ReactGa from "react-ga4";

export function useAnalytics() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGa.initialize("G-VZNQC37N9F");
      setInitialized(true);
    }

  }, [])

  return {
    initialized
  }
}