import { CircularProgress } from "@material-ui/core";
import React from "react";
import { AuthenticationContext } from "../../AuthenticationProvider";
import "./Logout";

interface ISignInRedirectCallbackProps {}

export const Logout = (props: ISignInRedirectCallbackProps) => {
  const { signout } = React.useContext(AuthenticationContext);
  
  React.useEffect(signout, [signout])

  return <CircularProgress />;
};
