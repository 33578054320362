import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, FormControlLabel, TextField, FormGroup, Checkbox, Typography, MenuItem, Select, ListItemText, InputLabel, FormControl } from "@material-ui/core";
import { DialogTitleWithExit } from "../../../../../../components/DialogTitleWithExit";
import { RichTextEditor } from "../../../../../../components/RichTextEditor";
import { contactAPI, emailTemplateAPI } from "../../../../../../utils/api";
import { emailGroupAPI } from "../../../../../../utils/api/EmailGroupApi";
import { EditorType } from "../../../../../../utils/enums";
import { Contact, EmailGroup, EmailTemplate } from "../../../../../../utils/model";
import "./EmailTemplateModal.scss";


interface IEmailTemplateModalProps {
  open: boolean;
  editData?: any;
  handleClickOpen: () => void;
  handleClose: () => void;
}

export const EmailTemplateModal = (props: IEmailTemplateModalProps) => {
  const { open, handleClickOpen, handleClose } = props;
  const [emailTemplate, setEmailTemplate] = React.useState<any>(null);
  const [emailBody, setEmailBody] = React.useState<any>("");
  const [ruleFiling, setRuleFiling] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [name, setName] = React.useState("");
  const [contactList, setContactList] = React.useState<any>(null);
  const [contactSelected, setContactSelected] = React.useState<any>([]);
  const [contactIdsSelected, setContactIdsSelected] = React.useState<any>([]);
  const [liaison, setLiaison] = React.useState(false);
  const [generalPublic, setGeneralPublic] = React.useState(false);
  const [governor, setGovernor] = React.useState(false);
  const [cabinet, setCabinet] = React.useState(false);
  const [oar, setOar] = React.useState(false);
  const [legislator, setLegislator] = React.useState(false);

  // Saves all form data to relevant databases
  // This first creates the email template then creates the email groups that reference the template that was just created to save the "To" contacts selected
  const saveForm = async () => {
    let success = true;
    try {
      let templateData: EmailTemplate = new EmailTemplate(name, subject, emailBody, ruleFiling, liaison, generalPublic, governor, cabinet, oar, legislator);
      templateData.id = emailTemplate.id;
      templateData.lastModified = new Date();
      await emailTemplateAPI.updateEmailTemplate(templateData);
    } catch (error) {
      console.error(error);
      success = false;
    }

    // remove existing contacts by template id and replace with new
    try {
      await emailGroupAPI.deleteByEmailTemplateId(emailTemplate.id);
    } catch (error) {
      console.error(error);
      success = false;
    }

    // replace with new email group
    try {
      let emailGroup: EmailGroup[] = [];
      contactSelected.forEach((contact: any, index: number) => {
        let groupItem = new EmailGroup(emailTemplate.id, contact.id);
        delete groupItem.id;
        emailGroup.push(groupItem);
      })
      await emailGroupAPI.createEmailGroup(emailGroup);
    } catch (error) {
      console.error(error);
      success = false;
    }
    if (success) {
      handleClose();
    }
  }

  // Updates emailBody state item with changes from text editor
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailBody(event);
  };

  const onMount = async () => {
    // on mount set edit data
    const { editData } = props;
    if (editData) {
      let templateData: any = await emailTemplateAPI.getEmailTemplatesById(editData.id);
      setEmailTemplate(templateData);
      let contacts: any = await contactAPI.getAllContacts();
      setContactList(contacts.result);

      //Boolean checkbox values
      setLiaison(templateData.is_Liaison);
      setGovernor(templateData.is_ActiveGov);
      setCabinet(templateData.is_Cabinet);
      setGeneralPublic(templateData.is_Public);
      setLegislator(templateData.is_Legislator);

      setEmailBody(templateData.body);
      setRuleFiling(templateData.ruleFiling);
      setSubject(templateData.subject);
      setName(templateData.name);
      setContactSelected([]);

      try {
        let editContacts: any = await emailGroupAPI.GetEmailGroupContactsByEmailTemplateIdAsync(editData.id);
        let contactIds: any = [];
        if (editContacts.length > 0) {
          editContacts.forEach((value: any) => {
            contactIds.push(value.id);
          })
          setContactIdsSelected(contactIds);
          setContactSelected(editContacts ?? []);
        } else {
          setContactSelected([]);
        }
      } catch (error) {
        setContactSelected([]);
        console.error(error);
      }

    }
  }

  useEffect(() => {
    if (props.open === true) {
      onMount();
    }
  }, [props.open, props.editData])

  // Checkbox control for formgroup to toggle booleans
  const checkboxControl = (value: any) => {
    switch (value) {
      case "public":
        generalPublic ? setGeneralPublic(false) : setGeneralPublic(true);
        break;
      case "liaison":
        liaison ? setLiaison(false) : setLiaison(true);
        break;
      case "gov":
        governor ? setGovernor(false) : setGovernor(true);
        break;
      case "oar":
        oar ? setOar(false) : setOar(true);
        break;
      case "cabinet":
        cabinet ? setCabinet(false) : setCabinet(true);
        break;
      case "legislator":
        legislator ? setLegislator(false) : setLegislator(true);
        break;
      default:
        break;
    }
  }

  // used to update the contacts selected in multiselect
  const handleContactChange = (event: any) => {
    const { target: { value } } = event;

    setContactIdsSelected(value);

    let tempContactList = contactList;
    let newContactList: any = [];
    tempContactList.forEach((el: any) => {
      if (value.indexOf(el.id) > -1) {
        newContactList.push(el);
      }
    });
    setContactSelected(newContactList);
  };

  // handles change event that takes place in "TextField" components
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name == "Subject") {
      setSubject(value);
    }
    if (name == "RuleFiling") {
      setRuleFiling(value);
    }
    if (name == "Name") {
      setName(value);
    }
  };

  return (
    <div>
      <Dialog
        className="dialog"
        open={open}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitleWithExit onClose={handleClose} id="form-dialog-title">
          Email Template
        </DialogTitleWithExit>
        <Divider />
        <DialogContent className="content">
          <Typography variant="h6" className="form-label">Select email recipients</Typography>
          <FormGroup className="form-group">
            <FormControlLabel value="liaison" control={<Checkbox checked={liaison} onChange={() => checkboxControl("liaison")} />} label="Liaison" />
            <FormControlLabel value="generalPublic" control={<Checkbox checked={generalPublic} onChange={() => checkboxControl("public")} />} label="General Public" />
            <FormControlLabel value="governor" control={<Checkbox checked={governor} onChange={() => checkboxControl("gov")} />} label="Governor" />
            <FormControlLabel value="oar" control={<Checkbox checked={oar} onChange={() => checkboxControl("oar")} />} label="OAR Office" />
            <FormControlLabel value="cabinet" control={<Checkbox checked={cabinet} onChange={() => checkboxControl("cabinet")} />} label="Cabinet" />
            <FormControlLabel value="legislator" control={<Checkbox checked={legislator} onChange={() => checkboxControl("legislator")} />} label="Legislator" />
          </FormGroup>
          <TextField disabled={true} className="email-text-field" name="Name" label="Name" value={name} variant="outlined" onChange={(e: any) => { handleTextFieldChange(e); }} fullWidth aria-readonly />
          <TextField disabled={true} className="email-text-field" name="RuleFiling" label="Rule Filing" value={ruleFiling} variant="outlined" onChange={(e: any) => { handleTextFieldChange(e); }} fullWidth />
          <TextField disabled={true} className="email-text-field" name="Subject" label="Subject" value={subject} variant="outlined" onChange={(e: any) => { handleTextFieldChange(e); }} fullWidth />
          {/* This should be a dropdown select with contact info */}
          <FormControl variant="outlined" fullWidth className="multi-select">
            <InputLabel className="select-label">To</InputLabel>
            {contactList ? (<Select
              className="select-box"
              label="To"
              id="contact-multi-select"
              multiple
              value={contactIdsSelected}
              fullWidth
              onChange={(ev: any) => handleContactChange(ev)}
              renderValue={(selected: any) => selected ? contactSelected.map(function (elem: any) {
                return (elem.firstName + " " + elem.lastName);
              }).join(", ") : null}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {contactList.map((contact: any) => (
                <MenuItem key={contact} value={contact.id}>
                  <Checkbox checked={contactIdsSelected.indexOf(contact.id) > -1} inputProps={{ "aria-label": "checkbox" }} />
                  <ListItemText primary={contact.firstName + " " + contact.lastName + " Email: " + (contact.email ? contact.email : "No Email Found")} />
                </MenuItem>
              ))}
            </Select>) : null}
          </FormControl>
          {/* <Divider/> */}
          <Typography variant="h6" className="form-label">Email Body</Typography>
          <RichTextEditor
            disable
            text={props.editData ? props.editData.body : ""}
            editorType={EditorType.default}
            saveText={saveForm}
            onTextChange={handleChange}
            height={"60%"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={(event) => { saveForm() }} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};