import { Result } from "./Result/Result";
import { AllRegisters } from "./Result/AllRegisters";

import "./Results.scss";

export const Results = (props: any) => {

  const results = props.documents.map((result: { document: any; }, index: any) => {
    if(props.query != "") {
      return <Result
          key={index}
          document={result.document}
        />;
    }
  });

  // console.log("Results: ", results);

  let beginDocNumber = Math.min(props.skip + 1, props.count);
  let endDocNumber = Math.min(props.skip + props.top, props.count);

  if(props.query == "") {
    beginDocNumber = Math.min(props.skip * 2 + 1, props.count);
    endDocNumber = Math.min(props.skip * 2 + props.top * 2, props.count);
  }

  return (
    <div>
      <div >
        <div className="reg-results-info">
          <p >Showing {beginDocNumber}-{endDocNumber} of {props.count.toLocaleString()} results</p>
        </div>
        <div className="results-register"> {results}</div>
        <div className="results-register">
        {props.query == "" ? (<AllRegisters {...props} />) : null}
        </div>
      </div>
    </div>
  );
};