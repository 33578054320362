import Alert from "@material-ui/lab/Alert";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import {
  ApplicationRoles,
  ApplicationUser,
  ClaimTypes,
  CommonClaims,
} from "../../utils/model/ApplicationUser";
import "./Authorized";

interface IAuthorizedProps {
  children: JSX.Element;
  roles?: ApplicationRoles[];
  claims?: Record<ClaimTypes, CommonClaims[]>;
  returnError?: boolean;
  showComponent?: boolean;
  redirectToLogin?: boolean;
}

export const Authorized = (props: IAuthorizedProps) => {
  const {
    showComponent,
    redirectToLogin,
    roles,
    children,
    returnError,
  } = props;
  const { isAuthenticated, getUser, signin } = useContext(
    AuthenticationContext
  );
  const initialAuthenticated = isAuthenticated();
  const [authorized, setAuthorized] = useState(initialAuthenticated);
  const onComponentClick = () => {
    if (redirectToLogin === true) {
      signin();
    }
  };

  const userHasRoles = useCallback((user: ApplicationUser): boolean => {
    if (roles != null) {
      let found = false;
      if (typeof user.profile?.role === "string") {
        const role = user.profile?.role as unknown;
        found = roles.some((r) => role === r);
      } else {
        found = user.profile?.role?.some((r) => roles?.includes(r));
      }
      return found;
    }
    return true;
  }, [roles]);

  const userHasClaims = useCallback((user: ApplicationUser): boolean => {
    // TODO Has Claim
    return true;
  }, []);

  const checkAuthentication = useMemo(
    () => async () => {
      const user = await getUser();
      if (user == null) {
        setAuthorized(false);
      } else if (userHasRoles(user) && userHasClaims(user)) {
        setAuthorized(true);
      } else {
        setAuthorized(false);
      }
    },
    [getUser, setAuthorized, userHasRoles, userHasClaims]
  );

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (authorized) {
      return <span className="authorized">{children}</span>;
  } else if (showComponent) {
    return (
      <span className="authorized" onClick={onComponentClick}>
        {children}
      </span>
    );
 } else {
    if (returnError === true) {
      return <Alert severity="error">Not Authorized</Alert>;
    }
    return null;
  }
};
