import {Field} from "@progress/kendo-react-form";
import {FormInput} from "../../Helpers/form-components";
import {emailValidator} from "../../Helpers/Validators";
import "./RegisterEmailForms.scss";

import {Card, CardBody,} from "@progress/kendo-react-layout";


export const RegisterEmailForm = (
    <div style={{marginTop: "2em", width: "100%"}}>
        <Card>
            <CardBody>
                <Field
                    key={"email"}
                    id={"email"}
                    name={"email"}
                    label={<h4 style={{color: "cornflowerblue"}}>Please Enter your Email Address to Continue</h4>}
                    hint={"Hint: Enter your personal email address."}
                    type={"email"}
                    component={FormInput}
                    validator={emailValidator}
                />
                <Field
                    key={"confirmEmail"}
                    id={"confirmEmail"}
                    name={"confirmEmail"}
                    label={<h4 style={{color: "cornflowerblue"}}>Confirm Email Address</h4>}
                    hint={"Email addresses must match"}
                    type={"email"}
                    component={FormInput}
                    validator={emailValidator}
                />
            </CardBody>
        </Card>
    </div>
);
