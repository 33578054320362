import { RulesAffectedIndexItem } from "./RulesAffectedIndexItem";

export class FinalizedRegister {
    constructor(
        registerId: number,
        volumeNumber: number,
        issueNumber: number,
        rulesAffectedIndex: RulesAffectedIndexItem[]
    ){
        this.registerId = registerId;
        this.volumeNumber = volumeNumber;
        this.issueNumber = issueNumber;
        this.rulesAffectedIndex = rulesAffectedIndex;

        // HTML issue numbers are always two digit numbers.
        // Example “Volume-39_Issue-08”
        this.htmlFileName = `Volume-${volumeNumber}_Issue-${(issueNumber >= 10 ? issueNumber : `0${issueNumber}`)}.htm`;

        // PDF issue numbers are single digit numbers when between 1-9.
        // Example “Volume-39_Issue-8”
        this.pdfFileName = `Volume-${volumeNumber}_Issue-${issueNumber}.pdf`;
    }

    public registerId: number;
    public volumeNumber: number;
    public issueNumber: number;
    public rulesAffectedIndex: RulesAffectedIndexItem[];
    public htmlFileName: string = "";
    public pdfFileName: string = "";
}