import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { Filing, Segment } from "../../../../../../utils/model";
import "./SegmentTree.scss";
import { UrlParser } from "../../../../../../utils";
import { RouteComponentProps, withRouter } from "react-router";
import { filingAPI, segmentAPI } from "../../../../../../utils/api";


interface EntityObject {
  [key: string]: any;
}

interface ISegmentDisplayProps extends RouteComponentProps {
  filing: Filing;
  segment: any;
  parent: Segment;
  updateParent: (segment: Segment[]) => void;
  toggleOpen: (segment: Segment) => void;
  editForm: (value: Segment[]) => void;
  deleteSegment: (value: Segment[]) => void;
  // rejectChecked: (value: Segment[]) => void;
}

const SegmentDisplayBase = (props: ISegmentDisplayProps) => {
  const [hasData, setHasData] = React.useState(false);
  const [segment, setSegment] = React.useState<Segment>(props.segment);
  const [childSegments, setChildSegments] = React.useState<any>(null);
  const [checked, setChecked] = useState(false);

  const getChildren = async () => {
    let segmentArray = await segmentAPI.getSegmentsByParentId(
      props.segment!.id,
      true
    );
    setChildSegments(segmentArray);
    setHasData(true);
  };

  useEffect(() => {
    if (props.segment) {
      getChildren();
    }
  }, [props.segment, props.filing]);

  const [expand, setExpand] = React.useState(true);

  const handleClick = () => {
    setExpand(!expand);
  };

  /**
   * @description converts form data to segment and adds segment to filing entity object
   */
  const updateSegment = async () => {
    const { filing } = props;

    // Get the parent segment so that segmentUpdate contains the necessary info otherwise parent isn't populated
    let tempSegment = Object.create(segment);
    let parentSegment: Segment = await segmentAPI.get(tempSegment.parentId);

    if (segment.id!) {
      let segmentUpdate = new Segment(
        segment.description,
        segment.titleNum,

        segment.recordStatus,
        segment.segmentTypeId,
        segment.chapterNum,
        segment.subChapterNum,
        segment.sectionNum,
        // segment.segmentNotes,
        segment.appendixNum,
        segment.partNum,
        segment.execOrderNum,
        segment.execOrderYear,
        segment.authority,

        segment.text,
        segment.notes,
        segment.additionalInformation,

        // segment.originalId,
        segment.ruleId,
        segment.parentId,
        segment.contactId,
        segment.segmentStatusId,
        segment.filingId,
        segment.inProcessSegmentId,
        segment.effectiveDate,
        null,
        false
      );
      segmentUpdate.id = segment.id;

      let filingUpdate = filing;
      let found = -1;
      if (filingUpdate.entity) {
        let entity: EntityObject = filing?.entity!;

        if (entity) {
          entity?.segments!.forEach((segment: Segment, index: number) => {
            if (segment.id === segmentUpdate.id) {
              found = index;
            }
          });
        }

        if (found !== -1) {
          entity?.segments.splice(found, 1);
          entity?.segments.push(segmentUpdate);
        } else {
          entity?.segments.push(segmentUpdate);
        }

        filingUpdate.entity = filing.entity;

        filingAPI.patch(filing?.id!, filingUpdate);
        // segmentAPI.patch(segment.id!, segmentUpdate);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // console.log("Segment Tree: ",event.target);
    setSegment({ ...segment, [name]: value });
  };

  const handleSegmentEditor = () => {
    // console.log(props.location);
    const query = UrlParser.Filing.getParameters(props.location.search);
    // console.log(query, "Hit the nav request");
    const filingType = query.filingtype as string;
    const filingId = query.filingid as number;
    const segmentId = segment.id as number;
    const newQuery = UrlParser.Filing.parseFilingSegmentText(
      filingType,
      filingId,
      segmentId
    );
    props.history.push(newQuery);
  };

  function render() {
    return (
      <div
        className={"expansion-" + props.segment?.name.toLowerCase()}
        id={props.segment.titleNum}
      >
        <List component="div">
          <ListItem className="list-item">
            <div className="segment-type">{props.segment?.name}</div>

            <FormControlLabel control={
              <Checkbox
                checked={checked}
              />
            } label="Reject" />

            <div className="actions">
              <div className="title">
                <TextField
                  className="input-box"
                  label="yellow"
                  name="title"
                  value={segment.description}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  className="input-box"
                  label="Reference Code"
                  name="referenceCode"
                  value={segment.titleNum}
                  onChange={handleChange}
                ></TextField>
              </div>
              <div>
                <IconButton
                  className="button"
                  onClick={() => {
                    props.updateParent([props.segment]);
                    props.toggleOpen(props.segment);
                  }}
                >
                  <AddIcon color="primary" />
                </IconButton>
              </div>
            </div>
            <div className="controls">
              <div className="editor-control">
                <Button
                  className="save-button"
                  variant="contained"
                  onClick={updateSegment}
                >
                  Save Changes
                </Button>

                <Button
                  className="editor-button"
                  variant="contained"
                  onClick={handleSegmentEditor}
                >
                  Open Editor
                </Button>
                {/* ) : null} */}
              </div>
              <div className="expand-button" onClick={handleClick}>
                {expand ? (
                  <IconButton>
                    <ExpandLess />
                  </IconButton>
                ) : (
                  <IconButton>
                    <ExpandMore />
                  </IconButton>
                )}
              </div>
            </div>
          </ListItem>
        </List>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          {hasData ? renderChildren() : null}
        </Collapse>
      </div>
    );
  }

  /**
   * @description Recursively renders heirarchy of segments also looks into segments stored on filing
   */
  const renderChildren = () => {
    const { editForm, deleteSegment, filing } = props;
    if (segment && childSegments) {
      return childSegments.map((item: any, key: number) => {
        return (<SegmentDisplay
          filing={filing}
          segment={item}
          parent={props.segment}
          updateParent={props.updateParent}
          toggleOpen={props.toggleOpen}
          editForm={editForm}
          deleteSegment={deleteSegment}
        />)
      })
    } else {
      return <div>blah</div>;
    }
  };

  return <div className="segment-tree">{hasData ? render() : null}</div>;
};

export const SegmentDisplay = withRouter(SegmentDisplayBase);
