import { Typography } from "@material-ui/core";
// import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { filingAPI } from "../../../../../utils/api";
import { Filing, FilingType } from "../../../../../utils/model/";
import { FilingAddForm } from "../FilingAddForm";
import "./FilingTable.scss";
import { setFilingTypeRows } from "./TableSetup";

interface IFilingTableProps extends RouteComponentProps {
  filingType: FilingType;
  reload?: boolean;
  setReload?: (reloaded: boolean) => void;
  filingTypes?: FilingType[];
  // modalType:string;
  updateRows: () => void;
  userTitleAccess: [];
  userRoleAccess: any;
}

// #######################################################
// Define Generic table
// #######################################################
const FilingTableWithRouter = (props: IFilingTableProps) => {
  const [filtering, setFiltering] = React.useState(false);
  const [rows, setRowData]: any = React.useState([]);
  const [columns, setColumns]: any = React.useState([]);
  const [numRows, setNumRows]: any = React.useState(50);

  // #######################################################
  // Toggle filter options on table
  // #######################################################
  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setFiltering(true);
    }
  };

  // useEffect(() => {
  // },[]);

  // #######################################################
  // Modal Controls
  // #######################################################
  const initalOpen: boolean = false;
  const [open, setOpen] = React.useState(initalOpen);
  const [openDelete, setOpenDelete] = React.useState(false);
  // const [editRow, setEditRow] = React.useState({});
  // const [deleteRow, setDeleteRow] = React.useState({});

  // #######################################################
  // Used to reload data after saving deleting or updating
  // #######################################################
  const updateRows = async () => {
    let filings: any = await filingAPI.getFilingsByType(props.filingType.id!);
    let vals = setFilingTypeRows(
      filings,
      props.filingType.name,
      updateRows
    );
    setRowData(vals.rows);
    setColumns(vals.columns);
    setNumRows(vals.rows.length);
  };

  const onMount = React.useCallback(async () => {
    let filings: any = await filingAPI.getFilingsByType(props.filingType.id!);
    let vals = setFilingTypeRows(
      filings,
      props.filingType.name,
      updateRows
    );
    setRowData(vals.rows);
    setColumns(vals.columns);
    setNumRows(vals.rows.length);
  }, [props.filingType.id, props.filingType.name]);

  useEffect(() => {
    try {
      onMount();
    } catch (error) {
      console.error(error);
    }
  }, [onMount]);

  useEffect(() => {
    try {
      if (props.reload === true && props.setReload != null) {
        onMount();
        props.setReload(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [props.reload, onMount, props.setReload, props]);

  // #######################################################
  // Default tab to Rules and allow for tab switching
  // #######################################################
  // let modal = {val: <div></div>}

  if (openDelete === true) {
    // modal = { val: <DeleteRowModal type={props.modalType} open={open} deleteRow={deleteRow} handleClickOpen={handleClickOpen} handleClose={handleClose}></DeleteRowModal>}
  }

  const closeAddDialog = () => {
    onMount();
    setOpen(false);
  };

  return (
    <div className="filing-table">
      {/* <MaterialTable
        columns={columns}
        data={rows}
        title={
          <Typography
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: 20,
              fontWeight: 500,
            }}
            variant="h1"
          >
            {props.filingType.name}
          </Typography>
        }
        options={{
          exportButton: true,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100, 200],
          filtering: filtering,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "No Records Found",
          },
        }}
        actions={
          // TODO ROB have onclick call a generic action handler, have that handler call the url parser and reset the react router location(action handler must be a component)
          [
            {
              icon: "filter_list",
              tooltip: "Toggle Filter",
              isFreeAction: true,
              onClick: (event, rowData) => {
                toggleFiltering();
              },
            },
            {
              icon: "add",
              tooltip: "Add new record",
              isFreeAction: true,
              onClick: (event) => {
                open ? setOpen(false) : setOpen(true);
                setOpenDelete(false);
              },
            },
          ]
        }
      />
      {open && (
        <FilingAddForm
          userRoleAccess={props.userRoleAccess}
          userTitleAccess={props.userTitleAccess}
          open={open}
          handleClose={closeAddDialog}
          filingType={props.filingType}
          updateRows={props.updateRows}
        />
      )} */}
    </div>
  );
};

export const FilingTable = withRouter(FilingTableWithRouter);
