import { CircularProgress } from '@material-ui/core';
import { Dialog } from '../../../../../../components/Dialog'
import { Paragraph } from '../../../../../../components/Paragraph';
import { useTitleHistoryController } from './TitleHistoryController'
import "./TitleHistoryModal.scss";
import { Button } from '@progress/kendo-react-buttons';
import { NoteType, StorageContainer } from '../../../../../../utils/enums';
import { fileAPI } from '../../../../../../utils/api';

interface IHistoryProps {
  open: boolean;
  referenceCode: any | null;
  onClose: () => void;
}

/**
 *
 * @param open boolean - determins if dialog is visible
 * @param segment Segment - used to fetch all segment history
 * @param onClose ()=>void - function to toggle open true/false state for closing the dialog
 * @returns
 */
export const TitleHistoryModal = (props: IHistoryProps) => {
  const { open, referenceCode, onClose } = props;
  const {
    // variables
    historyItems,
    loading,
    title,
  } = useTitleHistoryController(referenceCode, open);

  // let container: any = useRef<HTMLDivElement>(null);
  const downloadHistory = async (event: any) => {
    let url = await fileAPI.getFileUrl(event.target.value + ".pdf", StorageContainer.HistoricTitlePdf);
    // console.log(url);
    window.open(url);
  };

  /**
   *
   * @param segment accepts in a segment to get all segment notes
   * @returns React.tsx to render
   */
  const renderSegmentNotes = (segment: any) => {
    if (!segment.segmentNotes && segment.segmentNotes.length > 0) {
      return '';
    }


    let notes = segment.segmentNotes;
    let textBody: any;
    let editorsNotes: any = [];
    let sourceNotes: any = [];
    let authorityNotes: any = [];
    let footNotes: any = [];
    let agencyNotes: any = [];

    // // Order in which note types should appear
    // //  1. Footnote
    // //  2. Source
    // //  3. Editors
    // //  4. Agency
    // //  5. Authority

    notes.forEach((note: any, index: number) => {
      //   var found = null;
      //   found = noteType?.find((a:any) => a == note.noteTypeId);
      //   if(found) {
      if (note.noteTypeId == NoteType.Source) {
        sourceNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Editors) {
        editorsNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Authority) {
        authorityNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        )
      } else if (note.noteTypeId == NoteType.Agency) {
        agencyNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      }
      //   }
    })

    textBody = (
      <div>
        {footNotes.length > 0 ? (
          <div className="note">
            {footNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {sourceNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Source: </b>{sourceNotes.map((item: any, index: number) => { if (item != "") return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>;&nbsp;</span>) : null}</span>) })}]</span>
          </div>
        ) : null}
        {editorsNotes.length > 0 ? (
          <div className="note">
            {editorsNotes.map((item: any, index: number) => { if (item != "") return (<div className="inline-note-text"><i><strong>EDITOR'S NOTE: </strong>{item}</i>{index + 1 < editorsNotes.length ? (<span>,&nbsp;</span>) : null}</div>) })}
          </div>
        ) : null}
        {agencyNotes.length > 0 ? (
          <div className="note">
            {agencyNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < agencyNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {authorityNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]</span>
          </div>
        ) : null}
      </div>
    )

    return textBody;
  };

  return (
    <Dialog title="Title History" open={open} onClose={onClose} className="history-modal">
      <>
        {loading ? (<div className="history-loading"><CircularProgress /> Loading</div>) : null}
        {historyItems && historyItems.length > 0 ? (
          <div className="history-dialog-content">
            {historyItems.map((history: any, index: number) => {
              return (
                <div key={history.id}>
                  <div className="banner">
                    <div>
                      {history ? (
                        <Button
                          key={history.id + index}
                          icon="pdf"
                          style={{ color: "#034d9a" }}
                          disabled={false}
                          value={history.fileName}
                          onClick={downloadHistory}
                        >
                          Download PDF
                        </Button>
                      ) : null}
                      {/* <div><b>Description:</b> {history.originalDescription}</div> */}
                      <div>
                        <b> Title {title ? title.referenceCode : ""}. {title ? title?.title! : "No type found"}</b>
                      </div>
                      <div>
                        <b>Date:</b> {new Date(history.timeStamp).toLocaleDateString()}
                      </div>
                      <div>
                        <b>Status:</b> {history.titleStatusName}
                      </div>
                    </div>
                  </div>
                </div>)
            })}
          </div>
        ) : (
          <div>No historical records found</div>
        )}
      </>
    </Dialog >
  )
}
