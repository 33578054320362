import React, { useCallback, useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  InputLabel,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SearchQueryFields } from "../../../../../utils/enums/SearchQueryFields";
import { useSearch } from "../../../../../utils/hooks";
import { CheckboxGroup } from "../CheckboxGroup";
import { ruleAPI, searchFuncAPI, segmentAPI } from "../../../../../utils/api";
import { ISearchFilter } from "../../../../../utils/interfaces/ISearchFilter";
import { capitalizeText } from "../../../../../utils/utility_functions";
import "./AdvancedSearch.scss";
import { find, findIndex, pick } from "lodash";
import {
  DropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";

import { DatePicker, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Rule } from "../../../../../utils/model";



///////////////////////////////////////////////////////////////////////////////////////////////////
// Custom styles
///////////////////////////////////////////////////////////////////////////////////////////////////
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // paddingLeft: "1rem",
      // paddingRight: "1rem",
      // paddingBottom: "1rem"
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      // fontWeight: "theme.typography.fontWeightBold",
    },
    advancedSearch: {
      backgroundColor: "#1CA6DF",
      color: "white",
    },
    searchFocused: {
      '& > div[tabindex="0"]:focus': {
        backgroundColor: "#004e9a",
        boxShadow: "0 0 1pt 0.8pt blue",
      },
    },
    inputLabel: {
      position: "absolute",
      left: "24px",
      background: "white",
      padding: "0 6px",
      zIndex: 1,
      fontWeight: "normal",
      fontSize: "1.5em",
    },
    relative: {
      position: "relative",
    },
  })
);


///////////////////////////////////////////////////////////////////////////////////////////////////
// Advanced Search Component
///////////////////////////////////////////////////////////////////////////////////////////////////
export const AdvancedSearch = (props: any) => {

  const [titleList, setTitleList]: any = React.useState();
  const classes = useStyles();
  const { setSearchFilter, searchFilters } = useSearch();


  const onMount = React.useCallback(async () => {
    try {
      const ruleCollection:any = await ruleAPI.getRulesList();

      let values:any = Object.values(ruleCollection);

      values.forEach((item:any,index:number)=>{
        values[index].title = `Title ${item.referenceCode} - ${item.title}`;
    })

      setTitleList(values);
    } catch (err) {
      console.error(err);
    }
  }, []);


  useEffect(() => {
    onMount();
  }, []);


  const [state, setState] = React.useState({
    value: { text: "", id: 0},
  });

  const handleChange = (event: DropDownListChangeEvent) => {
    setState({
      value: event.target.value,
    });
  };
  const defaultValue = { start: new Date(), end: null };
  const [rowHeight, setRowHeight] = React.useState(90);
  const [colWidth, setColWidth] = React.useState(200);


  return (
    <div className={classes.root}>
      <Accordion classes={{ root: classes.searchFocused }}>
        <AccordionSummary
          classes={{ root: classes.advancedSearch }}
          style={{ borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Advanced Search</Typography>
        </AccordionSummary>
        <AccordionDetails className="advanced-search" style={{ backgroundColor: "white" }}>
          <div className="search-forms"></div>

          <div className="grid-layout-container">
            <GridLayout
              align={{ horizontal: "center" }}
              gap={{ rows: 5, cols: 30 }}
              rows={[
                { height: 10 },
                { height: rowHeight },
                { height: rowHeight },
              ]}
              cols={[
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
                { width: colWidth },
              ]}
            >
              <GridLayoutItem row={1} col={1} colSpan={5} className="checkbox-display">

                  {/* <CheckboxGroup<SearchQueryFields>
                    groupLabel="Rule Making Action"
                    onFieldChange={(fieldName: any, value) =>
                      setSearchFilter(fieldName, value)
                    }
                    fields={ruleTypes.map((ruleType) => ({
                      fieldLabel: ruleType.label,
                      fieldName: ruleType.field,
                      // @ts-ignore
                      isChecked: searchFilters[ruleType.field] as boolean,
                    }))}
                  /> */}

              </GridLayoutItem>
              <GridLayoutItem row={2} col={1} colSpan={2}>
                <div>
                  <DropDownList
                    data={titleList}

                    textField="title"
                    dataItemKey="id"
                    onChange={handleChange}
                    style={{ width: "25rem" }}
                    label="Sort results by TITLE..."
                  />
                </div>
              </GridLayoutItem>
              <GridLayoutItem row={2} col={3}>
                <Input
                  name="chapter"
                  // style={{ width: "20rem" }}
                  label="CHAPTER #"
                  pattern={"[A-Za-z]+"}
                  minLength={2}
                  required={false}
                />
              </GridLayoutItem>
              <GridLayoutItem row={2} col={4}>
                <Input
                  name="section"
                  // style={{ width: "20rem" }}
                  label="SECTION #"
                  pattern={"[A-Za-z]+"}
                  minLength={2}
                  required={false}
                />
              </GridLayoutItem>
              <GridLayoutItem row={2} col={5}>
                <Input
                  name="appendix"
                  // style={{ width: "20rem" }}
                  label="APPENDIX #"
                  pattern={"[A-Za-z]+"}
                  minLength={2}
                  required={false}
                />
              </GridLayoutItem>
              <GridLayoutItem row={3} col={1} colSpan={2}>
              <div className="k-text-inverse k-text-uppercase k-font-weight-bold">Search by Effective Date</div>
              <DateRangePicker format={{ month: "long", day: "numeric", year: "numeric" }}
                  defaultValue={defaultValue}/>

              </GridLayoutItem>
            </GridLayout>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
