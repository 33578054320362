import React from "react";
import "./ReportsWrapper.scss";
import { EmailTemplateTable } from "..";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import CodeFullEdition from "../CodeFullEdition/CodeFullEdition";
import { ReportsPage } from "../ReportsPage";

interface IReportsWrapperProps extends IStateTabComponentProps { }

export const ReportsWrapper = (props: IReportsWrapperProps) => {
  const [tab, setTab] = React.useState("reports");

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue);
  };


  return (
    <div className="reports-wrapper">
      <AppBar position="static" color="default">
        <Tabs className="reports-tab" value={tab} onChange={handleChange} indicatorColor="primary" >
          <Tab
            value={"reports"}
            className="tab-menu"
            label={`Reports`}
            {...a11yProps(0)}
          />
          <Tab
            value={"emailTemplates"}
            className="tab-menu"
            label={`Email Templates`}
            {...a11yProps(1)}
          />
          <Tab
            value="codeFullEdition"
            className="tab-menu"
            label={'Code Full Edition'}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      {tab === "reports" ? (<ReportsPage {...props} />) : null}
      {tab === "emailTemplates" ? (<EmailTemplateTable {...props} />) : null}
      {tab === "codeFullEdition" ? (<CodeFullEdition />) : null}
    </div>
  );
};
