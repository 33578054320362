// import React from "react";
import { ListItem, ListItemText } from '@material-ui/core';
import { ListChildComponentProps } from 'react-window';
import { Icon } from '..';


interface IIconListItem extends ListChildComponentProps {
    iconName: string;
    iconSelected: (index: number) => void;
}


export const IconListItem = (props: IIconListItem) => {
    const { index, style, iconSelected, iconName } = props;
    return (
        <ListItem button onClick={() => iconSelected(index)} style={style} key={index}>
            <ListItemText  id={iconName} primary={`${iconName}`} />
            <Icon icon={iconName} />
        </ListItem>
    );
}



