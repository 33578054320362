import "./ActionTab.scss";
import { Button } from "@material-ui/core";
import { filingAPI, actionAPI } from "../../../../utils/api";
import { ActionTypes, StatusTypes } from "../../../../utils/enums";
import { FilingActionRequest } from "../../../../utils/model";
import { TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

interface IRejectReasonProps {
    filing: any | null | undefined;
    actions: any[];
    actionTypeId: number;
    rerenderActions: (statusTypeId?: number | null) => void;
    rerenderSteps: () => void;
    setStatusType: (statusTypeId: number) => void;
    setRejectReason: (rejectReason: string) => void
};

export let RejectReason = React.forwardRef((props: IRejectReasonProps, ref) => {
    useEffect(() => { }, [props.filing]);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [helperText, setHelperText] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const errorMessage: string = props.actionTypeId == ActionTypes.Rework
        || props.actionTypeId == ActionTypes.SubmitForAgencyReview ?
        'OAR Issue(s) Identified Required' :
        'Reject Reason is Required';
    const label: string = props.actionTypeId == ActionTypes.Rework
        || props.actionTypeId == ActionTypes.SubmitForAgencyReview ?
        'OAR Issue(s) Identified' :
        'Reject Reason';

    const submit = async () => {
        if (rejectReason.trim().length == 0) {
            setIsError(true);
            setHelperText(errorMessage);
            return;
        }

        let filingId: number = props.filing.id;
        // let action: any = props.actions.find((x) => { return x.actionTypeId == props.actionTypeId; });
        let action = props.actions.find(x=>x.actionTypeId === props.actionTypeId);
        let targetStatusId: number = action.targetStatusId;
        let targetStatusTypeId: number = props.actionTypeId == ActionTypes.Rework ?
            StatusTypes.Reworked :
            StatusTypes.Rejected;

        await filingAPI.rejectFiling(filingId, targetStatusId, rejectReason);

        let actionRequest = new FilingActionRequest(action.codeAction, props.filing.id, action.id);
        await actionAPI.updateFilingWorkflowAction(actionRequest);

        props.setStatusType(targetStatusTypeId);
        props.rerenderActions(targetStatusTypeId);
        props.rerenderSteps();
        props.setRejectReason(rejectReason);
    };

    const cancel = async () => {
        props.rerenderActions(null);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value as string;

        if (value.trim().length == 0) {
            setIsError(true);
            setHelperText(errorMessage);
            return;
        }

        setIsError(false);
        setHelperText('');
        setRejectReason(value);
    };

    return (
        <Fragment>
            <TextField
                label={label}
                name="rejectReason"
                multiline
                fullWidth
                rows="7"
                variant="outlined"
                onChange={handleOnChange}
                error={isError}
                helperText={helperText}
            />
            <Button size="large" className="action-button reject-reason-button" onClick={submit}>
                <div>SUBMIT</div>
            </Button>
            <Button size="large" className="action-button reject-reason-button" onClick={cancel}>
                <div>CANCEL</div>
            </Button>
        </Fragment>
    );
});