export class Home {
  constructor(name: string, image_url: string, image_name: string) {
    this.name = name;
    this.image_url = image_url;
    this.image_name = image_name;
  }
  public name: string;
  public image_url: string;
  public image_name: string;
}
