import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class RoleAPI extends BaseAPI<string> {
  constructor() {
    super("role", APISettings.identityServerUrl);
  }
}

export const roleAPI = new RoleAPI();
