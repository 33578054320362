import { List, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { filingAPI, filingTypeAPI, taskAPI } from "../../../../utils/api";
import { Filing, FilingType, Task } from "../../../../utils/model";
import { UrlParser } from "../../../../utils/urlParser";
import { FilingView } from "../../FilingHome";
import { SubFilingDisplay } from "./components/SubFilingDisplay/SubFilingDisplay";
import { TaskDisplay } from "./components/TaskDisplay/TaskDisplay";
import "./SubFilingTab.scss";

interface ISubFilingTabProps extends RouteComponentProps {
  filing: any;
  updateView: (tab: FilingView) => void;
  subFilingType: string;
}

const SubFilingTabPreRouter = (props: ISubFilingTabProps) => {
  const { filing, updateView, subFilingType } = props;
  /************************ State ************************/

  const [subFilingTasks, setSubFilingTasks] = useState<Task[]>([]);
  const [subFilings, setSubFilings] = useState<Filing[]>([]);

  /****************** Callback Methods ******************/

  const setupSubFilingTasks = useCallback(async () => {
    // Get All the subFiling Tasks on this filing
    const tasks = await taskAPI.getAll({
      additionalInformation: `${subFilingType}`,
      filingId: filing.id,
    });
    setSubFilingTasks(tasks);
  }, [filing, filing.id]);

  /************** UseEffect Implementations **************/

  // Setup All Tasks for that can be used to create a sub filing
  useEffect(() => {
    setupSubFilingTasks();
  }, [setupSubFilingTasks, filing, filing.id]);

  const setupSubFilings = useCallback(async () => {
    const noticeFilingType: FilingType[] = await filingTypeAPI.getAll({
      name: "Notice",
      includeSubFilingTypes: true,
    });
    if (noticeFilingType.length > 0) {
      const initialFilings = await filingAPI.getAll({
        root: filing.id,
        filingTypeId: noticeFilingType[0].id,
      });
      // Get the subFiling task type
      setSubFilings(initialFilings);
    }
  }, [filing, filing.id]);

  useEffect(() => {
    setupSubFilings();
  }, [setupSubFilings, filing, filing.id]);

  const onClickSubFiling = (subFiling: Filing) => {
    updateView("Filing");
    // const filingUrl = UrlParser.Filing.parseFiling(
    //   subFiling.filingType.name,
    //   subFiling.id!
    // );
    // props.history.push(filingUrl);
  };

  const updateFilings = () => {
    setupSubFilings();
  };

  /******************* OnClick Methods *******************/

  const onClickNotificationTask = (task: Task) => {
    // const newFilingUrl = UrlParser.Filing.parseFilingTypeCreateWithForm(
    //   subFilingType,
    //   true,
    //   filing.id!,
    //   task.data?.form ?? task.notes
    // );
    // props.history.push(newFilingUrl);
  };
  return (
    <div className="sub-filing-tab">
      {subFilingTasks.length < 1 ? (
        <Typography>No notice filings available at this time.</Typography>
      ) : null}
      <div className="sub-filing-task-container">
        {subFilingTasks.map((task) => (
          <TaskDisplay onClick={onClickNotificationTask} task={task} />
        ))}
      </div>
      <div className="sub-filing-container">
        <List component="nav" aria-label="main mailbox folders">
          {subFilings.map((subFiling) => (
            <SubFilingDisplay
              updateFilings={updateFilings}
              onClick={onClickSubFiling}
              filing={subFiling}
              color={"primary"}
            />
          ))}
        </List>
      </div>
    </div>
  );
};

export const SubFilingTab = withRouter(SubFilingTabPreRouter);
