import { Card, CardActions, CardContent, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import { Icon } from "../../../../../components";
import { Status as StatusModel } from "./../../../../../utils/model";
import './Status.scss';

interface IStatusProps {
    status: StatusModel;
}


export const Status = (props: IStatusProps) => {
    const { status } = props;
    // const actions = props.status?.actions;
    return (
        <div className="status">
            <Card >
                <Tooltip title={status.description} aria-label={status.description} placement="top-start">
                    <CardContent>
                        <Icon icon={status.statusType.iconName} style={{ fontSize: 100 }} />
                        <Typography gutterBottom variant="h5" component="h2">
                            {status.name}
                        </Typography>
                    </CardContent>
                </Tooltip>
                <CardActions>
                    <div className="actions">
                        {/* {actions.map((action, key) => <Action key={key} action={action} />)} */}
                    </div>
                </CardActions>
            </Card>
        </div>
    );
}



