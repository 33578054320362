import { statusAPI } from "../../../utils/api";
import { Filing } from "../../../utils/model";

interface Column {
  field: string; // create a column bound to the "name" field
  title: string; // set its title to "Name
  hidden: boolean; // used to define if a column should be rendered or not
}

export const setFeeIncreaseData = async (rows: any) => {
  const entity: any = rows[0].entity;
  const newRows: any = [];
  let columns: any = [];
  const keys = Object.keys(entity);

  const transformText = (key: any) => {
    var text = key.replace(/([A-Z])/g, " $1");
    var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
    return capitalizeText;
  };

  let column: Column;
  let ruleColumn: Column = {
    field: "rule",
    title: "Title Number",
    hidden: false,
  };
  let chapterColumn: Column = {
    field: "chapter",
    title: "Chapter Number",
    hidden: false,
  };
  let agencyColumn: Column = {
    field: "agency",
    title: "Agency",
    hidden: false,
  };
  let filingTypeColumn: Column = {
    field: "filingType",
    title: "Filing Type",
    hidden: false,
  };
  let filingDateColumn: Column = {
    field: "filingDate",
    title: "Filing Date",
    hidden: false,
  };
  columns.push(
    ruleColumn,
    chapterColumn,
    agencyColumn,
    filingTypeColumn,
    filingDateColumn
  );

  // workflow id 13 == fee increase
  let statusList: any = await statusAPI.getStatusesByWorkflowId(13);
  let publishedStatusId: any;
  statusList.result.forEach((status: any, index: number) => {
    if (status.name == "Published") {
      publishedStatusId = status.id;
      return;
    }
  });

  rows.forEach((item: any, index: number) => {
    // filters out anything besides published filings
    if (item.statusId == publishedStatusId) {
      let ruleId = null;
      let linkItems = null;
      let chapterNumber = null;
      let agencyName = null;
      let filingTypeName = null;
      let filingDate = null;

      if (item.entity.rule) {
        ruleId = item.entity.rule.referenceCode;
        agencyName = item.entity.rule.title;
      }

      if (item.entity.feeIncreaseLetter) {
        linkItems = item.entity.feeIncreaseLetter;
      }

      if (item.entity.chapter) {
        // console.log(item.entity.chapter)
        chapterNumber = item.entity.chapter.chapterNum;
      }

      if (item.created) {
        let date = new Date(item.created);
        filingDate = date.toLocaleDateString();
      }

      filingTypeName = "Fee Increase";

      let rowData = {
        rule: ruleId,
        feeIncreaseLink: linkItems,
        chapter: chapterNumber,
        agency: agencyName,
        filingType: filingTypeName + " Notification",
        filingDate: filingDate,
        docketNumber: item.entity.docketNumber,
      };

      newRows.push(rowData);
    }
  });

  let docketColumn: any = {};
  docketColumn.field = "docketNumber";
  docketColumn.title = "Docket Number";
  columns.push(docketColumn);

  newRows.forEach((item: any, index: number) => {
    console.log(item);
    newRows[index] = {
      ...item,
      docketNumber: item.docketNumber,
    };
  });

  // columns.reverse();
  // columns.sort((x: any, y: any) => {
  //     return x.field === "title" ? -1 : y.field === "title" ? 1 : 0;
  // });

  return { rows: newRows, columns: columns };
};

// Fee increase table columns
// Title Number
// Chapter Number
// Agency
// Filing Type
// Filing Date
// Link to Filing (This is the PDF of the fee increase letter that is uploaded by the user through the filing process).
