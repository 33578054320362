import {
  FormLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Draft,
  Filing,
  FilingType,
  Rule,
} from "../../../../../../utils/model";
import { NoticeNRIRulemakingAction } from "../../../../../../utils/model/Filing";
import { Row, Select, TextField } from "./";
import { RULE_PLURAL_NAME } from "./../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";
import { FilingLink } from "../..";
import { FileUpload } from "../../../../../../components/FileUpload";
import { StorageContainer, DraftStatuses, RulemakingActions } from "../../../../../../utils/enums";
import { fileAPI } from "../../../../../../utils/api";

interface INoticeNRIFormProps {
  filingType: FilingType;
  filing?: Filing;
  renderRuleDropdown: () => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  // attestationList: Contact[];
  // liaisonList: Contact[];
  ruleList: Rule[];
  draftList: Draft[];
  saveForm: (item: any, type: string) => void;
  getProposedRules: (draftId: number | null | undefined) => string[];
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
  setupAttestation: (ruleId: number) => void;
  setupLiaison: (ruleId: number) => void;
  returnContactListAsync: (ruleId: number, isAttestation: boolean) => any;
}

const getInitialState = (props: INoticeNRIFormProps) => ({
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  chapter: props.ruleList[0],
  rule: props.ruleList[0],
  chapterId: -1,
  ruleId: -1,
  authority: "",
  rules: "",
  submitGovCabDate: new Date(),
  commentBeginDate: new Date(),
  commentEndDate: new Date(),
  publicHearing: "",
  publicHearingDate: new Date(),
  adoptionDate: new Date(),
  adoptionGovDate: new Date(),
  legislativeApproval: "",
  legislativeDisapproval: "",
  govApproval: "",
  finalAdoptionDate: new Date(),
  effectiveDate: new Date(),
  supersededRules: "N/A",
  supersededEmergencyActions: "N/A",
  gubernatorialApproval: "N/A",
  registerPublication: "N/A",
  docketSelect: Docket,
  docketSelectId: -1,
  docketSelectNumber: "N/A",
  incorporatedRef: "N/A",
  incorporatedStandards: "N/A",
  incorporatedRules: "N/A",
  availability: "N/A",
  gistAnalysis: "",
  contactPerson: "",
  liaison: Contact,
  liaisonId: 0,
  attestation: Contact,
  attestationId: 0,
  segments: [],
  transmittalSheet: [{ guid: "", filename: "" }],
  draftId: null,
  proposedRules: "",
  rulemakingAction: RulemakingActions.NriPermanent
});

export let NoticeNRIForm = React.forwardRef(
  (props: INoticeNRIFormProps, ref) => {
    const [formValues, handleChange] = useFormState(
      { ...getInitialState(props), ...props.filing?.entity },
      ref
    );
    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const [proposedRulesText, setProposedRulesText] = React.useState<any>();
    const nameErrorMessage: string = "Filing Name Required";
    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    const setLiaisonListFromRuleId = React.useCallback(async (ruleId: number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    }, [props]
    );

    useEffect(() => {
      // console.log("NoticeNRIForm useEffect")

      if (!formValues.rule && !props.filing) {
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);
        props.setupLiaison(formValues.ruleId);
        props.setupAttestation(formValues.ruleId);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        // console.log('NRIrule - ', rule)
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }
      } else if (props.filing) {
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
      }
    }, [formValues.rule, formValues.ruleId, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);


    const handleDateChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      date: Date | null
    ) => {
      handleChange(event, name, date);
      if (name === "effectiveDate") {
        handleChange(event, "enactingClause", "EFFECTIVE DATE " + date);
      }
    };

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i: any) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const handleDraftSelectChange = async (event: React.ChangeEvent<HTMLInputElement>, name: string, rawValue: string | null) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);

          let proposedRulesText = await props.getProposedRules(draft.id);
          setProposedRulesText(proposedRulesText);
          handleChange(event, "proposedRules", proposedRulesText);
        }
        handleChange(event, "draftName", value);
      }
    };

    const handleProposedRulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value as string;
      setProposedRulesText(value);
      handleChange(event, "proposedRules", value);
    };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        if (name === "liaisonId") {
          contact = ruleContactList.find((i: any) => i.id === rawValue);
        } else {
          contact = attestationContactList.find((i: any) => i.id === rawValue);
        }
        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
            handleChange(event, "liaisonId", contact);
          } else {
            handleChange(event, "attestation", contact);
            handleChange(event, "attestationId", contact);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleRulemakingSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      value: string
    ) => {
      handleChange(event, name, value);
    };

    const onTransmittalSheetUpload = (guid: string, filename: string) => {
      let index = formValues.transmittalSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.transmittalSheet.push(uploadObject);
      } else {
        formValues.transmittalSheet[index] = uploadObject;
      }
    };

    const onTransmittalSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var transmittalData = formValues.transmittalSheet.filter((obj: any) => { return obj.guid !== guid; });
          formValues.transmittalSheet = transmittalData;
          handleChange(null, 'transmittalSheet', transmittalData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    const currId = uuid();

    return (
      <FormProvider values={formValues} onChange={handleChange}>
        <div className="flex-column">
          <Typography variant="h6">Notice of Rulemaking Intent (NRI)</Typography>
          <TextField
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row>
            <Select
              label="TITLE"
              name="rule"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>
          <Row>
            <Select
              name="rulemakingAction"
              label="RULEMAKING ACTION"
              defaultValue={RulemakingActions.NriPermanent}
              onChange={handleRulemakingSelectChange}
              disabled={readOnly}
            >
              <MenuItem value={RulemakingActions.NriPermanent}>{RulemakingActions.NriPermanent}</MenuItem>
              <MenuItem value={RulemakingActions.NriEmergency}>{RulemakingActions.NriEmergency}</MenuItem>
            </Select>
            <Select
              label="DRAFTS"
              name="draft"
              value={formValues.draftId}
              onChange={handleDraftSelectChange}
              disabled={readOnly}
            >
              {props.renderDraftDropdown}
            </Select>
          </Row>
          <TextField
            name="proposedRules"
            label="PROPOSED RULES"
            multiline
            fullWidth
            rows="5"
            disabled={readOnly}
            value={proposedRulesText}
            onChange={handleProposedRulesChange}
          />
          <TextField
            name="summary"
            label="SUMMARY"
            multiline
            fullWidth
            rows="5"
            disabled={readOnly}
          />
          <TextField
            name="authority"
            label="AUTHORITY"
            multiline
            fullWidth
            rows="5"
            disabled={readOnly}
          />
          <Paper elevation={0} variant="outlined">
            <TextField
              name="commentPeriod"
              label="COMMENT PERIOD"
              rows="5"
              multiline
              fullWidth
              disabled={readOnly} />
            <Row>
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">
                  COMMENT PERIOD: START DATE
                </FormLabel>
                <DatePicker
                  name="commentBeginDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.commentBeginDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) =>
                    handleDateChange(event, "commentBeginDate", date)
                  }
                  dateFormat="MMMM d, yyyy"
                  disabled={readOnly}
                />
              </div>
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">
                  COMMENT PERIOD: END DATE
                </FormLabel>
                <DatePicker
                  name="commentEndDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.commentEndDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) =>
                    handleDateChange(event, "commentEndDate", date)
                  }
                  dateFormat="MMMM d, yyyy"
                  disabled={readOnly}
                />
              </div>
            </Row>
          </Paper>
          <Paper elevation={0} variant="outlined">
            <TextField
              name="publicHearing"
              label="PUBLIC HEARING"
              multiline
              fullWidth
              rows="5"
              disabled={readOnly} />
          </Paper>

          <TextField
            name="requestForComments"
            label="REQUEST FOR COMMENTS FROM BUSINESS ENTITIES"
            multiline
            fullWidth
            rows="5"
            disabled={readOnly}
          />
          <TextField
            name="copiesOfProposedRules"
            label="COPIES OF PROPOSED RULES"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="ruleImpact"
            label="RULE IMPACT STATEMENT"
            rows="5"
            multiline
            fullWidth
            width="medium"
            disabled={readOnly}
          />
          <TextField
            name="contactPerson"
            label="CONTACT PERSON"
            rows="5"
            multiline
            fullWidth
            width="medium"
            disabled={readOnly}
          />
          <TextField
            name="publicCommentResource"
            label="PUBLIC COMMENT RESOURCE"
            type="email"
            disabled={readOnly}
          />
          <Select
            name="liaisonId"
            label="AGENCY LIAISON"
            onChange={handleContactSelectChange}
            disabled={readOnly}
          >
            {ruleContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderLiaisonDropdown} */}
          </Select>

          <FormLabel>TRANSMITTAL SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.transmittalSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onTransmittalSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onTransmittalSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>

        </div>
      </FormProvider>
    );
  }
);
