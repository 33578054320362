import { DropDownList } from '@progress/kendo-react-dropdowns'
import { useReportFormController } from './ReportFormController';
import { Button, CircularProgress } from '@material-ui/core';
import { Checkbox } from '@progress/kendo-react-inputs';

interface IRulesLiaison {
  selectedReport: string;
}

const RulesLiaison = (props: IRulesLiaison) => {
  const { selectedReport } = props;
  const {
    formValues,
    titles,
    recordStatuses,
    loading,
    // functions
    updateFormValues,
    runReport,
  } = useReportFormController();

  return (
    <div className="report-form">
      <div className="input-description">Select a Title</div>
      <DropDownList
        data={titles}
        value={formValues.rule}
        textField="title"
        dataItemKey="id"
        onChange={(event) => updateFormValues({ ...formValues, rule: event.target.value })}
      />
      {/* // checkbox goes here */}
      <div className="input-description">Liaison Status</div>
      <DropDownList
        data={recordStatuses}
        value={formValues.liaisonStatus ?? {}}
        textField="name"
        dataItemKey="id"
        onChange={(event) => updateFormValues({ ...formValues, liaisonStatus: event.target.value })}
      />
      {/* <div className="input-description">Include Alternate Liaisons</div> */}
      <Checkbox defaultChecked={true} value={formValues.includeLiaison}
        onChange={(event) => updateFormValues({ ...formValues, includeLiaison: event.target.value })}
      >
        <span style={{ textAlign: "center", paddingLeft: "0.5rem" }}><b>Include Alternate Liaisons</b></span>
      </Checkbox>
      <div className="report-button-group">
        {loading
          ? (
            <div className='loading-report-text'>
              <span>
                Loading
              </span>
              <CircularProgress color="primary" size={20} />
            </div>
          )
          : (
            <>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "csv")}>
                Download CSV
              </Button>
              <Button variant="contained" color="primary" onClick={() => runReport(selectedReport, "pdf")}>
                Download PDF
              </Button>
            </>
          )
        }
      </div>
    </div>
  )
}

export default RulesLiaison