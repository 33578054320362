import { SegmentNote } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class SegmentNoteAPI extends BaseAPI<SegmentNote> {
  constructor() {
    super("note", APISettings.adminRulesApiUrl);
  }

  public getSegmentNoteById: (id: number) => Promise<SegmentNote> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentNoteById?id=${id}`))
      .data;
  };

  public getAllSegmentNote: () => Promise<SegmentNote[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllSegmentNote`)).data;
  };

  public createSegmentNote: (note: SegmentNote) => Promise<any> =
    async (note: SegmentNote) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/CreateSegmentNote`, note
      );
      return response.data;
    };

  public removeSegmentNote: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveSegmentNote?id=${id}`
    );
    return response.data;
  };

  public updateSegmentNote: (
    id: number,
    note: SegmentNote
  ) => Promise<SegmentNote> = async (id: number, note: SegmentNote) => {
    const response = await BaseAPI.axios.put(
      `${this.apiUrl}/UpdateSegmentNote?id=${id}`, note
    );
    return response.data;
  };
}

export const segmentNoteAPI = new SegmentNoteAPI();
