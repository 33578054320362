import { CodeFullEdition } from "../model";
// import { APISettings } from "../settings";
// import { StaticAuthenticationSettings } from "../settings/AuthenticationSettings";
import { BaseAPI } from "./internal";

class CodeFullEditionAPI extends BaseAPI<CodeFullEdition> {
  constructor() {
    super("");
  }

  public getVolumeList: () => Promise<CodeFullEdition[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetVolumeList`)).data;
  };

  public getCodeFullEditionByVolumeNumber: (
    id: number
  ) => Promise<CodeFullEdition[]> = async (volumeNumber: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetCodeFullEditionByVolumeNumber?volumeNumber=${volumeNumber}`
      )
    ).data;
  };

  public createCodeFullEdition: (
    codeFullEdition: CodeFullEdition
  ) => Promise<any> = async (codeFullEdition: CodeFullEdition) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateCodeFullEdition`,
      {
        codeFullEdition,
      }
    );
    return response.data;
  };

  public generateCodeFullEditionJob: () => Promise<any> = async () => {
    const response = await BaseAPI.axios.get(
      `${this.apiUrl}/GenerateNewCodeFullEditionPdfBuildJob`
    );
    return response.data;
  };
}
export const codeFullEditionAPI = new CodeFullEditionAPI();
