export enum RulemakingActions {
	AdoptedEmergency = "Adopted Emergency Rules",
	CancelledCommentEmergency = "Cancelled comment period and/or public hearing relating to a proposed EMERGENCY rulemaking action",
	CancelledCommentPermanent = "Cancelled comment period and/or public hearing relating to a proposed PERMANENT rulemaking action",
	CancelledHearingEmergency = "Cancelled Public Hearing Relating to Emergency Rulemaking Action",
	CancelledHearingPermanent = "Cancelled Public Hearing Relating to Permanent Rulemaking Action",
	ContinuedCommentEmergency = "Continued comment period and/or public hearing relating to a proposed EMERGENCY rulemaking action",
	ContinuedCommentPermanent = "Continued comment period and/or public hearing relating to a proposed PERMANENT rulemaking action",
	ContinuedHearingEmergency = "Continued Public Hearing Relating to Emergency Rulemaking Action",
	ContinuedHearingPermanent = "Continued Public Hearing Relating to Permanent Rulemaking Action",
	FeeIncrease = "Notification of Fee Increase",
	Error = "Notice of error in published document",
	EmergencyAdoption = "EMERGENCY adoption",
	ExecutiveOrder = "Executive Order",
	GovernorDeclaration = "Governor Declaration",
	NoticeLDPermanentEmergency = "Legislative disapproval of EMERGENCY rules",
	NoticeLDPermanentPermanent = "Legislative disapproval of PERMANENT rules",
	NriEmergency = "Notice of proposed EMERGENCY rulemaking",
	NriPermanent = "Notice of proposed PERMANENT rulemaking",
	NoticeSR = "Submission to Governor and Legislature",
	Permanent = "Adopted Permanent Rules",
	PermanentFinalAdoption = "PERMANENT final adoption",
	Preemptive = "PREEMPTIVE adoption",
	PreemptiveAdoption = "Adopted Preemptive Rules",
	WithdrawnEmergency = "Withdrawal of EMERGENCY rulemaking",
    WithdrawnPermanent = "Withdrawal of PERMANENT rulemaking"
}