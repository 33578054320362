import { Container, Divider } from "@material-ui/core";
import { Menu } from "../../../../components/Menu/Menu";
import { getOS } from "../../../../utils/UserOS";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { SubscriptionRecoverSteps } from "./SubscriptionRecoverSteps";


export const RecoverPasswordForm = () => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";



  return (
    <>
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>

      <div className="subscriptions">
        <Container>
          <Card className="card-container">
            {/*<CardImage*/}
            {/*  src={loginBackground}*/}
            {/*  style={{ backgroundColor: "black", padding: 20 }}*/}
            {/*/>*/}
            <div>
              <CardHeader>
                <CardTitle style={{ textAlign: "center" }}>
                  <strong>Rules & Regulations Subscription Service</strong>
                </CardTitle>
                <br />
                <CardTitle style={{ textAlign: "center" }}>
                  Enter new Password
                </CardTitle>
              </CardHeader>

              <CardBody>
                  <SubscriptionRecoverSteps />
              </CardBody>
            </div>
          </Card>
        </Container>
      </div>
    </>
  );
};
