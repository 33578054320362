export class StatusType {
    constructor(
        id: number,
        status: string,
        iconName: string) {
            this.id = id;
            this.status = status;
            this.iconName = iconName;
    }

    public id: number;
    public status: string;
    public iconName: string;
}