import { MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import { CommentThreadComp } from "../../..";
import { FormMap } from "../../../../stateOffice/pages/FilingBuilder/FormMap";
import {
  draftAPI,
  filingTypeAPI,
  segmentAPI,
} from "../../../../utils/api";
import { contactAPI } from "../../../../utils/api/ContactApi";
import { contactTypeAPI } from "../../../../utils/api/ContactTypeApi";
import { ruleAPI } from "../../../../utils/api/RuleApi";
import { correctedDocumentTypeAPI } from "../../../../utils/api/CorrectedDocumentTypeApi";
import {
  Contact,
  ContactType,
  Docket,
  Filing,
  Rule,
  Draft,
  CorrectedDocumentType
} from "../../../../utils/model";
import "./MainTab.scss";
import { FilingTypes, SegmentStatus, SegmentType } from "../../../../utils/enums";
import { ContactSupportOutlined } from "@material-ui/icons";
import _remove from "lodash/remove";



interface IMainTabProps {
  filing: Filing;
  saveForm: (value: any, type: string) => void;
  userTitleAccess: any;
  userRoleAccess: any;
}

export interface FormImperativeHandles {
  getValues(): any;
  getType(): string;
}

export let MainTab = React.forwardRef((props: IMainTabProps, ref) => {
  const [ruleList, setRuleList] = React.useState<Rule[]>([]);
  const [draftList, setDraftList] = React.useState<Draft[]>([]);
  const [docketList, setDocketList] = React.useState<Docket[]>([]);
  const [contactList, setContactList] = React.useState<Contact[]>([]);
  const [liaisonList, setLiaisonList] = React.useState<Contact[]>([]);
  const [filingType, setFilingType] = React.useState<any | null>(null);
  const [chapterList, setChapterList]: any = React.useState([]);
  const [correctedDocumentTypeList, setCorrectedDocumentTypeList] = React.useState<CorrectedDocumentType[]>([]);
  const [adoptionStart, setAdoptionStart] = React.useState<Date>();
  const [adoptionEnd, setAdoptionEnd] = React.useState<Date>();
  const NoFormMessage = () => <div>Form not found</div>;

  const formatDate = (dateString: string) => {

    var date = new Date(dateString);
    var month = date.toLocaleString("en-us", { month: "long" });
    var day = date.getDate();
    var year = date.getFullYear();
    return month.toUpperCase() + " " + day + ", " + year;
  };

  const setupDocket = React.useCallback(async () => {
    try {
      // const docketList = await docketAPI.getAllDockets();
      // setDocketList(docketList);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setupRules = React.useCallback(async () => {
    try {
      let ruleCollection: Rule[] = await ruleAPI.getAllRules({ pageNumber: 1, pageSize: 300 }, true);

      if (props.filing.filingTypeId == FilingTypes.Adopted_PRE) {
        ruleCollection = ruleCollection.filter(x => x.referenceCode === '340');
        let segments: Object = await segmentAPI.getChapterSegmentsByParentId(22992);
        setChapterList(segments)
      }

      // Remove executive orders from the list of title options for these filing types.
      if (
        props.filing.filingTypeId == FilingTypes.Notice || props.filing.filingTypeId == FilingTypes.Notice_NRI ||
        props.filing.filingTypeId == FilingTypes.Notice_CON || props.filing.filingTypeId == FilingTypes.Notice_CAN ||
        props.filing.filingTypeId == FilingTypes.Notice_SR || props.filing.filingTypeId == FilingTypes.Notice_LD ||
        props.filing.filingTypeId == FilingTypes.Notice_WR || props.filing.filingTypeId == FilingTypes.Notice_ERR ||
        props.filing.filingTypeId == FilingTypes.Adopted || props.filing.filingTypeId == FilingTypes.Adopted_PERM ||
        props.filing.filingTypeId == FilingTypes.Adopted_PRE) {
        ruleCollection = _remove(ruleCollection, (x: Rule) => x.referenceCode !== "1");
      }


      let newRulesList: any = [];

      // Filter out rules that the user does not have access to but allow all titles if user is admin
      if (props.userRoleAccess) {
        if (typeof props.userRoleAccess == "string") {
          if (props.userRoleAccess == "Global User Administrator" || props.userRoleAccess == "Office of Administrative Rules" && newRulesList.length! > 0) {
            newRulesList = ruleCollection;
          }
        } else {
          props.userRoleAccess.forEach((item: any, index: number) => {
            if (item == "Global User Administrator" || item == "Office of Administrative Rules" && newRulesList.length! > 0) {
              newRulesList = ruleCollection;
            }
          })
        }
      } else {
        // Filter out rules that the user does not have access to
        for (let i = 0; i < ruleCollection.length; i++) {
          for (let j = 0; j < props.userTitleAccess.length; j++) {
            if (ruleCollection[i].referenceCode == props.userTitleAccess[j]) {
              newRulesList.push(ruleCollection[i]);
            }
          }
        }
      }

      setRuleList(ruleCollection);
    } catch (err) {
      console.error(err);
    }
  }, [props.filing.filingTypeId]);

  const setupDrafts = async () => {
    // console.log("Setting up drafts");
    try {
      const draftList = await draftAPI.getAllDrafts();
      setDraftList(draftList);
    } catch (err) {
      console.error(err);
    }
  };

  const setupAttestation = React.useCallback(async () => {
    try {
      // We need to be careful with this to actually select the attestation officers
      const contactType = await contactTypeAPI.getAllContactTypes();
      let contactTypeId;
      contactType.result.forEach((val: ContactType) => {
        if (val.name === "Attestation Officer") {
          contactTypeId = val.id;
        }
      });
      if (contactTypeId !== null && contactTypeId !== undefined) {
        const contactCollection: any = await contactAPI.getAllContactsByType(
          contactTypeId
        );
        setContactList(contactCollection.result);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setupLiaison = React.useCallback(async () => {
    try {
      // We need to be careful with this to actually select the liaison officers
      // Where its going off the contact.name this may have to be adjusted
      const contactTypeList = await contactTypeAPI.getAllContactTypes();
      var contactType = contactTypeList.result.find(
        (element: any) =>
          element.name === "Agency Liaison" ||
          element.name === "Alternate Liaison"
      );
      if (contactType !== undefined) {
        const contactCollection: any = await contactAPI.getAllContactsByType(
          contactType?.id!,
          { pageNumber: 1, pageSize: 500 }
        );
        setLiaisonList(contactCollection.result);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const setupFilingType = React.useCallback(async () => {
    try {
      // We need to be careful with this to actually select the liaison officers
      let value: any = await filingTypeAPI.getFilingTypeById(props.filing.filingTypeId);
      setFilingType(value);

    } catch (err) {
      console.error(err);
    }
  }, []);

  const setupCorrectedDocumentTypes = async () => {
    try {
      let correctedDocumentTypes = await correctedDocumentTypeAPI.getByFilingTypeIdAsync(props.filing.filingTypeId);
      setCorrectedDocumentTypeList(correctedDocumentTypes);
    } catch (err) {
      console.error(err);
    }
  }

  const renderRuleDropdown = () => {
    let menuItems: any = [];


    if (ruleList) {
      ruleList.forEach((id: Rule) => {
        if (id.id != null) {
          menuItems.push(
            <MenuItem key={id.id} value={id.id}>Title {id.referenceCode}. {id.title}</MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderDocketDropdown = () => {
    let menuItems: any = [];
    if (docketList) {
      docketList.forEach((element) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={element.id} value={element.id}>
              {element.year + "-" + element.documentNumber}
            </MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  // Filing add form function
  const returnChapterListAsync = async (
    parentId: number | null | undefined
  ) => {
    if (parentId && chapterList) {
      let segments: Object = await segmentAPI.getChapterSegmentsByParentIdIncludeAll(parentId);
      // setChapterList(segments)
      return segments;
    }
    return chapterList;
  };

  const returnContactListAsync = async (titleId: number, isAttestation: boolean) => {
    if (titleId != null) {
      let contacts: any;
      if (isAttestation) {
        contacts = contactAPI.getAttestationContactsByTitle(titleId);
      } else {
        contacts = contactAPI.getLiaisonContactsByTitle(titleId);
      }
      return contacts;
    } else {
      return [];
    }
  }

  const renderDraftDropdown = () => {
    let menuItems: any = [];
    if (draftList) {
      draftList.forEach((element: any, index: number) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={index} value={element.id}>
              {"Title - " + element.titleNumber + " Chapter - " + element.chapterNumber + ": " + element.draftName}
            </MenuItem>
          )
        }
      })
    }
    return menuItems;
  }

  const renderContactDropdown = () => {
    let menuItems: any = [];
    if (contactList) {
      contactList.forEach((element: Contact) => {
        if (element.id != null) {
          menuItems.push(
            <MenuItem key={element.id} value={element.id}>
              {element.firstName + " " + element.lastName}
            </MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderLiaisonDropdown = () => {
    let menuItems: any = [];
    if (liaisonList) {
      liaisonList.forEach((element: Contact) => {
        if (element.id != null) {
          menuItems.push(<MenuItem key={element.id} value={element.id}>{`${element.firstName} ${element.lastName}`}</MenuItem>
          );
        }
      });
    }
    return menuItems;
  };

  const renderCorrectedDocumentTypeDropdown = () => {
    if (correctedDocumentTypeList) {
      let menuItems: any = [];
      correctedDocumentTypeList.forEach((item: CorrectedDocumentType) => {
        if (item.id != null) {
          menuItems.push(<MenuItem key={item.id} value={item.id}>{item.documentName}</MenuItem>);
        }
      });
      return menuItems;
    }
  };

  const setAdoptionStartAndEndDates = () => {
    if (props.filing.filingType?.id && props.filing.filingType?.id == FilingTypes.Adopted_EME) {
      if (props.filing.adoptionDate) {
        setAdoptionStart(new Date(props.filing.adoptionDate));

        var end = new Date(props.filing.adoptionDate)
        setAdoptionEnd(new Date(end.setDate(end.getDate() + 10)));
      }
    }
  };

  const getSegmentStatus = (statusId: number) => {
    switch (statusId) {
      case SegmentStatus.New:
        return " [NEW]"
      case SegmentStatus.Revoked:
        return " [REVOKED]";
      case SegmentStatus.Reserved:
        return " [RESERVED]"
      case SegmentStatus.Expired:
        return " [EXPIRED]";
      case SegmentStatus.Amended:
        return " [AMENDED]"
      case SegmentStatus.AmendedRenumbered:
        return " [AMENDED AND RENUMBERED]"
      case SegmentStatus.Renumbered:
        return " [RENUMBERED]"
      case SegmentStatus.Superseded:
        return " [SUPERSEDED]"
      case SegmentStatus.Terminated:
        return " [TERMINATED]"
      case SegmentStatus.Transferred:
        return " [TRANSFERRED]"
      case SegmentStatus.Other:
        return " [OTHER]"
      case SegmentStatus.Published:
        return " [PUBLISHED]"
      default:
        return "";
        break;
    }
  };

  const getProposedRules = async (draftId: number | null | undefined) => {
    let proposedRules = await draftAPI.getProposedRulesByDraftId(draftId!, "MainTab.tsx");
    let proposedRulesText = "";
    proposedRules && proposedRules.length > 0 ?
      proposedRules
        .filter((x: any) => x.segmentTypeId != SegmentType.Title && x.segmentTypeId != SegmentType.Chapter)
        .forEach(x => {
          if (x.segmentTypeId == SegmentType.Subchapter) {
            proposedRulesText += `Subchapter ${x.subchapterNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else if (x.segmentTypeId == SegmentType.Appendix) {
            proposedRulesText += `Appendix ${x.appendixNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else if (x.segmentTypeId == SegmentType.Part) {
            proposedRulesText += `Part ${x.partNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          } else {
            proposedRulesText += `${x.sectionNumber}. ${x.name}${x.segmentData ? getSegmentStatus(x.segmentData.statusId) : getSegmentStatus(x.segmentStatusId)} \r`;
          }
        })
      : '';

    return proposedRulesText;
  };

  const _render = () => {
    if (filingType != null) {
      let formKey = filingType.name || "";
      const CurrentFormComponent =
        FormMap[
        Object.keys(FormMap).find(
          (key) => key.toLowerCase() === formKey.toLowerCase()
        )!
        ] || NoFormMessage;
      return (
        <CurrentFormComponent
          ref={ref}
          attestationList={contactList}
          liaisonList={liaisonList}
          filing={props.filing}
          docketList={docketList}
          ruleList={ruleList}
          draftList={draftList}
          adoptionStart={adoptionStart}
          adoptionEnd={adoptionEnd}
          filingType={props.filing.filingTypeId}
          correctedDocumentTypeList={correctedDocumentTypeList}
          formatDate={formatDate}
          renderContactDropdown={renderContactDropdown()}
          renderLiaisonDropdown={renderLiaisonDropdown()}
          renderRuleDropdown={renderRuleDropdown()}
          renderDocketDropdown={renderDocketDropdown()}
          renderDraftDropdown={renderDraftDropdown()}
          returnChapterListAsync={returnChapterListAsync}
          renderCorrectedDocumentTypeDropdown={renderCorrectedDocumentTypeDropdown()}
          getProposedRules={getProposedRules}
          returnContactListAsync={returnContactListAsync}
        />
      );
    }
  };

  useEffect(() => {
    setAdoptionStartAndEndDates();
    setupAttestation();
    setupDocket();
    setupLiaison();
    setupFilingType();
    setupDrafts();
    setupCorrectedDocumentTypes();
    if (props.filing) {
      setupRules();
    }
  }, [props.filing, setupRules, setupAttestation, setupDocket, setupLiaison, setAdoptionStart, setAdoptionEnd]);
  return (
    <div className="main-tab">
      {_render()}
      {/* <CommentThreadComp type="filing" id={props.filing.id!} /> */}
    </div>
  );
});
