import {
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Filing,
  FilingType,
  Rule,
  Draft,
  CorrectedDocumentType,
} from "../../../../../../utils/model";
import { ErrorRulemakingAction } from "../../../../../../utils/model/Filing";
import { Row, Select, TextField } from "./";
import "./../FilingAddForm.scss";
import { FilingLink } from "../..";
import { FileUpload } from "../../../../../../components/FileUpload";
import {
  StorageContainer,
  FilingTypes,
  DraftStatuses,
  RulemakingActions,
  CorrectedDocumentTypes
} from "../../../../../../utils/enums";
import { fileAPI } from "../../../../../../utils/api";

interface INoticeOfErrorFormProps {
  filingType: FilingType;
  filing?: Filing;
  renderRuleDropdown: () => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderDocketDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  renderCorrectedDocumentTypeDropdown: () => any;
  // attestationList: Contact[];
  // liaisonList: Contact[];
  docketList: Docket[];
  ruleList: Rule[];
  draftList: Draft[];
  correctedDocumentTypeList: CorrectedDocumentType[];
  saveForm: (item: any, type: string) => void;
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
  returnContactListAsync: (ruleId: number, isAttestation: boolean) => any;
}

const getInitialState = (props: INoticeOfErrorFormProps) => ({
  filingName: "",
  formName: "NoDraft",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  chapter: props.ruleList[0],
  rule: props.ruleList[0],
  ruleId: -1,
  chapterId: -1,
  rulemakingAction: RulemakingActions.Error,
  findingofEmergency: "",
  docketSelect: Docket,
  docketSelectId: 0,
  docketSelectNumber: "",
  registerPublication: "",
  documentType: "",
  documentTypeId: 0,
  rulesField: "",
  altrulesField: "",
  liaisonId: 0,
  liaison: Contact,
  segments: [],
  transmittalSheet: [{ guid: "", filename: "" }],
  draftId: null,
  draft: null,
  correctedDocumentTypeId: 1,
  correctedDocumentType: CorrectedDocumentTypes.NoticeOfCancelledCommentPeriod,
  documentTypeName: ""
});

export let NoticeOfErrorForm = React.forwardRef(
  (props: INoticeOfErrorFormProps, ref) => {
    const [formValues, handleChange] = useFormState(
      { ...getInitialState(props), ...props.filing?.entity },
      ref
    );

    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    const setLiaisonListFromRuleId = React.useCallback(async (ruleId: number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    }, [props]
    );

    const setAttestationListFromRuleId = React.useCallback(async (ruleId: number) => {
      const attestation = await props.returnContactListAsync(ruleId, true);
      setAttestationContactList(attestation);
    }, [props]
    );

    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        if (
          !Object.values(ErrorRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction = ErrorRulemakingAction.Error;
        }
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);
        setAttestationListFromRuleId(props.ruleList[0]?.id!);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }
      } else if (props.filing) {
        if (
          !Object.values(ErrorRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction = ErrorRulemakingAction.Error;
        }
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
        setAttestationListFromRuleId(entity.rule.id);
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i: any) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
          setAttestationListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const handleDraftSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);
        }
      }
    };

    const handleCorrectedDocumentTypeSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      value: string
    ) => {
      handleChange(event, name, value);
    };

    // const handleCorrectedDocumentTypeSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, name: string, rawValue: string | null) => {
    //   const value = rawValue as keyof typeof CorrectedDocumentType;
    //   if (value != null) {
    //     let correctedDocumentType = props.correctedDocumentTypeList.find((i) => i.id === rawValue);
    //     if (correctedDocumentType != null) {
    //       handleChange(event, "documentTypeName", correctedDocumentType.documentName);
    //       handleChange(event, "correctedDocumentTypeId", correctedDocumentType.id);
    //     }
    //   }
    // };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        if (name === "liaisonId") {
          contact = ruleContactList.find((i: any) => i.id === rawValue);
        } else {
          contact = attestationContactList.find((i: any) => i.id === rawValue);
        }
        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
            handleChange(event, "liaisonId", contact.id);
          } else {
            handleChange(event, "attestation", contact);
            handleChange(event, "attestationId", contact.id);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleRulemakingSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      value: string
    ) => {
      value === ErrorRulemakingAction.Error
        ? handleChange(event, name, ErrorRulemakingAction.Error)
        : handleChange(event, name, "");
    };

    const currId = uuid();

    const onTransmittalSheetUpload = (guid: string, filename: string) => {
      let index = formValues.transmittalSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.transmittalSheet.push(uploadObject);
      } else {
        formValues.transmittalSheet[index] = uploadObject;
      }
    };

    const onTransmittalSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var transmittalData = formValues.transmittalSheet.filter((obj: any) => { return obj.guid !== guid; });
          formValues.transmittalSheet = transmittalData;
          handleChange(null, 'transmittalSheet', transmittalData);
        }
      } catch (error) {
        console.error("Failed to delete file: ", error)
      }
    }

    return (
      <FormProvider values={formValues} onChange={handleChange}>
        <div className="flex-column">
          <Typography variant="h6">Notice of Error in Published Document (ERR)</Typography>
          <TextField
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row>
            <Select
              label="TITLE"
              name="rule"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId !== -1 ? (
              <Select
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (
                    <MenuItem key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>
                  );
                })}
              </Select>
            ) : null}
          </Row>

          <TextField
            name="rulemakingAction"
            label="ACTION"
            value={RulemakingActions.Error}
            fullWidth
            disabled={readOnly}
          />

          <FormLabel className="effect-checkbox">DOCUMENT CORRECTED</FormLabel>
          <FormGroup className="form-group-checkbox">

            <Select
              name="correctedDocumentType"
              label="DOCUMENT TYPE"
              onChange={handleCorrectedDocumentTypeSelectChange}
              disabled={readOnly}
            >
              <MenuItem value={CorrectedDocumentTypes.NoticeOfCancelledCommentPeriod}>{CorrectedDocumentTypes.NoticeOfCancelledCommentPeriod}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.NoticeOfContinuedCommentPeriod}>{CorrectedDocumentTypes.NoticeOfContinuedCommentPeriod}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.NoticeOfLegislativeDisapproval}>{CorrectedDocumentTypes.NoticeOfLegislativeDisapproval}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.NoticeOfRulemakingIntent}>{CorrectedDocumentTypes.NoticeOfRulemakingIntent}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.NoticeOfWithdrawnRules}>{CorrectedDocumentTypes.NoticeOfWithdrawnRules}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.PermanentRuleDocumentEnactingClause}>{CorrectedDocumentTypes.PermanentRuleDocumentEnactingClause}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.PermanentRuleDocumentPreamble}>{CorrectedDocumentTypes.PermanentRuleDocumentPreamble}</MenuItem>
              <MenuItem value={CorrectedDocumentTypes.StatementOfSubmissionToGovAndLeg}>{CorrectedDocumentTypes.StatementOfSubmissionToGovAndLeg}</MenuItem>
            </Select>

          </FormGroup>
          <TextField
            name="rules"
            label="RULES"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="registerPublication"
            label="REGISTER PUBLICATION"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="docketSelectNumber"
            label="DOCKET NUMBER"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <TextField
            name="corrections"
            label="CORRECTIONS"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <Select
            name="liaisonId"
            label="AGENCY LIAISON"
            value={formValues.liaisonId}
            onChange={handleContactSelectChange}
            disabled={readOnly}
          >
            {ruleContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderLiaisonDropdown} */}
          </Select>

          <FormLabel>TRANSMITTAL SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.transmittalSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onTransmittalSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onTransmittalSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
        </div>
      </FormProvider>
    );
  }
);
