export class JsonUtilities {
  // Solution from https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
  public static removeEmpty<T>(obj: T): T {
    let object: any = obj;
    return Object.fromEntries(
      Object.entries(object)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? JsonUtilities.removeEmpty(v) : v])
    ) as T;
  }
}