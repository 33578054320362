import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import * as ReactDOM from "react-dom";
import "./DropDownSelect.scss";

interface IDropDownProps {
    className?: string;
    label?: string;
    data: any[];
    value: any;
    onChange: (event:any) => void;
}



export const DropDownSelect = (props: IDropDownProps) => {

    return (
        <div>
            <div><strong>{props.label}</strong></div>
            <DropDownList
                className={props.className}
                data={props.data}
                onChange={props.onChange}
                textField="text"
                dataItemKey="id"
                value={props.value}
            />
        </div>
    );
};
