import { EmailGroup } from "../model/EmailGroup";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class EmailGroupAPI extends BaseAPI<EmailGroup> {
  constructor() {
    super("emailGroups", APISettings.adminRulesApiUrl);
  }

  public GetEmailGroupContactsByEmailTemplateIdAsync: (id:number) => Promise<any[]> = async (id:number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetEmailGroupContactsByEmailTemplateIdAsync?emailTemplateId=${id}`)).data;
  };

  public createEmailGroup: (emailGroup: EmailGroup[]) => Promise<any> =
    async (emailGroup: EmailGroup[]) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/CreateEmailGroupsAsync`,
        emailGroup,
      );
      return response.data;
    };

  public deleteByEmailTemplateId: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeleteByEmailTemplateId?emailTemplateId=${id}`
    );
    return response.data;
  };

}

export const emailGroupAPI = new EmailGroupAPI();
