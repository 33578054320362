import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Pager, RegisterSearchWrapper, Results } from ".";
import { Menu } from "../../components/Menu";
import { searchFuncAPI } from "../../utils/api";
import { Registries } from "../../utils/model/Registries";
import { getOS } from "../../utils/UserOS";
import "./Registers.scss";
import { PublicRegisterPreviewModal } from "./components/Results/Result/PublicRegisterPreviewModal";

interface IRegisterProps {
  registries: Registries;
}

export const Registers = (props: IRegisterProps) => {
  let location = useLocation();
  let history = useHistory();
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  const [results, setResults] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [q, setQ] = useState(
    new URLSearchParams(location.search).get("q") ?? ""
  );
  const [top] = useState(new URLSearchParams(location.search).get("top") ?? 5);
  const [skip, setSkip] = useState(
    new URLSearchParams(location.search).get("skip") ?? 0
  );
  const [filters, setFilters] = useState([]);
  const [facets, setFacets] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [htmlName, setHtmlName] = useState("");


  let resultsPerPage = top;

  useEffect(() => {
    setIsLoading(true);
    const body = {
      q: q,
      top: top,
      skip: skip,
      filters: filters,
    };

    axios
      // .post("/api/search", body)
      searchFuncAPI.httpSearchFunction('SearchRegistry', body)
      .then((response:any) => {
        setResults(response.data.results);
        setFacets(response.data.facets);
        setResultCount(response.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [q, top, skip, filters]);

    const togglePreviewModal = () => {
      openPreviewModal ? setOpenPreviewModal(false) : setOpenPreviewModal(true);
    }

    const handleHTMLRegisterPreview = (htmlName: string) => {
      setHtmlName(htmlName);
      togglePreviewModal();
    }

  let postSearchHandler = (searchTerm: any) => {
    // pushing the new search term to history when q is updated
    // allows the back button to work as expected when coming back from the details page
    history.push("/registers?q=" + searchTerm);
    setCurrentPage(1);
    setSkip(0);
    setFilters([]);
    setQ(searchTerm);
  };

  let updatePagination = (newPageNumber: number) => {
    setCurrentPage(newPageNumber);
    setSkip((newPageNumber - 1) * +top);
  };

  var body;
  if (isLoading) {
    body = (
      <div className="col-md-9">
        <CircularProgress />
      </div>
    );
  } else {
    body = (
      <>
        <PublicRegisterPreviewModal
          open={openPreviewModal}
          close={togglePreviewModal}
          registerName={htmlName}
          htmlFilename={htmlName}
        />
        <div className="col-md-9">
          <Results
            query={q}
            documents={results}
            top={top}
            skip={skip}
            count={resultCount}
            handleHTMLRegisterPreview={handleHTMLRegisterPreview}
          ></Results>
          <Pager
            className="pager-style"
            currentPage={currentPage}
            resultCount={resultCount}
            resultsPerPage={resultsPerPage}
            setCurrentPage={updatePagination}
          ></Pager>
        </div>
      </>
    );
  }

  return (
    <div className="code-search">
      <a id="skip-nav" className="skiplink-text" href="#main-content">
        Skip to Main Content
      </a>
      <Menu title={"Home"} OS={platform}></Menu>

      <main id="main-content">
          <RegisterSearchWrapper postSearchHandler={postSearchHandler} q={q}/>
          <div className="results">
          {/* <Facets facets={facets} filters={filters} setFilters={setFilters}/> */}
            {body}
          </div>
      </main>
    </div>
  );
};
