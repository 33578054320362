import { Button, IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { useEffect } from "react";
import "./Banner.scss"

interface IBannerProps {
    children: JSX.Element;
    style: "warn" | "info" | "success";
    showBanner: boolean;
    timeout?: number;
    onClose?: () => void;
}


/**
 *
 * @param text - Text you want to display in the banner
 * @param style - Style types Warn(Red), Info(Blue), Success(Green)
 * @param showBanner - Show Banner allows parent component to show or hide
 * @param timeout - Timeout used if component should automatically disappear after a set time
 * @returns Banner component
 */
export const Banner = (props: IBannerProps) => {
    const [bannerHideClassName, setBannerHideClassName] = React.useState<string>(props.showBanner ? "show-banner" : "hide-banner");
    const {children, style, showBanner} = props

    useEffect(() => {
      setBannerHideClassName(showBanner ? "show-banner" : "hide-banner");
    }, [showBanner])

    if(props.timeout) {
        setTimeout(() => {
            setBannerHideClassName("hide-banner")
        }, props.timeout);
    }
    return (
        <div className={`banner-bar banner-bar-${style} ${bannerHideClassName}`}>
            {children}
            <IconButton onClick={props.onClose}><Cancel/></IconButton>
        </div>
    );
}