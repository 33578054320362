import { Button, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
// import React from "react";
import { Link } from "react-router-dom";
import { Authorized } from "../../Authorized";
import { useMenuStyles } from "../Menu";
import "./NavLink.scss";

interface INavLinkProps {
  children: any;
  to?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  requireAuthentication?: boolean;
}

export const NavLink = (props: INavLinkProps) => {
  const { to = "/home", children, requireAuthentication } = props;
  const classes = useMenuStyles();
  if (requireAuthentication == null) {
    return (
      <Authorized showComponent={true} redirectToLogin={false}>
        <Button component={Link} className="menu-button" to={to}>
          {props.icon && <props.icon className={classes.linkIcon} />}
          {children}
        </Button>
      </Authorized>
    );
  }
  return (
    <Button component={Link} className="menu-button" to={to}>
      {props.icon && <props.icon className={classes.linkIcon} />}
      {children}
    </Button>
  );
};
