import { useEffect, useReducer, useState } from "react";
import { ruleAPI, segmentAPI } from "../../../../../../utils/api";
import { reportsAPI } from "../../../../../../utils/api/ReportsApi";
import saveAs from "file-saver";
import { RecordStatus } from "../../../../../../utils/enums";
import { Segment } from "../../../../../../utils/model";

export const useReportFormController = () => {
  // var fs = require("fs");
  const [titles, setTitles] = useState<any>([{ title: "Any", id: 0 }]);
  const [chapters, setChapters] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [ruleTypes, setRuleTypes] = useState<string[]>([
    "Any",
    "New",
    "Amended",
    "Revoked",
    "Renumbered",
    "Reserved",
  ]);
  const [recordStatuses] = useState<any[]>([
    { name: "Active", id: RecordStatus.Active },
    { name: "Deleted", id: RecordStatus.Deleted },
    { name: "Inactive", id: RecordStatus.Inactive },
  ]);

  const getChapters = async (segmentId: number) => {
    setChapters([]);
    let response = await segmentAPI.getChapterSegmentsByParentId(segmentId);

    // add 'Any' chapter to the top of the dropdown list
    const anyChapter: Segment = {
      id: 0,
      parentId: null,
      description: "Any",
      titleNum: "",
      recordStatus: 0,
      segmentTypeId: 0,
      sectionNum: null,
      appendixNum: null,
      partNum: null,
      execOrderNum: null,
      execOrderYear: null,
      authority: null,
      text: null,
      notes: null,
      chapterNum: null,
      subChapterNum: null,
      ruleId: null,
      additionalInformation: null,
      contactId: null,
      segmentStatusId: null,
      filingId: null,
      inProcessSegmentId: null,
      effectiveDate: null,
      renumberedToSegmentNumber: null,
      hasEmergency: false,
    };

    response.unshift(anyChapter);

    setChapters([...chapters, ...response]);
  };

  const [formValues, updateFormValues] = useReducer(
    (prev: any, next: any) => {
      const newData = { ...prev, ...next };
      // Ensure that the start date is never after the end date
      if (newData.startDate > newData.endDate) {
        newData.endDate = newData.startDate;
      }

      // get chapters unless selecting executive orders (id == 1) where it doesn't have chapters
      if (newData.rule.id != prev.rule.id) {
        newData.chapter = null;
        setChapters([]);
        // don't get chapters for executive orders or 'Any'
        if (newData.rule.id != 1 && newData.rule.id != 0) {
          getChapters(newData.rule.segmentId);
        }
      }

      return newData;
    },
    {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 0, 0)),
      rule: { title: "Any", id: 0 },
      chapter: { description: "Any", id: 0 },
      status: "Any",
      includeLiaison: true,
      liaisonStatus: { name: "Active", id: RecordStatus.Active },
    }
  );

  const saveFile = (fileData: any, type: "csv" | "pdf", name: string) => {
    if (type === "csv") {
      var blob = new Blob([fileData], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `${name}.${type}`);
    } else {
      // pdf
      var blob = new Blob([fileData], { type: "text/pdf" });
      saveAs(blob, `${name}.${type}`);
    }
  };

  const runReport = async (reportName: string, format: "csv" | "pdf") => {
    setLoading(true);
    if (reportName == "Rule Record") {
      let response: any = await reportsAPI.RulesRecordReport(
        formValues.rule,
        formValues.chapter ?? 0,
        formValues.status,
        formValues.startDate,
        formValues.endDate,
        format
      );
      saveFile(response, format, reportName);
    }

    if (reportName === "Agency Liaison Email") {
      let response: any = await reportsAPI.ActiveAgencyUserEmailReport(
        formValues.rule.id,
        format
      );
      saveFile(response, format, reportName);
    }

    if (reportName === "Active Agency Users") {
      let response: any = await reportsAPI.ActiveLiaisonUserEmailReport(
        formValues.rule.id,
        format
      );
      saveFile(response, format, reportName);
    }

    if (reportName === "Rules Liaison") {
      let response: any = await reportsAPI.RulesLiaisonReport(
        formValues.rule,
        formValues.chapter,
        formValues.includeLiaison,
        format
      );
      saveFile(response, format, reportName);
    }

    if (reportName === "Code Statistics") {
      let response: any = await reportsAPI.CodeStatisticsReport(
        formValues.startDate,
        formValues.endDate,
        format
      );
      saveFile(response, format, reportName);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getTitles = async () => {
      let result = await ruleAPI.getAllRules(
        { pageNumber: 1, pageSize: 300 },
        true
      );
      setTitles([...titles, ...result]);
    };

    getTitles();
  }, []);

  return {
    formValues,
    titles,
    chapters,
    ruleTypes,
    recordStatuses,
    loading,
    // functions
    updateFormValues,
    runReport,
  };
};
