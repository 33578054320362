import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import LocalHospital from "@material-ui/icons/LocalHospital";
import { DraftPreviewModal } from "../../../../../stateOffice/pages/WorkQueue/components/DraftPreviewModal";
import { draftAPI, filingAPI } from "../../../../../utils/api";
import { Draft, Filing } from "../../../../../utils/model";
import { Tooltip } from "@material-ui/core";
import "./EmergencyResult.scss";

interface IEmergencyModalButtonProps {
  filingId: number;
}

interface IDraft {
  id: number | any;
  title: string;
  chapterNum: string;
}

export const EmergencyModalButton = (props: IEmergencyModalButtonProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [draftData, setDraftData] = useState<IDraft>();
  const [effectiveDate, setEffectiveDate] = useState<Date>();
  const [expirationDate, setExpirationDate] = useState<Date>();
  const openEmergencyModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  }

  const onMount = async () => {

    let filing: Filing = await filingAPI.getFilingsById(props.filingId)

    let effectiveDate: Date;
    let expirationDate: Date;

    effectiveDate = new Date(filing.effectiveOn!);
    expirationDate = new Date(filing.entity?.expirationDate);

    setEffectiveDate(effectiveDate);
    setExpirationDate(expirationDate);

    let draft: Draft = await draftAPI.getDraft(filing.draftId!);

    const newDraftData: IDraft = {
      id: draft.id,
      title: draft.draftName,
      chapterNum: filing.chapterNumber
    }

    setDraftData(newDraftData);
  }

  useEffect(() => {

    onMount();

  }, [])

  return (
    <div>
      <DraftPreviewModal open={modalOpen} draftData={draftData} close={openEmergencyModal} effectiveDate={effectiveDate} expirationDate ={expirationDate} isEmergency={true} />
      <Tooltip title={"Active Emergency Rule"} placement="top">
        <Button
          className="emergencyButton"
          onClick={openEmergencyModal}
        >
          <LocalHospital />
        </Button>
      </Tooltip>
    </div>
  );
};
