import { Button } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Action, FilingActionRequest } from "../../../../utils/model";
import { Icon } from "../../../../components/";
import "./ActionTab.scss";
import { actionAPI, functionAPI, filingAPI, codificationAPI, ruleAPI } from "../../../../utils/api";
import { ActionTypes, FilingTypes, StatusTypes, WorkflowActionResults } from "../../../../utils/enums";
import { Work } from "@material-ui/icons";

interface IActionButtonProps {
  action: Action;
  filing: any | null | undefined;
  triggerReload: () => void;
  setTargetStatus: (targetStatusId: number) => void;
  setActionTypeId: (actionTypeId: any) => void;
  rerenderActions: () => void;
  setCancelFilingSnackVisibility: (isVisible: boolean) => void;
  setWorkflowActionResultSnackVisibility: (isVisible: boolean) => void;
  setWorkflowActionResultMessage: (msg: string | undefined) => void;
}

export let ActionButton = React.forwardRef((props: IActionButtonProps, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {  }, [props.action]);

  const triggerAction = async () => {
    setIsLoading(true);
    if(props.action.id != null) {
      let _filing = await filingAPI.getFilingsById(props.filing.id!);
      let statusTypeId = _filing.status?.statusTypeId;

      if  (props.action.actionTypeId == ActionTypes.Reject) {
        props.setActionTypeId(ActionTypes.Reject);
        return;
      }
      else if (props.action.actionTypeId == ActionTypes.Rework && statusTypeId != StatusTypes.Reworked) {
        props.setActionTypeId(ActionTypes.Rework);
        return;
      }
      else if (props.action.actionTypeId === ActionTypes.SubmitForAgencyReview && statusTypeId === StatusTypes.OarReview) {
        props.setActionTypeId(ActionTypes.SubmitForAgencyReview);
        return;
      }
      else if (props.action.actionTypeId == ActionTypes.Cancel) {
        props.setCancelFilingSnackVisibility(true);
        return;
      }

      // Create Adopted Rule Document when applicable
      // 1. Filing type needs to be:
      //      a. Adopted Emergency
      //      b. Adopted Permanent
      //      c. Adopted Preemptive
      // 2. Action needs to be submission or resubmission to OAR for review
      var isValidAction = props.action.actionTypeId == ActionTypes.SubmitForOarReview || props.action.actionTypeId == ActionTypes.Submit;
      var isValidFilingType = _filing.filingTypeId == FilingTypes.Adopted_EME ||
                              _filing.filingTypeId == FilingTypes.Adopted_PRE ||
                              _filing.filingTypeId == FilingTypes.Adopted_PERM;

      if(isValidFilingType && isValidAction) {
        await ruleAPI.createAdoptedRuleDocument(props.filing.id!);
      }

      let actionRequest = new FilingActionRequest(props.action.codeAction, props.filing.id, props.action.id);
      let result = await actionAPI.updateFilingWorkflowAction(actionRequest);

      if(result == WorkflowActionResults.FailedToIdentifyTargetRegister.toString()) {
        props.setWorkflowActionResultSnackVisibility(true);
        props.setWorkflowActionResultMessage("Error: No register could be identified for the filing.");
        return;
      }

      if (props.filing.filingTypeId == FilingTypes.Misc && props.action.name === "Codify") {
        await codificationAPI.codifyFiling(props.filing.id, props.action.actionTypeId);
      }

      props.setTargetStatus(props.action.targetStatusId!);
      props.setActionTypeId(props.action.actionTypeId);
      props.rerenderActions();
      props.triggerReload();
      setIsLoading(false);
    }
  }

  return (
    <Fragment>
        <Button
            variant="contained"
            color="primary"
            size="large"
            className="action-button"
            startIcon={<Icon icon={props.action.actionType ? props.action.actionType.icon : "build"}></Icon>}
            onClick={triggerAction}
            disabled={(props.filing.isReadOnly ?? false) || isLoading}
        >
            <div className="action-description">
                <div>{props.action.name}</div>
                <div>{props.action.description}</div>
            </div>
        </Button>
    </Fragment>
  );
});
