import * as React from "react";
import { EmailTable } from "../../../../../utils/model";
// import { Column } from "material-table";
// import { PublicationStatus } from "../../../../../utils/enums";
// import { UrlParser } from "../../../../../utils/urlParser";
// import { Link } from "react-router-dom";

// #######################################################
// Set Initial columns for dynamic column generation
// #######################################################
let columns: any = [];

// #######################################################
// Set Contact Types and create columns
// #######################################################
export const setEmailTypeRows = (
  rows: any,
  name: string,
  updateRows: () => void
) => {

  if (rows[0] !== undefined && rows) {
    const emailTable: EmailTable = new EmailTable(
      rows[0].id,
      rows[0].name,
      rows[0].subject,
      rows[0].body,
      rows[0].ruleFiling
      // rows[0].ToType
    );

    columns = [];
    const keys = Object.keys(emailTable);
    keys.forEach((key, index) => {
      // Regular expression takes table name and converts formatting
      var text = key.replace(/([A-Z])/g, " $1");
      var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);
      const column: any = {};
      column.title = capitalizeText;
      let lookup: Map<string, string> = new Map<string, string>();

      // console.log("Column title: ", column.title);
      switch (column.title) {
        case "Id":
          column.title = "Id";
          column.render = (row: any, type: any) => {
            // console.log("rendered row: ", row.name);
            return row.id;
          };
          break;
        case "Name":
          column.title = "Name";
          column.render = (row: any, type: any) => {
            // console.log("rendered row: ", row.name);
            return row.name;
          };
          break;
        case "Subject":
          column.render = (row: any, type: any) => {
            return row.subject;
          };
          break;
        case "Body":
          column.render = (row: any, type: any) => {
            return row.body;
          };
          break;
        case "Rule Filing":
          column.render = (row: any, type: any) => {
            return row.ruleFiling;
          };
          break;
        // case "To Type":
        //   column.render = (row, type) => {
        //     return row.ToType;
        //   };
        //   break;
        default:
          break;
      }

      column.field = key;
      columns.push(column);
    });
  }
  const StatusIconColumn: any = {
    title: "Status",
    // render: (row, type) => <StatusIcon status={row.statusId} />,
    render: (row: any, type: any) => <div></div>,
  };
  const ActionIconColumn: any = {};

  return { rows: rows, columns: columns };
};
