import { Identifiable } from ".";

export class ContactType extends Identifiable {
    constructor(name: string, type: string) {
        super(null);
        this.name = name;
        this.type = type;
    }

    public name: string;
    public type: string;
}