import React, { useEffect } from "react";
import "./FilingBuilder.scss";
import { FilingWrapper } from "./components/FilingWrapper";
import { IStateTabComponentProps } from "../IStateTabComponentProps";
import { filingTypeAPI } from "../../../utils/api/FilingTypeApi";
import { FilingType } from "../../../utils/model/FilingType";

interface IAgencyAdminProps extends IStateTabComponentProps {}

export const FilingBuilder = (props: IAgencyAdminProps) => {
  const [filingTypes, setFilingTypes] = React.useState<FilingType[]>([]);

  const onMount = React.useCallback(async () => {
    // NOTE WE NEED TO GET ALL FILINGS FOR THIS TO WORK
    let pagingParameters = { pageNumber: 1, pageSize: 50 };
    let filingTypes: any = await filingTypeAPI.getAllFilingTypes(
      pagingParameters
    );
    setFilingTypes(filingTypes);
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className="filing-builder">
      <FilingWrapper {...props} filingTypes={filingTypes} />
    </div>
  );
};
