export class SegmentNote {
    constructor(
        segmentId: number,
        noteTypeId: number,
        note:string,
        addedOn?: Date,
        id?: number,
    ) {
        this.segmentId = segmentId
        this.noteTypeId = noteTypeId;
        this.note = note;

        if(id) {
            this.id = id;
        }
        if(addedOn) {
            this.addedOn = addedOn;
        }
    }

    public segmentId: number;
    public noteTypeId: number;
    public note: string;
    public addedOn?: Date;
    public id?: number;
}