import React from 'react'
import { Menu } from '../../../components/Menu'
import { getOS } from '../../../utils/UserOS';
import { useProposedRulesController } from './ProposedRulesController';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import Person from '@material-ui/icons/Person'
import "./ProposedRules.scss"
import { DraftPreviewModal } from '../../../stateOffice/pages/WorkQueue/components/DraftPreviewModal';
import { AlertDialog } from '../../../components';
import { Link } from 'react-router-dom';


const ProposedRules = () => {

  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  const {
    // variables
    proposedRuleFilings,
    dialogOpen,
    proposedRuleData,
    alertMessage,
    alertOpen,
    // functions
    toggleDialog,
    toggleAlertDialog,
    emailLiaison,
  } = useProposedRulesController();

  return (
    <div className="proposed-rules" id="Home">
      <a id="skip-nav" className="skiplink-text" href="#main-content">
        Skip to Main Content
      </a>
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      <main>
        <div className="proposed-rule-body">
          <Dialog
            open={alertOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="alert-dialog"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {alertMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button component={Link} to={'quicklinks'}>Go To Quicklinks Page</Button>
              <Button onClick={toggleAlertDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          {proposedRuleData ? (
            <DraftPreviewModal open={dialogOpen} close={toggleDialog} draftData={proposedRuleData} />
          ) : null}
          <h1 className="proposed-rules-title">Proposed Rules Open For Public Comment</h1>
          <hr className="underline" />
          {
            proposedRuleFilings
              ? proposedRuleFilings.map((filing: any, index: number) => {
                return (
                  <div className="proposed-rule-item" key={filing.id + index}>
                    {/* <Divider /> */}
                    <div>
                      <Button
                        variant="text"
                        color="default"
                        onClick={() => toggleDialog(filing.draftId)}
                        startIcon={<DescriptionIcon />}
                      >
                        View Proposed Rule
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => emailLiaison(filing)}
                        startIcon={<Person />}
                      >
                        Leave Public Comment
                      </Button>
                    </div>
                    <div>
                      <b>Title {(filing.entity.rule.referenceCode ?? '')}.</b> {filing.entity.rule.title}
                    </div>
                    <div>
                      <b>Chapter {(filing.entity.chapter.chapterNum ?? '')}.</b> {filing.entity.chapter.description ?? ''}
                    </div>
                    <div>
                      <b>Comment Start Date: </b> {new Date(filing.entity.commentBeginDate).toLocaleDateString() ?? ''}
                    </div>
                    <div>
                      <b>Comment End Date: </b> {new Date(filing.entity.commentEndDate).toLocaleDateString() ?? ''}
                    </div>
                    <div>
                      <b>Notice Type: </b> {filing.entity.rulemakingAction}
                    </div>
                  </div>
                );
              })
              : null
          }
        </div>
      </main>
    </div>
  )
}

export default ProposedRules