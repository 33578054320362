import * as React from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./FilingTable.scss"
import { CircularProgress, Tooltip } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { FilingAddForm } from '../FilingAddForm';
import { UrlParser } from '../../../../../utils';
import { IStateTabComponentProps } from '../../../IStateTabComponentProps';
import { PublicationStatus } from '../../../../../utils/enums';
import { Switch } from '@progress/kendo-react-inputs';


interface IFilingTableTempProps extends RouteComponentProps, IStateTabComponentProps {
  rows: any;
  columns: any;
  // numRows: number;
  filingType: any;
  add?: () => void;
  // paging?: (page: any) => any;
  height?: string;
  tableName?: string;
  updateRows: (archived?: boolean) => void;
  userTitleAccess: any;
  userRoleAccess: any;
  loading?: boolean;
  updateArchived: (isArchived: boolean) => void;
  archived?: boolean;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

const FilingTableTempWithRouter = (props: IFilingTableTempProps) => {

  const [height, setHeight] = React.useState<any>("75vh");
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );

  const onMount = React.useCallback(async () => {
    if (props.height) {
      setHeight(props.height);
    }
    setDataResult(process(props.rows, dataState));

  }, [props.rows, props.columns])

  React.useEffect(() => {
    onMount();
  }, [onMount])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  const closeAddDialog = () => {
    setOpen(false);
    props.updateRows();
  }

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;

    setDataResult({ ...dataResult });
  };

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    if (_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const toggleArchivedView = async (event: any) => {
    let archivedStatus: boolean = event.target.value;
    props.updateArchived(archivedStatus);
  }

  const docketColumn = (data: GridCellProps) => {
    // console.log("DATAITEM", data.dataItem)
    if (data) {
      return (
        <td className="" role="gridcell">
          {data.dataItem.entity ? data.dataItem.entity?.docketNumber : "No Docket Found"}
        </td>
      )
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  }

  const titleColumn = (data: GridCellProps) => {
    // console.log("DATAITEM", data.dataItem)
    if (data) {
      return (
        <td className="" role="gridcell">
          {data.dataItem.entity.rule ? +data.dataItem.entity.rule.referenceCode : ""}
        </td>
      )
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  }

  const navigateToPage = (filing: any) => {
    // console.log(filing);
    props.history.push(UrlParser.Filing.parseFiling(props.filingType.name, filing.id!));
    let url = UrlParser.Filing.parseFiling(props.filingType.name, filing.id!);
    return url;
  }

  const pageChange = async (event: GridPageChangeEvent) => {
    // if (props.paging) {
    // let newRecords = await props.paging(event.page);
    setDataState(event.page);
    setDataResult(process(props.rows, event.page));
    // }
  }

  const hyperLinkColumn = (data: GridCellProps) => {
    // console.log("DATAITEM", data.dataItem)
    if (data) {
      return (
        <td className="newIcon" role="gridcell">
          <a onClick={() => navigateToPage(data.dataItem)} className="filing-link">
            {data.dataItem.name}
          </a>
        </td>
      );
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  }

  const publicationStatusColumn = (data: GridCellProps) => {
    if (data) {
      return (
        <td className="" role="gridcell">
          {PublicationStatus[data.dataItem.published]}
        </td>
      );
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  };

  const statusColumn = (data: GridCellProps) => {
    if (data) {
      return (
        <td role="gridcell">
          {/* {data.dataItem.status.name} */}
        </td>
      );
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  }

  const createdColumn = (data: GridCellProps) => {
    if (data) {
      return (
        <td role="gridcell">
          {new Date(data.dataItem.created).toLocaleDateString()}
        </td>
      );
    } else {
      return (
        <td className="" role="gridcell">

        </td>
      );
    }
  }

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element: any, i: number) => {
      if (element.field == "id") {
        return (<GridColumn field={element.field} key={element.id} title={element.title} />);
      }
    });

    columnsToShow.push(<GridColumn field={"Docket"} title="Docket Number" cell={docketColumn} />);
    columnsToShow.push(<GridColumn field={"Title"} title="Title" cell={titleColumn} filter="numeric" />);
    columnsToShow.push(<GridColumn field={"publicationStatus"} title="Publication Status" cell={publicationStatusColumn} />);
    columnsToShow.push(<GridColumn field={"dDateCreated"} title="Date Created" cell={createdColumn} />);
    columnsToShow.unshift(<GridColumn field={"Link"} title="Filing Name" cell={hyperLinkColumn} filterable={false} />);

    // if(columnsToShow.length > 0 && props.rows.length > 0) {
    return (
      <Grid
        key={"FilingTable"}
        style={{
          height: height,
        }}
        sortable={true}
        filterable={true}
        resizable={true}
        groupable={true}
        reorderable={true}
        pageable={{
          buttonCount: 4,
          pageSizes: true,
        }}
        // onPageChange={pageChange}
        data={dataResult}
        {...dataState}
        onDataStateChange={dataStateChange}
        total={dataResult.total}
      // detail={DetailComponent}
      // expandField="expanded"
      // onExpandChange={expandChange}
      >
        <GridToolbar>
          <ButtonGroup className="button-group">
            <Button
              icon="xls"
              title="Export to Excel"
              className="k-button k-secondary button"
              onClick={exportExcel}
            />
            <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
            <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={() => setOpen(true)} />
            <div className="filing-label">
              {props.tableName ? (
                <h3>{props.tableName}</h3>
              ) : null}
            </div>
          </ButtonGroup>
          <ButtonGroup className="right-button-group">
            <>
              <h3 className="toggle-label">Archived View</h3>
              <Switch
                value={props.archived}
                onChange={toggleArchivedView}
              />
            </>
          </ButtonGroup>
        </GridToolbar>
        <GridNoRecords>
          {
            props.loading ? (<div className="center-table-loading"><CircularProgress color="inherit" size={40} /> Loading Data</div>) : "No Records Found"
          }
        </GridNoRecords>
        {columnsToShow}
      </Grid>
    );
    // } else {
    //   return null;
    // }
  }

  return (
    <div className="work-queue">
      <ExcelExport
        data={props.rows}
        ref={(exporter: any) => {
          _export = exporter;
        }}
      >
        {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
      {open && (
        <FilingAddForm
          userTitleAccess={props.userTitleAccess}
          userRoleAccess={props.userRoleAccess}
          open={open}
          handleClose={closeAddDialog}
          filingType={props.filingType}
          updateRows={props.updateRows}
        />
      )}
    </div>
  );
}

export const FilingTableTemp = withRouter(FilingTableTempWithRouter);
