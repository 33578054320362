import Pagination from "@material-ui/lab/Pagination";
import { getOS } from "../../utils/UserOS";
import React, { useCallback, useEffect, useState } from "react";
import { Authorized } from "../../components";
import { Menu } from "../../components/Menu";
import { PostsAPI } from "../../utils/api/PostsApi";
import { Post } from "../../utils/model/Post";
import "./Blog.scss";
import { CreatePost } from "./components/CreatePost";
import { Posts } from "./components/Posts";

interface IBlogProps {
  post: Post;
}

export const Blog = (props: IBlogProps) => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  const [posts, setPosts] = useState<Post[]>([]);
  const [allPosts, setAllPosts] = useState<Post[]>([]);
  const [postElements, setPostElements] = React.useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const loadData = useCallback(async () => {
    var incomingPosts = await PostsAPI.getAllPosts();
    incomingPosts = incomingPosts.reverse();
    // console.table(incomingPosts);
    let postSubset = incomingPosts.slice((page - 1) * 5, page * 5);
    setPosts(postSubset);
    setAllPosts(incomingPosts);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // const jumpToPost = (id: string) => {
  //   const postElement = document.getElementById(id);
  //   if (postElement) {
  //     postElement.scrollIntoView({
  //       block: "center",
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    let postSubset = allPosts.slice((value - 1) * 5, value * 5);
    setPosts(postSubset);
  };

  return (
    <div className="news" id="news">
      <a id="skip-nav" className="skiplink-text" href="#main-content">
        Skip to Main Content
      </a>
      <header className="app-bar">
        <nav>
          <Menu title={"home"} OS={platform}></Menu>
        </nav>
      </header>
      <main className="news-blog">
        <h1 className="news-title">NEWS</h1>
        <hr className="underline" />
        <Authorized>
          <CreatePost onSave={loadData} />
        </Authorized>
        {posts.map((post) => (
          <Posts
            onUpdate={loadData}
            post={post}
            className="news-post"
            id={`post-${post.id}`}
          />
        ))}
        {postElements ? (
          <Pagination
            className="pagination"
            count={
              allPosts.length % 5 !== 0
                ? Math.floor(allPosts.length / 5) + 1
                : Math.floor(allPosts.length / 5)
            }
            page={page}
            onChange={handleChange}
          />
        ) : null}
      </main>
    </div>
  );
};
