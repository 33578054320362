import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class ReportsAPI extends BaseAPI<string> {
  constructor() {
    super("reports", APISettings.adminRulesApiUrl);
  }

  public executeReport: (reportName: string) => Promise<any> = async (
    reportName: string
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}?reportName=${reportName}`
    );
    return response.data;
  };

  public RulesRecordReport: (
    title: any,
    chapter: any,
    ruleType: string | null,
    startDate: Date,
    endDate: any,
    reportFormat: "csv" | "pdf"
  ) => Promise<any> = async (
    title: any,
    chapter: any,
    ruleType: string | null,
    startDate: Date,
    endDate: any,
    reportFormat: "csv" | "pdf"
  ) => {
    try {
      if (title.id == 0) {
        title.id = null;
      }
      if (chapter.id == 0) {
        chapter.id = null;
      }
      if (ruleType === "Any") {
        ruleType = null;
      }
      const response = await BaseAPI.axios.get(
        `${this.apiUrl}/GetRulesRecordReport?title=${title.id}&chapter=${
          chapter.id
        }&ruleType=${ruleType}&startDate=${startDate.toDateString()}&endDate=${endDate.toDateString()}&reportFormat=${reportFormat}`,
        { responseType: "blob" }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };

  public ActiveAgencyUserEmailReport: (
    ruleId: any,
    type: "pdf" | "csv"
  ) => Promise<any> = async (ruleId: any, type: "csv" | "pdf") => {
    try {
      if (type === "csv") {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetActiveAgencyUserEmailReportCsv/${ruleId}`
        );
        return response.data;
      } else if (type === "pdf") {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetActiveAgencyUserEmailReportPdf/${ruleId}`,
          { responseType: "blob" }
        );
        return response.data;
      }
    } catch (error) {
      return null;
    }
  };

  public ActiveLiaisonUserEmailReport: (
    ruleId: any,
    type: "csv" | "pdf"
  ) => Promise<any> = async (ruleId: any, type: "csv" | "pdf") => {
    try {
      if (type === "csv") {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetAgencyLiaisonsEmailReportCsv/${ruleId}`
        );
        return response.data;
      } else if (type === "pdf") {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetAgencyLiaisonsEmailReportPdf/${ruleId}`,
          { responseType: "blob" }
        );
        return response.data;
      }
    } catch (error) {
      return null;
    }
  };

  public RulesLiaisonReport: (
    title: any,
    liaisonStatus: any,
    includeLiaison: boolean,
    reportFormat: "csv" | "pdf"
  ) => Promise<any> = async (
    title: any,
    liaisonStatus: any,
    includeLiaison: boolean,
    reportFormat: "csv" | "pdf"
  ) => {
    try {
      if (reportFormat === "csv") {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetRulesLiaisonsReportCsv/${
            title.id ?? 0
          }/${includeLiaison}/${liaisonStatus.id ?? 0}`
        );
        return response.data;
      } else {
        const response = await BaseAPI.axios.get(
          `${this.apiUrl}/GetRulesLiaisonsReportPdf/${
            title.id ?? 0
          }/${includeLiaison}/${liaisonStatus.id ?? 0}`,
          { responseType: "blob" }
        );
        return response.data;
      }
    } catch (error) {
      return null;
    }
  };

  public CodeStatisticsReport: (
    startDate: Date,
    endDate: any,
    reportFormat: "csv" | "pdf"
  ) => Promise<any> = async (
    startDate: Date,
    endDate: any,
    reportFormat: "csv" | "pdf"
  ) => {
    try {
      if (reportFormat === "csv") {
        const response = await BaseAPI.axios.get(
          `${
            this.apiUrl
          }/GetCodeStatisticsReportCsv/${startDate.toDateString()}/${endDate.toDateString()}`
        );
        return response.data;
      } else {
        const response = await BaseAPI.axios.get(
          `${
            this.apiUrl
          }/GetCodeStatisticsReportPdf/${startDate.toDateString()}/${endDate.toDateString()}`,
          { responseType: "blob" }
        );
        return response.data;
      }
    } catch (error) {
      return null;
    }
  };
}

export const reportsAPI = new ReportsAPI();
