import { TaskType } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class TaskTypeAPI extends BaseAPI<TaskType> {
  constructor() {
    super("taskType", APISettings.adminRulesApiUrl);
  }

  public getTaskTypesById: (id: number) => Promise<TaskType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetTaskTypesById/${id}`))
      .data;
  };

  public getAllTaskTypes: () => Promise<TaskType[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetTaskTypesById`)).data;
  };

  public createTaskTypes: (taskType: TaskType) => Promise<any> = async (
    taskType: TaskType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateTaskTypes`,
      {
        taskType,
      }
    );
    return response.data;
  };

  public removeTaskTypes: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveTaskTypes/${id}`
    );
    return response.data;
  };

  public updateTaskTypes: (
    id: number,
    taskType: TaskType
  ) => Promise<TaskType> = async (id: number, taskType: TaskType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateTaskTypes/${id}`,
      { taskType }
    );
    return response.data;
  };
}

export const taskTypeAPI = new TaskTypeAPI();
