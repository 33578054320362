import { PostStatus } from "../enums/PostStatus";
import { AuditableEntity } from "./AuditableEntity";

export class Post extends AuditableEntity {
  constructor(text: string, title: string, postStatus: PostStatus) {
    super();
    this.text = text;
    this.title = title;
    this.postStatus = postStatus;
  }
  public text: string;
  public title: string;
  public postStatus: PostStatus;
}
