import * as React from 'react';
import { Grid, GridColumn, GridDataStateChangeEvent, GridNoRecords, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, process } from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
require("@progress/kendo-theme-material");
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import "./CurrentRulesTable.scss"
import { CircularProgress } from '@material-ui/core';


interface ITableProps {
  rows:any;
  columns:any;
  add?: () => void;
  height?: string;
  tableName?: string;
  paging?: (page:any) => any;
  numberOfRecords?: number;
}

interface LocaleInterface {
  language: string;
  locale: string;
}

export const CurrentRulesTable = (props:ITableProps) => {
  const [height, setHeight] = React.useState<any>("75vh");
  const [numberOfRecords,setNumberOfRecords] = React.useState<number>(0);
  const [dataState, setDataState] = React.useState<any>({
    skip: 0,
    take: 20,
    sort: [{ field: "id", dir: "desc" }]
  });
  const [dataResult, setDataResult] = React.useState<DataResult>(
    process(props.rows, dataState)
  );

  const onMount = React.useCallback(async() => {
      if(props.height) {
        setHeight(props.height);
      }

      setDataResult(process(props.rows, dataState));
  },[props.rows, props.columns])


  React.useEffect(() => {
    onMount();
  }, [props.rows,props.height,props.columns])

  React.useMemo(() => {
    setDataState({
      skip: 0,
      take: 20,
      sort: [{ field: "id", dir: "desc" }]
    });
  }, [props.numberOfRecords])

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(props.rows, event.dataState));
    setDataState(event.dataState);
  };

  let _pdfExport: GridPDFExport | null;
  const exportExcel = () => {
    _export.save();
  };

  let _export:any;
  const exportPDF = () => {
    if(_pdfExport !== null) {
      _pdfExport.save();
    }
  };

  const pageChange = async (event: GridPageChangeEvent) => {
    if(props.paging) {
      let newRecords = await props.paging(event.page);
      setDataState(event.page);
      setDataResult(process(props.rows, event.page));
    }
  }

  const _renderTable = () => {
    var allColumns = props.rows.length > 0 ? props.columns : [];

    var columnsToShow = [];
    columnsToShow = allColumns.map((element:any, i:number) => {
      if(element.field != "contactType" && element.field != "recordStatus" && element.field != "status" && element.field != "id") {
        return (<GridColumn field={element.field} key={i} title={element.title}/>);
      }
    });

    if(columnsToShow.length > 0 && props.rows.length > 0) {
      return (
          <Grid
              style={{
                height: height,
              }}
              sortable={true}
              filterable={true}
              resizable={true}
              // groupable={true}
              reorderable={true}
              pageable={{
                buttonCount: 3,
                pageSizes: true,
              }}
              data={props.rows.slice(dataState.skip, dataState.take + dataState.skip)}
              skip={dataState.skip}
              take={dataState.take}
              onPageChange={pageChange}
              {...dataState}
              onDataStateChange={dataStateChange}
              total={props.numberOfRecords ? props.numberOfRecords : 0}
            >
              <GridToolbar>
                <ButtonGroup className="button-group">
                  <Button
                    icon="xls"
                    title="Export to Excel"
                    className="k-button k-secondary button"
                    onClick={exportExcel}
                  />
                  <Button icon="pdf" title="Export to PDF" className="k-button k-secondary button" onClick={exportPDF} />
                  {/* <Button icon="add" className="k-button k-secondary button" title="Add Record" onClick={props.add}/> */}
                </ButtonGroup>
                {props.tableName ? (
                  <h3>{props.tableName}</h3>
                ) : null}
              </GridToolbar>
              <GridNoRecords>
                <CircularProgress />
              </GridNoRecords>
              { columnsToShow }
            </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="table">
      <ExcelExport
            data={props.rows}
            ref={(exporter:any) => {
              _export = exporter;
            }}
          >
             {_renderTable()}
      </ExcelExport>
      <GridPDFExport
        ref={(element) => {
          _pdfExport = element;
        }}
        margin="1cm"
      >
        {_renderTable()}
      </GridPDFExport>
    </div>
  );
}


