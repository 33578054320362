import { PublicationRecordType } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

interface ModelProperties {
  [key: string]: any;
}

class PublicationRecordTypeApi extends BaseAPI<PublicationRecordType> {
  constructor() {
    super("PublicationRecordType", APISettings.adminRulesApiUrl);
  }

  // public getAllTypes: () => Promise<any> = async () => {
  //   const url = `${this.apiUrl}/${this.type}`;
  //   const response = await BaseAPI.axios.get(url);
  //   return response.data;
  // };

  public getPublicationRecordTypeById: (
    id: number
  ) => Promise<PublicationRecordType> = async (id: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetPublicationRecordTypeById/${id}`
      )
    ).data;
  };

  public getAllPublicationRecordTypes: () => Promise<PublicationRecordType[]> =
    async () => {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetAllPublicationRecordTypes`)
      ).data;
    };

  public getAllPublicationRecordTypesByType: (
    typeId: number
  ) => Promise<PublicationRecordType[]> = async (typeId: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetAllPublicationRecordTypesByType?typeId=${typeId}`
      )
    ).data;
  };

  public createPublicationRecordType: (
    publicationRecordType: PublicationRecordType
  ) => Promise<any> = async (publicationRecordType: PublicationRecordType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreatePublicationRecordType`,
      {
        publicationRecordType,
      }
    );
    return response.data;
  };

  public removePublicationRecordType: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemovePublicationRecordType/${id}`
    );
    return response.data;
  };

  public updatePublicationRecordType: (
    id: number,
    publicationRecordType: PublicationRecordType
  ) => Promise<PublicationRecordType> = async (
    id: number,
    publicationRecordType: PublicationRecordType
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdatePublicationRecordType/${id}`,
      { publicationRecordType }
    );
    return response.data;
  };
}

export const PublicationRecordTypeAPI = new PublicationRecordTypeApi();
