import {Subscriber} from './../../model';
import {BaseAPI} from './../internal';
import {APISettings} from './../../settings/APISettings';


class SubscriberAPI extends BaseAPI<Subscriber> {
    constructor() {
        super("subscriber", APISettings.subscriptionsUrl);
    }
}

export const subscriberAPI = new SubscriberAPI();
