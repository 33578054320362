import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@material-ui/core";
import {
  Code as CodeIcon,
  ExpandMore as ExpandMoreIcon,
  History as HistoryIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@material-ui/icons";
import React, { useCallback, useEffect } from "react";
import { Menu } from "../../../components/Menu";
import { ruleAPI } from "../../../utils/api";
import { getOS } from "../../../utils/UserOS";
import { Link, AgencyDisplay } from "./components";
import { APISettings } from "../../../utils/settings/APISettings";
import "./QuickLinks.scss";


/**
 * @description Page Component for Quick Links
 *
 * @component QuickLinks
 * @example
 * return (
 *    <QuickLinks/>
 * )
 */

interface IQuicklinkProps {}

export const QuickLinks = (props: IQuicklinkProps) => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";
  const [expanded, setExpanded] = React.useState("");
  const [agencyList, setAgencyList]: any = React.useState();

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onMount = useCallback(async () => {
    const agencies: any = await ruleAPI.getAllRules({pageNumber: 1, pageSize: 200},false);
    if (agencies) {
      let result: [] = agencies;
      setAgencyList(result);
    }
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className="quicklinks">
      <Container>
        <Menu title={"Home"} OS={platform}></Menu>
        <main id="main-content">
          <h1 className="quicklinks-title">QuickLinks</h1>
          <hr className="underline" />
          <Accordion
            square
            expanded={expanded === "agencyResources"}
            onChange={handleChange("agencyResources")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel1d-content"
              aria-label="Resources for Agencies"
              id="panel1d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Resources for Agencies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="links">
                {/* This first link should redirect to  */}
                  <Link
                    textName="adminRulesLabel"
                    fileName=""
                    hyperlinkOverride={APISettings.webClientUrl + "/code"}
                    readOnly={false}
                  />
                <Link
                  textName="datesAndDeadlinesLabel"
                  fileName="pdfDatesAndDeadlines"
                  readOnly={false}
                />
                <Link
                  textName="schedulesRegisterLabel"
                  fileName=""
                  readOnly={true}
                />

                <ul className="list-item">
                  <Link
                    textName="schedulesAllLabel"
                    fileName="pdfSchedulesAll"
                    readOnly={false}
                  />
                  <Link
                    textName="schedulesPermLabel"
                    fileName="pdfSchedulesPerm"
                    readOnly={false}
                  />
                </ul>
                <Link
                  textName="overviewPermLabel"
                  fileName="pdfOverviewPerm"
                  readOnly={false}
                />
                <Link
                  textName="overviewEmergencyLabel"
                  fileName="pdfOverviewEmergency"
                  readOnly={false}
                />
                <Link
                  textName="checklistPermLabel"
                  fileName="pdfChecklistPerm"
                  readOnly={false}
                />
                <Link
                  textName="checklistEmergencyLabel"
                  fileName="pdfChecklistEmergency"
                  readOnly={false}
                />
                <Link
                  textName="tipsLabel"
                  fileName="pdfTips"
                  readOnly={false}
                />
                <Link
                  textName="formatLabel"
                  fileName="pdfFormat"
                  readOnly={false}
                />
                <Link
                  textName="typingReqLabel"
                  fileName="pdfTypingReq"
                  readOnly={false}
                />
                <Link
                  textName="amendRuleLabel"
                  fileName="pdfAmendRule"
                  readOnly={false}
                />
                <Link
                  textName="suggestionsLabel"
                  fileName="pdfSuggestions"
                  readOnly={false}
                />
                <Link
                  textName="acronymLabel"
                  fileName="pdfAcronym"
                  readOnly={false}
                />
                <Link textName="FAQLabel" fileName="pdfFAQ" readOnly={false} />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "pulicResources"}
            onChange={handleChange("pulicResources")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel2d-content"
              aria-label="Resources for the Public"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Resources for the Public</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="links">
                <Link
                  textName="publicOverviewPermLabel"
                  fileName="pdfPublicOverviewPerm"
                  readOnly={false}
                />
                <Link
                  textName="publicOverviewEmergencyLabel"
                  fileName="pdfPublicOverviewEmergency"
                  readOnly={false}
                />
                <Link
                  textName="publicCitizenParticipationLabel"
                  fileName="pdfPublicCitizenParticipation"
                  readOnly={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "orderForms"}
            onChange={handleChange("orderForms")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel2d-content"
              aria-label="Order Forms"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Order Forms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="links">
                <Link
                  textName="orderFormsForCodeLabel"
                  fileName=""
                  readOnly={true}
                />
                <ul className="list-item">
                  <Link
                    textName="orderForCodePublicationDescLabel"
                    fileName="pdfForCodePublicationDesc"
                    readOnly={false}
                  />
                  <Link
                    textName="orderFormForCodeLabel"
                    fileName="pdfOrderFormForCode"
                    readOnly={false}
                  />
                </ul>
                <Link
                  textName="orderFormsForPublishersLabel"
                  fileName=""
                  readOnly={true}
                />
                <ul className="list-item">
                  <Link
                    textName="orderFormsPublisherAgreementLabel"
                    fileName="pdfOrderFormsPublisherAgreement"
                    readOnly={false}
                  />
                </ul>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "agencyListing"}
            onChange={handleChange("agencyListing")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel2d-content"
              aria-label="Agency Listing"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Agency Listing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* This will be a flat list of Agencies and thier Liaison contact info */}
              <AgencyDisplay agencyList={agencyList} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "ruleOnRules"}
            onChange={handleChange("ruleOnRules")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel2d-content"
              aria-label="Rules on Rules"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Rules on Rules</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="links">
                <Link
                  textName="rulesOnRulesLabel"
                  fileName=""
                  hyperlinkOverride={
                    APISettings.webClientUrl + "/code?q=655"
                  }
                  readOnly={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded === "administrativeProcedures"}
            onChange={handleChange("administrativeProcedures")}
          >
            <AccordionSummary
              className="label"
              aria-controls="panel2d-content"
              aria-label="Administrative Procedures Act"
              id="panel2d-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>Administrative Procedures Act</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="links">
                <Link
                  textName="adminProceduresLabel"
                  fileName=""
                  hyperlinkOverride="https://www.oscn.net/applications/oscn/Index.asp?ftdb=STOKST75&level=1"
                  readOnly={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </main>
      </Container>
    </div>
  );
};
