import {SubscriptionEntry} from './../../model';
import {APISettings} from './../../settings/APISettings';
import {BaseAPI} from './../internal';

//could try underscore if need word separation or camal case
class SubscriptionEntryAPI extends BaseAPI<SubscriptionEntry> {
    constructor() {
        super("subscriptionentry", APISettings.subscriptionsUrl);
    }

    public deleteBySubscriberAndCategory: (model: SubscriptionEntry) => Promise<SubscriptionEntry> = async (model: SubscriptionEntry) => {
        return (await BaseAPI.axios.delete(`${this.apiUrl}/${this.type}`, {
            params: {
                "CategoryId": model.category!.id!,
                "SubscriberId": model.subscriberId
            }
        }))
    };

    // public getDictionary: () => Promise<Map<number,Category>> = async () => {
    //   return (await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`)).data;
    // };

    // public create: (model: Identifiable) => Promise<Identifiable> = async (
    //   model: Identifiable
    // ) => {
    //   // delete model.id;
    //   const response = await BaseAPI.axios.post(
    //     `${this.apiUrl}/${this.type}`,
    //     model
    //   );
    //   return response.data;


}

export const subscriptionEntryAPI = new SubscriptionEntryAPI();