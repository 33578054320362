import { Grid } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { Card } from "@progress/kendo-react-layout";
import saveAs from "file-saver";
import { useState } from "react";
import { CodeModalButton, ContactHyperLink } from ".";
import { CodeModal } from "..";
import { fileAPI } from "../../../../../utils/api";
import { StorageContainer } from "../../../../../utils/enums";
import "./CodeResult.scss";

interface ICodeModalButtonProps {
  segment?: any;
  title?: string | undefined;
  expanded?: string;
  rules?: any;
  email?: string;
}

export const ChildCodeCard = (props: ICodeModalButtonProps) => {
  const [copied, setCopied] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const copyTitleLocation = async () => {
    const el = document.createElement("input");

    let url: any = await fileAPI.copyFileUrl(
      "Title_" + props.title + ".pdf",
      StorageContainer.titlepdf
    );

    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }


  const openCodeModal = () => {
    modalOpen ? setModalOpen(false) : setModalOpen(true);
  }

  const pdfDownload = async () => {

    if (!props.segment) {
      let url: any = await fileAPI.getFileUrl(
        "Title_" + props.title + ".pdf",
        StorageContainer.titlepdf
      );

      let blob: Blob = await fetch(url).then((r) => r.blob());
      const fileURL = URL.createObjectURL(blob);
      saveAs(blob, "Title_" + props.title + ".pdf");
    }
  }

  const htmlDownload = async () => {

    let url: any = await fileAPI.getFileUrl(
      "Title_" + props.title + ".html",
      StorageContainer.TitleHtml
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);
    saveAs(blob, "Title_" + props.title + ".html");
  }

  return (
    <Card
      orientation="horizontal"
      style={{ width: "100%", maxHeight: "375px" }}>
      {props.segment ? (<CodeModal open={modalOpen} title={props.segment.titleNum} onClose={openCodeModal} />) : (<CodeModal open={modalOpen} title={props.title} onClose={openCodeModal} />)}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
          <div>
            <div>
              {props.segment ? (<CodeModalButton type={props.segment.name} title={props.segment.titleNum} segment={props.segment} />) : (<CodeModalButton type={"Title"} title={props.title} segment={props.segment} />)}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div>
            {!props.segment ? (
              <p>
                <Button
                  look="flat"
                  icon="pdf"
                  style={{ color: "red" }}
                  disabled={false}
                  value={props.title}
                  onClick={pdfDownload}
                >
                  Download PDF
                </Button>
                {!props.segment && props.title ? (
                  <Button
                    look="flat" icon="copy" onClick={copyTitleLocation} value={props.title}>
                    {!copied ? "Copy Title Link" : "Copied!"}
                  </Button>
                ) : null}
                <Button
                  look="flat"
                  icon="code"
                  style={{ color: "blue" }}
                  disabled={false}
                  value={props.title}
                  onClick={htmlDownload}
                >
                  Download Html
                </Button>
                {props.email ? (
                  <ContactHyperLink
                    email={props.email}
                  />
                ) : null}
              </p>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};