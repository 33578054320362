import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Identifiable } from ".";
import { EmailGroup, EmailMergeItem } from "./";

export class EmailTemplate extends Identifiable {
  constructor(
    name: string,
    subject: string,
    body: string,
    ruleFiling:string,
    is_Liaison:boolean,
    is_Public:boolean,
    is_ActiveGov:boolean,
    is_Cabinet:boolean,
    is_Oar:boolean,
    is_Legislator:boolean,
    to_Type?:string,
    lastModified?: Date,
  ) {
    super(null);
    this.name = name;
    this.subject = subject;
    this.body = body;
    this.ruleFiling=ruleFiling;
    this.is_Liaison = is_Liaison;
    this.is_Public = is_Public;
    this.is_ActiveGov = is_ActiveGov;
    this.is_Cabinet = is_Cabinet;
    this.is_Oar = is_Oar;
    this.is_Legislator = is_Legislator;

    if(to_Type != null) {
      this.to_Type = to_Type;
    }
    if(lastModified != null) {
      this.lastModified = lastModified;
    }
  }
  public name: string;
  public subject: string;
  public body: string;
  public ruleFiling: string;
  public is_Liaison: boolean;
  public is_Public: boolean;
  public is_ActiveGov: boolean;
  public is_Cabinet: boolean;
  public is_Oar: boolean;
  public is_Legislator: boolean;
  public to_Type?: string;
  public lastModified?: Date;
}
