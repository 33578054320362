import { BaseAPI } from ".";
// import { AxiosRequestConfig } from "axios";
import { APISettings } from "../settings/APISettings";

class FunctionAPI extends BaseAPI<any> {
  constructor() {
    super("file", APISettings.functionUrl);
  }

  public triggerFilingAction: (
    actionName: string,
    filingId: number,
    actionId: number
  ) => Promise<string> = async (
    actionName: string,
    filingId: number,
    actionId: number
  ) => {
    let action = actionName.replace(/\s/g, '');

    try {
      let url = `${APISettings.functionUrl}/${action}?filingId=${filingId}&actionId=${actionId}`;
      await BaseAPI.axios.get(`${url}`);
    } catch (error) {
      console.error(error);
    }

    return("Ok");
  };

  public triggerFunction: (functionName:string) => Promise<string> = async ( functionName:string )  => {
    let url = `${APISettings.functionUrl}/${functionName}`;
    return (await BaseAPI.axios.get(`${url}`))
  }
}

export const functionAPI = new FunctionAPI();
