import React, { Dispatch, SetStateAction } from 'react';
import { VisibilityLevel } from '../enums';
import { Workflow } from '../model';

// SetUp Types
type WorkflowProviderProps = { children: React.ReactNode }

// Setup Contexts for setting(dispatch) and getting(State) our current Workflow
const initalWorkflow = new Workflow("loading",VisibilityLevel.Admin);
const WorkflowStateContext = React.createContext<Workflow>(initalWorkflow);
const WorkflowDispatchContext = React.createContext<Dispatch<SetStateAction<Workflow>>>(() => { });

const WorkflowProvider = ({ children }: WorkflowProviderProps) => {
  // Create state to be used in our context
  // Create a set state method to be used in Dispatch context
  const [state, setState] = React.useState<Workflow>(initalWorkflow);
  return (
    <WorkflowStateContext.Provider value={state}>
      <WorkflowDispatchContext.Provider value={setState}>
        {children}
      </WorkflowDispatchContext.Provider>
    </WorkflowStateContext.Provider>
  )
}

const useWorkflowState = () => {
  const context = React.useContext(WorkflowStateContext)
  if (context === undefined) {
    throw new Error('useNavigationState must be used within a NavigationProvider');
  }
  return context
}

const useWorkflowDispatch = () => {
  const context = React.useContext(WorkflowDispatchContext)
  if (context === undefined) {
    throw new Error('useWorkflowDispatch must be used within a WorkflowProvider');
  }
  return context
}



export { WorkflowProvider, useWorkflowState, useWorkflowDispatch };
