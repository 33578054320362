import React from "react";
import "./WorkQueueNews.scss";
import { Card, Grow, Paper, Typography } from "@material-ui/core";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { TextWrapper } from "../../../../../components/TextWrapper";
import { EditorType } from "../../../../../utils/enums";
// import Typography from "material-ui/styles/typography";

interface IWorkQueueNewsProps {}

export const WorkQueueNews = (props: IWorkQueueNewsProps) => {
  return (
    <Grow in={true}>
      <Paper id="state-news" className="display-pane-left">
          <Typography>News</Typography>
          <Card className="my-work-info">
            <TextWrapper
              editorType={EditorType.paragraph}
              name="myWorkNews"
              className="mywork-news"
            />
          </Card>
      </Paper>
    </Grow>
  );
};
