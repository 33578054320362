import { Rule } from "../model";
import { APISettings } from "../settings/APISettings";
import { BaseAPI } from "./internal";

class ReportExecutionDate extends BaseAPI<Rule> {
  constructor() {
    super("reportExecutionDate", APISettings.adminRulesApiUrl);
  }

  public getReportExecutionDatesById: (
    id: number
  ) => Promise<ReportExecutionDate> = async (id: number) => {
    return (
      await BaseAPI.axios.get(
        `${this.apiUrl}/GetReportExecutionDatesById/${id}`
      )
    ).data;
  };

  public createReportExecutionDates: (
    reportExecutionDate: ReportExecutionDate
  ) => Promise<any> = async (reportExecutionDate: ReportExecutionDate) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateReportExecutionDates`,
      {
        reportExecutionDate,
      }
    );
    return response.data;
  };

  public removeReportExecutionDates: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveReportExecutionDates/${id}`
    );
    return response.data;
  };

  public updateReportExecutionDates: (
    id: number,
    reportExecutionDate: ReportExecutionDate
  ) => Promise<ReportExecutionDate> = async (
    id: number,
    reportExecutionDate: ReportExecutionDate
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateReportExecutionDates/${id}`,
      { reportExecutionDate }
    );
    return response.data;
  };
}

export const reportExecutionDate = new ReportExecutionDate();
