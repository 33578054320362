import { DraftPreviewModal } from "../../../../stateOffice/pages/WorkQueue/components/DraftPreviewModal";
import { Button } from "@material-ui/core";

interface IDraftTabProps {
    open: boolean;
    handleOpen: () => void;
    draftData: any;
}

export const DraftTab = (props:IDraftTabProps) => {
  return (
    <div className="draft-tab">
      <Button
              variant="contained"
              size="small"
              className="draft-button"
              onClick={props.handleOpen}
            >
              Preview Draft
            </Button>
      <DraftPreviewModal open={props.open} close={props.handleOpen} draftData={props.draftData} />
    </div>
  );
};
