// UI imports
import "./SubscriptionProfile.scss";
import {
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
// components
import { Menu } from "../../../components/Menu";
import Link from '@material-ui/core/Link';
import SubscriptionDetails from "./components/SubscriptionDetails/SubscriptionDetails";
import UserAccountDetails from "./components/UserAccountDetails/UserAccountDetails";
// helpers
import { getOS } from "../../../utils/UserOS";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSubscriptionProfileController } from "./SubscriptionProfileController";

/**
 * @description subscription profile page
 * @returns React.TSX
 */
export const SubscriptionProfile = () => {
  const os = getOS();
  const platform = os !== "iOS" && os !== "Android" ? "desktop" : "mobile";

  const getSessionStorage = sessionStorage.getItem("subscriptionUser");
  const userInfo = getSessionStorage ? JSON.parse(getSessionStorage) : null;

  const {
    // data
    alertMessage,
    alertType,
    alertOpen,
    // functions
    toggleAlert,
    setMessage,
  } = useSubscriptionProfileController();

  return (
    <div className="chapters-results">
      <header className="app-bar">
        <nav>
          <Menu title={"Home"} OS={platform}></Menu>
        </nav>
      </header>
      {userInfo ? (
        <div className="body rule-management">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={toggleAlert}
          >
            <Alert
              elevation={6}
              variant="filled"
              severity={alertType === "success" ? "success" : "warning"}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <div className="subscription-profile-header">
            <Card
              style={{
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                marginTop: "15px",
              }}
            >
              <CardHeader
                style={{
                  background: "transparent",
                }}
              >
                <CardTitle>
                  <h2>Subscriptions User Profile</h2>
                </CardTitle>
                <hr />
                <CardSubtitle
                  style={{
                    color: "cornflowerblue",
                  }}
                >
                  <p>
                    Thank you for creating an account!
                    <br />
                    To subscribe to receive email notifications about specific
                    rule content, please
                    <Link href="/code" className="code-link">
                      click here
                    </Link>
                    to browse and select your
                    areas of interest by Agency.
                  </p>
                </CardSubtitle>
              </CardHeader>
            </Card>
          </div>

          <div className="account-details">
            {/* Subscription profile */}
            <div className="subscription-profile">
              <UserAccountDetails userInfo={userInfo} toggleAlert={toggleAlert} setAlertMessage={setMessage} />
            </div>

            {/* Subscription details */}
            <div className="subscription-details">
              <SubscriptionDetails userInfo={userInfo} />
            </div>
          </div>
        </div>
      ) : (<div>Please create a subscription profile</div>)}
    </div >
  );
};
