import { Task } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class TaskAPI extends BaseAPI<Task> {
  constructor() {
    super("task", APISettings.adminRulesApiUrl);
  }

  public getTasksById: (id: number) => Promise<Task> = async (id: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetTasksById/${id}`)).data;
  };

  public getAllTasks: () => Promise<Task[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllTasks`)).data;
  };

  public createTasks: (task: Task) => Promise<any> = async (task: Task) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateTasks`, {
      task,
    });
    return response.data;
  };

  public removeTasks: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveTasks/${id}`
    );
    return response.data;
  };

  public updateTasks: (id: number, task: Task) => Promise<Task> = async (
    id: number,
    task: Task
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateTasks/${id}`,
      { task }
    );
    return response.data;
  };
}

export const taskAPI = new TaskAPI();
