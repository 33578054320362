import { APISettings } from "../settings/APISettings";
import { BaseAPI } from "./internal";

interface PartialUser {
  profile: {
    firstName: string;
    lastName: string;
    photo: string;
  };
}

class UserInfoAPI extends BaseAPI<PartialUser> {
  constructor() {
    super("userInformation", `${APISettings.identityServerUrl}`);
  }
  getAvatarInfo: (id: string) => Promise<PartialUser> = async (id: string) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/${this.type}/${id}`)).data;
  };
  get: () => Promise<PartialUser> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  create: () => Promise<PartialUser> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  update: () => Promise<PartialUser> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
  delete: () => Promise<PartialUser> = async () => {
    throw new Error("This API is not Defined for filingHistory.");
  };
}

export const userInfoAPI = new UserInfoAPI();
