import React from "react";
import "./ActionDisplayRouter.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useEffect } from "react";
import { UrlParser } from "../../utils";
import { useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { DialogTitleWithExit } from "../DialogTitleWithExit";

interface IActionDisplayRouterProps extends RouteComponentProps {}

const ActionDisplayRouterWithRouter = (props: IActionDisplayRouterProps) => {
  const [show, setShow] = useState<boolean>(false);

  const setupUrl = React.useCallback(() => {
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    const isValidFilingAction: boolean =
      queryInfo.filingtype != null &&
      queryInfo.filingid != null &&
      queryInfo.actionid != null;
    // Check to see if it is a valid action from the api
    // Check to see if that action is registered with a front end component
    if (isValidFilingAction) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [props.location.search]);

  useEffect(() => {
    setupUrl();
  }, [setupUrl]);

  useEffect(() => {
    setupUrl();
  }, [setupUrl, props.location.search]);

  const closeAction = () => {
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    if (queryInfo.filingtype != null && queryInfo.filingid != null) {
      props.history.push(
        UrlParser.Filing.parseFiling(queryInfo.filingtype, queryInfo.filingid)
      );
      setShow(false);
    }
  };

  return (
    <Dialog
      className="action-display-router"
      fullWidth
      disableEnforceFocus
      onClose={closeAction}
      aria-labelledby="action-display-router"
      open={show}
    >
      <DialogTitleWithExit onClose={closeAction} />
      <DialogContent dividers></DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export const ActionDisplayRouter = withRouter(ActionDisplayRouterWithRouter);
