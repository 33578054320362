import axios, { AxiosStatic } from "axios";
// import { request } from "http";
// import { AuthenticationService } from '../authentication/AuthenticationService';
import { APISettings } from "../settings/APISettings";
import {
  // AuthenticationSettings,
  StaticAuthenticationSettings,
} from "../settings/AuthenticationSettings";

class AuthenticationSession {
  constructor(access_token: string, id_token: string, subscription: string) {
    this.access_token = access_token;
    this.id_token = id_token;
  }
  access_token: string;
  id_token: string;
}
const getAuthSession = (): AuthenticationSession | null => {
  const item = localStorage.getItem(
    `oidc.user:${APISettings.identityServerUrl}:${StaticAuthenticationSettings.client_id}`
  );
  if (item != null) {
    return JSON.parse(item) as AuthenticationSession;
  } else return null;
};

const getBearerToken = () => {
  const session = getAuthSession();
  if (session !== null) {
    return session.access_token;
  } else {
    return null;
  }
};

export class BaseAPI<Identifiable, IdentifiableQuery = any> {
  constructor(type: string, apiUrl?: string) {
    if (apiUrl) {
      this.apiUrl = apiUrl;
    } else {
      this.apiUrl = APISettings.adminRulesApiUrl;
    }
    this.type = type;
    BaseAPI.axios = this.configureAxiosInterceptors();
  }

  // Configure auto renewal of tokens for 401 errors or if a user is not found.
  private configureAxiosInterceptors = () => {
    axios.interceptors.request.use((request) => {
      const Bearer = getBearerToken();
      if (Bearer !== null) {
        request.headers["Authorization"] = `Bearer ${Bearer}`;
      }
      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        switch (error?.response?.status) {
          case 401:
            // this.authService.renewToken();
            // Create a toast message
            return Promise.reject(new Error("No user or user token found"));
          default:
            return Promise.reject(error);
        }
      }
    );
    return axios;
  };

  public static axios: AxiosStatic;
  // private authService: AuthenticationService = new AuthenticationService();
  public apiUrl: string;
  public type: string = "";
  public get: (id: number) => Promise<Identifiable> = async (id: number) => {
    return await axios.get(`${this.apiUrl}/${this.type}/${id}`);
  };
  public getAll: (
    model?: Partial<IdentifiableQuery>
  ) => Promise<Identifiable[]> = async (model?: Partial<IdentifiableQuery>) => {
    const url = `${this.apiUrl}/${this.type}`;
    try {
      if (model) {
        let queryString = "";
        let count = 0;
        for (const property in model) {
          if (count === 0) {
            queryString = `?${property}=${model[property]}`;
          } else {
            queryString += `&${property}=${model[property]}`;
          }
          count++;
        }
        const response = await BaseAPI.axios.get(`${url}${queryString}`);
        let list: Identifiable[] = [];
        if (Array.isArray(response.data)) {
          list = response.data;
        }
        return list;
      } else {
        const response = await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`);
        return response.data;
      }
    } catch (err:any) {
      console.error(
        "%c[GETALL] FETCH ERROR ->",
        "background: black; color: red; font-size: 11px",
        url,
        err
      );
      throw new Error(err);
    }
  };

  public create: (model: Identifiable) => Promise<Identifiable> = async (
    model: Identifiable
  ) => {
    // delete model.id;
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}`,
      model
    );
    return response.data;
  };

  public update: (id: number, model: Identifiable) => Promise<Identifiable> =
    async (id: number, model: Identifiable) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/${this.type}/${id}`,
        model
      );
      return response.data;
    };

  public delete: (id: number) => Promise<Identifiable> = async (id: number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/${this.type}/${id}`
    );
    return response.data;
  };
}
