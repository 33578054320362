import { Comment } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class CommentAPI extends BaseAPI<Comment> {
  constructor() {
    super("comment", APISettings.adminRulesApiUrl);
  }

  public addComment: (
    comment: Comment,
    name: string,
    id: number
  ) => Promise<any> = async (comment: Comment, name: string, id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/${this.type}/${name}/${id}`,
      comment
    );
    return response.data;
  };

  public getCommentsById: (id: number) => Promise<Comment> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCommentsById/${id}`))
      .data;
  };

  public getCommentsByThreadId: (id: number) => Promise<Comment> = async (
    id: number
  ) => {
    try {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetCommentsByThreadId/${id}`)
      ).data;
    } catch (error) {
      return [];
    }
  };

  public getCommentsListById: (id: number) => Promise<Comment> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCommentsById/${id}`))
      .data;
  };

  public createComment: (comment: Comment) => Promise<any> = async (
    comment: Comment
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/CreateComment`,
      comment
    );
    return response.data;
  };

  public removeComment: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveComments/${id}`
    );
    return response.data;
  };

  public updateComments: (id: number, comment: Comment) => Promise<Comment> =
    async (id: number, comment: Comment) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/UpdateComments/${id}`,
        comment
      );
      return response.data;
    };
}

export const commentAPI = new CommentAPI();
