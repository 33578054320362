import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FilingSubTab } from "..";
import { ActionDisplayRouter, FilingHome } from "../../../../../components";
import { UrlParser } from "../../../../../utils";
import { filingAPI } from "../../../../../utils/api";
import { PrivateRoute } from "../../../../../utils/authentication";
import { FilingType } from "../../../../../utils/model/FilingType";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { FilingTab } from "../FilingTab/";
import { FilingTableTemp } from "../FilingTable/";
import { setFilingTypeRows } from "../FilingTable/TableSetup";
import "./FilingWrapper.scss";
import { AuthenticationContext } from "../../../../../utils/authentication/AuthenticationProvider";


interface IAgencyAdminProps extends RouteComponentProps, IStateTabComponentProps {
  filingTypes: FilingType[];
}

export interface FormImperativeHandles {
  onMount(): any;
}

const FilingWrapperWithRouter = (props: IAgencyAdminProps) => {
  const [rows, setRowData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [numRows, setNumRows] = React.useState(50);
  const [reloaded, setReloaded] = React.useState(false);
  const [user, setUser] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [archived, setArchived] = React.useState<boolean>(false);


  // passes in selected or default filingType to sub list of filing types
  // Note: You also need to add a if condition to the FilingTab component to maintain the parent tab being selected
  let filingType = React.useMemo(() => {
    setReloaded(true);
    if (props.filingTypes) {
      const queryInfo = UrlParser.Filing.getParameters(props.location.search);

      if (queryInfo.filingtype != "Adopted" && queryInfo.filingtype != "Notice") {
        return (
          props.filingTypes.find(
            (filingType) => filingType.name === queryInfo.filingtype
          ) || props.filingTypes[0]
        );
      } else if (queryInfo.filingtype == "Adopted") {
        let foundPreemptive = props.filingTypes.find((filingType) => filingType.name === "AdoptedPreemptive");
        let foundPermanant = props.filingTypes.find((filingType) => filingType.name === "Adoption-PERM");
        let foundEmergency = props.filingTypes.find((filingType) => filingType.name === "AdoptionOfEmergency");
        return foundPreemptive || foundPermanant || foundEmergency;
      } else if (queryInfo.filingtype == "Notice") {
        return props.filingTypes.find((filingType) => filingType.name === "Notice-NRI");
      }
    }
  }, [props.filingTypes, props.location.search]);

  const updateRows = async () => {
    setLoading(true);
    if (filingType) {
      setRowData([]);
      setColumns([]);
      setNumRows(0);
      let vals: any = null;
      let filings: any = await filingAPI.getFilingsByType(filingType.id! ?? 2, { pageNumber: 1, pageSize: 20 }, archived);
      vals = setFilingTypeRows(
        filings,
        filingType.name,
        updateRows
      );

      let rowCount = 0;
      try {
        if (filingType) {
          // rowCount = await filingAPI.getFilingCountByType(filingType.id! ?? 2);
          rowCount = rows.length;
        } else {
          rowCount = 0;
        }
      } catch (error) {
        console.warn("No records found for filing type");
      }

      let currentData: any = [];
      currentData.fill({}, 0, rowCount);
      vals.rows.forEach((element: any, i: number) => {
        currentData[i] = element;
      })
      // console.log("Current Data; ", currentData)
      setRowData(currentData);
      setColumns(vals.columns);
      setNumRows(rowCount);
    }
    setLoading(false);
  };

  const { getUser } = useContext(AuthenticationContext);

  const getAuthentication = async () => {
    let authorized = await getUser();

    // updateEditStatus(authorized?.profile);
    return authorized?.profile;
  };

  const onMount = async () => {
    setLoading(true);
    if (!user) {
      // console.log("User not set, setting user:");
      setUser(await getAuthentication());
    } else {
      // console.log("User set, titles allowed:", user.Titles);
    }

    const queryInfo = props.location.search

    if (!queryInfo.includes("&filingId=")) {
      if (props.filingTypes && filingType) {
        if (filingType!.id === 1 || filingType!.id === 2) {
          props.history.push(UrlParser.Filing.parseFilingType("Notice-NRI"));
        }
        if (queryInfo.includes("filingtype=Adopted")) {
          if (!queryInfo.includes("Preemptive")) {
            props.history.push(UrlParser.Filing.parseFilingType("Adoption-PERM"));
          }
        }
      }
    }

    if (filingType) {
      setRowData([]);
      setColumns([]);
      setNumRows(0);
      let filings: any = await filingAPI.getFilingsByType(filingType.id!, { pageNumber: 1, pageSize: 20 }, archived);
      let vals = setFilingTypeRows(
        filings,
        filingType.name,
        updateRows
      );

      let rowCount = 0;
      try {
        // rowCount = await filingAPI.getFilingCountByType(filingType.id!);
        rowCount = rows.length;
      } catch (error) {
        console.warn("No records found for filing type");
      }
      // console.log("Row Value", vals.rows);

      vals.rows.forEach((element: any, i: number) => {
        vals.rows[i].created = new Date(element.created).toLocaleDateString();
      });
      setRowData(vals.rows);
      setColumns(vals.columns);
      setNumRows(rowCount);
    }
    setLoading(false);
  }

  const updateArchived = (isArchived: boolean) => {
    setArchived(isArchived);
  }

  useEffect(() => {
    updateRows();
  }, [archived])

  /* I Don't think having a get next page function is needed any longer
   * this was initially setup when we were trying to setup server side paging which is no longer
   * in effect
  */
  const getNextPage = async (pageData: any) => {
    let page = ((pageData.skip + pageData.take) / pageData.take);
    let pageSize = Math.abs(pageData.take);
    let pageValues: any = rows;
    let currentData: any = [];

    // // pageValues = await filingAPI.getFilingsByType(filingType!.id!, { pageNumber: page, pageSize: pageSize });
    // console.log(pageValues);
    // currentData.length = rows.length;
    // currentData.fill({}, 0, numRows);
    // // rows.forEach((element: any, i: number) => {
    // //   currentData[i] = element;
    // // })
    // pageValues.forEach((element: any, i: number) => {
    //   currentData[i + pageData.skip] = element;
    // });

    // setRowData(currentData);
    return (rows);
  }

  useEffect(() => {
    onMount();
  }, [filingType])

  if (filingType == undefined) {
    if (props.filingTypes) {
      filingType = props.filingTypes.find((filingType) => filingType.name === "Notice-NRI");
    }
  }

  // const filingsTabUrl = "rule filings";
  if (!props.filingTypes) {
    return null;
  }
  return (
    <div className="filing-wrapper">
      {/* ************* WARNING *******************
             * The following line of code make a dangerous
             * assumption as to what the url will be for rule
             * filings. That item is set in StateOfficeNavigationSettings.
             * TODO
             *  We need to start passing generic props into
             *  All components implemented as part of
             *  the State Office Navigation Settings that
             *  pass url and title information into the component
             *  that could be passed down to this level.
            /* ************* WARNING *******************/}
      {/* TODO Build Query param router */}
      <PrivateRoute
        path={`/state/${props.urlCode}`}
        render={() => <FilingTab filingTypes={props.filingTypes} />}
      />
      <PrivateRoute
        path={`/state/${props.urlCode}`}
        render={() => <FilingSubTab filingTypes={props.filingTypes} filingType={filingType} />}
      />
      <PrivateRoute
        path={`/state/${props.urlCode}/filing`}
        component={ActionDisplayRouter}
      />
      <PrivateRoute
        path={`/state/${props.urlCode}/filing`}
        render={() => <FilingHome updateRows={updateRows} />}
      />
      {/* TODO: Add in Action Display Router */}
      {filingType ? (
        <FilingTableTemp
          userTitleAccess={user.Titles}
          userRoleAccess={user.role}
          rows={rows}
          columns={columns}
          // numRows={numRows}
          filingType={filingType}
          // paging={getNextPage}
          add={function () { }}
          height="80vh"
          tableName={filingType.name}
          updateRows={updateRows}
          loading={loading}
          archived={archived}
          updateArchived={updateArchived}
          {...props}
        />
      ) : <div>Not Rendered {rows}</div>}
    </div>
  );
};

export const FilingWrapper = withRouter(FilingWrapperWithRouter);
