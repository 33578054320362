import {Category} from './../../model';
import {BaseAPI} from './../internal';
import {APISettings} from './../../settings/APISettings';


class CategoryAPI extends BaseAPI<Category> {
    constructor() {
        super("category", APISettings.subscriptionsUrl);
    }

    public getDictionary: () => Promise<Record<number, Category>> = async () => {
        return (await BaseAPI.axios.get(`${this.apiUrl}/${this.type}`)).data;
    };
}

export const categoryAPI = new CategoryAPI();
