import { useState } from "react";
import { passwordValidator } from "../../../Helpers/Validators";
import { FormInput } from "../../../Helpers/form-components";
import { subscriberAPI, userSubscriptionAPI } from "../../../../../utils/api";
import { UserSubscription } from "../../../../../utils/model/subscription/UserSubscription";

export const useUserAccountDetailsController = (props: any) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const [changePassCheck, setChangePassCheck] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(1);
  let session = sessionStorage.getItem("subscriptionUser");
  let user = JSON.parse(session!);
  const [formValues, setFormValues] = useState<any>({
    newEmail: "",
    confirmEmail: "",
    newPassword: "",
    confirmPassword: "",
    acctPassword: null,
  });

  // TODO: need to find way to validate email to change email address
  // TODO: need to validate password and confirm password match. Submit change to db.

  const togglePassCheck = () => {
    // clear form on form toggle (this way we only submit PW change or Email change)
    changePassCheck ? setChangePassCheck(false) : setChangePassCheck(true);
    setFormKey(formKey + 1);
  };

  const toggleEdit = () => {
    setFormValues({
      newEmail: "",
      confirmEmail: "",
      newPassword: "",
      confirmPassword: "",
      acctPassword: null,
    });
    setFormKey(formKey + 1);
    // console.log("toggleEdit", formValues);
    editMode ? setEditMode(false) : setEditMode(true);
  };

  const handleSubmit = async (dataItem: { [name: string]: any }) => {
    if (!user) {
      return;
    }

    if (changePassCheck) {
      let userInfo = {
        userEmail: user.user.email,
        newPassword: dataItem.newPassword,
        password: dataItem.acctPassword,
      };

      let response: any =
        await userSubscriptionAPI.changeSubscriberUserPassword(userInfo);

      // Check response if it exists and and alert the user of success or failure
      if (!response) {
        props.setAlertMessage("Failed to update password", "error");
      } else {
        if (response.message == "Success") {
          props.setAlertMessage("Updated password successfully", "success");
        } else {
          props.setAlertMessage("Failed to update password", "error");
        }
      }
      setEditMode(false);
    } else {
      userSubscriptionAPI.updateSubscriberEmail({
        password: dataItem.acctPassword,
        newEmailAddress: dataItem.newEmail,
        currentEmailAddress: user.user.email,
      });
      let newUser = { ...user };
      newUser.user.email = dataItem.newEmail;
      sessionStorage.setItem("subscriptionUser", JSON.stringify(newUser));
      window.location.reload();
    }
  };

  // validation
  const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
  const emailValidator = (value: string) => {
    setEmailConfirmed(emailRegex.test(value));
    return emailRegex.test(value) ? "" : "Please enter a valid email.";
  };

  return {
    FormInput,
    // functions
    handleSubmit,
    emailValidator,
    togglePassCheck,
    changePassCheck,
    passwordValidator,
    toggleEdit,
    // state
    editMode,
    emailConfirmed,
    formValues,
    formKey,
  };
};
