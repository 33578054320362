import { SegmentHistory } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class SegmentHistoryAPI extends BaseAPI<SegmentHistory> {
  constructor() {
    super("draft", APISettings.adminRulesApiUrl);
  }

  public getSegmentHistoryById: (id: number) => Promise<SegmentHistory> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentHistoryById/${id}`))
      .data;
  };

  public getAllSegmentHistory: () => Promise<SegmentHistory> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllSegmentHistory`)).data;
  };

  public createSegmentHistory: (segmentHistory: SegmentHistory, includeDraft: boolean) => Promise<any> = async (
    segmentHistory: SegmentHistory
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateSegmentHistory`,
      segmentHistory
    );
    return response.data;
  };

  public createSegmentHistoryInRegister: (segmentHistory: SegmentHistory) => Promise<any> = async (
    segmentHistory: SegmentHistory
  ) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateSegmentHistoryInRegister`,
      segmentHistory
    );
    return response.data;
  }

  public DeleteSegmentHistories: (segmentId:number,draftId:number) => Promise<any> = async (segmentId:number,draftId:number) => {
    const response = await BaseAPI.axios.delete(
      `${this.apiUrl}/DeleteSegmentHistories?segmentId=${segmentId}&draftId=${draftId}`
    );
    return response.data;
  };

  public updateSegmentHistory: (segmentHistory: SegmentHistory) => Promise<SegmentHistory> =
    async (segmentHistory:SegmentHistory) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/UpdateSegmentHistory`,
         segmentHistory
      );
      return response.data;
    };

    public updateEditLockByUserId : (id: number) => Promise<any> =
    async(id: number) => {
      const response = (await BaseAPI.axios.put(`${this.apiUrl}/UpdateEditLockByUserId/${id}`));
      return response.data;

    }

  public GetSegmentHistoryByDraftId: (id: number) => Promise<SegmentHistory> = async (
    id:number
  ) => {
    const response = await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentHistoryByDraftId?draftId=${id}`);
    return response.data;
  };
}

export const segmentHistoryAPI = new SegmentHistoryAPI();
