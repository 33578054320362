// import React from "react";
import {
  Dialog as MDialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { DialogTitleWithExit } from "../DialogTitleWithExit";
import { useId } from "@reach/auto-id";

// interface IDialogProps extends DialogProps {
//   title: React.ReactNode | React.ReactNodeArray;
//   actions?: React.ReactNode;
//   onClose: (any) => void;
//   open: boolean;
//   children?: React.ReactNode | React.ReactNodeArray;
//   titleProps?: object;
//   contentProps?: object;
//   actionsProps?: object;
// }

export const Dialog = ({
  title,
  actions,
  onClose,
  open,
  children,
  titleProps = {},
  contentProps = {},
  actionsProps = {},
  ...props
}: any) => {
  const id = useId();

  return (
    <MDialog
      onClose={onClose}
      aria-labelledby={id?.toString()}
      open={open}
      {...props}
    >
      <DialogTitleWithExit onClose={onClose} id={id} {...titleProps}>
        {title}
      </DialogTitleWithExit>
      <DialogContent className="dialog-body" dividers {...contentProps}>
        {children}
      </DialogContent>
      <DialogActions {...actionsProps}>{actions}</DialogActions>
    </MDialog>
  );
};
