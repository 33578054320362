import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    TextField
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Icon } from "..";
import { DialogTitleWithExit } from "../../components/DialogTitleWithExit";
import { IconList } from "./IconList";
import { IconListItem } from "./IconListItem";
import "./IconSelector.scss";

interface IIconSelectorProps {
  onSelect: (iconName: string) => void;
  icon: string;
  className?: string;
}

const iconKeys: string[] = IconList;
export const IconSelector = (props: IIconSelectorProps) => {
  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [stateIconKeys, setStateIconKeys] = useState<string[]>(iconKeys);
  const [searchValue, setSearchValue] = useState(props.icon);
  useEffect(() => {
    const filterList = iconKeys.filter((iconName) =>
      iconName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setStateIconKeys(filterList);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(props.icon);
  }, [props.icon]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValueIn = event.target.value;
    setSearchValue(searchValueIn);
  };
  const iconSelected = (index: number) => {
    const name = stateIconKeys[index];
    props.onSelect(name);
    setOpen(false);
  };
  return (
    <div className={props.className}>
      <Button
        aria-label={`select icon, current icon: ${props.icon}`}
        onClick={() => setOpen(true)}
        variant="contained"
        color={"primary"}
      >
        <Icon icon={props.icon} />
      </Button>
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="icon-selector-title"
        open={open}
      >
        <DialogTitleWithExit onClose={handleClose}>
          <span id="icon-selector-title">Choose Icon</span>
        </DialogTitleWithExit>
        <Divider />
        <DialogContent>
          <TextField
            id="searchfield"
            onChange={onSearchChange}
            value={searchValue}
            label="Search"
          />
          <div className="icon-selector">
            <FixedSizeList
              height={400}
              width={300}
              itemSize={46}
              itemCount={stateIconKeys.length}
            >
              {(props: ListChildComponentProps) => {
                const { index } = props;
                const iconName = stateIconKeys[index];
                return (
                  <IconListItem
                    {...props}
                    iconName={iconName}
                    iconSelected={iconSelected}
                  />
                );
              }}
            </FixedSizeList>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
