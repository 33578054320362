import { Status } from "../..";
import { Status as StatusModel } from "./../../../../../../../utils/model";
import { StatusEditDialog } from "./components";
import "./StatusEditWrapper.scss";

interface IStatusProps {
  index: number;
  status?: StatusModel;
  size?: "small" | "medium" | "large";
}

export const StatusEditWrapper = (props: IStatusProps) => {
  const { status, index } = props;
  if (status) {
    return (
      <div className="status-edit-wrapper">
        <StatusEditDialog index={index} status={status} />
        <Status status={status} />
      </div>
    );
  } else {
    return null;
  }
};
