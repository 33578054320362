import { FormGroup, FormLabel, MenuItem, Typography } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { FileUpload } from "../../../../../../components/FileUpload";
import { v4 as uuid } from "uuid";
import { FormProvider, useFormState } from "../../../../../../utils/hooks";
import {
  Contact,
  Docket,
  Draft,
  Filing,
  FilingType,
  Rule,
} from "../../../../../../utils/model";
import { AdoptedEmergencyRulemakingAction } from "../../../../../../utils/model/Filing";
import { Checkbox, Row, Select, TextField } from "./";
import { RULE_PLURAL_NAME } from "./../../../../../../utils/settings/RuleTitlePluralType";
import "./../FilingAddForm.scss";
import { FilingLink } from "../..";
import { FileUpload } from "../../../../../../components/FileUpload";
import { FilingTypes, StorageContainer, DraftStatuses, RulemakingActions } from "../../../../../../utils/enums";
import { fileAPI } from "../../../../../../utils/api";

interface IAdoptionOfEmergencyFormProps {
  filingType: FilingType;
  filing?: Filing;
  formatDate: (date: any) => any;
  renderRuleDropdown: () => any;
  renderChapterDropdown: () => any;
  // renderContactDropdown: () => any;
  // renderLiaisonDropdown: () => any;
  renderDocketDropdown: () => any;
  renderDraftDropdown: () => any;
  returnChapterListAsync: (parentid: number | null | undefined) => any;
  // attestationList: Contact[];
  // liaisonList: Contact[];
  docketList: Docket[];
  ruleList: Rule[];
  draftList: Draft[];
  adoptionStart: any;
  adoptionEnd: any;
  saveForm: (item: any, type: string) => void;
  getProposedRules: (draftId: number | null | undefined) => string[];
  setupDrafts: (ruleId: number, filterByUserTitleClaim: boolean, excludeDraftStatusIds: number[]) => void;
  returnContactListAsync: (ruleId: number, isAttestation: boolean) => any;
}

const currId = uuid();

const getInitialState = (props: IAdoptionOfEmergencyFormProps) => ({
  adoptionDate: new Date(),
  filingName: "",
  docket: Docket,
  docketId: 0,
  docketNumber: "",
  rule: props.ruleList[0],
  chapter: props.ruleList[0],
  ruleId: -1,
  chapterId: -1,
  rulemakingAction: RulemakingActions.AdoptedEmergency,
  authority: "",
  commentPeriod: "N/A",
  commentBeginDate: new Date(),
  commentEndDate: new Date(),
  publicHearing: "",
  publicHearingDate: new Date(),
  isGovernorApproved: false,
  isLaterEffective: false,
  isContingentEffective: false,
  laterEffectiveDate: new Date(),
  contingentDate: new Date(),
  expiration: "",
  supersededRules: "",
  supersededEmergencyActions: "N/A",
  docketSelect: Docket,
  docketSelectId: -1,
  docketSelectNumber: "",
  gubernatorialApproval: "",
  registerPublication: "",
  incorporationRef: "N/A",
  incorporatedStandards: "",
  incorporatedRules: "",
  available: "",
  findingOfEmergency: "",
  gistAnalysis: "",
  contactPerson: "",
  enactingClause: "EFFECTIVE DATE " + props.formatDate(new Date()),
  feeIncrease: "N/A",
  reasonForRule: "",
  ruleImpactReport: [{ guid: "", filename: "" }],
  agencyRuleReport: [{ guid: "", filename: "" }],
  attestation: Contact,
  attestationId: 0,
  liaison: Contact,
  liaisonId: 0,
  segments: [],
  transmittalSheet: [{ guid: "", filename: "" }],
  attestationSheet: [{ guid: "", filename: "" }],
  draftId: null,
  draft: null,
  rules: ""
});

export let AdoptionOfEmergencyForm = React.forwardRef(
  (props: IAdoptionOfEmergencyFormProps, ref) => {
    const [formValues, handleChange] = useFormState({ ...getInitialState(props), ...props.filing?.entity }, ref);

    const [ruleContactList, setRuleContactList]: any = React.useState([]);
    const [attestationContactList, setAttestationContactList]: any = React.useState([]);
    const [chapterList, setChapterList]: any = React.useState([]);
    const [readOnly, setReadOnly] = React.useState<boolean>(props.filing ? props.filing!.isReadOnly! : false);
    const [helperText, setHelperText] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const [proposedRulesText, setProposedRulesText] = React.useState<any>();

    const nameErrorMessage: string = "Filing Name Required";

    const handleFilingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value as string;
      let isError: boolean = value.trim().length == 0;
      let errorMessage: string = isError ? nameErrorMessage : '';

      setIsError(isError);
      setHelperText(errorMessage);
      handleChange(e, "filingName", value);
    };

    const setChapterListFromRuleId = React.useCallback(
      async (ruleId: number) => {
        const chapters = await props.returnChapterListAsync(ruleId);
        setChapterList(chapters);
      },
      [props]
    );

    const setLiaisonListFromRuleId = React.useCallback(async (ruleId: number) => {
      const liaisons = await props.returnContactListAsync(ruleId, false);
      setRuleContactList(liaisons);
    }, [props]
    );

    const setAttestationListFromRuleId = React.useCallback(async (ruleId: number) => {
      const attestation = await props.returnContactListAsync(ruleId, true);
      setAttestationContactList(attestation);
    }, [props]
    );

    useEffect(() => {
      if (!formValues.rule && !props.filing) {
        if (
          !Object.values(AdoptedEmergencyRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            AdoptedEmergencyRulemakingAction.AdoptedEmergency;
        }
        formValues.rule = props.ruleList[0];
        formValues.ruleId = props.ruleList[0]?.id;
        setLiaisonListFromRuleId(props.ruleList[0]?.id!);
        setAttestationListFromRuleId(props.ruleList[0]?.id!);

        let rule = props.ruleList.find((i: any) => i.id === formValues.ruleId);
        if (rule != null) {
          setChapterListFromRuleId(rule.segmentId);
        }

      } else if (props.filing) {
        if (
          !Object.values(AdoptedEmergencyRulemakingAction).includes(
            formValues.rulemakingAction
          )
        ) {
          formValues.rulemakingAction =
            AdoptedEmergencyRulemakingAction.AdoptedEmergency;
        }
        if (props.filing?.isReadOnly) {
          setReadOnly(props.filing.isReadOnly);
        }
        let entity = Object.create(props.filing!.entity!);
        setChapterListFromRuleId(entity.rule.segmentId);
        setLiaisonListFromRuleId(entity.rule.id);
        setAttestationListFromRuleId(entity.rule.id);
      }
    }, [formValues.rule, props.filing, props.ruleList, setChapterListFromRuleId]);

    React.useEffect(() => {
      (async () => {
        try {
          const { filingType, setupDrafts } = props;
          if (formValues.ruleId! > 0 && filingType.id! > 0) {
            const excludedDraftStatusIds: number[] = [];
            excludedDraftStatusIds.push(DraftStatuses.EndOfLife);
            await setupDrafts(formValues.ruleId!, true, excludedDraftStatusIds);
          }
        }
        catch (ex: any) {
          console.error(`Error: ${ex.message}`);
        }
      })();

      return () => {
        // this now gets called when the component unmounts
      };
    }, [formValues.ruleId]);


    // [0] Immediately Upon Governor Approval
    // [1] Contingent
    // [2] Later


    const [enactingClauseOptions, setEnactingClauseOptions] = React.useState([
      'PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING EMERGENCY RULES ARE CONSIDERED PROMULGATED AND EFFECTIVE UPON APPROVAL BY THE GOVERNOR AS SET FORTH IN 75 O.S., SECTION 253(F):',
      `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING EMERGENCY RULES ARE CONSIDERED PROMULGATED UPON APPROVAL BY THE GOVERNOR AS SET FORTH IN 75 O.S., SECTION 253(F), AND EFFECTIVE UPON APPROVAL BY THE GOVERNOR OR ${props.formatDate(formValues.laterEffectiveDate)}, WHICHEVER IS LATER: `,
      `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING EMERGENCY RULES ARE CONSIDERED PROMULGATED UPON APPROVAL BY THE GOVERNOR AS SET FORTH IN 75 O.S., SECTION 253(F), WITH A LATER EFFECTIVE DATE OF ${props.formatDate(formValues.contingentDate)}:`
    ]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, date: Date | null) => {
      handleChange(event, name, date);
      let enactingClauses = enactingClauseOptions;

      if (name === "contingentDate") {
        enactingClauses[1] = `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING EMERGENCY RULES ARE CONSIDERED PROMULGATED UPON APPROVAL BY THE GOVERNOR AS SET FORTH IN 75 O.S., SECTION 253(F), AND EFFECTIVE UPON APPROVAL BY THE GOVERNOR OR ${props.formatDate(date)}, WHICHEVER IS LATER:`
        setEnactingClauseOptions(enactingClauses);
        handleChange(event, 'enactingClause', enactingClauseOptions[1]);
      }
      else if (name === "laterEffectiveDate") {
        enactingClauses[2] = `PURSUANT TO THE ACTIONS DESCRIBED HEREIN, THE FOLLOWING EMERGENCY RULES ARE CONSIDERED PROMULGATED UPON APPROVAL BY THE GOVERNOR AS SET FORTH IN 75 O.S., SECTION 253(F), WITH A LATER EFFECTIVE DATE OF ${props.formatDate(date)}:`
        setEnactingClauseOptions(enactingClauses);
        handleChange(event, 'enactingClause', enactingClauseOptions[2]);
      }
    };

    const handleRuleSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.ruleList.find((i) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "rule", rule);
          handleChange(event, "ruleId", rule.id);
          setChapterListFromRuleId(rule.segmentId!);
          setLiaisonListFromRuleId(rule.id!);
          setAttestationListFromRuleId(rule.id!);
        }
        handleChange(event, "titleName", value);
      }
    };

    const handleDraftSelectChange = async (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Draft;
      if (value != null) {
        let draft = props.draftList.find((i: any) => i.id === rawValue);
        if (draft != null) {
          handleChange(event, "draft", draft);
          handleChange(event, "draftId", draft.id);


          let rulesText = await props.getProposedRules(draft.id);
          setProposedRulesText(rulesText);
          handleChange(event, "rules", rulesText);
        }
        handleChange(event, "draftName", value);
      }
    };

    const handleProposedRulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value as string;
      setProposedRulesText(value);
      handleChange(event, "rules", value);
    };

    const handleChapterSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let chapter = chapterList.find((i: any) => i.id === rawValue);
        if (chapter != null) {
          handleChange(event, "chapter", chapter);
          handleChange(event, "chapterId", chapter.id);
        }
        handleChange(event, "chapterName", value);
      }
    };

    const handleDocketSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Rule;
      if (value != null) {
        let rule = props.docketList.find((i) => i.id === rawValue);
        if (rule != null) {
          handleChange(event, "docketSelect", rule);
          handleChange(event, "docketSelectId", rule.id);
          handleChange(
            event,
            "docketSelectNumber",
            `${rule.year}-${rule.documentNumber}`
          );
        }
      }
    };

    const handleAttestationSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      if (value != null) {
        let contact = attestationContactList.find((i: any) => i.id === rawValue);
        if (contact != null) {
          handleChange(event, "attestation", contact);
          handleChange(event, "attestationId", contact.id);
        }
      }
    };

    const handleIncorpSelectChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, value: string) => {
      value === "Yes"
        ? handleChange(event, "incorporationRef", "Yes")
        : handleChange(event, "incorporationRef", "N/A");
    };

    const handleFeeIncreaseChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string
    ) => {
      value === "Yes"
        ? handleChange(event, "feeIncrease", "Yes")
        : handleChange(event, "feeIncrease", "N/A");
    };

    const handleSupersededSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string | null
    ) => {
      value === "Yes"
        ? handleChange(event, "supersededEmergencyActions", "Yes")
        : handleChange(event, "supersededEmergencyActions", "N/A");
    };

    const handleCommentPeriodSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string | null
    ) => {
      value === "Yes"
        ? handleChange(event, "commentPeriod", "Yes")
        : handleChange(event, "commentPeriod", "N/A");
    };

    const handlePublicHearingSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      value: string | null
    ) => {
      value === "Yes"
        ? handleChange(event, "publicHearing", "Yes")
        : handleChange(event, "publicHearing", "N/A");
    };

    const effectiveToggle = (event: React.ChangeEvent<HTMLInputElement>, name: string, value: boolean | null) => {
      value === true ? handleChange(event, name, false) : handleChange(event, name, true);

      if (name === "isGovernorApproved" && formValues.isGovernorApproved === false) {
        handleChange(event, "enactingClause", enactingClauseOptions[0]);
        handleChange(event, "isContingentEffective", false);
        handleChange(event, "isLaterEffective", false);
      } else if (name === "isContingentEffective" && formValues.isContingentEffective === false) {
        handleChange(event, "enactingClause", enactingClauseOptions[1]);
        handleChange(event, "isGovernorApproved", false);
        handleChange(event, "isLaterEffective", false);
      } else if (name === "isLaterEffective" && formValues.isLaterEffective === false) {
        handleChange(event, "enactingClause", enactingClauseOptions[2]);
        handleChange(event, "isGovernorApproved", false);
        handleChange(event, "isContingentEffective", false);
      } else {
        handleChange(event, "enactingClause", "");
        handleChange(event, "isGovernorApproved", false);
        handleChange(event, "isLaterEffective", false);
        handleChange(event, "isContingentEffective", false);
      }
    };

    const handleContactSelectChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      name: string,
      rawValue: string | null
    ) => {
      const value = rawValue as keyof typeof Contact;
      let contact: Contact | undefined | null;
      if (value != null) {
        if (name === "liaisonId") {
          contact = ruleContactList.find((i: any) => i.id === rawValue);
        } else {
          contact = attestationContactList.find((i: any) => i.id === rawValue);
        }
        if (contact != null) {
          if (name === "liaisonId") {
            handleChange(event, "liaison", contact);
            handleChange(event, "liaisonId", contact.id);
          } else {
            handleChange(event, "attestation", contact);
            handleChange(event, "attestationId", contact.id);
          }
          handleChange(event, name, contact.id);
        }
      }
    };

    const handleRulemakingSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
      name: string,
      value: string
    ) => {
      value === AdoptedEmergencyRulemakingAction.AdoptedEmergency
        ? handleChange(
          event,
          name,
          AdoptedEmergencyRulemakingAction.AdoptedEmergency
        )
        : handleChange(event, name, "");
    };

    const onUploadRuleImpact = (guid: string, filename: string) => {
      let index = formValues.ruleImpactReport.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.ruleImpactReport.push(uploadObject);
      } else {
        formValues.ruleImpactReport[index] = uploadObject;
      }
    };

    const onUploadAgencyRule = (guid: string, filename: string) => {
      let index = formValues.agencyRuleReport.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.agencyRuleReport.push(uploadObject);
      } else {
        formValues.agencyRuleReport[index] = uploadObject;
      }
    };

    const onTransmittalSheetUpload = (guid: string, filename: string) => {
      let index = formValues.transmittalSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.transmittalSheet.push(uploadObject);
      } else {
        formValues.transmittalSheet[index] = uploadObject;
      }
    };

    const onAttestationSheetUpload = (guid: string, filename: string) => {
      let index = formValues.attestationSheet.findIndex(
        (item: any) => item.filename == filename
      );

      let uploadObject = { guid: guid, filename: filename };
      if (index == -1) {
        formValues.attestationSheet.push(uploadObject);
      } else {
        formValues.attestationSheet[index] = uploadObject;
      }
    };

    // FILE DELETE FUNCTIONS
    const onRuleImpactDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var ruleImpactData = formValues.ruleImpactReport.filter((obj: any) => {
            return obj.guid !== guid;
          });
          formValues.ruleImpactReport = ruleImpactData;
          handleChange(null, 'transmittalSheet', ruleImpactData);
        }
      } catch (error) {
        console.error('Failed to delete file: ', error);
      }
    };

    const onAgencyRuleReportDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var agencyRuleReportData = formValues.agencyRuleReport.filter((obj: any) => {
            return obj.guid !== guid;
          });
          formValues.agencyRuleReport = agencyRuleReportData;
          handleChange(null, 'transmittalSheet', agencyRuleReportData);
        }
      } catch (error) {
        console.error('Failed to delete file: ', error);
      }
    };

    const onTransmittalSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var transmittalData = formValues.transmittalSheet.filter((obj: any) => {
            return obj.guid !== guid;
          });
          formValues.transmittalSheet = transmittalData;
          handleChange(null, 'transmittalSheet', transmittalData);
        }
      } catch (error) {
        console.error('Failed to delete file: ', error);
      }
    };

    const onAttestationSheetDelete = async (guid: string) => {
      try {
        let response = await fileAPI.deleteFile(guid, StorageContainer.FilingUploads);
        if (response) {
          var attestationData = formValues.attestationSheet.filter((obj: any) => {
            return obj.guid !== guid;
          });
          formValues.attestationSheet = attestationData;
          handleChange(null, 'transmittalSheet', attestationData);
        }
      } catch (error) {
        console.error('Failed to delete file: ', error);
      }
    };

    return (
      <FormProvider values={formValues} onChange={handleChange}>
        <div className="flex-column">
          <Typography variant="h6">ADOPTED EMERGENCY RULES</Typography>
          <TextField
            id={`name-text-field${currId}`}
            label="FILING NAME"
            name="filingName"
            placeholder="Filing Name (REQUIRED)"
            disabled={readOnly}
            onChange={handleFilingNameChange}
            error={isError}
            helperText={helperText}
            variant="outlined"
            aria-label="filing name"
            autoFocus
          />
          <Row className="rule-title-row">
            <Select
              className="drop-down"
              id={`name-text-field${currId}`}
              label="TITLE"
              name="ruleId"
              value={formValues.ruleId}
              onChange={handleRuleSelectChange}
              disabled={readOnly}
            >
              {props.renderRuleDropdown}
            </Select>
            {formValues.ruleId != -1 ? (
              <Select
                className="drop-down"
                id={`name-text-field${currId}`}
                label="CHAPTER"
                name="chapterId"
                value={formValues.chapterId}
                onChange={handleChapterSelectChange}
                disabled={readOnly}
              >
                {chapterList.map((chapterSegment: any) => {
                  return (<MenuItem className="drop-down-item" key={chapterSegment.id!} value={chapterSegment.id!}>Chapter {chapterSegment.chapterNum!}. {chapterSegment.description!}</MenuItem>);
                })}
              </Select>
            ) : null}
          </Row>
          <Row>
            <Select
              name="rulemakingAction"
              label="RULEMAKING ACTION"
              defaultValue={RulemakingActions.AdoptedEmergency}
              onChange={handleRulemakingSelectChange}
              disabled={readOnly}
            >
              <MenuItem value={RulemakingActions.AdoptedEmergency}>{RulemakingActions.AdoptedEmergency}</MenuItem>
            </Select>
            <Select
              label="DRAFTS"
              name="draft"
              value={formValues.draftId ?? 0}
              onChange={handleDraftSelectChange}
              disabled={readOnly}
            >
              {props.renderDraftDropdown}
            </Select>
          </Row>
          <TextField
            id="adopted-emergency-rules"
            name="rules"
            label="RULES"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
            value={proposedRulesText}
            onChange={handleProposedRulesChange}
          />
          <TextField
            id={`name-text-field${currId}`}
            name="authority"
            label="AUTHORITY"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <FormGroup className="form-group-checkbox">
            <Select
              name="commentPeriod"
              label="COMMENT PERIOD"
              defaultValue={"N/A"}
              onChange={handleCommentPeriodSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </Select>
            {formValues.commentPeriod === "Yes" ? (
              <Row>
                <div className="date-picker-wrapper">
                  <FormLabel className="label-date">
                    COMMENT PERIOD: START DATE
                  </FormLabel>
                  <DatePicker
                    name="commentBeginDate"
                    className="date-picker"
                    showPopperArrow={false}
                    selected={new Date(formValues.commentBeginDate)}
                    onChange={(date: Date, event: React.ChangeEvent<any>) =>
                      handleDateChange(event, "commentBeginDate", date)
                    }
                    dateFormat="MMMM d, yyyy"
                    disabled={readOnly}
                  />
                </div>
                <div className="date-picker-wrapper">
                  <FormLabel className="label-date">
                    COMMENT PERIOD: END DATE
                  </FormLabel>
                  <DatePicker
                    name="commentEndDate"
                    className="date-picker"
                    showPopperArrow={false}
                    selected={new Date(formValues.commentEndDate)}
                    onChange={(date: Date, event: React.ChangeEvent<any>) =>
                      handleDateChange(event, "commentEndDate", date)
                    }
                    dateFormat="MMMM d, yyyy"
                    disabled={readOnly}
                  />
                </div>
              </Row>
            ) : null}
            <Select
              name="publicHearing"
              label="PUBLIC HEARING"
              defaultValue={"N/A"}
              onChange={handlePublicHearingSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </Select>
            {formValues.publicHearing === "Yes" ? (
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">
                  PUBLIC HEARING DATE
                </FormLabel>
                <DatePicker
                  name="publicHearingDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.publicHearingDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) =>
                    handleDateChange(event, "publicHearingDate", date)
                  }
                  dateFormat="MMMM d, yyyy"
                  disabled={readOnly}
                />
              </div>
            ) : null}
          </FormGroup>
          <div className="date-picker-wrapper">
            <FormLabel className="label-date">ADOPTION</FormLabel>
            <div>Emergency rules can only be submitted within 10 days of adoption.</div>
            <DatePicker
              name="adoptionDate"
              className="date-picker"
              showPopperArrow={false}
              selected={new Date(formValues.adoptionDate)}
              onChange={(date: Date, event: React.ChangeEvent<any>) => handleDateChange(event, "adoptionDate", date)}
              dateFormat="MMMM d, yyyy"
              disabled={readOnly}
            />
          </div>
          <FormLabel className="effect-checkbox">EFFECTIVE</FormLabel>
          <FormGroup className="form-group-checkbox">
            <Checkbox
              label="IMMEDIATELY UPON GOVERNOR'S APPROVAL"
              onClick={(event) =>
                effectiveToggle(
                  event,
                  "isGovernorApproved",
                  formValues.effective
                )
              }
              name="isGovernorApproved"
              checked={formValues.isGovernorApproved}
              value={formValues.isGovernorApproved}
            />
            <div>
              <Checkbox
                label="CONTINGENT EFFECTIVE DATE"
                onClick={(event) => effectiveToggle(event, "isContingentEffective", formValues.isContingentEffective)}
                name="isContingentEffective"
                checked={formValues.isContingentEffective}
                value={formValues.isContingentEffective}
              />
              {formValues.isContingentEffective ? (
                <div className="date-picker-wrapper">
                  <FormLabel className="label-date">SELECT DATE</FormLabel>
                  <DatePicker
                    name="contingentDate"
                    className="date-picker"
                    showPopperArrow={false}
                    selected={new Date(formValues.contingentDate)}
                    onChange={(date: Date, event: React.ChangeEvent<any>) => handleDateChange(event, "contingentDate", date)}
                    dateFormat="MMMM d, yyyy"
                    disabled={readOnly}
                  />
                </div>
              ) : null}

              <Checkbox
                label="LATER EFFECTIVE DATE"
                onClick={(event) => effectiveToggle(event, "isLaterEffective", formValues.isLaterEffective)}
                name="isLaterEffective"
                checked={formValues.isLaterEffective}
                value={formValues.isLaterEffective}
              />
            </div>
            {formValues.isLaterEffective ? (
              <div className="date-picker-wrapper">
                <FormLabel className="label-date">SELECT DATE</FormLabel>
                <DatePicker
                  name="laterEffectiveDate"
                  className="date-picker"
                  showPopperArrow={false}
                  selected={new Date(formValues.laterEffectiveDate)}
                  onChange={(date: Date, event: React.ChangeEvent<any>) => handleDateChange(event, "laterEffectiveDate", date)}
                  dateFormat="MMMM d, yyyy"
                  disabled={readOnly}
                />
              </div>
            ) : null}
          </FormGroup>
          <FormGroup className="expiration">
            <TextField
              id={`name-text-field${currId}`}
              name="expiration"
              label="EXPIRATION"
              multiline
              rows="4"
              fullWidth
              disabled={readOnly}
            />
          </FormGroup>
          <FormGroup className="form-group-checkbox">
            <Select
              name="supersededEmergencyActions"
              label="SUPERSEDED EMERGENCY ACTIONS"
              defaultValue={"N/A"}
              onChange={handleSupersededSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            {formValues.supersededEmergencyActions === "Yes" ? (
              <React.Fragment>
                <TextField
                  id={`name-text-field${currId}`}
                  fullWidth
                  name="supersededRules"
                  label="SUPERSEDED RULES"
                  multiline
                  disabled={readOnly}
                  rows="5"
                />
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="gubernatorialApproval"
                  label="GUBERNATORIAL APPROVAL"
                  disabled={readOnly}
                />
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="registerPublication"
                  label="REGISTER PUBLICATION"
                  disabled={readOnly}
                />
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="docketSelectNumber"
                  label="DOCKET NUMBER"
                  disabled={readOnly}
                />
              </React.Fragment>
            ) : null}
          </FormGroup>
          <FormGroup className="form-group-checkbox">
            <Select
              name="incorporationRef"
              label="INCORPORATIONS BY REFERENCE"
              value={formValues.incorporationRef}
              defaultValue={"N/A"}
              onChange={handleIncorpSelectChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">N/A</MenuItem>
            </Select>
            {formValues.incorporationRef === "Yes" ? (
              <React.Fragment>
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="incorporatedStandards"
                  label="INCORPORATED STANDARDS"
                  rows="5"
                  multiline
                  fullWidth
                  disabled={readOnly}
                />
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="incorporatedRules"
                  label="INCORPORATING RULES"
                  rows="5"
                  multiline
                  fullWidth
                  disabled={readOnly}
                />
                <TextField
                  id={`name-text-field${currId}`}
                  variant="outlined"
                  name="available"
                  label="AVAILABLITY"
                  rows="5"
                  multiline
                  fullWidth
                  disabled={readOnly}
                />
              </React.Fragment>
            ) : null}
          </FormGroup>
          <TextField
            id={`name-text-field${currId}`}
            name="findingOfEmergency"
            label="FINDING OF EMERGENCY"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            id={`name-text-field${currId}`}
            name="gistAnalysis"
            label="GIST/ANALYSIS"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            id={`name-text-field${currId}`}
            name="contactPerson"
            label="CONTACT PERSON"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <TextField
            id={`name-text-field${currId}`}
            name="enactingClause"
            label="ENACTING CLAUSE"
            multiline
            rows="5"
            fullWidth
            disabled={readOnly}
          />
          <Row>
            <Select
              name="feeIncrease"
              label="FEE INCREASE"
              defaultValue={"N/A"}
              onChange={handleFeeIncreaseChange}
              disabled={readOnly}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="N/A">No</MenuItem>
            </Select>
            <h5>
              Reminder: Agency Must File a Notification of Fee Increase or New
              Fees
            </h5>
          </Row>
          <TextField
            id={`name-text-field${currId}`}
            name="reasonForRule"
            label="REASON FOR RULE (If resubmitting, please also include reason for resubmission)"
            rows="5"
            multiline
            fullWidth
            disabled={readOnly}
          />
          <FormLabel>RULE IMPACT STATEMENT</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.ruleImpactReport.map((element: any, key: number) => {
              if (element.guid) {
                return (
                  <FilingLink guid={element.guid} fileName={element.filename} onDelete={onRuleImpactDelete} />
                );
              }
            })}
            <FileUpload
              onUploaded={onUploadRuleImpact}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>

          <FormLabel>AGENCY RULE REPORT (Optional)</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.agencyRuleReport.map((element: any, key: number) => {
              if (element.guid) {
                return (
                  <FilingLink guid={element.guid} fileName={element.filename} onDelete={onAgencyRuleReportDelete} />
                );
              }
            })}
            <FileUpload
              onUploaded={onUploadAgencyRule}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>

          <Select
            name="liaisonId"
            label="AGENCY LIAISON"
            onChange={handleContactSelectChange}
          >
            {ruleContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderLiaisonDropdown} */}
          </Select>
          <FormLabel>TRANSMITTAL SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.transmittalSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onTransmittalSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onTransmittalSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
          <Select
            name="attestationId"
            label="ATTESTATION OFFICER"
            onChange={handleAttestationSelectChange}
            disabled={readOnly}
          >
            {attestationContactList.map((element: any, key: number) => {
              return (
                <MenuItem key={element.id} value={element.id}>
                  {element.firstName + " " + element.lastName}
                </MenuItem>
              )
            })
            }
            {/* {props.renderContactDropdown} */}
          </Select>
          <FormLabel>ATTESTATION SHEET</FormLabel>
          <FormGroup className="form-group-checkbox">
            {formValues.attestationSheet.map(
              (element: any, key: number) => {
                if (element.guid) {
                  return (
                    <FilingLink guid={element.guid} fileName={element.filename} onDelete={onAttestationSheetDelete} />
                  );
                }
              }
            )}
            <FileUpload
              onUploaded={onAttestationSheetUpload}
              allowedFileTypes={["pdf"]}
              showPreview={false}
              storageContainer={StorageContainer.FilingUploads}
              disabled={readOnly}
            />
          </FormGroup>
          {props.filing?.adoptedRuleDocumentPdf ? (
            <Fragment>
              <FormLabel>ADOPTED RULE DOCUMENT</FormLabel>
              <FormGroup className="form-group-checkbox">
                <FilingLink guid={props.filing!.adoptedRuleDocumentPdf.substr(0, props.filing!.adoptedRuleDocumentPdf.lastIndexOf('.')) + '.pdf'} fileName={`Adoption${props.filing!.name}.pdf`} container={"adopted-rule-documents"} />
              </FormGroup>
            </Fragment>
          ) : null}
        </div>
      </FormProvider>
    );
  }
);
