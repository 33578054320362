export class APISettings {
  public static adminRulesApiUrl = "https://okadminrules-api.azurewebsites.net";
  public static webClientUrl = "https://rules.ok.gov";
  public static identityServerUrl = "https://oklahoma-tec-identity-development.azurewebsites.net";
  public static genServiceUrl = "https://oklahoma-tec-registercompile-dev.azurewebsites.net";

  // public static genServiceUrl = "http://localhost:5003";
  //public static adminRulesApiUrl = "https://localhost:5001";
  //public static webClientUrl = "https://localhost:3000";
  // public static identityServerUrl = "https://localhost:5002";

  public static functionUrl = "https://okadministrativerules.azurewebsites.net/api";
  public static searchFuncUrl = "https://oksearchrules.azurewebsites.net/api";
  public static subscriptionsUrl = "https://oklahoma-tec-subscriptions-development.azurewebsites.net/api";
  public static blobStorageUrl = "https://oklahomarules.blob.core.windows.net";
}