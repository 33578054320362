import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@material-ui/core/";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import { actionAPI, statusAPI } from "../../../../../../../utils/api";
import { Status } from "../../../../../../../utils/model";
import { ActionList } from "../ActionList/ActionList";
import { StatusModal } from "../StatusModal/StatusModal";
import { Workflow } from "./../../../../../../../utils/model/Workflow";
import "./StatusCard.scss";

interface IStatusCardProps {
  workflow: Workflow;
  statusList: Status[];
  triggerReload: () => void;
}

export const StatusCard = (props: IStatusCardProps) => {
  const initialStatus: Status[] = [];
  const [selectedStatus, setSelectedStatus] = React.useState(initialStatus[0]);
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const deleteStatus = async () => {
    // Creates a new action for a status item
    try {
      if (selectedStatus && alertOpen) {
        let actions = await actionAPI.getAll({
          "status.id": selectedStatus.id,
        });
        if (actions) {
          for (let i = 0; i < actions.length; i++) {
            const element = actions[i];

            if (element != null && element.id != null) {
              await actionAPI.delete(element.id);
            }
          }
        }
        await statusAPI.delete(selectedStatus!.id!);
      }
    } catch (error) {
      console.error("You've encountered and error: " + error);
    }
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAlertClose = (event: any) => {
    event.stopPropagation();
    setAlertOpen(false);
  };

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const updateSelected = (status: Status) => {
    setSelectedStatus(status);
  };

  const clearSelected = () => {
    setSelectedStatus(initialStatus[0]);
  };

  const onDelete = async (event:any) => {
      await deleteStatus();
      handleAlertClose(event);
      props.triggerReload();
  }

  return (
    <div>
      <div>
        {props.statusList.map((status, key, elements) => (
          <div key={key} id={status.name}>
            <Card className="card" key={key}>
              <CardHeader
                titleTypographyProps={{ variant: "h5", component: "h3" }}
                title={status.name}
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      updateSelected(status);
                      setSelectedStatus(status);
                      handleClickOpen();
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <Typography variant="h6" color="textSecondary" component="p">
                  Actions
                </Typography>
                <Divider />
                <ActionList status={status} nextStatusList={elements} />
              </CardContent>
              <Divider />
              <CardActions className="card-actions">
                <IconButton
                  size="medium"
                  aria-label="Delete Status"
                  className="card-icon-warn"
                  onClick={() => {
                    setSelectedStatus(status);
                    handleAlertOpen();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <Dialog
                  open={alertOpen}
                  onClose={handleAlertClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Delete Status And Actions"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      WARNING: Are you sure you want to delete this Status? This
                      will also delete the actions contained within this status.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleAlertClose} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={onDelete}
                      color="secondary"
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardActions>
            </Card>
            {key < props.statusList.length - 1 ? (
              <div className="card-actions-arrow">
                <ArrowDownwardIcon />
              </div>
            ) : (
              <div />
            )}
          </div>
        ))}
        <div className="card-actions-new">
          <IconButton
            size="medium"
            aria-label="Add new status"
            className="add-new-icon"
            onClick={() => {
              clearSelected();
              handleClickOpen();
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <StatusModal
        open={open}
        workflow={props.workflow}
        handleClickOpen={handleClickOpen}
        triggerReload={props.triggerReload}
        handleClose={handleClose}
        selectedStatus={selectedStatus}
      />
    </div>
  );
};
