import { SegmentType } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class SegmentTypeAPI extends BaseAPI<SegmentType> {
  constructor() {
    super("segmentType", APISettings.adminRulesApiUrl);
  }

  public getSegmentTypesById: (id: number) => Promise<SegmentType> = async (
    id: number
  ) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetSegmentTypesById/${id}`))
      .data;
  };

  public getAllSegmentTypes: () => Promise<SegmentType[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetAllSegmentTypes`)).data;
  };

  public createSegmentTypes: (segmentType: SegmentType) => Promise<any> =
    async (segmentType: SegmentType) => {
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/CreateSegmentTypes`,
        {
          segmentType,
        }
      );
      return response.data;
    };

  public removeSegmentTypes: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveSegmentTypes/${id}`
    );
    return response.data;
  };

  public updateSegmentTypes: (
    id: number,
    segmentType: SegmentType
  ) => Promise<SegmentType> = async (id: number, segmentType: SegmentType) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateSegmentTypes/${id}`,
      { segmentType }
    );
    return response.data;
  };
}

export const segmentTypeAPI = new SegmentTypeAPI();
