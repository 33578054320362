/**
 * It takes in a draft object and returns a form that allows the user to edit the draft
 * @param {IEditForm} props - IEditForm
 */
import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardActions,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import Form from "antd/lib/form/Form";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Draft, Segment } from "../../utils/model";
import { RichTextEditor } from "../RichTextEditor";
import {
  EditorType,
  StatusRecordStatus,
  StorageContainer,
} from "../../utils/enums";
import { RuleDrafting } from "../../utils/classes/RuleDrafting";
import "./DraftEditForm.scss";
import { FileUpload } from "../FileUpload";
import { findByLabelText } from "@testing-library/react";
import { flexbox } from "@material-ui/system";
import { CopyrightOutlined, SettingsBackupRestore } from "@material-ui/icons";
import { APISettings } from "../../utils/settings";
import { DiffCompare } from "../DiffCompare";
import { Button } from "@material-ui/core";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";

/**
 * @description draft editor form
 * @param reloadHierarchy -- function trigger to reload the mywork draft editor hierarchy
 * @param edit -- expects a segment history object of edit data
 * @param editSection -- optional function used to set data my work split pane and state values
 * @param draftData -- used when calling updateSegmentHistory endpoint
 * @param originalSegment -- segment data for the diff comparison tool to evaluate against
 * @param openError -- function that accepts in error message string to display toast error message on save
 */
interface IDraftEditForm {
  sectionNum: any;
  edit: any;
  draftData: any;
  originalSegment: any;
  reload?: () => void;
  openError?: (response?: string) => void;
  editSection?: (data: any, segmentData: any) => void;
  isRegister?: boolean;
  segmentHistoryId?: any;
  isReadOnly?: boolean;
  // cancelEdit: any;
}

export const DraftEditForm = (props: IDraftEditForm) => {
  let ruleDrafting = new RuleDrafting();
  const [editFormData, setEditFormData] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [files, setFiles] = useState<any>(props.edit.images ?? []);
  const [initialText, setInitialText] = useState<string>("");
  const [modifiedText, setModifiedText] = useState<string>("");
  const [statusSelected, setStatusSelected] = React.useState({
    value: { text: "[UNDEFINED]", id: 12 },
  });
  const [diffView, setDiffView] = React.useState<boolean>(false);
  const [renumberedToValue, setRenumberedToValue] = React.useState<string>(
    props.edit.renumberedToSegmentNumber ?? ""
  );
  const [statusList, setStatusList] = React.useState<any[]>(
    ruleDrafting.agencyStatuses
  );

  /**
   * @description - sets the RuleDrafting status definition instead of having it defined inline so it can also be accessed outside of this component
   */
  // filter status by user role
  // const status = ruleDrafting.oarStatuses;
  const { getUser } = useContext(AuthenticationContext);

  // get user data from server and validate roles to filter front end items;
  const filterRoles = async () => {
    let statusData = ruleDrafting.agencyStatuses;
    let user = await getUser();

    if (user) {
      user.profile.role.forEach((element: string) => {
        if (
          element == "Office Of Administrative Rules" ||
          element == "Global User Administrator"
        ) {
          statusData = ruleDrafting.oarStatuses;
        }
      });
    }
    setStatusList(statusData);
  };

  /* This is a React Hook that is used to set the state of the EditForm component. */
  useEffect(() => {
    if (props.edit !== undefined) {
      filterRoles();
      setEditFormData(props.edit);
      setDescription(props.edit.description);
      setInitialText(props.edit.text);
      setFiles(props.edit.images ?? []);
      setRenumberedToValue(props.edit.renumberedToSegmentNumber);
      // Grab status id and text for drop down list
      if (props.edit && props.edit.statusName !== null && statusList) {
        setStatusValue(props.edit.statusName);
      } else {
        setStatusValue({
          value: { text: statusList[0].text, id: statusList[0].id },
        });
      }
    } else {
      setEditFormData(0);
    }

    return () => {
      setEditFormData(0);
      setDescription("");
      setInitialText("");
    };
  }, [props.editSection, props.edit]);

  /**
   * It sets the status value to the value of the edit prop if it exists, otherwise it sets it to the
   * value of the status prop.
   * @param {any} setValue - The value that will be set to the state.
   */
  const setStatusValue = (setValue: any) => {
    if (props.edit) {
      setStatusSelected({
        value: {
          text: props.edit.statusName.toUpperCase(),
          id: props.edit.statusId,
        },
      });
      if (props.edit.renumberedToSegmentNumber) {
        setRenumberedToValue(props.edit.renumberedToSegmentNumber);
      }
    } else {
      setStatusSelected(setValue);
    }
  };

  /**
   * It updates the draft data with the new values.
   */
  const updateDraft = async () => {
    let response = await ruleDrafting.UpdateSegmentHistory(
      statusSelected,
      editFormData,
      description,
      props.draftData,
      files,
      renumberedToValue
    );
    if (!response.id && props.openError) {
      // prints error.response.data
      props.openError(response);
    }
    // Setting edit sections to null so it closes the edit form and reloading hierarchy
    if (props.editSection) {
      props.editSection(null, null);
    }
    if (props.reload) {
      props.reload();
    }
  };

  /**
   * Update the draft data with register validation.
   */
  const updateDraftRegister = async () => {
    if (!props.isReadOnly) {
      // update segment history table to change lockedByUserId to null.
      let lockEdit = await ruleDrafting.UpdateEditLockByUserId(
        props.segmentHistoryId
      );

      // Passing to update if register is not read only.
      let response = await ruleDrafting.UpdateSegmentHistoryInRegister(
        statusSelected,
        editFormData,
        description,
        props.draftData,
        files,
        renumberedToValue
      );

      if (!response.id && props.openError) {
        // prints error.response.data
        props.openError(response);
      }
      // Setting edit sections to null so it closes the edit form and reloading hierarchy
      // console.log(props.editSection);
      if (props.editSection) {
        props.editSection(null, null);
      }
      if (props.reload) {
        props.reload();
      }
    } else {
      if (props.openError) {
        props.openError(
          "Cannot save changes on register data that has already been published"
        );
      } else {
        alert(
          "Cannot save changes on register data that has already been published"
        );
      }
    }
  };

  /**
   * It takes an event as an argument, and then sets the status property to the value of the
   * event.target.value property
   * @param {DropDownListChangeEvent} event - DropDownListChangeEvent
   */
  const handleChange = (event: DropDownListChangeEvent) => {
    setStatusSelected({ value: event.target.value });
  };

  /**
   *
   * @description - stores text editor contents in variable
   * @param data - string data from text editor
   */
  const textChange = (data: any) => {
    let formData: any = {};
    // Object assign used because javascript assigns by object reference
    Object.assign(formData, editFormData);
    formData.text = data;
    setEditFormData(formData);
    setModifiedText(formData.text);
  };

  /**
   * It takes in a guid and filename and then creates a url to the blob storage.
   * @param {string} guid - The GUID of the file that was uploaded.
   * @param {string} filename - The name of the file that was uploaded.
   */
  const fileUploaded = (guid: string, filename: string) => {
    //https://oklahomarules.blob.core.windows.net/temp/0a228c08-9abd-4b66-90d8-251a2b011bdc.png
    let url = `${APISettings.blobStorageUrl}/temp/${guid}`;
    let fileList = files;
    fileList.push({ guid: guid, filename: filename, url: url });

    // Clear files to reset state so it propgates data to front end on fileList.map(()=>{})
    setFiles([]);
    setFiles(fileList);
  };

  /**
   * It copies the image url to the clipboard.
   * @param {any} image - The image object that was clicked.
   */
  const copyText = (image: any) => {
    navigator.clipboard.writeText(image.url);
    alert("copied\r" + `${image.url!} \r${image.filename!}`);
  };

  const diffViewToggle = () => {
    diffView ? setDiffView(false) : setDiffView(true);
  };


  /* Used to render the edit form.
   */
  const _renderEdit = () => {
    return (
      <Card className="edit-card">
        <CardBody className="card-body">
          <CardHeader>
            <h2>EDIT SECTION:</h2>
            <h3>
              <strong>
                {props.originalSegment.sectionNum} ( {editFormData.description} )
              </strong>
            </h3>
          </CardHeader>

          <Input
            name="description"
            style={{ width: "100%" }}
            label="Description"
            value={description}
            onChange={(e: any) => setDescription(e.value)}
            disabled={props.isReadOnly}
          />
          {statusList.length > 1 ? (
            <DropDownList
              style={{ width: "100%" }}
              data={statusList.sort((a, b) => a.text.localeCompare(b.text))}
              textField="text"
              dataItemKey="id"
              value={statusSelected.value}
              onChange={handleChange}
              label="Status"
              className="status-list"
              disabled={props.isReadOnly}
            />
          ) : null}
          {/*
             Note for the magic numbers below
             status id 5 == amended and renumbered
             status id 6 = renumbered
           */}
          {statusSelected.value.id == 5 || statusSelected.value.id == 6 ? (
            <Input
              name="Renumbered To"
              style={{ width: "100%" }}
              label="Renumbered To"
              value={renumberedToValue}
              onChange={(e: any) => setRenumberedToValue(e.value)}
            />
          ) : null}

          {props.originalSegment.name == "Appendix" ? (
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!props.isReadOnly ? (
                <FileUpload
                  allowedFileTypes={["image", "pdf"]}
                  storageContainer={StorageContainer.Temp}
                  onUploaded={fileUploaded}
                />
              ) : null}
              <span style={{ flex: 2, marginLeft: "1rem" }}>
                {files.length > 0
                  ? files.map((element: any, key: number) => {
                    return (
                      <div key={key}>
                        Image: {element.filename}{" "}
                        <a href="#" onClick={() => copyText(element)}>
                          Copy URL
                        </a>
                      </div>
                    );
                  })
                  : null}
              </span>
            </div>
          ) : null}

          <span style={{ display: "flex", height: "100%" }}>
            <div
              className="draft-editor-texteditor"
              style={{
                height: "100% !important",
                marginTop: ".5rem",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <RichTextEditor
                text={initialText ? initialText : ""}
                editorType={EditorType.segment}
                diffView={diffViewToggle}
                disable={diffView || props.isReadOnly}
                onTextChange={textChange}
                height={"80vh"}
                width={"100%"}
              />
            </div>
            {diffView && props.originalSegment ? (
              <div
                className={`${diffView ? "diff-visible" : "diff-hide"
                  } editor-compare`}
              >
                <div>
                  <Button
                    className="diff-close"
                    variant="contained"
                    onClick={() => setDiffView(false)}
                  >
                    Close
                  </Button>
                </div>
                <div>
                  <DiffCompare
                    text={props.originalSegment.text}
                    textCompare={modifiedText ? modifiedText : initialText}
                    editorType={EditorType.segment}
                  />
                </div>
              </div>
            ) : null}
          </span>
        </CardBody>
        <CardActions>
          <span
            className="k-button k-flat k-primary"
            onClick={props.isRegister ? updateDraftRegister : updateDraft}
          >
            Update Draft
          </span>
        </CardActions>
      </Card>
    );
  };

  /* This is a ternary operator that returns the _renderEdit() function if editFormData is not null,
   otherwise it returns null. */
  return <div>{editFormData ? _renderEdit() : null}</div>;
};
