import { Button, Divider, FormLabel, List, TextField } from "@material-ui/core";
import { create } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { commentAPI, commentThreadAPI } from "../../utils/api";
import { CommentStatus, ThreadType, VisibilityLevel } from "../../utils/enums";
import { Comment as CommentType, CommentThread } from "../../utils/model/";
import "./CommentThread.scss";
import { Comment } from "./components/Comment";

interface ICommentThreadProps {
  id: number;
  type: string;
}

export const CommentThreadComp = (props: ICommentThreadProps) => {
  const [commentThread, setCommentThread] = useState<CommentThread>();
  const [comments, setComments] = useState<any>();
  const [text, setText] = useState<string>();

  const onMount = useCallback(async () => {
    if (props.id) {
      let thread: any;
      thread = await commentThreadAPI.getCommentThreadByReferenceId(props.id);
      setCommentThread(thread);
      let commentList = await commentAPI.getCommentsByThreadId(thread.id);
      setComments(commentList);
      // switch (props.type) {
      //   case "filing":
      //     try {
      //       thread = await commentThreadAPI.getCommentThread(
      //         props?.id
      //       );
      //       setCommentThread(thread);
      //     } catch (err) {
      //       console.error("error fetching comment threads", err);
      //     }
      //     break;
      //   case "segment":
      //     try {
      //       thread = await commentThreadAPI.getCommentThread(
      //         props?.id
      //       );
      //       setCommentThread(thread);
      //     } catch (err) {
      //       console.error("error fetching comment threads", err);
      //     }
      //     break;
      //   default:
      //     break;
      // }
    }
  }, [props.id, props.type]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const AddComment = async () => {
    // if not comment thread exists create one and then add a comment
    if (text && !commentThread && props.id) {
      let commentThread = new CommentThread(0,ThreadType.Filing,props.id,VisibilityLevel.Title);
      delete commentThread.id;

      let createdThread = await commentThreadAPI.createCommentThreads(commentThread);

      let comment = new CommentType(
        text,
        VisibilityLevel.Title,
        CommentStatus.Active,
        createdThread.value.id,
      );
      delete comment.id;

      console.log("Comment: ",comment);
      await commentAPI.createComment(comment);

    } else if (text && commentThread) {
      let comment = new CommentType(
        text,
        VisibilityLevel.Title,
        CommentStatus.Active,
        commentThread.id!
      );
      delete comment.id;
      await commentAPI.createComment(comment);
    }
    onMount();
  };

  const UpdateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value as string;
    setText(value);
  };

  return (
    <div className="comment-thread">
      <FormLabel className="comment-thread-title">COMMENTS</FormLabel>
      <Divider />
      <List className="comment-list">
        {comments
          ? comments.map((comment:any,index:number) => (
              <Comment key={index} comment={comment} />
            ))
          : null}

        <TextField
          id="comment-message"
          margin="dense"
          name="comment"
          variant="outlined"
          placeholder="message..."
          multiline
          rows="3"
          label="Comment"
          type="text"
          onChange={UpdateText}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={AddComment}>
          Comment
        </Button>
      </List>
    </div>
  );
};
