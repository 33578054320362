import { Identifiable } from ".";

export class EmailGroup extends Identifiable {
  constructor(emailTemplateId: number, contactId: number) {
    super(null);
    this.emailTemplateId = emailTemplateId;
    this.contactId = contactId;
  }
  public emailTemplateId: number;
  public contactId: number;
}
