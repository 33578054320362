// import React from "react";
import "./ActionIcon.scss";
import { Fab, Tooltip } from "@material-ui/core";
import { Icon } from "..";

interface IActionIconProps {
  onClick?: (event?: any) => void;
  className?: string;
  color: "primary" | "default" | "warning" | "danger" | "success";
  icon: string;
  label: string;
}

export const ActionIcon = (props: IActionIconProps) => {
  const { onClick, className, icon, label, color } = props;

  return (
    <span className = "action-icon">
      <Tooltip title={label} placement="bottom">
        <Fab
          disableTouchRipple={true}
          className={`${className}  ${color}`}
          onClick={onClick}
        >
          {<Icon icon={icon}></Icon>}
        </Fab>
      </Tooltip>
    </span>
  );
};
