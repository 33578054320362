import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EditorType } from "../../utils/enums";
import "./RichTextEditor.scss";
import "../../fonts.css";

interface IRichTextEditorProps {
  text: string;
  editorType: EditorType;
  saveText?: (text: string) => Promise<void>;
  diffView?: (diffVal?: boolean) => void;
  onTextChange: (content: any, editor: any) => void;
  width?: string;
  height?: string;
  disable?: boolean;
}

/**
 *
 * @author Brandon Larsen <brandon.larsen@tecuity.com>
 * @author
 * @param {string} text -  text you want to show in the editor on load. NOTE: do not pass in text stored in a variable that is being set onTextChange
 * @param {Enum<EditorType>} editorType - editor type used to limit text editor options (ex: EditorType.segment limits to TimesNewRoman and 12pt font only)
 * @param {function(string) => void} [saveText] - function used for ctrl+s functionality if you want it to make a api call to update on ctrl+s
 * @param {function(diffVal?: boolean) => void} [diffView] - used to toggle visibility of the diff compare button
 * @param {fucntion(any,any) => void} onTextChange text change event handler that returns HTML string and editor detail/setting object
 * @param {string} [width] string used to override editor width property
 * @param {string} [height] string used to override editor height parameter
 * @returns Text editor component
 */
export const RichTextEditor = (props: IRichTextEditorProps) => {
  // set default font values based on EditorType
  var text = props.text;

  const [diffCompare, setDiffCompare] = useState(false);
  const [height, setHeight] = useState(props.height ? props.height : "100%");
  const [width, setWidth] = useState(props.width ? props.width : "auto");
  let diff = false;
  let body = "{ font-family: Montserrat; }";
  let fontsizes = "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt";

  // set default plugins
  let toolbar = [
    "undo redo | formatselect | fontselect | fontsizeselect | link | media image | insertdatetime |",
    "| alignleft aligncenter alignright alignjustify | bold italic strikethrough underline backcolor | bullist numlist outdent indent | removeformat | diffButton",
  ];
  // default fonts use variable for editor configuration
  let fonts =
    "Montserrat=Montserrat;" +
    "Andale Mono=andale mono,times;" +
    "Arial=arial,helvetica,sans-serif;" +
    "Arial Black=arial black,avant garde;" +
    "Book Antiqua=book antiqua,palatino;" +
    "Courier New=courier new,courier;" +
    "Georgia=georgia,palatino;" +
    "Helvetica=helvetica;" +
    "Impact=impact,chicago;" +
    "Tahoma=tahoma,arial,helvetica,sans-serif;" +
    "Terminal=terminal,monaco;" +
    "Times New Roman=times new roman,times;" +
    "Trebuchet MS=trebuchet ms,geneva;" +
    "Verdana=verdana,geneva;";

  // setup segment editor properties
  if (props.editorType === EditorType.segment) {
    fonts = "Times New Roman=times new roman,times;";
    fontsizes = "12pt";
    body = "{ font-family: times new roman, times; }";
    toolbar = [
      "undo redo | formatselect | fontselect | fontsizeselect | link | media image | insertdatetime |",
      "| alignleft aligncenter alignright alignjustify | bold italic strikethrough underline backcolor | outdent indent | removeformat | diffButton",
    ];
  } else if(props.editorType === EditorType.note) {
    fonts = "Times New Roman=times new roman,times;";
    fontsizes = "8pt";
    body = "{ font-family: times new roman, times; }";
    toolbar = [
      "undo redo | formatselect | fontselect | fontsizeselect | link | media image | insertdatetime |",
      "| alignleft aligncenter alignright alignjustify | bold italic strikethrough underline backcolor | outdent indent | removeformat | diffButton",
    ];
  }

  return (
    <Editor
      id="editor"
      initialValue={text}
      apiKey="spb22jv4iz1ga9w68mm97g8o9v8s5kld3ds7wujwaxk4eyu5"
      // tinymceScriptSrc="../../../public/tinymce.min.js"
      disabled={props.disable ? props.disable : false}
      init={{
        height: height,
        width: width,
        // skin: (window.matchMedia('(prefers-color-scheme: oxide)').matches ? 'oxide-dark' : 'oxide'),
        // content_css: 'dark',
        menubar: "file edit insert view format table tools help",
        // selector: "textarea"
        content_style:
          // importing font from local was having issues which is why this is here
          "@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); body { font-family: Montserrat; }" +
          "body " +
          body +
          "" +
          "del { background-color: rgba(255, 74, 46, 0.6); }" +
          "ins { background-color: rgba(105, 219, 132, 0.6); }" +
          "div {margin: 0; padding: 0;}" +
          "p {margin: 0; padding: 0;}",
        plugins: [
          "advlist autolink lists autolink link charmap print",
          "searchreplace visualblocks save image",
          "insertdatetime media table paste help wordcount",
        ],
        font_formats: fonts,
        fontsize_formats: fontsizes,
        indentation: '50px',
        onchange_callback: "updateText",
        toolbar: toolbar,
        forced_root_block : 'div',
        formats: {
          underline: { inline: 'u' },
          strikethrough: { inline: 's' },
        },
        save_onsavecallback: function () {
          // make sure all ins and del tags are removed before save
          var child = this.getContent.firstChild;
          while (child) {
            if (child.nodeName.toLowerCase() == "del") {
              // delete the <del> tags and thier content
              child.remove();
            }
            child = child.nextSibling;
          }
          if (props.saveText) {
            props.saveText(this.getContent());
          }
        },
        paste_postprocess: (editor: any, args: any) => {
          // replace any <p></p> with <div></div>
          var tempHtml = args.node.innerHTML;
          tempHtml = tempHtml.replace(new RegExp('<p', 'g'), "<div")
          tempHtml = tempHtml.replace(new RegExp('</p', 'g'), "</div");

          args.node.innerHTML = tempHtml;
        },
        // This can be uncommented if we want fullscreen launching
        setup: function (editor: any) {
          // Setup Text editor based on type
          editor.on("init", function () {
            if (props.editorType == EditorType.paragraph) {
              // setup default select font format
              editor.execCommand("fontName", false, "Arial");
              editor.execCommand("fontSize", false, "12pt");
            } else if (props.editorType == EditorType.default) {
              // setup default select font format
              editor.execCommand("fontName", false, "Arial");
              editor.execCommand("fontFamily", false, "Arial, Times New Roman");
              editor.execCommand("fontSize", false, "12pt");
            } else if (props.editorType == EditorType.note) {
              // setup default select font format
              editor.execCommand("fontName", false, "Arial");
              editor.execCommand("fontFamily", false, "Arial, Times New Roman");
              editor.execCommand("fontSize", false, "8pt");
            }
          });
          // editor.ui.registry.addButton("redlineButton", {
          //   tooltip: "Redline",
          //   icon: "strike-through",
          //   onAction: function () {
          //     if (editor.selection.getEnd().className != "redline") {
          //       editor.insertContent(
          //         '<s class="redline" style="text-decoration: line-through">' +
          //         editor.selection.getContent() +
          //         '</s>'
          //       );
          //     } else {
          //       // get and replace data to remove redline
          //       const edit = editor.selection.getEnd().innerHTML;
          //       editor.selection.getEnd().remove();
          //       editor.insertContent("<span>" + edit + "</span>");
          //     }
          //   },
          // });
          editor.ui.registry.addButton("underlineButton", {
            tooltip: "Draft - Underline",
            icon: "underline",
            onAction: function () {
              if (editor.selection.getEnd().className != "underline-text") {
                // add underline data
                editor.insertContent(
                  '<u class="underline-text" style="text-decoration: underline">' +
                  editor.selection.getContent() +
                  "</u>"
                );
              } else {
                // get and replace data to remove underline
                const edit = editor.selection.getEnd().innerHTML;
                editor.selection.getEnd().remove();
                editor.insertContent("<span>" + edit + "</span>");
              }
            },
          });
          editor.ui.registry.addButton("diffButton", {
            tooltip: "Difference Compare",
            icon: "ltr",
            onAction: function () {
              // toggles diff compare window
              // only allow this button to appear when also paired with DiffCompare component
              if (props.diffView) {
                diff ? diff = false : diff = true;
                props.diffView(diff);
              }
            },
          });
          editor.on("keydown", function (ed: any) {
            if (ed.key == "Tab") {
              // tab pressed
              editor.insertContent("&emsp;&emsp;&emsp;"); // inserts tab
              ed.preventDefault();
              ed.stopPropagation();
              return false;
            }
          });
        },
      }}
      onEditorChange={props.onTextChange}
    />
  );
};
