import { Fab, FormLabel, IconButton, Tooltip } from "@material-ui/core";
import { colors } from "material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { Icon } from "../../../../..";
import { taskAPI, taskTypeAPI } from "../../../../../../utils/api";
import { Task } from "../../../../../../utils/model";
import "./TaskDisplay.scss";

interface INoticeFilingTabProps {
  task: Task;
  onClick: (task: Task) => void;
}

export const TaskDisplay = (props: INoticeFilingTabProps) => {
  const { task, onClick } = props;
  // Get All Notice Filings that reference this parent and display a link to them
  const color: "inherit" | "primary" | "secondary" | "default" | undefined =
    "primary";
  const icon: string = "add";
  const label: string = "Add Notice Filing";
  const [noticeTasks, setNoticeTasks] = useState<Task[]>([]);

  const setupNoticeTasks = useCallback(async () => {
    const taskTypes = await taskTypeAPI.getAllTaskTypes();
    // Get the notice task type
    // Filter by the notice task type
    const tasks = await taskAPI.getAllTasks();
    setNoticeTasks(tasks);
  }, [task]);

  useEffect(() => {
    setupNoticeTasks();
  }, [setupNoticeTasks, task]);

  return (
    <div className="sub-filing-task">
      <IconButton
        size="small"
        disableTouchRipple={true}
        className={`action-icon`}
        onClick={() => {
          onClick(task);
        }}
        color={"primary"}
      >
        <Tooltip title={task.description} placement="bottom">
          <Fab className={"primary"}>{<Icon icon={task.icon} />}</Fab>
        </Tooltip>
      </IconButton>
      <FormLabel>{task.name}</FormLabel>
    </div>
  );
};
