import { Workflow } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class WorkflowAPI extends BaseAPI<Workflow> {
  constructor() {
    super("workflows", APISettings.adminRulesApiUrl);
  }

  public patch: (workflowId: number, name: string) => Promise<any> = async (
    workflowId: number,
    name: string
  ) => {
    const response = await BaseAPI.axios.patch(
      `${this.apiUrl}/${this.type}/${workflowId}`,
      { name: name }
    );
    return response.data;
  };

  public addStatus: (workflowId: number, statusId: number) => Promise<any> =
    async (workflowId: number, statusId: number) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/${this.type}/AddStatus`,
        { workflowId: workflowId, statusId: statusId }
      );
      return response.data;
    };

  public removeStatus: (workflowId: number, statusId: number) => Promise<any> =
    async (workflowId: number, statusId: number) => {
      const response = await BaseAPI.axios.put(
        `${this.apiUrl}/${this.type}/RemoveStatus`,
        { workflowId: workflowId, statusId: statusId }
      );
      return response.data;
    };
}

export const workflowAPI = new WorkflowAPI();
