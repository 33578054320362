import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
// import Typography from "@material-ui/core/Typography";
import React from "react";
import { useSearch } from "../../../../../utils/hooks";
import "./ButtonAppBar.scss";

interface IButtonAppBarProps {
  clearForm: () => void
}

export const ButtonAppBar = (props: IButtonAppBarProps) => {
  const { clearSearchFilters } = useSearch();
  return (
    <div className="button-app-bar">
      <AppBar position="static" className="toolbar">
        <Toolbar>
          <h2 aria-level={1} className="shrunk-h2">
            Administrative Code Search
          </h2>
          <Button className="button-app-bar" onClick={() => props.clearForm()}>
            Clear Search
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};
