import { BaseAPI } from ".";
import { AxiosRequestConfig } from "axios";
import { APISettings } from "../settings/APISettings";
import { StorageContainer } from "../enums";

const uploadConfiguration: AxiosRequestConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};

class FileAPI extends BaseAPI<any> {
  constructor() {
    super("file", APISettings.adminRulesApiUrl);
  }
  public getFileUrl: (
    fileName: string,
    storageContainer: StorageContainer
  ) => Promise<string> = async (
    fileName: any,
    storageContainer: StorageContainer
  ) => {
    let file =
      `https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=${StorageContainer[storageContainer]}&name=${fileName}`.toString();

    return file;
  };

  public copyFileUrl: (
    fileName: string,
    storageContainer: StorageContainer
  ) => Promise<string> = async (
    fileName: any,
    storageContainer: StorageContainer
  ) => {
    let file =
      `https://oklahomarules.blob.core.windows.net/${StorageContainer[storageContainer]}/${fileName}`.toString();
    return file;
  };

  public uploadFile: (
    fileName: string,
    formData: FormData,
    storageContainer: StorageContainer,
    useName?: string,
    onUploadProgress?: (progressEvent: any) => void
  ) => Promise<string> = async (
    fileName: string,
    formData: FormData,
    storageContainer: StorageContainer,
    useName?: string,
    onUploadProgress?: (progressEvent: any) => void
  ) => {
    if (onUploadProgress) {
      uploadConfiguration.onUploadProgress = onUploadProgress;
    }

    let url = `https://okadministrativerules.azurewebsites.net/api/BlobStorageCreateFile?name=${fileName}&storageContainer=${
      StorageContainer[storageContainer]
    }&useName=${useName ?? "false"}`;

    return (await BaseAPI.axios.post(`${url}`, formData, uploadConfiguration))
      .data;
  };

  public deleteFile: (
    fileName: string,
    storageContainer: StorageContainer
  ) => Promise<string> = async (
    fileName: string,
    storageContainer: StorageContainer
  ) => {
    let url = `https://okadministrativerules.azurewebsites.net/api/BlobStorageDeleteFile?name=${fileName}&storageContainer=${StorageContainer[storageContainer]}`;
    return (await BaseAPI.axios.post(`${url}`)).data;
  };

  get: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for files.");
  };
  getAll: () => Promise<null[]> = async () => {
    throw new Error("This API is not Defined for files.");
  };
  create: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for files.");
  };
  update: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for files.");
  };
  delete: () => Promise<null> = async () => {
    throw new Error("This API is not Defined for files.");
  };
}

export const fileAPI = new FileAPI();
