import React from "react";
import { FilingType } from "../model/FilingType";
import { UrlParser } from "../";

export const useQueryFilingType = (
  search: string,
  filingTypes: FilingType[]
):string => {
  const filingType = React.useMemo(() => {
    if (filingTypes != null) {
      const queryInfo = UrlParser.Filing.getParameters(search);
      const isValidFilingType: boolean =
        queryInfo.filingtype != null
          ? filingTypes.filter(
              (filingType) => filingType?.name === queryInfo.filingtype
            ).length > 0
          : false;
      if (isValidFilingType) {
        return queryInfo.filingtype;
      } else {
        return filingTypes[0]?.name ?? "";
      }
    } else {
      return "";
    }
  }, [filingTypes, search]);

  return filingType ?? "";
};
