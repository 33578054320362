import { Button, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
// import { FilingAddForm } from "../../stateOffice/pages/FilingBuilder/components";
import { UrlParser } from "../../utils";
import { draftAPI, filingAPI, segmentAPI } from "../../utils/api";
import { PublicationStatus, VisibilityLevel } from "../../utils/enums";
import { Draft, EntityType, Filing, FilingType, Segment } from "../../utils/model";
import { FilingQuery } from "../../utils/urlParser";
import { Dialog } from "../Dialog";
import {
  EditTab,
  HistoryTab,
  MainTab,
  ManageTab,
  ActionTab,
  ReviewTab,
  // SubFilingTab,
} from "./components";
import { CommentTab } from "./components/CommentTab";
import { FilingHeader } from "./components/FilingHeader";
import "./FilingHome.scss";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import { DraftTab } from "./components/DraftTab/DraftTab";

interface IFilingHomeProps extends RouteComponentProps {
  updateRows: () => any;
}

export type FilingView =
  "Actions"
  | "Filing"
  | "Edit"
  | "Comments"
  | "Draft"
  | "History"
  | "Segment"
  | "Review";

export interface FormImperativeHandles {
  getValues(): any;
  getType(): string;
}

export const FilingHomeWithRouter = (props: IFilingHomeProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [segment, setSegment] = useState<Segment | null>(null);
  const [filing, setFiling] = useState<Filing | null>(null);
  const [baseFiling, setBaseFiling] = useState<Filing | undefined>(undefined);
  const [filingType, setFilingType] = useState<FilingType | undefined>(
    undefined
  );
  const [selectedView, setSelectedView] = useState<FilingView>("Actions");
  const [showNewFiling, setShowNewFiling] = useState<boolean>(false);
  const [query, setQuery] = useState<FilingQuery>({});
  const [snackOpen, setSnackOpen] = React.useState(false);
  const formRef = React.useRef<FormImperativeHandles>();
  const [previewOpen, setPreviewOpen] = useState<boolean>(true);
  const [draft, setDraft] =useState<Draft | null>(null)

  const [values, setValues]: any = React.useState({
    published: PublicationStatus.Preparing,
    visibility: VisibilityLevel.Title,
    filingType: "",
    segmentId: -1,
    entityType: EntityType,
    entityTypeId: "",
    entityId: -1,
  });
  const [user, setUser] = React.useState<any>(null);

  const { getUser } = useContext(AuthenticationContext);

  const getAuthentication = async () => {
    let authorized = await getUser();

    // updateEditStatus(authorized?.profile);
    return authorized?.profile;
  };


  const setupValues = React.useCallback(() => {
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    if (queryInfo.filingid != null && queryInfo.filingtype != null) {
      if (queryInfo.segmentid != null) {
        setValues({
          published: PublicationStatus.Preparing,
          visibility: VisibilityLevel.Title,
          filingType: queryInfo.filingtype,
          entityType: EntityType,
          entityTypeId: "",
          entityId: -1,
          segmentId: queryInfo.segmentid,
        });
      } else {
        setValues({
          published: PublicationStatus.Preparing,
          visibility: VisibilityLevel.Title,
          filingType: queryInfo.filingtype,
          entityType: EntityType,
          entityTypeId: "",
          entityId: -1,
        });
      }
    }
  }, [props.location.search]);


  const previewDraft = () => {
    //Preview draft
    previewOpen ? setPreviewOpen(false) : setPreviewOpen(true);
  };

  const setupUrl = React.useCallback(async () => {
    // setFiling(null);
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    const isValidFilingAction: boolean = queryInfo.filingtype != null && queryInfo.filingid != null;


    if (isValidFilingAction && queryInfo.filingid) {
      setShow(true);
      let value: Filing = await filingAPI.getFilingsById(queryInfo.filingid!).then();
      setFiling(value);

      if (queryInfo.segmentid != null) {
        const segmentItem: any = segmentAPI.get(queryInfo.segmentid).then((value) => { return value });
        setSegment(segmentItem);
        setSelectedView("Segment");
      }
    } else {
      setShow(false);
    }
  }, [props.location.search]);

  const onMount = async () => {
    if (!user) {
      // console.log("User not set, setting user:");
      setUser(await getAuthentication());
    } else {
      // console.log("User set, titles allowed:", user.Titles);
    }
  }
  useEffect(() => {
    onMount();
    if (filing == null) {
      setupUrl();
    } else {
      getDraft();
    }
    setupValues();
  }, [setupUrl, setupValues, filing]);

  const getDraft = async()=>{
    if(filing != null){
      const draft = await draftAPI.getDraft(filing.draftId!);
      setDraft(draft);
    }
  }

  const closeAction = () => {
    const queryInfo = UrlParser.Filing.getParameters(props.location.search);
    if (queryInfo.filingtype != null && queryInfo.filingid != null) {
      props.history.push(
        UrlParser.Filing.parseFilingType(queryInfo.filingtype)
      );
      setFiling(null);
      setSelectedView("Actions");
    }
    setShow(false);
    props.updateRows();
  };

  // update which component is displayed in the body here
  const updateView = (tab: FilingView) => {
    if(tab ==='Draft'){
      setPreviewOpen(true);
    }
    setSelectedView(tab);
  };

  // fetches filing info after update to reload all child components
  const triggerReload = () => {
    setupUrl();
  }

  const renderSelectedView = () => {
    if (filing != null) {
      // console.log(selectedView);
      switch (selectedView) {
        case "Actions":
          return <ActionTab saveForm={saveForm} ref={formRef} filing={filing} triggerReload={triggerReload} />;
        case "Filing":
          return <MainTab userTitleAccess={user?.Titles} userRoleAccess={user?.role} saveForm={saveForm} ref={formRef} filing={filing} />;
        case "Comments":
          return <CommentTab filingId={filing.id!} />
        case "Draft":
          return <DraftTab open={previewOpen} handleOpen={previewDraft} draftData={draft}/>
        // case "Segment":
        //   return <EditTab segment={segment} filing={filing}  />;
        // case "Manage":
        //   if(filing.entity?.title) {
        //     return <ManageTab filing={filing} {...props} />;
        //   } else {
        //     return <div/>
        //   }
        case "History":
          return <HistoryTab filingId={filing.id!} />;
        // case "Review":
        //   return <ReviewTab filing={filing} />;
        default:
          return (<div></div>);
      }
    }
  };

  /**
   * Cannot generate summary
   * @param {any} value - The value of the form.
   * @param {string} type - string - the type of form being saved.
   * @returns None
   */
  const saveForm = async (value: any, type: string) => {
    let filingName = value.filingName;
    if (filingName.trim().length == 0) {
      setSnackOpen(true);
      return;
    }

    if (filing) {
      filing.entity = value;
      filing.draftId = value.draftId;
      filing.effectiveOn = value.effectiveDate ?? null;
      if (value.docketId) {
        filing.docketId = value.docketId;
      }
      filing.name = value.filingName;
    }

    try {
      // update existing item
      if (filing && filing.id != null) {
        await filingAPI.updateFilings(filing.id, filing);
        // await filingAPI.patch(filing.id, filing);
        closeAction();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getActions = () => {
    switch (selectedView) {
      case "Filing":
        return (
          <Button
            className="save-button"
            variant="contained"
            onClick={() => {
              if (formRef.current) {
                saveForm(
                  formRef.current.getValues(),
                  formRef.current.getType()
                );
              }
            }}
            color="primary"
          >
            Save
          </Button>
        );
      case "Edit":
        return (
          <Button
            autoFocus
            className="save-text"
            onClick={() => { }}
            color="primary"
          >
            Save changes
          </Button>
        );
      default:
        console.warn("No Action Selected!");
    }
  };

  const closeAddDialog = () => {
    setShowNewFiling(false);
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  // if (showNewFiling && filingType) {
  //   return (
  //     <FilingAddForm
  //       open={showNewFiling}
  //       handleClose={closeAddDialog}
  //       form={query.form}
  //       filing={baseFiling}
  //       filingType={filingType}
  //     />
  //   );
  // }

  return (
    <Dialog
      fullScreen={
        // selectedView === "Edit" ||
        // selectedView === "Manage" ||
        // selectedView === "Segment" ||
        selectedView === "Review"
      }
      title={
        <FilingHeader
          filing={filing}
          updateView={updateView}
          selectedView={selectedView}
        />
      }
      actions={filing ? getActions() : <CircularProgress />}
      open={show == true && filing != null}
      onClose={closeAction}
      className="filing-home"
      maxWidth="lg"
    >
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Filing Name Required</Alert>
      </Snackbar>
      {filing != null ? renderSelectedView() : null}
    </Dialog>
  );
};

export const FilingHome = withRouter(FilingHomeWithRouter);
