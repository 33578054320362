import { Avatar, IconButton } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import React, { useCallback } from "react";
import { AuthenticationContext } from "./../../../../utils/authentication/AuthenticationProvider";
import "./UserIndicator.scss";

interface IUserIndicatorProps {}

export const UserIndicator = (props: IUserIndicatorProps) => {
  const { signout, isAuthenticated, getUser } = React.useContext(
    AuthenticationContext
  );
  const onAuthentication = useCallback(
    () => async () => {
      const user = await getUser();
      const test = user as any;
      const userPhotoUrl = test.profile.photo;
      setProfilePhotoUrl(userPhotoUrl);
      setAuthentication(isAuthenticated());
    },
    [getUser, isAuthenticated]
  );

  const initialAuthenticated = isAuthenticated();
  const [authenticated, setAuthentication] =
    React.useState(initialAuthenticated);
  const [profilePhotoUrl, setProfilePhotoUrl] = React.useState<string>("");
  const authenticate = useCallback(async () => {
    if (isAuthenticated()) {
      onAuthentication();
      if (profilePhotoUrl === "") {
        const user = await getUser();
        if (user != null) {
          setProfilePhotoUrl(user.profile.photo);
        }
      }
    }
  }, [isAuthenticated, onAuthentication]);

  React.useEffect(() => {
    authenticate();
  }, [authenticate, isAuthenticated]);

  if (authenticated) {
    return (
      <div className="user-indicator">
        <div className="user-info">
          <Avatar alt="user profile" src={profilePhotoUrl} />
          <IconButton aria-label="logout" className="logout" onClick={signout}>
            <ExitToApp />
          </IconButton>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
