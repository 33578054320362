import { Button, Dialog, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { segmentAPI } from '../../utils/api';
import { Paragraph } from '../Paragraph';
import "./AdminEditor.scss";
import { SegmentEditForm, NoteForm } from './Components';
import { CurrentRuleEditPreview } from './Components/CurrentRuleEditPreview';

interface IAdminEditor {
    note?: any;
    sectionNum?: any;
    editData?: any;
    reload: () => void;
    openAlert: (alertType: boolean, response: string) => void;
    isReadOnly?: boolean;
    type:"EditSegment" | "EditNote" | "CreateNote";
}


export const AdminEditor = (props: IAdminEditor) => {
    const {sectionNum,editData,reload,openAlert,isReadOnly,type, note} = props;
    const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
    const [editedData, setEditedData] = React.useState<any>(null);
    const [children, setChildren] = React.useState<any>(<div> Some text to preview</div>)
    const [previewData, setPreviewData] = React.useState<any>([]);

    const _renderEdit = () => {
        let JSXElement:JSX.Element = (<div>Cannot render editor</div>);

        if(type == "EditSegment") {
            JSXElement = (
                <SegmentEditForm
                    editSegmentData={editData}
                    reload={reload}
                    sectionNum={sectionNum}
                    type={type}
                    openAlert={openAlert}
                    updateEditedText={updateEditedText}
                />
            )
        } else {
            JSXElement = (<NoteForm note={note} type={type} reload={props.reload!}/>);
        }

        return JSXElement;
    }

    const updateEditedText = (text:any) => {
        setEditedData(text);
    }

    const togglePreview = () => {
        previewOpen ? setPreviewOpen(false) : setPreviewOpen(true);
    }

    /* This is a ternary operator that returns the _renderEdit() function if editFormData is not null,
     otherwise it returns null. */
    return (
    <div>
        {type != "CreateNote" ? (
            <Button onClick={togglePreview} variant="contained" className="preview-button">{previewOpen ? "Close Preview" : "Preview Changes"}</Button>
        ) : null}
        {previewOpen ? (
            <div>
               <CurrentRuleEditPreview note={note} editData={editedData} sectionNum={sectionNum} type={type}/>
            </div>
        ) : null}

        {editData || note ? (<div className={previewOpen ? "hidden" : ""}>{_renderEdit()}</div>) : null}
    </div>
    );
};
