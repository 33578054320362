import { EmailTemplate } from "../model/EmailTemplate";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";

class EmailTemplateAPI extends BaseAPI<EmailTemplate> {
  constructor() {
    super("emailTemplates", APISettings.adminRulesApiUrl);
  }

  public getEmailTemplatesById: (id: number) => Promise<EmailTemplate> = async (id: number) => {
    return (
      await BaseAPI.axios.get(`${this.apiUrl}/GetEmailTemplatesById/${id}`)
    ).data;
  };

  public getByIdAsync: (id: number) => Promise<EmailTemplate> = async (id: number) => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetByIdAsync/${id}`)).data;
  };

  public getEmailTemplates: (pagingParameters?: any) => Promise<EmailTemplate> = async (pagingParameters?: any) => {
    if(pagingParameters) {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetEmailTemplates?pageNumber=${pagingParameters.pageNumber}&pageSize=${pagingParameters.pageSize}`)
      ).data;
    } else {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetEmailTemplates`)
      ).data;
    }
  };

  public getEmailTemplatesForTable: () => Promise<any[]> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetEmailTemplatesForTable`)).data;
  };

  public createEmailTemplates: (emailTemplate: EmailTemplate) => Promise<any> = async (emailTemplate: EmailTemplate) => {
      const response = await BaseAPI.axios.post(`${this.apiUrl}/CreateEmailTemplates`, {emailTemplate});
      return response.data;
    };

  public removeEmailTemplates: (id: number) => Promise<any> = async (id: number) => {
    const response = await BaseAPI.axios.post(`${this.apiUrl}/RemoveEmailTemplates/${id}`);
    return response.data;
  };

  public updateEmailTemplate: (emailTemplate: EmailTemplate) => Promise<EmailTemplate> = async (emailTemplate: EmailTemplate) => {
    const response = await BaseAPI.axios.put(`${this.apiUrl}/UpdateEmailTemplate`, emailTemplate);
    return response.data;
  };
}

export const emailTemplateAPI = new EmailTemplateAPI();