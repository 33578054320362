import { Box, Drawer, IconButton, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import {
    AllInbox,
    Home,
    InsertComment,
    MenuBook,
} from "@material-ui/icons";
import MenuIcon from '@material-ui/icons/Menu';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import { NavLink } from "../Menu/NavLink";
import './MenuDrawer.scss';


type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IMenuDrawerProps {

}

const useStyles = makeStyles({
    list: {
        width: "auto",
    },
    fullList: {
        width: 'auto',
    },
});

export const MenuDrawer = (props: IMenuDrawerProps) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
        top: false,
        left: false,
        bottom: false,
    });

    const { signin, isAuthenticated } = useContext(AuthenticationContext);
    const initialAuthenticated = isAuthenticated();
    useEffect(() => {
        setAuthentication(isAuthenticated());
    }, [isAuthenticated]);
    const [authenticated, setAuthentication] = useState(initialAuthenticated);

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        state.top = open;
        setState({ ...state, [anchor]: open });
    };

    const renderMenuItem = (text: string) => {
        switch (text) {
            case "Home":
                return (<NavLink to='/home' icon={Home}>{text}</NavLink>);
            case "Code":
                return (<NavLink to='/code' icon={MenuBook}>{text}</NavLink>)
            case "Register":
                return (<NavLink to="/registers" icon={MenuBook}>{text}</NavLink>)
            case "FeeIncrease":
                return (<NavLink to="/feeincrease" icon={MenuBook}>Fee Increases</NavLink>)
            case "ProposedRules":
                return (<NavLink to="/proposedrules" icon={MenuBook}>Proposed Rules</NavLink>)
            case "News":
                return (<NavLink to="/news" icon={InsertComment}>{text}</NavLink>)
            case "QuickLinks":
                return (<NavLink to="/quicklinks" icon={AllInbox}>{text}</NavLink>)
                break;
        }
    }

    const sideList = (anchor: Anchor) => (
        <Box
            role="menu"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div
                className={classes.list}
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <List>
                    {['Home', 'Code', 'Register', 'FeeIncrease', 'ProposedRules', 'News', 'QuickLinks'].map((text, index) => (
                        <ListItem button key={index}>
                            {renderMenuItem(text)}
                        </ListItem>
                    ))}
                    <ListItem button onClick={() => (window.location.href = "/subscriptionLogin")}>
                        <Typography>
                            Subscriber Login
                        </Typography>
                    </ListItem>
                    <ListItem button onClick={signin}>
                        <Typography>
                            Login
                        </Typography>
                    </ListItem>
                </List>
            </div>
        </Box>
    );


    return (
        <div>
            {(['right'] as const).map((anchor) => (
                <Fragment key={anchor}>
                    <IconButton className="menu-icon" aria-label="menu" onClick={toggleDrawer('top', true)}>
                        <MenuIcon />
                    </IconButton>
                    <Drawer anchor={anchor} open={state.top} onClose={toggleDrawer('top', false)}>
                        {sideList('top')}
                    </Drawer>
                </Fragment>
            ))}
        </div>
    );
}

