import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

import { CmsType, EditorType } from "../../utils/enums";
import { Authorized } from "../Authorized";
import { DiffCompare } from "../DiffCompare";
import { EditButton } from "./../EditButton";
import "./CustomizedDialogs.scss";
import { CmsItem } from "../../utils/model";
import { cmsItemAPI } from "../../utils/api";
import { RichTextEditor } from "../RichTextEditor";

interface ICustomizedDialogsProps {
  id: number;
  name: string;
  onClick: any;
  editorType: EditorType;
  dialogText: string;
  onTextChange: (content: any, editor: any) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const CustomizedDialogs = (props: ICustomizedDialogsProps) => {
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [text, setText] = React.useState<string>(props.dialogText);
  const [diff, setDiff] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const saveText = async (text: string): Promise<void> => {
    // const textString = JSON.stringify(text);
    // API Call to update Text
    if (text.length > 0) {
      const cmsItem = new CmsItem(props.name, text, CmsType.HTML);
      cmsItem.id = props.id;
      setOpenSnack(true);
      try {
        await cmsItemAPI.updateCmsItems(cmsItem);
        props.onClick();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const diffView = (diffVal?: boolean) => {
    if(diffVal) {
      setDiff(diffVal);
    }
  };

  const onTextChange = (value: string) => {
    setText(value);
  };

  const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenSnackError(false);
  };

  return (
    <div className="customized-dialogs">
      <Authorized roles={["Office of Administrative Rules", "Global User Administrator"]}>
        <EditButton className="text-edit-position" onClick={handleClickOpen} />
      </Authorized>

      <Dialog
        fullScreen
        disableEnforceFocus
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.name}
        </DialogTitle>
        <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
          <Alert onClose={handleSnackClose} severity="success">
            Saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSnackError}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
          <Alert onClose={handleSnackClose} severity="error">
            failed to save!
          </Alert>
        </Snackbar>
        <DialogContent dividers>
          <Typography gutterBottom>
            <span style={{ display: "flex", height: "85vh" }}>
              <div style={{ maxWidth: "100%", width: "100%" }}>
                {props.editorType === EditorType.paragraph ? (
                  // Uncomment to revert
                  // <CmsParagraphEditor
                  //   saveText={saveText}
                  //   diffView={diffView}
                  //   editorType={props.editorType}
                  //   onTextChange={onTextChange}
                  //   text={props.dialogText}
                  // />

                  <RichTextEditor
                    text={props.dialogText}
                    editorType={props.editorType}
                    diffView={diffView}
                    saveText={saveText}
                    onTextChange={onTextChange}
                  />
                ) : props.editorType === EditorType.title ||
                  props.editorType === EditorType.subTitle ? (
                  <RichTextEditor
                    text={props.dialogText}
                    editorType={props.editorType}
                    diffView={diffView}
                    saveText={saveText}
                    onTextChange={onTextChange}
                  />
                ) : // Uncomment to revert
                // <CmsTitleEditor
                //   saveText={saveText}
                //   diffView={diffView}
                //   editorType={props.editorType}
                //   onTextChange={onTextChange}
                //   text={props.dialogText}
                // />
                null}
              </div>
              <div className={`${diff ? "diff-visible" : "diff-hide"}`}>
                <DiffCompare
                  text={props.dialogText}
                  textCompare={text}
                  editorType={props.editorType}
                />
              </div>
            </span>
          </Typography>
          <Typography gutterBottom></Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => saveText(text)} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
