import { CommentThread } from "../model";
import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { ThreadType } from "../enums";

class CommentThreadAPI extends BaseAPI<CommentThread> {
  constructor() {
    super("CommentThread", APISettings.adminRulesApiUrl);
  }

  public getCommentThread: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.get(
      `${this.apiUrl}/${this.type}/${id}`
    );
    return response.data;
  };

  public getCommentThreads: () => Promise<CommentThread> = async () => {
    return (await BaseAPI.axios.get(`${this.apiUrl}/GetCommentThreads`)).data;
  };

  public getCommentThreadsById: (id: number) => Promise<CommentThread> = async (
    id: number
  ) => {
    try {
      return (
        await BaseAPI.axios.get(`${this.apiUrl}/GetCommentThreadsById/${id}`)
      ).data;
    } catch (error) {
      return null;
    }
  };

  public getCommentThreadByReferenceId: (id: number) => Promise<CommentThread> =
    async (id: number) => {
      try {
        return (
          await BaseAPI.axios.get(
            `${this.apiUrl}/GetCommentThreadsByReferenceId/${id}`
          )
        ).data;
      } catch (error) {
        return {};
      }
    };
  public createCommentThreads: (commentThread: CommentThread) => Promise<any> =
    async (commentThread: CommentThread) => {
      // console.log("Comment thread in front end api", commentThread)
      const response = await BaseAPI.axios.post(
        `${this.apiUrl}/CreateCommentThreads`,
        commentThread
      );
      return response.data;
    };

  public removeCommentThreads: (id: number) => Promise<any> = async (
    id: number
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/RemoveCommentThreads/${id}`
    );
    return response.data;
  };

  public updateCommentThreads: (
    id: number,
    commentThread: CommentThread
  ) => Promise<CommentThread> = async (
    id: number,
    commentThread: CommentThread
  ) => {
    const response = await BaseAPI.axios.post(
      `${this.apiUrl}/UpdateCommentThreads/${id}`,
      { commentThread }
    );
    return response.data;
  };
}

export const commentThreadAPI = new CommentThreadAPI();
