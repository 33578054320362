import { NoteData } from ".";

export class SegmentHistoryData {
    constructor(
        description: string,
        statusId: number,
        statusName: string,
        text:string,
        notes: NoteData[],
        images: any[],
        renumberedToSegmentNumber: string,
    ) {
        this.description = description;
        this.statusId = statusId;
        this.statusName = statusName;
        this.text = text;
        this.notes = notes;
        this.images = images;
        this.renumberedToSegmentNumber = renumberedToSegmentNumber;
    }

    public description: string;
    public statusId: number;
    public statusName: string;
    public text: string;
    public notes: NoteData[];
    public images: any[];
    public renumberedToSegmentNumber: string;
}