import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Authorized } from "../../../../components";
import { EditButton } from "../../../../components/EditButton";
import { FileUpload } from "../../../../components/FileUpload";
import { cmsItemAPI } from "../../../../utils/api";
import { StorageContainer } from "../../../../utils/enums";
import { CmsItem } from "../../../../utils/model";

import "../QuickLinks.scss";
import { SaveRounded } from "@material-ui/icons";

interface IEditQuickLinkDialogProps {
  cmsNameItem: CmsItem;
  cmsLinkItem: CmsItem;
  readOnly: boolean;
  onChange: (value: CmsItem) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles({
  btn: {
    paddingTop: 10,
    paddingRight: 45,
  },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const EditQuickLinkDialog = (props: IEditQuickLinkDialogProps) => {
  const [fileName, setFileName] = useState("");
  const [fileNameLabel, setFileNameLabel] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onUpload = (guid: string, fileName: string) => {
    setFileName(guid);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileNameLabel(event.target.value);
    console.error(fileName);
  };

  const onSave = async () => {
    const cmsNameItem = props.cmsNameItem;
    const cmsLinkItem = props.cmsLinkItem;

    cmsLinkItem.value = fileName;
    cmsNameItem.value = fileNameLabel;

    if (cmsNameItem.id) {
      await cmsItemAPI.updateCmsItems(cmsNameItem);
      handleClose();
      if (props.onChange) {
        props.onChange(cmsNameItem);
      }
    }

    if (cmsLinkItem.id) {
      await cmsItemAPI.updateCmsItems(cmsLinkItem);
      handleClose();
      // if (props.onChange) {
      //   props.onChange(cmsLinkItem.value[0].text);
      // }
    }

    handleClose();
  };

  return (
    <div>
      <Authorized roles={["Office of Administrative Rules", "Global User Administrator"]}>
        <EditButton className="image-edit-position" onClick={handleClickOpen} />
      </Authorized>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className={classes.btn}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {Object(props.cmsNameItem.value[0]).text}
          </DialogTitle>
        </div>
        <DialogContent dividers>
          {props.readOnly ? (
            <TextField
              label="Edit Label"
              variant="outlined"
              fullWidth
              value={fileNameLabel}
              onChange={handleNameChange}
            />
          ) : (
            <div className="form-fields">
              <TextField
                label="Edit Label"
                variant="outlined"
                fullWidth
                value={fileNameLabel}
                onChange={handleNameChange}
              />
              <FileUpload
                onUploaded={onUpload}
                allowedFileTypes={["pdf"]}
                storageContainer={StorageContainer.PublicCms}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
