import { AppBar, Button, LinearProgress, makeStyles, Tab as MaterialTab, Tabs, } from "@material-ui/core";
import PublicIcon from "@material-ui/icons/Public";
import UserIcon from "@material-ui/icons/SupervisedUserCircle";
import React, { useCallback, useContext, useEffect, useMemo, useState, } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { AuthenticationContext } from "../../utils/authentication/AuthenticationProvider";
import { useNavigationDispatch } from "../../utils/hooks";
import { ApplicationUser } from "../../utils/model";
import { APISettings, StateOfficeNavigationSettings, Tab, } from "../../utils/settings";
import { Authorized } from "../Authorized";
import { UserIndicator } from "./components";
import "./Navigation.scss";
import IconButton from "@material-ui/core/IconButton";
import { ExitToApp } from "@material-ui/icons";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { DropDownSelect } from "../DropDownSelect";

interface INavigationProps {
  tabName: string;
}

const useClasses = makeStyles((theme) => ({
  header: {
    background: "#fff",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  rightAlign: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
  },
}));

// const CustomTabs = withStyles({
//   indicator: {
//     backgroundColor: "#004E9A",
//   },
// })(Tabs);

export const Navigation = withRouter(
  (props: RouteComponentProps<INavigationProps>) => {
    const classes = useClasses();
    const navigationOptions = useMemo(
      () => new StateOfficeNavigationSettings(),
      []
    );
    const dispatchNavigation = useNavigationDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const [userRole, setUserRole] = useState<any[]>(["unassigned"]);

    // Get User Authorization Method
    const { signout, getUser } = useContext(AuthenticationContext);

    const onLoad = useCallback(async () => {
      const appUser = await getUser();
      const user: ApplicationUser = appUser as ApplicationUser;
      // console.log(user);
      if (!user.profile.role) {
        setUserRole(["no roles"]);
      } else if (user.profile.role.includes("Office of Administrative Rules") || user.profile.role.includes("Global User Administrator")) {
        setUserRole(["oar"]);
      } else if (user.profile.role.includes("Agency Liaison")) {
        setUserRole(["liaison"]);
      } else if (user.profile.role.includes("Attestation Officer")) {
        setUserRole(["attestation officer"]);
      } else if (user.profile.role.includes("Cabinet Secretary")) {
        setUserRole(["cabinet secretary"]);
      } else if (user.profile.role.includes("Legislature")) {
        setUserRole(["legislature"]);
      } else if (user.profile.role.includes("Governor")) {
        setUserRole(["governor"]);
      } else {
        setUserRole(["public"]);
      }
    }, [getUser]);

    const redirectToUserManagement = () => {
      window.location.href =
        APISettings.identityServerUrl + "/Account/AccountManagement";
    };

    const redirectToSubscriptionManagement = () => {
      window.location.href =
        APISettings.webClientUrl + "/SubscriptionManagement";
    };

    // Dropdown user options button
    const items = [
      <Button
        startIcon={<UserIcon />}
        onClick={redirectToUserManagement}>
        State Users
      </Button>,
      <Button
        startIcon={<UserIcon />}
        onClick={redirectToSubscriptionManagement}>
        Subscription Users
      </Button>
    ];

    const userTabs = useMemo(() => {
      // get all the available tabs for this user
      let userTabsByRole: Tab[] = [];
      // Switch statement to populate tabs by user type
      userRole.forEach((role) => {
        switch (role) {
          case "governor":
            userTabsByRole = navigationOptions.governorTabs;
            break;
          case "cabinet secretary":
            userTabsByRole = navigationOptions.cabinetSecretaryTabs;
            break;
          case "legislature":
            userTabsByRole = navigationOptions.legislatureTabs;
            break;
          case "oar":
            userTabsByRole = navigationOptions.oarTabs;
            break;
          case "attestation officer":
            userTabsByRole = navigationOptions.attestationOfficerTabs;
            break;
          case "liaison":
            userTabsByRole = navigationOptions.liaisonTabs;
            break;
          case "general agency":
            userTabsByRole = navigationOptions.agencyTabs;
            break;

          default:
            userTabsByRole = [];
            break;
        }
      })
      return userTabsByRole;
    }, [userRole, navigationOptions]);

    const tabs = userTabs.map((tab: any) => tab.urlPath);

    useEffect(() => {
      // On load
      onLoad();
      // Grab tab name out of the url
      const { tabName } = props.match.params;

      // Find out the index of the tab name passed in
      const index = tabs.indexOf(tabName);

      // If that index is found setup state to that index
      if (index !== -1) {
        setSelectedTab(index);
        dispatchNavigation(userTabs[index]);
        // when navigating update the redirectURI so the site brings you back to the last visited url
        localStorage.setItem("redirectUri", window.location.pathname + window.location.search);
      }
    }, [
      dispatchNavigation,
      props.match.params.tabName,
      props.match.params,
      setSelectedTab,
      // tabs,
      onLoad,
      userTabs,
    ]);

    const redirectToHome = () => {
      props.history.push("/home");
    };

    const updateTab = (newValue: string) => {
      const index = Number.parseFloat(newValue);
      setSelectedTab(index);
      dispatchNavigation(userTabs[index]);
      props.history.push(`/state/${tabs[index]}`);
    };
    if (userRole[0] === "unassigned") {
      return <LinearProgress className="navigation" />;
    }



    return (
      <div className="navigation">
        <AppBar className={classes.header}>
          <a id="skip-nav" className="skiplink-text" href="#main-content">
            Skip to Main Content
          </a>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            className="tabs"
            // textColor=""
            onChange={(event, newValue) => updateTab(newValue)}
            aria-label="navigation options"
          >
            {userTabs.map((tab, i) => (
              <MaterialTab label={tab.title} key={i} />
            ))}
          </Tabs>
          <div className={classes.rightAlign}>
            <Button startIcon={<PublicIcon />} onClick={redirectToHome}>
              Public Portal
            </Button>

            <Authorized
              showComponent={false}
              redirectToLogin={false}
              roles={["Office of Administrative Rules", "Global User Administrator"]}
            >
              <DropDownButton
                icon="cog"
                className="dropdown-button"
                text="Manage Users"
                items={items}
                look="flat"
              />
            </Authorized>

            <UserIndicator />
          </div>
        </AppBar>
      </div>
    );
  }
);
