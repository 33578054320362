import React, { useEffect } from "react";
import "./EmailTemplateTable.scss";
import { IStateTabComponentProps } from "../../../IStateTabComponentProps";
import { setEmailTypeRows } from "./EmailTemplateTableSetup";
import { emailTemplateAPI } from "../../../../../utils/api/EmailTemplateApi";
import {EmailTemplateModal} from "./components/EmailTemplateModal";
import { EmailTable } from ".";

interface IEmailTemplateTableProps extends IStateTabComponentProps {}

export const EmailTemplateTable = (props: IEmailTemplateTableProps) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [filtering, setFiltering] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [rows, setRowData]: any = React.useState([]);
  const [columns, setColumns]: any = React.useState([]);
  const [numRows, setNumRows]: any = React.useState(150);
  const [editRow, setEditRow] = React.useState({});
  const [deleteRow, setDeleteRow] = React.useState({});
  const [pagingParameters, setPagingParameters] = React.useState({pageNumber: 1, pageSize: 10});
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const toggleFiltering = () => {
    if (filtering) {
      setFiltering(false);
    } else {
      setFiltering(true);
    }
  };

  const updateRows = async (value?: any) => {
    try {
      let emailTemplates: any;
        emailTemplates = await emailTemplateAPI.getEmailTemplatesForTable();
      let vals = setEmailTypeRows(
        emailTemplates,
        "email",
        updateRows
      );
      setRowData(vals.rows);
      setColumns(vals.columns);
      setNumRows(100);
    } catch (error:any) {
      console.error("Error: ", error.message);
      setRowData([])
      setColumns([]);
      setNumRows(100);
    }
  };

  const onMount = React.useCallback(async () => {
    try {
      let emailTemplates: any = await emailTemplateAPI.getEmailTemplatesForTable();

      let vals = setEmailTypeRows(
        emailTemplates,
        "email",
        updateRows
      );
      setRowData(vals.rows);
      setColumns(vals.columns);
      setNumRows(100);
    } catch (error:any) {
      console.error("Error: ", error.message);
      setRowData([])
      setColumns([]);
      setNumRows(100);
    }
  }, []);

  const changeNumberOfRows = (value:number) => {
    let paging = {pageSize: value, pageNumber: pageNumber};
    setPagingParameters(paging);
    setPageSize(value);
    updateRows(value);
  }

  const changePage = (value:number) => {
    let paging = {pageSize: pageSize, pageNumber: value + 1};
    setPagingParameters(paging);
    setPageNumber(value + 1);
    // updateRows(paging);
  }

  useEffect(() => {
    try {
      onMount();
    } catch (error) {
      console.error(error);
    }
  }, [onMount]);

  const handleModalToggle = async (editData?: any) =>  {
    if(editData && editData.id) {
        setEditRow(await emailTemplateAPI.getByIdAsync(editData.id));
    }

    open ? setOpen(false) : setOpen(true);
  }

  return (
    <div className="email-table">
      <EmailTemplateModal open={open} editData={editRow ? editRow : null} handleClickOpen={handleModalToggle} handleClose={handleModalToggle}/>
      <EmailTable edit={handleModalToggle} rows={rows} columns={columns} add={handleModalToggle} numberOfRecords={rows.length} paging={changePage}/>
    </div>
  );
};
