import { Identifiable } from ".";
import { EmailGroup, EmailMergeItem } from "./";

export class EmailTable extends Identifiable {
  constructor(
    id: number,
    name: string,
    subject: string,
    body: string,
    ruleFiling: string,
    // toType: string,
  ) {
    super(null);
    this.id = id;
    this.name = name;
    this.subject = subject;
    this.body = body;
    this.ruleFiling = ruleFiling;
    // this.ToType = toType;
  }
  public id: number;
  public name: string;
  public subject: string;
  public body: string;
  public ruleFiling: string;
//   public ToType: string;
}
