import { BaseAPI } from ".";
// import { AxiosRequestConfig } from "axios";
import { APISettings } from "../settings/APISettings";

class SearchFuncApi extends BaseAPI<any> {
  constructor() {
    super("file", APISettings.searchFuncUrl);
  }

  public httpSearchFunction: (functionName:string, body:any) => Promise<string> = async ( functionName:string, body:any )  => {
    let url = `${APISettings.searchFuncUrl}/${functionName}`;
    return (await BaseAPI.axios.post(`${url}`, body))
  }
}

export const searchFuncAPI = new SearchFuncApi();