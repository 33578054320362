// import {Registers} from '../../pages/Registers';

import {
  AgencyAdmin,
  CurrentRules,
  FilingBuilder,
  Reports,
  WorkQueue,
  DocumentData,
  Registers,
} from "../../stateOffice/pages";
import { IStateTabComponentProps } from "../../stateOffice/pages/IStateTabComponentProps";
import { WorkflowRouter } from "../../stateOffice/pages/State";
import { NotFound } from "./../../pages/NotFound";
import { RuleTitlePluralType } from "./RuleTitlePluralType";

export class StateOfficeNavigationSettings {
  constructor() {
    this.administratorTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
      new Tab("Current Rules", CurrentRules),
      new Tab("Reports", Reports),
      new Tab("Title Admin", AgencyAdmin),
      // new Tab("Document Data", DocumentData),
      // new Tab("Workflow", WorkflowRouter),
      new Tab("Register", Registers),
    ];
    this.liaisonTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
      new Tab("Current Rules", CurrentRules),
    ];
    this.attestationOfficerTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
      new Tab("Current Rules", CurrentRules),
    ];
    this.oarTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
      new Tab("Current Rules", CurrentRules),
      new Tab("Reports", Reports),
      new Tab("Title Admin", AgencyAdmin),
      // new Tab("Document Data", DocumentData),
      // new Tab("Workflow", WorkflowRouter),
      new Tab("Register", Registers),
    ];
    this.cabinetSecretaryTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
    ];
    this.governorTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
    ];
    this.legislatureTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
    ];
    this.agencyTabs = [
      new Tab("My Work", WorkQueue),
      new Tab("Rule Filings", FilingBuilder),
      new Tab("Current Rules", CurrentRules),
    ];
    this.readOnlyTabs = [];
    const myWork = new Tab("My Work");
    this.defaultTab = myWork;
  }
  public defaultTab: Tab;
  public liaisonTabs: Tab[];
  public attestationOfficerTabs: Tab[];
  public oarTabs: Tab[];
  public cabinetSecretaryTabs: Tab[];
  public governorTabs: Tab[];
  public legislatureTabs: Tab[];
  public administratorTabs: Tab[];
  public agencyTabs: Tab[];
  public readOnlyTabs: Tab[];
  public static OwningUsed: boolean = false;
  public static OwningTitle: string = "Agency";
  public static OwningTitlePlural: string = "Agencies";
  public static RuleTitle: string = "Title";
  public static RuleTitlePlural: RuleTitlePluralType = "Titles";
}

export class Tab {
  constructor(
    title: string,
    component?: (props: IStateTabComponentProps) => JSX.Element,
    customUrl?: string
  ) {
    this.title = title;
    this.urlPath = customUrl?.toLowerCase() ?? title.toLowerCase();
    this.componentProps = { title: this.title, urlCode: this.urlPath };
    this.component = component ?? NotFound;
  }
  public title: string;
  public componentProps: IStateTabComponentProps;
  public component: (props: IStateTabComponentProps) => JSX.Element;
  public urlPath: string;
}
