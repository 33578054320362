import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { IStateTabComponentProps } from "../IStateTabComponentProps";
import { useAgency } from "./../../../utils/hooks/useAgency";
import "./AgencyAdmin";
import { AgencyPath } from "./components/AgencyPath";

interface IAgencyAdminProps extends IStateTabComponentProps {}

export const AgencyAdmin = (props: IAgencyAdminProps) => {
  const [Agency] = useAgency();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const setupAgency = () => {
    setLoading(false);
  };

  useEffect(() => {
    setupAgency();
  }, []);

  useEffect(() => {
    setActiveStep(0);
  }, [loading]);

  useEffect(() => {
    if (Agency.statusCollection.length === activeStep) {
      setActiveStep(Agency.statusCollection.length - 1);
    }
  }, [Agency.statusCollection.length, activeStep]);

  if (loading) {
    return <CircularProgress />;
  } else {
    return (
      <div className="Agency-builder">
        <AgencyPath />
      </div>
    );
  }
};
