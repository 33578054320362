import { Draft } from "../../../../utils/model";

// #######################################################
// Set Contact Types and create columns
// #######################################################
export const setDraftTypeRows = (
  rows: any,
  ) => {
  // #######################################################
  // Set Initial columns for dynamic column generation
  // #######################################################
  let columns: any = [];

  if (rows[0] !== undefined && rows) {
    const draft: Draft = new Draft(
      rows[0].draftName,
      rows[0].titleNumber,
      rows[0].chapterNumber,
      rows[0].createdBy,
      rows[0].createdOn,
      rows[0].draftStatus,
      rows[0].isEditable
    );

    columns = [];
    const keys = Object.keys(draft);
    keys.forEach((key, index) => {
      // Regular expression takes table name and converts formatting
      var text = key.replace(/([A-Z])/g, " $1");
      var capitalizeText = text.charAt(0).toUpperCase() + text.slice(1);

      const column = {
        title: capitalizeText,
        field: key,
        hidden:false,
      };

      let lookup: Map<string, string> = new Map<string, string>();
      columns.push(column);
    });
  }

  return { rows: rows, columns: columns };
};
