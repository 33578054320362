import { Identifiable } from '.';

export class EntityType extends Identifiable {
    constructor(name: string, schema: object){
        super(null);
        this.name = name;
        this.schema = schema;

    }
    public  name: string;
    public  schema: object;
}


