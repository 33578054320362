import { AccordionDetails, Paper, Typography, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Card, CardBody } from "@progress/kendo-react-layout";
import React from "react";
import { CodeModalButton } from ".";
import "./CodeResult.scss";
import "./ExpansionDetail.scss";
import { Paragraph } from "../../../../../components/Paragraph";
import { fileAPI } from "../../../../../utils/api";
import { StorageContainer, SegmentType, NoteType } from "../../../../../utils/enums";
import { savePDF } from "@progress/kendo-react-pdf";
import saveAs from "file-saver";
import { getEnabledCategories } from "trace_events";

interface IExpansionDetailProps {
  segment: any;
  noteTypes: any;
}

export const ExpansionDetail = (props: IExpansionDetailProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "1rem",
      },
      heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "bold"
        // fontWeight: theme.typography.fontWeightBold,
      },
      advancedSearch: {
        backgroundColor: "#0066A6",
        color: "white",
      },
      searchFocused: {
        '& > div[tabindex="0"]:focus': {
          backgroundColor: "#004e9a",
          boxShadow: "0 0 1pt 0.8pt blue",
        },
      },
      inputLabel: {
        position: "absolute",
        left: "24px",
        background: "white",
        padding: "0 6px",
        zIndex: 1,
        fontWeight: "normal",
        fontSize: "1.5em",
      },
      relative: {
        position: "relative",
      },
    })
  );

  const style = {
    // marginLeft: props.agencyList.values. * 3,
  };

  let container: any = React.useRef<HTMLDivElement>(null);

  const _renderExecutiveOrder = () => {
    props.segment.text = props.segment.text.replace(`<b>${props.segment.execOrderNum}</b>`, '');
    props.segment.text = props.segment.text.replace(`<b>${props.segment.description}</b>`, '');

    return (props.segment.text);
  };

  const getSegmentStatus = (item: any) => {
    if (item.statusName) {
      switch (item.statusName) {
        // case "New":
        //   return " [NEW]";
        case "Revoked":
          return " [REVOKED]";
        case "Reserved":
          return " [Reserved]";
        case "Expired":
          return " [EXPIRED]";
        // case "Amended":
        //   return " [AMENDED]"
        case "AmendedAndRenumbered":
          if (item.renumberedToSegmentNumber != null) {
            return ` [AMENDED AND RENUMBERED TO ${item.renumberedToSegmentNumber}]`;
          } else {
            return " [AMENDED AND RENUMBERED]";
          }
        case "Renumbered":
          if (item.renumberedToSegmentNumber != null) {
            return ` [RENUMBERED TO ${item.renumberedToSegmentNumber}]`;
          } else {
            return " [RENUMBERED]";
          }
        case "Superseded":
          return " [SUPERSEDED]";
        case "Terminated":
          return " [TERMINATED]";
        case "Transferred":
          return " [TRANSFERRED]";
        case "Other":
          return " [OTHER]";
        case "Published":
          return " [PUBLISHED]";
        default:
          return "";
          break;
      }
    }
  }

  const getSegmentHeader = (segment: any) => {
    if (segment.segmentTypeId == SegmentType.Appendix) {
      return `<div style='text-align: center;'><b>APPENDIX ${segment.appendixNum}. ${segment.description}${getSegmentStatus(segment)}</b></div>`;
    }
    else if (segment.segmentTypeId == SegmentType.ExecutiveOrder) {
      return `<div><b>${segment.execOrderNum}.${getSegmentStatus(segment)}</b></div>`;
    }
    else {
      return `<div><b>${segment.sectionNum}. ${segment.description}${getSegmentStatus(segment)}</b></div>`;
    }
  };

  const pdfDownload = async () => {
    let element = container.current;
    savePDF(element, {
      paperSize: "a4",
      margin: 40,
      fileName: `Section-${props.segment.sectionNum}`,
    }, () => document.querySelector('#download')!.remove());
  }

  const htmlDownload = async () => {
    let url: any = await fileAPI.getFileUrl(
      "Title_" + props.segment.titleNum + ".html",
      StorageContainer.TitleHtml
    );

    let blob: Blob = await fetch(url).then((r) => r.blob());
    const fileURL = URL.createObjectURL(blob);

    saveAs(blob, "Title_" + props.segment.titleNum + ".html");
  }

  const getPrefixNotes = () => {
    // let sourceNotes = "";
    // let type = -1;
    // let noteHTML;

    // props.noteTypes.forEach((element:any) => {
    //   if(element.type == "Source") {
    //     props.segment.segmentNotes.forEach((note:any) => {
    //       sourceNotes += note.note;
    //     });
    //   }
    // })
    // noteHTML = <span style={{fontSize: "11pt"}}>[{sourceNotes}]</span>;

    return "";
  }

  const renderSegmentNotes = (segment: any) => {
    if (!segment.segmentNotes && segment.segmentNotes.length > 0) {
      return '';
    }


    let notes = segment.segmentNotes;
    let textBody: any;
    let editorsNotes: any = [];
    let sourceNotes: any = [];
    let authorityNotes: any = [];
    let footNotes: any = [];
    let agencyNotes: any = [];

    const linkHref = "https://okadministrativerules.azurewebsites.net/api/BlobStorageGetFile?storageContainer=PublicRegister&name=";


    // // Order in which note types should appear
    // //  1. Footnote
    // //  2. Source
    // //  3. Editors
    // //  4. Agency
    // //  5. Authority

    notes.forEach((note: any, index: number) => {
      //   var found = null;
      //   found = noteType?.find((a:any) => a == note.noteTypeId);
      //   if(found) {
      if (note.noteTypeId == NoteType.Source) {
        sourceNotes.push(
          note
        );
      } else if (note.noteTypeId == NoteType.Editors) {
        editorsNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Authority) {
        authorityNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        )
      } else if (note.noteTypeId == NoteType.Agency) {
        agencyNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      } else if (note.noteTypeId == NoteType.Footnote) {
        footNotes.push(
          <div className="inline-note-text">
            <Paragraph value={note.note} className="inline-note-text" />
          </div>
        );
      }
      //   }
    })

    textBody = (
      <div>
        {footNotes.length > 0 ? (
          <div className="note">
            {footNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < footNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {sourceNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">
              <b>Source: </b>
              {sourceNotes.map((item: any, index: number) => {
                return item.hRef !== null ? (
                  <>
                    <Paragraph
                      value={`<a href="${linkHref + item.hRef}" className="note-zone">${item.note}</a> `}
                      className="inline-note-text"
                    />
                    {index + 1 < sourceNotes.length ? <span>;&nbsp;</span> : null}
                  </>

                ) : (
                  <>
                    <Paragraph value={item.note} className="inline-note-text" />
                    {index + 1 < sourceNotes.length ? <span>;&nbsp;</span> : null}
                  </>
                );
              }
              )
              }
            </span>
          </div>
        ) : null}
        {editorsNotes.length > 0 ? (
          <div className="note">
            {editorsNotes.map((item: any, index: number) => { if (item !== "") return (<div className="inline-note-text"><i><strong>EDITOR'S NOTE: </strong>{item}</i>{index + 1 < editorsNotes.length ? (<span>,&nbsp;</span>) : null}</div>) })}
          </div>
        ) : null}
        {agencyNotes.length > 0 ? (
          <div className="note">
            {agencyNotes.map((item: any, index: number) => { return (<span className="inline-note-text">{item}{index + 1 < agencyNotes.length ? (<span>,&nbsp;</span>) : null}</span>) })}
          </div>
        ) : null}
        {authorityNotes.length > 0 ? (
          <div className="note">
            <span className="inline-note-text">[<b>Authority: </b>{authorityNotes.map((item: any, index: number) => { if (item != "") { return (<span className="inline-note-text">{item}{index + 1 < sourceNotes.length ? (<span>,&nbsp;</span>) : null}</span>) } })}]</span>
          </div>
        ) : null}
      </div>
    )

    return textBody;

    // let sourceNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Source);
    // let editorNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Editors);
    // let authorityNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Authority);
    // let footnotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Footnote);
    // let agencyNotes = segment.segmentNotes.filter((x: any) => x.noteTypeId == NoteType.Agency);
    // let sourceNotesText: string = sourceNotes && sourceNotes.length > 0
    //   ? sourceNotes.map((note: any) => { return note.note.trim() }).join('; ')
    //   : '';

    // // Order in which note types should appear
    // //  1. Footnote
    // //  2. Source
    // //  3. Editors
    // //  4. Agency
    // //  5. Authority
    // return <div style={{fontSize: "11px"}}>
    //   {/* May need to add this in front of each footnote (we'll have to see what Chris says): &emsp;&emsp;&emsp; */}
    //   {
    //     footnotes && footnotes.length > 0
    //       ? footnotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'>${note.note}</div>`} className=""/> })
    //       : null
    //   }
    //   {
    //     sourceNotesText !== ''
    //       ? <Paragraph value={`<div className="segment-note">[<strong>Source: </strong>${sourceNotesText}]</div>`} className="" />
    //       : null
    //   }
    //   {
    //     editorNotes && editorNotes.length > 0
    //       ? editorNotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'><i><strong>EDITOR'S NOTE: </strong>${note.note}</i></div>`} className=""/> })
    //       : null
    //   }
    //   {
    //     agencyNotes && agencyNotes.length > 0
    //       ? agencyNotes.map((note: any) => { return <Paragraph value={`<div class='segment-note'><strong>AGENCY NOTE: </strong>${note.note}</div>`} className=""/> })
    //       : null
    //   }
    //   </div>;
  };

  const getSuffixNotes = () => {
    let sourceNotes = "";
    let noteHTML;
    // console.log(props.noteTypes);
    if (props.noteTypes) {
      props.noteTypes.forEach((element: any) => {
        if (element.type == "Source") {
          props.segment.segmentNotes.forEach((noteItem: any) => {
            if (noteItem.noteTypeId == element.id) {
              sourceNotes += noteItem.note;
              // console.log(sourceNotes)
            }
          });
        }
      })
    }
    return <span style={{ fontSize: "9pt" }}>[<strong>Source: </strong>{sourceNotes}]</span>;
  }

  const getReferenceCode = () => {
    let referenceCode;
    let statusTag = null;
    if (props.segment.statusName != "Undefined") {
      if (props.segment.statusName == "Amended" || props.segment.statusName == "Amended And Renumbered") {
        if (props.segment.renumberedToSegmentNumber) {
          statusTag = props.segment.statusName + " To " + props.segment.renumberedToSegmentNumber;
        } else {
          statusTag = props.segment.statusName;
        }
      } else {
        statusTag = props.segment.statusName;
      }
    }
    switch (props.segment.name) {
      case "Appendix":
        referenceCode = <strong className="appendix-label">{props.segment.appendixNum + ". " + props.segment.description} {statusTag ? statusTag : null}</strong>
        break;
      case "Section":
        referenceCode = <strong>{props.segment.sectionNum + " " + props.segment.description} {statusTag ? "[" + statusTag + "]" : null}</strong>
        break;
      case "Executive Order":
        referenceCode = <strong>{props.segment.execOrderNum} {statusTag ? "[" + statusTag + "]" : null}</strong>
        break;
      default:
        break;
    }
    return referenceCode;
  }

  const compileSegmentText = (segment: any) => {
    var header = getSegmentHeader(segment);
    var text = segment.text == null ? '' : segment.text;

    return `${header}${text}`;
  };

  return (
    <AccordionDetails>
      <Card
        orientation="horizontal"
        style={{ width: "100%", maxHeight: "1000px" }}
      >
        <h2 className="agency-display">{props.segment.ruleType}</h2>
        <Paper style={style} className="owning-body">
          <Typography>
            <CardBody>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                  <div>
                    <div>
                      <CodeModalButton type={props.segment.name} segment={props.segment} title={props.segment.titleNum} />
                    </div>
                    <p>

                      <strong style={{ color: "#D68630", marginLeft: 15 }}>
                        OAC Number:
                      </strong>{" "}
                      {<span>{props.segment.sectionNum ?? props.segment.execOrderNum ?? "Appendix " + props.segment.appendixNum}</span>}
                    </p>

                    {props.segment.effectiveDate ? (
                      <p>
                        <strong style={{ color: "#D68630", marginLeft: 15 }}>
                          Effective Date:
                        </strong>{" "}
                        <span>{new Date(props.segment.effectiveDate).toLocaleDateString()}</span>
                      </p>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <div className="text-display">
                <Paragraph value={compileSegmentText(props.segment)} className="code-display" />
                {renderSegmentNotes(props.segment)}
              </div>
            </CardBody>
          </Typography>
        </Paper>
      </Card>
    </AccordionDetails>
  );
};

