import { CmsType } from "../enums";
import { Identifiable } from "./Identifiable";

export class CmsItem extends Identifiable {
  constructor(name: string, value: string, type: CmsType) {
    super(null);
    this.name = name;
    this.value = value;
    this.type = type;
  }
  public name!: string;
  public value: string;
  public type: CmsType;
}
