import { LinearProgress, makeStyles, Snackbar } from "@material-ui/core";
import CloudUploadroundedIcon from "@material-ui/icons/CloudUploadRounded";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { fileAPI } from "../../../../../../utils/api";
import { StorageContainer } from "../../../../../../utils/enums";

type availableFileTypes = "image" | "word";

interface IUploadPDFProps {
  showPreview?: boolean;
  numberOfAllowedFiles?: number;
  allowedFileTypes: availableFileTypes;
  fileName?: string;
  onUploaded?: (fileName: string) => void;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// TODO Put styling into a css stylesheet
const useStyles = makeStyles({
  input: {
    flexDirection: "row",
    alignItems: "center",
    justifyItems: "center",
  },
  label: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  dropZone: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    border: "2px dashed rgba(0,0,0,0.5)",
    borderRadius: 10,
    paddingRight: "4vw",
    paddingLeft: "4vw",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      background: "#3f51b5",
    },
  },
  center: {
    padding: 10,
    justifyItems: "center",
    alignItems: "center",
  },
  imagePreview: {
    width: "100%",
    heightMax: 120,
    alignContent: "center",
    paddingTop: 10,
    flexDirection: "row",
  },
  imagePreviewHide: {
    display: "none",
  },
  status: {
    display: "flex",
    flexDirection: "column",
  },
  statusPreview: {
    width: "100%",
  },
  statusPreviewHide: {
    display: "none",
  },
  progressBar: {
    paddingTop: 10,
  },
});

export let UploadPDF = (props: IUploadPDFProps) => {
  const initalSuccessMessage: string | null = null;
  const [snackSuccessMessage, setSnackSuccessMessage] = useState<string | null>(
    initalSuccessMessage
  );
  const initalErrorMessage: string | null = null;
  const [snackErrorMessage, setErrorMessage] = useState<string | null>(
    initalErrorMessage
  );
  const [filePreview, setFilePreview] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filename, setFilename] = useState("Choose File");
  const classes = useStyles();

  // WARNING: Because of the show preview prop we should never set using setStateShowPreview
  const [showPreview, setStateShowPreview] = useState(false);
  // Allows component implementation to turn off preview
  const setShowPreview = React.useCallback(
    (showPreview: boolean) => {
      if (props.showPreview) {
        setStateShowPreview(showPreview);
      }
    },
    [props.showPreview]
  );

  useEffect(() => {
    // show the file preview if a file name is defined
    const fileName = props.fileName;
    if (fileName) {
      const fileUrl = fileAPI.getFileUrl(fileName, StorageContainer.FilingUploads);
      if(fileUrl) {
        setFilePreview(fileUrl.toString());
      }
      // setShowPreview(true);
    }
  }, [props.fileName, setShowPreview, setFilePreview]);

  type OnDropEvent = (af: File[], rf: FileRejection[], e: DropEvent) => void;
  const onDrop: OnDropEvent = (
    acceptedFiles: File[],
    rejctedFiles: FileRejection[],
    dropEvent: DropEvent
  ) => {
    // Run Validation
    const valid: boolean = onDropValidation(acceptedFiles, dropEvent);
    if (!valid) {
      return;
    }
    // TODO Modify for multi-file uploads
    setFilename(acceptedFiles[0].name);
    uploadDoc(acceptedFiles);
  };

  const onDropValidation = (acceptedFiles: File[], dropEvent: DropEvent) => {
    const { numberOfAllowedFiles } = props;

    // check file length
    if (numberOfAllowedFiles) {
      if (acceptedFiles.length > numberOfAllowedFiles) {
        setErrorMessage(`Too many files, only ${numberOfAllowedFiles} allowed`);
        return false;
      }
    }

    // check individual files
    for (let index = 0; index < acceptedFiles.length; index++) {
      const acceptedFile = acceptedFiles[index];
      // TODO check file types
      const { allowedFileTypes } = props;
      // Check if the image is in the allowed file types
      if (allowedFileTypes.includes("image")) {
        // a regex that checks for supported image file extensions
        const imageType = RegExp(/(^image*)\/([A-z])*/);
        // return false if the test fails
        if (!imageType.test(acceptedFile.type)) {
          setErrorMessage(`Unsupported file type`);
          return false;
        }
      }
      // TODO check file naming
      // TODO check rejected file and reasons
    }
    return true;
  };

  const uploadProgress = (progressEvent: ProgressEvent) => {
    setUploadPercentage(
      Math.round((progressEvent.loaded * 100) / progressEvent.total)
    );
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadDoc = async (acceptedFiles: File[]) => {
    setShowPreview(true);
    // TODO Manage multi file uploads
    // setup preview for the current file while it uploads
    setFilePreview(URL.createObjectURL(acceptedFiles[0]));
    const formData = new FormData();
    // TODO Manage multi file uploads
    formData.append("file", acceptedFiles[0]);
    try {
      // Run the upload
      const uploaded = await fileAPI.uploadFile(
        acceptedFiles[0].name,
        formData,
        StorageContainer.PublicCms,
        "false",
        uploadProgress
      );
      await uploaded;
      if (props.onUploaded) {
        props.onUploaded(uploaded);
      }
      // const fileUrl = fileAPI.getFileUrl(uploaded, StorageContainer.PublicCms);
      // setFilePreview(fileUrl);
      // if successful alert
      setSnackSuccessMessage("File Uploaded");
    } catch (err:any) {
      setUploadPercentage(0);
      if (err.response?.status === 500) {
        return setErrorMessage("Upload Error");
      } else {
        return setErrorMessage("Upload Error");
      }
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      // disable click away closures of the browser explorer page.
      return;
    }
    setSnackSuccessMessage(null);
    setErrorMessage(null);
  };

  return (
    <div>
      <section>
        <div className={classes.input}>
          <div>
            <div className={classes.dropZone} {...getRootProps()}>
              <CloudUploadroundedIcon />
              <div className={classes.center}>{filename}</div>
            </div>
            <input {...getInputProps()} />
          </div>
        </div>
      </section>

      <div className={classes.progressBar}>
        <LinearProgress
          variant="determinate"
          className={
            filename !== "Choose File"
              ? classes.statusPreview
              : classes.statusPreviewHide
          }
          color={uploadPercentage ? "primary" : "secondary"}
          value={uploadPercentage}
        />
      </div>

      <div>
        <img
          id="preview"
          className={
            showPreview ? classes.imagePreview : classes.imagePreviewHide
          }
          src={filePreview}
          alt=""
        />
      </div>

      {/* Popup managment */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackErrorMessage ? true : false}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity="error">{snackErrorMessage ?? ""}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackSuccessMessage ? true : false}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity="success">{snackSuccessMessage ?? ""}</Alert>
      </Snackbar>
    </div>
  );
};
