import { VisibilityLevel, FilingType} from '../enums';
import { Identifiable } from '.';
import { Status } from './Status';

export class Agency extends Identifiable {
    constructor(name:string, filingType:FilingType, visibility:VisibilityLevel){
        super(null);
        this.name = name;
        this.filingType = filingType;
        this.visibility = visibility;
        this.statusCollection = [];
    }
    public name:string;
    public filingType: FilingType;
    public visibility: VisibilityLevel;
    public statusCollection: Status[];
    public addStatus = (status:Status) => {
        this.statusCollection.push(status);
    }
}