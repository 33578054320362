import * as React from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  ListItem,
  Divider,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import "./AlertDialogWithUserInfo.scss";
import { UserAvatar } from "../UserAvatar";
import { ApplicationRoles } from "../../utils/model/ApplicationUser";
import { userInfo } from "os";

interface IAlertDialog {
  close: boolean;
  open: boolean;
  textContent: string;
  alertResponse: (response: boolean) => void;
  key?: number;
  user: string;
  modifiedDetail?: string;
}

export const AlertDialogWithUserInfo = (props: IAlertDialog) => {
  const { key, user } = props;
  const handleClose = (response: boolean) => {
    props.alertResponse(response);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="alert-dialog"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="message">
            <WarningIcon className="alert-icon" />
            <p>{"Are you sure you want to continue?"}</p>
            <WarningIcon className="alert-icon" />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="comment" key={key}>
          <DialogContentText id="alert-dialog-description">
            {props.textContent}
          </DialogContentText>
            <ListItem alignItems="flex-start">
              <UserAvatar
                userId={user!}
                displayText={props.modifiedDetail ?? ""}

              />
            </ListItem>
          </div>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            Override and Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
