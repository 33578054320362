import { Fab, Input, Typography } from "@material-ui/core";
import Nightly from "@material-ui/icons/NightsStayRounded";
import DateIcon from "@material-ui/icons/DateRange";
import Upload from "@material-ui/icons/CloudUploadOutlined";
import Success from "@material-ui/icons/Check";
import Failed from "@material-ui/icons/SmsFailed";
import React, { useEffect } from "react";
import { developmentAPI } from "../../../../../utils/api/Development";
import "./DevelopmentToolbar.scss";
import { EnumSelectInput } from "../../../../../components";

interface IDevelopmentToolbar {}
export enum FilingDates {
  SubmittedDate,
  EffectiveDate,
  CommentBeginDate,
  CommentEndDate,
}
export const DevelopmentToolbar = (props: IDevelopmentToolbar) => {
  type NightlyJobStatus = "success" | "fail" | "pending" | "none";
  type SetDateStatus = "success" | "fail" | "pending" | "none";
  const members: any[] = Object.keys(FilingDates);
  // assign only the string values
  let strings: string[] = members.filter(
    (member) => !parseInt(member) && parseInt(member) !== 0
  );
  // assign only the number values
  let numbers: number[] = members.filter(
    (member) => parseInt(member) || parseInt(member) === 0
  );

  const [show, setShow] = React.useState<boolean>(false);
  const [minimized, setMinimized] = React.useState<boolean>(false);
  const [dateType, setDateType] = React.useState<number>(0);
  const [dateToSet, setDate] = React.useState<string>("");
  const [filingId, setFilingId] = React.useState<number>(0);
  const [
    nightlyJobStatus,
    setNightlyJobStatus,
  ] = React.useState<NightlyJobStatus>("none");
  const [dateStatus, setDateStatus] = React.useState<SetDateStatus>("none");
  // Check to see if we are in development
  // if so display development toolbar
  const onMount = React.useCallback(async () => {
    // setShow(await developmentAPI.isDevelopment());
    setShow(false);
  }, []);

  const onChangeDaysToSubtract = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = event.target.value;
    setDate(value);
  };

  const onChangeFilingId = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value);
    setFilingId(value);
  };

  const runNightly = async () => {
    if (nightlyJobStatus == "none") {
      setNightlyJobStatus("pending");
      const success: boolean = await developmentAPI.runNightlyJobs();
      if (success) {
        setNightlyJobStatus("success");
      } else {
        setNightlyJobStatus("fail");
      }
      setTimeout(() => {
        setNightlyJobStatus("none");
      }, 4000);
    }
  };
  const lowerCaseFirstLetter = (string: string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  };
  const setDateStatusClick = async () => {
    if (dateToSet === "") {
      alert("You have not yet chosen a date");
    } else if (filingId === 0) {
      alert("0 is not a valid filingId");
    } else if (dateStatus == "none") {
      setDateStatus("pending");
      const success: boolean = await developmentAPI.setDateOnFiling(
        filingId,
        lowerCaseFirstLetter(strings[dateType]),
        dateToSet
      );
      if (success) {
        setDateStatus("success");
      } else {
        setDateStatus("fail");
      }
      setTimeout(() => {
        setDateStatus("none");
      }, 4000);
    }
  };

  useEffect(() => {
    onMount();
  }, [onMount]);
  if (show == true) {
    if (minimized) {
      return (
          <div className="expand-development-container">
            <Fab
              className="plus"
              onClick={() => {
                setMinimized(false);
              }}
            >
              <Typography variant="h6">+</Typography>
            </Fab>
          </div>
      );
    }
    return (
      <div className="development-container">
        <div className="title-contianer">
          <Typography variant="h6">Development toolbar</Typography>
          <Fab
            className="min"
            onClick={() => {
              setMinimized(true);
            }}
          >
            <Typography variant="h6">-</Typography>
          </Fab>
        </div>
        <div className="development-toolbar">
          <Fab variant="extended" onClick={runNightly}>
            {nightlyJobStatus == "success" ? (
              <Success htmlColor="green" />
            ) : nightlyJobStatus == "fail" ? (
              <Failed htmlColor="red" />
            ) : nightlyJobStatus == "pending" ? (
              <Upload htmlColor="yellow" />
            ) : (
              <Nightly />
            )}
            {nightlyJobStatus == "success" ? (
              <Typography variant="body1">Success</Typography>
            ) : nightlyJobStatus == "fail" ? (
              <Typography variant="body1">Failed</Typography>
            ) : nightlyJobStatus == "pending" ? (
              <Typography variant="body1">Waiting...</Typography>
            ) : (
              <Typography variant="body1">NightlyJob</Typography>
            )}
          </Fab>

          <div className="date-container">
            <EnumSelectInput
              title="date to change"
              value={dateType}
              onChange={setDateType}
              enumerable={FilingDates}
            />
            <Input
              type="date"
              value={dateToSet}
              onChange={onChangeDaysToSubtract}
            ></Input>
            <Input
              type="number"
              value={filingId}
              onChange={onChangeFilingId}
            ></Input>
            <Fab variant="extended" onClick={setDateStatusClick}>
              {dateStatus == "success" ? (
                <Success htmlColor="green" />
              ) : dateStatus == "fail" ? (
                <Failed htmlColor="red" />
              ) : dateStatus == "pending" ? (
                <Upload htmlColor="yellow" />
              ) : (
                <DateIcon />
              )}
              {dateStatus == "success" ? (
                <Typography variant="body1">Success</Typography>
              ) : dateStatus == "fail" ? (
                <Typography variant="body1">Failed</Typography>
              ) : dateStatus == "pending" ? (
                <Typography variant="body1">Waiting...</Typography>
              ) : (
                <Typography variant="body1">Set Date</Typography>
              )}
            </Fab>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
