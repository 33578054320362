import { BaseAPI } from "./internal";
import { APISettings } from "../settings/APISettings";
import { SegmentCodificationHistory } from "../model";

class SegmentCodificationHistoryApi extends BaseAPI<SegmentCodificationHistory> {
    constructor() {
        super("any", APISettings.adminRulesApiUrl);
      }

      public getCodificationHistoryBySegmentIdAsync: (segmentId: number) => Promise<any> = async (segmentId: number) => {
        return (await BaseAPI.axios.get(`${this.apiUrl}/GetCodificationHistoryBySegmentIdAsync/${segmentId}`)).data;
      };

      public addAsync: (segmentId: number) => Promise<any> = async (segmentId: number) => {
        let response = await BaseAPI.axios.post(`${this.apiUrl}/AddCodificationHistoryBySegmentId/${segmentId}`);
        return response.data;
      };

      public addCodificationHistoryAsync: (data: SegmentCodificationHistory) => Promise<any> = async (data: SegmentCodificationHistory) => {
        let response = await BaseAPI.axios.post(`${this.apiUrl}/AddCodificationHistoryAsync`, {data});
        return response.data;
      };

      public addCodificationHistoryRangeAsync: (data: SegmentCodificationHistory[]) => Promise<any> = async (data: SegmentCodificationHistory[]) => {
        let response = await BaseAPI.axios.post(`${this.apiUrl}/AddCodificationHistoryRangeAsync`, {data});
        return response.data;
      };

      public addNoteCodificationRecord: (noteId: number) => Promise<any> = async (noteId: number) => {
        let response = await BaseAPI.axios.post(`${this.apiUrl}/AddNoteCodificationRecord/${noteId}`);
        return response.data;
      };
}

export const SegmentCodificationHistoryAPI = new SegmentCodificationHistoryApi();