import internal from "stream";
import { PublicationStatus, TypeStatus, VisibilityLevel } from "../enums";
import { Action } from "./Action";
import { Amendment } from "./Amendment";
import { CommentThread } from "./CommentThread";
import { Filing } from "./Filing";
import { FilingHistory } from "./FilingHistory";
import { Identifiable } from "./Identifiable";
import { SegmentHistoryData } from "./SegmentHistoryData";
import { Status } from "./Status";
import { Task } from "./Task";
import { TemplateValue } from "./TemplateValue";
import { TodoList } from "./TodoList";
import { Workflow } from "./Workflow";

export class AggregatedRegister extends Identifiable {
  constructor(
    segmentId: number | null,
    draftId: number,
    segmentData: SegmentHistoryData,
    startRow: Date,
    endRow: Date,
    includeInDraft: boolean,
    isNewSegment: boolean = false,
    filingId: number,
    name: string,
    includeInRegister: boolean,
    statusId: number,
    published: PublicationStatus,
    visibility: VisibilityLevel,
    filingTypeId: number,
    ruleId: number,
    chapterId: number,
    titleNumber: string,
    chapterNumber: string,
    draftStatus: string,
    isEditable: boolean,
    draftName: string,
    filingTypeName: string,
    alternativeName: string,
    description: string,
    subFiling: boolean,
    status: TypeStatus,
    workflowId: number,
    notes: string,
    additionalInformation: string,
    docketYear: number,
    liaisonId?: number,
    docketId?: number,
    adoptionDate?: Date,
    registerId?: number | null,
    entity?: any,
    segmentStatusId?: number | null,
    lockedByUserId?: string | null,
    lastModified?: Date | string,
    anticipatedPublishingDate?: Date | string,
    submissionDeadline?: Date | string,
    docketNumber?: string,
  ) {
    super(null)
    if (segmentId) {
      this.segmentId = segmentId;
    } else {
      this.segmentId = null;
    }
    this.draftId = draftId;
    this.segmentData = segmentData;
    this.startRow = startRow;
    this.endRow = endRow;
    this.includeInDraft = includeInDraft;
    this.isNewSegment = isNewSegment;
    if (segmentStatusId) {
      this.segmentStatusId = segmentStatusId;
    }
    if (lockedByUserId) {
      this.lockedByUserId = lockedByUserId;
    }

    if (entity) {
      this.entity = entity;
    }
    this.name = name;
    this.includeInRegister = includeInRegister;
    this.statusId = statusId;
    this.published = published;
    this.visibility = visibility;
    this.filingTypeId = filingTypeId;
    this.ruleId = ruleId;
    this.chapterId = chapterId;
    this.liaisonId = liaisonId;
    this.docketId = docketId;
    this.adoptionDate = adoptionDate;
    if (registerId) {
      this.registerId = registerId;
    }
    this.titleNumber = titleNumber;
    this.chapterNumber = chapterNumber;
    this.draftStatus = draftStatus;
    this.isEditable = isEditable;
    this.draftName = draftName;

    this.filingTypeName = filingTypeName,
    this.alternativeName = alternativeName;
    this.description = description;
    this.subFiling = subFiling;
    this.status = status;
    this.workflowId = workflowId;
    this.notes = notes;
    this.additionalInformation = additionalInformation;
    this.docketYear = docketYear;
    this.lastModified = lastModified;
    this.anticipatedPublishingDate = anticipatedPublishingDate;
    this.submissionDeadline = submissionDeadline;
    this.docketNumber = docketNumber;
  }

  public segmentId?: number | null;
  public draftId: number;
  public segmentData: SegmentHistoryData;
  public startRow: Date;
  public endRow: Date;
  public includeInDraft: boolean;
  public isNewSegment: boolean;
  public segmentStatusId?: number;
  public lockedByUserId?: string;

  public entity?: any;

  public name: string;
  public includeInRegister: boolean;
  public statusId: number;
  public published: PublicationStatus;
  public visibility: VisibilityLevel;
  public filingTypeId: number;
  public ruleId: number;
  public chapterId: number;
  public liaisonId?: number;
  public docketId?: number;
  public adoptionDate?: Date;
  public registerId?: number;

  public titleNumber: string;
  public chapterNumber: string;
  public draftStatus: string;
  public isEditable: boolean;
  public draftName: string;

  public filingTypeName: string;
  public alternativeName: string;
  public description: string;
  public subFiling: boolean;
  public status: TypeStatus;
  public workflowId: number;
  public notes: string;
  public additionalInformation: string;

  public docketYear: number;
  public lastModified?: Date | string;

  public anticipatedPublishingDate?: Date | string;
  public submissionDeadline?: Date | string;
  public docketNumber?: string
}
